import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { GlobalContext } from "../components/authorized/PortalPage";
import { MAX_CHART_ITEM_COUNT } from "../constants";

export const MaxChartItemCountInput = () => {
    const { maxChartItemCount, setMaxChartItemCount }: { maxChartItemCount: number, setMaxChartItemCount: any } = React.useContext(GlobalContext);
    return (
        <Grid item>
            <TextField
                fullWidth
                required
                variant="outlined"
                label="Balken Anzahl"
                type="number"
                InputProps={{
                    endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                }}
                value={maxChartItemCount}
                onChange={(event: any) => {
                    setMaxChartItemCount(event.target.value);
                    localStorage.setItem(MAX_CHART_ITEM_COUNT, JSON.stringify(event.target.value))
                }}
            />
        </Grid>
    )

}