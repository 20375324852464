import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme, Checkbox, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { GridStackCustomDataTable } from '../../authorized/portal/Dashboard/customDashboard/GridStackCustomDataTable';
import { GridStackCustom } from '../../../models/GridStackCustom';
import { InlineDateTimePicker } from '../../core/InlineDateTimePicker';
import { UserSavedCombinationGroup } from '../../../models/UserSavedCombinationGroup';
import { PeriodChartFilterType } from '../../../helpers/date-management';
import { useTranslation } from 'react-i18next';

export const SetPeriodForGroupCombinationDialog = ({ open, setOpen, onRefresh, groupCombination }
    : { open: boolean, setOpen: any, onRefresh: any, groupCombination: UserSavedCombinationGroup }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [isCustomDateEnabled, setIsCustomDateEnabled] = React.useState(false);
    const [includeHours, setIncludeHours] = React.useState(false);
    const [periodChartFilterType, setPeriodChartFilterType] = React.useState(PeriodChartFilterType.Month);
    const {t} = useTranslation();


    React.useEffect(() => {
        if (groupCombination.startDateEval) {
            setStartDate(new Date(groupCombination.startDateEval));
        }
        if (groupCombination.endDateEval) {
            setEndDate(new Date(groupCombination.endDateEval));
        }
        setIsCustomDateEnabled(groupCombination.isCustomDateEnabled);
        setIncludeHours(groupCombination.includeHours);
        setPeriodChartFilterType(groupCombination.periodChartFilterType.toString() as any);
    }, [open]);



    const [loading, setLoading] = React.useState(false);

    const handleClose = () => setOpen(false);
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle handleClose={handleClose} title={"Setzen Sie für die Kombination Ihren Zeitraum"} />
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isCustomDateEnabled}
                                        onChange={(event: any) => { setIsCustomDateEnabled(event.target.checked) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Spezifischer Zeitraum aktiviert"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeHours}
                                        onChange={(event: any) => { setIncludeHours(event.target.checked) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Stundenanzeige"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Zeitauswahl</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={periodChartFilterType} onChange={(event) => {
                                    setPeriodChartFilterType(event.target.value as PeriodChartFilterType);
                                }} row>
                                    <FormControlLabel value={PeriodChartFilterType.Hour} control={<Radio />} label="Stündlich" />
                                    <FormControlLabel value={PeriodChartFilterType.Day} control={<Radio />} label="Täglich" />
                                    <FormControlLabel value={PeriodChartFilterType.Week} control={<Radio />} label="Wöchentlich" />
                                    <FormControlLabel value={PeriodChartFilterType.Month} control={<Radio />} label="Monatlich" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item container direction="row" spacing={3}>
                            <Grid item>
                                <InlineDateTimePicker includeHours={includeHours} disabled={!isCustomDateEnabled} title={"Start Datum"} selectedDate={startDate} setSelectedDate={(event: any) => {
                                    setStartDate(event);
                                }}></InlineDateTimePicker>
                            </Grid>
                            <Grid item>
                                <InlineDateTimePicker includeHours={includeHours} disabled={!isCustomDateEnabled} title={"End Datum"} selectedDate={endDate} setSelectedDate={(event: any) => {
                                    setEndDate(event);
                                }}></InlineDateTimePicker>
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        disabled={loading}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        endIcon={loading && <CircularProgress size={32} />}
                        variant={'contained'}
                        color="primary"
                        disabled={loading}
                        onClick={async () => {
                            setLoading(true);
                            await onRefresh(startDate, endDate, isCustomDateEnabled, includeHours, periodChartFilterType);
                            handleClose();
                            setLoading(false);
                        }}
                    >
                        Speichern!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));