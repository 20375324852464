import Paper from '@mui/material/Paper';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import makeStyles from '@mui/styles/makeStyles';
import { mapToiletTypeKey } from './Base';
import { lightgrey } from '../../styles/colors';
import { toiletTypePredicate } from './components/Cells';
import React from 'react';
import { Group } from '../../models/Group';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const GroupSingleSelection = ({ groups, selectedGroup, setSelectedGroup }
    : { groups: GroupLicenseTable[], selectedGroup: GroupLicenseTable | undefined, setSelectedGroup: any }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [columns, setColumns] = React.useState(defaultGroupColumns(t));
    return (
        <Paper>
            <Grid
                getRowId={(row: GroupLicenseTable) => row.id}
                rows={groups}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering columnExtensions={[
                    { columnName: keys.toiletType, predicate: toiletTypePredicate },
                ]} />

                <SortingState
                    defaultSorting={[{ columnName: keys.notificationName, direction: 'desc' }]}
                />
                <IntegratedSorting />


                <SelectionState
                    selection={selectedGroup ? [selectedGroup.id] : []}
                    onSelectionChange={(changes: (string | number)[]) => {

                        if (changes.length > 1)
                            changes.shift();

                        setSelectedGroup(groups.filter(x => x.id === changes[0])[0]);
                    }}
                />
                <IntegratedSelection />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table />
                <TableColumnResizing
                    columnWidths={defaultGroupColumnsWidths}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ColumnChooser />
                <TableFilterRow />
                <TableSelection
                    selectByRowClick
                    highlightRow
                />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
        </Paper>
    );
};

const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});

const keys = {
    type: 'type',
    notificationName: 'notificationName',
    objectName: 'objectName',
    objectCostLocation: 'objectCostLocation',
    objectDescription: 'objectDescription',
    levelName: 'levelName',
    roomName: 'roomName',
    toiletType: 'toiletType',
    uniqueDeviceId: 'uniqueDeviceId',
    userPropertyName: 'userPropertyName',
}

export const defaultGroupColumnsWidths = [
    { columnName: keys.type, width: 220 },
    { columnName: keys.notificationName, width: 220 },
    { columnName: keys.objectName, width: 220 },
    { columnName: keys.objectCostLocation, width: 220 },
    { columnName: keys.objectDescription, width: 220 },
    { columnName: keys.levelName, width: 220 },
    { columnName: keys.roomName, width: 220 },
    { columnName: keys.toiletType, width: 220 },
    { columnName: keys.uniqueDeviceId, width: 220 },
    { columnName: keys.userPropertyName, width: 220 }
]

export const defaultGroupColumns = (t: any) => [
    {
        name: keys.type,
        title: 'Einheit Typ',
        getCellValue: (row: GroupLicenseTable) => Group.GetLabelByType(row.groupType),
    },
    {
        name: keys.notificationName,
        title: 'Einheit Name',
        getCellValue: (row: GroupLicenseTable) => row.notificationName,
    },
    {
        name: keys.objectName,
        title: 'Objekt Name',
        getCellValue: (row: GroupLicenseTable) => row.objectName,
    },
    {
        name: keys.objectCostLocation,
        title: 'Objekt Kostenstelle',
        getCellValue: (row: GroupLicenseTable) => row.objectCostLocation,
    },
    {
        name: keys.objectDescription,
        title: 'Objekt Beschreibung',
        getCellValue: (row: GroupLicenseTable) => row.objectDescription,
    },
    {
        name: keys.levelName,
        title: 'Ebene',
        getCellValue: (row: GroupLicenseTable) => row.levelName,
    },
    {
        name: keys.roomName,
        title: 'Kunde',
        getCellValue: (row: GroupLicenseTable) => row.roomName,
    },
    {
        name: keys.toiletType,
        title: 'Toiletten Typ',
        getCellValue: (row: GroupLicenseTable) => mapToiletTypeKey(row.toiletType),
    },
    {
        name: keys.uniqueDeviceId,
        title: 'Device Id',
        getCellValue: (row: GroupLicenseTable) => row.uniqueDeviceId,
    }
    ,
    {
        name: keys.userPropertyName,
        title: t("myUnitsSettings.featureName"),
        getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
    }
]

