import { ExpandMore, Height } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { DateTimePicker } from "@mui/x-date-pickers";
import { isAfter, isBefore, subDays } from "date-fns";
import React, { Fragment, useContext, useImperativeHandle, useReducer, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { getGroupsPortalByObjectId } from "../../../../api/objectUnit/getGroupSettingsByObjectId";
import { primaryColor } from "../../../../App";
import { GLOBAL_PERIOD_CHART_FILTER, OBJECT_COMBINATION, SERVICE_WORKER_COMBINATION } from "../../../../constants";
import { AggregateType, DataFilterType, PeriodChartFilterType, generateChartData, generateSumChartData, getDataFromGroupIdsTrigger, getMotionData, getMotionsByObjectIds, getMotionsByServiceWorkerIds, getServiceData, getServicesByObject, getServicesModesByServiceworkers, getSettingsServicesByGroups, getSettingsServicesByObject, getSettingsServicesByServiceWorker } from "../../../../helpers/charts/base";
import { getAllCleaningTriggerTypes, generateCleaningTriggerSumChartData } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { generateCleaningTaskChartData, generateCleaningTaskSumChartData, generateCompletedCleaningTaskChartData, generateCompletedCleaningTaskSumChartData, generateIncidentReportChartData, generateIncidentReportSumChartData, generateIsOnTimeCleaningTaskChartData, generateIsOnTimeCleaningTaskSumChartData, generateMaterialConsumptionChartData, generateMaterialConsumptionSumChartData, getAllCleaningTasks, getAllIncidents, getAllMaterial, getCompletedCleaningTaskFields, getIsOnTimeCleaningTaskFields } from "../../../../helpers/charts/dataGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../helpers/compontents";
import { APIDataAmountType, defaultPeriodChartFilterType, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupMotion } from "../../../../models/GroupMotion";
import { GroupServiceMode } from "../../../../models/GroupServiceMode";
import { CleaningOperation, GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { SettingsServiceTableEntry } from "../../../../models/SettingsServiceTable";
import { mapChartKeyFeedback } from "../../../charts/Base";
import { StandardBarChartSimpleColor } from "../../../charts/StandardBarChartSimpleColor";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { ChartHeightTextBox, GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { useQuery } from "../checkout/ShopPage";
import { GroupMotionChart } from "./customerUsage/GetGroupMotions";
import { loadMotionData } from "./FrequencyDashboard";
import { CleaningTasksCompletedDashboard } from "./services/CleaningTaskCompleted";
import { CleaningTasksOnTimeDashboard } from "./services/CleaningTaskOnTime";
import { CleaningTasksDashboard } from "./services/CleaningTasks";
import { GetGroupMotionsMaterialsCount } from "./services/GetGroupMotionsMaterialsCount";
import { GetGroupRatioMotionsMaterials } from "./services/GetGroupRatioMotionsMaterials";
import { IncidentReportDashboard } from "./services/IncidentReport";
import { MaterialConsumptionDashboard } from "./services/MaterialConsumption";
import { SettingsServiceTable } from "./services/SettingsServiceTable";
import { generateCleaningDurationData } from "../../../../helpers/charts/setPointAndActualValue/SetPointAndActualValueDataGenerator";
import { ServicesDurationChart } from "./services/ServicesDurationChart";

export enum EvalApiType {
    Groups = 0,
    Objects = 1,
    ServiceWorkers = 2
}

export const Services = ({ serviceDisplayType, groupIds, drawerDisplayType }: { serviceDisplayType: EvalApiType, groupIds?: any, drawerDisplayType: DrawerMenuItemDisplayType }) => {

    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);


    const [serviceModeDurationDataAll, setServiceModeDurationDataAll] = React.useState<any>([]);
    const [serviceModeDurationData, setServiceModeDurationData] = React.useState<any[]>([]);
    const [serviceModeDurationDataFields, setServiceModeDurationDataFields] = React.useState<any[]>([]);
    const childRefsetPointActualValueServiceDuration: any = useRef();
    const childRefsetPointActualValueServiceDurationChart: any = useRef();


    const [serviceModesratioMotionsMaterialConsumptionAllData, setserviceModesRatioMotionsMaterialConsumptionAllData] = React.useState<any>([]);
    const [serviceModesratioMotionsMaterialConsumptionData, setserviceModesRatioMotionsMaterialConsumptionData] = React.useState<any>([]);
    const [serviceModesratioMotionsMaterialConsumptionDataFields, setserviceModesRatioMotionsMaterialConsumptionDataFields] = React.useState<any>([]);

    const [serviceModeshundredVisitorHowManyMaterialsAllData, setserviceModesHundredVisitorHowManyMaterialsAllData] = React.useState<any>([]);
    const [serviceModeshundredVisitorHowManyMaterialsData, setserviceModesHundredVisitorHowManyMaterialsData] = React.useState<any>([]);
    const [serviceModeshundredVisitorHowManyMaterialsDataFields, setserviceModesHundredVisitorHowManyMaterialsDataFields] = React.useState<any>([]);

    const [serviceModesMaterialConsumptionAllData, setServiceModesMaterialConsumptionAllData] = React.useState<any>([]);
    const [serviceModesMaterialConsumptionData, setServiceModesMaterialConsumptionData] = React.useState<any>([]);
    const [serviceModesmaterialConsumptionDataFields, setserviceModesMaterialConsumptionDataFields] = React.useState<any>([]);
    const [serviceModesincidentReportData, setServiceModesIncidentReportData] = React.useState<any>([]);
    const [serviceModesincidentReportAllData, setserviceModesIncidentReportAllData] = React.useState<any>([]);
    const [serviceModesincidentReportDataFields, setServiceModesIncidentReportDataFields] = React.useState<any>([]);
    const [serviceModescleaningTaskData, setserviceModesCleaningTaskData] = React.useState<any>([]);
    const [serviceModescleaningTaskAllData, setserviceModesCleaningTaskAllData] = React.useState<any>([]);
    const [serviceModescleaningTaskDataFields, setserviceModesCleaningTaskDataFields] = React.useState<any>([]);
    const [serviceModescleaningTaskIsCompletedData, setserviceModesCleaningTaskIsCompletedData] = React.useState<any>([]);
    const [serviceModescleaningTaskIsCompletedAllData, setserviceModesCleaningTaskIsCompletedAllData] = React.useState<any>([]);
    const [serviceModescleaningTaskIsOnTimeData, setserviceModesCleaningTaskIsOnTimeData] = React.useState<any>([]);
    const [serviceModescleaningTaskIsOnTimeAllData, setserviceModesCleaningTaskIsOnTimeAllData] = React.useState<any>([]);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);

    const history = useHistory();

    const childRefRatioMotionsMaterialConsumption: any = useRef();
    const childRefHundredVisitorHowManyMaterials: any = useRef();

    const serviceModeschildRefCleaningTasks: any = useRef();
    const serviceModeschildRefMaterialConsumption: any = useRef();
    const serviceModeschildRefIncidentReport: any = useRef();
    const serviceModeschildRefCompletedTask: any = useRef();
    const serviceModeschildRefTaskOnTime: any = useRef();

    const serviceModeschildRefCleaningTasksChart: any = useRef();
    const serviceModeschildRefMaterialConsumptionChart: any = useRef();
    const serviceModeschildRefIncidentReportChart: any = useRef();
    const serviceModeschildRefCompletedTaskChart: any = useRef();
    const serviceModeschildRefTaskOnTimeChart: any = useRef();

    const serviceModesserviceModeschildRefMotionChart: any = useRef();
    const serviceModeschildRefMotion: any = useRef();


    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);

    const [groupMotions, setGroupMotions] = React.useState<any>([]);
    const [groupMotionsAll, setGroupMotionsAll] = React.useState<any>([]);
    const [groupMotionType, setGroupMotionType] = React.useState<any>([]);

    const { groupId } = useParams<{ groupId: string }>();
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const [allServiceMode, setAllServiceMode] = React.useState<GroupServiceMode[]>([]);
    const [allCleaningTasks, setAllCleaningTasks] = React.useState<CleaningOperation[]>([]);
    const [settingsServices, setSettingsServices] = React.useState<SettingsServiceTableEntry[]>([]);
    const [loadingCompletedGroups, setLoadingCompletedGroups] = React.useState(false);
    const [loadingCompletedCleaningTasks, setLoadingCompletedCleaningTasks] = React.useState(false);
    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(30);

    const queryString = useQuery(history);
    const fixedId = queryString.get("serviceId");
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);

    const refBuildTaskDoneChart = React.useRef<any>();
    const refBuildTaskNotDoneChart = React.useRef<any>();

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        let token = getApiToken(history);
        if (!token) return;
        setserviceModesCleaningTaskData([]);
        setServiceModesMaterialConsumptionData([]);
        setLoadingCompleted(false);
        setLoadingCompletedGroups(false);
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
        setApiDataLoading(true);
        setLoadingProgress(0);
        setValueBuffer(20);
        (async () => {

            if (serviceDisplayType == EvalApiType.Objects) {
                let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupsLicenseTableEntries(groups);
            }
            else if (serviceDisplayType == EvalApiType.ServiceWorkers){
                let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupsLicenseTableEntries(groups);
            }

            let [motions, servicesMode, cleaningTasks] = await getServicesData(token, groupId, groupIds, selectedObjectId, selectedServiceWorkerId, showDataServiceMode, showserviceModesCleaningTaskData, setLoadingProgress, setValueBuffer);


            await loadMotionData(
                setGroupMotionsAll,
                setGroupMotionType,
                setGroupMotions,
                setLoadingCompleted,
                AggregateType.Time,
                motions);

            setLoadingCompletedGroups(true);
            setLoadingProgress(100);
            setValueBuffer(100);
            setApiDataLoading(false);



        })();
    }, [apiDataAmountType, reloadDataFromApi]);

    const updateSimpleData = (serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[], beginDate: Date, endDate: Date) => {
        setSettingsServices(reloadSettingsServices(serviceMode, cleaningTasks, beginDate, endDate));
    }



    React.useEffect(() => {
        setserviceModesHundredVisitorHowManyMaterialsDataFields([...serviceModesmaterialConsumptionDataFields]);
        setserviceModesRatioMotionsMaterialConsumptionDataFields([...serviceModesmaterialConsumptionDataFields])

        if (groupMotionsAll.length > 0 && serviceModesMaterialConsumptionAllData.length > 0) {
            setserviceModesHundredVisitorHowManyMaterialsAllData([...groupMotionsAll, ...serviceModesMaterialConsumptionAllData]);
            setserviceModesRatioMotionsMaterialConsumptionAllData([...groupMotionsAll, ...serviceModesMaterialConsumptionAllData]);

        }
    }, [groupMotionsAll, serviceModesMaterialConsumptionAllData])

    React.useEffect(() => {
        if (isPrintPage) {
            (async () => {
                const token = getApiToken(history);
                if (groupsLicenseTableEntries.length < 1) {
                    let groupsPortal = await getPortalGroups(groupIds, token, groupId);
                    setGroupsLicenseTableEntries(groupsPortal);
                }
                setLoadingCompletedGroups(true);
            })();
        }

    }, [isPrintPage]);

    const reloadSettingsServices = (serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[], beginDate: Date | null = null, endDate: Date | null = null) => {
        if (beginDate && endDate) {
            serviceMode = serviceMode.filter(x => {
                console.log(x);
                let curDate = new Date(x.createdTimestamp * 1000);
                return isAfter(curDate, beginDate) && isBefore(curDate, endDate);
            });
        }
        let servicesTable = cleaningTasks.map(task => {
            let val = serviceMode.reduce((prevVal, curVal) => {
                if (curVal.cleaningTaskItems.includes(task.text)) {
                    return prevVal + 1;
                }
                return prevVal;
            }, 0)
            return new SettingsServiceTableEntry(task.text, val, task.isEnabled, task.standardItemId);
        });
        return servicesTable;
    }



    const showDataServiceMode = async (servicesMode: GroupServiceMode[]) => {
        setAllServiceMode(servicesMode);
        let periodChartFilter: PeriodChartFilterType = (localStorage.getItem(GLOBAL_PERIOD_CHART_FILTER) || "" + defaultPeriodChartFilterType) as PeriodChartFilterType;

        let allCleaningDurationTemp = generateCleaningDurationData(servicesMode);
        setServiceModeDurationDataAll(allCleaningDurationTemp);
        let allFields = ["timeOfService"];
        let cleaningDurationsTemp = generateChartData(allCleaningDurationTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allFields);
        setServiceModeDurationDataFields(allFields);
        setServiceModeDurationData(cleaningDurationsTemp);

        await manageServicesData(servicesMode,
            setServiceModesMaterialConsumptionAllData,
            setserviceModesMaterialConsumptionDataFields,
            setServiceModesMaterialConsumptionData,
            setserviceModesIncidentReportAllData,
            setServiceModesIncidentReportDataFields,
            setServiceModesIncidentReportData,
            setserviceModesCleaningTaskAllData,
            setserviceModesCleaningTaskDataFields,
            setserviceModesCleaningTaskData,
            setserviceModesCleaningTaskIsCompletedAllData,
            setserviceModesCleaningTaskIsCompletedData,
            setserviceModesCleaningTaskIsOnTimeAllData,
            setserviceModesCleaningTaskIsOnTimeData,
            setLoadingCompleted,
            AggregateType.Time,
            fixedId);

    }

    const showserviceModesCleaningTaskData = (servicesMode: GroupServiceMode[], cleaningTasks: CleaningOperation[]) => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        setEndDate(endDate);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        setStartDate(startDate);

        setAllCleaningTasks(cleaningTasks);
        setSettingsServices(reloadSettingsServices(servicesMode, cleaningTasks, startDate, endDate));
        if (refBuildTaskDoneChart.current) {
            refBuildTaskDoneChart.current.updateTaskDoneSimpleChart(startDate, endDate, servicesMode, cleaningTasks);
        }
        if (refBuildTaskNotDoneChart.current) {
            refBuildTaskNotDoneChart.current.updateTaskNotDoneSimpleChart(startDate, endDate, servicesMode, cleaningTasks);
        }
        setLoadingCompletedCleaningTasks(true);


    }

    React.useEffect(() => {
        if (loadingCompletedGroups) {
            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
        }
    }, [loadingCompletedGroups, loadingCompletedCleaningTasks])

    const updateChosenPastDaysFromParent = (event: any) => {
        if (serviceModeschildRefCleaningTasks.current != null)
            serviceModeschildRefCleaningTasks.current.updateChosenPastDays(event.target.value);

        if (serviceModeschildRefMaterialConsumption.current != null)
            serviceModeschildRefMaterialConsumption.current.updateChosenPastDays(event.target.value);

        if (serviceModeschildRefIncidentReport.current != null)
            serviceModeschildRefIncidentReport.current.updateChosenPastDays(event.target.value);

        if (serviceModeschildRefCompletedTask.current != null)
            serviceModeschildRefCompletedTask.current.updateChosenPastDays(event.target.value);

        if (serviceModeschildRefTaskOnTime.current != null)
            serviceModeschildRefTaskOnTime.current.updateChosenPastDays(event.target.value);

        if (childRefRatioMotionsMaterialConsumption.current != null)
            childRefRatioMotionsMaterialConsumption.current.updateChosenPastDays(event.target.value);

        if (childRefHundredVisitorHowManyMaterials.current != null)
            childRefHundredVisitorHowManyMaterials.current.updateChosenPastDays(event.target.value);

        if (serviceModeschildRefMotion.current != null)
            serviceModeschildRefMotion.current.updateChosenPastDays(event.target.value);

    }

    const updateEndDateFromParent = (event: any) => {
        if (serviceModeschildRefCleaningTasks.current != null)
            serviceModeschildRefCleaningTasks.current.updateEndDateFromParent(event);

        if (serviceModeschildRefMaterialConsumption.current != null)
            serviceModeschildRefMaterialConsumption.current.updateEndDateFromParent(event);

        if (serviceModeschildRefIncidentReport.current != null)
            serviceModeschildRefIncidentReport.current.updateEndDateFromParent(event);

        if (serviceModeschildRefCompletedTask.current != null)
            serviceModeschildRefCompletedTask.current.updateEndDateFromParent(event);

        if (serviceModeschildRefTaskOnTime.current != null)
            serviceModeschildRefTaskOnTime.current.updateEndDateFromParent(event);

        if (childRefRatioMotionsMaterialConsumption.current != null)
            childRefRatioMotionsMaterialConsumption.current.updateEndDateFromParent(event);

        if (childRefHundredVisitorHowManyMaterials.current != null)
            childRefHundredVisitorHowManyMaterials.current.updateEndDateFromParent(event);

        if (serviceModeschildRefMotion.current != null)
            serviceModeschildRefMotion.current.updateEndDateFromParent(event);

        if (refBuildTaskDoneChart) {
            refBuildTaskDoneChart.current.updateSimpleDataEnd(event);
        }

        if (refBuildTaskNotDoneChart) {
            refBuildTaskNotDoneChart.current.updateSimpleDataEnd(event);
        }
        updateSimpleData(allServiceMode, allCleaningTasks, startDate, event);
        setEndDate(event);

    }

    const updateStartDateFromParent = (event: any) => {

        if (serviceModeschildRefCleaningTasks.current != null)
            serviceModeschildRefCleaningTasks.current.updateStartDateFromParent(event);

        if (serviceModeschildRefMaterialConsumption.current != null)
            serviceModeschildRefMaterialConsumption.current.updateStartDateFromParent(event);

        if (serviceModeschildRefIncidentReport.current != null)
            serviceModeschildRefIncidentReport.current.updateStartDateFromParent(event);

        if (serviceModeschildRefCompletedTask.current != null)
            serviceModeschildRefCompletedTask.current.updateStartDateFromParent(event);

        if (serviceModeschildRefTaskOnTime.current != null)
            serviceModeschildRefTaskOnTime.current.updateStartDateFromParent(event);

        if (childRefRatioMotionsMaterialConsumption.current != null)
            childRefRatioMotionsMaterialConsumption.current.updateStartDateFromParent(event);

        if (childRefHundredVisitorHowManyMaterials.current != null)
            childRefHundredVisitorHowManyMaterials.current.updateStartDateFromParent(event);

        if (serviceModeschildRefMotion.current != null)
            serviceModeschildRefMotion.current.updateStartDateFromParent(event);

        if (refBuildTaskDoneChart) {
            refBuildTaskDoneChart.current.updateSimpleDataStart(event);
        }

        if (refBuildTaskNotDoneChart) {
            refBuildTaskNotDoneChart.current.updateSimpleDataStart(event);
        }

        updateSimpleData(allServiceMode, allCleaningTasks, event, endDate);
        setStartDate(event);

    }

    const updateDataFilterTypeFromParent = (event: any) => {

        if (serviceModeschildRefCleaningTasks.current != null)
            serviceModeschildRefCleaningTasks.current.updateDataFilterTypeFromParent(event);

        if (serviceModeschildRefMaterialConsumption.current != null)
            serviceModeschildRefMaterialConsumption.current.updateDataFilterTypeFromParent(event);

        if (serviceModeschildRefIncidentReport.current != null)
            serviceModeschildRefIncidentReport.current.updateDataFilterTypeFromParent(event);

        if (serviceModeschildRefCompletedTask.current != null)
            serviceModeschildRefCompletedTask.current.updateDataFilterTypeFromParent(event);

        if (serviceModeschildRefTaskOnTime.current != null)
            serviceModeschildRefTaskOnTime.current.updateDataFilterTypeFromParent(event);

        if (childRefRatioMotionsMaterialConsumption.current != null)
            childRefRatioMotionsMaterialConsumption.current.updateDataFilterTypeFromParent(event);

        if (childRefHundredVisitorHowManyMaterials.current != null)
            childRefHundredVisitorHowManyMaterials.current.updateDataFilterTypeFromParent(event);

        if (serviceModeschildRefMotion.current != null)
            serviceModeschildRefMotion.current.updateDataFilterTypeFromParent(event);

    }

    const BuildPrintContent = () => {

        return (
            <div className="page-size" ref={refPrint}>
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Services Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[serviceModeschildRefCleaningTasksChart, serviceModeschildRefMaterialConsumptionChart, serviceModeschildRefIncidentReportChart, serviceModeschildRefCompletedTaskChart, serviceModeschildRefTaskOnTimeChart, serviceModesserviceModeschildRefMotionChart]}
                            updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                            updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                            updateStartDateFromParent={updateStartDateFromParent}
                            updateEndDateFromParent={updateEndDateFromParent} />
                    </Grid>

                    <Grid item xs={12}>
                        {buildCleaningTasksDashboard()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>

                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <BuildTaskDoneChart ref={refBuildTaskDoneChart} allServiceMode={allServiceMode} allCleaningTasks={allCleaningTasks} loadingCompletedCleaningTasks={loadingCompletedCleaningTasks} />
                    </Grid>
                    <Grid item xs={6}>
                        <BuildTaskNotDoneChart ref={refBuildTaskNotDoneChart} allServiceMode={allServiceMode} allCleaningTasks={allCleaningTasks} loadingCompletedCleaningTasks={loadingCompletedCleaningTasks} />
                    </Grid>
                </Grid>
                <div className="page-break"></div>

                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildMaterialConsumptionDashboard()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupMotion()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildGetGroupRatioMotionsMaterials()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupMotionsMaterialsCount()}
                    </Grid>

                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Services Bericht"} evaluationChoice={evaluationChoice} groupsSettings={[]} />
                    <Grid item xs={12}>
                        {buildIncidentReportDashboard()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildCleaningTasksCompletedDashboard()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildCleaningTasksOnTimeDashboard()}
                    </Grid>
                </Grid>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Services Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const buildSettingServiceTable = () => {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        md={6}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                            >
                                Services Analyse {false ? "(wird geladen)" : ""}
                            </Typography >
                        </Grid>
                        <Grid item>
                            <Typography
                                className="Paper-Result"
                                component="p"
                            >
                                {settingsServices.length}
                            </Typography >
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid xs={12} item container spacing={3}>
                            <Grid item>
                                < DateTimePicker
                                    disableFuture
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                    label={"Start Datum"}
                                    value={startDate}
                                    onChange={(newVal) => {
                                        if (!newVal) return;

                                        updateSimpleData(allServiceMode, allCleaningTasks, newVal, endDate)
                                        //         setSettingsServices(reloadSettingsServices(allServiceMode, allCleaningTasks, newVal, endDate));
                                        setStartDate(newVal);
                                    }}
                                    format={"dd.MM.yyyy HH:mm"}
                                    
                                />
                            </Grid>
                            <Grid item>
                                < DateTimePicker
                                    disableFuture
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                    label={"Ende Datum"}
                                    value={endDate}
                                    onChange={(newVal) => {
                                        if (!newVal) return;

                                        updateSimpleData(allServiceMode, allCleaningTasks, newVal, endDate)
                                        //      setSettingsServices(reloadSettingsServices(allServiceMode, allCleaningTasks, startDate, newVal));
                                        setEndDate(newVal);
                                    }}
                                    format={"dd.MM.yyyy HH:mm"}
                                    
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {loadingCompletedCleaningTasks ?
                                <SettingsServiceTable settingsServices={settingsServices}></SettingsServiceTable>
                                :
                                <CircularProgress color="inherit" />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }



    const buildCleaningTasksDashboard = () => {
        return (
            <CleaningTasksDashboard
                childRefChart={serviceModeschildRefCleaningTasksChart}
                childRef={serviceModeschildRefCleaningTasks}
                setChartData={setserviceModesCleaningTaskData}
                allData={serviceModescleaningTaskAllData}
                data={serviceModescleaningTaskData}
                dataField={serviceModescleaningTaskDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildMaterialConsumptionDashboard = () => {
        return (
            <MaterialConsumptionDashboard
                childRefChart={serviceModeschildRefMaterialConsumptionChart}
                childRef={serviceModeschildRefMaterialConsumption}
                setChartData={setServiceModesMaterialConsumptionData}
                materialConsumptionData={serviceModesMaterialConsumptionData}
                dataField={serviceModesmaterialConsumptionDataFields}
                allData={serviceModesMaterialConsumptionAllData}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupRatioMotionsMaterials = () => {
        return (
            <GetGroupRatioMotionsMaterials
                childRef={childRefRatioMotionsMaterialConsumption}
                setChartData={setserviceModesRatioMotionsMaterialConsumptionData}
                data={serviceModesratioMotionsMaterialConsumptionData}
                dataField={serviceModesratioMotionsMaterialConsumptionDataFields}
                allData={serviceModesratioMotionsMaterialConsumptionAllData}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupMotionsMaterialsCount = () => {
        return (
            <GetGroupMotionsMaterialsCount
                childRef={childRefHundredVisitorHowManyMaterials}
                setChartData={setserviceModesHundredVisitorHowManyMaterialsData}
                data={serviceModeshundredVisitorHowManyMaterialsData}
                dataField={serviceModeshundredVisitorHowManyMaterialsDataFields}
                allData={serviceModeshundredVisitorHowManyMaterialsAllData}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildIncidentReportDashboard = () => {
        return (
            <IncidentReportDashboard
                childRefChart={serviceModeschildRefIncidentReportChart}
                childRef={serviceModeschildRefIncidentReport}
                setChartData={setServiceModesIncidentReportData}
                allData={serviceModesincidentReportAllData}
                incidentReportData={serviceModesincidentReportData}
                dataField={serviceModesincidentReportDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildCleaningTasksCompletedDashboard = () => {
        return (
            <CleaningTasksCompletedDashboard
                childRefChart={serviceModeschildRefCompletedTaskChart}
                childRef={serviceModeschildRefCompletedTask}
                setChartData={setserviceModesCleaningTaskIsCompletedData}
                allData={serviceModescleaningTaskIsCompletedAllData}
                data={serviceModescleaningTaskIsCompletedData}
                dataName1={'Alle'}
                dataName2={"Teilweise"}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildCleaningTasksOnTimeDashboard = () => {
        return (
            <CleaningTasksOnTimeDashboard
                childRefChart={serviceModeschildRefTaskOnTimeChart}
                childRef={serviceModeschildRefTaskOnTime}
                setChartData={setserviceModesCleaningTaskIsOnTimeData}
                allData={serviceModescleaningTaskIsOnTimeAllData}
                data={serviceModescleaningTaskIsOnTimeData}
                dataName1={'Püntklich'}
                dataName2={"Verpasst"}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupMotion = () => {
        return (
            <GroupMotionChart
                childRefChart={serviceModesserviceModeschildRefMotionChart}
                childRef={serviceModeschildRefMotion}
                setChartData={setGroupMotions}
                allData={groupMotionsAll}
                data={groupMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceDurationChart = () => {
        return (
            <ServicesDurationChart
                childRefChart={childRefsetPointActualValueServiceDurationChart}
                childRef={childRefsetPointActualValueServiceDuration}
                setChartData={setServiceModeDurationData}
                allData={serviceModeDurationDataAll}
                data={serviceModeDurationData}
                dataField={serviceModeDurationDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Leistungsdoku - Charts</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[serviceModeschildRefCleaningTasksChart, serviceModeschildRefMaterialConsumptionChart, serviceModeschildRefIncidentReportChart, serviceModeschildRefCompletedTaskChart, serviceModeschildRefTaskOnTimeChart, serviceModesserviceModeschildRefMotionChart]}
                        updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateStartDateFromParent={updateStartDateFromParent}
                        updateEndDateFromParent={updateEndDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    {buildCleaningTasksDashboard()}
                </Grid>

                <Grid item xs={12}>
                    {buildMaterialConsumptionDashboard()}
                </Grid>
                <Grid item xs={12}>
                    {buildSettingServiceTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildServiceDurationChart()}
                </Grid>
                <Grid item xs={12}>
                    <BuildTaskDoneChart ref={refBuildTaskDoneChart} allServiceMode={allServiceMode} allCleaningTasks={allCleaningTasks} loadingCompletedCleaningTasks={loadingCompletedCleaningTasks} />
                </Grid>
                <Grid item xs={12}>
                    <BuildTaskNotDoneChart ref={refBuildTaskNotDoneChart} allServiceMode={allServiceMode} allCleaningTasks={allCleaningTasks} loadingCompletedCleaningTasks={loadingCompletedCleaningTasks} />
                </Grid>
                <Grid item xs={12}>
                    {buildGetGroupMotion()}
                </Grid>
                <Grid item xs={12}>
                    {buildGetGroupRatioMotionsMaterials()}
                </Grid>
                <Grid item xs={12}>
                    {buildGetGroupMotionsMaterialsCount()}
                </Grid>
                <Grid item xs={12}>
                    {buildIncidentReportDashboard()}
                </Grid>
                <Grid item xs={12}>
                    {buildCleaningTasksCompletedDashboard()}
                </Grid>
                <Grid item xs={12}>
                    {buildCleaningTasksOnTimeDashboard()}
                </Grid>
            </Grid>
        );
    }

    return isPrintPage ? BuildPrintContent() : buildGridContent();

}

export const getServicesData = async (token: any, groupId: string, groupIds: string[], selectedObjectId: string, selectedServiceWorkerId: string, showDataServiceMode: any, showserviceModesCleaningTaskData: any, setLoadingProgress?: any, setValueBuffer?: any,) => {
    let motions: GroupMotion[] = []
    let servicesMode: GroupServiceMode[] = []
    let cleaningTasks: CleaningOperation[] = [];

    servicesMode = await getServiceData(groupId, token, groupIds);
    if (setLoadingProgress && setValueBuffer) {
        setLoadingProgress(30);
        setValueBuffer(60);
    }
    showDataServiceMode(servicesMode);


    cleaningTasks = await getSettingsServicesByGroups(groupId, token, groupIds);
    if (setLoadingProgress && setValueBuffer) {
        setLoadingProgress(60);
        setValueBuffer(90);
    }
    showserviceModesCleaningTaskData(servicesMode, cleaningTasks);

    motions = await getMotionData(groupId, token, groupIds);
    if (setLoadingProgress && setValueBuffer) {
        setLoadingProgress(90);
        setValueBuffer(100);
    }

    return [motions, servicesMode, cleaningTasks] as [GroupMotion[], GroupServiceMode[], CleaningOperation[]];
}

export const manageServicesData = async (
    temp: GroupServiceMode[],
    setServiceModesMaterialConsumptionAllData: any,
    setserviceModesMaterialConsumptionDataFields: any,
    setServiceModesMaterialConsumptionData: any,
    setserviceModesIncidentReportAllData: any,
    setServiceModesIncidentReportDataFields: any,
    setServiceModesIncidentReportData: any,
    setserviceModesCleaningTaskAllData: any,
    setserviceModesCleaningTaskDataFields: any,
    setserviceModesCleaningTaskData: any,
    setserviceModesCleaningTaskIsCompletedAllData: any,
    setserviceModesCleaningTaskIsCompletedData: any,
    setserviceModesCleaningTaskIsOnTimeAllData: any,
    setserviceModesCleaningTaskIsOnTimeData: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType,
    fixedId: string | null = null) => {

    if (fixedId) {
        temp = temp.filter(x => x.id == fixedId);
    }
    let periodChartFilter: PeriodChartFilterType = (localStorage.getItem(GLOBAL_PERIOD_CHART_FILTER) || "" + defaultPeriodChartFilterType) as PeriodChartFilterType;


    if (AggregateType.Sum === aggregateType) {
        let allMaterialDataTemp = generateMaterialConsumptionSumChartData(temp);
        setServiceModesMaterialConsumptionAllData(allMaterialDataTemp);
        let allMaterials = getAllMaterial(temp);
        let materialTemp = generateSumChartData(allMaterialDataTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allMaterials);
        setserviceModesMaterialConsumptionDataFields(allMaterials);
        setServiceModesMaterialConsumptionData(materialTemp);

        let allIncidentTemp = generateIncidentReportSumChartData(temp);
        setserviceModesIncidentReportAllData(allIncidentTemp);
        let allIncidents = getAllIncidents(temp)
        let incidentTemp = generateSumChartData(allIncidentTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allIncidents);
        setServiceModesIncidentReportDataFields(allIncidents);
        setServiceModesIncidentReportData(incidentTemp);

        let allCleaningTaskTemp = generateCleaningTaskSumChartData(temp);
        setserviceModesCleaningTaskAllData(allCleaningTaskTemp);
        let allCleaningTasks = getAllCleaningTasks(temp);
        let cleaningTaskTemp = generateSumChartData(allCleaningTaskTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allCleaningTasks);
        setserviceModesCleaningTaskDataFields(allCleaningTasks);
        setserviceModesCleaningTaskData(cleaningTaskTemp);

        let completedCleaningTaskAllTemp = generateCompletedCleaningTaskSumChartData(temp);
        setserviceModesCleaningTaskIsCompletedAllData(completedCleaningTaskAllTemp);
        let allCleaningTasksCompleted = getCompletedCleaningTaskFields;
        let completedCleaningTaskTemp = generateSumChartData(completedCleaningTaskAllTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allCleaningTasksCompleted);
        setserviceModesCleaningTaskIsCompletedData(completedCleaningTaskTemp);

        let cleaningTaskIsOnTimeAllTemp = generateIsOnTimeCleaningTaskSumChartData(temp);
        setserviceModesCleaningTaskIsOnTimeAllData(cleaningTaskIsOnTimeAllTemp);
        let allCleaningTasksIsOnTime = getIsOnTimeCleaningTaskFields;
        let cleaningTaskIsOnTimeTemp = generateSumChartData(cleaningTaskIsOnTimeAllTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allCleaningTasksIsOnTime);
        setserviceModesCleaningTaskIsOnTimeData(cleaningTaskIsOnTimeTemp);
    } else {
        let allMaterialDataTemp = generateMaterialConsumptionChartData(temp);
        setServiceModesMaterialConsumptionAllData(allMaterialDataTemp);
        let allMaterials = getAllMaterial(temp);
        let materialTemp = generateChartData(allMaterialDataTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allMaterials);
        setserviceModesMaterialConsumptionDataFields(allMaterials);
        setServiceModesMaterialConsumptionData(materialTemp);

        let allIncidentTemp = generateIncidentReportChartData(temp);
        setserviceModesIncidentReportAllData(allIncidentTemp);
        let allIncidents = getAllIncidents(temp)
        let incidentTemp = generateChartData(allIncidentTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allIncidents);
        setServiceModesIncidentReportDataFields(allIncidents);
        setServiceModesIncidentReportData(incidentTemp);

        let allCleaningTaskTemp = generateCleaningTaskChartData(temp);
        setserviceModesCleaningTaskAllData(allCleaningTaskTemp);
        let allCleaningTasks = getAllCleaningTasks(temp);
        let cleaningTaskTemp = generateChartData(allCleaningTaskTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allCleaningTasks);
        setserviceModesCleaningTaskDataFields(allCleaningTasks);
        setserviceModesCleaningTaskData(cleaningTaskTemp);

        let completedCleaningTaskAllTemp = generateCompletedCleaningTaskChartData(temp);
        setserviceModesCleaningTaskIsCompletedAllData(completedCleaningTaskAllTemp);
        let allCleaningTasksCompleted = getCompletedCleaningTaskFields;
        let completedCleaningTaskTemp = generateChartData(completedCleaningTaskAllTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allCleaningTasksCompleted);
        setserviceModesCleaningTaskIsCompletedData(completedCleaningTaskTemp);

        let cleaningTaskIsOnTimeAllTemp = generateIsOnTimeCleaningTaskChartData(temp);
        setserviceModesCleaningTaskIsOnTimeAllData(cleaningTaskIsOnTimeAllTemp);
        let allCleaningTasksIsOnTime = getIsOnTimeCleaningTaskFields;
        let cleaningTaskIsOnTimeTemp = generateChartData(cleaningTaskIsOnTimeAllTemp, periodChartFilter, DataFilterType.StartEnd, null, null, allCleaningTasksIsOnTime);
        setserviceModesCleaningTaskIsOnTimeData(cleaningTaskIsOnTimeTemp);
    }

    setLoadingCompleted(true);
}

export const loadTriggeredCleaningDataAsync = async (
    data: GroupCleaningTrigger[],
    setTriggeredCleaningAllTasks: any,
    setTriggerCleaningType: any,
    setTriggeredCleaningTasks: any,
    setLoadingCompleted: any
) => {

    let allCleaningTriggerTypes = getAllCleaningTriggerTypes();
    let cleaningTriggerChartAllData = generateCleaningTriggerSumChartData(data);
    let cleaningTriggerChartData = generateSumChartData(cleaningTriggerChartAllData, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allCleaningTriggerTypes);

    cleaningTriggerChartAllData = mapChartKeyFeedback(cleaningTriggerChartAllData);
    setTriggeredCleaningAllTasks(cleaningTriggerChartAllData);
    setTriggerCleaningType(allCleaningTriggerTypes);
    setTriggeredCleaningTasks(cleaningTriggerChartData);
    setLoadingCompleted(true);
}


export const BuildTaskDoneChart = React.forwardRef(({ allServiceMode, allCleaningTasks, loadingCompletedCleaningTasks }: { allServiceMode: GroupServiceMode[], allCleaningTasks: CleaningOperation[], loadingCompletedCleaningTasks: boolean }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateTaskDoneSimpleChart(startDate: Date, endDate: Date, serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[]) {
            updateTaskDoneSimpleChart(startDate, endDate, serviceMode, cleaningTasks);
        },
        updateSimpleDataStart(date: Date) {
            setStartDate(date);
            updateSimpleData(allServiceMode, allCleaningTasks, date, endDate);
        },
        updateSimpleDataEnd(date: Date) {
            setEndDate(date);
            updateSimpleData(allServiceMode, allCleaningTasks, startDate, date);
        }

    }));

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);
    const [expandedPanel, setExpandedPanel] = React.useState(true);
    const [simpleChatDoneTasks, setSimpleChartDoneTasks] = React.useState<any[]>([]);
    const [simpleChartDoneTasksAll, setSimpleChartDoneTasksAll] = React.useState<any[]>([]);
    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());

    React.useEffect(() => {
        updateTaskDoneSimpleChart(startDate, endDate, allServiceMode, allCleaningTasks);

    }, [maxChartItemCount])

    const updateTaskDoneSimpleChart = (startDate: Date, endDate: Date, serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[]) => {

        if (startDate && endDate) {
            serviceMode = serviceMode.filter(x => {
                let curDate = new Date(x.createdTimestamp * 1000);
                return isAfter(curDate, startDate) && isBefore(curDate, endDate);
            });
        }

        let res = cleaningTasks.reduce((prevVal, curVal: CleaningOperation) => {

            let sum = serviceMode.reduce((prevVal2, curVal2) => {
                if (curVal2.cleaningTaskItems.includes(curVal.text)) {
                    return prevVal2 + 1;
                }
                return prevVal2;
            }, 0);
            return [...prevVal, { argumentFieldService: curVal.text, valueFieldService: sum, countService: sum }];
            //                return [...prevVal, { argumentFieldService: curVal.text, valueFieldService: sum, countService: cleaningTasks.length }];

        }, [] as any[]);
        let resNormal = res.filter(x => x.valueFieldService > 0).sort((a, b) => a.valueFieldService - b.valueFieldService);
        setSimpleChartDoneTasks(resNormal.slice(-maxChartItemCount));
        setSimpleChartDoneTasksAll(resNormal);
    }

    const updateSimpleData = (serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[], beginDate: Date, endDate: Date) => {
        updateTaskDoneSimpleChart(beginDate, endDate, serviceMode, cleaningTasks);
    }


    return (
        <Accordion expanded={expandedPanel}>
            <AccordionSummary onClick={() => setExpandedPanel(!expandedPanel)} expandIcon={<ExpandMore />}>
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="flex-start"
                    md={6}
                >
                    <Grid item>
                        <Typography
                            variant="body1"
                            component="p"
                            color="textSecondary"
                        >
                            Services durchgeführt {false ? "(wird geladen)" : ""}
                        </Typography >
                    </Grid>
                    <Grid item>
                        <Typography
                            className="Paper-Result"
                            component="p"
                        >
                            {simpleChartDoneTasksAll.length}
                        </Typography >
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item xs={12} container direction={"row"} spacing={3}>
                    <Grid xs={12} item container spacing={3}>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Start Datum"}
                                value={startDate}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    //                    let temp = reloadServiceCount(onlineStatusTableData, newVal, endDate);
                                    //                    setGroupsOnlineStatusCountTable(temp);
                                    updateSimpleData(allServiceMode, allCleaningTasks, newVal, endDate)
                                    //            updateTaskDoneSimpleChart(newVal, endDate, allServiceMode, allCleaningTasks);
                                    setStartDate(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Ende Datum"}
                                value={endDate}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    //             let temp = reloadServiceCount(onlineStatusTableData, startDate, newVal);
                                    //             setGroupsOnlineStatusCountTable(temp);
                                    //                 updateTaskDoneSimpleChart(startDate, newVal, allServiceMode, allCleaningTasks);
                                    updateSimpleData(allServiceMode, allCleaningTasks, newVal, endDate)

                                    setEndDate(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Services erledigt Bericht</Typography>
                        {loadingCompletedCleaningTasks ?
                            <Grid item>
                                <StandardBarChartSimpleColor color={blue[600]} dataSource={simpleChatDoneTasks} name={"Services, die erledigt wurden"} height={chartHeightOnlineStatusSimple} />
                            </Grid>
                            :
                            <CircularProgress color="inherit" />
                        }
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
});

export const BuildTaskNotDoneChart = React.forwardRef(({ allServiceMode, allCleaningTasks, loadingCompletedCleaningTasks }: { allServiceMode: GroupServiceMode[], allCleaningTasks: CleaningOperation[], loadingCompletedCleaningTasks: boolean }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateTaskNotDoneSimpleChart(startDate: Date, endDate: Date, serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[]) {
            updateTaskNotDoneSimpleChart(startDate, endDate, serviceMode, cleaningTasks);
        },
        updateSimpleDataStart(date: Date) {
            setStartDate(date);
            updateSimpleData(allServiceMode, allCleaningTasks, date, endDate);
        },
        updateSimpleDataEnd(date: Date) {
            setEndDate(date);
            updateSimpleData(allServiceMode, allCleaningTasks, startDate, date);
        }

    }));
    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [expandedPanel, setExpandedPanel] = React.useState(true);
    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);
    const [simpleChatNotDoneTasks, setSimpleChartNotDoneTasks] = React.useState<any[]>([]);
    const [simpleChatNotDoneTasksAll, setSimpleChartNotDoneTasksAll] = React.useState<any[]>([]);
    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());

    const updateSimpleData = (serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[], beginDate: Date, endDate: Date) => {
        updateTaskNotDoneSimpleChart(beginDate, endDate, serviceMode, cleaningTasks);
    }

    React.useEffect(() => {
        updateTaskNotDoneSimpleChart(startDate, endDate, allServiceMode, allCleaningTasks);

    }, [maxChartItemCount]);


    const updateTaskNotDoneSimpleChart = (startDate: Date, endDate: Date, serviceMode: GroupServiceMode[], cleaningTasks: CleaningOperation[]) => {

        if (startDate && endDate) {
            serviceMode = serviceMode.filter(x => {
                let curDate = new Date(x.createdTimestamp * 1000);
                return isAfter(curDate, startDate) && isBefore(curDate, endDate);
            });
        }

        let res = cleaningTasks.reduce((prevVal, curVal: CleaningOperation) => {

            if (!serviceMode.some(x => x.cleaningTaskItems.includes(curVal.text))) {
                return [...prevVal, { argumentFieldService: curVal.text, valueFieldService: 1, countService: 1 }];
            }
            return prevVal;
        }, [] as any[]);
        let resNormal = res.sort((a, b) => a.valueFieldService - b.valueFieldService);
        setSimpleChartNotDoneTasks(resNormal.slice(-maxChartItemCount));
        setSimpleChartNotDoneTasksAll(resNormal);
    }

    return (
        <Accordion expanded={expandedPanel}>
            <AccordionSummary onClick={() => setExpandedPanel(!expandedPanel)} expandIcon={<ExpandMore />}>
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="flex-start"
                    md={6}
                >
                    <Grid item>
                        <Typography
                            variant="body1"
                            component="p"
                            color="textSecondary"
                        >
                            Services nicht durchgeführt {false ? "(wird geladen)" : ""}
                        </Typography >
                    </Grid>
                    <Grid item>
                        <Typography
                            className="Paper-Result"
                            component="p"
                        >
                            {simpleChatNotDoneTasksAll.length}
                        </Typography >
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item xs={12} container direction={"row"} spacing={3}>
                    <Grid xs={12} item container spacing={3}>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Start Datum"}
                                value={startDate}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    //                    let temp = reloadServiceCount(onlineStatusTableData, newVal, endDate);
                                    //                    setGroupsOnlineStatusCountTable(temp);
                                    updateSimpleData(allServiceMode, allCleaningTasks, newVal, endDate)
                                    //                updateTaskNotDoneSimpleChart(newVal, endDate, allServiceMode, allCleaningTasks);
                                    setStartDate(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Ende Datum"}
                                value={endDate}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    //             let temp = reloadServiceCount(onlineStatusTableData, startDate, newVal);
                                    //             setGroupsOnlineStatusCountTable(temp);
                                    updateSimpleData(allServiceMode, allCleaningTasks, newVal, endDate)
                                    //          updateTaskNotDoneSimpleChart(startDate, newVal, allServiceMode, allCleaningTasks);
                                    setEndDate(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Services nicht erledigt Bericht</Typography>
                        {loadingCompletedCleaningTasks ?
                            <Grid item>
                                <StandardBarChartSimpleColor dataSource={simpleChatNotDoneTasks} name={"Services, die nicht erledigt wurden"} height={chartHeightOnlineStatusSimple} />
                            </Grid>
                            :
                            <CircularProgress color="inherit" />
                        }
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
})