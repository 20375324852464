import { ArrowCircleUp, ExpandCircleDown, ExpandMore, Height, Print } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Divider, FormControlLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { Fragment, useContext, useImperativeHandle, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../../api";
import { GREEN_LIGHT_SMILE, GREEN_SMILE, MotionSensorSvgWhite, RED_LIGHT_SMILE, RED_SMILE, YELLOW_SMILE } from "../../../../../assets";
import { getDataFromGroupIdsTrigger, generateChartData, PeriodChartFilterType, DataFilterType, APIDataAmountType, getQualityChecksByUserIdGroupIds } from "../../../../../helpers/charts/base";
import { getAllCleaningTriggerTypes, generateCleaningTriggerChartData } from "../../../../../helpers/charts/cleaningTriggerGenerator";
import { generateIncidentReportChartDataByObjectLeaderQualityChecks, getAllIncidentsByObjectLeaderQualityChecks, generateServicesChartDataByObjectLeaderQualityChecks, getAllServicesByObjectLeaderQualityChecks, generateMaterialsChartDataByObjectLeaderQualityChecks, getAllMaterialsByObjectLeaderQualityChecks, getAllAverageServicesByObjectLeaderQualityChecks, generateAverageServicesChartDataByObjectLeaderQualityChecks, generateAverageMaterialsChartDataByObjectLeaderQualityChecks, getAllAverageMaterialsByObjectLeaderQualityChecks, generateAverageGroupQualityChecksChartDataByObjectLeader, generateAverageMaterialsGroupQualityChecksChartDataByObjectLeader, generateAverageGroupCombinedQualityChecksChartDataByObjectLeader, generateAverageGroupCombinedMaterialsQualityChecksChartDataByObjectLeader, generateAverageGroupCombinedQualityChecksTableDataByObjectLeader, getValueFromMarkedService } from "../../../../../helpers/charts/objectLeader/dataGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../../helpers/common";
import { getApiToken } from "../../../../../helpers/storage-management";
import { StringHelper } from "../../../../../helpers/string-helper";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable, GroupLicenseTableServiceAverage } from "../../../../../models/GroupLicenseTable";
import { FeedbackDisplayType, GroupSettings, LocationInformation } from "../../../../../models/GroupSettings";
import { ObjectLeaderQualityCheckModel } from "../../../../../models/ObjectLeaderQualityCheckModel";
import { GroupTypePrintDisplay } from "../../../../common/GroupTypePrintDisplay";
import { ChartHeightTextBox, GlobalTimeChoice } from "../../../../core/GlobalTimeChoice";
import { ObjectLeaderIncidentReportChart } from "../../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderIncidentReportChart";
import { ObjectLeaderQualityCheckAverageMaterialsChart } from "../../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageMaterialsChart";
import { ObjectLeaderQualityCheckAverageServicesAllGroupsCombined } from "../../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageServicesAllGroupsCombinedChart";
import { ObjectLeaderQualityCheckAverageServicesChart } from "../../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageServicesChart";
import { ObjectLeaderQualityCheckMaterialChart } from "../../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckMaterialChart";
import { ObjectLeaderQualityCheckServicesChart } from "../../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckServicesChart";
import { ObjectLeaderQualityCheckAverageTable } from "../../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageTable";
import { ObjectLeaderQualityCheckTable } from "../../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckTable";
import { GroupDetailsPrintTable } from "../../../../tables/GroupDetailsPrintTable";
import { TriggeredCleaningTask } from "./TriggeredCleaningTask";
import { useReactToPrint } from "react-to-print";
import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import { StandardBarChartSimpleColorFeed } from "./StandardBarChartSimpleColorFeed";
import { defaultEndDate, defaultStartDate, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../../helpers/date-management";
import { useQuery } from "../../checkout/ShopPage";
import { MaxChartItemCountInput } from "../../../../../helpers/compontents";
import { GlobalContext } from "../../../PortalPage";
import useNoInitialEffect from "../../../../../helpers/extensions";
import { EvalApiType } from "../Services";
import { DrawerMenuItemDisplayType } from "../../../ObjectMenuPointDrawer";
import { HitListChartsQualityChecksAvgMarking, HitListChartsQualityChecksBestServices, HitListChartsQualityChecksCountMarking } from "./HitListChartsQualityChecks";
import { UserServiceWorker } from "../../../../../models/UserServiceWorker";

export const QualityChecksDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {

    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const [serviceWorkers, setServiceWorkers] = React.useState<UserServiceWorker[]>([]);


    const [groupsLicenseTableEntriesAverage, setGroupsLicenseTableEntriesAverage] = React.useState<GroupLicenseTableServiceAverage[]>([]);

    const refPrintAverageSmileTable: any = useRef();
    const history = useHistory();

    const queryString = useQuery(history);
    const doPrint = queryString.get("print");
    const fixedId = queryString.get("qualityCheckId");

    const handlePrint = useReactToPrint({

        content: () => refPrintAverageSmileTable.current,
        pageStyle: `@page {
            size: auto;
            margin: 10mm;
          }`,
        onAfterPrint: () => {

        }

    });

    const { maxChartItemCount, setIsPreFilterDialogVisible }: { maxChartItemCount: number, setIsPreFilterDialogVisible: any } = useContext(GlobalContext);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);

    const [objectLeaderIncidentReportData, setObjectLeaderIncidentReportData] = React.useState<any>([]);
    const [objectLeaderIncidentReportAllData, setObjectLeaderIncidentReportAllData] = React.useState<any>([]);
    const [objectLeaderIncidentReportDataFields, setObjectLeaderIncidentReportDataFields] = React.useState<any>([]);

    const [objectLeaderServicesData, setObjectLeaderServicesData] = React.useState<any>([]);
    const [objectLeaderServicesAllData, setObjectLeaderServicesAllData] = React.useState<any>([]);
    const [objectLeaderServicesDataFields, setObjectLeaderServicesDataFields] = React.useState<any>([]);

    const [objectLeaderAverageServicesData, setObjectLeaderAverageServicesData] = React.useState<any>([]);
    const [objectLeaderAverageServicesAllData, setObjectLeaderAverageServicesAllData] = React.useState<any>([]);
    const [objectLeaderAverageServicesDataFields, setObjectLeaderAverageServicesDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsServiceData, setObjectLeaderAverageGroupsServiceData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsServiceAllData, setObjectLeaderAverageGroupsServiceAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsServiceDataFields, setObjectLeaderAverageGroupsServiceDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsCombinedServiceData, setObjectLeaderAverageGroupsCombinedServiceData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedServiceAllData, setObjectLeaderAverageGroupsCombinedServiceAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedServiceDataFields, setObjectLeaderAverageGroupsCombinedServiceDataFields] = React.useState<any>([]);

    const [objectLeaderMaterialsData, setObjectLeaderMaterialsData] = React.useState<any>([]);
    const [objectLeadermaterialsAllData, setObjectLeaderMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderMaterialsDataFields, setObjectLeaderMaterialsDataFields] = React.useState<any>([]);

    const [objectLeaderAverageMaterialsData, setObjectLeaderAverageMaterialsData] = React.useState<any>([]);
    const [objectLeaderAverageMaterialsAllData, setObjectLeaderAverageMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderAverageMaterialsDataFields, setObjectLeaderAverageMaterialsDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsMaterialsData, setObjectLeaderAverageGroupsMaterialsData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsMaterialsAllData, setObjectLeaderAverageGroupsMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsMaterialsDataFields, setObjectLeaderAverageGroupsMaterialsDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsCombinedMaterialsData, setObjectLeaderAverageGroupsCombinedMaterialsData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedMaterialsAllData, setObjectLeaderAverageGroupsCombinedMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedMaterialsDataFields, setObjectLeaderAverageGroupsCombinedMaterialsDataFields] = React.useState<any>([]);

    const childRefObjectLeaderIncidents: any = useRef();
    const childRefObjectLeaderIncidentsChart: any = useRef();
    const [loadingCompleted, setLoadingCompleted] = React.useState(true);

    const childRefObjectLeaderServices: any = useRef();
    const childRefObjectLeaderServicesChart: any = useRef();

    const childRefObjectLeaderAverageServices: any = useRef();
    const childRefObjectLeaderAverageServicesChart: any = useRef();

    const childRefObjectLeaderAverageGroupsServices: any = useRef();
    const childRefObjectLeaderAverageGroupsServicesChart: any = useRef();

    const childRefObjectLeaderAverageGroupsCombinedServices: any = useRef();
    const childRefObjectLeaderAverageGroupsCombinedServicesChart: any = useRef();

    const childRefObjectLeaderMaterials: any = useRef();
    const childRefObjectLeaderMaterialsChart: any = useRef();

    const childRefObjectLeaderAverageMaterials: any = useRef();
    const childRefObjectLeaderAverageMaterialsChart: any = useRef();

    const childRefObjectLeaderAverageGroupsMaterials: any = useRef();
    const childRefObjectLeaderAverageGroupsMaterialsChart: any = useRef();

    const childRefObjectLeaderAverageGroupsCombinedMaterials: any = useRef();
    const childRefObjectLeaderAverageGroupsCombinedMaterialsChart: any = useRef();

    const { groupId } = useParams<{ groupId: string }>();

    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);

    const [allQualityChecks, setAllQualityChecks] = React.useState<ObjectLeaderQualityCheckModel[]>([]);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const [startDateTableAverage, setStartDateTableAverage] = React.useState(subDays(new Date(), 10));

    const [endDateTableAverage, setEndDateTableAverage] = React.useState(new Date());
    const refAverageServicesSimpleChart = useRef<any>();
    const refAverageGroupsSimpleChart = useRef<any>();

    const loadDataAsync = async () => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        setEndDateTableAverage(endDate);
        let startDate = getStartDateFromUrlOrStorage(queryString);

        const token = getApiToken(history);
        if (!token) return;
        setLoadingCompleted(false);
        let groupsPortal: GroupLicenseTable[] = [];
        if (serviceDisplayType == EvalApiType.Objects) {
            groupsPortal = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groupsPortal.map(x => x.id);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        if (serviceDisplayType == EvalApiType.Groups) {
            groupsPortal = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }


        let qualityChecks = await getQualityChecksByUserIdGroupIds(groupId, token, groupIds)

        if (fixedId) qualityChecks = qualityChecks.filter(x => x.id == fixedId);
        setAllQualityChecks(qualityChecks);


        let workers = await api.getServiceWorkers(token);
        setServiceWorkers(workers);

        let allIncidentTemp = generateIncidentReportChartDataByObjectLeaderQualityChecks(qualityChecks);
        setObjectLeaderIncidentReportAllData(allIncidentTemp);
        let allIncidents = getAllIncidentsByObjectLeaderQualityChecks(qualityChecks)
        let incidentTemp = generateChartData(allIncidentTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allIncidents);
        setObjectLeaderIncidentReportDataFields(allIncidents);
        setObjectLeaderIncidentReportData(incidentTemp);

        let allServicesTemp = generateServicesChartDataByObjectLeaderQualityChecks(qualityChecks);
        setObjectLeaderServicesAllData(allServicesTemp);
        let allServicesBottomRow = getAllServicesByObjectLeaderQualityChecks(qualityChecks)
        let serviceTemp = generateChartData(allServicesTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allServicesBottomRow);
        setObjectLeaderServicesDataFields(allServicesBottomRow);
        setObjectLeaderServicesData(serviceTemp);


        let allAverageServicesBottomRow = getAllAverageServicesByObjectLeaderQualityChecks(qualityChecks)
        let allAverageServicesTemp = generateAverageServicesChartDataByObjectLeaderQualityChecks(qualityChecks, allAverageServicesBottomRow);
        setObjectLeaderAverageServicesAllData(allAverageServicesTemp);
        setObjectLeaderAverageServicesDataFields(allAverageServicesBottomRow);

        let allMaterialsTemp = generateMaterialsChartDataByObjectLeaderQualityChecks(qualityChecks);
        setObjectLeaderMaterialsAllData(allMaterialsTemp);
        let allMaterialsBottomRow = getAllMaterialsByObjectLeaderQualityChecks(qualityChecks)
        let materialsTemp = generateChartData(allMaterialsTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allMaterialsBottomRow);
        setObjectLeaderMaterialsDataFields(allMaterialsBottomRow);
        setObjectLeaderMaterialsData(materialsTemp);

        let allAverageMaterialsBottomRow = getAllAverageMaterialsByObjectLeaderQualityChecks(qualityChecks)
        let allAverageMaterialsTemp = generateAverageMaterialsChartDataByObjectLeaderQualityChecks(qualityChecks, allAverageMaterialsBottomRow);
        setObjectLeaderAverageMaterialsAllData(allAverageMaterialsTemp);
        setObjectLeaderAverageMaterialsDataFields(allAverageMaterialsBottomRow);


        let allAverageGroupsBottomRow = groupsPortal.filter(x => qualityChecks?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(x => x.notificationName);
        let allAverageGroupsServicesTemp = generateAverageGroupQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
        setObjectLeaderAverageGroupsServiceAllData(allAverageGroupsServicesTemp);
        setObjectLeaderAverageGroupsServiceDataFields(allAverageGroupsBottomRow);

        let allAverageGroupsCombinedServicesTemp = generateAverageGroupCombinedQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
        setObjectLeaderAverageGroupsCombinedServiceAllData(allAverageGroupsCombinedServicesTemp);
        setObjectLeaderAverageGroupsCombinedServiceDataFields(["Gesamt"]);
        setObjectLeaderAverageGroupsCombinedServiceData(generateChartData(allAverageGroupsCombinedServicesTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, startDate, endDate, ["Gesamt"]));
        //    if (childRefAverageGroupsCombinedServices.current) childRefAverageGroupsCombinedServices.current.renderData();

        let allAverageGroupsMaterialsTemp = generateAverageMaterialsGroupQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
        setObjectLeaderAverageGroupsMaterialsAllData(allAverageGroupsMaterialsTemp);
        setObjectLeaderAverageGroupsMaterialsDataFields(allAverageGroupsBottomRow);

        let allAverageGroupsCombinedMaterialsTemp = generateAverageGroupCombinedMaterialsQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
        setObjectLeaderAverageGroupsCombinedMaterialsAllData(allAverageGroupsCombinedMaterialsTemp);
        setObjectLeaderAverageGroupsCombinedMaterialsDataFields(["Gesamt"]);


        let myqualityChecks = qualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let currentGroupsPortal = groupsPortal.filter(x => myqualityChecks?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName));
        let averageQualityChecksTable = generateAverageGroupCombinedQualityChecksTableDataByObjectLeader(myqualityChecks, currentGroupsPortal, allAverageServicesBottomRow, allAverageMaterialsBottomRow);
        setAccordionExpandable(averageQualityChecksTable);
        setLoadingCompleted(true);

        if (JSON.parse(doPrint || 'false')) {
            if (globalTimeChoiceChildEvents.doPrintingProcess) {
                await globalTimeChoiceChildEvents.doPrintingProcess(token);

            }
        }

    }

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);


    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);

        if (allQualityChecks) {
            setStartDateTableAverage(startDate);
            setEndDateTableAverage(endDate);
            updateTableAverage(startDate, endDate, allQualityChecks);


        }
    }, [allQualityChecks])

    const [hiddenColumnNames, setHiddenColumnNames] = React.useState([GroupLicenseTable.Columns.toiletType, GroupLicenseTableServiceAverage.ColumnsExtended.material]);
    const isMaterialShown = () => {
        return hiddenColumnNames.includes(GroupLicenseTableServiceAverage.ColumnsExtended.material);
    }
    const isServicesShown = () => {
        return hiddenColumnNames.includes(GroupLicenseTableServiceAverage.ColumnsExtended.service);
    }



    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
    }, [apiDataAmountType, reloadDataFromApi]);

    const updateChosenPastDaysFromParent = (event: any) => {
        childRefObjectLeaderServices.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageGroupsServices.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageGroupsCombinedServices.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderMaterials.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageMaterials.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageGroupsMaterials.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageGroupsCombinedMaterials.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderIncidents.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageServices.current.updateChosenPastDays(event.target.value);
        childRefObjectLeaderAverageGroupsServices.current.updateChosenPastDays(event.target.value);

    }

    const updateEndDateFromParent = (event: any) => {
        if (childRefObjectLeaderServices.current) childRefObjectLeaderServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedServices.current) childRefObjectLeaderAverageGroupsCombinedServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderMaterials.current) childRefObjectLeaderMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageMaterials.current) childRefObjectLeaderAverageMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsMaterials.current) childRefObjectLeaderAverageGroupsMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedMaterials.current) childRefObjectLeaderAverageGroupsCombinedMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderIncidents.current) childRefObjectLeaderIncidents.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageServices.current) childRefObjectLeaderAverageServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateEndDateFromParent(event);

        if (refAverageServicesSimpleChart.current) {
            refAverageServicesSimpleChart.current.updateEndDateAverageServicesSimpleChartData(event);
        }
        if (refAverageGroupsSimpleChart.current) {
            refAverageGroupsSimpleChart.current.updateEndDateAverageGroupsSimpleChartData(event);
        }
        if (allQualityChecks) {
            setEndDateTableAverage(event);
            updateTableAverage(startDateTableAverage, event, allQualityChecks);
        }

    }

    const updateStartDateFromParent = (event: any) => {
        if (childRefObjectLeaderServices.current) childRefObjectLeaderServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedServices.current) childRefObjectLeaderAverageGroupsCombinedServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderMaterials.current) childRefObjectLeaderMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageMaterials.current) childRefObjectLeaderAverageMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsMaterials.current) childRefObjectLeaderAverageGroupsMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedMaterials.current) childRefObjectLeaderAverageGroupsCombinedMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderIncidents.current) childRefObjectLeaderIncidents.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageServices.current) childRefObjectLeaderAverageServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateStartDateFromParent(event);


        if (refAverageServicesSimpleChart.current) {
            refAverageServicesSimpleChart.current.updateStartDateAverageServicesSimpleChartData(event);
        }
        if (refAverageGroupsSimpleChart.current) {
            refAverageGroupsSimpleChart.current.updateStartDateAverageGroupsSimpleChartData(event);
        }
        if (allQualityChecks) {
            setStartDateTableAverage(event);
            updateTableAverage(event, endDateTableAverage, allQualityChecks);
        }


    }

    const updateDataFilterTypeFromParent = (event: any) => {
        if (childRefObjectLeaderServices?.current)
            childRefObjectLeaderServices.current.updateDataFilterTypeFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices?.current) {
            childRefObjectLeaderAverageGroupsServices.current.updateDataFilterTypeFromParent(event);
        }
        if (childRefObjectLeaderAverageGroupsCombinedServices?.current) {
            childRefObjectLeaderAverageGroupsCombinedServices.current.updateDataFilterTypeFromParent(event);
        }
        if (childRefObjectLeaderMaterials?.current)
            childRefObjectLeaderMaterials.current.updateDataFilterTypeFromParent(event);
        if (childRefObjectLeaderAverageMaterials?.current) {
            childRefObjectLeaderAverageMaterials.current.updateDataFilterTypeFromParent(event);
        }
        if (childRefObjectLeaderAverageGroupsMaterials?.current) {
            childRefObjectLeaderAverageGroupsMaterials.current.updateDataFilterTypeFromParent(event);
        }
        if (childRefObjectLeaderAverageGroupsCombinedMaterials?.current) {
            childRefObjectLeaderAverageGroupsCombinedMaterials.current.updateDataFilterTypeFromParent(event);
        }
        if (childRefObjectLeaderIncidents?.current)
            childRefObjectLeaderIncidents.current.updateDataFilterTypeFromParent(event);
        if (childRefObjectLeaderAverageServices?.current)
            childRefObjectLeaderAverageServices.current.updateDataFilterTypeFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices?.current)
            childRefObjectLeaderAverageGroupsServices.current.updateDataFilterTypeFromParent(event);

    }

    const buildObjectLeaderQualityCheckServicesChart = () => {
        return (
            <ObjectLeaderQualityCheckServicesChart
                servicesData={objectLeaderServicesData}
                dataField={objectLeaderServicesDataFields}
                allData={objectLeaderServicesAllData}
                setChartData={setObjectLeaderServicesData}
                childRefChart={childRefObjectLeaderServicesChart}
                childRef={childRefObjectLeaderServices}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildObjectLeaderQualityCheckMaterialChart = () => {
        return (
            <ObjectLeaderQualityCheckMaterialChart
                materialData={objectLeaderMaterialsData}
                dataField={objectLeaderMaterialsDataFields}
                allData={objectLeadermaterialsAllData}
                setChartData={setObjectLeaderMaterialsData}
                childRefChart={childRefObjectLeaderMaterialsChart}
                childRef={childRefObjectLeaderMaterials}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildObjectLeaderIncidentReportChart = () => {
        return (
            <ObjectLeaderIncidentReportChart
                incidentReportData={objectLeaderIncidentReportData}
                dataField={objectLeaderIncidentReportDataFields}
                allData={objectLeaderIncidentReportAllData}
                setChartData={setObjectLeaderIncidentReportData}
                childRefChart={childRefObjectLeaderIncidentsChart}
                childRef={childRefObjectLeaderIncidents}
                loadingCompleted={loadingCompleted}
            />
        )
    }




    const buildObjectLeaderQualityCheckAverageMaterialsChart = () => {
        return (<Grid item xs={12}>
            <ObjectLeaderQualityCheckAverageMaterialsChart
                materialsData={objectLeaderAverageMaterialsData}
                dataField={objectLeaderAverageMaterialsDataFields}
                allData={objectLeaderAverageMaterialsAllData}
                setChartData={setObjectLeaderAverageMaterialsData}
                childRefChart={childRefObjectLeaderAverageMaterialsChart}
                childRef={childRefObjectLeaderAverageMaterials}
                loadingCompleted={loadingCompleted}
            />
        </Grid>)
    }
    const buildObjectLeaderQualityCheckAverageServicesChart = () => {
        return (<Grid item xs={12}>
            <ObjectLeaderQualityCheckAverageServicesChart
                servicesData={objectLeaderAverageServicesData}
                dataField={objectLeaderAverageServicesDataFields}
                allData={objectLeaderAverageServicesAllData}
                setChartData={setObjectLeaderAverageServicesData}
                childRefChart={childRefObjectLeaderAverageServicesChart}
                childRef={childRefObjectLeaderAverageServices}
                loadingCompleted={loadingCompleted}
            />
        </Grid>)
    }

    const buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesAllGroupsCombined
                    servicesData={objectLeaderAverageGroupsCombinedServiceData}
                    dataField={objectLeaderAverageGroupsCombinedServiceDataFields}
                    allData={objectLeaderAverageGroupsCombinedServiceAllData}
                    setChartData={setObjectLeaderAverageGroupsCombinedServiceData}
                    childRefChart={childRefObjectLeaderAverageGroupsCombinedServicesChart}
                    childRef={childRefObjectLeaderAverageGroupsCombinedServices}
                    loadingCompleted={loadingCompleted}
                    title={"Bewertungsverlauf Alle Einheiten Zusammen: Services"}
                />
            </Grid>
        )
    }

    const buildObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesAllGroupsCombined
                    servicesData={objectLeaderAverageGroupsCombinedMaterialsData}
                    dataField={objectLeaderAverageGroupsCombinedMaterialsDataFields}
                    allData={objectLeaderAverageGroupsCombinedMaterialsAllData}
                    setChartData={setObjectLeaderAverageGroupsCombinedMaterialsData}
                    childRefChart={childRefObjectLeaderAverageGroupsCombinedMaterialsChart}
                    childRef={childRefObjectLeaderAverageGroupsCombinedMaterials}
                    loadingCompleted={loadingCompleted}
                    title={"Bewertungsverlauf Alle Einheiten Zusammen: Materialverbrauch"}
                />
            </Grid>
        )
    }

    const buildObjectLeaderQualityCheckAverageServicesGroupsChart = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesChart
                    servicesData={objectLeaderAverageGroupsServiceData}
                    dataField={objectLeaderAverageGroupsServiceDataFields}
                    allData={objectLeaderAverageGroupsServiceAllData}
                    setChartData={setObjectLeaderAverageGroupsServiceData}
                    childRefChart={childRefObjectLeaderAverageGroupsServicesChart}
                    childRef={childRefObjectLeaderAverageGroupsServices}
                    loadingCompleted={loadingCompleted}
                    title={"Einheiten Gesamt Bewertung Services"}
                />
            </Grid>
        )
    }

    const buildObjectLeaderQualityCheckAverageMaterialsGroupsChart = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesChart
                    servicesData={objectLeaderAverageGroupsMaterialsData}
                    dataField={objectLeaderAverageGroupsMaterialsDataFields}
                    allData={objectLeaderAverageGroupsMaterialsAllData}
                    setChartData={setObjectLeaderAverageGroupsMaterialsData}
                    childRefChart={childRefObjectLeaderAverageGroupsMaterialsChart}
                    childRef={childRefObjectLeaderAverageGroupsMaterials}
                    loadingCompleted={loadingCompleted}
                    title={"Einheiten Gesamt Bewertung Materialverbrauch"}
                />
            </Grid>
        )
    }

    const [visibleChartsType, setVisibleTypeCharts] = React.useState(QualityCheckPrintType.Services);

    const buldPrintCharts = () => {
        switch (visibleChartsType) {
            case QualityCheckPrintType.Services:
                return (
                    <React.Fragment>

                        <Grid container spacing={2} >
                            <AverageServicesSimpleChart ref={refAverageServicesSimpleChart} allQualityChecks={allQualityChecks} loadingCompleted={loadingCompleted} />
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={2}>
                            {buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined()}
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={2}>
                            <AverageGroupsSimpleChart ref={refAverageGroupsSimpleChart} allQualityChecks={allQualityChecks} loadingCompleted={loadingCompleted} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                        </Grid>
                    </React.Fragment>
                )
            case QualityCheckPrintType.Material:
                return (
                    <React.Fragment>
                        <Grid container spacing={2} >
                            {buildObjectLeaderQualityCheckAverageMaterialsChart()}
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={2}>
                            {buildObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined()}
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={2}>
                            {buildObjectLeaderQualityCheckAverageMaterialsGroupsChart()}
                        </Grid>
                    </React.Fragment>
                )
            case QualityCheckPrintType.ServicesMaterial:
                return (
                    <React.Fragment>
                        <Grid container spacing={2} >
                            {buildObjectLeaderQualityCheckAverageServicesChart()}
                            {buildObjectLeaderQualityCheckAverageMaterialsChart()}
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={2}>
                            {buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined()}
                            {buildObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined()}
                        </Grid>
                        <div className="page-break"></div>
                        <Grid container spacing={2}>
                            {buildObjectLeaderQualityCheckAverageServicesGroupsChart()}
                            {buildObjectLeaderQualityCheckAverageMaterialsGroupsChart()}
                        </Grid>
                    </React.Fragment>
                )
        }
    }
    const globalTimeChoiceChildEvents = { doPrintingProcess: (token: string) => { } };
    const globalTimeChoiceChildEventsPrintTemplate = { doPrintingProcess: (token: string) => { } };

    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Qualitätsmanagement Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefObjectLeaderServicesChart, childRefObjectLeaderAverageServicesChart, childRefObjectLeaderAverageGroupsServicesChart, childRefObjectLeaderAverageGroupsCombinedServicesChart, childRefObjectLeaderMaterialsChart, childRefObjectLeaderAverageMaterialsChart, childRefObjectLeaderAverageGroupsMaterialsChart, childRefObjectLeaderAverageGroupsCombinedMaterialsChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{ padding: 10 }}>
                            <Typography>Diagramme Anzeige</Typography>
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="contentCode"
                                value={visibleChartsType}
                                onChange={(event: any) => {
                                    setVisibleTypeCharts(parseInt(event.target.value))
                                }}
                            >
                                <FormControlLabel
                                    value={QualityCheckPrintType.Services}
                                    control={<Radio />}
                                    label="Services"
                                />
                                <FormControlLabel
                                    value={QualityCheckPrintType.Material}
                                    control={<Radio />}
                                    label="Materialien"
                                />
                                <FormControlLabel
                                    value={QualityCheckPrintType.ServicesMaterial}
                                    control={<Radio />}
                                    label="Alle"
                                />
                            </RadioGroup>
                        </Paper>
                    </Grid>
                </Grid>
                {buldPrintCharts()}


            </div>
        );
    }


    const setAccordionExpandable = (averageQualityChecksTable: any[]) => {
        setGroupsLicenseTableEntriesAverage(averageQualityChecksTable);
        setAccordionExpandableServices(averageQualityChecksTable.map(x => { return { id: x.id, expanded: false } }));
        setAccordionExpandableMaterials(averageQualityChecksTable.map(x => { return { id: x.id, expanded: false } }));
    }

    const updateTableAverage = (startDate: Date, endDate: Date, localQualityChecks: ObjectLeaderQualityCheckModel[]) => {
        let qualityChecks = localQualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let currentGroupsPortal = groupsLicenseTableEntries.filter(x => qualityChecks?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName));
        let averageQualityChecksTable = generateAverageGroupCombinedQualityChecksTableDataByObjectLeader(localQualityChecks, currentGroupsPortal, objectLeaderAverageServicesDataFields, objectLeaderAverageMaterialsDataFields);
        setAccordionExpandable(averageQualityChecksTable);
    }

    const [accordionExpandableServices, setAccordionExpandableServices] = React.useState<any[]>();
    const [accordionExpandableMaterials, setAccordionExpandableMaterials] = React.useState<any[]>();
    const [accordionAllExpandedPressed, setAccordionAllExpandedPresssed] = React.useState(false);
    const [accordionAllExpandedPressedMaterials, setAccordionAllExpandedPresssedMaterials] = React.useState(false);





    const buildDetailsShow = (isMaterials = false) => {

        if (isMaterials) {
            if (isMaterialShown()) return;
        } else {
            if (isServicesShown()) return;
        }

        let compare = isMaterials ? accordionAllExpandedPressedMaterials : accordionAllExpandedPressed;

        return (
            compare ?
                <Button size="small" variant="contained" startIcon={<ArrowCircleUp />} color="primary" onClick={() => {
                    if (!isMaterials) {
                        setAccordionExpandableServices(accordionExpandableServices?.map(x => { return { id: x.id, expanded: false } }));
                        setAccordionAllExpandedPresssed(false);
                    } else {
                        setAccordionExpandableMaterials(accordionExpandableServices?.map(x => { return { id: x.id, expanded: false } }));
                        setAccordionAllExpandedPresssedMaterials(false);
                    }

                }}>
                    Details Einklappen  {isMaterials ? "Material" : "Services"}
                </Button>
                :
                <Button size="small" variant="contained" startIcon={<ExpandCircleDown />} color="primary" onClick={() => {
                    if (!isMaterials) {
                        setAccordionExpandableServices(accordionExpandableServices?.map(x => { return { id: x.id, expanded: true } }));
                        setAccordionAllExpandedPresssed(true);
                    } else {
                        setAccordionExpandableMaterials(accordionExpandableServices?.map(x => { return { id: x.id, expanded: true } }));
                        setAccordionAllExpandedPresssedMaterials(true);
                    }

                }}>
                    Details Ausklappen {isMaterials ? "Material" : "Services"}
                </Button>
        )
    }


    const buildAverageTableSimpleDate = () => {
        return (
            <Grid item xs={12}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Qualitätssicherungen Tabelle Zusammenfassend {loadingCompleted ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {groupsLicenseTableEntriesAverage.length > 0 ? groupsLicenseTableEntriesAverage.length : "Keine Einträge"}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button style={{ marginRight: 10 }} size="small" variant="contained" startIcon={<Print />} color="primary" onClick={handlePrint}>
                            Tabelle Drucken
                        </Button>
                        {buildDetailsShow()}
                        {buildDetailsShow(true)}

                        {allQualityChecks &&
                            <div ref={refPrintAverageSmileTable} className="page-root">
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsmanagement Einheiten Gesamt Bericht</Typography>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item>
                                        < DatePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={"Start Datum"}
                                            value={startDateTableAverage}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setStartDateTableAverage(newVal);
                                                updateTableAverage(newVal, endDateTableAverage, allQualityChecks);
                                            }}
                                            format={"dd.MM.yyyy"}

                                        />
                                    </Grid>
                                    <Grid item>
                                        < DatePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={"Ende Datum"}
                                            value={endDateTableAverage}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setEndDateTableAverage(newVal);
                                                updateTableAverage(startDateTableAverage, newVal, allQualityChecks);
                                            }}
                                            format={"dd.MM.yyyy"}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <ObjectLeaderQualityCheckAverageTable hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames} accordionExpandableMaterials={accordionExpandableMaterials} setAccordionExpandableMaterials={setAccordionExpandableMaterials} accordionExpandableServices={accordionExpandableServices} rows={groupsLicenseTableEntriesAverage} setAccordionExpandableServices={setAccordionExpandableServices} />
                                    </Grid>
                                </Grid>
                                <div className="page-break"></div>

                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }



    const buildAverageMenuPoints = () => {
        return (
            <Grid item xs={12}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Qualitätssicherungen Details {allQualityChecks ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item xs={12}>
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        md={6}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="p"
                                            >
                                                Qualitätssicherungen Diagramme Details {allQualityChecks ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                            </Typography >
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item container direction="column" spacing={3}>
                                        <Grid item container justifyContent={"center"} spacing={2}>
                                            <Grid item xs={12}>
                                                {buildObjectLeaderQualityCheckServicesChart()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {buildObjectLeaderQualityCheckMaterialChart()}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {buildObjectLeaderIncidentReportChart()}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>


                        <Grid item xs={12}>
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        md={6}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="p"
                                            >
                                                Qualitätssicherungen Diagramme Zusammenfassung (Durchschnitt) {allQualityChecks ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                            </Typography >
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid item container direction="column" spacing={3} >
                                        <Grid item>
                                            {buildSmileDescription()}
                                        </Grid>
                                        <Grid item container justifyContent={"center"} spacing={2}>
                                            {buildObjectLeaderQualityCheckAverageServicesChart()}
                                            {buildObjectLeaderQualityCheckAverageMaterialsChart()}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        md={6}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="p"
                                            >
                                                Qualitätssicherungen nach Einheiten Diagramme {allQualityChecks ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                            </Typography >
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid item container direction="column" spacing={3} >
                                        <Grid item>
                                            {buildSmileDescription()}
                                        </Grid>
                                        <Grid item container justifyContent={"center"} spacing={2}>
                                            {buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined()}
                                            {buildObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined()}
                                            {buildObjectLeaderQualityCheckAverageServicesGroupsChart()}
                                            {buildObjectLeaderQualityCheckAverageMaterialsGroupsChart()}

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Qualitätssicherungen Log Tabelle {allQualityChecks ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {allQualityChecks ? allQualityChecks.length : ""}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {allQualityChecks ?
                                <ObjectLeaderQualityCheckTable qualityChecks={allQualityChecks} groups={groupsLicenseTableEntries} userServiceWorkers={serviceWorkers}/> : <CircularProgress size={32} />
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefObjectLeaderServicesChart, childRefObjectLeaderAverageServicesChart, childRefObjectLeaderAverageGroupsServicesChart, childRefObjectLeaderAverageGroupsCombinedServicesChart, childRefObjectLeaderMaterialsChart, childRefObjectLeaderAverageMaterialsChart, childRefObjectLeaderAverageGroupsMaterialsChart, childRefObjectLeaderAverageGroupsCombinedMaterialsChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                {buildAverageTableSimpleDate()}
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Hitliste Qualitätssicherungen {loadingCompleted ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {allQualityChecks.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction={"row"} spacing={3}>
                                <Grid item xs={12}>
                                    <HitListChartsQualityChecksCountMarking qualityChecksTableData={allQualityChecks} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <HitListChartsQualityChecksAvgMarking qualityChecksTableData={allQualityChecks} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <HitListChartsQualityChecksBestServices qualityChecksTableData={allQualityChecks} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <AverageServicesSimpleChart allQualityChecks={allQualityChecks} loadingCompleted={loadingCompleted} />
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <AverageGroupsSimpleChart allQualityChecks={allQualityChecks} loadingCompleted={loadingCompleted} groupsLicenseTableEntries={groupsLicenseTableEntries} />

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {buildAverageMenuPoints()}

            </Grid>
        );
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}

export const AverageGroupsSimpleChart = React.forwardRef(({ allQualityChecks, loadingCompleted, groupsLicenseTableEntries }: { allQualityChecks: any[] | undefined, loadingCompleted: boolean, groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateAverageGroupsSimpleChartData(date: Date) {
            updateStartDateAverageGroupsSimpleChartData(date);
        },
        updateEndDateAverageGroupsSimpleChartData(date: Date) {
            updateEndDateAverageGroupsSimpleChartData(date);
        }

    }));

    const history = useHistory();
    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);
    const queryString = useQuery(history);

    const [chartHeightAverageGroupsSimple, setChartHeightAverageGroupsSimple] = React.useState(800);
    const [endDateAverageGroupsSimpleChartData, setEndDateAverageGroupsSimpleChartData] = React.useState(new Date());
    const [startDateAverageGroupsSimpleChartData, setStartDateAverageGroupsSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [averageGroupsSimpleChartData, setAverageGroupsSimpleChartData] = React.useState<any[]>([]);





    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);



        if (allQualityChecks) {

            updateAverageGroupsSimpleChart(startDate, endDate, allQualityChecks);
            setStartDateAverageGroupsSimpleChartData(startDate);
            setEndDateAverageGroupsSimpleChartData(endDate);


        }
    }, [allQualityChecks])
    React.useEffect(() => {
        updateEndDateAverageGroupsSimpleChartData(endDateAverageGroupsSimpleChartData);
    }, [maxChartItemCount])

    const updateStartDateAverageGroupsSimpleChartData = (newVal: Date) => {
        if (!allQualityChecks) return;
        setStartDateAverageGroupsSimpleChartData(newVal);
        updateAverageGroupsSimpleChart(newVal, endDateAverageGroupsSimpleChartData, allQualityChecks);
    }

    const updateEndDateAverageGroupsSimpleChartData = (newVal: Date) => {
        if (!allQualityChecks) return;
        setEndDateAverageGroupsSimpleChartData(newVal);
        updateAverageGroupsSimpleChart(startDateAverageGroupsSimpleChartData, newVal, allQualityChecks);
    }

    const updateAverageGroupsSimpleChart = (startDate: Date, endDate: Date, localQualityChecks: ObjectLeaderQualityCheckModel[]) => {
        let qualityChecks = localQualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);

        let res = qualityChecks.reduce((sumValue, newValue: ObjectLeaderQualityCheckModel) => {
            let notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
            let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
            if (entryExists) {
                let sum = 0;
                let count = 0;
                newValue.markedServices.forEach(item => {
                    sum += getValueFromMarkedService(item) || 0;
                    count++;
                });
                entryExists.countService += count;
                entryExists.valueFieldService += sum;
                return [...sumValue];
            } else {
                let sum = 0;
                let count = 0;
                newValue.markedServices.forEach(item => {
                    sum += getValueFromMarkedService(item) || 0;
                    count++;
                });
                return [...sumValue, { argumentFieldService: notificationName, valueFieldService: sum, countService: count }];
            }
        }, [] as any[]);
        res = res.map(x => { { return { ...x, valueFieldService: Math.round(x.valueFieldService / x.countService) } } }).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount);
        setAverageGroupsSimpleChartData(res);
    }

    return (
        <Grid item xs={12}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        md={6}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                            >
                                Qualitätssicherungen Einheiten Zusammenfassend (Einfach) {loadingCompleted ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                            </Typography >
                        </Grid>
                        <Grid item>
                            <Typography
                                className="Paper-Result"
                                component="p"
                            >
                                {averageGroupsSimpleChartData.length > 0 ? averageGroupsSimpleChartData.length : "Keine Einträge"}
                            </Typography >
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        allQualityChecks &&
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsmanagement Einheiten Gesamt Bericht</Typography>
                            <Grid container direction="row" spacing={3}>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Start Datum"}
                                        value={startDateAverageGroupsSimpleChartData}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            updateStartDateAverageGroupsSimpleChartData(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Ende Datum"}
                                        value={endDateAverageGroupsSimpleChartData}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            updateEndDateAverageGroupsSimpleChartData(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                                <Grid item>
                                    <ChartHeightTextBox chartHeight={chartHeightAverageGroupsSimple} setChartHeight={setChartHeightAverageGroupsSimple} />

                                </Grid>
                                <MaxChartItemCountInput />
                            </Grid>
                            <StandardBarChartSimpleColorFeed height={chartHeightAverageGroupsSimple} dataSource={averageGroupsSimpleChartData} />
                        </Grid>
                    }
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
})


export const AverageServicesSimpleChart = React.forwardRef(({ allQualityChecks, loadingCompleted }: { allQualityChecks: any[] | undefined, loadingCompleted: boolean }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateAverageServicesSimpleChartData(date: Date) {
            updateStartDateAverageServicesSimpleChartData(date);
        },
        updateEndDateAverageServicesSimpleChartData(date: Date) {
            updateEndDateAverageServicesSimpleChartData(date);
        }

    }));
    const history = useHistory();

    const [startDateAverageServicesSimpleChartData, setStartDateAverageServicesSimpleChartData] = React.useState(subDays(new Date(), 10));
    const { maxChartItemCount, setIsPreFilterDialogVisible }: { maxChartItemCount: number, setIsPreFilterDialogVisible: any } = useContext(GlobalContext);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);
    const [startDateAverageGroupsSimpleChartData, setStartDateAverageGroupsSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateAverageServicesSimpleChartData, setEndDateAverageServicesSimpleChartData] = React.useState(new Date());
    const [averageServicesSimpleChartData, setAverageServicesSimpleChartData] = React.useState<any[]>([]);
    const queryString = useQuery(history);
    const [chartHeightAverageServicesSimple, setChartHeightAverageServicesSimple] = React.useState(800);
    React.useEffect(() => {
        updateEndDateAverageServicesSimpleChartData(endDateAverageServicesSimpleChartData);
    }, [maxChartItemCount])
    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);

        setEndDateAverageServicesSimpleChartData(endDate);


        if (allQualityChecks) {

            updateAverageServicesSimpleChart(startDate, endDate, allQualityChecks);
            setStartDateAverageGroupsSimpleChartData(startDate);

            setStartDateAverageServicesSimpleChartData(startDate);
            setEndDateAverageServicesSimpleChartData(endDate);


        }
    }, [allQualityChecks])


    const updateStartDateAverageServicesSimpleChartData = (newVal: Date) => {
        if (!allQualityChecks) return;
        setStartDateAverageServicesSimpleChartData(newVal);
        updateAverageServicesSimpleChart(newVal, endDateAverageServicesSimpleChartData, allQualityChecks);
    }

    const updateEndDateAverageServicesSimpleChartData = (newVal: Date) => {
        if (!allQualityChecks) return;
        setEndDateAverageServicesSimpleChartData(newVal);
        updateAverageServicesSimpleChart(startDateAverageGroupsSimpleChartData, newVal, allQualityChecks);
    }

    const updateAverageServicesSimpleChart = (startDate: Date, endDate: Date, localQualityChecks: ObjectLeaderQualityCheckModel[]) => {
        let qualityChecks = localQualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let res = qualityChecks.reduce((sumValue, newValue: ObjectLeaderQualityCheckModel) => {
            newValue.markedServices.forEach(item => {
                let entryExists = sumValue.find(x => x.argumentFieldService == item.cleaningOperationText);
                if (entryExists) {
                    entryExists.valueFieldService += getValueFromMarkedService(item);
                    entryExists.countService += 1;
                } else {
                    sumValue = [...sumValue, { argumentFieldService: item.cleaningOperationText, valueFieldService: getValueFromMarkedService(item), countService: 1 }];
                }
            });
            return [...sumValue];
        }, [] as any[]);
        res = res.map(x => { { return { ...x, valueFieldService: Math.round(x.valueFieldService / x.countService) } } }).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount);
        setAverageServicesSimpleChartData(res);
    }

    return (
        <Grid item xs={12}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        md={6}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                            >
                                Qualitätssicherungen Services Zusammenfassend (Einfach) {loadingCompleted ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                            </Typography >
                        </Grid>
                        <Grid item>
                            <Typography
                                className="Paper-Result"
                                component="p"
                            >
                                {averageServicesSimpleChartData.length > 0 ? averageServicesSimpleChartData.length : "Keine Einträge"}
                            </Typography >
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    allQualityChecks &&
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsmanagement Services Gesamt Bericht</Typography>
                        <Grid container direction="row" spacing={3}>
                            <Grid item>
                                < DatePicker
                                    disableFuture
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                    label={"Start Datum"}
                                    value={startDateAverageServicesSimpleChartData}
                                    onChange={(newVal) => {
                                        if (!newVal) return;
                                        updateStartDateAverageServicesSimpleChartData(newVal);
                                    }}
                                    format={"dd.MM.yyyy"}

                                />
                            </Grid>
                            <Grid item>
                                < DatePicker
                                    disableFuture
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                    label={"Ende Datum"}
                                    value={endDateAverageServicesSimpleChartData}
                                    onChange={(newVal) => {
                                        if (!newVal) return;
                                        updateEndDateAverageServicesSimpleChartData(newVal);
                                    }}
                                    format={"dd.MM.yyyy"}

                                />
                            </Grid>
                            <Grid item>
                                <ChartHeightTextBox chartHeight={chartHeightAverageServicesSimple} setChartHeight={setChartHeightAverageServicesSimple} />

                            </Grid>
                            <MaxChartItemCountInput />
                        </Grid>
                        <StandardBarChartSimpleColorFeed dataSource={averageServicesSimpleChartData} name={"QM-Services Gesamt"} height={chartHeightAverageServicesSimple} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
})


export enum QualityCheckPrintType {
    Services = 0,
    Material = 1,
    ServicesMaterial = 2
}

export const buildSmileDescription = (height = 60) => {
    return (
        <Grid container direction="row" spacing={3} justifyContent="center">
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>0%</Typography>
                </Grid>
                <Grid item>
                    <img src={RED_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>25%</Typography>
                </Grid>
                <Grid item>
                    <img src={RED_LIGHT_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>50%</Typography>
                </Grid>
                <Grid item>
                    <img src={YELLOW_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>75%</Typography>
                </Grid>

                <Grid item>
                    <img src={GREEN_LIGHT_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>100%</Typography>
                </Grid>
                <Grid item>
                    <img src={GREEN_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
        </Grid>

    )
}

export const buildSmileDescriptionWithVisitors = (height = 60, feedbackDisplayTypeChart: FeedbackDisplayType) => {
    return (
        <Grid container direction="row" spacing={3} justifyContent="center">
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>25%</Typography>
                </Grid>
                <Grid item>
                    <img src={RED_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            {feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                <Grid item xs={2} container direction="column" alignItems={"center"}>
                    <Grid item>
                        <Typography>25%</Typography>
                    </Grid>
                    <Grid item>
                        <img src={RED_LIGHT_SMILE} style={{ height: height }} />
                    </Grid>
                </Grid>
            }
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>75%</Typography>
                </Grid>
                <Grid item>
                    <img src={YELLOW_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            {feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

                <Grid item xs={2} container direction="column" alignItems={"center"}>
                    <Grid item>
                        <Typography>100%</Typography>
                    </Grid>

                    <Grid item>
                        <img src={GREEN_LIGHT_SMILE} style={{ height: height }} />
                    </Grid>
                </Grid>
            }

            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>100%</Typography>
                </Grid>
                <Grid item>
                    <img src={GREEN_SMILE} style={{ height: height }} />
                </Grid>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems={"center"}>
                <Grid item>
                    <Typography>80%</Typography>
                </Grid>
                <Grid item>
                    {<MotionSensorSvgWhite color="#000000" />}
                    <Typography>Besucher ohne Feedback</Typography>
                </Grid>
            </Grid>
        </Grid>

    )
}