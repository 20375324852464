import React from 'react';
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Save, SupervisedUserCircle } from '@mui/icons-material';
import { GLOBAL_ALL_GRID_STACK_TYPES, GLOBAL_ALL_GRID_STACK_TYPES_TITLE, GridStackCustom, GridStackCustomItem, GridStackElementType, GridStackItemDisplayTable, GridStackItemType } from '../../../../../../models/GridStackCustom';
import { usePortalAccountStyles } from '../../../../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { StringHelper } from '../../../../../../helpers/string-helper';
import { DefaultDialogTitle } from '../../../../../dialogs/DefaultDialogTitle';
import { GridStackItemChooserDisplayTable } from './GridStackItemChooserDisplayTable';
import { useTranslation } from 'react-i18next';

export const GridStackCustomItemManagementPageEditingDialog = ({ visible, setVisible, gridStackCustomItem, onSave }
    : { visible: boolean, setVisible: any, gridStackCustomItem?: GridStackCustomItem | undefined, onSave: any }) => {

    const classes = usePortalAccountStyles();

    const [currentGridStackCustom, setCurrentGridStackCustom] = React.useState<GridStackCustomItem>(GridStackCustomItem.CreateInstance(GridStackItemType.CockpitFeedback));

    const [loading, setLoading] = React.useState(false);
    const [selection, setSelection] = React.useState<any[]>([]);
    const [rows, setRows] = React.useState<any[]>([]);
    const [elementTypeChoice, setElementTypeChoice] = React.useState(GridStackElementType.Chart);
    const {t} = useTranslation();
    
    React.useEffect(() => {
        if (selection.length > 0) {
            setCurrentGridStackCustom({ ...currentGridStackCustom, id: GridStackCustomItem.GetLabelType(selection[0]) });
        }
    }, [selection])

    const onClose = () => {
        setCurrentGridStackCustom(GridStackCustomItem.CreateInstance(GridStackItemType.CockpitFeedback));
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        let allItems = GLOBAL_ALL_GRID_STACK_TYPES.map(x => GridStackItemDisplayTable.CreateInstance(x));
        setRows(allItems);

        (async () => {
            if (gridStackCustomItem) {
                setCurrentGridStackCustom(gridStackCustomItem);
            } else {
                setCurrentGridStackCustom(GridStackCustomItem.CreateInstance(GridStackItemType.CockpitFeedback));
            }

        })();

    }, [visible, gridStackCustomItem]);

    const buildElementTypeChooser = () => {
        return (
            <Grid item container direction={"column"}>
                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Element Typ</FormLabel>
                        <RadioGroup
                            row
                            value={elementTypeChoice}
                            onChange={(event) => {
                                let val = parseInt(event.target.value);
                                setElementTypeChoice(val);
                                if (GridStackElementType.Chart == val){
                                    let allItems = GLOBAL_ALL_GRID_STACK_TYPES.map(x => GridStackItemDisplayTable.CreateInstance(x));
                                    setRows(allItems);
                                }else{
                                    let allItems = GLOBAL_ALL_GRID_STACK_TYPES_TITLE.map(x => GridStackItemDisplayTable.CreateInstance(x));
                                    setRows(allItems);
                                }
                            }}
                        >
                            <FormControlLabel
                                value={GridStackElementType.Chart}
                                control={<Radio />}
                                label={"Diagramm"}
                            />
                            <FormControlLabel
                                value={GridStackElementType.Title}
                                control={<Radio />}
                                label={"Titel"}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth={"xl"}>
            <DefaultDialogTitle
                title="Element hinzufügen"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="row" spacing={3} item>
                        <Grid item xs={12}>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={currentGridStackCustom.posX} label={"Position X"}
                                onChange={(event) => {
                                    if (isNaN(parseInt(event.target.value))) return;
                                    setCurrentGridStackCustom({ ...currentGridStackCustom, posX: parseInt(event.target.value) })
                                }} />
                        </Grid>
                        <Grid item>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={currentGridStackCustom.posY} label={"Position Y"}
                                onChange={(event) => {
                                    if (isNaN(parseInt(event.target.value))) return;
                                    setCurrentGridStackCustom({ ...currentGridStackCustom, posY: parseInt(event.target.value) })
                                }} />
                        </Grid>
                        <Grid item>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={currentGridStackCustom.width} label={"Breite Anteil (1-12)"}
                                onChange={(event) => {
                                    if (isNaN(parseInt(event.target.value))) return;
                                    setCurrentGridStackCustom({ ...currentGridStackCustom, width: parseInt(event.target.value) })
                                }} />
                        </Grid>
                        <Grid item>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={currentGridStackCustom.height} label={"Höhen Anteil"}
                                onChange={(event) => {
                                    if (isNaN(parseInt(event.target.value))) return;
                                    setCurrentGridStackCustom({ ...currentGridStackCustom, height: parseInt(event.target.value) })
                                }} />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentGridStackCustom.isStandardChartHeightEnabled}
                                        onChange={(event: any) => {
                                            setCurrentGridStackCustom({ ...currentGridStackCustom, isStandardChartHeightEnabled: event.target.checked });
                                        }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Verwende Standard-Diagramm-Höhe"
                            />
                        </Grid>
                        {!currentGridStackCustom.isStandardChartHeightEnabled &&
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currentGridStackCustom.isAutoChartHeightEnabled}
                                            onChange={(event: any) => {
                                                setCurrentGridStackCustom({ ...currentGridStackCustom, isAutoChartHeightEnabled: event.target.checked });
                                            }}
                                            name="isEnabled"
                                            color="primary"
                                        />
                                    }
                                    label="Automatische Höhenanpassung des Diagramms"
                                />
                            </Grid>
                        }
                        {!currentGridStackCustom.isStandardChartHeightEnabled && !currentGridStackCustom.isAutoChartHeightEnabled &&
                            <Grid item>
                                <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={currentGridStackCustom.chartHeight} label={"Diagramm-Höhe"}
                                    onChange={(event) => {
                                        if (isNaN(parseInt(event.target.value))) return;
                                        setCurrentGridStackCustom({ ...currentGridStackCustom, chartHeight: parseInt(event.target.value) })
                                    }} />
                            </Grid>
                        }
                    </Grid>
                    {buildElementTypeChooser()}
                    {rows.length > 0 &&
                        <Grid item xs={12}>
                            <GridStackItemChooserDisplayTable rows={rows} selection={selection} setSelection={setSelection} />
                        </Grid>
                    }
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Name"
                            variant="filled"
                            value={currentGridStackCustom.id}
                            onChange={(event: any) => { setCurrentGridStackCustom({ ...currentGridStackCustom, id: event.target.value }) }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"

                    endIcon={<Save />}
                    disabled={loading || selection.length < 1}
                    onClick={async () => {

                        setLoading(true);
                        for (let selectedGrid of selection) {
                            currentGridStackCustom.gridStackItemType = selectedGrid;
                            currentGridStackCustom.id = GridStackCustomItem.GetLabelType(selectedGrid);
                            await onSave(currentGridStackCustom);
                        }

                        setLoading(false);

                        onClose();
                    }}
                >
                    {selection.length} Element Hinzufügen
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
