import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Add, AddToHomeScreen, AlarmOn, Edit, PhonelinkSetup } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { ServiceWorkerType, UserServiceWorker } from '../../../../models/UserServiceWorker';
import { api } from '../../../../api';
import { getUserIdFromStorage } from '../../../../helpers/common';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { UserServiceWorkerManagementPageDataTable } from './UserServiceWorkerManagementPageDataTable';
import { UserServiceWorkerManagementPageEditingDialog } from './UserServiceWorkerManagementPageEditingDialog';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { useQuery } from '../../portal/checkout/ShopPage';
import { User } from '../../../../models/GroupSettings';
import { GlobalContext } from '../../PortalPage';
import { elevationPaper, paperStyle } from '../../../../styles/container';
import { UserType } from '../../../../models/UserType';
import { LinearProgressWithLabel } from '../../../core/components/ProgressComponent';

export const ServiceWorkerManagementPage = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [selectedUserServiceWorker, setSelectedUserServiceWorker] = React.useState<UserServiceWorker>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);
    const { userAccount }: any = React.useContext(GlobalContext);


    const queryString = useQuery(history);
    const phoneNumberFromQueryString = queryString.get("phoneNumber");
    const emailFromQueryString = queryString.get("email");
    const nfcCodeFromQueryString = queryString.get("nfcCode");
    const nameFromQueryString = queryString.get("name");
    const groupId = queryString.get('groupId');
    const templateId = queryString.get('templateId');
    const serviceWorkerId = queryString.get('serviceWorkerId');

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);

    const onRefresh = async () => {
        setLoading(true);
        const tempToken = getApiToken(history);
        const userId = getUserIdFromStorage();

        if (!tempToken || !userId) return;

        setToken(tempToken);
        setLoadingProgress(0);
        setValueBuffer(20);

        const tempServiceWorkers = await api.getServiceWorkers(tempToken);
        setUserServiceWorkers(tempServiceWorkers);
        console.log(tempServiceWorkers, "meine Servicekräfte");

        setLoadingProgress(20);
        setValueBuffer(60);

        let allGroupsTemp = await api.GetAllGroupsByUserIdAsync(tempToken, userId);
        setGroups(allGroupsTemp);

        setLoadingProgress(60);
        setValueBuffer(100);

        const tempObjects = await api.getUserObjectUnits(tempToken);
        setObjects(tempObjects);
        setLoading(false);
        setLoadingProgress(100);
        setValueBuffer(100);

        return tempServiceWorkers;

    }

    React.useEffect(() => {

        (async () => {
            let serviceWorkers = await onRefresh();
            if (serviceWorkerId) {
                setSelectedUserServiceWorker(serviceWorkers?.find(x => x.id == serviceWorkerId));
                setEditSettingDialogVisible(true);
            }
        })();

        if (phoneNumberFromQueryString || nfcCodeFromQueryString || nameFromQueryString) {
            let worker = UserServiceWorker.CreateUserServiceWorker();
            worker.name = nameFromQueryString || "";
            worker.phoneNumber = phoneNumberFromQueryString || "";
            worker.email = emailFromQueryString || "";
            worker.nfcCode = nfcCodeFromQueryString || "";
            worker.isEnabled = true;
            if (worker.email) worker.type = ServiceWorkerType.ObjectLeader;
            setSelectedUserServiceWorker(worker);
            setAddSettingDialogVisible(true);
        }

    }, [history]);

    if (userAccount && userAccount.userType == UserType.Customer) {
        return (
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={elevationPaper} style={paperStyle}>
                        <Typography variant="h4">Kunde kann nicht Servicekraft Verwaltung anschauen</Typography>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Servicekraft Verwaltung
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedUserServiceWorker(UserServiceWorker.CreateUserServiceWorker());
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        Servicekraft hinzufügen
                    </Button>
                </Grid>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        <PhonelinkSetup /> Einheiten Zuweisen
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <AddToHomeScreen /> Servicekraft zur App einladen
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <AlarmOn /> Anmeldungen im Objekt
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <Edit /> Bearbeiten
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper>

                    {loading &&
                        <Grid item>
                            <CircularProgress size={64} />
                        </Grid>
                    }
                    <UserServiceWorkerManagementPageDataTable
                        onRefresh={onRefresh}
                        objects={objects}
                        rows={userServiceWorkers}
                        groups={groups}
                        onEdit={async (row: UserServiceWorker) => {
                            setSelectedUserServiceWorker(row);
                            setEditSettingDialogVisible(true);
                        }}
                        onDelete={async (row: UserServiceWorker) => {
                            setSelectedUserServiceWorker(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                        onInviteUser={async (row: UserServiceWorker) => {
                            api.inviteUserServiceWorkerToApp(row, token, enqueueSnackbar);
                        }}
                    />

                </Paper>
            </Grid>

            <UserServiceWorkerManagementPageEditingDialog
                objects={userServiceWorkers}
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                object={selectedUserServiceWorker}
                onSave={async (property: UserServiceWorker) => {
                    const updatedServiceWorker = await api.createUserServiceWorker(property, token, enqueueSnackbar);
                    if (groupId) {
                        let groupSettings = await api.getGroupSettings(groupId, token);
                        if (groupSettings.users) {
                            groupSettings.users.users = groupSettings.users?.users.filter(x => !(x.username == nameFromQueryString && x.nfcCode == nfcCodeFromQueryString && x.whatsAppPhoneNumber == phoneNumberFromQueryString));
                            let newUser = new User(updatedServiceWorker.name, updatedServiceWorker.nfcCode, updatedServiceWorker.phoneNumber, updatedServiceWorker.isEnabled);
                            newUser.userServiceWorkerId = updatedServiceWorker.id;
                            groupSettings.users.users.push(newUser);
                            api.updateGroupSettings(groupSettings, token, enqueueSnackbar);
                        }
                    } else if (templateId) {
                        let groupSettings = await api.getGroupSettingsTemplateById(token, templateId);
                        if (groupSettings.users) {
                            groupSettings.users.users = groupSettings.users?.users.filter(x => !(x.username == nameFromQueryString && x.nfcCode == nfcCodeFromQueryString && x.whatsAppPhoneNumber == phoneNumberFromQueryString));
                            let newUser = new User(updatedServiceWorker.name, updatedServiceWorker.nfcCode, updatedServiceWorker.phoneNumber, updatedServiceWorker.isEnabled);
                            newUser.userServiceWorkerId = updatedServiceWorker.id;
                            groupSettings.users.users.push(newUser);
                            api.updateUserGroupSettingsTemplate(groupSettings, token, enqueueSnackbar);
                        }
                    }

                    if (!updatedServiceWorker)
                        return;
                    setUserServiceWorkers([updatedServiceWorker, ...userServiceWorkers]);
                    onRefresh();
                }}
            />

            <UserServiceWorkerManagementPageEditingDialog
                objects={userServiceWorkers}
                visible={editSettingDialogVisible}
                onInviteUser={async (row: UserServiceWorker) => {
                    api.inviteUserServiceWorkerToApp(row, token, enqueueSnackbar);
                    let rows = await onRefresh();
                    let newRow = rows?.find(x => x.id == row.id);
                    if (newRow) {
                        row.activationCode = newRow.activationCode;
                        setSelectedUserServiceWorker({ ...row });
                    }
                }}
                setVisible={setEditSettingDialogVisible}
                object={selectedUserServiceWorker}
                onSave={async (userServiceWorker: UserServiceWorker) => {

                    const updated = await api.putUserServiceWorker(userServiceWorker, token, enqueueSnackbar);

                    if (!updated) return;

                    setUserServiceWorkers(p => p.map((item: UserServiceWorker) => item.id === updated.id ? updated : item));
                    onRefresh();
                }
                }

            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, diese Servicekraft zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!selectedUserServiceWorker) return;

                    await api.deleteUserServiceWorker(selectedUserServiceWorker.id, token, enqueueSnackbar);

                    setUserServiceWorkers(userServiceWorkers.filter(x => x.id !== selectedUserServiceWorker.id));
                    setDeleteConfirmationDialogVisible(false);
                    onRefresh();
                }}
            />



        </Grid>
    );
};