export const schemaImportCheckpoint = {
    'Einheit Name': {
        prop: 'Einheit Name',
        type: String
    },
    'Ebene': {
        prop: 'Ebene',
        type: String
    },
    'Objekt Kostenstelle': {
        prop: 'Objekt Kostenstelle',
        type: String
    },
}