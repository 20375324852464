import { GLOBAL_SELECTED_GRID_STACK_CUSTOM, GROUP_COMBINATION, GROUP_ID, GROUP_NAME } from "../../constants";
import { getQueryString } from "../../helpers/general";
import { GridStackCustom } from "../../models/GridStackCustom";
import { GroupLicenseTable } from "../../models/GroupLicenseTable";
import { ToiletType } from "../../models/GroupSettings";


export const handleSelectedCombinationGroup = (selection: any, groups: GroupLicenseTable[], setSelectedCombinationGroup: any, history: any) => {
    let res = [];
    while (selection.length) {
        let item = selection.shift();
        let group = groups.find((group) => group.id === item);
        res.push({ id: group?.id, name: group?.notificationName });
    }
    setSelectedCombinationGroup(res);
    localStorage.setItem(GROUP_COMBINATION, JSON.stringify(res));
    history.push(`/portal/groups/services/sum?${getQueryString(res)}`);
}

export const handleSelectedCombinationGroupGridStackCustom = (selection: any, groups: GroupLicenseTable[], setSelectedCombinationGroup: any, history: any, gridStackCustom: GridStackCustom) => {
    localStorage.setItem(GLOBAL_SELECTED_GRID_STACK_CUSTOM, JSON.stringify(gridStackCustom));
    let res = [];
    while (selection.length) {
        let item = selection.shift();
        let group = groups.find((group) => group.id === item);
        res.push({ id: group?.id, name: group?.notificationName });
    }
    setSelectedCombinationGroup(res);
    localStorage.setItem(GROUP_COMBINATION, JSON.stringify(res));
    history.push(`/portal/groups/services/custom-sum/${gridStackCustom.name}/?${getQueryString(res)}`);
}

export const mapToiletTypeKey = (val: any) => {
    switch (val) {
        case ToiletType.Meeting:
            return "Meeting"
        case ToiletType.Neutral:
            return "Neutral"
        case ToiletType.Male:
            return "Männlich"
        case ToiletType.Female:
            return "Weiblich"
        default:
            return val;
    }
}