import { ExpandMore, Height } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Divider, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { differenceInHours, differenceInMinutes, fromUnixTime, isAfter, isBefore, max, subDays } from "date-fns";
import React, { Fragment, useContext, useImperativeHandle, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { DataFilterType, PeriodChartFilterType, generateChartData, getDataFromGroupIdsTrigger, APIDataAmountType, getOnlineStatusData, getFeedbackData, getCleanerFeedbackByUserGroupIds, getMotionData, getOnlineStatusBatteryData } from "../../../../helpers/charts/base";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { getAvgFromSimpleChart, getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../helpers/compontents";
import { formatDateRange, formatEuropeanDateTime, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage, getUnixMillisec } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { BatteryOnlineStatusCountTableClass, GroupOnlineStatus, GroupOnlineStatusCountTable } from "../../../../models/GroupOnlineStatus";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { StandardBarChartSimpleColor } from "../../../charts/StandardBarChartSimpleColor";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { ChartHeightTextBox, GlobalTimeChoice, PrintButton, getGlobalEndDate, getGlobalStartDate, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { useQuery } from "../checkout/ShopPage";
import { CleaningTriggerDataTable } from "./cleaningTrigger/CleaningTriggerDataTable";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { PrintContext } from "./HitListDashboard";
import { OnlineStatusCountTable } from "./onlineStatus/OnlineStatusCountTable";
import { OnlineStatusDataTable } from "./onlineStatus/OnlineStatusDataTable";
import { EvalApiType } from "./Services";
import { BatteryOnlineStatusCountTable } from "./onlineStatus/BatteryOnlineStatusCountTable";
import { GroupCleanerFeedback } from "../../../../models/GroupCleanerFeedback";
import { GroupFeedback } from "../../../../models/GroupFeedback";
import { GroupMotion } from "../../../../models/GroupMotion";
import { GroupType } from "../../../../models/Group";

export const BatteryDisplayOnlineStatusDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds, onlineStatuse, groups }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any, onlineStatuse?: any[], groups?: any[] }) => {
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [loadingCompletedGroups, setLoadingCompletedGroups] = React.useState(false);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const [cleanerFeedbacks, setCleanerFeedbacks] = React.useState<GroupCleanerFeedback[]>([]);
    const [feedbacks, setFeedbacks] = React.useState<GroupFeedback[]>([]);


    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [batteryOnlineStatusCountTableData, setbatteryOnlineStatusCountTableData] = React.useState<BatteryOnlineStatusCountTableClass[]>([]);

    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());
    const [onlineStatusTableData, setOnlineStatusTableData] = React.useState<GroupOnlineStatus[]>([]);

    const [isPrintingPage, setIsPrintingPage] = React.useState({ print: false, resolve: undefined });



    const globalTimeChoiceChildEventsPrintTemplate = { doPrintingProcess: (token: string) => { } };
    const globalTimeChoiceChildEvents = { doPrintingProcess: (token: string) => { } };

    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);

    const queryString = useQuery(history);
    const doPrint = queryString.get("print");
    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();



    const loadDataAsync = async () => {
        setApiDataLoading(true);
        setLoadingCompleted(false);
        const token = getApiToken(history);
        if (!token) return;

        let groupsPortal: GroupLicenseTable[] = [];
        if (serviceDisplayType == EvalApiType.Groups) {
            groupsPortal = await getPortalGroups(groupIds, token, groupId);
        }
        else if (serviceDisplayType == EvalApiType.Objects) {
            groupsPortal = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
        } else if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            groupsPortal = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
        }
        groupsPortal = groupsPortal.filter(x => x.groupType == GroupType.DisplayBatteryWallApp)
        groupIds = groupsPortal.map(x => x.id);
        setGroupsLicenseTableEntries(groupsPortal);

        let feedbacks = await getFeedbackData(groupId, token, groupIds);
        let cleanerFeedbacks = await getCleanerFeedbackByUserGroupIds(groupId, token, groupIds);

        setFeedbacks(feedbacks);
        setCleanerFeedbacks(cleanerFeedbacks);

        setLoadingCompleted(true);
        setApiDataLoading(false);
        if (JSON.parse(doPrint || 'false')) {
            if (globalTimeChoiceChildEvents.doPrintingProcess) {
                await globalTimeChoiceChildEvents.doPrintingProcess(token);

            }
        }
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
        setStartDate(getGlobalStartDate());
        setEndDate(getGlobalEndDate());

        let onlineStatusBatteryData = await getOnlineStatusBatteryData(groupId, token, groupIds);
        setOnlineStatusTableData(onlineStatusBatteryData);



        reloadBatteryOnlineCount(feedbacks, onlineStatusBatteryData, cleanerFeedbacks, groupsPortal, getGlobalStartDate(), getGlobalEndDate());

    }

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, [])

    useNoInitialEffect(() => {
        loadDataAsync();
        (async () => {
            const token = getApiToken(history);
            loadDataAsync();
            setLoadingCompletedGroups(true);
        })();

    }, [apiDataAmountType, reloadDataFromApi]);


    const updateChosenPastDaysFromParent = (event: any) => {
    }

    const updateEndDateFromParent = (event: any) => {
        setEndDate(event);
        let temp = reloadBatteryOnlineCount(feedbacks, onlineStatusTableData, cleanerFeedbacks, groupsLicenseTableEntries, startDate, event);
    }

    const updateStartDateFromParent = (event: any) => {
        setStartDate(event);
        let temp = reloadBatteryOnlineCount(feedbacks, onlineStatusTableData, cleanerFeedbacks, groupsLicenseTableEntries, startDate, event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
    }

    const reloadOnlineStatusCount = (groupOnlineStatus: GroupOnlineStatus[], beginDate: Date | null = null, endDate: Date | null = null) => {
        let countMax = 0;
        let allGroupOnlineStatus = [...groupOnlineStatus];
        if (beginDate && endDate) {
            groupOnlineStatus = groupOnlineStatus.filter(x => {
                let curDate = new Date(x.createdTimestamp);
                return isAfter(curDate, beginDate) && isBefore(curDate, endDate);
            });
            countMax = differenceInHours(endDate, beginDate) / 24;
        }
        let ret = groupOnlineStatus.reduce((prevVal: GroupOnlineStatusCountTable[], curVal: GroupOnlineStatus) => {
            let valExist = prevVal.find(x => x.groupId == curVal.groupId);
            if (valExist) {
                valExist.onlineCount++;
            } else {
                let onlineCount = 1;
                let group = groupsLicenseTableEntries.find(x=>x.id == curVal.groupId);
                prevVal.push(new GroupOnlineStatusCountTable(curVal.groupId, curVal.userId, onlineCount, countMax, new Date(curVal.createdTimestamp), curVal.batteryLevel, group?.uniqueDeviceId || "", ""));
            }
            return prevVal
        }, []);

        for (let onlineStatus of allGroupOnlineStatus) {
            let valExist = ret.find(x => x.groupId == onlineStatus.groupId);
            if (valExist) {
                let curDate = new Date(onlineStatus.createdTimestamp);
                if (isBefore(valExist.lastOnlineTimestamp, curDate)) {
                    valExist.lastOnlineTimestamp = curDate;
                }
            }
        }

        return ret;



    }

    const reloadBatteryOnlineCount = (feedbacks: GroupFeedback[], groupOnlineStatus: GroupOnlineStatus[], cleanerFeedbacks: GroupCleanerFeedback[], groups: GroupLicenseTable[], beginDate: Date, endDate: Date) => {
        let allOnlineStatus = reloadOnlineStatusCount(groupOnlineStatus, beginDate, endDate);
        let entries = groups.reduce((preVal, group) => {
            let curGroupOnlineStatus = allOnlineStatus.find(x=>x.groupId == group.id);
            let newItem = new BatteryOnlineStatusCountTableClass();
            newItem.groupOnlineStatusCountTable = curGroupOnlineStatus;
            newItem.deviceId = group.uniqueDeviceId;
            let currentFeedbacks = feedbacks.filter(x => x.groupId == group.id && isAfter(new Date(x.createdTimestampString), beginDate) && isBefore(new Date(x.createdTimestampString), endDate));
            newItem.feedbackCount = currentFeedbacks.length;
            newItem.feedbackLastOnline = max(currentFeedbacks.map(x => new Date(x.createdTimestampString)));
            let currentCleanerFeedbacks = cleanerFeedbacks.filter(x => x.groupId == group.id && isAfter(fromUnixTime(x.createdTimestamp), beginDate) && isBefore(fromUnixTime(x.createdTimestamp), endDate));
            newItem.cleanerFeedbackCount = currentCleanerFeedbacks.length;
            newItem.cleanerFeedbackLastOnline = max(currentCleanerFeedbacks.map(x => fromUnixTime(x.createdTimestamp)))
            newItem.groupId = group.id;
            newItem.feedbackBatteryLevel = currentFeedbacks.find(x=>new Date(x.createdTimestampString).getTime() == newItem.feedbackLastOnline.getTime())?.batteryLevel || 0;
            return [...preVal, newItem];
        }, [] as BatteryOnlineStatusCountTableClass[])
        setbatteryOnlineStatusCountTableData(entries);



    }

    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Online Status Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }


    const buildGridContent = () => {
        return (
            <Grid container spacing={2} direction="row">
                {!loadingCompleted &&
                    <Grid item xs={12}>
                        <CircularProgress size={64} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography variant="h4">Battery Display Online Status</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Online-Status Log Tabelle {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {onlineStatusTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OnlineStatusDataTable rows={onlineStatusTableData} groups={groupsLicenseTableEntries} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Online-Status Analyse {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {batteryOnlineStatusCountTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                <Grid xs={12} item container spacing={3}>
                                    <Grid item>
                                        < DateTimePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={"Start Datum"}
                                            value={startDate}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                let temp = reloadBatteryOnlineCount(feedbacks, onlineStatusTableData, cleanerFeedbacks, groupsLicenseTableEntries, newVal, endDate);
                                                setStartDate(newVal);
                                            }}
                                            format={"dd.MM.yyyy HH:mm"}
                                            
                                        />
                                    </Grid>
                                    <Grid item>
                                        < DateTimePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={"Ende Datum"}
                                            value={endDate}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                let temp = reloadBatteryOnlineCount(feedbacks, onlineStatusTableData, cleanerFeedbacks, groupsLicenseTableEntries, startDate, newVal);
                                                setEndDate(newVal);
                                            }}
                                            format={"dd.MM.yyyy HH:mm"}
                                            
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {loadingCompletedGroups && groupsLicenseTableEntries.length > 0 ?
                                        <BatteryOnlineStatusCountTable rows={batteryOnlineStatusCountTableData} groupLicenseTable={groupsLicenseTableEntries}></BatteryOnlineStatusCountTable>
                                        :
                                        <CircularProgress color="inherit" />
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[]}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateEndDateFromParent={updateEndDateFromParent}
                        updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>



            </Grid>
        );
    }

    const globalFields = {
        isPrintPage: isPrintPage,
        setIsPrintPage: setIsPrintPage,
        isPrintingPage: isPrintingPage,
        setIsPrintingPage: setIsPrintingPage
    }

    return (
        <PrintContext.Provider value={globalFields}>
            {isPrintPage ? buildPrintContent() : buildGridContent()}
        </PrintContext.Provider>
    )
}

