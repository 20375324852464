import { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
    ColumnChooser,
    Grid,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { lightgrey } from '../../styles/colors';
import { ToiletTypeCell } from './components/Cells';
import { LocationInformation } from '../../models/GroupSettings';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';

const TableRow = (props: any) => {
    const { row, handleClose, ...restProps } = props;
    const classes = useStyles();

    return (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => {
            }}
            className={clsx(classes.customRow, classes.selected)}
        />
    );
};
const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});



const Cell = (props: any) => {
    const { column } = props;
    if (column.name === 'toiletType') {
        return <ToiletTypeCell {...props} />;
    }
    return <Table.Cell {...props} />;
};


export const GroupDetailsPrintTable = ({ groups }: { groups: GroupLicenseTable[] }) => {

    const [columns] = useState([
        { name: 'notificationName', title: 'Einheit Name' },
        { name: 'objectName', title: 'Objekt Name' },
        { name: 'levelName', title: 'Ebene' },
        { name: 'roomName', title: 'Kunde' },
        { name: 'toiletType', title: 'Toiletten Typ' },
        { name: 'uniqueDeviceId', title: 'Device Id' }
    ]);
    const [columnWidths, setColumnWidths]: any = useState([
        { columnName: 'notificationName', width: 220 },
        { columnName: 'objectName', width: 220 },
        { columnName: 'levelName', width: 220 },
        { columnName: 'roomName', width: 220 },
        { columnName: 'toiletType', width: 220 },
        { columnName: 'uniqueDeviceId', width: 220 }
    ]);

    return (
        <Paper>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={columns}
            >

                <SortingState
                    defaultSorting={[{ columnName: 'notificationName', direction: 'desc' }]}
                />
                <IntegratedSorting />
                <Table
                    cellComponent={Cell}
                    rowComponent={(props) => (
                        <TableRow
                            {...props}
                        />
                    )}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                />
                <Toolbar />
                <ColumnChooser />
            </Grid>
        </Paper>
    );
};