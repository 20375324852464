import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, ListItem, TableCell, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { GroupOnlineStatusCountTable } from '../../../../../models/GroupOnlineStatus';
import { GroupServiceModeCountTable } from '../../../../../models/GroupServiceModeCountTable';
import { Group } from '../../../../../models/Group';
import { mapToiletTypeKey } from '../../../../tables/Base';
import React from 'react';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { formatEuropeanDateTime, formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from '../../../../../helpers/date-management';
import { differenceInMinutes } from 'date-fns';
import { Check, Error, ExpandMore, SensorDoor } from '@mui/icons-material';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText } from '@mui/material';
import { green } from '@mui/material/colors';
import { getApiToken } from '../../../../../helpers/storage-management';
import { api } from '../../../../../api';
import { useHistory } from 'react-router-dom';
import { LoadingIcon } from '../../../../common/LoadingIcon';
import { ReactComponent as MotionSensorSvg } from '../../../../../assets/svgs/motion-sensor.svg'
import { SettingsServiceTableEntry } from '../../../../../models/SettingsServiceTable';
import { Column } from 'devextreme-react/data-grid';
import { CheckboxCell, FilterCellCheck } from '../../../../tables/ServiceModeTable';


const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

export const SettingsServiceTable = ({ settingsServices }: { settingsServices: SettingsServiceTableEntry[] }) => {
    const exporterRef: any = useRef(null);
    const history = useHistory();
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [columns, setColumns] = React.useState<any[]>([
        { name: SettingsServiceTableEntry.Columns.serviceEntry, title: 'Service Eintrag', getCellValue: (row: SettingsServiceTableEntry) => row.serviceEntry },
        {
            name: SettingsServiceTableEntry.Columns.countDone,
            title: 'Anzahl Service',
            getCellValue: (row: SettingsServiceTableEntry) => row.countDone,
        },
        {
            name: SettingsServiceTableEntry.Columns.isEnabled,
            title: 'Aktiviert',
            getCellValue: (row: SettingsServiceTableEntry) => row.isEnabled,
        },
        {
            name: SettingsServiceTableEntry.Columns.standardItemId,
            title: 'Standard Item',
            getCellValue: (row: SettingsServiceTableEntry) => !!row.standardItemId,
        }
    ]);

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        const [loading, setLoading] = React.useState(false);
        if (column.name === SettingsServiceTableEntry.Columns.countDone) {
            return (
                <Table.Cell
                    {...props}>
                    <MuiGrid container direction={"row"} alignItems="center" spacing={1}>
                        <MuiGrid item>
                            <Typography fontWeight={'bold'} color={row.countDone > 0 ? green[700] : "error"}>{value}</Typography>
                        </MuiGrid>
                        <MuiGrid item>
                            {row.countDone > 0 ? <Check style={{ color: green[700] }} fontSize={"large"} /> : <Error color='error' fontSize={"large"} />}
                        </MuiGrid>
                    </MuiGrid>
                </Table.Cell>
            )
        }
        if (column.name === SettingsServiceTableEntry.Columns.isEnabled || column.name == SettingsServiceTableEntry.Columns.standardItemId) {
            return <CheckboxCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const FilterCell = (props: any) => {
        const { column } = props;
        if (column.name === SettingsServiceTableEntry.Columns.isEnabled || column.name == SettingsServiceTableEntry.Columns.standardItemId) {
            return <FilterCellCheck {...props} />;
        }
        return <TableFilterRow.Cell {...props} />;
    };



    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == SettingsServiceTableEntry.Columns.serviceEntry) {
            return { columnName: el.name, width: 350 }
        }
        return { columnName: el.name, width: 180 }
    }));


    return (
        <>
            <Grid
                rows={settingsServices}
                columns={columns}
            >
                <FilteringState
                />
                <IntegratedFiltering />
                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table
                    cellComponent={Cell}
                />

                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />

                <PagingPanel pageSizes={[10, 15, 20, 25, 0]} />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={settingsServices}
                columns={columns}
                onSave={onSave}
            />
        </>
    );
};