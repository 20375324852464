import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { CircularProgress, DialogActions, DialogContent, Fab, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { UserServiceWorkerSelectionDataTable } from '../entireEval/UserServiceWorkerSelectionDataTable';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { endOfDay, startOfDay } from 'date-fns';
import { CustomDateTimePicker, InlineDateTimePicker } from '../../../core/InlineDateTimePicker';
import { api } from '../../../../api';
import { UserServiceWorkerObjectSession } from '../../../../models/UserServiceWorkerObjectSession';
import { getUserIdFromStorage } from '../../../../helpers/common';
import { getApiToken } from '../../../../helpers/storage-management';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const AddServiceWorkerObjectSessionDialog = ({ open, setOpen, groups, objects, userServiceWorkers, currentObject }
    : { open: boolean, setOpen: any, groups: GroupLicenseTable[], objects: ObjectUnit[], userServiceWorkers: UserServiceWorker[], currentObject: ObjectUnit }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [text, setText] = React.useState("");
    const [files, setFiles] = React.useState<(File | null)[]>([]);
    const [selectedEndDate, setSelectedEndDate] = React.useState(endOfDay(new Date()));
    const [selectedStartDate, setSelectedStartDate] = React.useState(startOfDay(new Date()));

    const [selectedUserServiceWorkers, setSelectedUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"xl"}
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle
                    title={<React.Fragment>Anwesenheit eintragen für Objekt: {currentObject.name}</React.Fragment>}
                    handleClose={handleClose}
                />
                <DialogActions style={{marginTop: 20}}>
                    <Grid container item direction={'row'} spacing={2}>
                        <Grid item>
                            <CustomDateTimePicker label={"Start Datum"} value={selectedStartDate} change={setSelectedStartDate}></CustomDateTimePicker>
                        </Grid>
                        <Grid item>
                            <CustomDateTimePicker label={"End Datum"} value={selectedEndDate} change={setSelectedEndDate}></CustomDateTimePicker>
                        </Grid>
                    </Grid>
                </DialogActions>
                <DialogContent className={classes.dialogContent} dividers>
                    <UserServiceWorkerSelectionDataTable groups={groups} currentGroup={null} rows={userServiceWorkers} selectedUserServiceWorkers={selectedUserServiceWorkers} onSelectionChange={(ids: string[]) => {
                        let workers = ids.map(x => userServiceWorkers.find(serviceWorker => serviceWorker.id == x) as UserServiceWorker);
                        setSelectedUserServiceWorkers(workers);
                    }} objects={objects} />
                </DialogContent>

                <DialogActions className={classes.dialogActions}>

                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>

                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        disabled={selectedUserServiceWorkers.length < 1 || loading}
                        variant={'contained'}
                        endIcon={loading && <CircularProgress size={32}/>}
                        color="primary"
                        onClick={async () => {
                            const tempToken = getApiToken(history);
                            let userId = getUserIdFromStorage() || "";
                            setLoading(true);
                            for (let serviceWorker of selectedUserServiceWorkers) {
                                let objectSession = UserServiceWorkerObjectSession.CreateFullUserServiceWorkerObjectSession(currentObject.id, userId, serviceWorker.id, selectedStartDate, selectedEndDate);
                                await api.createServiceWorkerObjectSession(objectSession, tempToken, enqueueSnackbar);
                            }
                            setLoading(false);
                            setFiles([]);
                            handleClose();
                        }}
                    >
                        Anwesenheit eintragen!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));