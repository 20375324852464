import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, CircularProgress, Divider, Fab, FormControl, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, LocalShipping, Category, CheckCircle, CenterFocusStrong, MonitorWeight, LineWeight } from "@mui/icons-material";
import { CleaningOperation, GroupSettings, ItemThing, ItemThingType, MaterialConsumption } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { errorColor, white } from "../../styles/colors";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { ReactComponent as PaperRoll } from '../../assets/svgs/paper-roll.svg';
import { useContainerStyles } from "../../styles/container";
import { StandardWorkItem, StandardWorkItemExtras, StandardWorkItemType } from "../../models/StandardItem";
import { api } from "../../api";
import { FileBlobType } from "../../models/FileBlobType";
import { useSnackbar } from "notistack";
import DefaultItemsDialogOld, { DefaultItemsType, getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import DefaultItemsDialog from "../dialogs/DefaultItemsDialog";
import { LocalMedia } from "../images/NetworkMedia";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { ObjectUnit, QMRatingType, QMSettings } from "../../models/ObjectUnit";
import { SettingsContext } from "../authorized/PortalPage";
import { MaterialSelectionTableGroup } from "../tables/material/MaterialSelectionTableGroup";
import { MaterialStock, StockActionDoneFromType, StockActionType, UserStock } from "../../models/UserStock";
import { getUserIdFromStorage } from "../../helpers/common";
import { MaterialStockGroupSettings } from "../../models/MaterialStockGroupSettings";
import { useTranslation } from "react-i18next";
import RoomTypeItemsDialog from "../dialogs/RoomTypeItemsDialog";

export const GroupSettingFieldsQmControl = ({ currentObjectUnit, row, onChange, token, loadStandardServices, standardServices, standardRoomType, buildStandardItemIcon, loadStandardRoomTypes, standardRoomTypes }:
    { currentObjectUnit: ObjectUnit | undefined, row: GroupSettings | UserGroupSettingsTemplate; onChange: any; token: any, loadStandardServices: any, standardServices: StandardWorkItem[], standardRoomType: StandardWorkItem | undefined, buildStandardItemIcon: any, loadStandardRoomTypes: any, standardRoomTypes: StandardWorkItem[] }) => {

    const classes = GroupSettingFieldsUseStyles();

    const { refreshSettings } = React.useContext(SettingsContext);
    const [expanded, setExpanded] = React.useState(false);

    const weightAll = React.useMemo(() => row.cleaningOperations?.cleaningOperations.reduce((prevVal, curVal) => {
        return prevVal + (curVal?.weighting || 0);
    }, 0), [row]);

    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [currentItemThingType, setCurrentItemThingType] = React.useState(ItemThingType.MainUsageComponent);

    const ItemsQM = React.useMemo(() => {
        switch (currentObjectUnit?.qmSettings?.qmRatingType) {
            case QMRatingType.QMWeighting:
                return (
                    <Grid item container direction={"row"} spacing={3}>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: "bold", fontSize: 26 }} color={weightAll == 100 ? "green" : "red"}>Gesamt Gewichtung: {weightAll}%</Typography>
                        </Grid>
                        {
                            row.cleaningOperations &&
                            row.cleaningOperations.cleaningOperations &&
                            row.cleaningOperations.cleaningOperations.map((operation: CleaningOperation, index: number) => {
                                const currentStandardItem = standardServices.find(x => x.id == operation.standardItemId);
                                let isStandardOperation = operation?.intervalControlIds && operation?.intervalControlIds?.length < 1;
                                return (
                                    <Grid key={index} item md={12} lg={6} xl={4} container direction="row" alignItems="center" alignContent="center" justifyContent={"center"} spacing={3}>
                                        <Grid item lg={7}>
                                            {currentStandardItem ?
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    style={{ color: 'red' }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{buildStandardItemIcon(currentStandardItem)}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    disabled={true}
                                                    name="cleaningOperations"
                                                    label="Standard-Serviceleistung"
                                                    value={currentStandardItem.text || ''} onChange={undefined} />
                                                :
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        style: { color: isStandardOperation ? "black" : 'red' },
                                                        endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(operation.text || '')}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="cleaningOperations"
                                                    disabled={true}
                                                    label="Serviceleistung"
                                                    value={operation.text || ''}
                                                    onChange={(event: any) => {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                        onChange(res);
                                                    }}
                                                />
                                            }
                                        </Grid>
                                        <Grid item>
                                            <TextFieldGroupSettings
                                                fullWidth
                                                InputProps={{
                                                    style: { color: isStandardOperation ? "black" : 'red' },
                                                    endAdornment: <InputAdornment variant="filled" position="end">%</InputAdornment>,
                                                    startAdornment: <InputAdornment variant="filled" position="start"><LineWeight /></InputAdornment>,
                                                }}
                                                variant={"filled"}
                                                name="cleaningOperations"
                                                type={"number"}
                                                label="Gewichtung"
                                                style={{ width: 150 }}
                                                value={operation.weighting || ''}
                                                onChange={(event: any) => {
                                                    let res = { target: { name: event.target.name, value: JSON.stringify({ val: parseInt(event.target.value), index: index, property: 'weighting' }) } };
                                                    onChange(res);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}

                    </Grid>
                )
            case QMRatingType.QMErrorPointsCount:
                return (
                    <Grid item xs={12} container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: 10 }}>{t("myUnitsSettings.selectRoomType")}</Typography>
                            <FormControl variant="filled" style={{ minWidth: 300 }}>
                                <Autocomplete
                                    freeSolo
                                    loading={loading}
                                    value={row.locationInformation?.roomType}
                                    onChange={(event: any, newValue: string | null) => {
                                        if (!newValue) return;
                                        if (!row.locationInformation) return;

                                        row.locationInformation.roomType = newValue;
                                        refreshSettings();
                                    }}

                                    id="controllable-states-demo"
                                    options={standardRoomTypes.map(x => x.text)}
                                    sx={{ width: 300 }}
                                    renderInput={(params: any) => <TextFieldGroupSettings onChange={(event) => {
                                        if (!row.locationInformation) return;
                                        row.locationInformation.roomType = event.target.value;
                                        refreshSettings();
                                    }} {...params} label={t("myUnitsSettings.roomType")} />}
                                />
                            </FormControl>


                        </Grid>
                        <Grid item>
                            <TextFieldGroupSettings
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                }}
                                variant={"filled"}
                                margin="normal"
                                name="roomSize"
                                type="number"
                                label={t("myUnitsSettings.areaM2")}
                                value={row.locationInformation?.roomSize || 0}
                                onChange={(event) => {
                                    if (row.locationInformation)
                                        row.locationInformation.roomSize = parseFloat(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {loading && <CircularProgress size={32} color="primary" />}
                        </Grid>
                        <ItemThingSection standardRoomType={standardRoomType} row={row} itemThingType={ItemThingType.MainUsageComponent} />
                        <Grid item>
                            <Divider />
                        </Grid>
                        <ItemThingSection standardRoomType={standardRoomType} row={row} itemThingType={ItemThingType.RestInventory} />
                        <Grid item>
                            <Divider />
                        </Grid>
                        <ItemThingSection standardRoomType={standardRoomType} row={row} itemThingType={ItemThingType["Walls&Covers"]} />
                        <Grid item>
                            <Divider />
                        </Grid>
                        <ItemThingSection standardRoomType={standardRoomType} row={row} itemThingType={ItemThingType.Floor} />
                        <Grid item>
                            <Divider />
                        </Grid>
                        <ItemThingSection standardRoomType={standardRoomType} row={row} itemThingType={ItemThingType.HardToSeeThings} />
                        <Grid item>
                            <Divider />
                        </Grid>

                    </Grid>
                )
            default:
                return (
                    <>
                        <Typography>Standard</Typography>
                    </>
                )
        }
    }, [row, currentObjectUnit, loading]);



    return (
        <Accordion expanded={expanded} className={classes.hover} onChange={async (event, expanded) => {
            setExpanded(expanded);
            if (expanded && standardServices.length < 1) {
                loadStandardServices();
            }
            if (expanded && standardRoomTypes.length < 1) {
                setLoading(true);
                await loadStandardRoomTypes();
                setLoading(false);
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <CenterFocusStrong style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.qualityManagement")}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.cleaningOperations?.isQmControlEnabled}
                                onChange={(event: any) => {
                                    if (row.cleaningOperations) {
                                        row.cleaningOperations.isQmControlEnabled = event.target.checked;
                                        refreshSettings();
                                    }
                                }}
                                name=""
                                color="primary" />}
                            label={`${t("settingsPage.qualityManagement")} ${t("settingsPage.controlActivated")}`} />
                    </Grid>



                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>{t("myUnitsSettings.selectionInObjectGreenbirdShowscaseErrorPoints", { title: `${currentObjectUnit?.name}: ${QMSettings.GetLabelByType(currentObjectUnit?.qmSettings?.qmRatingType)}` })} </Typography>
                {ItemsQM}


            </AccordionDetails>
        </Accordion>
    );
};

interface ItemThingSectionProps {
    row: GroupSettings | UserGroupSettingsTemplate;
    itemThingType: ItemThingType,
    standardRoomType: StandardWorkItem | undefined
}


const ItemThingSection = (props: ItemThingSectionProps) => {
    const { refreshSettings } = React.useContext(SettingsContext);
    const {t} = useTranslation();

    const title = React.useMemo(() => {
        return ItemThing.getItemThingTypeText(props.itemThingType, t);
    }, [props.itemThingType])

    return (
        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
            <Grid item lg={4}>
                <Button
                    size="large"
                    fullWidth
                    startIcon={<Add />}
                    variant={'contained'}
                    onClick={async () => {
                        if (props.row.cleaningOperations && !props.row.cleaningOperations?.itemThings) props.row.cleaningOperations.itemThings = [];
                        props.row.cleaningOperations?.itemThings?.push(new ItemThing("", true, props.itemThingType))
                        refreshSettings();
                    }}
                    color="primary"
                >
                    {t("myUnitsSettings.addItem", {title: title})}
                </Button>

            </Grid>
            {props.standardRoomType &&
                <Grid item>
                    <RoomTypeItemsDialog
                        onLoadFiles={async () => {
                        }}
                        allStandardItems={props.standardRoomType.extras.filter(x => x.type == props.itemThingType)}
                        currentItems={props.row?.cleaningOperations?.itemThings?.filter(x => x.itemThingType == props.itemThingType).map(el => props?.standardRoomType?.extras.find(x => x.text == el.text) || new StandardWorkItemExtras("", 0)) || []}
                        manageItems={(newItems: StandardWorkItemExtras[], itemThingType: ItemThingType) => {
                            if (!props.row.cleaningOperations?.itemThings) return;
                            props.row.cleaningOperations.itemThings = [...props.row.cleaningOperations.itemThings, ...newItems.map(x => new ItemThing(x.text, true, itemThingType))];
                            refreshSettings();
                        }}
                        itemThingType={props.itemThingType}
                    />
                </Grid>
            }
            <Grid container direction={"row"} item lg={8} spacing={3}>
                {props.row.cleaningOperations?.itemThings?.filter(x => x.itemThingType == props.itemThingType).map(itemThing => {
                    return (
                        <Grid container item lg={4} direction={"row"} alignItems={"center"} spacing={1}>
                            <Grid item >
                                <Fab
                                    size="small"
                                    style={{ backgroundColor: errorColor, color: white }}
                                    onClick={() => {
                                        if (props.row.cleaningOperations?.itemThings) {
                                            props.row.cleaningOperations.itemThings = props.row.cleaningOperations?.itemThings?.filter(x => !(x.text == itemThing.text && itemThing.itemThingType == x.itemThingType));
                                            refreshSettings();
                                        }
                                    }}>
                                    <Remove />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="itemThings"
                                    label={`Gegenstand ${title}`}
                                    value={itemThing.text || ''}
                                    onChange={(event: any) => {
                                        itemThing.text = event.target.value;
                                        refreshSettings();
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={itemThing.isEnabled}
                                        onChange={(event: any) => {
                                            itemThing.isEnabled = event.target.checked;
                                            refreshSettings();
                                        }}
                                        name="cleaningOperations"
                                        color="primary" />
                                    }
                                    label="Aktiviert" />
                            </Grid>
                        </Grid>

                    )
                })}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    )
}