import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { api } from '../../../../api';
import { errorColor, white } from '../../../../styles/colors';
import { assignGroupsFromObjectToServiceWorker, unAssignGroupsFromObjectToServiceWorker } from '../serviceWorker/ObjectSelectionAssignServiceWorkerToObjectDialog';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { getApiToken } from '../../../../helpers/storage-management';
import { useTranslation } from 'react-i18next';

export const UserServiceWorkerImportForObjectsDialog = ({
  open,
  setOpen,
  data,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  data: { object: ObjectUnit, userServiceWorker: UserServiceWorker }[]
  onRefresh: any,
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
  const [token, setToken] = React.useState("");
  const [isAssignGroupsFromObjectEnabled, setIsAssignGroupsFromObjectEnabled] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();
  const handleClose = () => {
    setOpen(false);
  }

  const disabled = data.length < 1 || loading;

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);

  React.useEffect(() => {

    (async () => {
      if (!open) return;
      setLoading(true);

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);


      const tempGroups = await api.getAllGroups(tempToken);
      setGroups(tempGroups);
      setLoading(false);

    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Import Servicekräfte auf Objekte zuweisen: Anzahl {data.length}</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        {loading && `Noch ${count} Dateneinheiten laden`}
        {loading && <CircularProgress size={64} />}
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          style={{ display: 'inline-block', textAlign: 'right', width: '100%' }}
          control={
            <Checkbox
              checked={isAssignGroupsFromObjectEnabled}
              onChange={(event: any) => { setIsAssignGroupsFromObjectEnabled(event.target.checked); }}
              name="isEnabled"
              color="primary"
            />
          }
          label="Einheiten des Objekts, der Servicekraft zuweisen/entfernen"
        />
      </DialogActions>
      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={disabled ? {} : { backgroundColor: errorColor, color: white }}
          disabled={disabled}
          endIcon={<Delete />}
          onClick={async () => {
            setLoading(true);
            setCount(data.length);
            for (let prop of data) {
              await api.deleteServiceWorkerFromObject(prop.object.id, prop.userServiceWorker.id, token, enqueueSnackbar);

              await unAssignGroupsFromObjectToServiceWorker(isAssignGroupsFromObjectEnabled, prop.object, groups, prop.userServiceWorker, token, enqueueSnackbar);
              setCount(prev=>prev - 1);
            }
            setLoading(false);
            onRefresh();
            handleClose();
          }}
        >
          Entfernen
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          size="large"
          endIcon={<Add />}
          onClick={async () => {
            setLoading(true);
            setCount(data.length);

            for (let prop of data) {
              await api.assignServiceWorkerToObject(prop.object.id, prop.userServiceWorker.id, token, enqueueSnackbar);

              await assignGroupsFromObjectToServiceWorker(isAssignGroupsFromObjectEnabled, prop.object, groups, prop.userServiceWorker, token, enqueueSnackbar);
              setCount(prev=>prev- 1);
            }
            setLoading(false);
            onRefresh();
            handleClose();
          }}
        >
          Zuweisen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));