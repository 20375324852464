import React, { useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { DrawerMenuItemDisplayType } from '../../ObjectMenuPointDrawer';
import { GlobalContext } from '../../PortalPage';
import { OBJECT_COMBINATION } from '../../../../constants';
import { ObjectStockTable } from './table/ObjectStockTable';
import { ImportanceLevel, MaterialStockGroupEntry, MaterialStockSummaryTableEntry, UserStock } from '../../../../models/UserStock';
import { StandardWorkItem, StandardWorkItemType } from '../../../../models/StandardItem';
import { getUserIdFromStorage } from '../../../../helpers/common';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { GroupSettings } from '../../../../models/GroupSettings';
import { ObjectMaterialStockSummaryTable } from './table/ObjectMaterialStockSummaryTable';
import { ExpandMore, RefreshOutlined, TableRows } from '@mui/icons-material';
import { UserStockExpanded } from '../../../../models/UserStockExpanded';
import { ObjectStockTableExpanded } from './table/ObjectStockTableExpaned';
import { UserAccountBasic } from '../../../../models/UserAccountLight';
import useNoInitialEffect from '../../../../helpers/extensions';
import { APIDataAmountType } from '../../../../helpers/charts/base';

export const ObjectStockDashboard = ({ displayType = DrawerMenuItemDisplayType.Single }: { displayType?: DrawerMenuItemDisplayType }) => {


    const history = useHistory();
    const [userStocks, setUserStocks] = React.useState<UserStock[]>([]);

    const [usersInformation, setUsersInformation] = React.useState<UserAccountBasic[]>([]);


    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const [selectedObjectUnit, setSelectedObjectUnit] = React.useState<ObjectUnit>();
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);

    const [materials, setMaterials] = React.useState<StandardWorkItem[]>([]);
    const [groups, setGroups] = React.useState<GroupSettings[]>([]);

    const [allGroups, setAllGroups] = React.useState<GroupSettings[]>([]);

    const [userStocksExpanded, setUserStocksExpanded] = React.useState<UserStockExpanded[]>([]);

    const [materialStockSummaryEntries, setMaterialStockSummaryEntries] = React.useState<MaterialStockSummaryTableEntry[]>();
    const [allMaterialStockSummaryEntries, setAllMaterialStockSummaryEntries] = React.useState<MaterialStockSummaryTableEntry[]>();


    const [showAllExpanded, setShowAllExpanded] = React.useState(false);
    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);


    const [isFilterZeroEntriesSummaryEntriesEnabled, setIsFilterZeroEntriesSummaryEntriesEnabled] = React.useState(false);
    const [showDiffZeroEnabled, setShowDiffZeroEnabled] = React.useState(false);

    const [showOnlyBelowMinimum, setShowOnlyBelowMinimum] = React.useState(false);
    const [loadingCustomDashboards, setLoadingCustomDashboards] = React.useState(false);
    const [token, setToken] = React.useState("");


    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);

    }, [history.location.pathname]);

    useNoInitialEffect(() => {
        setApiDataLoading(true);
        setLoading(true);
        (async () => {

            await loadDataAsync();
            setLoading(false);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);

    const loadDataAsync = React.useCallback(async () => {
        const token = getApiToken(history);
        setToken(token);
        if (!token) return;


        let tempObjects: ObjectUnit[] = [];
        if (displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) {
            tempObjects = await api.getUserObjectUnits(token);
        } else {
            const tempObject = await api.getUserObjectUnit(selectedObjectId, token);
            setSelectedObjectUnit(tempObject);
            tempObjects = [tempObject];
        }
        setObjects(tempObjects);



        let groups = await api.getGroupSettingsByObjectId(token, tempObjects.map(x => x.id));
        setAllGroups(groups);


        let userStocks: UserStock[] = [];
        if (displayType == DrawerMenuItemDisplayType.All) {
            userStocks = await api.getUserStocks(token, [], []);
            setUserStocks(userStocks);
        } else if (displayType == DrawerMenuItemDisplayType.Multiple) {
            let tempCombObject = localStorage.getItem(OBJECT_COMBINATION) || "[]";
            userStocks = await api.getUserStocks(token, [tempCombObject], groups.map(x => x.id || ""));
            setUserStocks(userStocks);
        }
        else {
            userStocks = await api.getUserStocks(token, [selectedObjectId], groups.map(x => x.id || ""));
            setUserStocks(userStocks);
        }

        let userIds = Array.from(new Set(userStocks.map(x => x.userId)));

        let groupIds = Array.from(new Set(userStocks.map(x => x.groupId)));
        if (groupIds.length > 0) {
            setGroups(groups.filter(x => x?.id && groupIds.includes(x.id)));
        }


        const tempServiceWorkers = await api.getServiceWorkers(token);
        setUserServiceWorkers(tempServiceWorkers);

        const usersTemp = await api.getBasicUserInformationByUserIds(token, userIds);
        setUsersInformation(usersTemp);



        const tempMaterials = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.Material, getUserIdFromStorage())
        setMaterials(tempMaterials);



        let userStocksExpanded = userStocks.reduce((prevVal, curVal) => {
            curVal.materialStocks.forEach((value) => {
                let newVal = UserStockExpanded.CreateInstance(curVal, value);
                newVal.materialText = tempMaterials.find(x => x.id == value.materialId)?.text || "";

                prevVal.push(newVal);
            });
            return prevVal;
        }, [] as UserStockExpanded[]);

        setUserStocksExpanded(userStocksExpanded);

        await loadTableSummaryDetails(tempMaterials, tempObjects, userStocks, userStocks, groups);



    }, [history.location.pathname]);

    let userStockExpandedEntriesToShow = React.useMemo(() => {
        if (showAllExpanded) {
            return userStocksExpanded;
        } else {
            return userStocksExpanded.filter(x => !((x.materialStock.lastGroupStockAmount - x.materialStock.currentGroupStockAmount) == 0 && (x.materialStock.lastObjectStockAmount - x.materialStock.currentObjectStockAmount == 0)))
        }
    }, [userStocksExpanded, showAllExpanded]);

    const loadTableSummaryDetails = (tempMaterials: StandardWorkItem[], tempObjects: ObjectUnit[], localUserStocks: UserStock[], mergedUserStocks: UserStock[], localGroups: GroupSettings[]) => {
        let tableSummaryDetails = tempMaterials.reduce((prevVal, curValMaterial) => {
            let entries: MaterialStockSummaryTableEntry[] = [];
            tempObjects.forEach(currentObject => {
                let lastObjectStock = localUserStocks.find(x => x.objectId == currentObject.id);





                if (lastObjectStock) {
                    let newEntry: MaterialStockSummaryTableEntry = new MaterialStockSummaryTableEntry();

                    let curMaterialHistory = localUserStocks.filter(x => x.objectId == currentObject.id).flatMap(x => x.materialStocks).filter(x => x.materialId == curValMaterial.id);


                    let objectStockSumOutput = 0;
                    let objectStockSumInput = 0;
                    let diffSumMaterial = curMaterialHistory.reduce((prevVal, curVal) => {
                        let diff = (curVal?.currentObjectStockAmount || 0) - (curVal?.lastObjectStockAmount || 0);
                        if (diff > 0) {
                            objectStockSumInput += diff;
                        }
                        if (diff < 0) {
                            objectStockSumOutput += diff;
                        }
                        return 0;
                    }, 0);
                    newEntry.objectStockSumInput = objectStockSumInput;
                    newEntry.objectStockSumOutput = objectStockSumOutput;

                    let lastDiffObjectStock = localUserStocks.find(x => x.materialStocks.some(y => y.materialId == curValMaterial.id && y.lastObjectStockAmount != y.currentObjectStockAmount));

                    let mostRecentMaterial = lastObjectStock?.materialStocks.find(x => x.materialId == curValMaterial.id);

                    newEntry.minimumObjectStock = mostRecentMaterial?.minimumObjectAmount || 0;
                    newEntry.maximumObjectStock = mostRecentMaterial?.maximumObjectAmount || 0;

                    newEntry.importanceLevelObject = mostRecentMaterial?.importanceLevelObject || ImportanceLevel.Default;

                    if (lastDiffObjectStock) {
                        lastObjectStock = lastDiffObjectStock;
                    }
                    let material = lastObjectStock?.materialStocks.find(x => x.materialId == curValMaterial.id);

                    newEntry.materialId = curValMaterial.id;
                    newEntry.materialText = curValMaterial.text;
                    newEntry.article = curValMaterial.article;
                    newEntry.entity = curValMaterial.entity;

                    newEntry.objectStock = material?.currentObjectStockAmount || 0;
                    newEntry.objectStockDifference = (material?.currentObjectStockAmount || 0) - (material?.lastObjectStockAmount || 0);



                    newEntry.lastStockDate = lastObjectStock?.createdTimestamp || new Date();
                    newEntry.objectId = currentObject.id;
                    newEntry.stockSum = newEntry.objectStock;
                    newEntry.groups = localGroups.filter(x => mergedUserStocks.some(y => y.groupId == x.id && (y.objectId == "" || y.objectId == currentObject.id))).map(group => {
                        let groupEntry = new MaterialStockGroupEntry();
                        let lastGroupStocks = mergedUserStocks.filter(x => x.groupId == group.id).sort((a,b)=>a.createdTimestamp < b.createdTimestamp ? 1 : -1);
                        if (lastGroupStocks?.length > 0 ) {
                            let lastGroupStock = lastGroupStocks[0];
                            let lastMaterialGroupStock = lastGroupStock.materialStocks.find(x => x.materialId == curValMaterial.id);
                            if (lastMaterialGroupStock) {
                                groupEntry.groupId = lastGroupStock.groupId;
                                groupEntry.stockAmount = lastMaterialGroupStock.currentGroupStockAmount;
                                groupEntry.minimumStockAmount = lastMaterialGroupStock.minimumGroupAmount;
                                newEntry.stockSum = newEntry.stockSum + groupEntry.stockAmount;
                            }

                        }
                        return groupEntry;
                    });
                    entries.push(newEntry);
                }

            })
            return [...prevVal, ...entries];
        }, [] as MaterialStockSummaryTableEntry[]);
        setMaterialStockSummaryEntries(tableSummaryDetails?.filter(x => x.objectStockDifference != 0));
        setAllMaterialStockSummaryEntries(tableSummaryDetails);
    }


    return (
        <Paper>
            <Typography style={{ paddingBottom: 30 }} variant='h3' component={"h3"}>Lager - Analyse {selectedObjectUnit?.name}</Typography>
            <Divider />
            {loading && <CircularProgress size={128} />}
            {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item>
                                <TableRows />
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">Log Tabelle Einträge: {userStocks.length}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ObjectStockTable
                            usersInformation={usersInformation}
                            groups={groups}
                            selectedObjectUnit={selectedObjectUnit}
                            userStocks={userStocks}
                            userServiceWorkers={userServiceWorkers}
                            objects={objects}
                            materials={materials}
                        />
                    </AccordionDetails>
                </Accordion>
            }
            {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item>
                                <TableRows />
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">Log Tabelle Einträge aufgebrochen: {userStockExpandedEntriesToShow.length}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControlLabel
                            control={<Checkbox
                                checked={showAllExpanded}
                                onChange={(event: any) => {
                                    setShowAllExpanded(event.target.checked);
                                }}
                                name="isDeviceRestartTimeEnabled"
                                color="primary" />}
                            label="Zeige alle Einträge" />
                        <ObjectStockTableExpanded
                            groups={groups}
                            selectedObjectUnit={selectedObjectUnit}
                            userStocks={userStockExpandedEntriesToShow}
                            userServiceWorkers={userServiceWorkers}
                            objects={objects}
                            usersInformation={usersInformation}
                        />
                    </AccordionDetails>
                </Accordion>
            }
            {materialStockSummaryEntries &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item>
                                <TableRows />
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">Material Analyse Einträge: {materialStockSummaryEntries.length}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loadingCustomDashboards}
                            endIcon={loadingCustomDashboards && <CircularProgress size={32} />}
                            startIcon={<RefreshOutlined />}
                            onClick={async () => {
                                setLoadingCustomDashboards(true);

                                let missingGroups = allGroups.filter(x => !groups.some(y => y.id == x.id));
                                missingGroups.filter(x => x.locationInformation?.objectUnitId && objects.map(y => y.id).includes(x.locationInformation.objectUnitId));
                                let extraUserStocks = await api.getLatestUserStockByGroupIds(token, missingGroups.map(x => x.id || ""));
                                let mergedUserStocks = [...userStocks, ...extraUserStocks].sort((a,b)=>a.createdTimestamp < b.createdTimestamp ? 1 : -1);
                                let groupIds = Array.from(new Set(mergedUserStocks.map(x => x.groupId)));
                                let newGropus = allGroups.filter(x => x?.id && groupIds.includes(x.id));
                                if (groupIds.length > 0) {
                                    setGroups(newGropus);
                                }

                                await loadTableSummaryDetails(materials, objects, userStocks, mergedUserStocks, newGropus);

                                setLoadingCustomDashboards(false);
                            }}
                        >
                            Gesamtlagerstand aller Einheiten abfragen (außerhalb ausgewählten Zeitraums)
                        </Button>
                    </AccordionDetails>
                    <AccordionDetails>

                        <FormControlLabel
                            control={<Checkbox
                                checked={isFilterZeroEntriesSummaryEntriesEnabled}
                                onChange={(event: any) => {
                                    if (event.target.checked) {
                                        setMaterialStockSummaryEntries(allMaterialStockSummaryEntries?.filter(x => x.stockSum > 0));
                                    } else {
                                        setMaterialStockSummaryEntries(allMaterialStockSummaryEntries);
                                    }
                                    setIsFilterZeroEntriesSummaryEntriesEnabled(event.target.checked);
                                    setShowOnlyBelowMinimum(false);
                                    setShowDiffZeroEnabled(false);
                                }}
                                name="isDeviceRestartTimeEnabled"
                                color="primary" />}
                            label="Einträge mit Gesamtlagerstand 0 ausblenden" />
                        <FormControlLabel
                            control={<Checkbox
                                checked={showDiffZeroEnabled}
                                onChange={(event: any) => {
                                    if (event.target.checked) {
                                        setMaterialStockSummaryEntries(allMaterialStockSummaryEntries);
                                    } else {
                                        setMaterialStockSummaryEntries(allMaterialStockSummaryEntries?.filter(x => x.objectStockDifference != 0));
                                    }
                                    setIsFilterZeroEntriesSummaryEntriesEnabled(false);
                                    setShowOnlyBelowMinimum(false);
                                    setShowDiffZeroEnabled(event.target.checked);
                                }}
                                name="isDeviceRestartTimeEnabled"
                                color="primary" />}
                            label="Einträge mit Differenz 0 im Objekt anzeigen" />
                        <FormControlLabel
                            control={<Checkbox
                                checked={showOnlyBelowMinimum}
                                onChange={(event: any) => {
                                    if (event.target.checked) {
                                        setMaterialStockSummaryEntries(allMaterialStockSummaryEntries?.filter(x => x.minimumObjectStock > x.objectStock || x.groups.some(y => y.minimumStockAmount > y.stockAmount)));
                                    } else {
                                        setMaterialStockSummaryEntries(allMaterialStockSummaryEntries);
                                    }
                                    setShowOnlyBelowMinimum(event.target.checked);
                                    setShowDiffZeroEnabled(false);
                                    setIsFilterZeroEntriesSummaryEntriesEnabled(false);

                                }}
                                name="isDeviceRestartTimeEnabled"
                                color="primary" />}
                            label="Zeige nur, wo Mindestmenge unterschritten wurde" />
                        <ObjectMaterialStockSummaryTable objects={objects} materialStockSummaryTableEntrys={materialStockSummaryEntries} groups={groups} />
                    </AccordionDetails>
                </Accordion>
            }
        </Paper>
    );
}