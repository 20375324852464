import { GroupSettingsIncidenName, Sensor } from "./GroupSettings";


export enum ServiceCancelReasonType {
    NotCancelled = 0,
    BackendCleanUp = 1,
    WaitingScreenFrontend = 2,
    WaitingScreenMobile = 3
}


export class GroupServiceMode {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId !: string;
    public isOnTime!: boolean;
    public isCompleted!: boolean;
    public isInProgress!: boolean;
    public materialConsumptionItems!: GroupServiceModeMaterialConsumption[];
    public incidentReportItems!: string[];
    public cleaningTaskItems!: string[];
    public startTimestamp!: number;
    public endTimestamp!: number;
    public createdTimestamp!: number;
    public doneByUser!: string;
    public sensor!: Sensor;
    public finishedMotionTrigger!: boolean;
    public finishedFeedbackTrigger!: boolean;
    public finishedIntervalTrigger !: boolean;
    public isPartlyCompleted !: boolean;
    public isCanceled!: boolean;
    public serviceCancelReasonType !: ServiceCancelReasonType;
    public incidentPhotos !: GroupServiceModeIncidentPhoto[];
    public incidentTexts !: GroupServiceModeIncident[];
    public userServiceWorkerId !: string;
    public nfcCode !: string;

    public constructor(groupId: string, isOnTime: boolean, isCompleted: boolean, materialConsumptionItems: GroupServiceModeMaterialConsumption[], incidentReportItems: string[],
        cleaningTaskItems: string[], startTimestamp: number, endTimestamp: number, createdTimestamp: number, doneByUser: string, sensor: Sensor,
        finishedMotionTrigger: boolean, finishedFeedbackTrigger: boolean, finishedIntervalTrigger: boolean, isPartlyCompleted: boolean, isCanceled: boolean) {
        this.systemETag = null;
        this.groupId = groupId;
        this.isOnTime = isOnTime;
        this.isCompleted = isCompleted;
        this.materialConsumptionItems = materialConsumptionItems;
        this.incidentReportItems = incidentReportItems;
        this.cleaningTaskItems = cleaningTaskItems;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.createdTimestamp = createdTimestamp;
        this.doneByUser = doneByUser;
        this.sensor = sensor;
        this.finishedMotionTrigger = finishedMotionTrigger;
        this.finishedFeedbackTrigger = finishedFeedbackTrigger;
        this.finishedIntervalTrigger = finishedIntervalTrigger;
        this.isPartlyCompleted = isPartlyCompleted;
        this.isCanceled = isCanceled;
    }
}

export class GroupServiceModeIncidentPhoto {
    public description: string = '';
    public fileName: string = '';
    public contentType: string = '';
    constructor() {
        this.fileName = "";
        this.contentType = "";
    }
}

export class GroupServiceModeMaterialConsumption {
    public text!: string;
    public amount!: string | number;
    public constructor(text: string, amount: string | number) {
        this.text = text;
        this.amount = amount;
    }
}
export class GroupServiceCompletedCleaningTasks {
    [key: string]: string | number;
    public date!: string;
    public isCompleted!: number;
    public isIncomplete!: number
    public constructor(date: string, isCompleted: number, isIncomplete: number) {
        this.date = date;
        this.isCompleted = isCompleted;
        this.isIncomplete = isIncomplete;
    }
}
export class GroupServiceIsOnTimeCleaningTasks {
    [key: string]: string | number;
    public date!: string;
    public isOnTime!: number;
    public isNotOnTime!: number
    public constructor(date: string, isOnTime: number, isNotOnTime: number) {
        this.date = date;
        this.isOnTime = isOnTime;
        this.isNotOnTime = isNotOnTime;
    }
}

export class GroupServiceModeTable {
    public id !: string;
    public groupId!: string;
    public isOnTime!: boolean;
    public isCompleted!: boolean;
    public materialConsumptionItems!: any;
    public incidentReportItems!: string[];
    public incidentTexts !: GroupServiceModeIncident[];
    public cleaningTaskItems!: string[];
    public startTimestamp!: any;
    public endTimestamp!: any;
    public createdTimestamp!: any;
    public doneByUser!: string;
    public sensor!: Sensor;
    public finishedMotionTrigger!: boolean;
    public finishedFeedbackTrigger!: boolean;
    public finishedIntervalTrigger !: boolean;
    public isPartlyCompleted!: boolean;
    public isInProgress!: boolean;
    public isCanceled!: boolean;
    public incidentPhotos !: GroupServiceModeIncidentPhoto[];
    public userServiceWorkerId !: string;
    public serviceCancelReasonType !: ServiceCancelReasonType;
    public nfcCode !: string;


    public constructor(id: string, groupId: string, isOnTime: boolean, isCompleted: boolean, materialConsumptionItems: any[], incidentReportItems: string[], incidentTexts: GroupServiceModeIncident[], cleaningTaskItems: string[],
        startTimestamp: Date, endTimestamp: string, createdTimestamp: Date, doneByUser: string, sensor: Sensor,
        finishedMotionTrigger: boolean, finishedFeedbackTrigger: boolean, finishedIntervalTrigger: boolean, isPartlyCompleted: boolean, isInProgress: boolean, incidentPhotos: GroupServiceModeIncidentPhoto[], isCanceled: boolean, serviceCancelReasonType: ServiceCancelReasonType,userServiceWorkerId: string
        , nfcCode: string) {
        this.id = id;
        this.groupId = groupId;
        this.isOnTime = isOnTime;
        this.isCompleted = isCompleted;
        this.materialConsumptionItems = materialConsumptionItems;
        this.incidentReportItems = incidentReportItems;
        this.cleaningTaskItems = cleaningTaskItems;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.createdTimestamp = createdTimestamp;
        this.doneByUser = doneByUser;
        this.sensor = sensor;
        this.finishedMotionTrigger = finishedMotionTrigger;
        this.finishedFeedbackTrigger = finishedFeedbackTrigger;
        this.finishedIntervalTrigger = finishedIntervalTrigger;
        this.isPartlyCompleted = isPartlyCompleted;
        this.isInProgress = isInProgress;
        this.incidentPhotos = incidentPhotos;
        this.isCanceled = isCanceled;
        this.incidentTexts = incidentTexts;
        this.userServiceWorkerId = userServiceWorkerId;
        this.serviceCancelReasonType = serviceCancelReasonType;
        this.nfcCode = nfcCode;
    }

    static Columns = {
        id: 'id',
        object: "object",
        serviceWorker: 'serviceWorker',
        phoneNumber: 'phoneNumber',
        createdTimestamp: 'createdTimestamp',
        endTimestamp: 'endTimestamp',
        endTime: "endTime",
        startTime: "startTime",
        startTimestamp: 'startTimestamp',
        duration: 'duration',
        isOnTime: 'isOnTime',
        durationMinutes: 'durationMinutes',
        durationHours: 'durationHours',
        isCanceled: 'isCanceled',
        incidentTexts: 'incidentTexts',
        serviceCancelReasonType: 'serviceCancelReasonType',
        nfcCode : 'nfcCode'
    }

    static AvailableServiceCancelReasonTypes = [
        
        { value: ServiceCancelReasonType.NotCancelled, label: 'Kein Abbruch' },
        { value: ServiceCancelReasonType.BackendCleanUp, label: 'Inaktiv im Backend' },
        { value: ServiceCancelReasonType.WaitingScreenFrontend, label: 'RFID Wartebildschirm' },
        { value: ServiceCancelReasonType.WaitingScreenMobile, label: 'App Scan Wartebildschirm' },


    ];

    static GetLabelByServiceCancelReasonType = (type: ServiceCancelReasonType) => GroupServiceModeTable.AvailableServiceCancelReasonTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}

export class GroupServiceModeIncident {
    public reporterName: string = "";
    public incidentText: string = "";
    public incidentNames !: GroupServiceModeIncidenName[];
    constructor(reporterName: string, incidentText: string) {
        this.reporterName = reporterName;
        this.incidentText = incidentText;
    }
}

export class GroupServiceModeIncidenName {
    public text!: string;
    public price !: number;
    constructor(text: string, price: number) {
        this.text = text;
        this.price = price;
    }
    static CreateInstance = (text: string, price: number) => {
        let ret = new GroupServiceModeIncidenName(text, price);
        return ret;
    }
    static CreateInstanceFromSettings = (incidentName: GroupSettingsIncidenName) => {
        let ret = new GroupServiceModeIncidenName(incidentName.text, incidentName.price);
        return ret;
    }
}

export class GroupServiceModeIncidentImage {
    public incidentImage !: string;
    public description: string = "";
    public originalFilename !: string;
    public contentType !: string;

    constructor(incidentImage: string, description: string) {
        this.incidentImage = incidentImage;
        this.description = description;
    }
}