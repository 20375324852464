import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { Add, Cancel, Chat, Check, OpenInBrowser, Remove, RestartAlt, Warning } from '@mui/icons-material';
import { AppBar, Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Toolbar, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { LAST_PUBLIC_FEEDBACK } from '../../constants';
import CheckMeNowIcon from '../../assets/svgs/check-me-now_icon.svg'
import GreenbirdLogo from '../../assets/svgs/greenbird_logo.svg'
import cmnHardware from '../../assets/image/cmn.png';

import { ReactComponent as Smiles } from '../../assets/svgs/newSmiles.svg'
import { useQuery } from '../authorized/portal/checkout/ShopPage';
import { GroupSettingsLight } from '../../models/GroupSettingsExtended';
import { GroupFeedback } from '../../models/GroupFeedback';
import { getUnixTime, subHours } from 'date-fns';
import { primaryColor } from '../../App';
import { NetworkImageNonDialog } from '../images/NetworkImageNonDialog';
import { FeedbackDisplayType, FeedbackQuestion, GroupSettingsSmilesType, GroupSettingsIncidenReport, PublicFeedbackModeType, GroupSettingsIncidenName, GroupSettings } from '../../models/GroupSettings';
import { isBefore } from 'date-fns/esm';

import GREEN_SMILE_WINTER from '../../assets/image/smiles/winter_green.png'
import GREEN_SMILE_OCTOBER from '../../assets/image/smiles/smile_green_october.png'
import GREEN_SMILE_CHRISTMAS from '../../assets/image/smiles/green_christmas.png'
import GREEN_SMILE_SILVESTER from '../../assets/image/smiles/silvester_green.png'
import GREEN_SMILE_CARNIVAL from '../../assets/image/smiles/carnival_green.png'
import SMILE_GREEN_EASTERN from '../../assets/image/smiles/smile_green_eastern.png'
import SMILE_GREEN_SPRING from '../../assets/image/smiles/smile_green_spring.png'
import SMILE_GREEN_SUMMER from '../../assets/image/smiles/smile_green_summer.png'
import SMILE_GREEN_AUTUMN from '../../assets/image/smiles/smile_green_autumn.png'
import SMILE_YELLOW_AUTUMN from '../../assets/image/smiles/smile_yellow_autumn.png'
import SMILE_RED_AUTUMN from '../../assets/image/smiles/smile_red_autumn.png'

import YELLOW_SMILE_WINTER from '../../assets/image/smiles/winter_yellow.png'
import YELLOW_SMILE_OCTOBER from '../../assets/image/smiles/smile_yellow_october.png'
import YELLOW_SMILE_SILVESTER from '../../assets/image/smiles/silvester_yellow.png'
import YELLOW_SMILE_CARNIVAL from '../../assets/image/smiles/carnival_yellow.png'
import SMILE_YELLOW_EASTERN from '../../assets/image/smiles/smile_yellow_eastern.png'
import SMILE_YELLOW_SPRING from '../../assets/image/smiles/smile_yellow_spring.png'
import SMILE_YELLOW_SUMMER from '../../assets/image/smiles/smile_yellow_summer.png'
import YELLOW_SMILE_CHRISTMAS from '../../assets/image/smiles/yellow_christmas.png'


import RED_SMILE_WINTER from '../../assets/image/smiles/winter_red.png'
import RED_SMILE_OCTOBER from '../../assets/image/smiles/smile_red_october.png'
import RED_SMILE_CHRISTMAS from '../../assets/image/smiles/red_christmas.png'
import RED_SMILE_SILVESTER from '../../assets/image/smiles/silvester_red.png'
import RED_SMILE_CARNIVAL from '../../assets/image/smiles/carnival_red.png'
import SMILE_RED_EASTERN from '../../assets/image/smiles/smile_red_eastern.png'
import SMILE_RED_SPRING from '../../assets/image/smiles/smile_red_spring.png'
import SMILE_RED_SUMMER from '../../assets/image/smiles/smile_red_summer.png'








import GREEN_SMILE_WINTER2 from '../../assets/image/smiles2/green_winter_2.png'
import GREEN_SMILE_OCTOBER2 from '../../assets/image/smiles2/green_october_2.png'
import GREEN_SMILE_CHRISTMAS2 from '../../assets/image/smiles2/green_christmas_2.png'
import GREEN_SMILE_SILVESTER2 from '../../assets/image/smiles2/green_silvester_2.png'
import GREEN_SMILE_CARNIVAL2 from '../../assets/image/smiles2/green_carnelval_2.png'
import SMILE_GREEN_EASTERN2 from '../../assets/image/smiles2/green_eastern_2.png'
import SMILE_GREEN_SPRING2 from '../../assets/image/smiles2/green_spring_2.png'
import SMILE_GREEN_SUMMER2 from '../../assets/image/smiles2/green_summer_2.png'
import SMILE_GREEN_AUTUMN2 from '../../assets/image/smiles2/green_autumn_2.png'
import SMILE_GREEN_HALLOWEEN2 from '../../assets/image/smiles2/green_halloween_2.png'

import YELLOW_SMILE_WINTER2 from '../../assets/image/smiles2/yellow_winter_2.png'
import YELLOW_SMILE_OCTOBER2 from '../../assets/image/smiles2/yellow_october_2.png'
import YELLOW_SMILE_SILVESTER2 from '../../assets/image/smiles2/yellow_silvester_2.png'
import YELLOW_SMILE_CARNIVAL2 from '../../assets/image/smiles2/yellow_carneval_2.png'
import SMILE_YELLOW_EASTERN2 from '../../assets/image/smiles2/yellow_eastern_2.png'
import SMILE_YELLOW_SPRING2 from '../../assets/image/smiles2/yellow_spring_2.png'
import SMILE_YELLOW_SUMMER2 from '../../assets/image/smiles2/yellow_summer_2.png'
import YELLOW_SMILE_CHRISTMAS2 from '../../assets/image/smiles2/yellow_christmas_2.png'
import SMILE_YELLOW_AUTUMN2 from '../../assets/image/smiles2/yellow_autumn_2.png'
import SMILE_YELLOW_HALLOWEEN2 from '../../assets/image/smiles2/yellow_halloween_2.png'


import RED_SMILE_WINTER2 from '../../assets/image/smiles2/red_winter_2.png'
import RED_SMILE_OCTOBER2 from '../../assets/image/smiles2/red_october_2.png'
import RED_SMILE_CHRISTMAS2 from '../../assets/image/smiles2/red_christmas_2.png'
import RED_SMILE_SILVESTER2 from '../../assets/image/smiles2/red_silvester_2.png'
import RED_SMILE_CARNIVAL2 from '../../assets/image/smiles2/red_carneval_2.png'
import SMILE_RED_EASTERN2 from '../../assets/image/smiles2/red_eastern_2.png'
import SMILE_RED_SPRING2 from '../../assets/image/smiles2/red_spring_2.png'
import SMILE_RED_SUMMER2 from '../../assets/image/smiles2/red_summer_2.png'
import SMILE_RED_AUTUMN2 from '../../assets/image/smiles2/red_autumn_2.png'
import SMILE_RED_HALLOWEEN2 from '../../assets/image/smiles2/red_halloween_2.png'

import PayLicense from '../../assets/image/license/pay_license.png';


import { getDefaultIcon } from '../dialogs/DefaultItemsDialogOld';
import { StandardWorkItem, StandardWorkItemType } from '../../models/StandardItem';
import { loadStandardItems, StandardItemIcon } from '../core/GroupSettingFieldsFeedbackControl';
import { FeedbackQuestionType, GroupFeedbackQuestion } from '../../models/GroupFeedbackQuestion';
import { errorColor, white } from '../../styles/colors';
import { StringHelper } from '../../helpers/string-helper';
import { GroupIncidentVisitor } from '../../models/GroupIncidentVisitor';
import { UserAccount } from '../../models/UserAccount';
import { GroupServiceModeIncidenName, GroupServiceModeIncident } from '../../models/GroupServiceMode';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FreeTextPanelPayPerServiceComponent, PayPerServiceContentComponent } from './PayPerServicePublic';
import { GroupPayPerService, GroupPayPerServiceItem } from '../../models/GroupPayPerService';
import { useTranslation } from 'react-i18next';
export enum DisplayFeedbackPublicType {
  Feedback = 0,
  FeedbackQuestion = 1,
  IncidentVisitor = 2,
  FeedbackFreeText = 3,
  IncidentFreeText = 4,
  PayPerService = 5,
  PayPerServiceFreeText = 6,
  FeedbackQuestionPositive = 7,
  Suggestion = 8,
  Finished = 255,
}


export const getYellowSmile = (settings: GroupSettings | GroupSettingsLight | undefined, giveFeedbackYellow: any) => {

  switch (settings?.content?.smilesType) {
    case GroupSettingsSmilesType.October:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_OCTOBER} />;
    case GroupSettingsSmilesType.Winter:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_WINTER} />;
    case GroupSettingsSmilesType.Carnival:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_CARNIVAL} />;
    case GroupSettingsSmilesType.Silvester:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_SILVESTER} />;
    case GroupSettingsSmilesType.Christmas:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_CHRISTMAS} />;
    case GroupSettingsSmilesType.Eastern:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_EASTERN} />;
    case GroupSettingsSmilesType.Spring:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_SPRING} />;
    case GroupSettingsSmilesType.Summer:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_SUMMER} />
    case GroupSettingsSmilesType.Autumn:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_AUTUMN} />


    case GroupSettingsSmilesType.October2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_OCTOBER2} />;
    case GroupSettingsSmilesType.Winter2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_WINTER2} />;
    case GroupSettingsSmilesType.Carnival2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_CARNIVAL2} />;
    case GroupSettingsSmilesType.Silvester2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_SILVESTER2} />;
    case GroupSettingsSmilesType.Christmas2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={YELLOW_SMILE_CHRISTMAS2} />;
    case GroupSettingsSmilesType.Eastern2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_EASTERN2} />;
    case GroupSettingsSmilesType.Spring2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_SPRING2} />;
    case GroupSettingsSmilesType.Summer2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_SUMMER2} />
    case GroupSettingsSmilesType.Autumn2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_AUTUMN2} />
    case GroupSettingsSmilesType.Halloween2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackYellow} src={SMILE_YELLOW_HALLOWEEN2} />
    default:
      return (
        <Smiles onClick={giveFeedbackYellow} viewBox='680 0 340 350' />
      );
  }
}

export const getRedSmile = (settings: GroupSettings | GroupSettingsLight | undefined, giveFeedbackRed: any) => {

  switch (settings?.content?.smilesType) {
    case GroupSettingsSmilesType.October:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_OCTOBER} />;
    case GroupSettingsSmilesType.Winter:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_WINTER} />;
    case GroupSettingsSmilesType.Carnival:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_CARNIVAL} />;
    case GroupSettingsSmilesType.Silvester:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_SILVESTER} />;
    case GroupSettingsSmilesType.Christmas:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_CHRISTMAS} />;
    case GroupSettingsSmilesType.Eastern:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_EASTERN} />;
    case GroupSettingsSmilesType.Spring:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_SPRING} />;
    case GroupSettingsSmilesType.Summer:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_SUMMER} />
    case GroupSettingsSmilesType.Autumn:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_AUTUMN} />


    case GroupSettingsSmilesType.October2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_OCTOBER2} />;
    case GroupSettingsSmilesType.Winter2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_WINTER2} />;
    case GroupSettingsSmilesType.Carnival2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_CARNIVAL2} />;
    case GroupSettingsSmilesType.Silvester2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_SILVESTER2} />;
    case GroupSettingsSmilesType.Christmas2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={RED_SMILE_CHRISTMAS2} />;
    case GroupSettingsSmilesType.Eastern2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_EASTERN2} />;
    case GroupSettingsSmilesType.Spring2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_SPRING2} />;
    case GroupSettingsSmilesType.Summer2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_SUMMER2} />
    case GroupSettingsSmilesType.Autumn2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_AUTUMN2} />
    case GroupSettingsSmilesType.Halloween2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackRed} src={SMILE_RED_HALLOWEEN2} />

    default:
      return (
        <Smiles onClick={giveFeedbackRed} viewBox='1365 0 340 350' />
      );
  }
}

export const getGreenSmile = (settings: GroupSettings | GroupSettingsLight | undefined, giveFeedbackGreen: any) => {

  switch (settings?.content?.smilesType) {
    case GroupSettingsSmilesType.October:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_OCTOBER} />;
    case GroupSettingsSmilesType.Winter:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_WINTER} />;
    case GroupSettingsSmilesType.Carnival:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_CARNIVAL} />;
    case GroupSettingsSmilesType.Silvester:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_SILVESTER} />;
    case GroupSettingsSmilesType.Christmas:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_CHRISTMAS} />;
    case GroupSettingsSmilesType.Eastern:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_EASTERN} />;
    case GroupSettingsSmilesType.Spring:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_SPRING} />;
    case GroupSettingsSmilesType.Summer:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_SUMMER} />;
    case GroupSettingsSmilesType.Autumn:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_AUTUMN} />;





    case GroupSettingsSmilesType.October2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_OCTOBER2} />;
    case GroupSettingsSmilesType.Winter2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_WINTER2} />;
    case GroupSettingsSmilesType.Carnival2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_CARNIVAL2} />;
    case GroupSettingsSmilesType.Silvester2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_SILVESTER2} />;
    case GroupSettingsSmilesType.Christmas2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={GREEN_SMILE_CHRISTMAS2} />;
    case GroupSettingsSmilesType.Eastern2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_EASTERN2} />;
    case GroupSettingsSmilesType.Spring2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_SPRING2} />;
    case GroupSettingsSmilesType.Summer2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_SUMMER2} />;
    case GroupSettingsSmilesType.Autumn2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_AUTUMN2} />;
    case GroupSettingsSmilesType.Halloween2:
      return <img style={{ width: "100%" }} onClick={giveFeedbackGreen} src={SMILE_GREEN_HALLOWEEN2} />



    default:
      return (
        <Smiles onClick={giveFeedbackGreen} viewBox='0 0 340 350' />
      );
  }
}

export const FeedbackPublic = () => {

  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);


  const groupId = useQuery(history).get("groupId");
  const userId = useQuery(history).get("userId");
  const [settings, setSettings] = React.useState<GroupSettingsLight>();
  const [userAccount, setUserAccount] = React.useState<UserAccount>();
  const [source, setSource] = React.useState('');
  const [sourceUserLogo, setSourceUserLogo] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [lastPublicFeedback, setLastPublicFeedback] = React.useState<Date>();
  const [currentDatetime, setCurrentDatetime] = React.useState<Date>(subHours(new Date(), 3));
  const [selectedFeedbackQuestions, setSelectedFeedbackQuestions] = React.useState<FeedbackQuestion[]>([]);
  const [displayFeedbackPublicType, setDisplayFeedbackPublicType] = React.useState<DisplayFeedbackPublicType>(DisplayFeedbackPublicType.Feedback);
  const [standardFeedbackQuestion, setStandardFeedbackQuestion] = React.useState<StandardWorkItem[]>([]);
  const [standardIncidents, setStandardIncidents] = React.useState<StandardWorkItem[]>([]);
  const [selectedIncidents, setSelectedIncidents] = React.useState<GroupServiceModeIncident[]>([]);
  const [selectedPayPerServices, setSelectedPayPerServices] = React.useState<GroupPayPerServiceItem[]>([]);

  const [customTextsFeedback, setCustomTextsFeedback] = React.useState<string[]>([]);
  const [customTextsIncident, setCustomTextsIncident] = React.useState<GroupServiceModeIncident[]>([]);
  const [customTextsPayPerService, setCustomTextsPayPerService] = React.useState<GroupPayPerServiceItem[]>([]);

  const [messenger, setMessenger] = React.useState("");
  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let settings = await api.getGroupSettingsLight(groupId || "", userId || "");
      setSettings(settings);
      setSource(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/images/logos/${settings.logo?.fileName}`);
      const userAccount = await api.getLightUser(userId);
      setUserAccount(userAccount);
      setSourceUserLogo(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/logos/${userAccount.logo?.fileName}`);
      let date: Date = JSON.parse(localStorage.getItem(LAST_PUBLIC_FEEDBACK) || "null");
      if (date) {
        setLastPublicFeedback(new Date(date));
      }
      setLoading(false);
      if (settings.feedbacks?.publicFeedbackModeType == PublicFeedbackModeType.OnlyFeedbackQuestion) {
        if (settings?.feedbacks?.isFeedbackQuestionEnabled) {
          setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestion);
        } else if (settings.feedbacks.isFeedbackQuestionPositiveEnabled) {
          setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestionPositive);
        }
      }
      else if (settings.feedbacks?.publicFeedbackModeType == PublicFeedbackModeType.OnlyIncident) {
        setDisplayFeedbackPublicType(DisplayFeedbackPublicType.IncidentVisitor);
      }
      else if (settings.feedbacks?.publicFeedbackModeType == PublicFeedbackModeType.OnlyPayPerService) {
        setDisplayFeedbackPublicType(DisplayFeedbackPublicType.PayPerService);
      }

    })();
  }, []);

  React.useEffect(() => {
    if (displayFeedbackPublicType == DisplayFeedbackPublicType.FeedbackQuestion && settings?.feedbacks?.feedbackQuestions) {
      loadStandardItems("", setStandardFeedbackQuestion, enqueueSnackbar, settings?.feedbacks?.feedbackQuestions, userId || "");
    }
    if (displayFeedbackPublicType == DisplayFeedbackPublicType.IncidentVisitor && settings?.incidentReports?.incidentReports) {
      loadStandardItems("", setStandardIncidents, enqueueSnackbar, settings.incidentReports.incidentReports, userId || "", StandardWorkItemType.Incident);
    }
  }, [displayFeedbackPublicType])


  const onClose = () => {
    history.goBack();
  }
  const giveFeedbackYellow = async () => {
    setLastPublicFeedbackTimeStorage();
    let feedback = new GroupFeedback(groupId || "", 0, 0, 1, getUnixTime(new Date()));
    feedback.userId = userId || "";
    onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionNeutral, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionNeutral, settings?.feedbacks?.isEnabledSuggestionNeutral);
  };

  const onFeedbackClick = async (feedback: GroupFeedback, feedbackNegativDialogEnabled: boolean | undefined, feedbackPositiveDialogEnabled: boolean | undefined, isSuggestionDialogEnabled: boolean | undefined) => {
    if (settings?.feedbacks?.isFeedbackQuestionEnabled && feedbackNegativDialogEnabled) {
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestion);
    }
    else if (settings?.feedbacks?.isFeedbackQuestionPositiveEnabled && feedbackPositiveDialogEnabled) {
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestionPositive);

    }
    else if (settings?.feedbacks?.isSuggestionEnabled && isSuggestionDialogEnabled) {
      setLastPublicFeedbackTimeStorage();
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.Finished);

    } else {
      setLastPublicFeedbackTimeStorage();
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.Finished);
    }
    await api.CreateFeedbackLightByGroupId(feedback, enqueueSnackbar);
  }

  const smileSize = settings?.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? 2 : 4;

  const setLastPublicFeedbackTimeStorage = () => {
    let newTime = new Date();
    setLastPublicFeedback(newTime);
    localStorage.setItem(LAST_PUBLIC_FEEDBACK, JSON.stringify(newTime));
  }

  const giveFeedbackGreen = async () => {
    setLastPublicFeedbackTimeStorage();
    let feedback = new GroupFeedback(groupId || "", 0, 0, 0, getUnixTime(new Date()));
    feedback.userId = userId || "";
    if (settings?.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative) {
      feedback.veryPositiveAmount = 1
      onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionVeryPositive, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionVeryPositive, settings?.feedbacks?.isEnabledSuggestionVeryPositive);
    } else {
      feedback.positiveAmount = 1
      onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionPositive, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionPositive, settings?.feedbacks?.isEnabledSuggestionPositive);
    }
  }






  const giveFeedbackRed = async () => {
    setLastPublicFeedbackTimeStorage();
    let feedback = new GroupFeedback(groupId || "", 0, 0, 0, getUnixTime(new Date()));
    feedback.userId = userId || "";
    if (settings?.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative) {
      feedback.veryNegativeAmount = 1;
      onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionVeryNegative, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionVeryNegative, settings?.feedbacks?.isEnabledSuggestionVeryNegative);
    } else {
      feedback.negativeAmount = 1
      onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionNegative, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionNegative, settings?.feedbacks?.isEnabledSuggestionNegative);
    }

  }

  const infoText1 = settings?.content?.publicDisplayText1;
  const infoText2 = settings?.content?.publicDisplayText2;

  const buildFeedbackContent = () => {
    return (
      <Grid container direction={"column"} spacing={3}>
        {/*
      <Grid item container direction={"column"}>
        <Grid item container direction="row">
          <Grid item xs={2}>
            <Typography>Objekt:</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={'bold'}>{settings?.locationInformation?.objectName}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={2}>
            <Typography>Ebene:</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={'bold'}>{settings?.locationInformation?.levelName}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={2}>
            <Typography>Einheit:</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={'bold'}>{settings?.locationInformation?.notificationName}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={2}>
            <Typography>Kunde:</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={'bold'}>{settings?.locationInformation?.roomName}</Typography>
          </Grid>
        </Grid>
      </Grid>
*/}
        <Grid item container justifyContent={"center"} spacing={5}>
          <Grid item xs={12}>
            <Typography className='text-public' style={{ color: settings?.content?.publicText1Color }} fontSize={20}>{settings?.content?.publicText1 || ""}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className='text-public' style={{ color: settings?.content?.publicDisplayText1Color }} fontSize={28} fontWeight={"bold"}>{infoText1}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className='text-public' style={{ color: settings?.content?.publicDisplayText2Color }} fontSize={28} fontWeight={"bold"}>{infoText2}</Typography>
          </Grid>
        </Grid>

        <Grid item container direction={"row"} spacing={3} justifyContent="center">
          <Grid item xs={smileSize}>
            {getGreenSmile(settings, giveFeedbackGreen)}
          </Grid>
          {settings?.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

            <Grid item xs={smileSize}>
              <Smiles onClick={async () => {
                let feedback = new GroupFeedback(groupId || "", 0, 0, 0, getUnixTime(new Date()));
                feedback.userId = userId || "";
                feedback.positiveAmount = 1;
                onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionPositive, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionPositive, settings?.feedbacks?.isEnabledSuggestionPositive);
              }} viewBox='340 0 340 350' />
            </Grid>
          }
          <Grid item xs={smileSize}>
            {getYellowSmile(settings, giveFeedbackYellow)}
          </Grid>


          {settings?.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
            <Grid item xs={smileSize}>
              <Smiles onClick={async () => {
                let feedback = new GroupFeedback(groupId || "", 0, 0, 0, getUnixTime(new Date()));
                feedback.userId = userId || "";
                feedback.negativeAmount = 1;
                onFeedbackClick(feedback, settings?.feedbacks?.isEnabledFeedbackQuestionNegative, settings?.feedbacks?.isEnabledPositiveFeedbackQuestionNegative, settings?.feedbacks?.isEnabledSuggestionNegative);
              }} viewBox='1020 0 340 350' />
            </Grid>
          }
          <Grid item xs={smileSize}>
            {getRedSmile(settings, giveFeedbackRed)}
          </Grid>

        </Grid>
      </Grid>
    )
  }

  const buildFinishedContent = () => {
    return (
      <Grid container direction={"column"} spacing={3}>
        <Grid item container justifyContent={"center"} spacing={5}>
          <Grid item xs={12}>
            <Typography className='text-public' style={{ color: settings?.content?.byeText1Color }} fontSize={28} fontWeight={"bold"}>
              {settings?.content?.byeText1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" className='text-public' fontSize={28} style={{ color: settings?.content?.byeText2Color }} fontWeight={"bold"}>{settings?.content?.byeText2}</Typography>
          </Grid>
          {settings?.feedbacks?.isPublicFeedbackRestartEnabled &&
            <Grid item xs={12} style={{ margin: 10 }}>
              <Button
                fullWidth
                size='large'
                startIcon={<RestartAlt />}
                variant={'contained'}
                color="error"
                onClick={async () => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Feedback Neustart
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }


  const navigateAfterFeedbackQuestion = () => {
    if (settings?.feedbacks?.publicFeedbackModeType == PublicFeedbackModeType.OnlyFeedbackQuestion) {
      setLastPublicFeedbackTimeStorage();
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.Finished);
    } else {
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.Feedback);
    }
  }

  const navigateAfterIncident = () => {
    if (settings?.feedbacks?.publicFeedbackModeType == PublicFeedbackModeType.OnlyIncident || settings?.feedbacks?.publicFeedbackModeType == PublicFeedbackModeType.OnlyPayPerService) {
      setLastPublicFeedbackTimeStorage();
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.Finished);
    } else {
      setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestion);
    }
  }


  const mapContent = () => {
    if (!settings) return;
    switch (displayFeedbackPublicType) {
      case DisplayFeedbackPublicType.Finished:
        return (
          buildFinishedContent()
        )
      case DisplayFeedbackPublicType.Feedback:
        return (
          !lastPublicFeedback || isBefore(lastPublicFeedback, currentDatetime) ? buildFeedbackContent() : buildFinishedContent()
        )
      case DisplayFeedbackPublicType.FeedbackQuestion:
        return (
          <FeedbackQuestionsDisplayByType feedbackQuestionHeader={settings.feedbacks?.feedbackQuestionsHeader || ""} feedbackQuestionType={FeedbackQuestionType.Negative} feedbackQuestions={settings?.feedbacks?.feedbackQuestions || []} settings={settings} navigateAfterFeedbackQuestion={navigateAfterFeedbackQuestion} customTextsFeedback={customTextsFeedback}
            setCustomTextsFeedback={setCustomTextsFeedback} selectedFeedbackQuestions={selectedFeedbackQuestions} setSelectedFeedbackQuestions={setSelectedFeedbackQuestions} setDisplayFeedbackPublicType={setDisplayFeedbackPublicType} messenger={messenger}
            standardFeedbackQuestion={standardFeedbackQuestion} />
        )
      case DisplayFeedbackPublicType.FeedbackQuestionPositive:
        return (
          <FeedbackQuestionsDisplayByType feedbackQuestionHeader={settings.feedbacks?.feedbackQuestionsPositiveHeader || ""} feedbackQuestionType={FeedbackQuestionType.Positive} feedbackQuestions={settings?.feedbacks?.feedbackQuestionsPositive || []} settings={settings} navigateAfterFeedbackQuestion={navigateAfterFeedbackQuestion} customTextsFeedback={customTextsFeedback}
            setCustomTextsFeedback={setCustomTextsFeedback} selectedFeedbackQuestions={selectedFeedbackQuestions} setSelectedFeedbackQuestions={setSelectedFeedbackQuestions} setDisplayFeedbackPublicType={setDisplayFeedbackPublicType} messenger={messenger}
            standardFeedbackQuestion={standardFeedbackQuestion} />
        )
      case DisplayFeedbackPublicType.IncidentVisitor:
        if (!settings) return;
        return (
          <>
            <BuildIncidentVisitorContent incidentNames={settings.incidentReports?.incidentNames || []} isWithNamePriceEnabled={settings.incidentReports?.isIncidentVisitorWithNamePriceEnabled || false} isWithNameEnabled={settings.incidentReports?.isIncidentVisitorWithNameEnabled || false} isFreeTextEnabled={settings.incidentReports?.isIncidentVisitorCustomTextEnabled || false} customTexts={customTextsIncident} items={settings.incidentReports?.incidentReports || []} selectedIncidents={selectedIncidents} setselectedIncidents={setSelectedIncidents} standardFeedbackQuestion={standardIncidents}
              onFinish={async () => {
                let chosenTexts = selectedIncidents.map(x => x);
                navigateAfterIncident();
                if (groupId && userId) {
                  await api.CreateIncidentVisitorLightByGroupId(new GroupIncidentVisitor(groupId, userId, chosenTexts, getUnixTime(new Date()), customTextsIncident, messenger || ""), groupId, userId, enqueueSnackbar);
                }
              }}
              onFreeText={() => {
                if (customTextsIncident.length < 1) {
                  setCustomTextsFeedback([""]);
                }
                setDisplayFeedbackPublicType(DisplayFeedbackPublicType.IncidentFreeText);
              }}
              onCancel={() => {
                navigateAfterIncident();
              }} />
          </>
        )
      case DisplayFeedbackPublicType.PayPerService:
        if (!settings) return;
        return (
          <>
            <PayPerServiceContentComponent PayPerServiceItemNames={settings.payPerServiceControl?.payPerServiceItemNames || []} isWithNamePriceEnabled={settings.payPerServiceControl?.isPayPerServiceWithNamePriceEnabled || false} isWithNameEnabled={settings.payPerServiceControl?.isPayPerServiceWithNameEnabled || false} isFreeTextEnabled={settings.payPerServiceControl?.isPayPerServiceCustomTextEnabled || false} customTexts={customTextsPayPerService} items={settings.payPerServiceControl?.payPerServiceItems || []}
              selectedPayPerServices={selectedPayPerServices} setselectedPayPerServices={setSelectedPayPerServices} standardFeedbackQuestion={standardIncidents}
              onFinish={async () => {
                let chosenTexts = selectedPayPerServices.map(x => x);
                navigateAfterIncident();
                if (groupId && userId) {
                  await api.CreatePayPerServiceLightByGroupId(new GroupPayPerService(groupId, userId, chosenTexts, getUnixTime(new Date()), customTextsPayPerService, messenger || ""), groupId, userId, enqueueSnackbar);
                }
              }}
              onFreeText={() => {
                if (customTextsIncident.length < 1) {
                  setCustomTextsFeedback([""]);
                }
                setDisplayFeedbackPublicType(DisplayFeedbackPublicType.PayPerServiceFreeText);
              }}
              onCancel={() => {
                navigateAfterIncident();
              }} />
          </>
        )
      case DisplayFeedbackPublicType.FeedbackFreeText:
        return (
          <FreeTextPanelComponent title='Feedbackantwort' messenger={messenger} setMessenger={setMessenger} customTextsFeedback={customTextsFeedback} setCustomTextsFeedback={setCustomTextsFeedback}
            onCancel={() => {
              setCustomTextsFeedback([]);
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestion);
            }} onFinish={() => {
              setCustomTextsFeedback(customTextsFeedback.filter(x => !StringHelper.IsNullOrWhiteSpace(x)));
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackQuestion);
            }} />
        )
      case DisplayFeedbackPublicType.IncidentFreeText:
        if (!settings) return;
        return (
          <FreeTextPanelIncidentComponent isWithNameEnabled={settings.incidentReports?.isIncidentVisitorWithNameEnabled || false} title='Vorfall' messenger={messenger} setMessenger={setMessenger} incidentText={customTextsIncident} setincidentText={setCustomTextsIncident}
            onCancel={() => {
              setCustomTextsIncident([]);
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.IncidentVisitor);
            }} onFinish={() => {
              setCustomTextsIncident(customTextsIncident.filter(x => !StringHelper.IsNullOrWhiteSpace(x.incidentText)));
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.IncidentVisitor);
            }} />
        )
      case DisplayFeedbackPublicType.PayPerServiceFreeText:
        if (!settings) return;
        return (
          <FreeTextPanelPayPerServiceComponent payPerServiceItemNames={settings.payPerServiceControl?.payPerServiceItemNames || []} isWithNameEnabled={settings.payPerServiceControl?.isPayPerServiceWithNameEnabled || false} title='Zimmer' messenger={messenger} setMessenger={setMessenger} roomText={customTextsPayPerService} setroomText={setCustomTextsPayPerService}
            onCancel={() => {
              setCustomTextsPayPerService([]);
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.PayPerService);
            }} onFinish={() => {
              setCustomTextsPayPerService(customTextsPayPerService.filter(x => !StringHelper.IsNullOrWhiteSpace(x.roomText)));
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.PayPerService);
            }} />
        )
    }
  }

  const previewView = () => {
    return (
      <Grid container direction={"column"} justifyContent="center" alignItems={"center"} spacing={3}>
        <Grid item>
          <Typography style={{ textAlign: 'center' }} color="primary">Check Me Now: Feedback</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ textAlign: 'center', fontSize: 40, fontWeight: 'bold', color: primaryColor }}>Willkommen</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ textAlign: 'center', fontSize: 30 }}>Sie werden gleich weitergeleitet</Typography>
        </Grid>
      </Grid>
    )
  }


  return (
    <Dialog fullScreen open={true}>
      <DialogTitle className={classes.dialogTitle}>
        <AppBar position="sticky">
          <Toolbar>
            <Grid item>
              <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
            </Grid>
            <Grid item >
              <Typography className={classes.dialogTitleAppBarTitle} variant="h6">
                Feedback
              </Typography>
            </Grid>
            <div style={{ flexGrow: 1 }}></div>
            <Grid item style={{ height: 50, textAlign: 'center' }}>
              {sourceUserLogo && userAccount?.logo?.fileName ?
                <NetworkImageNonDialog hideOverlay={true} src={sourceUserLogo} minWidth={0} authorize={false} /> :
                <img src={GreenbirdLogo} width={"100%"} alt="greenbird-logo" />
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} style={{ backgroundColor: loading ? "white" : settings?.content?.publicDisplayBackgroundColor ? settings.content.publicDisplayBackgroundColor : primaryColor, padding: 0 }}>
        <Grid style={{ height: 100, position: "relative", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
          {source && settings?.logo?.fileName ?
            <NetworkImageNonDialog hideOverlay={true} src={source} minWidth={0} authorize={false} /> :
            <img src={cmnHardware} height={100} alt="greenbird-logo" />
          }
        </Grid>
        {loading ? previewView() : mapContent()}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          fullWidth
          size='large'
          style={{ width: '100%' }}
          startIcon={<OpenInBrowser />}
          variant={'contained'}
          color="primary"
          onClick={async () => {
            window.open("https://" + settings?.content?.qrCodeUrl || "www.smart-service-display.com", '_self', 'noreferrer');
          }}
        >
          Mehr über uns erfahren
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + parseInt(theme.spacing(2)) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
  },
  dialogTitle: {
    padding: 0,
  },
  dialogTitleAppBar: {
    flexGrow: 1,
  },
  dialogTitleAppBarTitle: {
    flexGrow: 1,
  },
  empty: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));




export const FreeTextPanelComponent = ({ messenger, setMessenger, customTextsFeedback, setCustomTextsFeedback, onCancel, onFinish, title }: { messenger: string, setMessenger: any, customTextsFeedback: string[], setCustomTextsFeedback: any, onCancel: any, onFinish: any, title: string }) => {
  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
        <Typography className='text-public' color="primary" fontSize={28} fontWeight={"bold"}>{title} Verfassen</Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant={"outlined"}
          label="Melder"
          value={messenger}
          onChange={(event: any) => {
            setMessenger(event.target.value);
          }} />
      </Grid>
      {customTextsFeedback &&
        customTextsFeedback.map((el: string, index: number) => {
          return (
            <Grid item>
              <TextField
                fullWidth
                multiline
                minRows={8}
                variant={"outlined"}
                name="feedbackQuestionsItems"
                label={title}
                value={el || ''}
                onChange={(event: any) => {
                  customTextsFeedback[index] = event.target.value;
                  setCustomTextsFeedback([...customTextsFeedback]);
                }} />
            </Grid>
          );
        })}
      <Grid container item direction={"row"} spacing={3} alignItems="center">
        <Grid item xs={3}>
          <Fab
            size='large'
            color="error"
            onClick={async () => {
              onCancel();
            }}
          >
            <Cancel />
          </Fab >
        </Grid>
        <Grid item xs={9}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Check />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onFinish();
            }}
          >
            Fertig
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const FeedbackQuestionsDisplayByType = ({ feedbackQuestionType, feedbackQuestionHeader, feedbackQuestions, settings, navigateAfterFeedbackQuestion, customTextsFeedback, setCustomTextsFeedback, selectedFeedbackQuestions, setSelectedFeedbackQuestions, setDisplayFeedbackPublicType, messenger, standardFeedbackQuestion }:
  { feedbackQuestionType: FeedbackQuestionType, feedbackQuestionHeader: string, feedbackQuestions: FeedbackQuestion[], settings: GroupSettingsLight, navigateAfterFeedbackQuestion: any, customTextsFeedback: string[], setCustomTextsFeedback: any, selectedFeedbackQuestions: FeedbackQuestion[], setSelectedFeedbackQuestions: any, setDisplayFeedbackPublicType: any, messenger: string, standardFeedbackQuestion: StandardWorkItem[] }) => {

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { t } = useTranslation();

  const groupId = useQuery(history).get("groupId");
  const userId = useQuery(history).get("userId");
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <BuildFeedbackQuestionContent feedbackQuestionHeader={feedbackQuestionHeader} isFreeTextEnabled={settings.feedbacks?.isFeedbackCustomTextEnabled || false} customTexts={customTextsFeedback} items={feedbackQuestions} selectedFeedbackQuestions={selectedFeedbackQuestions} setSelectedFeedbackQuestions={setSelectedFeedbackQuestions} standardFeedbackQuestion={standardFeedbackQuestion}
          onFinish={async () => {
            let chosenTexts = selectedFeedbackQuestions.map(x => x.text);
            navigateAfterFeedbackQuestion();
            if (groupId && userId) {
              await api.CreateFeedbackQuestionsLightByGroupId(new GroupFeedbackQuestion(groupId, userId, chosenTexts, getUnixTime(new Date()), customTextsFeedback, messenger || "", feedbackQuestionType), groupId, userId, enqueueSnackbar);
            }
          }}
          onFreeText={() => {
            if (customTextsFeedback.length < 1) {
              setCustomTextsFeedback([""]);
            }
            setDisplayFeedbackPublicType(DisplayFeedbackPublicType.FeedbackFreeText);
          }}
          onCancel={() => {
            navigateAfterFeedbackQuestion();
          }} />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      {settings.incidentReports?.isIncidentVisitorEnabled && settings.feedbacks?.publicFeedbackModeType != PublicFeedbackModeType.OnlyFeedbackQuestion &&
        <Grid item>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%', marginTop: 15 }}
            startIcon={<Warning />}
            variant={'contained'}
            color="error"
            onClick={async () => {
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.IncidentVisitor);

            }}
          >
            Vorfall melden
          </Button>
        </Grid>
      }
      {settings?.payPerServiceControl && settings?.payPerServiceControl?.isPayPerServiceEnabled &&
        <Grid item>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%', marginTop: 15 }}
            startIcon={<img src={PayLicense} width={30} height={30} />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              setDisplayFeedbackPublicType(DisplayFeedbackPublicType.PayPerService);

            }}
          >
            Pay Per Service starten
          </Button>
        </Grid>
      }
    </Grid>
  )
}

export const BuildFeedbackQuestionContent = ({ feedbackQuestionHeader, items, selectedFeedbackQuestions, setSelectedFeedbackQuestions, standardFeedbackQuestion, onFinish, onFreeText, onCancel, customTexts, isFreeTextEnabled }:
  { feedbackQuestionHeader: string, items: FeedbackQuestion[] | GroupSettingsIncidenReport[], selectedFeedbackQuestions: FeedbackQuestion[] | GroupSettingsIncidenReport[], setSelectedFeedbackQuestions: any, standardFeedbackQuestion: StandardWorkItem[], onFinish: any, onFreeText: any, onCancel: any, customTexts: string[], isFreeTextEnabled: boolean }) => {
  const { t } = useTranslation();

  const toggleFeedbackQuestion = (value: FeedbackQuestion | GroupSettingsIncidenReport) => {
    if (selectedFeedbackQuestions.some(x => x.text == value.text)) {
      setSelectedFeedbackQuestions(selectedFeedbackQuestions.filter(x => x.text != value.text));

    } else {
      setSelectedFeedbackQuestions((prev: any) => [...prev, value]);
    }
  };
  return (
    <Grid container spacing={3} direction="column">
      {feedbackQuestionHeader &&
        <Grid item>
          <Typography fontSize={26} fontWeight={'bold'} textAlign={"center"} color={"primary"}>{feedbackQuestionHeader}</Typography>
        </Grid>
      }
      {isFreeTextEnabled &&
        <Grid item xs={12}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Chat />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onFreeText();
            }}
          >
            Freitext verfassen
          </Button>
        </Grid>
      }
      <Grid item>
        <List dense sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
          {items.map((value) => {
            const currentStandardItem = standardFeedbackQuestion ? standardFeedbackQuestion.find(x => x.id == value.standardItemId) : undefined;
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <Grid container>
                <Grid item xs={12}>
                  <ListItem

                    key={value.text}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={() => toggleFeedbackQuestion(value)}
                        checked={selectedFeedbackQuestions.some(x => x.text == value.text)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton onClick={() => toggleFeedbackQuestion(value)}>
                      <ListItemIcon style={{ width: 70 }}>
                        {currentStandardItem ? <StandardItemIcon currentStandardItem={currentStandardItem} /> : getDefaultIcon(value.text)}
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={currentStandardItem ? currentStandardItem.text : value.text} />
                    </ListItemButton>
                  </ListItem>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
              </Grid>
            );
          })}

        </List>
      </Grid>
      <Grid item container direction={"row"} spacing={3} alignItems="center" justifyContent={"center"}>

        <Grid item xs={5}>
          <Button
            color="error"
            variant='outlined'
            startIcon={<Cancel />}
            onClick={async () => {
              onCancel();
            }}
          >
            {t("myUnitsSettings.cancel")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Check />}
            disabled={selectedFeedbackQuestions.length < 1 && customTexts.length < 1}
            variant={'contained'}
            color="primary"
            onClick={() => { onFinish() }}
          >
            {selectedFeedbackQuestions.length + customTexts.length}  melden
          </Button>
        </Grid>
      </Grid>
    </Grid>

  )
}

export const FreeTextPanelIncidentComponent = ({ messenger, setMessenger, incidentText, setincidentText, onCancel, onFinish, title, isWithNameEnabled }: { messenger: string, setMessenger: any, incidentText: GroupServiceModeIncident[], setincidentText: any, onCancel: any, onFinish: any, title: string, isWithNameEnabled: boolean }) => {
  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
        <Typography className='text-public' color="primary" fontSize={28} fontWeight={"bold"}>{title} Verfassen</Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant={"outlined"}
          label="Melder"
          value={messenger}
          onChange={(event: any) => {
            setMessenger(event.target.value);
          }} />
      </Grid>
      <Grid item>
        <Button
          fullWidth
          size="large"
          startIcon={<Add />}
          variant={'contained'}
          onClick={async () => {
            setincidentText([...incidentText, new GroupServiceModeIncident("", "")])
          }}
          color="primary"
        >
          {title}
        </Button>
      </Grid>
      <Grid item container direction={"row"} spacing={3}>
        {incidentText &&
          incidentText.map((el, index: number) => {
            return (
              <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
                <Grid item xs={2}>
                  <Fab
                    size="small"
                    style={{ backgroundColor: errorColor, color: white }}
                    onClick={() => {
                      setincidentText(incidentText.filter((x, index2) => index2 != index));
                    }}
                  >
                    <Remove />
                  </Fab>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant={"outlined"}
                    name="feedbackQuestionsItems"
                    label={title}
                    value={el.incidentText || ''}
                    onChange={(event: any) => {
                      el.incidentText = event.target.value;
                      setincidentText([...incidentText]);
                    }} />
                </Grid>
                {isWithNameEnabled &&
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant={"outlined"}
                      name="feedbackQuestionsItems"
                      label={"Name"}
                      value={el.reporterName || ''}
                      onChange={(event: any) => {
                        el.reporterName = event.target.value;
                        setincidentText([...incidentText]);
                      }} />
                  </Grid>
                }
              </Grid>
            );
          })}
      </Grid>
      <Grid container item direction={"row"} spacing={3} alignItems="center">
        <Grid item xs={3}>
          <Fab
            size='large'
            color="error"
            onClick={async () => {
              onCancel();
            }}
          >
            <Cancel />
          </Fab >
        </Grid>
        <Grid item xs={9}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Check />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onFinish();
            }}
          >
            Fertig
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const BuildIncidentVisitorContent = ({ items, selectedIncidents, setselectedIncidents, standardFeedbackQuestion, onFinish, onFreeText, onCancel, customTexts, isFreeTextEnabled, isWithNameEnabled, incidentNames, isWithNamePriceEnabled }:
  { items: GroupSettingsIncidenReport[], selectedIncidents: GroupServiceModeIncident[], setselectedIncidents: any, standardFeedbackQuestion: StandardWorkItem[], onFinish: any, onFreeText: any, onCancel: any, customTexts: GroupServiceModeIncident[], isFreeTextEnabled: boolean, isWithNameEnabled: boolean, incidentNames: GroupSettingsIncidenName[], isWithNamePriceEnabled: boolean }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { t } = useTranslation();
  const toggleFeedbackQuestion = (value: GroupSettingsIncidenReport) => {
    if (selectedIncidents.some(x => x.incidentText == value.text)) {
      setselectedIncidents(selectedIncidents.filter(x => x.incidentText != value.text));

    } else {
      setselectedIncidents((prev: any) => [...prev, new GroupServiceModeIncident("", value.text)]);
    }
  };
  return (
    <Grid container spacing={3} direction="column">
      {isFreeTextEnabled &&
        <Grid item>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Chat />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onFreeText();
            }}
          >
            Freitext verfassen
          </Button>
        </Grid>
      }
      <Grid item >
        <List dense sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
          {items.map((value) => {
            const checked = selectedIncidents.some(x => x.incidentText == value.text);
            let currentIncident = selectedIncidents.find(x => x.incidentText == value.text);
            const currentStandardItem = standardFeedbackQuestion ? standardFeedbackQuestion.find(x => x.id == value.standardItemId) : undefined;
            const labelId = `checkbox-list-secondary-label-${value}`;
            const allowedIncidentNames = incidentNames.filter(x => !value.nameExceptionList.includes(x.text));
            return (
              <Grid container>
                <Grid item xs={12}>
                  <ListItem

                    key={value.text}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={() => toggleFeedbackQuestion(value)}
                        checked={checked}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <Grid container direction={"row"} style={{ marginRight: 40 }}>
                      <Grid item xs={(isWithNamePriceEnabled || isWithNameEnabled) && currentIncident ? 6 : 12}>
                        <ListItemButton style={{ width: currentIncident ? '50%' : '100%' }} onClick={() => toggleFeedbackQuestion(value)}>
                          <ListItemIcon style={{ width: 70 }}>
                            {currentStandardItem ? <StandardItemIcon currentStandardItem={currentStandardItem} /> : getDefaultIcon(value.text)}
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={currentStandardItem ? currentStandardItem.text : value.text} />
                        </ListItemButton>
                      </Grid>
                      {(isWithNamePriceEnabled || isWithNameEnabled) && currentIncident &&
                        <Grid item xs={6} >
                          {
                            allowedIncidentNames.length > 0 && isWithNamePriceEnabled &&
                            <Autocomplete
                              onChange={(event, newValue) => {
                                if (!currentIncident) return;
                                currentIncident.incidentNames = newValue.map(x => GroupServiceModeIncidenName.CreateInstanceFromSettings(x));
                                setselectedIncidents((prev: any) => [...prev]);
                              }}
                              multiple
                              id="checkboxes-tags-demo"
                              options={allowedIncidentNames}
                              autoComplete={false}
                              getOptionLabel={(option) => option.text}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.text}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                  }}
                                  label="Namen" placeholder="Namen" />
                              )}
                            />
                          }
                          {currentIncident && isWithNameEnabled &&

                            < TextField
                              fullWidth
                              variant={"outlined"}
                              label="Name"
                              value={currentIncident.reporterName}
                              onChange={(event: any) => {
                                if (currentIncident) {
                                  currentIncident.reporterName = event.target.value;
                                  setselectedIncidents((prev: any) => [...prev]);
                                }
                              }} />
                          }

                        </Grid>
                      }
                    </Grid>
                  </ListItem>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
              </Grid>
            );
          })}

        </List >
      </Grid >
      <Grid item container direction={"row"} spacing={3} alignItems="center" justifyContent={"center"}>
        <Grid item xs={5}>
          <Button
            color="error"
            variant='outlined'
            startIcon={<Cancel />}
            onClick={async () => {
              onCancel();
            }}
          >
            {t("myUnitsSettings.cancel")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Check />}
            disabled={selectedIncidents.length < 1 && customTexts.length < 1}
            variant={'contained'}
            color="primary"
            onClick={() => { onFinish() }}
          >
            {selectedIncidents.length + customTexts.length}  melden
          </Button>
        </Grid>


      </Grid>
    </Grid >

  )
}