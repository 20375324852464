import { Typography, FormControl, FormLabel, FormControlLabel, Radio, Grid, TextField, Paper, RadioGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import React, { useImperativeHandle, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MaxChartItemCountInput } from "../../../../../helpers/compontents";
import { formatDateRange, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../../helpers/date-management";
import { GroupFeedbackQuestion } from "../../../../../models/GroupFeedbackQuestion";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { StandardBarChartSimpleColor } from "../../../../charts/StandardBarChartSimpleColor";
import { ChartHeightTextBox, PrintButton } from "../../../../core/GlobalTimeChoice";
import { GlobalContext } from "../../../PortalPage";
import { useQuery } from "../../checkout/ShopPage";
import { FeedbackQuestionTextType } from "../FeedbackDashboard";
import { PrintContext } from "../HitListDashboard";

export const FEEDBACK_QUESTION_NEGATIVE_WITH_GROUPS_SIMPLE_TITLE = "Negative Feedbackfragen";
export const FEEDBACK_QUESTION_POSITIVE_WITH_GROUPS_SIMPLE_TITLE = "Positive Feedbackfragen";

//einfärben grün und rot
export const BuildGroupFeedbackQuestionsWithGroupsSimple = React.forwardRef(({ feedbackQuestionTableData, groupsLicenseTableEntries, titleHeader, color }: { feedbackQuestionTableData: GroupFeedbackQuestion[], groupsLicenseTableEntries: GroupLicenseTable[], titleHeader: string, color?: string }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateFeedbackQuestionWithGroupsSimpleData(date: Date) {
            updateStartDateFeedbackQuestionWithGroupsSimpleData(date);
        },
        updateEndDateFeedbackQuestionWithGroupsSimpleData(date: Date) {
            updateEndDateFeedbackQuestionWithGroupsSimpleData(date);
        }

    }));

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);
    const history = useHistory();
    const queryString = useQuery(history);


    const [startDateFeedbackQuestionWithGroupsSimple, setStartDateFeedbackQuestionWithGroupsSimple] = React.useState(subDays(new Date(), 10));
    const [endDateFeedbackQuestionWithGroupsSimple, setEndDateFeedbackQuestionWithGroupsSimple] = React.useState(new Date());
    const title = isPrintPage ? `<b>Gesamt ${titleHeader} der Einheiten Bericht</b><br />${formatDateRange(startDateFeedbackQuestionWithGroupsSimple, endDateFeedbackQuestionWithGroupsSimple)}` : "";

    const [chartHeightFeedbackQuestionWithGroupsSimple, setChartHeightFeedbackQuestionWithGroupsSimple] = React.useState(300);
    const [feedbackQuestionWithGroupsSimpleChartData, setFeedbackQuestionWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackQuestionWithGroupsType, setFeedbackQuestionWithGroupsType] = React.useState(FeedbackQuestionTextType.StandardText);

    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);


    React.useEffect(() => {
        updateEndDateFeedbackQuestionWithGroupsSimpleData(endDateFeedbackQuestionWithGroupsSimple);
    }, [maxChartItemCount]);

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (feedbackQuestionTableData) {
            setStartDateFeedbackQuestionWithGroupsSimple(startDate);
            setEndDateFeedbackQuestionWithGroupsSimple(endDate);
            updateFeedbackQuestionWithGroupsSimpleChart(startDate, endDate, feedbackQuestionTableData);
        }
    }, [feedbackQuestionTableData, groupsLicenseTableEntries])


    const updateStartDateFeedbackQuestionWithGroupsSimpleData = (newVal: Date) => {
        if (!feedbackQuestionTableData) return;
        setStartDateFeedbackQuestionWithGroupsSimple(newVal);
        updateFeedbackQuestionWithGroupsSimpleChart(newVal, endDateFeedbackQuestionWithGroupsSimple, feedbackQuestionTableData);
    }

    const updateEndDateFeedbackQuestionWithGroupsSimpleData = (newVal: Date) => {
        if (!feedbackQuestionTableData) return;
        setEndDateFeedbackQuestionWithGroupsSimple(newVal);
        updateFeedbackQuestionWithGroupsSimpleChart(startDateFeedbackQuestionWithGroupsSimple, newVal, feedbackQuestionTableData);
    }





    const updateFeedbackQuestionWithGroupsSimpleChart = (startDate: Date, endDate: Date, feedbackQuestionAllData: GroupFeedbackQuestion[], type: FeedbackQuestionTextType = FeedbackQuestionTextType.StandardText) => {
        let feedbackQuestions = feedbackQuestionAllData.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);
        let res = feedbackQuestions.reduce((sumValue, newValue: GroupFeedbackQuestion) => {
            let newItems = type == FeedbackQuestionTextType.StandardText ? newValue.items : newValue.customTexts;
            if (newItems) {
                let notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
                let entryExists = sumValue.find((x: any) => x.argumentField == notificationName);
                if (entryExists) {
                    entryExists.valueField += newItems.length;
                } else {
                    sumValue.push({ argumentField: notificationName, valueField: newItems.length });
                }
            }


            return sumValue;
        }, [] as any[]);
        res = res.sort((a, b) => a.valueField - b.valueField).slice(-maxChartItemCount);
        setFeedbackQuestionWithGroupsSimpleChartData(res);
    }

    const buildContent = () => {
        return <>
            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Gesamt {titleHeader} der Einheiten Bericht</Typography>
            <FormControl component="fieldset" >
                <FormLabel component="legend">{titleHeader}n Art</FormLabel>
                <RadioGroup
                    row
                    name="feedbackQuestionWithGroupsType"
                    value={feedbackQuestionWithGroupsType}
                    onChange={(event) => {
                        setFeedbackQuestionWithGroupsType(event.target.value as any);
                        updateFeedbackQuestionWithGroupsSimpleChart(startDateFeedbackQuestionWithGroupsSimple, endDateFeedbackQuestionWithGroupsSimple, feedbackQuestionTableData, event.target.value as any);
                    }}
                >
                    <FormControlLabel value={FeedbackQuestionTextType.StandardText} control={<Radio />} label="Vorgefertigter Text" />
                    <FormControlLabel value={FeedbackQuestionTextType.FreeText} control={<Radio />} label="Freitexte" />
                </RadioGroup>
            </FormControl>
            <Grid container direction="row" spacing={3}>
                <Grid item>
                    < DatePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={"Start Datum"}
                        value={startDateFeedbackQuestionWithGroupsSimple}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            updateStartDateFeedbackQuestionWithGroupsSimpleData(newVal);
                        }}
                        format={"dd.MM.yyyy"}
                        
                    />
                </Grid>
                <Grid item>
                    < DatePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={"Ende Datum"}
                        value={endDateFeedbackQuestionWithGroupsSimple}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            updateEndDateFeedbackQuestionWithGroupsSimpleData(newVal);

                        }}
                        format={"dd.MM.yyyy"}
                        
                    />
                </Grid>
                <Grid item>
                    <ChartHeightTextBox chartHeight={chartHeightFeedbackQuestionWithGroupsSimple} setChartHeight={setChartHeightFeedbackQuestionWithGroupsSimple} actualCount={feedbackQuestionWithGroupsSimpleChartData.length} />
                </Grid>
                <MaxChartItemCountInput />
            </Grid>
        </>;
    }

    const buildPrintContent = () => {
        return (
            <>
                <PrintButton title={`Gesamt ${titleHeader} der Einheiten Bericht Drucken`} pageRef={refPrint} />
                <ChartHeightTextBox chartHeight={chartHeightFeedbackQuestionWithGroupsSimple} setChartHeight={setChartHeightFeedbackQuestionWithGroupsSimple} display={false} actualCount={feedbackQuestionWithGroupsSimpleChartData.length} />
            </>
        )
    }

    if (!feedbackQuestionTableData) return <></>

    return (
        <Paper elevation={elevationPaper} style={paperStyle}>
            {isPrintPage ? buildPrintContent() : buildContent()}
            <Paper ref={refPrint}>
                <StandardBarChartSimpleColor color={color || undefined} valueFieldService={"valueField"} argumentFieldService={"argumentField"} dataSource={feedbackQuestionWithGroupsSimpleChartData} name={"${titleHeader}n Gruppiert Nach Einheiten Gesamt"} height={chartHeightFeedbackQuestionWithGroupsSimple} title={title} />
            </Paper>
        </Paper>
    )
});