import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Autocomplete, Box, Button, Card, Checkbox, Divider, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Popover, Radio, RadioGroup, Select, Slider, Switch, TextField, Typography } from "@mui/material";
import { Add, CloudUpload, ExpandMore, Visibility, Info, AddBox, Remove, RestoreFromTrash, DeleteForever, Delete, SwitchCamera, AssignmentReturn, ColorLens, LocationSearching } from "@mui/icons-material";
import { Content, ContentCode, ContentCombinationItem, ContentDisplayColorMode, ContentDisplayTextAnimationMode, ContentNews, ContentWeather, ContentWebView, DisplayLastCleaningType, GroupSettings, GroupSettingsLogo, GroupSettingsSmilesType, GroupSettingsType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { api } from "../../api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getApiToken } from "../../helpers/storage-management";
import { diffDays } from "../../helpers/date-management";
import { GroupSideBarImagesDialog } from "../dialogs/GroupSideBarImagesDialog";
import { ReactComponent as WeatherCloudyAlert } from '../../assets/svgs/weather-cloudy-alert.svg'
import { NavigatedFromType } from "../../models/NavigatedFrom";
import { errorColor, white } from "../../styles/colors";
import { CITIES } from "../../constants";
import { GroupSideBarImagesAssignmentDialog } from "../dialogs/GroupSideBarImagesAssignmentDialog";
import { Group, GroupType } from "../../models/Group";
import { GroupSettingFieldsLogo } from "./GroupSettingFields.Logo";
import { DISPLAY_TEXT, SMILE_LINE } from "../../assets";
import { getUserIdFromStorage } from "../../helpers/common";
import CmnSvg from '../../assets/svgs/checkmenow_logo.svg'
import { NetworkImageNonDialog } from "../images/NetworkImageNonDialog";
import { ChromePicker } from "react-color";
import { ProgressDialogComponent } from "./components/ProgressDialogComponent";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { useTranslation } from "react-i18next";
import { getGreenSmile, getRedSmile, getYellowSmile } from "../unauthorized/FeedbackPublic";
import { GlobalContext, SettingsContext } from "../authorized/PortalPage";
import { UserAccount } from "../../models/UserAccount";
import { UserType } from "../../models/UserType";
import { AzureStorageBrowserModel } from "../../models/AzureStorageBrowserModel";

export const GroupSettingFieldsContent = ({ row, onChange, type, group }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, type: GroupSettingsType, group: Group | undefined }) => {


    const [source, setSource] = React.useState('');
    const [progressData, setProgressData] = React.useState<any>();
    const [isUploadingFiles, setIsUploadingFiles] = React.useState(false);


    React.useEffect(() => {
        (async () => {

            if (!row.logo?.fileName) {
                setSource(CmnSvg);
                return;
            }
            const userId = getUserIdFromStorage();

            switch (type) {
                case GroupSettingsType.Template:
                    setSource(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${row.id}/images/logos/${row.logo?.fileName}`);
                    break;
                default:
                    setSource(`${process.env.REACT_APP_BASIC_URI}/groups/${row.id}/images/logos/${row.logo?.fileName}`);
                    break;
            }
        })();
    }, [])
    const classes = GroupSettingFieldsUseStyles();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [token, setToken] = React.useState("");
    const [GroupLogoDialogIsOpen, setOpenGroupLogoDialog] = React.useState(false);
    const [GroupSettingsSideBarSettingsItemsDialogIsOpen, setGroupSettingsSideBarSettingsItemsDialogIsOpen] = React.useState(false);
    const [GroupSettingsSideBarSettingsItemsAssignmentDialogIsOpen, setGroupSettingsSideBarSettingsItemsAssignmentDialogIsOpen] = React.useState(false);

    const [contentCombinationIndex, setContentCombinationIndex] = React.useState<number | undefined>();
    const { t } = useTranslation();
    const [smileImagesUri, setSmileImagesUri] = React.useState<AzureStorageBrowserModel[]>([]);
    const { refreshSettings } = React.useContext(SettingsContext);

    const showAdsVisible = row.content &&
        group &&
        !group.adLicenseExpired;


    const onSideBarImagesChosen = async (event: any, index?: number, combinationItem?: ContentCombinationItem) => {
        if (!event || !row) {
            enqueueSnackbar(t("myUnitsSettings.couldNotUploadFiles"), { variant: 'error' })
            return;
        };

        setIsUploadingFiles(true);

        const tempGroupSettings = await api.uploadGroupSideBarImages(event.target.files, row as GroupSettings, type, token, enqueueSnackbar, setProgressData);

        setIsUploadingFiles(false);
        if (!tempGroupSettings) {
            enqueueSnackbar(t("myUnitsSettings.anErrorOccurtDuringUpload"), { variant: 'error' })
            return;
        }

        // after save pressed data must persist

        if (index != undefined && combinationItem && tempGroupSettings.sideBarImage) {
            let fil = tempGroupSettings.sideBarImage.items.filter(x => !row.sideBarImage?.items.some(y => y.id == x.id));
            combinationItem.sideBarImageItemIds = [...combinationItem.sideBarImageItemIds, ...fil.map(el => el.id)];
            onChange({ target: { name: 'contentCombinationAdvertisementModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem, systemETag: tempGroupSettings.systemETag, sideBarImage: tempGroupSettings.sideBarImage }) } });
        } else {
            onChange({ target: { name: 'sideBarImage', value: JSON.stringify({ systemETag: tempGroupSettings.systemETag, sideBarImage: tempGroupSettings.sideBarImage }) } });
        }

        if (event.onChanged)
            event.onChanged();
    }

    React.useEffect(() => {

        const apiToken = getApiToken(history);
        setToken(apiToken);

    }, []);

    const buildExpiredAds = () => {
        return (
            <Grid>
                <FormHelperText>
                    Ihre kostenlose Lizenz für die Nutzung Werbeschaltung am Gerät ist abgelaufen.
                </FormHelperText>
                <Button
                    className={classes.marginTop}
                    size="small"
                    startIcon={<Add />}
                    variant={'contained'}
                    color="primary"

                    onClick={() => {
                        const queryParams = { showall: "1", chosenUnit: row.id || "", chosenUnitName: row.locationInformation?.notificationName || "", navigatedFrom: NavigatedFromType.SettingsAdsUnit }
                        const queryString = new URLSearchParams(queryParams).toString()
                        history.push({
                            pathname: '/portal/shop',
                            search: queryString
                        });
                    }}
                >
                    {t("myUnitsSettings.extendLicenseForAdvertising")}
                </Button>
            </Grid>
        )
    }

    const buildCombinationItem = (combinationItem: ContentCombinationItem, index?: number) => {
        switch (combinationItem.contentCode) {
            case ContentCode.News:
                return (
                    buildNewsItem("contentCombinationModify", combinationItem.news?.language, index, combinationItem)
                );
            case ContentCode.Weather:
                return (
                    buildWeatherItem("contentCombinationModify", combinationItem.weather?.city, combinationItem.weather?.longitude || "", combinationItem.weather?.latitude || "", index, combinationItem)
                );
            case ContentCode.Advertisements:
                if (showAdsVisible) {
                    return (
                        buildAdvertismentItem(index, combinationItem)
                    );
                } else {
                    return buildExpiredAds()
                }
            case ContentCode.WebView:
                return (
                    buildWebViewItem("contentCombinationModify", combinationItem?.webView?.url, index, combinationItem)
                )

        }
    }

    const buildNewsItem = (name: string, value: any, index?: number, combinationItem?: ContentCombinationItem) => {

        return (
            <Grid item>
                <FormControl variant="filled" style={{ minWidth: 300 }}>
                    <InputLabel id="demo-simple-select-filled-label">Sprache Nachrichten</InputLabel>
                    <Select
                        name={name}
                        value={value}
                        onChange={(event) => {
                            if (index != undefined && combinationItem) {
                                combinationItem.news = ContentNews.createInstance(event.target.value as string)
                                return onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem }) } })
                            }
                            return onChange(event);
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={"at"}>{t("myUnitsSettings.germanAustria")}</MenuItem>
                        <MenuItem value={"de"}>{t("myUnitsSettings.germanGermany")}</MenuItem>
                        <MenuItem value={"en"}>{t("myUnitsSettings.englishUsa")}</MenuItem>
                        <MenuItem value={"en-GB"}>{t("myUnitsSettings.englishEnglad")}</MenuItem>
                        <MenuItem value={"it"}>{t("italian")}</MenuItem>
                        <MenuItem value={"es"}>{t("spanish")}</MenuItem>
                        <MenuItem value={"fr"}>{t("french")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        )
    }

    const buildWebViewItem = (name: string, value: any, index?: number, combinationItem?: ContentCombinationItem) => {
        return (
            <Grid item lg={5}>
                <TextFieldGroupSettings
                    label="Web Url"
                    value={value}
                    fullWidth
                    onChange={(event) => {
                        if (index != undefined && combinationItem) {
                            combinationItem.webView = ContentWebView.createInstance(event.target.value as string)
                            return onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem }) } })
                        } else {
                            if (!row?.content?.webView && row.content) {
                                row.content.webView = ContentWebView.createInstance(event.target.value as string);

                            } else if (row.content?.webView) {
                                row.content.webView.url = event.target.value;
                            }
                            refreshSettings();
                        }
                    }}
                    variant="filled"
                />
            </Grid>
        )
    }


    const buildWeatherItem = (name: string, value: any, longitude: string, latitude: string, index?: number, combinationItem?: ContentCombinationItem) => {
        const isExistingCity = CITIES.includes(value);
        return (
            <Grid item container direction={"column"} spacing={3}>
                <Grid item>
                    <FormControl variant="filled" style={{ minWidth: 300 }}>
                        <Autocomplete
                            freeSolo
                            value={value}
                            onChange={(event: any, newValue: string | null) => {
                                if (!newValue) return;
                                if (index != undefined && combinationItem) {
                                    combinationItem.weather = ContentWeather.createInstance(newValue, longitude, latitude)
                                    return onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem }) } })
                                }
                                return onChange({ target: { name: name, value: newValue } });
                            }}

                            id="controllable-states-demo"
                            options={CITIES}
                            sx={{ width: 300 }}
                            renderInput={(params: any) => <TextFieldGroupSettings onChange={(event) => {
                                if (index != undefined && combinationItem) {
                                    combinationItem.weather = ContentWeather.createInstance(event.target.value, longitude, latitude)
                                    return onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem }) } })
                                }
                                return onChange({ target: { name: name, value: event.target.value } });
                            }} {...params} name={name} label={t("myUnitsSettings.cityWeather")} />}
                        />
                    </FormControl>
                </Grid>
                {!isExistingCity &&
                    <Grid item container direction={"row"} spacing={3}>
                        <Grid item xs={12}>
                            <Alert severity="info">
                                <AlertTitle>{t("myUnitsSettings.latitudeAndLongitutdeExampleVienna")}</AlertTitle>
                                <p>Longitude : 16.363449 / latitude: 48.210033</p>
                                <a href="https://www.gps-coordinates.net/" target="_blank">{t("myUnitsSettings.getYourCoordinates")}</a>
                            </Alert>
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Längengrad (Longitude)"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><LocationSearching /></InputAdornment>
                                }}
                                value={longitude}
                                onChange={(event: any) => {
                                    if (index != undefined && combinationItem) {
                                        combinationItem.weather = ContentWeather.createInstance(value, event.target.value, latitude)
                                        return onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem }) } })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Breitengrad (Latitude)"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><LocationSearching /></InputAdornment>
                                }}
                                value={latitude}
                                onChange={(event: any) => {
                                    if (index != undefined && combinationItem) {
                                        combinationItem.weather = ContentWeather.createInstance(value, longitude, event.target.value)
                                        return onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: index, newVal: combinationItem }) } })
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                }


            </Grid >
        )
    }

    const buildAdvertismentItem = (index?: number, combinationItem?: ContentCombinationItem) => {
        return (
            <Grid container xs={12} item direction="row" spacing={3} alignItems="center">
                <Grid item>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUpload />}
                        component="label">
                        {t("myUnitsSettings.uploadAdvertisement")}
                        <input
                            type="file"
                            name="uploadAdvertisements"
                            onChange={(event) => {
                                setContentCombinationIndex(index);
                                onSideBarImagesChosen(event, index, combinationItem);
                            }
                            }
                            hidden
                            accept="image/png, image/jpeg, image/jpg, image/gif, video/mp4"
                            multiple />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        size="large"
                        startIcon={<Visibility />}
                        variant={'contained'}
                        onClick={() => {
                            setContentCombinationIndex(index);
                            setGroupSettingsSideBarSettingsItemsDialogIsOpen(true)
                        }}
                        color="primary">
                        {t("myUnitsSettings.viewAdvertisement")}
                    </Button>
                </Grid>
                <Grid item  >
                    <Typography style={{ border: "1px solid grey", padding: 4 }} variant="body2">{t("myUnitsSettings.recommendedResolution")} (Format: jpgeg, png, jpg, mp4, giff).</Typography>
                </Grid>
                {combinationItem &&
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<AssignmentReturn />}
                            variant={'contained'}
                            onClick={() => {
                                setContentCombinationIndex(index);
                                setGroupSettingsSideBarSettingsItemsAssignmentDialogIsOpen(true);
                            }}
                            color="primary">
                            {t("myUnitsSettings.assignAvailableAdvertisements")}
                        </Button>
                    </Grid>
                }
            </Grid>
        )
    }

    let dashboardMode = DashboardMode.ContentFeedback;

    if (row.feedbacks?.isFeedbackControlEnabled && row.content?.isContentEnabled) {
        dashboardMode = DashboardMode.ContentFeedback;
    } else if (row.feedbacks?.isFeedbackControlEnabled) {
        dashboardMode = DashboardMode.FullScreenFeedback;
    } else if (row.content?.isContentEnabled) {
        dashboardMode = DashboardMode.ContentAndTimeterminal;
    } else {
        dashboardMode = DashboardMode.Timeterminal;
    }
    return (

        <Grid item xs={12}>
            <Accordion className={classes.hover} onChange={async (event, expanded) => {
                let smiles = await api.GetSmilesUri();
                setSmileImagesUri(smiles);
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                        <Grid item>
                            <WeatherCloudyAlert width={40} height={40} fill={"#000000"} />
                        </Grid>
                        <Grid item>
                            <Typography component="h4" variant="h4">{t("settingsPage.content")}</Typography>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }} />
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isEasyLoginEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isEasyLoginEnabled"
                                        color="primary"
                                    />
                                }
                                label={`Easy Login ${t("settingsPage.access")}`}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isContentEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="contentIsContentEnabled"
                                        color="primary"
                                    />
                                }
                                label={`Content ${t("settingsPage.activated")} ${t("settingsPage.(Wetter, News, Werbung)")}`}
                            />
                        </Grid>


                    </Grid>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography variant="h4">{t("myUnitsSettings.displayOperatingMode")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={DISPLAY_TEXT} style={{ height: 100 }} />
                        </Grid>
                        <Grid item xs={12} container direction="row" alignItems="center">
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t("myUnitsSettings.timeDisplaySelection")}</FormLabel>
                                    <RadioGroup
                                        row
                                        name="displayLastCleaningType"
                                        value={row.content?.displayLastCleaningType || DisplayLastCleaningType.DateTime}
                                        onChange={onChange}
                                    >
                                        <FormControlLabel
                                            value={DisplayLastCleaningType.DateTime}
                                            control={<Radio />}
                                            label={t("myUnitsSettings.dateAndTime")}
                                        />
                                        <FormControlLabel
                                            value={DisplayLastCleaningType.Time}
                                            control={<Radio />}
                                            label={t("myUnitsSettings.time")}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.content?.isObjectPresenceVisibleEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isObjectPresenceVisibleEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.objectPresenceDisplay")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.content?.isLastCheckEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isLastCheckEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.lastCheckDisplay")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.content?.isLastCleaningEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isLastCleaningEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.lastCleaningDisplay")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={row.locationInformation?.isLoginQrCodeEnabled}
                                    onChange={(event: any) => {
                                        event.target['value'] = event.target.checked;
                                        onChange(event);
                                    }}
                                    name="isLoginQrCodeEnabled"
                                    color="primary" />}
                                label={t("myUnitsSettings.qrCodeDisplayActivated")} />
                        </Grid>
                        <Grid item lg={3} >
                            <Alert severity="info">{t("myUnitsSettings.serviceStaffCanPerformServicesByScanning")}</Alert>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                id="filled-name"
                                variant={"filled"}
                                name="qrCodeUrl"
                                label="QR-Code URL"
                                value={row.content?.qrCodeUrl || ''}
                                onChange={(event: any) => {
                                    onChange({ target: { name: "qrCodeUrl", value: event.target.value } });
                                }} />
                        </Grid>
                        <Grid item lg={3} >
                            <Alert severity="info">{t("myUnitsSettings.visitorRedirectionToHomepage")}.</Alert>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                label={t("myUnitsSettings.showTotalFeedbackOnDisplay")}
                                control={
                                    <Checkbox
                                        name="isSumDisplayEnabled"
                                        color="primary"
                                        checked={row.feedbacks?.isSumDisplayEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            event.target.value = checked;
                                            onChange(event);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <img src={SMILE_LINE} style={{ height: 100 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <DisplayTextComponent onChange={onChange} row={row} />

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <DisplayTextComponent title="displayDisplayInfoTextBelow" componentType={ComponentPartType.TouchDisplay2} onChange={onChange} row={row} />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">Display - Logo</Typography>
                        </Grid>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item md={6}>
                                <GroupSettingFieldsLogo
                                    row={row}
                                    onRefresh={(logo: string) => {
                                        const userId = getUserIdFromStorage();

                                        setSource(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${row.id}/images/logos/${logo}`);

                                    }}
                                    onChange={onChange}
                                    group={group}
                                    type={type}
                                    showTip={true}
                                />
                            </Grid>
                            <Grid item>
                                {source &&
                                    <Grid item>
                                        <Typography variant="h5">Aktuelles Logo</Typography>
                                        <Card>
                                            <NetworkImageNonDialog src={source} hideOverlay={true} />
                                        </Card>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{t("myUnitsSettings.displayWaitingScreen")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isRfidExtraLoginEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isRfidExtraLoginEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.serviceWaitingScreenActivated")}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isWaitingScreenForScanAppEnabled}
                                        onChange={(event: any) => {
                                            if (!row.content) return;

                                            row.content.isWaitingScreenForScanAppEnabled = event.target.checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                        name="isWaitingScreenForScanAppEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.waitingScreenForCheckMeNowScanAppActivated")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isWaitingScreenServiceCancelEnabled}
                                        onChange={(event: any) => {
                                            if (!row.content) return;

                                            row.content.isWaitingScreenServiceCancelEnabled = event.target.checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                        name="isWaitingScreenServiceCancelEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.serviceCancellationWhenWaitingScreenCountdownExpiresActivated")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isWaitingScreenCountdownDisplayEnabled}
                                        onChange={(event: any) => {
                                            if (!row.content) return;

                                            row.content.isWaitingScreenCountdownDisplayEnabled = event.target.checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                        name="isWaitingScreenCountdownDisplayEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.showCountdownOnWaitingScreen")}
                            />
                        </Grid>
                        <Box width={"100%"} />
                        <Grid item lg={3}>
                            <TextFieldGroupSettings
                                label={t("myUnitsSettings.waitingScreenVisibilityInMinutes")}
                                value={row.content?.maxWaitingScreenInMinutes}
                                fullWidth
                                inputProps={{ min: Content.minWaitingScreenInMinutesValue, max: Content.maxWaitingScreenInMinutesValue }}
                                type="number"
                                onChange={(event) => {
                                    if (!row.content) return;

                                    row.content.maxWaitingScreenInMinutes = parseInt(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item lg={5}>
                            <Slider
                                value={row.content?.maxWaitingScreenInMinutes}

                                onChange={(event, value: number | number[]) => {
                                    if (!row.content) return;

                                    row.content.maxWaitingScreenInMinutes = value as number;
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                                min={ContentCombinationItem.minSlideDuration}
                                max={ContentCombinationItem.maxSlideDuration}
                                step={1} />
                        </Grid>
                        <Grid item lg={4} >
                            <Alert severity="info">{t("myUnitsSettings.howLongShouldTheWaitingScreenBeVisible")}</Alert>
                        </Grid>
                        <Box width={"100%"} />




                        <Grid item xs={12} container spacing={3} alignItems="center">

                            <Grid item xs={12} container direction="row" spacing={3} alignItems="center">
                                <Grid item xs={6} lg={3}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        label={`${t("myUnitsSettings.waitingScreenText")} 1`}
                                        name="inProgressText1"
                                        value={row.content?.inProgressText1}
                                        onChange={(event) => {
                                            onChange({ target: { name: "inProgressText1", value: event.target.value } });
                                        }}
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        label={`${t("myUnitsSettings.waitingScreenText")} 2`}
                                        name="inProgressText2"
                                        value={row.content?.inProgressText2}
                                        onChange={(event) => {
                                            onChange({ target: { name: "inProgressText2", value: event.target.value } });
                                        }}
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        label={`${t("myUnitsSettings.waitingScreenText")} 3`}
                                        name="inProgressText3"
                                        value={row.content?.inProgressText3}
                                        onChange={(event) => {
                                            onChange({ target: { name: "inProgressText3", value: event.target.value } });
                                        }}
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        label={`${t("myUnitsSettings.waitingScreenText")} 4`}
                                        name="inProgressText4"
                                        value={row.content?.inProgressText4}
                                        onChange={(event) => {
                                            onChange({ target: { name: "inProgressText4", value: event.target.value } });
                                        }}
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        label={`${t("myUnitsSettings.waitingScreenText")} 5`}
                                        name="inProgressText5"
                                        value={row.content?.inProgressText5}
                                        onChange={(event) => {
                                            onChange({ target: { name: "inProgressText5", value: event.target.value } });
                                        }}
                                        variant="filled"
                                    />
                                </Grid>

                                <Grid item lg={6} >
                                    <Alert severity="info">{t("myUnitsSettings.IfTheFieldsAreLeftEmptyThenTheDefaultTextWillBeDisplayedOnTheScreen")}</Alert>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isServiceListOnWaitingScreenEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isServiceListOnWaitingScreenEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.serviceListOnWaitingScreenActivated")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isFeedbackQuestionListOnWaitingScreenEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isFeedbackQuestionListOnWaitingScreenEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.serviceListOnWaitingScreenActivated")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isIncidentVisitorListOnWaitingScreenEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isIncidentVisitorListOnWaitingScreenEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.visitorIncidentsOnWaitingScreenActivated")}
                            />
                        </Grid>
                        <Box width="100%" />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">Display - Servicemode</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isFeedbackQuestionListOnServiceModeEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isFeedbackQuestionListOnServiceModeEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.feedbackQuestionsDisplayInServiceModeActivated")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.content?.isIncidentVisitorListOnServiceModeEnabled}
                                        onChange={(event: any) => {
                                            event.target['value'] = event.target.checked;
                                            onChange(event);
                                        }}
                                        name="isIncidentVisitorListOnServiceModeEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myUnitsSettings.visitorIncidentsDisplayInServiceModeActivated")}
                            />
                        </Grid>

                        <Grid item lg={5}>
                            <TextFieldGroupSettings
                                label={t("myUnitsSettings.feedbackQuestionsAndIncidentReportsBackInHours")}
                                value={row.content?.feedbackQuestionPastInHours}
                                fullWidth
                                inputProps={{ min: 1, max: 336 }}
                                type="number"
                                onChange={(event) => {
                                    if (!row.content) return;

                                    row.content.feedbackQuestionPastInHours = parseInt(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <Slider
                                value={row.content?.feedbackQuestionPastInHours}

                                onChange={(event, value: number | number[]) => {
                                    if (!row.content) return;

                                    row.content.feedbackQuestionPastInHours = value as number;
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                                min={1}
                                max={336}
                                step={1} />
                        </Grid>
                        <Grid item lg={3} >
                            <Alert severity="info">{t("myUnitsSettings.howFarBackShouldFeedbackQuestionsAndIncidentReportsBeQueried")}</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {group?.groupType == GroupType.DisplayWallApp &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Display - Modus</Typography>
                                </Grid>
                                <FeedbackDisplayModeComponent row={row} onChange={onChange} smileImagesUri={smileImagesUri} />
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t("myUnitsSettings.content")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{t("myUnitsSettings.displaySelection")}</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="gender"
                                            name="contentCode"
                                            value={dashboardMode}
                                            onChange={(event) => {
                                                if (!row.content || !row.feedbacks) return;


                                                if (event.target.value == DashboardMode.ContentFeedback.toString()) {
                                                    row.content.isContentEnabled = true;
                                                    row.feedbacks.isFeedbackControlEnabled = true;
                                                } else if (event.target.value == DashboardMode.FullScreenFeedback.toString()) {
                                                    row.content.isContentEnabled = false;
                                                    row.feedbacks.isFeedbackControlEnabled = true;
                                                } else if (event.target.value == DashboardMode.ContentAndTimeterminal.toString()) {
                                                    row.content.isContentEnabled = true;
                                                    row.feedbacks.isFeedbackControlEnabled = false;
                                                } else if (event.target.value == DashboardMode.Timeterminal.toString()) {
                                                    row.content.isContentEnabled = false;
                                                    row.feedbacks.isFeedbackControlEnabled = false;
                                                }
                                                onChange({ target: { name: "row", value: "" } });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={DashboardMode.ContentFeedback}
                                                control={<Radio />}
                                                label="Content und Feedback"
                                            />
                                            <FormControlLabel
                                                value={DashboardMode.ContentAndTimeterminal}
                                                control={<Radio />}
                                                label="Content und Timeterminal"
                                            />
                                            <FormControlLabel
                                                value={DashboardMode.FullScreenFeedback}
                                                control={<Radio />}
                                                label="Fullscreen Feedback"
                                            />
                                            <FormControlLabel
                                                value={DashboardMode.Timeterminal}
                                                control={<Radio />}
                                                label="Fullscreen Timeterminal"
                                            />
                                        </RadioGroup>

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Typography variant="h4">{t("myUnitsSettings.contentList")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={6} direction="row" className={classes.gridFloatLeft}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t("myUnitsSettings.contentSelection")}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="gender"
                                        name="contentCode"
                                        value={row.content?.contentCode || ContentCode.News}
                                        onChange={onChange}
                                    >
                                        <FormControlLabel
                                            value={ContentCode.News}
                                            control={<Radio />}
                                            label={t("myUnitsSettings.news")}
                                        />
                                        <FormControlLabel
                                            value={ContentCode.Weather}
                                            control={<Radio />}
                                            label={t("myUnitsSettings.weather")}
                                        />
                                        <FormControlLabel
                                            value={ContentCode.Advertisements}
                                            control={<Radio />}
                                            label={t("myUnitsSettings.advertisements")}
                                            disabled={(group?.adLicenseExpired)}
                                        />
                                        <FormControlLabel
                                            value={ContentCode.WebView}
                                            control={<Radio />}
                                            label="IFrame"
                                        />
                                        <FormControlLabel
                                            value={ContentCode.Combination}
                                            control={<Radio />}
                                            label={t("myUnitsSettings.combinationAdvanced")}
                                        />
                                    </RadioGroup>
                                    {
                                        row?.content &&
                                        group &&
                                        row.content.contentCode === ContentCode.Advertisements &&
                                        group.adLicenseExpired &&
                                        <FormHelperText >Werbunglizenz ist erforderlich, um diese Funktion zu verwenden</FormHelperText>
                                    }

                                </FormControl>
                            </Grid>
                            <Grid item xs={6} direction="row" className={classes.gridFloatLeft}>
                                {
                                    group &&
                                    !group.adLicenseExpired &&
                                    group.isFreeAdLicense &&
                                    group.adLicenseValidUntil &&
                                    <Grid>
                                        <FormHelperText className="contained">
                                            Sie haben die Möglichkeit Ihre persönliche Werbung am Check Me Now Gerät anzuzeigen.
                                            Ihre kostenlose Lizenz läuft in {diffDays(new Date(group.adLicenseValidUntil), new Date())} Tagen ab.
                                        </FormHelperText>
                                        <Button
                                            className={classes.marginTop}
                                            size="small"
                                            startIcon={<Add />}
                                            variant={'contained'}
                                            color="primary"

                                            onClick={() => {
                                                const queryParams = { showall: "1", chosenUnit: row.id || "", chosenUnitName: row.locationInformation?.notificationName || "", navigatedFrom: NavigatedFromType.SettingsAdsUnit }
                                                const queryString = new URLSearchParams(queryParams).toString()
                                                history.push({
                                                    pathname: '/portal/shop',
                                                    search: queryString
                                                });
                                            }}
                                        >
                                            {t("myUnitsSettings.extendLicenseForAdvertising")}
                                        </Button>
                                    </Grid>

                                }
                                {
                                    !showAdsVisible &&
                                    buildExpiredAds()
                                }
                            </Grid>
                        </Grid>





                        {
                            row?.content &&
                            row.content.contentCode == ContentCode.News &&
                            buildNewsItem("newsLanguageContent", row.content?.news?.language)
                        }
                        {
                            row?.content &&
                            row.content.contentCode == ContentCode.WebView &&
                            buildWebViewItem("webViewContent", row.content?.webView?.url)
                        }
                        {
                            row?.content &&
                            row.content.contentCode == ContentCode.Weather &&
                            buildWeatherItem("weatherCityContent", row.content?.weather?.city, row.content.weather?.longitude || "", row.content.weather?.latitude || "")
                        }

                        {
                            showAdsVisible &&
                            buildAdvertismentItem()
                        }

                        {
                            row.content &&
                            row.content.contentCode === ContentCode.Combination &&
                            <Grid container item direction="column" spacing={3}>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" >
                                        {t("myUnitsSettings.combinationOf2ContentUnits", {title: row.content.contentCombinationItems.length})}
                                    </Typography>
                                </Grid>
                                <Grid container xs={12} item direction="row" spacing={3}>
                                    <Grid item>
                                        <Button
                                            size="large"
                                            startIcon={<AddBox />}
                                            variant={'contained'}
                                            onClick={() => {
                                                let newContent = new ContentCombinationItem();
                                                newContent.weather = new ContentWeather();

                                                newContent.contentCode = ContentCode.Weather;

                                                onChange({ target: { name: 'addContentCombination', value: JSON.stringify(newContent) } });
                                            }}
                                            color="primary">
                                            {t("myUnitsSettings.addWeather")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="large"
                                            startIcon={<AddBox />}
                                            variant={'contained'}
                                            onClick={() => {
                                                let newContent = new ContentCombinationItem();
                                                newContent.news = new ContentNews();

                                                newContent.contentCode = ContentCode.News;

                                                onChange({ target: { name: 'addContentCombination', value: JSON.stringify(newContent) } });
                                            }}
                                            color="primary">
                                            {t("myUnitsSettings.addNews")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="large"
                                            startIcon={<AddBox />}
                                            disabled={!showAdsVisible}
                                            variant={'contained'}
                                            onClick={() => {
                                                let newContent = new ContentCombinationItem();

                                                newContent.contentCode = ContentCode.Advertisements;

                                                onChange({ target: { name: 'addContentCombination', value: JSON.stringify(newContent) } });
                                            }}
                                            color="primary">
                                            {t("myUnitsSettings.addAdvertisement")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="large"
                                            startIcon={<AddBox />}
                                            disabled={!showAdsVisible}
                                            variant={'contained'}
                                            onClick={() => {
                                                let newContent = new ContentCombinationItem();

                                                newContent.contentCode = ContentCode.WebView;

                                                onChange({ target: { name: 'addContentCombination', value: JSON.stringify(newContent) } });
                                            }}
                                            color="primary">
                                            {t("myUnitsSettings.addIFrame")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {
                                    row.content.contentCombinationItems.map((el, index) => {
                                        if (el.contentCode == ContentCode.Advertisements && el.isSumTogetherSlidesDurationEnabled) {
                                            const allSideBarItems = row.sideBarImage?.items.filter(x => el.sideBarImageItemIds.some(y => y == x.id));
                                            let sumSlideDur = allSideBarItems?.reduce((preVal, curVal) => {
                                                return preVal + curVal.slideDuration;
                                            }, 0)
                                            el.slideDuration = sumSlideDur || 4;
                                        }

                                        return (
                                            <Grid container item spacing={3}>
                                                <Grid container direction="row" item spacing={3} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" >
                                                            {index + 1}. {t("myUnitsSettings.contentUnit")} - {Content.getContentCodeString(el.contentCode,t)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={4}>
                                                        {buildCombinationItem(el, index)}
                                                    </Grid>
                                                    {el.contentCode == ContentCode.Advertisements &&
                                                        <Grid item container direction="row" alignItems="center" lg={1}>
                                                            <Grid item>
                                                                <Typography>{el.isSumTogetherSlidesDurationEnabled ? "Summe Aktiviert" : "Summe Deaktiviert"}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Switch
                                                                    color="primary"
                                                                    checked={el.isSumTogetherSlidesDurationEnabled}
                                                                    onChange={() => {
                                                                        el.isSumTogetherSlidesDurationEnabled = !el.isSumTogetherSlidesDurationEnabled;
                                                                        if (!el.isSumTogetherSlidesDurationEnabled) el.slideDuration = 4;
                                                                        onChange({ target: { name: "row", value: "" } });
                                                                    }} />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    <Grid item lg={3}>
                                                        <Slider
                                                            value={el.slideDuration}

                                                            onChange={(event, value: number | number[]) => {
                                                                if (!value) return;
                                                                el.slideDuration = value as number;
                                                                onChange({ target: { name: "row", value: "" } });
                                                            }}
                                                            min={ContentCombinationItem.minSlideDuration}
                                                            max={ContentCombinationItem.maxSlideDuration}
                                                            step={1} />
                                                    </Grid>
                                                    <Grid item lg={1}>
                                                        <TextFieldGroupSettings
                                                            label="Sekunden"
                                                            value={el.slideDuration}
                                                            inputProps={{ min: ContentCombinationItem.minSlideDuration, max: ContentCombinationItem.maxSlideDuration }}
                                                            type="number"
                                                            onChange={(event) => {
                                                                el.slideDuration = parseInt(event.target.value);
                                                                onChange({ target: { name: "row", value: "" } });
                                                            }}
                                                            variant="filled"
                                                        />
                                                    </Grid>
                                                    <Grid item container direction="row" alignItems="center" lg={1}>
                                                        <Grid item>
                                                            <Typography>{el.activated ? "Aktiviert" : "Deaktiviert"}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Switch
                                                                color="primary"
                                                                checked={el.activated}
                                                                onChange={() => {
                                                                    el.activated = !el.activated;
                                                                    onChange({ target: { name: "row", value: "" } });
                                                                }} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={1}>
                                                        <Button
                                                            size="medium"
                                                            variant="outlined"
                                                            style={{ backgroundColor: errorColor, color: white }}
                                                            aria-label="add"
                                                            startIcon={<DeleteForever />}
                                                            onClick={() => {
                                                                row.content?.contentCombinationItems.splice(index, 1);
                                                                onChange({ target: { name: "row", value: "" } });
                                                            }}>
                                                            Entfernen
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        }


                        <Grid item hidden>
                            <GroupSideBarImagesDialog
                                onChange={onChange}
                                groupId={row.id as string}
                                row={row}
                                type={type}
                                contentCombinationIndex={contentCombinationIndex}
                                open={GroupSettingsSideBarSettingsItemsDialogIsOpen}
                                onClose={() => setGroupSettingsSideBarSettingsItemsDialogIsOpen(false)} />
                        </Grid>
                        <Grid item hidden>
                            <GroupSideBarImagesAssignmentDialog
                                onChange={onChange}
                                groupId={row.id as string}
                                row={row}
                                type={type}
                                contentCombinationIndex={contentCombinationIndex}
                                open={GroupSettingsSideBarSettingsItemsAssignmentDialogIsOpen}
                                onClose={() => setGroupSettingsSideBarSettingsItemsAssignmentDialogIsOpen(false)} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <ProgressDialogComponent progressData={progressData} open={isUploadingFiles} setOpen={setIsUploadingFiles} title={"Werbung-Daten werden hochgeladen. Bitte warten!"} />
        </Grid>
    );
}

export enum DashboardMode {
    ContentFeedback = 0,
    FullScreenFeedback = 1,
    ContentAndTimeterminal = 2,
    Timeterminal = 3
}

export enum ComponentPartType {
    TouchDisplay = 0,
    TouchDisplay2 = 1,
    PublicFeedback = 2,
    PublicFeedback2 = 3,

}

export const DisplayTextComponent = ({ row, onChange, title = "displayDisplayInfoTextAbove", componentType = ComponentPartType.TouchDisplay }: { row: any, onChange: any, title?: string, componentType?: ComponentPartType }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const {t} = useTranslation();

    const rowProperty = React.useMemo(() => {
        switch (componentType) {
            case ComponentPartType.TouchDisplay:
                return "displayText1"
            case ComponentPartType.TouchDisplay2:
                return "displayText2"
            case ComponentPartType.PublicFeedback:
                return "publicDisplayText1"
            case ComponentPartType.PublicFeedback2:
                return "publicDisplayText2"
            default:
                return "displayText1"
        }
    }, []);

    const rowPropertyColor = React.useMemo(() => {
        switch (componentType) {
            case ComponentPartType.TouchDisplay:
                return "displayText1Color"
            case ComponentPartType.TouchDisplay2:
                return "displayText2Color"
            case ComponentPartType.PublicFeedback:
                return "publicDisplayText1Color"
            case ComponentPartType.PublicFeedback2:
                return "publicDisplayText2Color"
            default:
                return "displayText1Color"
        }
    }, []);

    const rowPropertyAnimation = React.useMemo(() => {
        switch (componentType) {
            case ComponentPartType.TouchDisplay:
                return "displayTextAnimationMode1"
            case ComponentPartType.TouchDisplay2:
                return "displayTextAnimationMode2"
            default:
                return "displayTextAnimationMode1"
        }
    }, []);


    return (
        <Grid item container direction="row" spacing={3} alignItems="center">
            <Grid item lg={3}>
                <Typography variant="h5">{t(`myUnitsSettings.${title}`)}</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextFieldGroupSettings
                    fullWidth
                    label={t(`myUnitsSettings.${title}`)}
                    name={rowProperty}
                    multiline
                    minRows={3}
                    value={row.content?.[rowProperty]}
                    onChange={(event) => {
                        onChange({ target: { name: rowProperty, value: event.target.value } });
                    }}
                    variant="filled"
                />
            </Grid>


            <Grid item>
                <Button aria-describedby={""} size={"large"} startIcon={<ColorLens />} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                }}>
                    {t("myUnitsSettings.selectFontColor")}
                </Button>
                <Popover
                    id={""}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <ChromePicker color={row?.content?.[rowPropertyColor]}
                        onChange={(event) => {
                            if (!row.content[rowPropertyColor] || !event) return;
                            row.content[rowPropertyColor] = event.hex;
                            onChange({ target: { name: "row", value: "" } });

                        }} />
                </Popover>

            </Grid>

            {(componentType == ComponentPartType.TouchDisplay || componentType == ComponentPartType.TouchDisplay2) &&

                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t("myUnitsSettings.textAnimationSelectionAbove")}</FormLabel>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="contentCode"
                            value={row?.content?.[rowPropertyAnimation]}
                            onChange={(event) => {
                                if (row?.content?.[rowPropertyAnimation] == null) return;

                                row.content[rowPropertyAnimation] = parseInt(event.target.value);

                                onChange({ target: { name: "row", value: "" } });
                            }}
                        >
                            <FormControlLabel
                                value={ContentDisplayTextAnimationMode.NoAnimation}
                                control={<Radio />}
                                label={t("myUnitsSettings.noAnimation")}
                            />
                            <FormControlLabel
                                value={ContentDisplayTextAnimationMode.HorizontalSpin}
                                control={<Radio />}
                                label={t("myUnitsSettings.horizontalRotation")}
                            />
                            <FormControlLabel
                                value={ContentDisplayTextAnimationMode.Pulse}
                                control={<Radio />}
                                label={t("myUnitsSettings.pulsing")}
                            />
                        </RadioGroup>

                    </FormControl>
                </Grid>
            }
            {(componentType == ComponentPartType.TouchDisplay || componentType == ComponentPartType.TouchDisplay2) &&

                <Grid item lg={6} >
                    <Alert severity="info">{t("myUnitsSettings.animationConsumesCPUPowerAbove")}</Alert>
                </Grid>
            }
        </Grid>
    )
}

export const FeedbackDisplayModeComponent = ({ row, onChange, smileImagesUri }: { row: any, onChange: any, smileImagesUri: AzureStorageBrowserModel[] }) => {
    const { refreshSettings } = React.useContext(SettingsContext);
    const { userAccount }: { userAccount: UserAccount } = React.useContext(GlobalContext);
    const {t} = useTranslation();

    return (
        <Grid item xs={12} spacing={3} container direction="column" style={{}}>
            <Grid item>
                <Typography variant="h5">Smileys</Typography>
            </Grid>
            <FeedbackSmileModeComponent row={row} onChange={onChange} />
            <Grid item>
                <Typography variant="h5">{t("myUnitsSettings.backgroundColor")}</Typography>
            </Grid>
            <Grid item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("myUnitsSettings.backgroundColorSelection")}</FormLabel>
                    <RadioGroup
                        row
                        aria-label="gender"
                        name="contentCode"
                        value={row?.content?.displayColorMode}
                        onChange={(event) => {
                            if (row?.content?.displayColorMode == null) return;

                            row.content.displayColorMode = parseInt(event.target.value);

                            onChange({ target: { name: "row", value: "" } });
                        }}
                    >
                        <FormControlLabel
                            value={ContentDisplayColorMode.ColorChange}
                            control={<Radio />}
                            label={t("myUnitsSettings.colorAnimation")}
                        />
                        <FormControlLabel
                            value={ContentDisplayColorMode.CustomColor}
                            control={<Radio />}
                            label={t("myUnitsSettings.customColor")}
                        />
                        <FormControlLabel
                            value={ContentDisplayColorMode.ColorCombination}
                            control={<Radio />}
                            label={t("myUnitsSettings.colorCombination")}
                        />
                    </RadioGroup>

                </FormControl>
            </Grid>
            {row.content?.displayColorMode == ContentDisplayColorMode.CustomColor &&
                <BackgroundColorFeedbackComponent row={row} onChange={onChange} />
            }
            {userAccount && userAccount.userType == UserType.Admin &&
                <Grid item xs={12} container direction="row" spacing={3} alignItems="center">
                    <Grid item>
                        <Autocomplete
                            freeSolo
                            fullWidth
                            value={row.feedbacks?.veryPositiveFeedbackUrl}
                            onChange={(event: any, newValue: string | null) => {
                                if (!newValue) return;
                                if (row.feedbacks) {
                                    let smile = smileImagesUri.find(x => x.fileName == newValue);
                                    row.feedbacks.veryPositiveFeedbackUrl = smile?.uri || "";
                                    refreshSettings();
                                }
                            }}

                            id="controllable-states-demo"
                            options={smileImagesUri.map(x => x.fileName)}
                            sx={{ width: 300 }}
                            renderInput={(params: any) => <TextFieldGroupSettings fullWidth
                                {...params} label={t("myUnitsSettings.veryPositiveSmileUrl")}
                                onChange={(event) => {
                                    if (row.feedbacks) {
                                        row.feedbacks.veryPositiveFeedbackUrl = event.target.value;
                                        refreshSettings();
                                    }

                                }}
                                variant="filled"
                            />

                            }
                        />

                    </Grid>
                    <Grid item>

                        <Autocomplete
                            freeSolo
                            fullWidth
                            value={row.feedbacks?.positiveFeedbackUrl}
                            onChange={(event: any, newValue: string | null) => {
                                if (!newValue) return;
                                if (row.feedbacks) {
                                    let smile = smileImagesUri.find(x => x.fileName == newValue);
                                    row.feedbacks.positiveFeedbackUrl = smile?.uri || "";
                                    refreshSettings();
                                }
                            }}

                            id="controllable-states-demo"
                            options={smileImagesUri.map(x => x.fileName)}
                            sx={{ width: 300 }}
                            renderInput={(params: any) => <TextFieldGroupSettings
                                fullWidth
                                {...params}
                                label="Positive Smile URL"
                                name="positiveFeedbackUrl"
                                value={row.feedbacks?.positiveFeedbackUrl}
                                onChange={(event) => {
                                    if (row.feedbacks) {
                                        row.feedbacks.positiveFeedbackUrl = event.target.value;
                                        refreshSettings();
                                    }
                                }}
                                variant="filled"
                            />

                            }
                        />
                    </Grid>
                    <Grid item>

                        <Autocomplete
                            freeSolo
                            fullWidth
                            value={row.feedbacks?.neutralFeedbackUrl}
                            onChange={(event: any, newValue: string | null) => {
                                if (!newValue) return;
                                if (row.feedbacks) {
                                    let smile = smileImagesUri.find(x => x.fileName == newValue);
                                    row.feedbacks.neutralFeedbackUrl = smile?.uri || "";
                                    refreshSettings();
                                }
                            }}

                            id="controllable-states-demo"
                            options={smileImagesUri.map(x => x.fileName)}
                            sx={{ width: 300 }}
                            renderInput={(params: any) => <TextFieldGroupSettings
                                fullWidth
                                {...params}
                                label="Neutral Smile URL"
                                name="neutralFeedbackUrl"
                                value={row.feedbacks?.neutralFeedbackUrl}
                                onChange={(event) => {
                                    if (row.feedbacks) {
                                        row.feedbacks.neutralFeedbackUrl = event.target.value;
                                        refreshSettings();
                                    }
                                }}
                                variant="filled"
                            />

                            }
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            freeSolo
                            fullWidth
                            value={row.feedbacks?.negativeFeedbackUrl}
                            onChange={(event: any, newValue: string | null) => {
                                if (!newValue) return;
                                if (row.feedbacks) {
                                    let smile = smileImagesUri.find(x => x.fileName == newValue);
                                    row.feedbacks.negativeFeedbackUrl = smile?.uri || "";
                                    refreshSettings();
                                }
                            }}

                            id="controllable-states-demo"
                            options={smileImagesUri.map(x => x.fileName)}
                            sx={{ width: 300 }}
                            renderInput={(params: any) => <TextFieldGroupSettings
                                fullWidth
                                {...params}
                                label="Negative Smile URL"
                                name="negativeSmileUrl"
                                value={row.feedbacks?.negativeFeedbackUrl}
                                onChange={(event) => {
                                    if (row.feedbacks) {
                                        row.feedbacks.negativeFeedbackUrl = event.target.value;
                                        refreshSettings();
                                    }
                                }}
                                variant="filled"
                            />

                            }
                        />

                    </Grid>
                    <Grid item>
                        <Autocomplete
                            freeSolo
                            fullWidth
                            value={row.feedbacks?.veryNegativeFeedbackUrl}
                            onChange={(event: any, newValue: string | null) => {
                                if (!newValue) return;
                                if (row.feedbacks) {
                                    let smile = smileImagesUri.find(x => x.fileName == newValue);
                                    row.feedbacks.veryNegativeFeedbackUrl = smile?.uri || "";
                                    refreshSettings();
                                }
                            }}

                            id="controllable-states-demo"
                            options={smileImagesUri.map(x => x.fileName)}
                            sx={{ width: 300 }}
                            renderInput={(params: any) => <TextFieldGroupSettings
                                fullWidth
                                {...params}
                                label={t("myUnitsSettings.veryNegativeSmileUrl")}
                                name="veryNegativeSmileUrl"
                                value={row.feedbacks?.veryNegativeFeedbackUrl}
                                onChange={(event) => {
                                    if (row.feedbacks) {
                                        row.feedbacks.veryNegativeFeedbackUrl = event.target.value;
                                        refreshSettings();
                                    }
                                }}
                                variant="filled"
                            />

                            }
                        />

                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export const BackgroundColorFeedbackComponent = ({ row, onChange, componentType = ComponentPartType.TouchDisplay }: { row: any, onChange: any, componentType?: ComponentPartType }) => {

    const rowProperty = React.useMemo(() => {
        switch (componentType) {
            case ComponentPartType.TouchDisplay:
                return "displayBackgroundColor"
            case ComponentPartType.PublicFeedback:
                return "publicDisplayBackgroundColor"
            default:
                return "displayBackgroundColor"
        }
    }, []);

    return (
        <Grid item>
            <ChromePicker color={row?.content[rowProperty]}
                onChange={(event) => {
                    if (!row.content[rowProperty] || !event) return;
                    row.content[rowProperty] = event.hex;
                    onChange({ target: { name: "row", value: "" } });

                }} />
        </Grid>
    )
}

export const FeedbackSmileModeComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item container direction={"column"} spacing={3}>
            <Grid item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Smileys Auswahl</FormLabel>
                    <RadioGroup
                        row
                        aria-label="gender"
                        value={row?.content?.smilesType}
                        onChange={(event) => {
                            if (row?.content?.smilesType == null) return;

                            row.content.smilesType = parseInt(event.target.value);

                            onChange({ target: { name: "row", value: "" } });
                        }}
                    >
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Standard}
                            control={<Radio />}
                            label="Standard"
                        />
                    </RadioGroup>
                    <RadioGroup
                        row
                        aria-label="gender"
                        value={row?.content?.smilesType}
                        onChange={(event) => {
                            if (row?.content?.smilesType == null) return;

                            row.content.smilesType = parseInt(event.target.value);

                            onChange({ target: { name: "row", value: "" } });
                        }}
                    >

                        <FormControlLabel
                            value={GroupSettingsSmilesType.Carnival}
                            control={<Radio />}
                            label={t("myUnitsSettings.carnival")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Eastern}
                            control={<Radio />}
                            label={t("myUnitsSettings.easter")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Spring}
                            control={<Radio />}
                            label={t("myUnitsSettings.spring")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Summer}
                            control={<Radio />}
                            label={t("myUnitsSettings.summer")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.October}
                            control={<Radio />}
                            label={t("myUnitsSettings.oktoberfest")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Autumn}
                            control={<Radio />}
                            label={t("myUnitsSettings.autumn")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Winter}
                            control={<Radio />}
                            label={t("myUnitsSettings.winter")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Christmas}
                            control={<Radio />}
                            label={t("myUnitsSettings.christmas")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Silvester}
                            control={<Radio />}
                            label={t("myUnitsSettings.newYearsEve")}
                        />


                    </RadioGroup>


                    <RadioGroup
                        row
                        aria-label="gender"
                        value={row?.content?.smilesType}
                        onChange={(event) => {
                            if (row?.content?.smilesType == null) return;

                            row.content.smilesType = parseInt(event.target.value);

                            onChange({ target: { name: "row", value: "" } });
                        }}
                    >

                        <FormControlLabel
                            value={GroupSettingsSmilesType.Carnival2}
                            control={<Radio />}
                            label={t("myUnitsSettings.carnival2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Eastern2}
                            control={<Radio />}
                            label={t("myUnitsSettings.easter2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Spring2}
                            control={<Radio />}
                            label={t("myUnitsSettings.spring2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Summer2}
                            control={<Radio />}
                            label={t("myUnitsSettings.summer2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.October2}
                            control={<Radio />}
                            label={t("myUnitsSettings.oktoberfest2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Autumn2}
                            control={<Radio />}
                            label={t("myUnitsSettings.autumn2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Halloween2}
                            control={<Radio />}
                            label={t("myUnitsSettings.halloween2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Winter2}
                            control={<Radio />}
                            label={t("myUnitsSettings.winter2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Christmas2}
                            control={<Radio />}
                            label={t("myUnitsSettings.christmas2")}
                        />
                        <FormControlLabel
                            value={GroupSettingsSmilesType.Silvester2}
                            control={<Radio />}
                            label={t("myUnitsSettings.newYearsEve2")}
                        />


                    </RadioGroup>

                </FormControl>
            </Grid>
            <Grid item container direction={"row"} spacing={3}>
                <Grid item width={200}>
                    {getGreenSmile(row, () => { })}
                </Grid>
                <Grid item width={200}>
                    {getYellowSmile(row, () => { })}
                </Grid>
                <Grid item width={200}>
                    {getRedSmile(row, () => { })}
                </Grid>
            </Grid>

        </Grid>
    )

}