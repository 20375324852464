import Paper from '@mui/material/Paper';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { toiletTypePredicate } from './components/Cells';
import { mapToiletTypeKey } from './Base';
import { Search } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { GROUP_ID, GROUP_NAME } from '../../constants';
import React from 'react';
import { Group } from '../../models/Group';
import { setStorageForDrawer } from '../../helpers/storage-management';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAll = ({ groups, selection, setSelection, setSelectedGroupName, setSelectedGroupId, setSelectedGroupType }
    : { groups: GroupLicenseTable[], selection: any, setSelection: any, setSelectedGroupName: any, setSelectedGroupId: any, setSelectedGroupType: any }) => {

    const history = useHistory();

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    return (
        <Paper>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={[
                    {
                        name: "type",
                        title: 'Einheit Typ',
                        getCellValue: (row: GroupLicenseTable) => Group.GetLabelByType(row.groupType),
                    },
                    {
                        name: keys.notificationName,
                        title: 'Einheit Name',
                        getCellValue: (row: GroupLicenseTable) => {
                            return (
                                <Button
                                    variant="contained"
                                    style={{ minWidth: 200 }}
                                    size="large"
                                    color="primary"
                                    startIcon={<Search />}
                                    onClick={() => {

                                        setSelectedGroupName(row.notificationName);
                                        setSelectedGroupId(row.id);
                                        setSelectedGroupType(row.groupType);
                                        setStorageForDrawer(row);

                                        history.push(`/portal/group/${row.id}`);
                                    }}
                                >
                                    {row.notificationName || "kein name"}
                                </Button>
                            );
                        },
                    },
                    {
                        name: keys.objectName,
                        title: 'Objekt Name',
                        getCellValue: (row: GroupLicenseTable) => row.objectName,
                    },
                    {
                        name: keys.levelName,
                        title: 'Ebene',
                        getCellValue: (row: GroupLicenseTable) => row.levelName,
                    },
                    {
                        name: keys.roomName,
                        title: 'Kunde',
                        getCellValue: (row: GroupLicenseTable) => row.roomName,
                    },
                    {
                        name: keys.toiletType,
                        title: 'Toiletten Typ',
                        getCellValue: (row: GroupLicenseTable) => mapToiletTypeKey(row.toiletType),
                    },
                    {
                        name: keys.uniqueDeviceId,
                        title: 'Device Id',
                        getCellValue: (row: GroupLicenseTable) => row.uniqueDeviceId,
                    },
                    {
                        name: keys.uniqueDeviceId,
                        title: 'Device Id',
                        getCellValue: (row: GroupLicenseTable) => row.uniqueDeviceId,
                    },
                    {
                        name: keys.userPropertyName,
                        title: t("myUnitsSettings.featureName"),
                        getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
                    }
                ]}
            >
                <FilteringState />
                <IntegratedFiltering
                    columnExtensions={[
                        { columnName: keys.toiletType, predicate: toiletTypePredicate },
                    ]}
                />

                <SortingState
                    defaultSorting={[{ columnName: keys.notificationName, direction: 'desc' }]}
                />
                <IntegratedSorting />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table />
                <TableColumnResizing
                    columnWidths={[
                        { columnName: keys.type, width: 220 },
                        { columnName: keys.notificationName, width: 260 },
                        { columnName: keys.objectName, width: 220 },
                        { columnName: keys.levelName, width: 220 },
                        { columnName: keys.roomName, width: 220 },
                        { columnName: keys.toiletType, width: 220 },
                        { columnName: keys.uniqueDeviceId, width: 200 },
                        { columnName: keys.userPropertyName, width: 200 }
                    ]}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <TableFilterRow
                />
                <PagingPanel
                    pageSizes={[5, 10, 15, 0]}
                />
            </Grid>
        </Paper>
    );
};

const keys = {
    type: 'type',
    notificationName: 'notificationName',
    objectName: 'objectName',
    levelName: 'levelName',
    roomName: 'roomName',
    toiletType: 'toiletType',
    uniqueDeviceId: 'uniqueDeviceId',
    userPropertyName: 'userPropertyName'
}