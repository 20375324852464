import { Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Maximize, Add, SupervisedUserCircle, Apartment, Today } from '@mui/icons-material';
import { CurrencyHelper } from '../../../../helpers/CurrencyHelper';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { Product, ItemProduct } from '../../../../models/Product';
import cmnExtended1 from '../../../../assets/image/shop/cmn-extended1.png';
import cmnExtended2 from '../../../../assets/image/shop/cmn-extended2.png';
import cmnContent from '../../../../assets/image/shop/cmn-content.png';
import cmnCheckpoint from '../../../../assets/image/shop/cmn-checkpoint.png';
import cmnRfid from '../../../../assets/image/shop/cmn-rfid.png';
import cmnSensor from '../../../../assets/image/shop/cmn-sensor.png';
import cmnBatterySensor from '../../../../assets/image/shop/battery-sensor.png';
import cmnBatteryDisplay from '../../../../assets/image/shop/battery-display.png';
import digiCheckpoint from '../../../../assets/image/shop/digicheckpoint.png';
import taqtFeedbackDevice from '../../../../assets/image/shop/taqtdevice.png';
import taqtTimeDevice from '../../../../assets/image/shop/taqttime.png';
import PayLicense from '../../../../assets/image/license/pay_license.png';

import cmnHardware from '../../../../assets/image/cmn.png';
import { ReactComponent as LicenseSvg } from '../../../../assets/svgs/license_grey.svg';

import { ProductType } from '../../ProductType';
import React from 'react';
import { green, grey } from '../../../../styles/colors';
import { GREEN_SMILE } from '../../../../assets';

export enum ShopListingChoice {
    HardwareInclLicense = 0,
    License = 1,
    Hardware = 2
}

export const CheckoutProductListing = ({ products, items, showAllProducts, setItems }
    : { products: Product[], items: CheckoutItem[], showAllProducts: any, setItems: React.Dispatch<React.SetStateAction<CheckoutItem[]>> }) => {

    const classes = useStyles();
    const [currentCmnLicenseHardware, setCurrentCmnLicenseHardware] = React.useState<ProductType>(ProductType.LicenseHardwarePackage12Months);
    const [currentCmnLicense, setCurrentCmnLicense] = React.useState<ProductType>(ProductType.LicenseBasic12Months);
    const [currentCmnSensorExtendedOneLicense, setCurrentCmnSensorExtendedOneLicense] = React.useState<ProductType>(ProductType.ExtensionLicenseSensorExtendedOne12Months);
    const [currentCmnSensorExtendedTwoLicense, setCurrentCmnSensorExtendedTwoLicense] = React.useState<ProductType>(ProductType.ExtensionLicenseSensorExtendedTwo12Months);
    const [currentCmnSensorExtendedOneOnlyLicense, setCurrentCmnSensorExtendedOneOnlyLicense] = React.useState<ProductType>(ProductType.OnlyLicenseSensorExtendedOne12Months);
    const [currentCmnSensorExtendedTwoOnlyLicense, setCurrentCmnSensorExtendedTwoOnlyLicense] = React.useState<ProductType>(ProductType.OnlyLicenseSensorExtendedTwo12Months);
    const [currentCmnContentManagementLicense, setCurrentCmnContentManagementLicense] = React.useState<ProductType>(ProductType.ExtensionContentManagement12Months);
    const [currentCmnCheckpointLicense, setCurrentCmnCheckpointLicense] = React.useState<ProductType>(ProductType.LicenseCheckpointPackage12Months);

    const [currentGroupUnitQualityCheckLicense, setCurrentGroupUnitQualityCheckLicense] = React.useState<ProductType>(ProductType.LicenseGroupUnitQualityCheckPackage12Months);

    const [currentObjectStandaloneLicense, setCurrentObjectStandaloneLicense] = React.useState<ProductType>(ProductType.LicenseObjectStandalonePackage12Months);

    const [bssdCurrentCmnLicenseHardware, setBssdCurrentCmnLicenseHardware] = React.useState<ProductType>(ProductType.LicenseHardwarePackageBssdBasic12Months);
    const [currentBssdCmnSensorExtendedOneLicense, setCurrentBssdCmnSensorExtendedOneLicense] = React.useState<ProductType>(ProductType.ExtensionLicenseBssdSensorExtendedOne12Months);
    const [currentLicenseLoraSensor, setCurrentLicenseLoraSensor] = React.useState<ProductType>(ProductType.LicenseLoraSensor12Months);

    const [currentGroupPublicFeedbackLicense, setCurrentGroupPublicFeedbackLicense] = React.useState<ProductType>(ProductType.LicenseGroupPublicFeedbackPackage12Months);
    const [currentGroupPayPerServiceLicense, setCurrentGroupPayPerServiceLicense] = React.useState<ProductType>(ProductType.LicenseGroupPayPerServicePackage12Months);

    const [currentGroupCalendarProLicense, setCurrentGroupCalendarProLicense] = React.useState<ProductType>(ProductType.LicenseCalendarProPackage12Months);

    const [currentGroupTaqtFeedbackLicense, setCurrentGroupTaqtFeedbackLicense] = React.useState<ProductType>(ProductType.LicenseGroupTaqtFeedbackPackage12Months);
    const [currentGroupTaqtTimeLicense, setCurrentGroupTaqtTimeLicense] = React.useState<ProductType>(ProductType.LicenseGroupTaqtTimePackage12Months);

    const [currentMaterialStockLicense, setCurrentMaterialStockLicense] = React.useState<ProductType>(ProductType.OnlyLicenseMaterialStock1);
    const [currentTimeTrackingProLicense, setCurrentTimeTrackingProLicense] = React.useState<ProductType>(ProductType.OnlyLicenseTimeTrackingPro1);
    const [currentCo2DocumentationLicense, setCurrentCo2DocumentationLicense] = React.useState<ProductType>(ProductType.OnlyLicenseCO2Documentation1);

    const [shopListingChoice, setShopListingChoice] = React.useState(ShopListingChoice.HardwareInclLicense);

    const isCMNLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseHardwarePackageTest:
            case ProductType.LicenseHardwarePackage12Months:
            case ProductType.LicenseHardwarePackage24Months:
            case ProductType.LicenseHardwarePackage36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isBssdLicenseHardwarePackage = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseHardwarePackageBssdBasicTest:
            case ProductType.LicenseHardwarePackageBssdBasic12Months:
            case ProductType.LicenseHardwarePackageBssdBasic24Months:
            case ProductType.LicenseHardwarePackageBssdBasic36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isBssdSensorExtendedOneLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.ExtensionLicenseBssdSensorExtendedOne12Months:
            case ProductType.ExtensionLicenseBssdSensorExtendedOne24Months:
            case ProductType.ExtensionLicenseBssdSensorExtendedOne36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isCMNLicenseWithoutHardware = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseBasic12Months:
            case ProductType.LicenseBasic24Months:
            case ProductType.LicenseBasic36Months:
                return true;
            default:
                return false;

        }
    }, []);
    const isSensorExtendedOneLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.ExtensionLicenseSensorExtendedOne12Months:
            case ProductType.ExtensionLicenseSensorExtendedOne24Months:
            case ProductType.ExtensionLicenseSensorExtendedOne36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isLoraSensorLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseLoraSensor12Months:
            case ProductType.LicenseLoraSensor24Months:
            case ProductType.LicenseLoraSensor36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isSensorOnlyLicenseExtendedOne = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.OnlyLicenseSensorExtendedOne12Months:
            case ProductType.OnlyLicenseSensorExtendedOne24Months:
            case ProductType.OnlyLicenseSensorExtendedOne36Months:
                return true;
            default:
                return false;

        }
    }, []);
    const isSensorExtendedTwoLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.ExtensionLicenseSensorExtendedTwo12Months:
            case ProductType.ExtensionLicenseSensorExtendedTwo24Months:
            case ProductType.ExtensionLicenseSensorExtendedTwo36Months:
                return true;
            default:
                return false;

        }
    }, []);
    const isSensorOnlyLicenseExtendedTwo = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.OnlyLicenseSensorExtendedTwo12Months:
            case ProductType.OnlyLicenseSensorExtendedTwo24Months:
            case ProductType.OnlyLicenseSensorExtendedTwo36Months:
                return true;
            default:
                return false;

        }
    }, []);
    const isContentManagementLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.ExtensionContentManagementTest:
            case ProductType.ExtensionContentManagement12Months:
            case ProductType.ExtensionContentManagement24Months:
            case ProductType.ExtensionContentManagement36Months:
                return true;
            default:
                return false;

        }
    }, []);
    const isCheckpointLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseCheckpointPackageTest:
            case ProductType.LicenseCheckpointPackage12Months:
            case ProductType.LicenseCheckpointPackage24Months:
            case ProductType.LicenseCheckpointPackage36Months:
                return true;
            default:
                return false;

        }
    }, []);
    const isGroupUnitQualityCheckLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseGroupUnitQualityCheckPackageTest:
            case ProductType.LicenseGroupUnitQualityCheckPackage12Months:
            case ProductType.LicenseGroupUnitQualityCheckPackage24Months:
            case ProductType.LicenseGroupUnitQualityCheckPackage36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isObjectStandaloneLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseObjectStandalonePackageTest:
            case ProductType.LicenseObjectStandalonePackage12Months:
            case ProductType.LicenseObjectStandalonePackage24Months:
            case ProductType.LicenseObjectStandalonePackage36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isGroupPublicFeedbackLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseGroupPublicFeedbackPackageTest:
            case ProductType.LicenseGroupPublicFeedbackPackage12Months:
            case ProductType.LicenseGroupPublicFeedbackPackage24Months:
            case ProductType.LicenseGroupPublicFeedbackPackage36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isGroupPayPerServiceLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseGroupPayPerServicePackageTest:
            case ProductType.LicenseGroupPayPerServicePackage12Months:
            case ProductType.LicenseGroupPayPerServicePackage24Months:
            case ProductType.LicenseGroupPayPerServicePackage36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isGroupCalendarProLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseCalendarProPackageTest:
            case ProductType.LicenseCalendarProPackage12Months:
            case ProductType.LicenseCalendarProPackage24Months:
            case ProductType.LicenseCalendarProPackage36Months:
                return true;
            default:
                return false;

        }
    }, []);

    const isGroupTaqtFeedbackLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseGroupTaqtFeedbackPackage12Months:
            case ProductType.LicenseGroupTaqtFeedbackPackage24Months:
            case ProductType.LicenseGroupTaqtFeedbackPackage36Months:
            case ProductType.LicenseGroupTaqtFeedbackPackageTest:
                return true;
            default:
                return false;

        }
    }, []);

    const isGroupTaqtTimeLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.LicenseGroupTaqtTimePackage12Months:
            case ProductType.LicenseGroupTaqtTimePackage24Months:
            case ProductType.LicenseGroupTaqtTimePackage36Months:
            case ProductType.LicenseGroupTaqtTimePackageTest:
                return true;
            default:
                return false;

        }
    }, []);

    const isTimeTrackingProLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.OnlyLicenseTimeTrackingPro1:
            case ProductType.OnlyLicenseTimeTrackingPro2:
            case ProductType.OnlyLicenseTimeTrackingPro3:
                return true;
            default:
                return false;

        }
    }, []);

    const isMaterialStockLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.OnlyLicenseMaterialStock1:
            case ProductType.OnlyLicenseMaterialStock2:
            case ProductType.OnlyLicenseMaterialStock3:
                return true;
            default:
                return false;

        }
    }, []);
    const isCO2DocumentationLicense = React.useCallback((product: Product) => {
        switch (product.type) {
            case ProductType.OnlyLicenseCO2Documentation1:
            case ProductType.OnlyLicenseCO2Documentation2:
            case ProductType.OnlyLicenseCO2Documentation3:
                return true;
            default:
                return false;

        }
    }, []);

    const GetLengthDescription = (productType: ProductType) => {
        return products.find(x => x.type == productType)?.lengthDescription;
    }

    const buildMonthChoice = (product: Product) => {
        let menuItems;
        let handleChange;

        const buildMenuItems = (test: ProductType | undefined, license1: ProductType, license2: ProductType, license3: ProductType) => {
            return (
                <React.Fragment>
                    {test && <FormControlLabel value={test} control={<Radio />} label={GetLengthDescription(test) || "Test"} />}
                    <FormControlLabel value={license1} control={<Radio />} label={GetLengthDescription(license1) || "12"} />
                    <FormControlLabel value={license2} control={<Radio />} label={GetLengthDescription(license2) || "24"} />
                    <FormControlLabel value={license3} control={<Radio />} label={GetLengthDescription(license3) || "36"} />
                </React.Fragment>
            )
        }

        if (isCMNLicense(product)) {

            menuItems = buildMenuItems(ProductType.LicenseHardwarePackageTest, ProductType.LicenseHardwarePackage12Months, ProductType.LicenseHardwarePackage24Months, ProductType.LicenseHardwarePackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnLicenseHardware(event.target.value);
            };
        }
        else if (isCMNLicenseWithoutHardware(product)) {
            menuItems = buildMenuItems(undefined, ProductType.LicenseBasic12Months, ProductType.LicenseBasic24Months, ProductType.LicenseBasic36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnLicense(event.target.value);
            };
        }
        else if (isLoraSensorLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.LicenseLoraSensor12Months, ProductType.LicenseLoraSensor24Months, ProductType.LicenseLoraSensor36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentLicenseLoraSensor(event.target.value);
            };
        }
        else if (isSensorExtendedOneLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.ExtensionLicenseSensorExtendedOne12Months, ProductType.ExtensionLicenseSensorExtendedOne24Months, ProductType.ExtensionLicenseSensorExtendedOne36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnSensorExtendedOneLicense(event.target.value);
            };
        }
        else if (isSensorOnlyLicenseExtendedOne(product)) {
            menuItems = buildMenuItems(undefined, ProductType.OnlyLicenseSensorExtendedOne12Months, ProductType.OnlyLicenseSensorExtendedOne24Months, ProductType.OnlyLicenseSensorExtendedOne36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnSensorExtendedOneOnlyLicense(event.target.value);
            };
        }
        else if (isSensorExtendedTwoLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.ExtensionLicenseSensorExtendedTwo12Months, ProductType.ExtensionLicenseSensorExtendedTwo24Months, ProductType.ExtensionLicenseSensorExtendedTwo36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnSensorExtendedTwoLicense(event.target.value);
            };
        }
        else if (isSensorOnlyLicenseExtendedTwo(product)) {
            menuItems = buildMenuItems(undefined, ProductType.OnlyLicenseSensorExtendedTwo12Months, ProductType.OnlyLicenseSensorExtendedTwo24Months, ProductType.OnlyLicenseSensorExtendedTwo36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnSensorExtendedTwoOnlyLicense(event.target.value);
            };
        }
        else if (isContentManagementLicense(product)) {
            menuItems = buildMenuItems(ProductType.ExtensionContentManagementTest, ProductType.ExtensionContentManagement12Months, ProductType.ExtensionContentManagement24Months, ProductType.ExtensionContentManagement36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnContentManagementLicense(event.target.value);
            };
        }
        else if (isCheckpointLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseCheckpointPackageTest, ProductType.LicenseCheckpointPackage12Months, ProductType.LicenseCheckpointPackage24Months, ProductType.LicenseCheckpointPackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCmnCheckpointLicense(event.target.value);
            };
        }
        else if (isGroupUnitQualityCheckLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseGroupUnitQualityCheckPackageTest, ProductType.LicenseGroupUnitQualityCheckPackage12Months, ProductType.LicenseGroupUnitQualityCheckPackage24Months, ProductType.LicenseGroupUnitQualityCheckPackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentGroupUnitQualityCheckLicense(event.target.value);
            };
        }
        else if (isObjectStandaloneLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseObjectStandalonePackageTest, ProductType.LicenseObjectStandalonePackage12Months, ProductType.LicenseObjectStandalonePackage24Months, ProductType.LicenseObjectStandalonePackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentObjectStandaloneLicense(event.target.value);
            };
        }
        else if (isBssdLicenseHardwarePackage(product)) {
            menuItems = buildMenuItems(ProductType.LicenseHardwarePackageBssdBasicTest, ProductType.LicenseHardwarePackageBssdBasic12Months, ProductType.LicenseHardwarePackageBssdBasic24Months, ProductType.LicenseHardwarePackageBssdBasic36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setBssdCurrentCmnLicenseHardware(event.target.value);
            };
        }
        else if (isBssdSensorExtendedOneLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.ExtensionLicenseBssdSensorExtendedOne12Months, ProductType.ExtensionLicenseBssdSensorExtendedOne24Months, ProductType.ExtensionLicenseBssdSensorExtendedOne36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentBssdCmnSensorExtendedOneLicense(event.target.value);
            };
        }
        else if (isGroupPublicFeedbackLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseGroupPublicFeedbackPackageTest, ProductType.LicenseGroupPublicFeedbackPackage12Months, ProductType.LicenseGroupPublicFeedbackPackage24Months, ProductType.LicenseGroupPublicFeedbackPackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentGroupPublicFeedbackLicense(event.target.value);
            };
        }
        else if (isGroupPayPerServiceLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseGroupPayPerServicePackageTest, ProductType.LicenseGroupPayPerServicePackage12Months, ProductType.LicenseGroupPayPerServicePackage24Months, ProductType.LicenseGroupPayPerServicePackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentGroupPayPerServiceLicense(event.target.value);
            };
        }
        else if (isGroupCalendarProLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseCalendarProPackageTest, ProductType.LicenseCalendarProPackage12Months, ProductType.LicenseCalendarProPackage24Months, ProductType.LicenseCalendarProPackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentGroupCalendarProLicense(event.target.value);
            };
        }
        else if (isGroupTaqtFeedbackLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseGroupTaqtFeedbackPackageTest, ProductType.LicenseGroupTaqtFeedbackPackage12Months, ProductType.LicenseGroupTaqtFeedbackPackage24Months, ProductType.LicenseGroupTaqtFeedbackPackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentGroupTaqtFeedbackLicense(event.target.value);
            };
        }
        else if (isGroupTaqtTimeLicense(product)) {
            menuItems = buildMenuItems(ProductType.LicenseGroupTaqtTimePackageTest, ProductType.LicenseGroupTaqtTimePackage12Months, ProductType.LicenseGroupTaqtTimePackage24Months, ProductType.LicenseGroupTaqtTimePackage36Months);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentGroupTaqtTimeLicense(event.target.value);
            };
        }
        else if (isTimeTrackingProLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.OnlyLicenseTimeTrackingPro1, ProductType.OnlyLicenseTimeTrackingPro2, ProductType.OnlyLicenseTimeTrackingPro3);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentTimeTrackingProLicense(event.target.value);
            };
        }
        else if (isMaterialStockLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.OnlyLicenseMaterialStock1, ProductType.OnlyLicenseMaterialStock2, ProductType.OnlyLicenseMaterialStock3);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentMaterialStockLicense(event.target.value);
            };
        }
        else if (isCO2DocumentationLicense(product)) {
            menuItems = buildMenuItems(undefined, ProductType.OnlyLicenseCO2Documentation1, ProductType.OnlyLicenseCO2Documentation2, ProductType.OnlyLicenseCO2Documentation3);
            handleChange = (event: React.ChangeEvent<{ value: any }>) => {
                setCurrentCo2DocumentationLicense(event.target.value);
            };
        }
        else {
            return;
        }

        return <Grid item>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    value={product.type}
                    onChange={handleChange}
                >
                    {menuItems}
                </RadioGroup>
            </FormControl>
        </Grid>;
    }

    let cmnLicenseFiltered = products.filter((el) => !isCMNLicense(el))
        .filter(el => !isSensorExtendedOneLicense(el))
        .filter(el => !isSensorExtendedTwoLicense(el))
        .filter(el => !isContentManagementLicense(el))
        .filter(el => !isCMNLicenseWithoutHardware(el))
        .filter(el => !isSensorOnlyLicenseExtendedOne(el))
        .filter(el => !isSensorOnlyLicenseExtendedTwo(el))
        .filter(el => !isCheckpointLicense(el))
        .filter(el => !isGroupUnitQualityCheckLicense(el))
        .filter(el => !isObjectStandaloneLicense(el))
        .filter(el => !isBssdLicenseHardwarePackage(el))
        .filter(el => !isBssdSensorExtendedOneLicense(el))
        .filter(el => !isLoraSensorLicense(el))
        .filter(el => !isGroupPublicFeedbackLicense(el))
        .filter(el => !isGroupPayPerServiceLicense(el))
        .filter(el => !isGroupCalendarProLicense(el))
        .filter(el => !isGroupTaqtFeedbackLicense(el))
        .filter(el => !isGroupTaqtTimeLicense(el))
        .filter(el => !isMaterialStockLicense(el))
        .filter(el => !isTimeTrackingProLicense(el))
        .filter(el => !isCO2DocumentationLicense(el));


    const adjustFilteredProducts = (product: Product | undefined, isLicense: any) => {
        if (product) {
            cmnLicenseFiltered.unshift(product);
        } else {
            product = products.find((el) => isLicense(el));
            if (product)
                cmnLicenseFiltered.unshift(product);
        }
    }


    //content management
    let cmnLicenseContentManagement = products.find((el) => currentCmnContentManagementLicense == el.type);
    adjustFilteredProducts(cmnLicenseContentManagement, isContentManagementLicense);

    //licenses only

    //check point
    let cmnLicenseCheckpoint = products.find((el) => currentCmnCheckpointLicense == el.type);
    adjustFilteredProducts(cmnLicenseCheckpoint, isCheckpointLicense);

    //quality check
    let cmnLicenseGroupUnitQualityCheck = products.find((el) => currentGroupUnitQualityCheckLicense == el.type);
    adjustFilteredProducts(cmnLicenseGroupUnitQualityCheck, isGroupUnitQualityCheckLicense);

    //object standalone
    let cmnLicenseObjectStandalone = products.find((el) => currentObjectStandaloneLicense == el.type);
    adjustFilteredProducts(cmnLicenseObjectStandalone, isObjectStandaloneLicense);

    let cmnOnlyLicenseSensorExtendedOne = products.find((el) => currentCmnSensorExtendedOneOnlyLicense == el.type);
    adjustFilteredProducts(cmnOnlyLicenseSensorExtendedOne, isSensorOnlyLicenseExtendedOne);

    let cmnOnlyLicenseSensorExtendedTwo = products.find((el) => currentCmnSensorExtendedTwoOnlyLicense == el.type);
    adjustFilteredProducts(cmnOnlyLicenseSensorExtendedTwo, isSensorOnlyLicenseExtendedTwo);

    let cmnLicenseWithoutHardware = products.find((el) => currentCmnLicense == el.type);
    adjustFilteredProducts(cmnLicenseWithoutHardware, isCMNLicenseWithoutHardware);

    //extended two
    let cmnLicenseSensorExtendedTwo = products.find((el) => currentCmnSensorExtendedTwoLicense == el.type);
    adjustFilteredProducts(cmnLicenseSensorExtendedTwo, isSensorExtendedTwoLicense);

    //extended one
    let cmnLicenseSensorExtendedOne = products.find((el) => currentCmnSensorExtendedOneLicense == el.type);
    adjustFilteredProducts(cmnLicenseSensorExtendedOne, isSensorExtendedOneLicense);


    //hardware check me now license
    let cmnLicense = products.find((el) => currentCmnLicenseHardware == el.type);
    adjustFilteredProducts(cmnLicense, isCMNLicense);

    //bssd hardware check me now license
    let cmnBssdLicense = products.find((el) => bssdCurrentCmnLicenseHardware == el.type);
    adjustFilteredProducts(cmnBssdLicense, isBssdLicenseHardwarePackage);

    //bssd
    let cmnBssdSensorLicense = products.find((el) => currentBssdCmnSensorExtendedOneLicense == el.type);
    adjustFilteredProducts(cmnBssdSensorLicense, isBssdSensorExtendedOneLicense);

    //extended one
    let cmnLicenseLoraSensor = products.find((el) => currentLicenseLoraSensor == el.type);
    adjustFilteredProducts(cmnLicenseLoraSensor, isLoraSensorLicense);


    //public feedback
    let cmnLicensePublicFeedback = products.find((el) => currentGroupPublicFeedbackLicense == el.type);
    adjustFilteredProducts(cmnLicensePublicFeedback, isGroupPublicFeedbackLicense);


    //payperservice
    let cmnLicenseGroupPayPerService = products.find((el) => currentGroupPayPerServiceLicense == el.type);
    adjustFilteredProducts(cmnLicenseGroupPayPerService, isGroupPayPerServiceLicense);

    //group calendar pro
    let cmnLicenseGroupCalendarPro = products.find((el) => currentGroupCalendarProLicense == el.type);
    adjustFilteredProducts(cmnLicenseGroupCalendarPro, isGroupCalendarProLicense);

    //group taqt Feedback
    let cmnLicenseGroupTaqtFeedback = products.find((el) => currentGroupTaqtFeedbackLicense == el.type);
    adjustFilteredProducts(cmnLicenseGroupTaqtFeedback, isGroupTaqtFeedbackLicense);

    //group taqt Time
    let cmnLicenseGroupTaqtTime = products.find((el) => currentGroupTaqtTimeLicense == el.type);
    adjustFilteredProducts(cmnLicenseGroupTaqtTime, isGroupTaqtTimeLicense);


    //
    let cmnLicenseCurrentMaterialStockLicense = products.find((el) => currentMaterialStockLicense == el.type);
    adjustFilteredProducts(cmnLicenseCurrentMaterialStockLicense, isMaterialStockLicense);
    //group taqt Time
    let cmnLicenseTimeTracking = products.find((el) => currentTimeTrackingProLicense == el.type);
    adjustFilteredProducts(cmnLicenseTimeTracking, isTimeTrackingProLicense);
    //group taqt Time
    let cmnLicenseCo2Documentation = products.find((el) => currentCo2DocumentationLicense == el.type);
    adjustFilteredProducts(cmnLicenseCo2Documentation, isCO2DocumentationLicense);

    const filterShopListing = React.useCallback((productTypeFilter: ShopListingChoice) => {
        return cmnLicenseFiltered.filter(el => checkShopListingProduct(el.type) == productTypeFilter)
    }, [cmnLicenseFiltered]);

    const sortShopListing = React.useCallback((cmnLicenseFiltered: Product[]) => {
        return cmnLicenseFiltered.sort((a, b) => a.priority - b.priority)
    }, []);

    cmnLicenseFiltered = filterShopListing(shopListingChoice);

    cmnLicenseFiltered = sortShopListing(cmnLicenseFiltered);

    return <>
        <Grid container direction="row" alignItems='center' alignContent='center' spacing={3}>

            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={shopListingChoice == ShopListingChoice.HardwareInclLicense}
                            onChange={(event: any) => {
                                setShopListingChoice(ShopListingChoice.HardwareInclLicense);
                            }}
                            name={"hideHardwareProducts"}
                            color="primary"
                        />
                    }
                    label={"Hardware inkl. Lizenz"}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={shopListingChoice == ShopListingChoice.License}
                            onChange={(event: any) => {
                                setShopListingChoice(ShopListingChoice.License);
                            }}
                            name={"hideOnlyLicenseProducts"}
                            color="primary"
                        />
                    }
                    label={"Lizenz ohne Hardware"}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={shopListingChoice == ShopListingChoice.Hardware}
                            onChange={(event: any) => {
                                setShopListingChoice(ShopListingChoice.Hardware);
                            }}
                            name={"hideOnlyLicenseProducts"}
                            color="primary"
                        />
                    }
                    label={"Hardware"}
                />
            </Grid>
        </Grid>
        <Grid className={classes.root} container direction="row" spacing={3}>

            {
                cmnLicenseFiltered
                    .filter((product: Product) => (product.isVisibleInShop || showAllProducts))
                    .map((product: Product, rowIndex: number) => {
                        return (
                            <Grid item md={6} lg={4}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="row" justifyContent="space-between">
                                            <Grid item xs={8} container direction="column">
                                                <Grid item>
                                                    <Typography variant="h6" component="p" style={{ whiteSpace: 'pre-line' }}>{product.name}</Typography>
                                                </Grid>
                                                {buildMonthChoice(product)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {showHeaderImage(product)}
                                            </Grid>
                                        </Grid>
                                        <Typography variant="body2" color="textSecondary" component="p" style={{ whiteSpace: 'pre-line' }}>
                                            {product.description}
                                            {
                                                product.itemProduct &&
                                                <Grid container direction="row" className={classes.itemProductMain}>
                                                    {
                                                        product.itemProduct
                                                            .filter((itemproduct: ItemProduct) => itemproduct.isVisible)
                                                            .map((itemproduct: ItemProduct) => {
                                                                return (
                                                                    <Grid container direction="row">
                                                                        <Grid item xs={9} className={classes.itemProductLblPrice}>
                                                                            {itemproduct.name}
                                                                        </Grid>
                                                                        <Grid item xs={3} className={classes.itemProductLblPrice}>
                                                                            {CurrencyHelper.toCurrencyString(itemproduct.price)}
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })
                                                    }
                                                </Grid>

                                            }
                                        </Typography>


                                    </CardContent>
                                    <CardActions>
                                        <Grid container direction="row">
                                            <Grid item xs={6} className={classes.unitPriceContainer}>
                                                <Typography variant="h6" className={classes.unitPriceText}>
                                                    {CurrencyHelper.toCurrencyString(product.unitPrice)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ButtonGroup
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <Button
                                                        className={classes.minusButton}
                                                        variant="contained"
                                                        onClick={() => {

                                                            if (!items.some(x => x.product.type === product.type))
                                                                return;

                                                            const changedItem = items.filter(x => x.product.type === product.type)[0];
                                                            changedItem.count--;
                                                            changedItem.updatePrice();

                                                            if (changedItem.count === 0) {
                                                                items = items.filter(x => x.product.type !== product.type);
                                                            }

                                                            setItems([...items]);
                                                        }}
                                                    >
                                                        <Maximize />
                                                    </Button>
                                                    <Button style={{ fontSize: 30, fontWeight: 'bold' }}>{items.filter(x => x.product.type === product.type)[0]?.count || 0}</Button>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {

                                                            if (!items.some(x => x.product.type === product.type)) {
                                                                items.push(CheckoutItem.createItem(product, 1, product.sensorCount));
                                                                setItems([...items]);
                                                                return;
                                                            }

                                                            const changedItem = items.filter(x => x.product.type === product.type)[0];
                                                            changedItem.count++;
                                                            changedItem.updatePrice();
                                                            setItems([...items]);
                                                        }}
                                                    >
                                                        <Add />
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })
            }

        </Grid >
    </>;
};
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 12,
        },
        '&::-webkit-scrollbar-track': {
            width: 12,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 12,
            borderRadius: 16,
            backgroundColor: theme.palette.primary.main,
        },
    },
    card: {
        height: 'auto'  //288
    },
    cardContent: {
        height: 'auto', //140,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    badge: {
        width: '100%',
    },
    unitPriceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    unitPriceText: {
        fontWeight: 'bold',
    },
    minusButton: {
        paddingTop: 18,
    },
    itemProductLblPrice: {
        textAlign: 'right'
    },
    itemProductMain: {
        marginTop: '10px'
    }
}));

export const showHeaderImage = (product: Product) => {
    const imageStyles: React.CSSProperties = { width: '100%', height: 200, objectFit: 'contain' }
    switch (product.type) {
        case ProductType.LicenseHardwarePackage36Months:
        case ProductType.LicenseHardwarePackage24Months:
        case ProductType.LicenseHardwarePackage12Months:
        case ProductType.LicenseHardwarePackageTest:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/CMN%20Touch.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseHardwarePackageBssdBasicTest:
        case ProductType.LicenseHardwarePackageBssdBasic12Months:
        case ProductType.LicenseHardwarePackageBssdBasic24Months:
        case ProductType.LicenseHardwarePackageBssdBasic36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/CMN%20Battery.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ExtensionLicenseBssdSensorExtendedOne12Months:
        case ProductType.ExtensionLicenseBssdSensorExtendedOne24Months:
        case ProductType.ExtensionLicenseBssdSensorExtendedOne36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatz%20Sensoren.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseBasic12Months:
        case ProductType.LicenseBasic24Months:
        case ProductType.LicenseBasic36Months:
        case ProductType.OnlyLicenseSensorExtendedOne12Months:
        case ProductType.OnlyLicenseSensorExtendedOne24Months:
        case ProductType.OnlyLicenseSensorExtendedOne36Months:
        case ProductType.OnlyLicenseSensorExtendedTwo12Months:
        case ProductType.OnlyLicenseSensorExtendedTwo24Months:
        case ProductType.OnlyLicenseSensorExtendedTwo36Months:
            return (
                <LicenseSvg style={{ ...imageStyles }} fill={green} />
            )
        case ProductType.ExtensionLicenseSensorExtendedOne36Months:
        case ProductType.ExtensionLicenseSensorExtendedOne24Months:
        case ProductType.ExtensionLicenseSensorExtendedOne12Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Sensor%20Personenz%C3%A4hlung.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseLoraSensor12Months:
        case ProductType.LicenseLoraSensor24Months:
        case ProductType.LicenseLoraSensor36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Sensor%20Nutzungsdauer%20LoRa.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ExtensionLicenseBssdSensorExtendedOne12Months:
        case ProductType.ExtensionLicenseBssdSensorExtendedOne24Months:
        case ProductType.ExtensionLicenseBssdSensorExtendedOne36Months:
            return (
                <img
                    src={cmnBatterySensor}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ExtensionLicenseSensorExtendedTwo36Months:
        case ProductType.ExtensionLicenseSensorExtendedTwo24Months:
        case ProductType.ExtensionLicenseSensorExtendedTwo12Months:
            return (
                <img
                    src={cmnBatterySensor}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ExtensionContentManagementTest:
        case ProductType.ExtensionContentManagement12Months:
        case ProductType.ExtensionContentManagement24Months:
        case ProductType.ExtensionContentManagement36Months:
            return (
                <img
                    src={cmnContent}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseCheckpointPackageTest:
        case ProductType.LicenseCheckpointPackage12Months:
        case ProductType.LicenseCheckpointPackage24Months:
        case ProductType.LicenseCheckpointPackage36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Checkpoints%20Label.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseGroupUnitQualityCheckPackageTest:
        case ProductType.LicenseGroupUnitQualityCheckPackage12Months:
        case ProductType.LicenseGroupUnitQualityCheckPackage24Months:
        case ProductType.LicenseGroupUnitQualityCheckPackage36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Qualit%C3%A4tsmanagement.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseObjectStandalonePackageTest:
        case ProductType.LicenseObjectStandalonePackage12Months:
        case ProductType.LicenseObjectStandalonePackage24Months:
        case ProductType.LicenseObjectStandalonePackage36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Objektmanagement.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementRFIdKeys:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatz%20RFID-Chips.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementNFCKeys:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatz%20NFC-Chips.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementSensor:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatz%20Sensoren.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementHardware:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatzger%C3%A4t%20CMN%20Touch.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementDigitalCheckpoint:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Checkpoint%20Smart.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementNFCKeys:
        case ProductType.ReplacementBatteryDisplayHardware:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatzger%C3%A4t%20CMN%20Battery.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.ReplacementTaqtFeedbackHardware:
        case ProductType.ReplacementTaqtTimeHardware:
        case ProductType.ReplacementTaqtOnlyFeedback:
        case ProductType.ReplacementTaqtOnlyTime:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Ersatzger%C3%A4t%20Taqt.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseGroupPublicFeedbackPackageTest:
        case ProductType.LicenseGroupPublicFeedbackPackage12Months:
        case ProductType.LicenseGroupPublicFeedbackPackage24Months:
        case ProductType.LicenseGroupPublicFeedbackPackage36Months:
            return (
                <img
                    src={GREEN_SMILE}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseGroupPayPerServicePackageTest:
        case ProductType.LicenseGroupPayPerServicePackage12Months:
        case ProductType.LicenseGroupPayPerServicePackage24Months:
        case ProductType.LicenseGroupPayPerServicePackage36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Pay%20per%20Service.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseCalendarProPackageTest:
        case ProductType.LicenseCalendarProPackage12Months:
        case ProductType.LicenseCalendarProPackage24Months:
        case ProductType.LicenseCalendarProPackage36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/Kalender%20Pro.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseGroupTaqtFeedbackPackageTest:
        case ProductType.LicenseGroupTaqtFeedbackPackage12Months:
        case ProductType.LicenseGroupTaqtFeedbackPackage24Months:
        case ProductType.LicenseGroupTaqtFeedbackPackage36Months:
            return (
                <img
                    src={"https://restroombird.blob.core.windows.net/shop/CMN%20Taqt%20Feedback.png"}
                    style={{ ...imageStyles }}
                />
            )
        case ProductType.LicenseGroupTaqtTimePackageTest:
        case ProductType.LicenseGroupTaqtTimePackage12Months:
        case ProductType.LicenseGroupTaqtTimePackage24Months:
        case ProductType.LicenseGroupTaqtTimePackage36Months:
            return (
                <>
                    <img
                        src={"https://restroombird.blob.core.windows.net/shop/CMN%20Taqt%20Time%20Terminal.png"}
                        style={{ ...imageStyles }}
                    />
                </>
            )
        case ProductType.OnlyLicenseTimeTrackingPro1:
        case ProductType.OnlyLicenseTimeTrackingPro2:
        case ProductType.OnlyLicenseTimeTrackingPro3:
            return (
                <>
                    <img
                        src={"https://restroombird.blob.core.windows.net/shop/timetracking-pro.png"}
                        style={{ ...imageStyles }}
                    />
                </>
            )
        case ProductType.OnlyLicenseCO2Documentation1:
        case ProductType.OnlyLicenseCO2Documentation2:
        case ProductType.OnlyLicenseCO2Documentation3:
            return (
                <>
                    <img
                        src={"https://restroombird.blob.core.windows.net/shop/co2.png"}
                        style={{ ...imageStyles }}
                    />
                </>
            )
        case ProductType.OnlyLicenseMaterialStock1:
        case ProductType.OnlyLicenseMaterialStock2:
        case ProductType.OnlyLicenseMaterialStock3:
            return (
                <>
                    <img
                        src={"https://restroombird.blob.core.windows.net/shop/materialstock.png"}
                        style={{ ...imageStyles }}
                    />
                </>
            )

        default:
            return (
                <img
                    src={cmnContent}
                    style={{ ...imageStyles }}
                />
            )
    }
}

export const checkShopListingProduct = (productType: ProductType) => {
    switch (productType) {
        case ProductType.OnlyLicenseSensorExtendedOne12Months:
        case ProductType.OnlyLicenseSensorExtendedOne24Months:
        case ProductType.OnlyLicenseSensorExtendedOne36Months:
        case ProductType.OnlyLicenseSensorExtendedTwo12Months:
        case ProductType.OnlyLicenseSensorExtendedTwo24Months:
        case ProductType.OnlyLicenseSensorExtendedTwo36Months:
        case ProductType.ExtensionContentManagementTest:
        case ProductType.ExtensionContentManagement12Months:
        case ProductType.ExtensionContentManagement24Months:
        case ProductType.ExtensionContentManagement36Months:
        case ProductType.LicenseBasic12Months:
        case ProductType.LicenseBasic24Months:
        case ProductType.LicenseBasic36Months:
        case ProductType.LicenseCheckpointPackageTest:
        case ProductType.LicenseCheckpointPackage12Months:
        case ProductType.LicenseCheckpointPackage24Months:
        case ProductType.LicenseCheckpointPackage36Months:
        case ProductType.LicenseGroupUnitQualityCheckPackageTest:
        case ProductType.LicenseGroupUnitQualityCheckPackage12Months:
        case ProductType.LicenseGroupUnitQualityCheckPackage24Months:
        case ProductType.LicenseGroupUnitQualityCheckPackage36Months:
        case ProductType.LicenseObjectStandalonePackageTest:
        case ProductType.LicenseObjectStandalonePackage12Months:
        case ProductType.LicenseObjectStandalonePackage24Months:
        case ProductType.LicenseObjectStandalonePackage36Months:
        case ProductType.LicenseGroupPublicFeedbackPackageTest:
        case ProductType.LicenseGroupPublicFeedbackPackage12Months:
        case ProductType.LicenseGroupPublicFeedbackPackage24Months:
        case ProductType.LicenseGroupPublicFeedbackPackage36Months:
        case ProductType.LicenseGroupPayPerServicePackageTest:
        case ProductType.LicenseGroupPayPerServicePackage12Months:
        case ProductType.LicenseGroupPayPerServicePackage24Months:
        case ProductType.LicenseGroupPayPerServicePackage36Months:

        case ProductType.LicenseCalendarProPackageTest:
        case ProductType.LicenseCalendarProPackage12Months:
        case ProductType.LicenseCalendarProPackage24Months:
        case ProductType.LicenseCalendarProPackage36Months:

        case ProductType.OnlyLicenseMaterialStock1:
        case ProductType.OnlyLicenseMaterialStock2:
        case ProductType.OnlyLicenseMaterialStock3:

        case ProductType.OnlyLicenseTimeTrackingPro1:
        case ProductType.OnlyLicenseTimeTrackingPro2:
        case ProductType.OnlyLicenseTimeTrackingPro3:

        case ProductType.OnlyLicenseCO2Documentation1:
        case ProductType.OnlyLicenseCO2Documentation2:
        case ProductType.OnlyLicenseCO2Documentation3:

            return ShopListingChoice.License;

        case ProductType.ReplacementDigitalCheckpoint:
        case ProductType.ReplacementHardware:
        case ProductType.ReplacementRFIdKeys:
        case ProductType.ReplacementSensor:
        case ProductType.ReplacementNFCKeys:
        case ProductType.ReplacementBatteryDisplayHardware:
        case ProductType.ReplacementTaqtFeedbackHardware:
        case ProductType.ReplacementTaqtTimeHardware:
            return ShopListingChoice.Hardware;
        default:
            return ShopListingChoice.HardwareInclLicense;
    }
}