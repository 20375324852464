import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { useHistory } from 'react-router-dom';
import React, { Children, useCallback, useRef } from 'react';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { ReactComponent as MotionSensorSvg } from '../../../../assets/svgs/motion-sensor.svg';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import { Check, CheckCircle, Edit, Error, Settings, SwitchCamera } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable, SensorLicense } from '../../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { mapToiletTypeKey } from '../../../tables/Base';
import { toiletTypePredicate } from '../../../tables/components/Cells';
import { buildCellObject } from '../serviceWorker/UserServiceWorkerManagementPageDataTable';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupSelectionAssignGroupToPropertyDialog } from './GroupSelectionAssignGroupToPropertyDialog';
import { GroupSelectionAssignGroupToObjectDialog } from './GroupSelectionAssignGroupToObjectDialog';
import { GroupSelectionAssignGroupToServiceWorkerDialog } from './GroupSelectionAssignGroupToServiceWorkerDialog';
import { GroupEntrieEvalEditingDialog } from './GroupEntrieEvalEditingDialog';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { UserGroupSettingsTemplate } from '../../../../models/UserGroupSettingsTemplate';
import { Group } from '../../../../models/Group';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GroupSettingsQuickEditDialog } from '../settingsChooser/GroupSettingsQuickEditDialog';
import { buildCellAssignedColumn } from '../../../tables/GroupSelectionTable';
import { GroupSelectionAssignMultupleGroupToTemplateSettingDialog } from './GroupSelectionAssignMultupleGroupToTemplateSettingDialog';
import { useTranslation } from 'react-i18next';







export const GroupEntireEvalTable = ({ groups, selectedGroups, onSelectionChange, properties,
    selectedObjectId, selectedServiceWorkerId, objects, userServiceWorkers, token,
    userGroupSettingsTemplate, onEditSettings, onRefresh, filters, setFilters, buildActionRow, onEditSettingsInDialog }
    : {
        groups: GroupLicenseTable[], selectedGroups: GroupLicenseTable[], onSelectionChange: any, properties: UserProperty[],
        selectedObjectId?: string | undefined, selectedServiceWorkerId?: string | undefined, objects?: ObjectUnit[] | undefined, userServiceWorkers: UserServiceWorker[], token: string,
        userGroupSettingsTemplate: UserGroupSettingsTemplate[], onEditSettings: any, onRefresh: any, filters: any, setFilters: any, buildActionRow: any, onEditSettingsInDialog: any
    }) => {



    const exporterRef: any = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Gesamttabelle');
        });
    };

    const { enqueueSnackbar } = useSnackbar();
    const {t} = useTranslation();

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [groupSelectionAssignPropertyDialogVisible, setGroupSelectionAssignPropertyDialogVisible] = React.useState(false);
    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);
    const [groupSelectionAssignServiceWorkerDialogVisible, setGroupSelectionAssignServiceWorkerDialogVisible] = React.useState(false);
    const [groupEditDialogVisible, setGroupEditDialogVisible] = React.useState(false);
    const [groupEditSettingsDialogVisible, setGroupEditSettingsDialogVisible] = React.useState(false);

    const [groupAssignTemplateSettingDialogVisible, setGroupAssignTemplateSettingDialogVisible] = React.useState(false);

    const [currentSelectedGroup, setCurrentSelectedGroup] = React.useState<GroupLicenseTable>();

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: GroupLicenseTable, value: any } = props;
        if (column.name === GroupLicenseTable.Columns.sensorLicenseValidUntil && row.sensorLicenses) {
            return buildCellStringArray(props, row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)), `Enthält ${row.sensorLicenses.length} Sensor Lizenzen`, null,
                row.sensorLicenses.map(x => <MotionSensorSvg fill={x.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />));;
        }
        if (column.name === GroupLicenseTable.Columns.loraSensors) {
            return buildCellStringArray(props, row.loraSensors.map(x => x.id), `Enthält ${row.loraSensors.length} Lora-Sensor`, null,
                row.loraSensors.map(x => <MotionSensorSvg />));;
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedObject) {
            return buildCellObject(objects?.find(x => x.id == row.objectUnitId), props, () => {
                setCurrentSelectedGroup(row);
                setGroupSelectionAssignObjectDialogVisible(true);
            });
        }
        if (column.name == GroupLicenseTable.Columns.assignedObject) {
            return buildCellAssignedColumn(row.objectUnitId == selectedObjectId, props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedServiceWorker && selectedServiceWorkerId) {
            return buildCellAssignedColumn(row.userServiceWorkerIds.includes(selectedServiceWorkerId), props);
        }
        if (column.name === GroupLicenseTable.Columns.deviceSensorId && row.sensors) {
            return buildCellStringArray(props, row.sensors.map(x => x.id), `Enthält ${row.sensors.length} Sensoren`, null,
                row.sensors.map(x => <MotionSensorSvg />));
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedServiceWorkers && userServiceWorkers) {
            let userServiceWorkersForRow = userServiceWorkers.filter(x => row.userServiceWorkerIds.includes(x.id)).map(x => `${x.name} ${x.phoneNumber}`);
            return buildCellStringArray(props, userServiceWorkersForRow, `Enthält ${userServiceWorkersForRow.length} Servicekräfte`, () => {
                setCurrentSelectedGroup(row);
                setGroupSelectionAssignServiceWorkerDialogVisible(true);
            });
        }
        if (column.name == GroupLicenseTable.Columns.userPropertyName && properties) {
            let res = properties.filter(x => row.userPropertyIds.includes(x.id));
            return buildCellStringArray(props, res.map(x => x.name), `Enthält ${res.length} Merkmale`, () => {
                setCurrentSelectedGroup(row);
                setGroupSelectionAssignPropertyDialogVisible(true);
            });
        }
        if (column.name == GroupLicenseTable.Columns.assignedTemplate) {
            return (
                <Table.Cell {...props}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography style={{ textAlign: 'center' }}>{value}</Typography>
                        <IconButton color="primary" component="span" onClick={async () => {
                            setCurrentSelectedGroup(row);
                            setGroupAssignTemplateSettingDialogVisible(true);
                        }}>
                            <SwitchCamera />
                        </IconButton>
                    </div>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    let columns = [
        {
            name: GroupLicenseTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
        },
        {
            name: GroupLicenseTable.Columns.objectName,
            title: 'Objekt Name',
            getCellValue: (row: GroupLicenseTable) => row.objectName,
        },
        {
            name: GroupLicenseTable.Columns.objectCostLocation,
            title: 'Objekt Kostenstelle',
            getCellValue: (row: GroupLicenseTable) => row.objectCostLocation,
        },
        {
            name: GroupLicenseTable.Columns.objectDescription,
            title: 'Objekt Beschreibung',
            getCellValue: (row: GroupLicenseTable) => row.objectDescription,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: 'Ebene',
            getCellValue: (row: GroupLicenseTable) => row.levelName,
        },
        {
            name: GroupLicenseTable.Columns.toiletType,
            title: 'Raumart',
            getCellValue: (row: GroupLicenseTable) => GroupLicenseTable.getCellRoomType(row),
        },
        {
            name: GroupLicenseTable.Columns.uniqueDeviceId,
            title: 'Device Id',
            getCellValue: (row: GroupLicenseTable) => row.uniqueDeviceId,
        },
        {
            name: GroupLicenseTable.Columns.deviceLicenseValidUntil,
            title: 'Display Gültig bis',
            getCellValue: (row: GroupLicenseTable) => row.deviceLicenseIsValid ? (row.deviceLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.deviceLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.adLicenseValidUntil,
            title: 'Werbunglizenz gültig bis',
            getCellValue: (row: GroupLicenseTable) => row.adLicenseIsValid ? (row.adLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.adLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.loraSensorLicenseValidUntil,
            title: 'Lora Sensor Lizenzen Gültig bis',
            getCellValue: (row: GroupLicenseTable) => row.loraSensorLicenses ? row.loraSensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.loraSensors,
            title: t("myUnitsSettings.assignedLoraSensors"),
            getCellValue: (row: GroupLicenseTable) => row.loraSensorLicenses ? row.loraSensors.map(x => x.id).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.sensorLicenseValidUntil,
            title: t("myUnitsSettings.sensorLicensesValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.sensorLicenses ? row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.deviceSensorId,
            title: 'Sensor Id',
            getCellValue: (row: GroupLicenseTable) => row?.sensors?.map(x=>x.id).join(","),
        },
        {
            name: GroupLicenseTable.Columns.groupCreatedDate,
            title: t("myUnitsSettings.unitActivatedOn"),
            getCellValue: (row: GroupLicenseTable) => formatEuropeanDateTime(new Date(row.groupCreatedDate))
        }
    ];

    let columnWidth = Object.keys(GroupLicenseTable.Columns).map(el => {
        if (el == GroupLicenseTable.Columns.currentAssignedObject) {
            return { columnName: el, width: 190 }
        }
        if (el == GroupLicenseTable.Columns.userPropertyName) {
            return { columnName: el, width: 220 }
        }
        if (el == GroupLicenseTable.Columns.currentAssignedServiceWorkers) {
            return { columnName: el, width: 400 }
        }
        if (el == GroupLicenseTable.Columns.actions) {
            return { columnName: el, width: 130 }
        }
        if (el == GroupLicenseTable.Columns.assignedTemplate) {
            return { columnName: el, width: 220 }
        }
        return { columnName: el, width: 170 }
    });


    if (userServiceWorkers && userServiceWorkers.length > 0) {
        const currentServiceWorkersAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedServiceWorkers,
            title: 'Aktuelle Servicekräfte zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let userServiceWorkersForRow = userServiceWorkers?.filter(x => row.userServiceWorkerIds.includes(x.id));
                return (
                    !userServiceWorkersForRow ? "Keine Zugewiesen" : `${userServiceWorkersForRow.map(x => `${x.name} ${x.phoneNumber}`).join(',')}`
                );
            }
        }
        columns = [currentServiceWorkersAssignedColumn, ...columns];
    }



    if (objects && objects.length > 0) {
        const currentObjectAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedObject,
            title: 'Aktuelles Objekt zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let object = objects?.find(x => x.id == row.objectUnitId);
                return (
                    !object ? "Keines Zugewiesen" : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        }
        columns = [currentObjectAssignedColumn, ...columns];

    }

    const assignedTemplateColumn: any = {
        name: GroupLicenseTable.Columns.assignedTemplate,
        title: 'Zugewiesene Vorlage',
        getCellValue: (row: GroupLicenseTable) => {
            let template = userGroupSettingsTemplate.find(x => x.id == row.templateId);
            if (template) {
                return template.name;
            }
            return "keine Vorlage"
        },
    }
    columns = [assignedTemplateColumn, ...columns]

    const customerColumn = {
        name: GroupLicenseTable.Columns.roomName,
        title: 'Kunde',
        getCellValue: (row: GroupLicenseTable) => row.roomName,
    };
    columns = [customerColumn, ...columns];

    const unitNameColumn = {
        name: GroupLicenseTable.Columns.notificationName,
        title: 'Einheit Name',
        getCellValue: (row: GroupLicenseTable) => row.notificationName,
    };
    columns = [unitNameColumn, ...columns];


    const groupTypeColumn = {
        name: GroupLicenseTable.Columns.groupType,
        title: 'Einheit Typ',
        getCellValue: (row: GroupLicenseTable) => Group.GetLabelByType(row.groupType),
    };

    columns = [groupTypeColumn, ...columns]

    const exportColumns = [...columns];

    const editColumn: any = {
        name: GroupLicenseTable.Columns.actions,
        title: 'Bearbeiten',
        getCellValue: (row: GroupLicenseTable) => {
            return <>
                <Tooltip title="Bearbeiten">
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            setGroupEditDialogVisible(true);
                            setCurrentSelectedGroup(row);
                        }}
                        size="large">
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Settings Bearbeiten">
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            onEditSettingsInDialog(row);
                            setCurrentSelectedGroup(row);
                            setGroupEditSettingsDialogVisible(true);
                        }}
                        size="large">
                        <Settings />
                    </IconButton>
                </Tooltip>
            </>;
        }
    };

    columns = [editColumn, ...columns];

    const myToolbar = (props: any) => {
        return (
            <Toolbar.Root {...props}>
                {buildActionRow()}
                {props.children}
            </Toolbar.Root>
        )
    }

    return (
        <div>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={columns}
                
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters}
                />
                <IntegratedFiltering columnExtensions={[
                    { columnName: GroupLicenseTable.Columns.toiletType, predicate: toiletTypePredicate },
                ]} />

                <SortingState
                    defaultSorting={[{ columnName: GroupLicenseTable.Columns.notificationName, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedGroups.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <VirtualTable
                    height={1000}
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableColumnVisibility />
                <Toolbar
                    rootComponent={myToolbar}
                />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <TableHeaderRow />
                <TableFilterRow />
                <PagingPanel
                    pageSizes={[0, 5, 10, 15]}
                />
            </Grid>

            {currentSelectedGroup &&
                <GroupSelectionAssignGroupToPropertyDialog onRefresh={onRefresh} currentGroup={currentSelectedGroup} groups={groups} open={groupSelectionAssignPropertyDialogVisible} setOpen={setGroupSelectionAssignPropertyDialogVisible} />
            }
            {currentSelectedGroup &&
                <GroupSelectionAssignGroupToObjectDialog onRefresh={onRefresh} currentGroup={currentSelectedGroup} groups={groups} open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} />
            }
            {currentSelectedGroup &&
                <GroupSelectionAssignGroupToServiceWorkerDialog onRefresh={onRefresh} currentGroup={currentSelectedGroup} groups={groups} open={groupSelectionAssignServiceWorkerDialogVisible} setOpen={setGroupSelectionAssignServiceWorkerDialogVisible} />
            }
            {currentSelectedGroup &&
                <GroupSettingsQuickEditDialog group={currentSelectedGroup} onRefresh={onRefresh} visible={groupEditSettingsDialogVisible} setVisible={setGroupEditSettingsDialogVisible} />
            }
            {currentSelectedGroup &&
                <GroupEntrieEvalEditingDialog onRefresh={onRefresh} onSave={async (updatedRow: GroupLicenseTable) => {
                    await api.updateGroupSettingsByGroupLicenseTable(updatedRow, token, enqueueSnackbar);
                    onRefresh();
                }} visible={groupEditDialogVisible} setVisible={setGroupEditDialogVisible} group={currentSelectedGroup} groups={groups} properties={properties} userServiceWorkers={userServiceWorkers} />
            }
            {
                currentSelectedGroup &&
                <GroupSelectionAssignMultupleGroupToTemplateSettingDialog onRefresh={onRefresh}
                    userGroupTemplateSettings={userGroupSettingsTemplate} open={groupAssignTemplateSettingDialogVisible} setOpen={setGroupAssignTemplateSettingDialogVisible}
                    currentGroups={[currentSelectedGroup]} />
            }
            <GridExporter
                ref={exporterRef}
                rows={groups}
                columns={exportColumns}
                onSave={onSave}
            />

        </div>
    );
};