import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Button, Checkbox, FormControlLabel, TableCell, Typography } from '@mui/material';
import { GroupServiceModeIncidentPhoto, GroupServiceModeTable } from '../../models/GroupServiceMode';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { GroupSettings } from '../../models/GroupSettings';
import { saveExcelFile } from '../../helpers/tables/common';
import { GroupServiceModeCountTable } from '../../models/GroupServiceModeCountTable';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { Group, GroupType } from '../../models/Group';
import { mapToiletTypeKey } from './Base';
import { buildCellStringArray } from '../common/AccordionForStringArray';
import { useTranslation } from 'react-i18next';
import { IncidentImagesDialog } from '../authorized/portal/Dashboard/serviceMode/IncidentImagesDialog';
import React from 'react';
import { PhotoAlbum } from '@mui/icons-material';




const FilterCellCheck = ({ filter, onFilter, classes }: { filter: any, onFilter: any, classes: any }) => (
    <TableCell>
        <FormControlLabel
            control={
                <Checkbox
                    onChange={e => {
                        onFilter({ value: e.target.checked });
                    }}
                    color="primary"
                />
            }
            label=""
        />
    </TableCell>
);

const FilterCell = (props: any) => {
    const { column } = props;
    if (column.name === 'isOnTime' || column.name === 'isCompleted' || column.name === "isPartlyCompleted" || column.name === "isCanceled" ||
        column.name === "finishedMotionTrigger" || column.name === "finishedFeedbackTrigger" || column.name === "finishedIntervalTrigger" || column.name === "isInProgress") {
        return <FilterCellCheck {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
};

const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

export const ServiceModeIncidentTable = ({ groupServicesMode, groupLicenseTable, allincidents }: { groupServicesMode: GroupServiceModeCountTable[], groupLicenseTable?: GroupLicenseTable[], allincidents: string[] }) => {
    const exporterRef: any = useRef(null);
    const { t } = useTranslation();
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const [filters, setFilters] = useState([]);
    const [isIncidentImagesDialogVisible, setIsIncidentImagesDialogVisible] = React.useState(false);
    const [incidentPhotos, setIncidentPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);
    const [groupId, setGroupId] = React.useState("");
    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let incidentColumnsName: any = [];
    let materialColumnsWidth: any = [];
    if (groupServicesMode.length > 0) {
        incidentColumnsName = allincidents.map((col) => {
            return {
                name: col,
                title: col,
                getCellValue: (row: GroupServiceModeCountTable) => {
                    return row.incidents.find(x => x.incidentText == col)?.amount || 0;
                },
            }
        });
        materialColumnsWidth = allincidents.map((col) => {
            return {
                columnName: col,
                width: 150
            }
        });
    }


    const [columns] = useState([
        { name: GroupServiceModeCountTable.Columns.groupId, title: 'Einheit Name', getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.notificationName },
        {
            name: GroupServiceModeCountTable.Columns.groupType,
            title: 'Einheit Typ',
            getCellValue: (row: GroupServiceModeCountTable) => Group.GetLabelByType(groupLicenseTable?.find(x => x.id == row.groupId)?.groupType),
        },
        {
            name: GroupServiceModeCountTable.Columns.objectName,
            title: 'Objekt Name',
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: GroupServiceModeCountTable.Columns.levelName,
            title: 'Ebene',
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: GroupServiceModeCountTable.Columns.roomName,
            title: 'Kunde',
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.roomName,
        },
        {
            name: GroupServiceModeCountTable.Columns.toiletType,
            title: 'Raumart',
            getCellValue: (row: GroupServiceModeCountTable) => mapToiletTypeKey(groupLicenseTable?.find(x => x.id == row.groupId)?.toiletType),
        },
        {
            name: GroupServiceModeCountTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.userPropertyName,
        },
        {
            name: GroupServiceModeCountTable.Columns.incidents,
            title: 'Vorfall',
            getCellValue: (row: GroupServiceModeCountTable) => row.incidents?.map(x => x.incidentText).join(","),
        },
        ...incidentColumnsName,
    ]);




    const [defaultColumnWidths] = useState([
        { columnName: GroupServiceModeCountTable.Columns.groupId, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.groupType, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.levelName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.objectName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.roomName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.toiletType, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.userPropertyName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.incidents, width: 250 },
        ...materialColumnsWidth,

    ]);


    const PhotoCell = (props: any) => {
        const { style, row, value, column }: { style: any, row: GroupServiceModeCountTable, value: any, column: any } = props;
        let photos = row.incidents.filter(x => x.incidentText == column.name).flatMap(x => x.photos);
        return (
            <Table.Cell
                {...props}
                style={{
                    ...style
                }}>
                {photos.length > 0 ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {

                            setGroupId(row.groupId);
                            setIncidentPhotos(photos);
                            setIsIncidentImagesDialogVisible(true);
                        }}
                        endIcon={<PhotoAlbum></PhotoAlbum>}
                    >{value} ({photos.length} Fotos)</Button> :
                    <Typography>{value}</Typography>

                }
            </Table.Cell>
        )
    };


    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupServiceModeCountTable } = props;
        if (column.name === GroupServiceModeCountTable.Columns.incidents) {
            let incidents = row?.incidents;
            return buildCellStringArray(props, incidents.map(x => `${x.incidentText} : ${x.amount}`) || [], `Enthält ${incidents?.length} Vorfälle`);
        }
        if (allincidents.includes(column.name)) {
            return <PhotoCell {...props} />
        }
        return <Table.Cell {...props} />;
    };


    return (
        <>
            <IncidentImagesDialog groupId={groupId} visible={isIncidentImagesDialogVisible} setVisible={setIsIncidentImagesDialogVisible} incidentPhotos={incidentPhotos} />

            <Grid
                rows={groupServicesMode}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting />

                <IntegratedPaging />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >

                    <TemplatePlaceholder />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <PagingPanel pageSizes={[10, 20, 30, 0]}/>
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={groupServicesMode}
                columns={columns}
                filters={filters}
                onSave={onSave}
            />
        </>
    );
};