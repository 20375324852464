import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, PhotoAlbum } from '@mui/icons-material';
import { GridStackItemDisplayTable } from '../../../../../../models/GridStackCustom';
import { saveExcelFile } from '../../../../../../helpers/tables/common';
import { ImagesDialog } from './ImagesDialog';
import { useTranslation } from 'react-i18next';

export const GridStackItemChooserDisplayTable = ({ rows, selection, setSelection }
    : { rows: GridStackItemDisplayTable[], selection: any, setSelection: any }) => {

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [currentImages, setCurrentImages] = React.useState([]);
    const [ isImagesDialogVisible, setIsImagesDialogVisible] = React.useState(false);
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === GridStackItemDisplayTable.Columns.imagePreview) {
            return <ShowImagePreview  {...props} setCurrentImages={setCurrentImages} setIsImagesDialogVisible={setIsImagesDialogVisible}/>
        }
        // if (column.name === GroupCleaningTrigger.Columns.extraServices) {
        //     let names: string[] = row.extraServices.map((x: CleaningTriggerExtraServices) => `${x.text} | ${x.isDone ? "Erledigt" : "Nicht Erledigt"}`);
        //     return buildCellStringArray(props, names, `Enthält ${names.length} Extra-Services`, null, names.map(x => getDefaultIcon(x || '')));
        // }
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: GridStackItemDisplayTable.Columns.gridStackItemType,
            title: "Name",
            getCellValue: (row: GridStackItemDisplayTable) => row.title,
        },
        {
            name: GridStackItemDisplayTable.Columns.originOfChart,
            title: "Ursprung",
            getCellValue: (row: GridStackItemDisplayTable) => row.originOfChart.join(','),
        },
        {
            name: GridStackItemDisplayTable.Columns.imagePreview,
            title: t("myUnitsSettings.preview"),
            getCellValue: (row: GridStackItemDisplayTable) => row.imagePreview,
        },
        {
            name: GridStackItemDisplayTable.Columns.id,
            title: "ID für Bild",
            getCellValue: (row: GridStackItemDisplayTable) => row.gridStackItemType,
        },
    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == GridStackItemDisplayTable.Columns.gridStackItemType) {
            return { columnName: el.name, width: 350 }
        }
        return { columnName: el.name, width: 180 }
    }));
    const [defaultHiddenColumnNames] = React.useState([GridStackItemDisplayTable.Columns.id]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Custom-Dashboard');
        });
    };
    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
                getRowId={(y => y.gridStackItemType)}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection}
                    onSelectionChange={(ids: any[]) => {
                        if (ids.length > 0){
                            setSelection([ids[ids.length - 1]]);
                        }else{
                            setSelection([]);
                        }
                    }}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <VirtualTable
                    cellComponent={Cell}
                    height={'600px'}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
            <ImagesDialog visible={isImagesDialogVisible} setVisible={setIsImagesDialogVisible} photos={currentImages} />
        </div>
    );
};


export const ShowImagePreview = (props: any) => {
    const { style, row, setIsImagesDialogVisible, setCurrentImages, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setCurrentImages([row.imagePreview]);
                    setIsImagesDialogVisible(true);
                }}
                endIcon={<PhotoAlbum></PhotoAlbum>}
            >Bilder anzeigen</Button>
        </Table.Cell>
    )
};