import { GroupTriggerBase } from "./GroupTriggerBase";

export enum CleaningTriggerType {
    Motion = 0,
    Feedback = 1,
    Planned = 2,
    PositiveFeedback = 3
}

export class GroupCleaningTrigger extends GroupTriggerBase {
    public cleaningTriggerType!: CleaningTriggerType;
    public isServiceStillWithinTime!: boolean;
    public isServiceDone!: boolean;
    public extraServices !: CleaningTriggerExtraServices[];
    public constructor(groupId: string, cleaningTriggerType: CleaningTriggerType, createdTimestamp: Date) {
        super(groupId, createdTimestamp);
        this.systemETag = null;
        this.cleaningTriggerType = cleaningTriggerType;
    }
    static GetLabelByType = (type: CleaningTriggerType) => GroupCleaningTrigger.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static AvailableTypes = [

        { value: CleaningTriggerType.Motion, label: 'Besucher' },
        { value: CleaningTriggerType.Feedback, label: 'Feedback' },
        { value: CleaningTriggerType.Planned, label: 'Intervall' },
        { value: CleaningTriggerType.PositiveFeedback, label: 'Positive Feedback' },
    ];

    static Columns = {
        ...GroupTriggerBase.Columns,
        cleaningTriggerType: "cleaningTriggerType",
        isServiceStillWithinTime: "isServiceStillWithinTime",
        isServiceDone: "isServiceDone",
        extraServices: "extraServices"
    }
}

export class CleaningTriggerExtraServices {
    public text !: string;
    public isDone !: boolean;
}

