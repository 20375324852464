import Dialog from '@mui/material/Dialog';
import React from 'react';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, DialogActions } from '@mui/material';
import { handleSelectedCombinationGroup } from '../tables/Base';
import { MergeType } from '@mui/icons-material';
import { GroupSelectionAll } from '../tables/GroupSelectionAll';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAllEvaluationDialog = ({ open, setOpen, selectedCombinationGroup, setSelectedCombinationGroup, setSelectedGroupName, setSelectedGroupId, setSelectedGroupType }
  : { open: boolean, setOpen: any, selectedCombinationGroup: any, setSelectedCombinationGroup: any, setSelectedGroupName: any, setSelectedGroupId: any, setSelectedGroupType: any }) => {

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selection, setSelection] = React.useState([]);
  const {t} = useTranslation();

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      (async () => {

        const token = getApiToken(history);
        if (!token) return;

        const tempGroups = await api.getAllGroups(token);
        setGroups(tempGroups);

      })();
    }

  }, [open]);

  React.useEffect(() => {
    setSelection(selectedCombinationGroup.map((el: any) => el.id));
  }, [selectedCombinationGroup]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title="Wähle Einheiten für Einzel- oder Mehrfachauswertung"
        handleClose={handleClose}
      />
      <GroupSelectionAll
        groups={groups}
        selection={selection}
        setSelection={setSelection}
        setSelectedGroupName={setSelectedGroupName}
        setSelectedGroupId={setSelectedGroupId}
        setSelectedGroupType={setSelectedGroupType}
      />
      <DialogActions>
        <Button size="large"
          startIcon={<CancelIcon />} variant={'outlined'} onClick={handleClose} color="primary">
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button

          disabled={selection.length < 1}
          variant="contained"
          size="large"
          color="primary"
          endIcon={<MergeType />}
          onClick={() => {
            handleSelectedCombinationGroup(selection, groups, setSelectedCombinationGroup, history);
            handleClose();
          }}
        >
          Einheiten kombinieren
        </Button>
      </DialogActions>

    </Dialog>
  );
}

