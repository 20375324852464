import { mapToiletTypeKey } from "../components/tables/Base";
import { GroupType } from "./Group";
import { GroupSettings, Sensor, User } from "./GroupSettings";

export class GroupLicenseTable {
    public id!: string;
    public edited : boolean = false;

    public areaSize !: number;
    public notificationName!: string;
    public objectName!: string;
    public levelName!: string;
    public roomType!: string;
    public roomSize!: number;
    public roomName!: string;
    public objectCostLocation !: string;
    public objectDescription !: string;

    public uniqueDeviceId!: string;
    public objectUnitId!: string;
    public isQmControlEnabled !: boolean;
    public userServiceWorkerIds!: string[];
    public groupServiceWorkers !: User[]
    public userPropertyIds!: string[];
    public userPropertyName!: string;
    public toiletType!: string;
    public userId!: string;
    public templateId !: string;

    public deviceLicenseValidUntil!: any;
    public deviceLicenseIsValid: boolean = false;
    public deviceLicenseIsExpired: boolean = false;
    public deviceLicenseDuration!: string;

    public adLicenseValidUntil!: any;
    public adLicenseIsValid: boolean = false;
    public adLicenseIsExpired: boolean = false;
    public adLicenseDuration!: string;
    public groupCreatedDate!: string;


    public qualityCheckLicenseValidUntil!: any;
    public qualityCheckLicenseIsValid: boolean = false;
    public qualityCheckLicenseIsExpired: boolean = false;
    public qualityCheckLicenseDuration!: string;

    public groupType !: GroupType;

    public sensorLicenses !: SensorLicense[];
    public loraSensorLicenses !: SensorLicense[];
    public sensors!: Sensor[];
    public loraSensors !: Sensor[];

    public lastOnline!: number;
    public nativeApplicationVersion : string = "";
    public downloadedFilename : string = "";
    public buildVersion : string = "";

    public deviceId : string = "";

    static getCellRoomType = (row: GroupLicenseTable) => {
        return row.roomType || mapToiletTypeKey(row.toiletType);
    }

    static Columns = {
        groupType: 'groupType',
        notificationName: 'notificationName',
        objectName: 'objectName',
        objectCostLocation: 'objectCostLocation',
        objectDescription: 'objectDescription',
        levelName: 'levelName',
        roomName: 'roomName',
        toiletType: 'toiletType',
        roomSize : 'roomSize',
        uniqueDeviceId: 'uniqueDeviceId',
        userPropertyName: 'userPropertyName',
        purchase: 'purchase',
        deviceLicenseDuration: 'deviceLicenseDuration',
        deviceLicenseDaysLeft: 'deviceLicenseIsValid',
        deviceLicenseValidUntil: 'deviceLicenseValidUntil',
        deviceSensorId: 'deviceSensorId',
        isUsed: 'isUsed',
        adLicenseExtend: 'adLicenseExtend',
        adLicenseValidUntil: 'adLicenseValidUntil',
        qualityCheckLicenseValidUntil: 'qualityCheckLicenseValidUntil',
        sensorLicenseValidUntil: 'sensorLicenseValidUntil',
        loraSensorLicenseValidUntil: 'loraSensorLicenseValidUntil',
        loraSensors: 'loraSensors',
        groupCreatedDate: 'groupCreatedDate',
        assignedObject: 'assignedObject',
        assignedQM: 'assignedQM',
        currentAssignedObject: 'currentAssignedObject',
        assignedServiceWorker: 'assignedServiceWorker',
        currentAssignedServiceWorkers: 'currentAssignedServiceWorkers',
        assignedProperty: 'assignedProperty',
        currentAssignedProperties: 'currentAssignedProperties',
        actions: 'actions',
        actions2: 'actions2',
        assignedTemplate: 'assignedTemplate',
        incidentPhotos:'incidentPhotos',
        deviceIdTaqt: 'deviceIdTaqt',
        

    };

    static CreateInstanceFromSettings = (settings: GroupSettings) => {
        let groupLicenseTable = new GroupLicenseTable();
        groupLicenseTable.notificationName = settings.locationInformation?.notificationName || "";
        groupLicenseTable.levelName = settings.locationInformation?.levelName || "";
        groupLicenseTable.roomName = settings.locationInformation?.roomName || "";
        groupLicenseTable.objectName = settings.locationInformation?.objectName || "";
        groupLicenseTable.id = settings.id || "";
        groupLicenseTable.userId = settings.userId || "";

        return groupLicenseTable;

    }

    static isDeivce = (group: GroupLicenseTable) =>{
        return group.groupType == GroupType.DisplayWallApp || group.groupType == GroupType.DisplayBatteryWallApp || group.groupType == GroupType.TaqtFeedback || group.groupType == GroupType.TaqtTime
    }


}

export class UserManagementGroups {
    public userId !: string;
    public groups !: GroupLicenseTable[];
    constructor (userId : string, groups: GroupLicenseTable[]) {
        this.userId = userId;
        this.groups = groups;
    }
}

export class ObjectManagementGroups {
    public objectId !: string;
    public groups !: GroupLicenseTable[];
    constructor (objectId : string, groups: GroupLicenseTable[]) {
        this.objectId = objectId;
        this.groups = groups;
    }
}

export class SensorLicense {
    public id !: string;
    public sensorLicenseValidUntil !: number;
    public sensorLicenseValidUntilDate !: string;
    public isSensorLicenseExpired!: boolean;
    public isNew = false;
    constructor () {
        this.id = "";
        this.isNew = true;
        this.sensorLicenseValidUntil = 0;
    }
}

export class GroupLicenseTableServiceAverage extends GroupLicenseTable {
    services: any;
    materials: any

    static ColumnsExtended = {
        groupType: 'groupType',
        service: 'service',
        material: 'material',
        serviceVal: 'serviceVal'
    };
}