import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DataFilterType, PeriodChartFilterType, generateChartData, generateChartDataWMissingDay, generateDisplayDate } from '../../helpers/charts/base';
import { InlineDateTimePicker } from './InlineDateTimePicker';
import { Grid, TextField } from '@mui/material';
import { defaultEndDate, defaultPastDays, defaultPeriodChartFilterType, defaultStartDate, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from '../../helpers/date-management';
import DataFilterTypeChoiceDialog from '../dialogs/DataFilterTypeChoiceDialog';
import { getCountNumber, getHowManyVisitorNeedOneMaterial, getHundredVisitorNeedHowManyMaterials, getProportion, getRatioNumber } from '../../helpers/common';
import { GlobalContext } from '../authorized/PortalPage';
import { CustomDashboardContext } from '../authorized/portal/Dashboard/CustomDashboard';
import { GridStackCustom, GridStackCustomItem } from '../../models/GridStackCustom';
import { useQuery } from '../authorized/portal/checkout/ShopPage';
import { useHistory } from 'react-router-dom';
import useNoInitialEffect from '../../helpers/extensions';

export const TimeChoice = forwardRef(({ data, setChartData, fields, chartCalcType = ChartCalcType.Sum, dateDisplayType = defaultPeriodChartFilterType }: any, ref: any) => {
  useImperativeHandle(
    ref,
    () => ({
      updateEndDateFromParent(date: any) {
/*        if (item){
          handleChange({ target: { value: item.periodChartFilterType } });
        } */ //causes bug custom dashboard: save timechoice
        setSelectedEndDate(date);
      },
      updateStartDateFromParent(date: any) {
        setSelectedStartDate(date);
      },
      updateDataFilterTypeFromParent(dataFilterType: any) {
        setDataFilterType(dataFilterType);
      },
      updateChosenPastDays(days: any) {
        setChosenPastDays(parseInt(days));
      },
      renderData() {
        setTimeBasedChartData(dataFilterType, isInitialMount, value, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData, chartCalcType);
      },
      renderDataWithDisplayDateType(value: PeriodChartFilterType) {
        setTimeBasedChartData(dataFilterType, isInitialMount, value, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData, chartCalcType);
        setValue(value);
      }
    }),
  )

  const { periodChartFilter, setPeriodChartFilter , isPreFilterDialogVisible}: { periodChartFilter: PeriodChartFilterType, setPeriodChartFilter: any, isPreFilterDialogVisible: boolean } = React.useContext(GlobalContext);
  const [value, setValue] = React.useState(dateDisplayType);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);

  const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
  const isInitialMount = useRef(true);
  const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);

  const { item, updateGridStack, selectedGridStackCustom }: { item: GridStackCustomItem, updateGridStack: (currentGridStackCustom: GridStackCustomItem) => void, selectedGridStackCustom: GridStackCustom } = React.useContext(CustomDashboardContext);

  const history = useHistory();
  const queryString = useQuery(history);
  useNoInitialEffect(()=>{
    let endDate = getEndDateFromUrlOrStorage(queryString);
    let startDate = getStartDateFromUrlOrStorage(queryString);
    setSelectedEndDate(endDate);
    setSelectedStartDate(startDate);
  }, [isPreFilterDialogVisible])

  React.useEffect(() => {
    setTimeBasedChartData(dataFilterType, isInitialMount, value, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData, chartCalcType);
  }, [value, selectedEndDate, selectedStartDate, chosenPastDays, dataFilterType]);

  React.useEffect(() => {
    handleChange({ target: { value: periodChartFilter } });
  }, [periodChartFilter])

  const handleChange = (event: any) => {

    setValue(event.target.value);
    if (event.target.value === PeriodChartFilterType.Hour) {
      setChosenPastDays(1);
    }
    else if (event.target.value === PeriodChartFilterType.Day) {
      setChosenPastDays(10)
    }
    else if (event.target.value === PeriodChartFilterType.Week) {
      setChosenPastDays(30);
    }
    else if (event.target.value === PeriodChartFilterType.Month) {
      setChosenPastDays(90);
    }

  };

  const buildFields = () => {
    switch (dataFilterType) {
      case DataFilterType.StartEnd:
        return (
          <Grid container item direction={'row'} spacing={2}>
            <Grid item>
              <InlineDateTimePicker title={"Start Datum"} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
          </Grid>
        );
      case DataFilterType.EndDatePastDays:
        return (
          <Grid container item xs={12} direction={'row'}>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 100, marginLeft: 30 }}
                inputProps={{ min: 1, style: {} }}
                id="filled-number"
                label="Tage zurück"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={chosenPastDays}
                onChange={(event) => { setChosenPastDays(parseInt(event.target.value)) }}
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <Grid container spacing={2} style={{ padding: 10, paddingBottom: 10 }}>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Zeitauswahl</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={(event) => {
            if (item) {
              item.periodChartFilterType = event.target.value as any;
              updateGridStack(item)
            }
            handleChange(event);
          }} row>
            <FormControlLabel value={PeriodChartFilterType.Hour} control={<Radio />} label="Stündlich" />
            <FormControlLabel value={PeriodChartFilterType.Day} control={<Radio />} label="Täglich" />
            <FormControlLabel value={PeriodChartFilterType.Week} control={<Radio />} label="Wöchentlich" />
            <FormControlLabel value={PeriodChartFilterType.Month} control={<Radio />} label="Monatlich" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType} />
      {buildFields()}
    </Grid>
  );
});

export enum ChartCalcType {
  "Sum" = 0,
  "IncreaseSum" = 1,
  "RatioMotionFeedback" = 2,
  "RatioMotionMaterialConsumption" = 3,
  "BySensorId" = 4,
  "CumulativeBySensorId" = 5,
  "CountMotionMaterialConsumption" = 6,
  "AverageQualityCheckServices" = 7,
  "Nothing" = 255,
}

export const setTimeBasedChartData = (dataFilterType: DataFilterType, isInitialMount: any, value: any, selectedEndDate: any, selectedStartDate: any, data: any, fields: any, chosenPastDays: any, setChartData: any, chartCalcType: ChartCalcType = ChartCalcType.Sum) => {
  if (isInitialMount.current) {
    isInitialMount.current = false;
  } else {
    let generatedData = null;
    if (value === PeriodChartFilterType.Hour) {
      generatedData = generateChartData(data, PeriodChartFilterType.Hour, dataFilterType, selectedEndDate, selectedStartDate, fields, chosenPastDays);
    }
    else if (value === PeriodChartFilterType.Day) {
      generatedData = generateChartDataWMissingDay(data, PeriodChartFilterType.Day, dataFilterType, selectedEndDate, selectedStartDate, fields, chosenPastDays);
    }
    else if (value === PeriodChartFilterType.Week) {
      generatedData = generateChartData(data, PeriodChartFilterType.Week, dataFilterType, selectedEndDate, selectedStartDate, fields, chosenPastDays);
    }
    else if (value === PeriodChartFilterType.Month) {
      generatedData = generateChartData(data, PeriodChartFilterType.Month, dataFilterType, selectedEndDate, selectedStartDate, fields, chosenPastDays);
    }

    switch (chartCalcType) {
      case ChartCalcType.IncreaseSum:
        increaseSum(generatedData);
        break;
      case ChartCalcType.RatioMotionFeedback:
        generatedData = calcRatio(generatedData);
        break;
      case ChartCalcType.RatioMotionMaterialConsumption:
        generatedData = calcRatioMotionMaterialConsumption(generatedData, fields);
        break;
      case ChartCalcType.CountMotionMaterialConsumption:
        generatedData = calcCountMotionMaterialConsumption(generatedData, fields);
        break;
      case ChartCalcType.AverageQualityCheckServices:
        generatedData = calcAverageQualityCheckServices(generatedData, data, value, fields);
        break;
      case ChartCalcType.Nothing:
        break;
    }

    setChartData(generatedData || []);

  }
}

export const calcAverageQualityCheckServices = (generatedData: any, data: any, value: any, fields: any) => {
  if (generatedData) {
    let dataWithDateDisplay = generateDisplayDate(data, value);
    generatedData.forEach((x: any) => {
      let latestDateData = dataWithDateDisplay?.filter(currentData => x.dateDisplay == currentData.dateDisplay);
      if (latestDateData) {
        for (let field of fields) {
          x[field] = Math.round(x[field] / latestDateData.filter(latestDateItem => !!latestDateItem[field]).length);
        }
      }

    })
  }
  return generatedData;
}

export const calcRatioMotionMaterialConsumption = (generateChartData: any, fields: any[]) => {
  if (!generateChartData) return [];
  let filtered = generateChartData.filter((el: any) => !!el.blockCount);
  return filtered.map((el: any) => {
    fields.forEach((field) => {
      el[field] = getHowManyVisitorNeedOneMaterial(el.blockCount, el[field]);
    })

    return el;
  })
}

export const calcCountMotionMaterialConsumption = (generateChartData: any, fields: any[]) => {
  if (!generateChartData) return [];
  let filtered = generateChartData.filter((el: any) => !!el.blockCount);
  return filtered.map((el: any) => {
    fields.forEach((field) => {
      el[field] = getHundredVisitorNeedHowManyMaterials(el.blockCount, el[field]);
    })

    return el;
  })
}

export const getRatioMotionFeedbackType = () => {
  return ["ratioVeryPositive", "ratioPositive", "ratioNeutral", "ratioNegative", "ratioVeryNegative"];
}

export const getRatioMotionFeedbackThreeSmilesType = () => {
  return ["ratioPositive", "ratioNeutral", "ratioNegative"];
}

export const calcRatio = (generateChartData: any) => {
  let filtered = generateChartData?.filter((el: any) => !!el.blockCount) || [];
  return filtered.map((el: any) => {
    let ratioNegative = getRatioNumber(el.blockCount, el.negativeAmount);
    let ratioPositive = getRatioNumber(el.blockCount, el.positiveAmount);
    let ratioNeutral = getRatioNumber(el.blockCount, el.neutralAmount);
    let ratioVeryPositive = getRatioNumber(el.blockCount, el.veryPositiveAmount);
    let ratioVeryNegative = getRatioNumber(el.blockCount, el.veryNegativeAmount);


    return { date: el.date, dateDisplay: el.dateDisplay, ratioNegative: ratioNegative, ratioNeutral: ratioNeutral, ratioPositive: ratioPositive, ratioVeryNegative: ratioVeryNegative, ratioVeryPositive: ratioVeryPositive }
  })
}

export const increaseSum = (chartData: any) => {
  chartData.reduce((accumulator: any, currentValue: any) => {
    let ret = accumulator + currentValue.blockCount;
    currentValue.blockCount = currentValue.blockCount + accumulator;
    return ret;
  }, 0);
}