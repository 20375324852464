import {  formatISO } from "date-fns";
import { CleaningTriggerType, GroupCleaningTrigger } from "../../models/GroupCleaningTrigger";

export const generateCleaningTriggerChartData = (groupCleaningTriggers : GroupCleaningTrigger[]) => {
    let arr = groupCleaningTriggers.map(el => {
        return {date : el.createdTimestamp, [CleaningTriggerType[el.cleaningTriggerType]]: 1}
    });
    return arr;
}
export const generateCleaningTriggerSumChartData = (groupCleaningTriggers : GroupCleaningTrigger[]) => {
    let arr = groupCleaningTriggers.map(el => {
        return {value : 1, key: CleaningTriggerType[el.cleaningTriggerType],date : el.createdTimestamp}
    });
    return arr;
}
export const getAllCleaningTriggerTypes = () => {
  //  let res = Object.keys(CleaningTriggerType).filter(k => (Number(k).toString() === Number.NaN.toString()));
    return ["Feedback", "Planned", "Motion"];
}
export function getStringValuesFromEnum<T>(myEnum: T): (keyof T)[] {
    return Object.keys(myEnum).filter(k => typeof (myEnum as any)[k] === 'number') as any;
  }