import { DeleteForever } from "@mui/icons-material"
import { Backdrop, Button, CircularProgress, Divider, Grid, Paper, TextField, Typography } from "@mui/material"
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers"
import { format, getUnixTime, isAfter, isBefore, isDate, isWithinInterval, parse, subDays } from "date-fns"
import { useSnackbar } from "notistack"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { api } from "../../../../api"
import { APIDataAmountType, getServiceData } from "../../../../helpers/charts/base"
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common"
import { getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage, getUnixMillisec } from "../../../../helpers/date-management"
import useNoInitialEffect from "../../../../helpers/extensions"
import { getApiToken } from "../../../../helpers/storage-management"
import { getChosenGroups, getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common"
import { formatServiceMode, getAllIncidentsColumns, getAllMaterialColumns, getAllServiceColumns } from "../../../../helpers/tables/serviceModeGenerator"
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable"
import { GroupServiceMode, GroupServiceModeMaterialConsumption, GroupServiceModeTable } from "../../../../models/GroupServiceMode"
import { GroupServiceModeCountTable, GroupServiceModeIncidentWithPhoto, GroupServiceModeServices } from "../../../../models/GroupServiceModeCountTable"
import { Sensor } from "../../../../models/GroupSettings"
import { UserServiceWorker } from "../../../../models/UserServiceWorker"
import { UserType } from "../../../../models/UserType"
import { elevationPaper, paperStyle } from "../../../../styles/container"
import { useBackdropStyles } from "../../../../styles/overlay"
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent"
import { GlobalTimeChoice } from "../../../core/GlobalTimeChoice"
import { HeaderCombination } from "../../../core/HeaderCombination"
import { TimeChoice } from "../../../core/TimeChoice"
import { ServiceModeCountTable } from "../../../tables/ServiceModeCountTable"
import { ServiceModeTable } from "../../../tables/ServiceModeTable"
import { ImportDataButtonCount } from "../../extendedapp/ObjectManagementPageDataTable"
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer"
import { GlobalContext } from "../../PortalPage"
import { useQuery } from "../checkout/ShopPage"
import { EvalApiType } from "./Services"
import { ServiceModeServiceWorkerDoneCountTable } from "../../../tables/ServiceModeServiceWorkerDoneCountTable"
import { ServiceModeIncidentTable } from "../../../tables/ServiceModeIncidentTable"



export const ServiceModeDashboardTable = ({ serviceDisplayType, groupIds, drawerDisplayType }: { serviceDisplayType: EvalApiType, groupIds?: any, drawerDisplayType: DrawerMenuItemDisplayType }) => {

    const backdropClasses = useBackdropStyles();

    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);
    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();


    const [selectedServiceModes, setSelectedServiceModes] = React.useState<GroupServiceModeTable[]>([]);


    const [groupServiceDataFromApi, setGroupServiceDataFromApi] = React.useState<GroupServiceMode[]>([]);
    const [groupsServiceMode, setGroupsServiceMode] = React.useState<GroupServiceModeTable[]>([]);
    const [groupsServiceModeTableFiltered, setGroupsServiceModeTableFiltered] = React.useState<GroupServiceModeTable[]>([]);

    const [groupsServiceModeCountTable, setGroupsServiceModeCountTable] = React.useState<GroupServiceModeCountTable[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [groupsSettings, setGroupsSettings] = React.useState();
    const [groupLicenseTable, setGroupLicenseTable] = React.useState<GroupLicenseTable[]>();
    const history = useHistory();

    const { groupId } = useParams<{ groupId: string }>();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const { userAccount }: any = React.useContext(GlobalContext);
    const queryString = useQuery(history);
    const fixedId = queryString.get("serviceId");
    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(50);
    const [allMaterials, setAllMaterials] = React.useState([]);
    const [allIncidents, setAllIncidents] = React.useState<string[]>([]);
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [isLoadingDeleting, setIsLoadingDeleting] = React.useState(false);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();


    const [allServices, setAllServices] = React.useState([]);

    const [startTime, setStartTime] = React.useState(() => {
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0)
        return date;
    });
    const [endTime, setEndTime] = React.useState(() => {
        let date = new Date();
        date.setHours(23);
        date.setMinutes(59)
        return date;
    });


    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        let token = getApiToken(history);
        if (!token) return;
        (async () => {
            setLoading(true);
            setApiDataLoading(true);
            setLoadingProgress(0);
            setValueBuffer(50);
            if (serviceDisplayType == EvalApiType.Objects) {
                let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupLicenseTable(groups);
            }
            else if (serviceDisplayType == EvalApiType.Groups) {
                let groupsPortal = await getPortalGroups(groupIds, token, groupId);
                setGroupLicenseTable(groupsPortal);
            }
            else if (serviceDisplayType == EvalApiType.ServiceWorkers) {
                let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupLicenseTable(groups);
            }
            setLoadingProgress(50);
            setValueBuffer(80);
            let temp: GroupServiceMode[] = await getServiceData(groupId, token, groupIds);
            if (fixedId) {
                temp = temp.filter(x => x.id == fixedId);
            }
            let endDate = getEndDateFromUrlOrStorage(queryString);
            let startDate = getStartDateFromUrlOrStorage(queryString);
            /*    if (endDate && startDate){
                    temp = temp.filter(x => {
                        let curDate = new Date(getUnixMillisec(x.startTimestamp));
                        return isAfter(curDate, startDate) && isBefore(curDate, endDate);
                    });
                } */
            let tempCountTable = reloadServiceCount(temp, startDate, endDate);
            let allMaterialCol = getAllMaterialColumns(temp);
            setAllMaterials(allMaterialCol);
            setAllIncidents(getAllIncidentsColumns(temp))
            setLoadingProgress(80);
            setValueBuffer(100);

            const tempServiceWorkers = await api.getServiceWorkers(token);
            setUserServiceWorkers(tempServiceWorkers);

            setLoadingProgress(100);
            setValueBuffer(100);

            setGroupServiceDataFromApi(temp);

            setGroupsServiceMode(formatServiceMode(temp));
            setGroupsServiceModeTableFiltered(formatServiceMode(temp));

            let allServices = getAllServiceColumns(temp);
            setAllServices(allServices);


            setLoading(false);

            setGroupsServiceModeCountTable(tempCountTable);
            setApiDataLoading(false);

        })();
    }, [apiDataAmountType, reloadDataFromApi]);

    const reloadServiceCount = (groupServicesMode: GroupServiceModeTable[], beginDate: Date | null = null, endDate: Date | null = null) => {
        if (beginDate && endDate) {
            groupServicesMode = groupServicesMode.filter(x => {
                let curDate = new Date(getUnixMillisec(x.startTimestamp));
                return isAfter(curDate, beginDate) && isBefore(curDate, endDate);
            });
        }
        let ret = groupServicesMode.reduce((prevVal: GroupServiceModeCountTable[], curVal: GroupServiceModeTable) => {
            let valExist = prevVal.find(x => x.groupId == curVal.groupId);
            if (valExist) {
                Object.keys(curVal.materialConsumptionItems).forEach(material => {
                    let currentMaterial = valExist?.materials.find(x => x.text == curVal.materialConsumptionItems[material].text);
                    if (currentMaterial) {
                        currentMaterial.amount = (currentMaterial.amount) + curVal.materialConsumptionItems[material].amount;
                    } else {
                        valExist?.materials.push(new GroupServiceModeMaterialConsumption(curVal.materialConsumptionItems[material].text, parseInt(curVal.materialConsumptionItems[material].amount)))
                    }

                });


                curVal.cleaningTaskItems.forEach(service => {
                    let currentService = valExist?.services.find(x => x.text == service);
                    if (currentService) {
                        currentService.amount = (currentService.amount) + 1;
                    } else {
                        valExist?.services.push(new GroupServiceModeServices(service, 1))
                    }

                });

                curVal.incidentTexts.forEach(incident => {
                    let currentIncident = valExist?.incidents.find(x => x.incidentText == incident.incidentText);
                    if (currentIncident) {
                        currentIncident.amount = (currentIncident.amount) + 1;
                        
                    } else {
                        valExist?.incidents.push(new GroupServiceModeIncidentWithPhoto(incident.reporterName, incident.incidentText))
                    }

                });


                if (curVal.isOnTime) {
                    valExist.serviceSignInCount++;
                }
                if (curVal.isPartlyCompleted) {
                    valExist.servicePartlyDoneCount++;
                }
                if (curVal.isCompleted) {
                    valExist.serviceCompletelyDoneCount++;
                }
                if (!curVal.isOnTime) {
                    valExist.serviceMissedCount++;
                }
            } else {
                let completelyDone = 0;
                let signInCount = 0;
                let partlyDoneCount = 0;
                let missedCount = 0;
                if (curVal.isOnTime) {
                    signInCount++;
                }
                if (curVal.isPartlyCompleted) {
                    partlyDoneCount++;
                }
                if (curVal.isCompleted) {
                    completelyDone++;
                }
                if (!curVal.isOnTime) {
                    missedCount++;
                }
                let services: GroupServiceModeServices[] = [];

                curVal.cleaningTaskItems.forEach(service => {
                    let currentService = services.find(x => x.text == service);
                    if (currentService) {
                        currentService.amount = 1;
                    } else {
                        services.push(new GroupServiceModeServices(service, 1))
                    }

                });

                let incidents: GroupServiceModeIncidentWithPhoto[] = [];

                curVal.incidentTexts.forEach(incident => {
                    let currentIncident = incidents.find(x => x.incidentText == incident.incidentText);
                    if (currentIncident) {
                        currentIncident.amount = 1;
                    } else {
                        incidents.push(new GroupServiceModeIncidentWithPhoto(incident.reporterName, incident.incidentText))
                    }

                });



                prevVal.push(new GroupServiceModeCountTable(curVal.groupId, signInCount, missedCount, completelyDone, partlyDoneCount, Object.keys(curVal.materialConsumptionItems).map(material => new GroupServiceModeMaterialConsumption(curVal.materialConsumptionItems[material].text, parseInt(curVal.materialConsumptionItems[material].amount))), services,
            incidents));

            }
            return prevVal;
        }, []);

        ret.forEach(x=>{
            x.incidents.forEach(incident=>{
                groupServicesMode.filter(serviceMode=>serviceMode.groupId == x.groupId).forEach(serviceMode=>{
                    if (serviceMode?.incidentPhotos?.length > 0){
                        let photosToAdd = serviceMode.incidentPhotos.filter(photo=>photo.description == incident.incidentText);
                        incident.photos.push(...photosToAdd);
                    }

                })
            })
        })

        return ret;
    }

    if (userAccount && userAccount.userType == UserType.Customer) {
        return (
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={elevationPaper} style={paperStyle}>
                        <Typography variant="h4">Kunde kann nicht Tabelle anschauen</Typography>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    const schema = {
        'Einheit Name': {
            prop: 'Einheit Name',
            type: String
        },
        'Ebene': {
            prop: 'Ebene',
            type: String
        },
        'Servicekraftname': {
            prop: 'Servicekraftname',
            type: String
        },
        'Startdatum': {
            prop: 'Startdatum',
            type: String
        },
        'Endedatum': {
            prop: 'Endedatum',
            type: String
        },
        'Pünktlich': {
            prop: 'Pünktlich',
            type: Boolean
        },
        'Alle Services erledigt': {
            prop: 'Alle Services erledigt',
            type: Boolean
        },
        'Services teilweise erledigt': {
            prop: 'Services teilweise erledigt',
            type: String
        },
        'Abgebrochen': {
            prop: 'Abgebrochen',
            type: Boolean
        },
        'In Bearbeitung': {
            prop: 'In Bearbeitung',
            type: Boolean
        },
        'Vorfallmeldungen': {
            prop: 'Vorfallmeldungen',
            type: String
        },
        'Services': {
            prop: 'Services',
            type: String
        },
        'Materialverbrauch': {
            prop: 'Materialverbrauch',
            type: String
        }
    }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>

            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <CircularProgress size={64} />
                </Grid>
            }
            <Grid item xs={12}>
                <Paper elevation={elevationPaper} style={paperStyle}>

                    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <GlobalTimeChoice
                                    isPrintPage={undefined}
                                    setIsPrintPage={() => { }}
                                    pageRef={null}
                                    chartRefs={[]} updateChosenPastDaysFromParent={() => { }} updateDataFilterTypeFromParent={() => { }} updateEndDateFromParent={() => { }} updateStartDateFromParent={() => { }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">Services Übersicht</Typography>
                        </Grid>

                        <Grid item container direction={"row"} alignItems="center" spacing={2}>

                            <Grid lg={2} item container direction="column">
                                <Grid item>
                                    <Button fullWidth href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Services-Import.xlsx">Vorlage Services Import Download</Button>
                                </Grid>
                                <Grid item>
                                    <ImportDataButtonCount title='Services Importieren' schema={schema} onFinish={async (row: any) => {
                                        const tempToken = getApiToken(history);
                                        let currentGroup = groupLicenseTable?.find(x => x.notificationName == row["Einheit Name"] && x.levelName == row["Ebene"]);
                                        if (!currentGroup) currentGroup = groupLicenseTable?.find(x => x.notificationName == row["Einheit Name"]);
                                        if (currentGroup) {


                                            let objectUnit = new GroupServiceMode(currentGroup?.id, row['Pünktlich'], row['Alle Services erledigt'], JSON.parse(row["Materialverbrauch"]), [], JSON.parse(row["Services"]), getUnixTime(parse(row["Startdatum"], "dd.MM.yyyy HH:mm", new Date())), getUnixTime(parse(row["Endedatum"], "dd.MM.yyyy HH:mm", new Date())), getUnixTime((new Date())), row["Servicekraftname"], new Sensor(), false, false, false, row['Services Teilweise erledigt'], row['Abgebrochen']);
                                            objectUnit.isInProgress = row['In Bearbeitung'];
                                            objectUnit.incidentTexts = JSON.parse(row["Vorfallmeldungen"]);
                                            let serviceWorker = userServiceWorkers.find(x => x.name == row["Servicekraftname"]);
                                            objectUnit.userServiceWorkerId = serviceWorker?.id || "";

                                            const added = await api.CreateGroupServiceModeManual(objectUnit, tempToken, enqueueSnackbar);
                                        }
                                    }} />
                                </Grid >
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item lg={2}>
                                <Button
                                    size="large"
                                    fullWidth
                                    color="error"

                                    startIcon={isLoadingDeleting && <CircularProgress size={32} />}
                                    disabled={selectedServiceModes.length < 1 || isLoadingDeleting} variant='contained'
                                    endIcon={<DeleteForever />} onClick={async () => {
                                        setIsLoadingDeleting(true);
                                        let token = getApiToken(history);
                                        for (let serviceMode of selectedServiceModes) {
                                            await api.DeleteServiceModeById(serviceMode.groupId, serviceMode.id, token, enqueueSnackbar);
                                            setSelectedServiceModes((prev) => prev.filter(x => x.id != serviceMode.id));
                                            setGroupsServiceMode((prev) => prev.filter(x => x.id != serviceMode.id));
                                        }
                                        setIsLoadingDeleting(false);
                                    }}>
                                    {selectedServiceModes.length} Services löschen
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                        <Grid xs={12} item container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <Typography color={"primary"} style={{ fontWeight: 'bold', fontSize: 22 }}>Uhrzeitfilterung für Startzeit des Service: </Typography>
                            </Grid>
                            <Grid item>
                                < TimePicker
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                    label={"Start Zeit"}
                                    value={startTime}
                                    onChange={(newVal) => {
                                        if (!isDate(newVal) || !newVal) return;
                                        let ret = groupsServiceMode.filter(item => {
                                            let serviceDate = parse(format(new Date(item.startTimestamp), "HH:mm"), "HH:mm", startTime);
                                            return isWithinInterval(serviceDate, { start: newVal, end: endTime });
                                        });
                                        setGroupsServiceModeTableFiltered(ret);
                                        setStartTime(newVal);
                                    }}
                                    format={"HH:mm"}
                                />
                            </Grid>
                            <Grid item>
                                < TimePicker
                                    slotProps={{ textField: { variant: 'outlined' } }}
                                    label={"Ende Zeit"}
                                    value={endTime}
                                    onChange={(newVal) => {
                                        if (!isDate(newVal) || !newVal) return;
                                        let ret = groupsServiceMode.filter(item => {
                                            let serviceDate = parse(format(new Date(item.startTimestamp), "HH:mm"), "HH:mm", endTime);
                                            return isWithinInterval(serviceDate, { start: startTime, end: newVal });
                                        });
                                        setGroupsServiceModeTableFiltered(ret);
                                        setEndTime(newVal);

                                    }}
                                    format={"HH:mm"}

                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                        {loadingProgress > 80 &&
                            <Grid item xs={12}>
                                <ServiceModeTable materialNames={allMaterials} selectedRows={selectedServiceModes} setSelectedRows={setSelectedServiceModes} userServiceWorkers={userServiceWorkers} groupServicesMode={groupsServiceModeTableFiltered} groupsSettings={groupLicenseTable}></ServiceModeTable>
                            </Grid>
                        }

                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Service Analyse</Typography>
                            </Grid>
                            <Grid xs={12} item container spacing={3}>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Start Datum"}
                                        value={startDate}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            let temp = reloadServiceCount(groupServiceDataFromApi, newVal, endDate);
                                            setGroupsServiceModeCountTable(temp);
                                            setStartDate(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Ende Datum"}
                                        value={endDate}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            let temp = reloadServiceCount(groupServiceDataFromApi, startDate, newVal);
                                            setGroupsServiceModeCountTable(temp);
                                            setEndDate(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                            </Grid>
                            {loadingProgress > 99 &&
                                <Grid item xs={12}>
                                    {groupsServiceModeCountTable.length > 0 ?
                                        <ServiceModeServiceWorkerDoneCountTable allServices={allServices} groupServicesMode={groupsServiceModeCountTable} groupLicenseTable={groupLicenseTable}></ServiceModeServiceWorkerDoneCountTable>
                                        :
                                        <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Keine Daten Vorhanden!</Typography>
                                    }
                                </Grid>
                            }
                        </Grid>

                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Material Analyse</Typography>
                            </Grid>
                            <Grid xs={12} item container spacing={3}>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Start Datum"}
                                        value={startDate}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            let temp = reloadServiceCount(groupServiceDataFromApi, newVal, endDate);
                                            setGroupsServiceModeCountTable(temp);
                                            setStartDate(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Ende Datum"}
                                        value={endDate}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            let temp = reloadServiceCount(groupServiceDataFromApi, startDate, newVal);
                                            setGroupsServiceModeCountTable(temp);
                                            setEndDate(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                            </Grid>
                            {loadingProgress > 99 &&
                                <Grid item xs={12}>
                                    {groupsServiceModeCountTable.length > 0 ?
                                        <ServiceModeCountTable allMaterials={allMaterials} groupServicesMode={groupsServiceModeCountTable} groupLicenseTable={groupLicenseTable}></ServiceModeCountTable>
                                        :
                                        <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Keine Daten Vorhanden!</Typography>
                                    }
                                </Grid>
                            }
                        </Grid>

                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Vorfall Analyse</Typography>
                            </Grid>
                            <Grid xs={12} item container spacing={3}>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Start Datum"}
                                        value={startDate}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            let temp = reloadServiceCount(groupServiceDataFromApi, newVal, endDate);
                                            setGroupsServiceModeCountTable(temp);
                                            setStartDate(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                                <Grid item>
                                    < DatePicker
                                        disableFuture
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                        label={"Ende Datum"}
                                        value={endDate}
                                        onChange={(newVal) => {
                                            if (!newVal) return;
                                            let temp = reloadServiceCount(groupServiceDataFromApi, startDate, newVal);
                                            setGroupsServiceModeCountTable(temp);
                                            setEndDate(newVal);
                                        }}
                                        format={"dd.MM.yyyy"}

                                    />
                                </Grid>
                            </Grid>
                            {loadingProgress > 99 &&
                                <Grid item xs={12}>
                                    {groupsServiceModeCountTable.length > 0 ?
                                        <ServiceModeIncidentTable allincidents={allIncidents} groupServicesMode={groupsServiceModeCountTable} groupLicenseTable={groupLicenseTable}></ServiceModeIncidentTable>
                                        :
                                        <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Keine Daten Vorhanden!</Typography>
                                    }
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}