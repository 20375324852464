import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Apartment, Close, Delete, DeleteForever, FileCopy, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { PropertySelectionDataTable } from './PropertySelectionDataTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { GroupSettings, GroupSettingsType } from '../../../../models/GroupSettings';
import { TemplateSettingsMultipleSelectionDataTable } from './TemplateSettingsMultipleSelectionDataTable';
import { ObjectMultipleSelectionDataTable } from './ObjectMultipleSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignMultupleGroupToObjectDialog = ({
  open,
  setOpen,
  currentGroups,
  allGroups,
  userObjects,
  onRefresh,
  preSelectedObjects = [],
  onDone,
  type = GroupSettingsType.Default
}: {
  open: boolean,
  setOpen: any,
  currentGroups: GroupLicenseTable[],
  allGroups: GroupLicenseTable[],
  userObjects: ObjectUnit[],
  onRefresh: any,
  preSelectedObjects?: ObjectUnit[],
  onDone?: any,
  type? : GroupSettingsType
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const [token, setToken] = React.useState("");
  const [selectedUserObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);

  const [assignServiceWorkersOfObject, setAssignServiceWorkersOfObject] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => {
    setSelectedObjects([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);
      setSelectedObjects(preSelectedObjects);


    })();

  }, [open]);

  const title = type == GroupSettingsType.Default ? `${currentGroups.length} Einheiten` : 'Vorlagen'


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Wähle ein Objekt für die ausgewählten ${title}`}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectMultipleSelectionDataTable rows={userObjects}
          groups={allGroups}
          selectedObjects={selectedUserObjects}
          onSelectionChange={(selection: string[]) => {
            let selected = selection[selection.length - 1];
            setSelectedObjects(userObjects.filter(x => selected === x.id));
          }}
        />
      </DialogContent>

      <DialogActions>
        <FormControlLabel style={{ marginLeft: 'auto' }}
          control={
            <Checkbox
              checked={assignServiceWorkersOfObject}
              onChange={(event: any) => {
                setAssignServiceWorkersOfObject(event.target.checked);
              }}
              name="isEnabled"
              color="primary"
            />
          }
          label={`Servicekräfte des Objekts auf ${title} zuweisen/wegweisen`}
        />
      </DialogActions>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        {selectedUserObjects.length > 0 &&
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={(preSelectedObjects.length > 0 && preSelectedObjects[0].id != selectedUserObjects[0].id) || loading ? {} : { backgroundColor: errorColor, color: white }}
            startIcon={loading ? <CircularProgress /> : <Apartment />}
            disabled={(preSelectedObjects.length > 0 && preSelectedObjects[0].id != selectedUserObjects[0].id) || loading}
            endIcon={<DeleteForever />}
            onClick={async () => {
              setLoading(true);
              for (let currentGroup of currentGroups) {
                await api.unAssignObjectFromGroup(currentGroup.id, selectedUserObjects[0].id, token, enqueueSnackbar);
                if (assignServiceWorkersOfObject) {
                  await api.UnAssignUserServiceWorkersFromGroup(currentGroup.id, selectedUserObjects[0].userServiceWorkerIds, token, enqueueSnackbar);
                }
              }
              setLoading(false);
              handleClose();
              onRefresh();

            }}
          >
            Objekt von {title} entfernen
          </Button>
        }
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading ? <CircularProgress /> : <Apartment />}
          disabled={selectedUserObjects.length < 1 || loading}
          endIcon={<Add />}
          onClick={async () => {
            if (!selectedUserObjects[0].id) return;

            setLoading(true);
            if (onDone) {
              await onDone(selectedUserObjects[0], assignServiceWorkersOfObject);
            } else {
              for (let currentGroup of currentGroups) {
                await api.assignObjectUnitToGroup(currentGroup.id, selectedUserObjects[0].id, token, enqueueSnackbar);
                if (assignServiceWorkersOfObject) {
                  await api.assignUserServiceWorkersToGroup(currentGroup.id, selectedUserObjects[0].userServiceWorkerIds, token, enqueueSnackbar);
                }
              }
            }

            setLoading(false);
            handleClose();
            onRefresh();


          }}
        >
          Ausgewähltes Objekt zu {title} zuweisen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));