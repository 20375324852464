import {
    AccordionSummary,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    AccordionDetails,
    TextField,
    Theme,
    Accordion,
    Fab,
    InputAdornment,
    Button,
    Radio,
    FormLabel,
    FormControl,
    RadioGroup,
    Box,
    Divider,
    Alert,
    CircularProgress,
    TextareaAutosize,
    Popover,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slider,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Check, CloudUpload, ColorLens, ExpandMore, FaceRetouchingOff, Remove } from '@mui/icons-material';
import { FeedbackDisplayType, FeedbackQuestion, GroupSettings, GroupSettingsSmilesType, GroupSettingsIncidenReport, PublicFeedbackModeType } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { ReactComponent as EmoticonNeutalSmileSvg } from '../../assets/svgs/emoticon-neutral-outline.svg'
import { errorColor, lightgrey, white } from '../../styles/colors';
import { GroupSettingsMappingKeys } from '../../helpers/group-settings-management';
import { useContainerStyles } from '../../styles/container';
import { PeriodChartFilterType } from '../../helpers/charts/base';
import { convertMinutesToTimespan, convertTimespanToMinutes } from '../../helpers/date-management';
import GreenSmile from '../../assets/image/smiles/green.png';
import YellowSmile from '../../assets/image/smiles/yellow.png';
import RedSmile from '../../assets/image/smiles/red.png';
import GreenLightSmile from '../../assets/image/smiles/lightgreensmile.png';
import RedLightSmile from '../../assets/image/smiles/lightredsmile.png';
import ThreeSmiles from '../../assets/image/smiles/threesmiles.png';
import FiveSmiles from '../../assets/image/smiles/fivesmiles.png';
import React from 'react';
import { Group, GroupType } from '../../models/Group';
import { StandardWorkItem, StandardWorkItemType } from '../../models/StandardItem';
import { api } from '../../api';
import { FileBlobType } from '../../models/FileBlobType';
import { useSnackbar } from 'notistack';
import DefaultItemsDialog from '../dialogs/DefaultItemsDialog';
import { getDefaultIcon } from '../dialogs/DefaultItemsDialogOld';
import { LocalMedia } from '../images/NetworkMedia';
import { ChromePicker } from 'react-color';
import { QrCodeUrlComponent } from './GroupSettingFieldsLocation';
import { BackgroundColorFeedbackComponent, ComponentPartType, DisplayTextComponent, FeedbackSmileModeComponent } from './GroupSettingFields.Content';
import PublicFeedbackFinishImage from '../../assets/image/publicFeedbackFinish.png';
import PublicFeedbackEntryImage from '../../assets/image/publicFeedbackEntry.png';
import { IncidentVisitorCheckboxComponent, IncidentVisitorFreeTextCheckboxComponent, IncidentVisitorWithNameCheckboxComponent, PublicFeedbackCheckboxComponent, PublicFeedbackRestartCheckboxComponent } from './GroupSettingFieldsIncidenReports';
import { GroupSettingFieldsLogo } from './GroupSettingFields.Logo';
import { UserAccountPage } from '../authorized/portal/UserAccountPage';
import { GlobalContext, SettingsContext } from '../authorized/PortalPage';
import { PayPerServiceIsEnabledCheckboxComponent } from './GroupSettingFieldsPayPerService';
import { TextFieldGroupSettings } from './components/groupSettings/TextFieldGroupSettings';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../models/UserType';
import { UserAccount } from '../../models/UserAccount';


export const GroupSettingFieldsFeedbackControl = ({ row, onChange, group, token, type }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, group: Group | undefined; token: any, type: any }) => {

    const classes = useStyles();
    const containerStyles = useContainerStyles();
    const [standardFeedbackQuestion, setStandardFeedbackQuestion] = React.useState<StandardWorkItem[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
    const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(null);
    const [anchorEl4, setAnchorEl4] = React.useState<HTMLButtonElement | null>(null);

    const [openAccountDialog, setOpenAccountDialog] = React.useState(false);

    const { t } = useTranslation();



    const buildSmileRadioButton = () => {
        return <Grid container direction="row">
            <Grid item xs={12} lg={6} container direction="column">
                <Grid item>
                    <FormControlLabel style={{ marginRight: 50 }} value={FeedbackDisplayType.PositiveNegativeNeutral} control={<Radio />} label={t("myUnitsSettings.3Smiles")} />
                </Grid>
                <Grid item>
                    <img src={ThreeSmiles} style={{ height: 40 }} />
                </Grid>
            </Grid>
            {group?.groupType == GroupType.DisplayWallApp &&
                <Grid item xs={12} lg={6} container direction="column">
                    <Grid item>
                        <FormControlLabel value={FeedbackDisplayType.AdvancedWithVeryPostiveNegative} control={<Radio />} label={t("myUnitsSettings.5Smiles")} />
                    </Grid>
                    <Grid item>
                        <img src={FiveSmiles} style={{ height: 40 }} />
                    </Grid>
                </Grid>
            }
        </Grid>
    }

    const isThreeSmilesDisplay = row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.PositiveNegativeNeutral;

    const buildVeryPositiveFeedback = () => {
        if (!isThreeSmilesDisplay) {
            return <React.Fragment>
                <Grid item>
                    <img src={GreenSmile} style={{ height: 60 }} />
                </Grid>
                {!row.feedbacks?.isLightSmilePositiveCountSame &&
                    <Grid item md={5} lg={5}>
                        <TextFieldGroupSettings
                            fullWidth
                            variant={"filled"}
                            type="number"
                            name="feedbackControlAmountTriggerVeryGood"
                            label={t("myUnitsSettings.triggerVeryPositiveFeedback")}
                            value={row.feedbacks?.amountTriggerVeryGood || ''}
                            onChange={onChange}
                        />
                    </Grid>
                }
                <Grid item md={5} lg={5}>
                    <FormControlLabel
                        label={t("myUnitsSettings.veryPositiveAndPositiveCountedTogetherEnabled")}
                        control={
                            <Checkbox
                                name={GroupSettingsMappingKeys.isLightSmilePositiveCountSame}
                                color="primary"
                                checked={row.feedbacks?.isLightSmilePositiveCountSame}
                                onChange={(event: any, checked: boolean) => {
                                    event.target.value = checked;
                                    onChange(event);
                                }}
                            />
                        }
                    />
                </Grid>


                <Box width="100%" />
            </React.Fragment>
        }
    }

    const buildVeryNegativeFeedback = () => {
        if (!isThreeSmilesDisplay) {
            return <React.Fragment>
                <Grid item>
                    <img src={RedSmile} style={{ height: 60 }} />
                </Grid>
                {
                    !row.feedbacks?.isLightSmileNegativCountSame &&
                    <Grid item md={5} lg={5}>
                        <TextFieldGroupSettings
                            fullWidth
                            variant={"filled"}
                            type="number"
                            name="feedbackControlAmountTriggerVeryBad"
                            label={t("myUnitsSettings.triggerVeryNegativeFeedback")}
                            value={row.feedbacks?.amountTriggerVeryBad || ''}
                            onChange={onChange}
                        />
                    </Grid>
                }
                <Grid item md={5} lg={5}>
                    <FormControlLabel
                        label={t("myUnitsSettings.veryNegativeAndNegativeCountedTogetherEnabled")}
                        control={
                            <Checkbox
                                name={GroupSettingsMappingKeys.isLightSmileNegativCountSame}
                                color="primary"
                                checked={row.feedbacks?.isLightSmileNegativCountSame}
                                onChange={(event: any, checked: boolean) => {
                                    event.target.value = checked;
                                    onChange(event);
                                }}
                            />
                        }
                    />
                </Grid>


                <Box width="100%" />
            </React.Fragment>
        }
    }

    return (
        <Accordion className={classes.hover} onChange={async (event, expanded) => {
            if (expanded && standardFeedbackQuestion.length < 1 && row.feedbacks?.feedbackQuestions) {
                loadStandardItems(token, setStandardFeedbackQuestion, enqueueSnackbar, row.feedbacks?.feedbackQuestions);

            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}            >
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <EmoticonNeutalSmileSvg width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.feedbackControl")}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <FormControlLabel
                            label={t("settingsPage.feedbackMessagesActivated")}
                            control={
                                <Checkbox
                                    name="isFeedbackNotificationEnabled"
                                    color="primary"
                                    checked={row.notification?.isFeedbackNotificationEnabled}
                                    onChange={(event: any, checked: boolean) => {
                                        event.target.value = checked;
                                        onChange(event);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            label={t("settingsPage.feedbackDisplayActivated")}
                            control={
                                <Checkbox
                                    name="isFeedbackControlEnabled"
                                    color="primary"
                                    checked={row.feedbacks?.isFeedbackControlEnabled}
                                    onChange={(event: any, checked: boolean) => {
                                        event.target.value = checked;
                                        onChange(event);
                                    }}
                                />
                            }
                        />
                    </Grid>

                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction="column" item spacing={3}>
                    {row?.feedbacks?.feedbackDisabledDuration != undefined &&
                        <Grid container item direction="row" spacing={3} alignItems={"center"}>
                            <Grid item>
                                <FaceRetouchingOff color='error' style={{ fontSize: 60, width: 60 }} />
                            </Grid>
                            <Grid item>
                                <TextFieldGroupSettings
                                    style={{ width: 400 }}
                                    variant={"filled"}
                                    type="number"
                                    name="feedbackControlFeedbackDisabledDuration"
                                    label={t("myUnitsSettings.durationInSecondsAfterVisitorFeedbackTouchBlocked")}
                                    value={row.feedbacks.feedbackDisabledDuration / 1000}
                                    onChange={(event) => {
                                        if (!event.target.value) return;
                                        event.target.value = (parseInt(event.target.value) * 1000).toString();
                                        onChange(event);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Slider
                                    style={{ width: 500 }}
                                    value={row.feedbacks.feedbackDisabledDuration / 1000}
                                    size='medium'
                                    onChange={(event, value: any) => {
                                        if (!row.feedbacks) return;
                                        onChange({ target: { name: "feedbackControlFeedbackDisabledDuration", value: (value * 1000).toString() } });
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Alert severity="warning">{t("myUnitsSettings.ifTooFewSecondsAreSetThereIsARiskThatAVisitorWillGiveFeedbackMultipleTimes")}</Alert>
                            </Grid>
                        </Grid>

                    }

                    <Grid item container direction="row">
                        <Grid item lg={9} container direction="row" spacing={3}>
                            <Grid item xs={6} container direction="row" alignItems="center" spacing={3}>
                                {buildVeryPositiveFeedback()}
                                <Grid item>
                                    <img src={isThreeSmilesDisplay ? GreenSmile : GreenLightSmile} style={{ height: 60 }} />
                                </Grid>
                                <Grid item md={5} lg={5}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        variant={"filled"}
                                        type="number"
                                        name="feedbackControlAmountTriggerGood"
                                        label={t("myUnitsSettings.triggerPositiveFeedback")}
                                        value={row.feedbacks?.amountTriggerGood || ''}
                                        onChange={onChange}
                                    />
                                </Grid>

                                <Box width="100%" />
                                <Grid item>
                                    <img src={YellowSmile} style={{ height: 60 }} />
                                </Grid>
                                <Grid item md={5} lg={5}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        variant={"filled"}
                                        type="number"
                                        name="feedbackControlAmountTriggerOk"
                                        label={t("myUnitsSettings.triggerNeutralFeedback")}
                                        value={row.feedbacks?.amountTriggerOk || ''}
                                        onChange={onChange}
                                    />
                                </Grid>

                                <Box width="100%" />
                                <Grid item>
                                    <img src={isThreeSmilesDisplay ? RedSmile : RedLightSmile} style={{ height: 60 }} />
                                </Grid>
                                <Grid item md={5} lg={5}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        variant={"filled"}
                                        type="number"
                                        name="feedbackControlAmountTriggerBad"
                                        label={t("myUnitsSettings.triggerNegativeFeedback")}
                                        value={row.feedbacks?.amountTriggerBad || ''}
                                        onChange={onChange}
                                    />
                                </Grid>

                                <Box width="100%" />
                                {buildVeryNegativeFeedback()}
                            </Grid>
                            <Grid item lg={6} container direction="row" spacing={3} justifyContent={"center"} justifyItems={"center"} style={{ textAlign: 'center' }}>
                                <Grid item xs={4}><Typography textAlign={"center"}>{t("myUnitsSettings.negativeFeedbackQuestion")}</Typography></Grid>
                                <Grid item xs={4}><Typography textAlign={"center"}>{t("myUnitsSettings.positiveFeedbackQuestion")}</Typography></Grid>
                                <Grid item xs={4}><Typography textAlign={"center"}>{t("myUnitsSettings.suggestionsAndRequests")}</Typography></Grid>

                                {row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            style={{ margin: 0, padding: 0 }}
                                            control={<Checkbox
                                                checked={row.feedbacks?.isEnabledFeedbackQuestionVeryPositive}
                                                onChange={(event) => {
                                                    if (row.feedbacks)
                                                        row.feedbacks.isEnabledFeedbackQuestionVeryPositive = event.target.checked;
                                                    onChange(event);

                                                }}
                                                name={"row"}
                                                color="primary" />}
                                            label="" />
                                    </Grid>
                                }
                                {row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            style={{ margin: 0, padding: 0 }}
                                            control={<Checkbox
                                                checked={row.feedbacks?.isEnabledPositiveFeedbackQuestionVeryPositive}
                                                onChange={(event) => {
                                                    if (row.feedbacks)
                                                        row.feedbacks.isEnabledPositiveFeedbackQuestionVeryPositive = event.target.checked;
                                                    onChange(event)

                                                }}
                                                name={"row"}
                                                color="primary" />}
                                            label="" />
                                    </Grid>
                                }
                                {row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            style={{ margin: 0, padding: 0 }}
                                            control={<Checkbox
                                                checked={row.feedbacks?.isEnabledSuggestionVeryPositive}
                                                onChange={(event) => {
                                                    if (row.feedbacks)
                                                        row.feedbacks.isEnabledSuggestionVeryPositive = event.target.checked;
                                                    onChange(event)

                                                }}
                                                name={"row"}
                                                color="primary" />}
                                            label="" />
                                    </Grid>
                                }





                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledFeedbackQuestionPositive}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledFeedbackQuestionPositive = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledPositiveFeedbackQuestionPositive}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledPositiveFeedbackQuestionPositive = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledSuggestionPositive}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledSuggestionPositive = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>



                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledFeedbackQuestionNeutral}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledFeedbackQuestionNeutral = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledPositiveFeedbackQuestionNeutral}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledPositiveFeedbackQuestionNeutral = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledSuggestionNeutral}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledSuggestionNeutral = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>



                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledFeedbackQuestionNegative}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledFeedbackQuestionNegative = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledPositiveFeedbackQuestionNegative}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledPositiveFeedbackQuestionNegative = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        style={{ margin: 0, padding: 0 }}
                                        control={<Checkbox
                                            checked={row.feedbacks?.isEnabledSuggestionNegative}
                                            onChange={(event) => {
                                                if (row.feedbacks)
                                                    row.feedbacks.isEnabledSuggestionNegative = event.target.checked;
                                                onChange(event)

                                            }}
                                            name={"row"}
                                            color="primary" />}
                                        label="" />
                                </Grid>


                                {row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            style={{ margin: 0, padding: 0 }}
                                            control={<Checkbox
                                                checked={row.feedbacks?.isEnabledFeedbackQuestionVeryNegative}
                                                onChange={(event) => {
                                                    if (row.feedbacks)
                                                        row.feedbacks.isEnabledFeedbackQuestionVeryNegative = event.target.checked;
                                                    onChange(event)

                                                }}
                                                name={"row"}
                                                color="primary" />}
                                            label="" />
                                    </Grid>
                                }
                                {row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&

                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            style={{ margin: 0, padding: 0 }}
                                            control={<Checkbox
                                                checked={row.feedbacks?.isEnabledPositiveFeedbackQuestionVeryNegative}
                                                onChange={(event) => {
                                                    if (row.feedbacks)
                                                        row.feedbacks.isEnabledPositiveFeedbackQuestionVeryNegative = event.target.checked;
                                                    onChange(event)

                                                }}
                                                name={"row"}
                                                color="primary" />}
                                            label="" />
                                    </Grid>
                                }
                                {row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            style={{ margin: 0, padding: 0 }}
                                            control={<Checkbox
                                                checked={row.feedbacks?.isEnabledSuggestionVeryNegative}
                                                onChange={(event) => {
                                                    if (row.feedbacks)
                                                        row.feedbacks.isEnabledSuggestionVeryNegative = event.target.checked;
                                                    onChange(event)

                                                }}
                                                name={"row"}
                                                color="primary" />}
                                            label="" />
                                    </Grid>
                                }


                            </Grid>
                        </Grid>
                        <Grid item lg={3} container direction="column" spacing={3}>
                            <Grid item>
                                <TextFieldGroupSettings

                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    variant={"filled"}
                                    name="feedbackControlDeviationTime"
                                    label={t("myUnitsSettings.responseTimeInMinutes")}
                                    value={row.feedbacks ? convertTimespanToMinutes(row.feedbacks.deviationTime) : 0}
                                    onChange={(event: any) => {
                                        let res = { target: { name: 'feedbackControlDeviationTime', value: convertMinutesToTimespan(event.target.value) } };
                                        onChange(res);
                                    }} />
                            </Grid>
                            {group?.groupType == GroupType.DisplayWallApp &&

                                <Grid item>
                                    <FormControlLabel
                                        label="Summe Feedback am Display anzeigen"
                                        control={
                                            <Checkbox
                                                name="isSumDisplayEnabled"
                                                color="primary"
                                                checked={row.feedbacks?.isSumDisplayEnabled}
                                                onChange={(event: any, checked: boolean) => {
                                                    event.target.value = checked;
                                                    onChange(event);
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            }
                            <Grid item>
                                <FormControlLabel
                                    label={t("myUnitsSettings.serviceTriggerActivated")}
                                    control={
                                        <Checkbox
                                            name={GroupSettingsMappingKeys.feedbackControlNotificationEnabled}
                                            color="primary"
                                            checked={row.feedbacks?.isNotificationEnabled}
                                            onChange={(event: any, checked: boolean) => {
                                                event.target.value = checked;
                                                onChange(event);
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item container direction="column">
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{t("myUnitsSettings.smileType")}</FormLabel>
                                        <RadioGroup
                                            row
                                            name={GroupSettingsMappingKeys.feedbackDisplayType}
                                            value={row.feedbacks?.feedbackDisplayType || FeedbackDisplayType.PositiveNegativeNeutral}
                                            onChange={onChange}
                                        >
                                            {buildSmileRadioButton()}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container direction="row" spacing={3}>
                        <Grid item container direction="row" xs={12} spacing={2} alignItems='center' >
                            <Grid item>
                                <Typography variant="h4">{t("myUnitsSettings.suggestionsAndRequests")}</Typography>
                            </Grid>
                            <Grid item >
                                <Alert severity="info">{t("myUnitsSettings.visitorsShouldWriteTheirSuggestionsAndRequest")}</Alert>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    style={{ margin: 0, padding: 0 }}
                                    control={<Checkbox
                                        checked={row.feedbacks?.isSuggestionEnabled}
                                        onChange={(event) => {
                                            if (row.feedbacks)
                                                row.feedbacks.isSuggestionEnabled = event.target.checked;
                                            onChange(event);

                                        }}
                                        name={"row"}
                                        color="primary" />}
                                    label={t("myUnitsSettings.suggestionsAndRequestsEnabled")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container direction="row" spacing={3}>
                        <Grid item container direction="row" xs={12} spacing={2} alignItems='center' >
                            <Grid item>
                                <Typography variant="h4">{t("myUnitsSettings.negativeFeedbackQuestionsForVisitors")}</Typography>
                            </Grid>
                            <Grid item >
                                <Alert severity="info">{t("myUnitsSettings.visitorsShouldProvideAJustificationAfterTheirRating")}</Alert>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    style={{ margin: 0, padding: 0 }}
                                    control={<Checkbox
                                        checked={row.feedbacks?.isMessengerFeedbackQuestionEnabled}
                                        onChange={(event) => {
                                            if (row.feedbacks)
                                                row.feedbacks.isMessengerFeedbackQuestionEnabled = event.target.checked;
                                            onChange(event);

                                        }}
                                        name={"row"}
                                        color="primary" />}
                                    label={t("myUnitsSettings.notifierFeedbackQuestionEnabled")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={5} lg={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                label={t("myUnitsSettings.headlineNegativeFeedbackQuestions")}
                                name="row"
                                multiline
                                minRows={3}
                                value={row.feedbacks?.feedbackQuestionsHeader || ''}
                                onChange={(event) => {
                                    if (row.feedbacks)
                                        row.feedbacks.feedbackQuestionsHeader = event.target.value;
                                    onChange(event);
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <FeedbackQuestionCheckboxEnabledComponent row={row} onChange={onChange} />
                        <FeedbackQuestionFreeTextCheckboxEnabledComponent row={row} onChange={onChange} />
                        <FeedbackQuestionFreeTextOnlyCheckboxEnabledComponent row={row} onChange={onChange} />

                    </Grid>
                    {!row?.feedbacks?.isFeedbackOnlyCustomTextEnabled &&
                        <Grid item container direction="row" spacing={3}>

                            <Grid item>
                                <Button
                                    size="large"
                                    startIcon={<Add />}
                                    variant={'contained'}
                                    onClick={async () => {
                                        onChange({ target: { name: 'feedbackQuestionsItemsAdd', value: "" } });
                                    }}
                                    color="primary"
                                >
                                    {t("myUnitsSettings.addFeedbackQuestions")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <DefaultItemsDialog
                                    onLoadFiles={async () => {
                                        let res = await Promise.all(standardFeedbackQuestion.map(async (x) => {
                                            if (x.photos.length > 0) {
                                                x.downloadedPhotoUrl = await api.DownloadUserFile(x.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, x.photos[0].fileName, x.photos[0].contentType, true)
                                            }
                                            return x;
                                        }));
                                        setStandardFeedbackQuestion(res);
                                    }}
                                    allStandardItems={standardFeedbackQuestion}
                                    currentItems={row.cleaningOperations?.cleaningOperations.map(el => standardFeedbackQuestion.find(x => x.id == el.standardItemId) || new StandardWorkItem()) || []}
                                    manageItems={(newItems: StandardWorkItem[]) => {
                                        onChange({ target: { name: 'feedbackQuestionsItemsAddMultiple', value: newItems.map(x => FeedbackQuestion.CreateInstance(x.text, true, x.id)) } });
                                    }}
                                    itemType={StandardWorkItemType.FeedbackQuestion}
                                />
                            </Grid>
                        </Grid>
                    }
                    {!row?.feedbacks?.isFeedbackOnlyCustomTextEnabled &&
                        <Grid item container direction={"row"} spacing={3}>
                            {row?.feedbacks?.feedbackQuestions &&
                                row.feedbacks.feedbackQuestions.map((el: FeedbackQuestion, index: number) => {
                                    const currentStandardItem = standardFeedbackQuestion.find(x => x.id == el.standardItemId);
                                    return (
                                        <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={0}>
                                            <Grid item xs={1}>
                                                <Fab
                                                    size="small"
                                                    style={{ backgroundColor: errorColor, color: white }}
                                                    onClick={() => {
                                                        onChange({ target: { name: 'feedbackQuestionsItemsRemove', value: JSON.stringify({ index: index }) } });
                                                    }}
                                                >
                                                    <Remove />
                                                </Fab>
                                            </Grid>
                                            {currentStandardItem ?
                                                <Grid item xs={8} className={containerStyles.marginFieldSmall}>
                                                    <TextFieldGroupSettings
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: <InputAdornment variant="filled" position="end"><StandardItemIcon currentStandardItem={currentStandardItem} /></InputAdornment>,
                                                        }}
                                                        variant={"filled"}
                                                        name="feedbackQuestionsItems"
                                                        label={t("myUnitsSettings.feedbackQuestion")}
                                                        multiline
                                                        minRows={3}
                                                        disabled
                                                        value={currentStandardItem.text || ''} />
                                                </Grid> :
                                                <Grid item xs={8} className={containerStyles.marginFieldSmall}>
                                                    <TextFieldGroupSettings
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                        }}
                                                        variant={"filled"}
                                                        name="feedbackQuestionsItems"
                                                        label={t("myUnitsSettings.feedbackQuestion")}
                                                        multiline
                                                        minRows={3}
                                                        value={el.text || ''}
                                                        onChange={(event: any) => {
                                                            let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                            onChange(res);
                                                        }} />
                                                </Grid>
                                            }
                                            <Grid item xs={2}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={el.isEnabled}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                            onChange(target);
                                                        }}
                                                        name="feedbackQuestionsItems"
                                                        color="primary" />}
                                                    label={t("myUnitsSettings.activated")} />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    }
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container direction="row" spacing={3}>
                        <Grid item container direction="row" xs={12} spacing={2} alignItems='center' >
                            <Grid item>
                                <Typography variant="h4">{t("myUnitsSettings.positiveFeedbackquestionsForVisitors")}</Typography>
                            </Grid>
                            <Grid item >
                                <Alert severity="info">{t("myUnitsSettings.visitorsShouldProvideAJustificationAfterTheirRating")}</Alert>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    style={{ margin: 0, padding: 0 }}
                                    control={<Checkbox
                                        checked={row.feedbacks?.isMessengerFeedbackQuestionEnabled}
                                        onChange={(event) => {
                                            if (row.feedbacks)
                                                row.feedbacks.isMessengerFeedbackQuestionEnabled = event.target.checked;
                                            onChange(event);

                                        }}
                                        name={"row"}
                                        color="primary" />}
                                    label={t("myUnitsSettings.notifierFeedbackQuestionEnabled")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={5} lg={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                label={t("myUnitsSettings.headlinePositiveFeedbackQuestions")}
                                name="row"
                                multiline
                                minRows={3}
                                value={row.feedbacks?.feedbackQuestionsPositiveHeader || ''}
                                onChange={(event) => {
                                    if (row.feedbacks)
                                        row.feedbacks.feedbackQuestionsPositiveHeader = event.target.value;
                                    onChange(event);
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <PositiveFeedbackQuestionCheckboxEnabledComponent row={row} onChange={onChange} />
                        <PositiveFeedbackQuestionFreeTextCheckboxEnabledComponent row={row} onChange={onChange} />
                        <PositiveFeedbackQuestionFreeTextOnlyCheckboxEnabledComponent row={row} onChange={onChange} />
                    </Grid>
                    {!row?.feedbacks?.isFeedbackOnlyCustomTextPositiveEnabled &&

                        <Grid item container direction="row" spacing={3}>

                            <Grid item>
                                <Button
                                    size="large"
                                    startIcon={<Add />}
                                    variant={'contained'}
                                    onClick={async () => {
                                        onChange({ target: { name: 'feedbackQuestionsPositiveItemsAdd', value: "" } });
                                    }}
                                    color="primary"
                                >
                                    {t("myUnitsSettings.addPositiveFeedbackQuestions")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <DefaultItemsDialog
                                    onLoadFiles={async () => {
                                        let res = await Promise.all(standardFeedbackQuestion.map(async (x) => {
                                            if (x.photos.length > 0) {
                                                x.downloadedPhotoUrl = await api.DownloadUserFile(x.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, x.photos[0].fileName, x.photos[0].contentType, true)
                                            }
                                            return x;
                                        }));
                                        setStandardFeedbackQuestion(res);
                                    }}
                                    allStandardItems={standardFeedbackQuestion}
                                    currentItems={row.cleaningOperations?.cleaningOperations.map(el => standardFeedbackQuestion.find(x => x.id == el.standardItemId) || new StandardWorkItem()) || []}
                                    manageItems={(newItems: StandardWorkItem[]) => {
                                        onChange({ target: { name: 'feedbackQuestionsPositiveItemsAddMultiple', value: newItems.map(x => FeedbackQuestion.CreateInstance(x.text, true, x.id)) } });
                                    }}
                                    itemType={StandardWorkItemType.FeedbackQuestion}
                                />
                            </Grid>
                        </Grid>
                    }
                    {!row?.feedbacks?.isFeedbackOnlyCustomTextPositiveEnabled &&
                        <Grid item container direction={"row"} spacing={3}>
                            {row?.feedbacks?.feedbackQuestionsPositive &&
                                row.feedbacks.feedbackQuestionsPositive.map((el: FeedbackQuestion, index: number) => {
                                    const currentStandardItem = standardFeedbackQuestion.find(x => x.id == el.standardItemId);
                                    return (
                                        <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={0}>
                                            <Grid item xs={1}>
                                                <Fab
                                                    size="small"
                                                    style={{ backgroundColor: errorColor, color: white }}
                                                    onClick={() => {
                                                        onChange({ target: { name: 'feedbackQuestionsPositiveItemsRemove', value: JSON.stringify({ index: index }) } });
                                                    }}
                                                >
                                                    <Remove />
                                                </Fab>
                                            </Grid>
                                            {currentStandardItem ?
                                                <Grid item xs={8} className={containerStyles.marginFieldSmall}>
                                                    <TextFieldGroupSettings
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: <InputAdornment variant="filled" position="end"><StandardItemIcon currentStandardItem={currentStandardItem} /></InputAdornment>,
                                                        }}
                                                        variant={"filled"}
                                                        name="feedbackQuestionsPositiveItems"
                                                        label={t("myUnitsSettings.positiveFeedbackQuestion")}
                                                        multiline
                                                        minRows={3}
                                                        disabled
                                                        value={currentStandardItem.text || ''} />
                                                </Grid> :
                                                <Grid item xs={8} className={containerStyles.marginFieldSmall}>
                                                    <TextFieldGroupSettings
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                        }}
                                                        variant={"filled"}
                                                        name="feedbackQuestionsPositiveItems"
                                                        label={t("myUnitsSettings.positiveFeedbackQuestion")}
                                                        multiline
                                                        minRows={3}
                                                        value={el.text || ''}
                                                        onChange={(event: any) => {
                                                            let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                            onChange(res);
                                                        }} />
                                                </Grid>
                                            }
                                            <Grid item xs={2}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={el.isEnabled}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                            onChange(target);
                                                        }}
                                                        name="feedbackQuestionsPositiveItems"
                                                        color="primary" />}
                                                    label={t("myUnitsSettings.activated")} />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    }
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container direction="row" spacing={3}>
                        <Grid item container direction="row" xs={12} spacing={2} alignItems='center' >
                            <Grid item>
                                <Typography variant="h4">{t("myUnitsSettings.serviceStaffFeedback")}</Typography>
                            </Grid>
                            <Grid item>
                                <Alert severity="info">{t("myUnitsSettings.serviceStaffShouldRateTheRoomConditionBeforeTheService")}</Alert>

                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                label={t("myUnitsSettings.serviceStaffFeedbackEnabled")}
                                control={
                                    <Checkbox
                                        name={GroupSettingsMappingKeys.isCleanerFeedbackEnabled}
                                        color="primary"
                                        checked={row.feedbacks?.isCleanerFeedbackEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            event.target.value = checked;
                                            onChange(event);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("myUnitsSettings.smileType")}</FormLabel>
                                <RadioGroup
                                    row
                                    name={GroupSettingsMappingKeys.feedbackCleanerDisplayType}
                                    value={row.feedbacks?.feedbackCleanerDisplayType || FeedbackDisplayType.PositiveNegativeNeutral}
                                    onChange={(event: any) => {
                                        onChange(event);
                                    }}                                >
                                    {buildSmileRadioButton()}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item direction="column" spacing={3}>
                        <Grid item container direction="row" spacing={3}>
                            <Grid item container direction="row" xs={12} spacing={2} alignItems='center' >
                                <Grid item>
                                    <Typography variant="h4">{t("myUnitsSettings.publicFeedbackMobile")}</Typography>
                                </Grid>
                                <Grid item >
                                    <Alert severity="info">{t("myUnitsSettings.visitorsShouldGiveARatingAfterScanningTheQRCode")}</Alert>
                                </Grid>
                            </Grid>
                            <PublicFeedbackCheckboxComponent onChange={onChange} row={row} />
                            <PublicFeedbackRestartCheckboxComponent onChange={onChange} row={row} />

                        </Grid>
                        <Grid item container>
                            <FeedbackQuestionCheckboxEnabledComponent row={row} onChange={onChange} />
                            <FeedbackQuestionFreeTextCheckboxEnabledComponent row={row} onChange={onChange} />
                        </Grid>
                        <Grid item container>
                            <PositiveFeedbackQuestionCheckboxEnabledComponent row={row} onChange={onChange} />
                            <PositiveFeedbackQuestionFreeTextCheckboxEnabledComponent row={row} onChange={onChange} />
                        </Grid>
                        <Grid item container>
                            <PayPerServiceIsEnabledCheckboxComponent row={row} onChange={onChange} group={group} />
                        </Grid>
                        <Grid item container>
                            <IncidentVisitorCheckboxComponent onChange={onChange} row={row} />
                            <IncidentVisitorFreeTextCheckboxComponent onChange={onChange} row={row} />
                            <IncidentVisitorWithNameCheckboxComponent onChange={onChange} row={row} />
                        </Grid>

                        <PublicFeedbackModeComponent row={row} onChange={onChange} />

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container direction={"row"} justifyContent="center" alignItems="center" spacing={3}>
                            <Grid container direction={"column"} spacing={3} item xs={9}>
                                <Grid item container direction={"row"}>
                                    <Button aria-describedby={""} size={"large"} startIcon={<CloudUpload />} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setOpenAccountDialog(true);
                                    }}>
                                        {t("myUnitsSettings.uploadCompanyLogo")}
                                    </Button>

                                </Grid>
                                <Grid item>
                                    <GroupSettingFieldsLogo
                                        row={row}
                                        onChange={onChange}
                                        group={group}
                                        type={type}
                                    />
                                </Grid>
                                <Grid item container direction={"row"} spacing={3} alignItems="center">
                                    <Grid item lg={3}>
                                        <Typography variant="h5">{t("myUnitsSettings.greetingTextTextAtTheTop")}</Typography>
                                    </Grid>

                                    <Grid item lg={6}>
                                        <TextFieldGroupSettings
                                            variant='filled'
                                            multiline
                                            fullWidth
                                            onChange={(event) => {
                                                onChange({ target: { name: "publicText1", value: event.target.value } });
                                            }}
                                            name="publicText1"
                                            value={row.content?.publicText1}
                                            aria-label="Öffentliches Feedback Text"
                                            minRows={3}
                                            placeholder={t("myUnitsSettings.greetingTextTextAtTheTop")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button aria-describedby={""} size={"large"} startIcon={<ColorLens />} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            setAnchorEl(event.currentTarget);
                                        }}>
                                            Schriftfarbe auswählen
                                        </Button>
                                        <Popover
                                            id={""}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={() => setAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <ChromePicker color={row?.content?.publicText1Color}
                                                onChange={(event) => {
                                                    if (!row.content?.publicText1Color || !event) return;
                                                    row.content.publicText1Color = event.hex;
                                                    onChange({ target: { name: "row", value: "" } });

                                                }} />
                                        </Popover>

                                    </Grid>


                                </Grid>

                                <DisplayTextComponent componentType={ComponentPartType.PublicFeedback} title='infoText1TextInTheMiddle' row={row} onChange={onChange} />
                                <DisplayTextComponent componentType={ComponentPartType.PublicFeedback2} title='infoText2TextAtTheBottom' row={row} onChange={onChange} />

                                <FeedbackSmileModeComponent row={row} onChange={onChange} />

                                <Grid item container direction={"row"} spacing={3} alignItems="center">

                                    <Grid item>
                                        <Button aria-describedby={""} size={"large"} startIcon={<ColorLens />} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            setAnchorEl2(event.currentTarget);
                                        }}>
                                            {t("myUnitsSettings.selectBackgroundColor")}
                                        </Button>
                                        <Popover
                                            id={""}
                                            open={Boolean(anchorEl2)}
                                            anchorEl={anchorEl2}
                                            onClose={() => setAnchorEl2(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <BackgroundColorFeedbackComponent row={row} onChange={onChange} componentType={ComponentPartType.PublicFeedback} />
                                        </Popover>

                                    </Grid>


                                </Grid>

                                <Grid item>
                                    <Typography variant="h5">{t("myUnitsSettings.websiteLink")}</Typography>
                                    <QrCodeUrlComponent onChange={onChange} row={row} />
                                </Grid>


                            </Grid>
                            <Grid container item xs={3}>
                                <Grid item>
                                    <img style={{ width: '100%' }} src={PublicFeedbackEntryImage} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid container item xs={12} spacing={3} alignItems="center">
                            <Grid container direction={"column"} spacing={3} item xs={9}>

                                <Grid item container direction="row" spacing={3} alignItems="center">
                                    <Grid item lg={3}>
                                        <Typography variant="h5">{t("myUnitsSettings.farewellTextFeedback1")} </Typography>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <TextFieldGroupSettings
                                            variant='filled'
                                            multiline
                                            fullWidth
                                            onChange={(event) => {
                                                onChange({ target: { name: "byeText1", value: event.target.value } });
                                            }}
                                            name="byeText1"
                                            value={row.content?.byeText1}
                                            minRows={3}
                                            placeholder={t("myUnitsSettings.farewellTextFeedback1")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button aria-describedby={""} size={"large"} startIcon={<ColorLens />} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            setAnchorEl3(event.currentTarget);
                                        }}>
                                            {t("myUnitsSettings.selectFontColor")}
                                        </Button>
                                        <Popover
                                            id={""}
                                            open={Boolean(anchorEl3)}
                                            anchorEl={anchorEl3}
                                            onClose={() => setAnchorEl3(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Grid item>
                                                <ChromePicker color={row?.content?.byeText1Color}
                                                    onChange={(event) => {
                                                        if (!row.content?.byeText1Color || !event) return;
                                                        row.content.byeText1Color = event.hex;
                                                        onChange({ target: { name: "row", value: "" } });

                                                    }} />
                                            </Grid>
                                        </Popover>

                                    </Grid>

                                </Grid>

                                <Grid item container direction="row" spacing={3} alignItems="center">
                                    <Grid item lg={3}>
                                        <Typography variant="h5">{t("myUnitsSettings.farewellTextFeedback2")}</Typography>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <TextFieldGroupSettings
                                            variant='filled'
                                            multiline
                                            fullWidth
                                            onChange={(event) => {
                                                onChange({ target: { name: "byeText2", value: event.target.value } });
                                            }}
                                            name="byeText2"
                                            value={row.content?.byeText2}
                                            minRows={3}
                                            placeholder={t("myUnitsSettings.publicFeedbackText")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button aria-describedby={""} size={"large"} startIcon={<ColorLens />} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            setAnchorEl4(event.currentTarget);
                                        }}>
                                            {t("myUnitsSettings.selectFontColor")}
                                        </Button>
                                        <Popover
                                            id={""}
                                            open={Boolean(anchorEl4)}
                                            anchorEl={anchorEl4}
                                            onClose={() => setAnchorEl4(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Grid item>
                                                <ChromePicker color={row?.content?.byeText2Color}
                                                    onChange={(event) => {
                                                        if (!row.content?.byeText2Color || !event) return;
                                                        row.content.byeText2Color = event.hex;
                                                        onChange({ target: { name: "row", value: "" } });

                                                    }} />
                                            </Grid>
                                        </Popover>


                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <img style={{ width: '100%' }} src={PublicFeedbackFinishImage} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>

                </Grid>
            </AccordionDetails>
            <AccountDialog open={openAccountDialog} setOpen={setOpenAccountDialog} />
        </Accordion >
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        hover: {
            "&:hover": {
                backgroundColor: lightgrey
            }
        },
    }),
);

export const loadStandardItems = async (token: any, setStandardFeedbackQuestion: any, enqueueSnackbar: any, items: FeedbackQuestion[] | GroupSettingsIncidenReport[], userId: string = "", standardItemType: StandardWorkItemType = StandardWorkItemType.FeedbackQuestion) => {
    let res = await api.GetStandardWorkItemsByType(token, standardItemType, userId);
    setStandardFeedbackQuestion(res);
    for (let item of items || []) {
        let curPhotoToDownload = res.find(x => x.id == item.standardItemId);
        if (curPhotoToDownload)
            curPhotoToDownload.downloadedPhotoUrl = await api.DownloadUserFile(curPhotoToDownload.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, curPhotoToDownload.photos[0].fileName, curPhotoToDownload.photos[0].contentType, true, userId)

    }
    setStandardFeedbackQuestion([...res]);
}
export const StandardItemIcon = ({ currentStandardItem }: { currentStandardItem: StandardWorkItem }) => {
    return (
        currentStandardItem.downloadedPhotoUrl ?
            <LocalMedia src={currentStandardItem.downloadedPhotoUrl.url} contentType={currentStandardItem.downloadedPhotoUrl.type} height={50} />
            : <CircularProgress />
    )
}

export const FeedbackQuestionCheckboxEnabledComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            <FormControlLabel
                label={t("myUnitsSettings.feedbackQuestionsEnabled")}
                control={
                    <Checkbox
                        name={GroupSettingsMappingKeys.isFeedbackQuestionEnabled}
                        color="primary"
                        checked={row.feedbacks?.isFeedbackQuestionEnabled}
                        onChange={(event: any, checked: boolean) => {
                            event.target.value = checked;
                            onChange(event);
                        }}
                    />
                }
            />
        </Grid>
    )
}

export const FeedbackQuestionFreeTextCheckboxEnabledComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            {row.feedbacks?.isFeedbackQuestionEnabled &&

                <FormControlLabel
                    label={t("myUnitsSettings.feedbackFreeTextInputEnabled")}
                    control={
                        <Checkbox
                            name={GroupSettingsMappingKeys.isFeedbackCustomTextEnabled}
                            color="primary"
                            checked={row.feedbacks?.isFeedbackCustomTextEnabled}
                            onChange={(event: any, checked: boolean) => {
                                event.target.value = checked;
                                onChange(event);
                            }}
                        />
                    }
                />
            }
        </Grid>
    )
}

export const FeedbackQuestionFreeTextOnlyCheckboxEnabledComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();

    if (!row.feedbacks?.isFeedbackCustomTextEnabled) {
        return <></>
    }

    return (
        <Grid item>
            {row.feedbacks?.isFeedbackQuestionEnabled &&

                <FormControlLabel
                    label={t("myUnitsSettings.showOnlyFreetextActivated")}
                    control={
                        <Checkbox
                            name={"row"}
                            color="primary"
                            checked={row.feedbacks?.isFeedbackOnlyCustomTextEnabled}
                            onChange={(event: any, checked: boolean) => {
                                row.feedbacks.isFeedbackOnlyCustomTextEnabled = checked;
                                onChange(event);
                            }}
                        />
                    }
                />
            }
        </Grid>
    )
}

export const PositiveFeedbackQuestionCheckboxEnabledComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            <FormControlLabel
                label={t("myUnitsSettings.positiveFeedbackQuestionsEnabled")}
                control={
                    <Checkbox
                        name={GroupSettingsMappingKeys.isFeedbackQuestionPositiveEnabled}
                        color="primary"
                        checked={row.feedbacks?.isFeedbackQuestionPositiveEnabled}
                        onChange={(event: any, checked: boolean) => {
                            event.target.value = checked;
                            onChange(event);
                        }}
                    />
                }
            />
        </Grid>
    )
}

export const PositiveFeedbackQuestionFreeTextCheckboxEnabledComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            {row?.feedbacks?.isFeedbackQuestionPositiveEnabled &&

                <FormControlLabel
                    label={t("myUnitsSettings.positiveFeedbackFreeTextInputEnabled")}
                    control={
                        <Checkbox
                            name={"row"}
                            color="primary"
                            checked={row.feedbacks?.isFeedbackCustomTextPositiveEnabled}
                            onChange={(event: any, checked: boolean) => {
                                row.feedbacks.isFeedbackCustomTextPositiveEnabled = checked;
                                onChange(event);
                            }}
                        />
                    }
                />
            }
        </Grid>
    )
}

export const PositiveFeedbackQuestionFreeTextOnlyCheckboxEnabledComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();

    if (!row.feedbacks?.isFeedbackCustomTextPositiveEnabled) {
        return <></>
    }

    return (
        <Grid item>
            {row.feedbacks?.isFeedbackQuestionPositiveEnabled &&

                <FormControlLabel
                    label={t("myUnitsSettings.showOnlyPositiveFreetextActivated")}
                    control={
                        <Checkbox
                            name={"row"}
                            color="primary"
                            checked={row.feedbacks?.isFeedbackOnlyCustomTextPositiveEnabled}
                            onChange={(event: any, checked: boolean) => {
                                row.feedbacks.isFeedbackOnlyCustomTextPositiveEnabled = checked;
                                onChange(event);
                            }}
                        />
                    }
                />
            }
        </Grid>
    )
}

export const AccountDialog = ({ open, setOpen }: { open: any, setOpen: any }) => {
    const { userAccount, setUserAccount }: { userAccount: any, setUserAccount: any } = React.useContext(GlobalContext);

    const handleClose = () => setOpen(false);
    const {t} = useTranslation();

    return (
        <Dialog
            fullWidth
            maxWidth={"xl"}
            onClose={handleClose}
            open={open}
        >
            <DialogContent dividers>
                <UserAccountPage setDrawerUserAccount={setUserAccount} currentLoggedInUser={userAccount} />
            </DialogContent>
            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<Check />}
                            variant={'contained'}
                            color="primary"
                            onClick={async () => {
                                handleClose();
                            }}
                        >
                            {t("myUnitsSettings.done")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export const PublicFeedbackModeComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            <FormControl component="fieldset">
                <FormLabel component="legend">{t("myUnitsSettings.publicFeedbackMode")}</FormLabel>
                <RadioGroup
                    row
                    aria-label="gender"
                    value={row?.feedbacks?.publicFeedbackModeType}
                    onChange={(event) => {
                        if (row?.feedbacks?.publicFeedbackModeType == null) return;

                        row.feedbacks.publicFeedbackModeType = parseInt(event.target.value);

                        onChange({ target: { name: "row", value: "" } });
                    }}
                >
                    <FormControlLabel
                        value={PublicFeedbackModeType.Standard}
                        control={<Radio />}
                        label={t("myUnitsSettings.feedbackFeedbackQuestionIncidentReportingPayPerService")}
                    />

                    <FormControlLabel
                        value={PublicFeedbackModeType.OnlyFeedbackQuestion}
                        control={<Radio />}
                        label={t("myUnitsSettings.onlyFeedbackQuestion")}
                    />

                    <FormControlLabel
                        value={PublicFeedbackModeType.OnlyIncident}
                        control={<Radio />}
                        label={t("myUnitsSettings.onlyIncidentReporting")}
                    />

                    <FormControlLabel
                        value={PublicFeedbackModeType.OnlyPayPerService}
                        control={<Radio />}
                        label={t("myUnitsSettings.onlyPayPerService")}
                    />


                </RadioGroup>

            </FormControl>
        </Grid>
    )

}