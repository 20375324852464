import Dialog from '@mui/material/Dialog';
import React from 'react';

import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { GROUP_ID, GROUP_NAME } from '../../../constants';
import { getApiToken } from '../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { UserAccount } from '../../../models/UserAccount';
import { UserType } from '../../../models/UserType';
import { GroupSingleSelection } from '../../tables/GroupSingleSelection';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { UserServiceWorkerObjectSession } from '../../../models/UserServiceWorkerObjectSession';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { ObjectSessionsByObjectIdTable } from './ObjectSessionsByObjectIdTable';
import { useTranslation } from 'react-i18next';

export const ObjectSessionByObjectIdDialog = ({
  open,
  setOpen,
  userServiceWorkers,
  selectedObjectUnit,
  groups
}: {
  open: boolean,
  setOpen: any,
  userServiceWorkers: UserServiceWorker[],
  selectedObjectUnit: ObjectUnit | undefined,
  groups: GroupLicenseTable[]
}) => {

  const history = useHistory();

  const [userServiceWorkerObjectSessions, setUserServiceWorkerObjectSessions] = React.useState<UserServiceWorkerObjectSession[]>([]);

  const handleClose = () => setOpen(false);
  const {t} = useTranslation();

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const token = getApiToken(history);
      if (!token) return;

      const tempSessions = await api.getObjectSessionsByObjectId(token, selectedObjectUnit?.id || "");
      setUserServiceWorkerObjectSessions(tempSessions);


    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Anmeldungen im Objekt ${selectedObjectUnit?.name} | Addresse: ${selectedObjectUnit?.addressLine} ${selectedObjectUnit?.city} | Kunde: ${selectedObjectUnit?.customerName}`}
        handleClose={handleClose}
      />

      <DialogContent>
        {selectedObjectUnit &&
          <ObjectSessionsByObjectIdTable
            setUserServiceWorkerObjectSessions={setUserServiceWorkerObjectSessions}
            selectedObjectUnit={selectedObjectUnit}
            userServiceWorkerObjectSessions={userServiceWorkerObjectSessions}
            userServiceWorkers={userServiceWorkers}
            groups={groups}
          />
        }
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          Dialog {t("myUnitsSettings.close")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));