import React from "react";
import { Grid, Slider, Switch, TextField, Typography } from "@mui/material";
import { GroupSettingsSideBarImageItem } from "../../models/GroupSettings";
import { useuseGroupSideBarImagesDialogSettingViewStyles } from "../../styles/useuseGroupSideBarImagesDialogSettingViewStyles";

export const GroupSideBarImagesDialogSettingsView = React.forwardRef(({ item }: { item: GroupSettingsSideBarImageItem | null }, ref: any) => {
    const classes = useuseGroupSideBarImagesDialogSettingViewStyles();

    const [slideDuration, setSlideDuration] = React.useState<number>(0);
    const [slideDurationInput, setSlideDurationInput] = React.useState<string>('');
    const [activated, setActivated] = React.useState<boolean>(false);

    const onActivationValueChanged = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setActivated(checked);
    }

    const onSliderValueChanged = async (event: Event, value: number | number[]) => {
        if (!value) return;

        setSlideDuration(value as number);
        setSlideDurationInput(value.toString());
    }

    const onSlideDurationInputChanged = async (event: any) => {
        if (!event) return;

        setSlideDurationInput(event.target.value);
    }

    const onSlideDurationInputBlurred = async (event: any) => {

        if (!event) return;

        const value = GroupSettingsSideBarImageItem.adaptSlideDurationValue(parseInt(event.target.value));
        setSlideDuration(value);
        setSlideDurationInput(value.toString());
    }

    const collectData = (): GroupSettingsSideBarImageItem => {

        if (item) {
            item.activated = activated;
            item.slideDuration = slideDuration;
        }

        return item as GroupSettingsSideBarImageItem;
    }

    React.useImperativeHandle(
        ref,
        () => ({
            collectData: collectData
        }),
    )

    React.useEffect(() => {

        if (!item) return;

        setSlideDuration(item.slideDuration);
        setSlideDurationInput(item.slideDuration.toString());
        setActivated(item.activated);

    }, [item])

    if (!item)
        return null;

    return (
        <div className={classes.root}>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography>{activated ? "Aktiviert" : "Deaktiviert"}</Typography>
                </Grid>
                <Grid item>
                    <Switch
                        color="primary"
                        checked={activated}
                        onChange={onActivationValueChanged} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs className={classes.sliderContainer}>
                    <Slider
                        value={slideDuration}
                        onChange={onSliderValueChanged}
                        min={4}
                        max={300}
                        step={1} />
                </Grid>
                <Grid item style={{ width: '30%' }}>
                    <TextField
                        label="Dauer in Sekunden"
                        defaultValue={slideDurationInput}
                        value={slideDurationInput}
                        onChange={onSlideDurationInputChanged}
                        onBlur={onSlideDurationInputBlurred}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </div>
    );
});
