import { ExpandMore, Height } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Divider, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { differenceInMinutes, isAfter, isBefore, subDays } from "date-fns";
import React, { Fragment, useContext, useImperativeHandle, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { OBJECT_COMBINATION } from "../../../../constants";
import { DataFilterType, PeriodChartFilterType, generateChartData, getDataFromGroupIdsFeedbackQuestions, getDataFromGroupIdsTrigger, APIDataAmountType, getFeedbackData, getFeedbackQuestionsByUserGroupIds, getOnlineStatusData, getQualityChecksByUserIdGroupIds } from "../../../../helpers/charts/base";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { getValueFromGroupFeedback } from "../../../../helpers/charts/objectLeader/dataGenerator";
import { getAvgFromSimpleChart, getAvgFromSimpleChartValueField, getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../helpers/compontents";
import { formatDateRange, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage, getUnixMillisec } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getFeedbackQuestionsForEval, getFeedbacksForEval, getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds, getPortalGroupsForEval } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupFeedback } from "../../../../models/GroupFeedback";
import { FeedbackQuestionType, GroupFeedbackQuestion } from "../../../../models/GroupFeedbackQuestion";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupMotion } from "../../../../models/GroupMotion";
import { GroupOnlineStatus, GroupOnlineStatusCountTable } from "../../../../models/GroupOnlineStatus";
import { GroupServiceMode } from "../../../../models/GroupServiceMode";
import { CleaningOperation, GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { StandardBarChartSimpleColor } from "../../../charts/StandardBarChartSimpleColor";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { ChartHeightTextBox, GlobalTimeChoice, PrintButton, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { useQuery } from "../checkout/ShopPage";
import { CleaningTriggerDataTable } from "./cleaningTrigger/CleaningTriggerDataTable";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { BuildGroupFeedbackQuestionsSimple, FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE } from "./FeedbackDashboard";
import { OnlineStatusCountTable } from "./onlineStatus/OnlineStatusCountTable";
import { OnlineStatusDataTable } from "./onlineStatus/OnlineStatusDataTable";
import { BuildOnlineStatusSimpleChartDetails } from "./OnlineStatusDashboard";
import { buildSmileDescription } from "./qualityChecks/QualityChecksDashboard";
import { getServicesData, EvalApiType } from "./Services";
import { ObjectLeaderQualityCheckModel } from "../../../../models/ObjectLeaderQualityCheckModel";
import { HitListChartsQualityChecksAvgMarking, HitListChartsQualityChecksBestServices, HitListChartsQualityChecksCountMarking } from "./qualityChecks/HitListChartsQualityChecks";

export const PrintContext = React.createContext<any>({});

export const HitListDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);
    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);

    const { groupId } = useParams<{ groupId: string }>();
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();
    const history = useHistory();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const [isPrintingPage, setIsPrintingPage] = React.useState({ print: false, resolve: undefined });

    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [onlineStatusTableData, setOnlineStatusTableData] = React.useState<GroupOnlineStatus[]>([]);
    const [allQualityChecks, setAllQualityChecks] = React.useState<ObjectLeaderQualityCheckModel[]>([]);


    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());
    const hitlistRefOnlineStatusSimpleChart: any = useRef();
    const hitlistRefFrequencyCountSimpleChart: any = useRef()
    const hitlistRefServicesCountSimpleChart: any = useRef()
    const hitlistRefServicesPunctualCountSimpleChart: any = useRef()
    const hitlistRefFeedbackQuestionSimpleChart: any = useRef()
    const hitlistRefFeedbacksQualitySimpleChart: any = useRef()

    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);



    const globalTimeChoiceChildEvents = { doPrintingProcess: (token: string) => { } };

    const [allServiceMode, setAllServiceMode] = React.useState<GroupServiceMode[]>([]);
    const [groupMotions, setGroupMotions] = React.useState<GroupMotion[]>([]);
    const [allCleaningTasks, setAllCleaningTasks] = React.useState<CleaningOperation[]>([]);

    const queryString = useQuery(history);
    const doPrint = queryString.get("print");

    const [feedbackQuestionTableData, setFeedbackQuestionTableData] = React.useState<GroupFeedbackQuestion[]>([]);
    const [feedbacksTableData, setFeedbacksTableData] = React.useState<GroupFeedback[]>([]);

    const showCleaningTaskData = (servicesMode: GroupServiceMode[], cleaningTasks: CleaningOperation[]) => {
        setAllCleaningTasks(cleaningTasks);
    }



    const loadDataAsync = async () => {
        const token = getApiToken(history);
        if (!token) return;

        if (serviceDisplayType == EvalApiType.Objects){
            let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        if (serviceDisplayType == EvalApiType.Groups){
            let groupsPortal = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        if (serviceDisplayType == EvalApiType.ServiceWorkers){
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        
        setLoadingProgress(20);
        setValueBuffer(40);

        var promise2 = (async () => {

            const tempGroupFeedbacks = await getFeedbackData(groupId, token, groupIds);
            setFeedbacksTableData(tempGroupFeedbacks);
        })();

        await promise2;
        setLoadingProgress(40);
        setValueBuffer(60);


        var promise1 = (async () => {
            const tempGroupFeedbacksQuestions: GroupFeedbackQuestion[] = await getFeedbackQuestionsByUserGroupIds(groupId, token, groupIds);

            setFeedbackQuestionTableData(tempGroupFeedbacksQuestions.filter(x=>x.feedbackQuestionType == FeedbackQuestionType.Negative));
        })();

        await promise1;
        setLoadingProgress(60);
        setValueBuffer(80);


        let [motions, servicesMode, cleaningTasks] = await getServicesData(token, groupId, groupIds, selectedObjectId, selectedServiceWorkerId, setAllServiceMode, showCleaningTaskData);
        setGroupMotions(motions);

        setLoadingProgress(80);
        setValueBuffer(100);



        var promise3 = (async () => {

            let onlineStatus = await getOnlineStatusData(groupId, token, groupIds);
            setOnlineStatusTableData(onlineStatus);

            if (JSON.parse(doPrint || 'false')) {
                if (globalTimeChoiceChildEvents.doPrintingProcess) {
                    await globalTimeChoiceChildEvents.doPrintingProcess(token);

                }
            }
        })();

        await promise3;

        let qualityChecks = await getQualityChecksByUserIdGroupIds(groupId, token, groupIds)
        setAllQualityChecks(qualityChecks);


        setLoadingProgress(100);
        setApiDataLoading(false);

    }

    React.useEffect(()=>{
        setIsPreFilterDialogVisible(true);
    },[]);

    useNoInitialEffect(() => {
        setApiDataLoading(true);
        setLoadingProgress(0);
        setValueBuffer(20);
        setLoadingCompleted(false);
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
        (async () => {

            await loadDataAsync();
            setLoadingCompleted(true);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (onlineStatusTableData) {
            setStartDate(startDate);
            setEndDate(endDate);

        }
    }, [onlineStatusTableData, groupsLicenseTableEntries])

    const updateChosenPastDaysFromParent = (event: any) => {
    }

    const updateEndDateFromParent = (event: any) => {
        hitlistRefOnlineStatusSimpleChart.current.updateEndDateOnlineStatusSimpleChartData(event);
        hitlistRefServicesCountSimpleChart.current.updateEndDateServicesChartSimpleCount(event);
        hitlistRefServicesPunctualCountSimpleChart.current.updateEndDateServicesChartSimpleCount(event);

        hitlistRefFeedbackQuestionSimpleChart.current.updateEndDateFeedbackQuestionSimpleData(event);


        hitlistRefFeedbacksQualitySimpleChart.current.updateEndDateFeedbacksQualityChartSimpleCount(event);
        hitlistRefFrequencyCountSimpleChart.current.updateEndDateFrequenciesChartSimpleCount(event);
        setEndDate(event);
    }

    const updateStartDateFromParent = (event: any) => {
        hitlistRefOnlineStatusSimpleChart.current.updateStartDateOnlineStatusSimpleChartData(event);
        hitlistRefServicesCountSimpleChart.current.updateStartDateServicesSimpleChartCount(event);
        hitlistRefServicesPunctualCountSimpleChart.current.updateStartDateServicesSimpleChartCount(event);

        hitlistRefFeedbackQuestionSimpleChart.current.updateStartDateFeedbackQuestionSimpleData(event);


        hitlistRefFeedbacksQualitySimpleChart.current.updateStartDateFeedbacksQualitySimpleChartCount(event);
        hitlistRefFrequencyCountSimpleChart.current.updateStartDateFrequenciesSimpleChartCount(event);

        setStartDate(event);
    }

    const MyBuildSimpleChartServiceCountDetails = <BuildSimpleChartServiceCountDetails ref={hitlistRefServicesCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} serviceModesData={allServiceMode} />;


    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <div className="page-break-after">
                    <GroupTypePrintDisplay title={"Hitliste Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <div>
                        <GlobalTimeChoice
                            isPrintingPage={isPrintingPage}
                            setIsPrintingPage={setIsPrintingPage}
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={() => { }} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </div>
                </div>
                <div className="page-break-after">
                    <BuildSimpleChartFeedbacksAvgDetails ref={hitlistRefFeedbacksQualitySimpleChart} groupFeedbacksData={feedbacksTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </div>



                <div className="page-break-after">
                    <BuildGroupFeedbackQuestionsSimple title={FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE} ref={hitlistRefFeedbackQuestionSimpleChart} feedbackQuestionTableData={feedbackQuestionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </div>
                <div className="page-break-after">
                    <BuildSimpleChartFrequencyCountDetails ref={hitlistRefFrequencyCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} frequencyData={groupMotions} />
                </div>
                <div className="page-break-after">
                    <BuildSimpleChartServiceCountDetails ref={hitlistRefServicesCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} serviceModesData={allServiceMode} />
                </div>
                <div className="page-break-after">
                    <BuildSimpleChartServicePunctualCountDetails ref={hitlistRefServicesPunctualCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} serviceModesData={allServiceMode} />
                </div>
                <div className="page-break-after">
                    <BuildOnlineStatusSimpleChartDetails ref={hitlistRefOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} isPercentEnabled={true} />
                </div>

                <div className="page-break-after">
                    {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                        <Fragment>
                            <div className="page-break"></div>
                            <div>
                                <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                                <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        );
    }


    const buildGridContent = () => {
        return (
            <Grid container spacing={3} direction="row">
                <Grid item xs={12}>
                    <Typography variant="h4">Hitliste</Typography>
                </Grid>
                {!loadingCompleted &&
                    <Grid item xs={12}>
                        <CircularProgress size={64} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[]}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateDataFilterTypeFromParent={() => { }}
                        updateEndDateFromParent={updateEndDateFromParent}
                        updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildSimpleChartFeedbacksAvgDetails ref={hitlistRefFeedbacksQualitySimpleChart} groupFeedbacksData={feedbacksTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildGroupFeedbackQuestionsSimple title={FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE} ref={hitlistRefFeedbackQuestionSimpleChart} feedbackQuestionTableData={feedbackQuestionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildSimpleChartFrequencyCountDetails ref={hitlistRefFrequencyCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} frequencyData={groupMotions} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {MyBuildSimpleChartServiceCountDetails}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildSimpleChartServicePunctualCountDetails ref={hitlistRefServicesPunctualCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} serviceModesData={allServiceMode} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildOnlineStatusSimpleChartDetails ref={hitlistRefOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} isPercentEnabled={true} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography color={"primary"} fontSize={"40px"}>Hitliste Qualitätssicherung</Typography>
                </Grid>
                <Grid item xs={12}>
                    <HitListChartsQualityChecksCountMarking qualityChecksTableData={allQualityChecks} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <HitListChartsQualityChecksAvgMarking qualityChecksTableData={allQualityChecks} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <HitListChartsQualityChecksBestServices qualityChecksTableData={allQualityChecks} />
                </Grid>





            </Grid>
        );
    }

    const globalFields = {
        isPrintPage: isPrintPage,
        setIsPrintPage: setIsPrintPage,
        isPrintingPage: isPrintingPage,
        setIsPrintingPage: setIsPrintingPage
    }

    return (
        <PrintContext.Provider value={globalFields}>
            {isPrintPage ? buildPrintContent() : buildGridContent()}
        </PrintContext.Provider>
    )

}


export const BuildSimpleChartServiceCountDetails = React.forwardRef(({ serviceModesData, groupsLicenseTableEntries }: { serviceModesData: GroupServiceMode[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateServicesSimpleChartCount(date: Date) {
            updateStartDateServicesSimpleChartCount(date);
        },
        updateEndDateServicesChartSimpleCount(date: Date) {
            updateEndDateServicesChartSimpleCount(date);
        }

    }))

    const { maxChartItemCount }: { showStatus: boolean, setShowStatus: any, maxChartItemCount: number } = useContext(GlobalContext);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [simpleChatServices, setSimpleChartServices] = React.useState<any[]>([]);
    const [simpleChatServicesBad, setSimpleChartServicesBad] = React.useState<any[]>([]);

    const [startDateOnlineStatusSimpleChartData, setStartDateOnlineStatusSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateOnlineStatusSimpleChartData, setEndDateOnlineStatusSimpleChartData] = React.useState(new Date());

    const [simpleChartDataAvgAll, setSimpleChartDataAvgAll] = React.useState(0);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);

    const [filteredSimpleChartDataCount, setFilteredSimpleChartDataCount] = React.useState(0);

    const refPrint = React.useRef(null);

    React.useEffect(() => {
        updateEndDateServicesChartSimpleCount(endDateOnlineStatusSimpleChartData)
    }, [maxChartItemCount]);

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (serviceModesData) {
            setStartDateOnlineStatusSimpleChartData(startDate);
            setEndDateOnlineStatusSimpleChartData(endDate);
            updateServiceCountSimpleChart(startDate, endDate, serviceModesData);
        }
    }, [serviceModesData, groupsLicenseTableEntries])

    const updateStartDateServicesSimpleChartCount = (newVal: Date) => {
        if (!serviceModesData) return;
        setStartDateOnlineStatusSimpleChartData(newVal);
        updateServiceCountSimpleChart(newVal, endDateOnlineStatusSimpleChartData, serviceModesData);
    }

    const updateEndDateServicesChartSimpleCount = (newVal: Date) => {
        if (!serviceModesData) return;
        setEndDateOnlineStatusSimpleChartData(newVal);
        updateServiceCountSimpleChart(startDateOnlineStatusSimpleChartData, newVal, serviceModesData);
    }

    const updateServiceCountSimpleChart = (startDate: Date, endDate: Date, serviceMode: GroupServiceMode[]) => {
        if (startDate && endDate) {
            serviceMode = serviceMode.filter(x => {
                let curDate = new Date(x.createdTimestamp * 1000);
                return isAfter(curDate, startDate) && isBefore(curDate, endDate);
            });
        }

        let res = groupsLicenseTableEntries.reduce((prevVal, curVal: GroupLicenseTable) => {

            let sum = serviceMode.reduce((prevVal2, curVal2) => {
                if (curVal2.groupId == curVal.id) {
                    return prevVal2 + 1;
                }
                return prevVal2;
            }, 0);
            return [...prevVal, { argumentFieldService: curVal.notificationName, valueFieldService: sum, countService: sum }];
        }, [] as any[]);

        let resNormal = res.filter(x => x.valueFieldService > 0).sort((a, b) => a.valueFieldService - b.valueFieldService);
        setSimpleChartServices(resNormal.slice(-maxChartItemCount));
        setSimpleChartServicesBad(resNormal.slice(0, maxChartItemCount).reverse());


        setFilteredSimpleChartDataCount(resNormal.length);

        setSimpleChartDataAvgAll(getAvgFromSimpleChart(resNormal))
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormal.slice(-maxChartItemCount)))
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormal.slice(0, maxChartItemCount).reverse()))
    }

    const buildContent = () => {
        return (
            <Paper className="paper-small">
                <Grid container spacing={3} item xs={12} direction="column">
                    <Grid item>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} fontWeight="bold" variant="h4" color={"primary"}>Service Anzahl pro Einheit Gesamt Bericht</Typography>
                        <Typography className="avg-font" variant="h5">Durchschnitt von {filteredSimpleChartDataCount} Einheiten: <b>{simpleChartDataAvgAll} Services</b></Typography>
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Start Datum"}
                                value={startDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateServicesSimpleChartCount(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Ende Datum"}
                                value={endDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateServicesChartSimpleCount(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Services pro Einheit Absteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvg} Services</b></Typography>
                            </Grid>
                            <Grid item>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={" Services"} />
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Services pro Einheit Aufsteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvgBad} Services</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={" Services"} />
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Services pro Einheit Absteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`;
        const subTitleDesc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvg} Services</b>`

        const titleAsc = `<b>Services pro Einheit Aufsteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`
        const subTitleAsc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvgBad} Services</b>`

        return (
            <>
                <PrintButton title="Besucher pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={" Services"} title={titleDesc} subTitle={subTitleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={" Services"} title={titleAsc} subTitle={subTitleAsc} />
                    </div>
                </div>
            </>

        )
    }

    if (!serviceModesData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});

export const BuildSimpleChartServicePunctualCountDetails = React.forwardRef(({ serviceModesData, groupsLicenseTableEntries }: { serviceModesData: GroupServiceMode[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateServicesSimpleChartCount(date: Date) {
            updateStartDateServicesSimpleChartCount(date);
        },
        updateEndDateServicesChartSimpleCount(date: Date) {
            updateEndDateServicesChartSimpleCount(date);
        }

    }))

    const { maxChartItemCount }: { showStatus: boolean, setShowStatus: any, maxChartItemCount: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [simpleChatServices, setSimpleChartServices] = React.useState<any[]>([]);
    const [simpleChatServicesBad, setSimpleChartServicesBad] = React.useState<any[]>([]);

    const [startDateOnlineStatusSimpleChartData, setStartDateOnlineStatusSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateOnlineStatusSimpleChartData, setEndDateOnlineStatusSimpleChartData] = React.useState(new Date());


    const [simpleMissedChartDataAvgAll, setSimpleMissedChartDataAvgAll] = React.useState(0);
    const [simpleChartDataAvgAll, setSimpleChartDataAvgAll] = React.useState(0);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);

    const [filteredSimpleChartDataCount, setFilteredSimpleChartDataCount] = React.useState(0);
    const [filteredSimpleChartDataMissedCount, setFilteredSimpleChartDataMissedCount] = React.useState(0);

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    React.useEffect(() => {
        updateServiceCountSimpleChart(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData, serviceModesData)
    }, [maxChartItemCount])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (serviceModesData) {
            setStartDateOnlineStatusSimpleChartData(startDate);
            setEndDateOnlineStatusSimpleChartData(endDate);
            updateServiceCountSimpleChart(startDate, endDate, serviceModesData);
        }
    }, [serviceModesData, groupsLicenseTableEntries])

    const updateStartDateServicesSimpleChartCount = (newVal: Date) => {
        if (!serviceModesData) return;
        setStartDateOnlineStatusSimpleChartData(newVal);
        updateServiceCountSimpleChart(newVal, endDateOnlineStatusSimpleChartData, serviceModesData);
    }

    const updateEndDateServicesChartSimpleCount = (newVal: Date) => {
        if (!serviceModesData) return;
        setEndDateOnlineStatusSimpleChartData(newVal);
        updateServiceCountSimpleChart(startDateOnlineStatusSimpleChartData, newVal, serviceModesData);
    }

    const updateServiceCountSimpleChart = (startDate: Date, endDate: Date, serviceMode: GroupServiceMode[]) => {
        if (startDate && endDate) {
            serviceMode = serviceMode.filter(x => {
                let curDate = new Date(x.createdTimestamp * 1000);
                return isAfter(curDate, startDate) && isBefore(curDate, endDate);
            });
        }

        let resOnTime = groupsLicenseTableEntries.reduce((prevVal, curVal: GroupLicenseTable) => {

            let sum = serviceMode.filter(x => x.isOnTime).reduce((prevVal2, curVal2) => {
                if (curVal2.groupId == curVal.id) {
                    return prevVal2 + 1;
                }
                return prevVal2;
            }, 0);
            return [...prevVal, { argumentFieldService: curVal.notificationName, valueFieldService: sum, countService: sum }];
        }, [] as any[]);


        let resNotOnTime = groupsLicenseTableEntries.reduce((prevVal, curVal: GroupLicenseTable) => {

            let sum = serviceMode.filter(x => !x.isOnTime).reduce((prevVal2, curVal2) => {
                if (curVal2.groupId == curVal.id) {
                    return prevVal2 + 1;
                }
                return prevVal2;
            }, 0);
            return [...prevVal, { argumentFieldService: curVal.notificationName, valueFieldService: sum, countService: sum }];
        }, [] as any[]);

        let resNormalOnTime = resOnTime.filter(x => x.valueFieldService > 0).sort((a, b) => a.valueFieldService - b.valueFieldService);
        let resNormalNotOnTime = resNotOnTime.filter(x => x.valueFieldService > 0).sort((a, b) => a.valueFieldService - b.valueFieldService);

        setSimpleChartServices(resNormalOnTime.slice(-maxChartItemCount));
        setSimpleChartServicesBad(resNormalNotOnTime.slice(0, maxChartItemCount).reverse());


        setSimpleMissedChartDataAvgAll(getAvgFromSimpleChart(resNormalNotOnTime));
        setSimpleChartDataAvgAll(getAvgFromSimpleChart(resNormalOnTime))
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormalOnTime.slice(-maxChartItemCount)))
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormalNotOnTime.slice(0, maxChartItemCount).reverse()))

        setFilteredSimpleChartDataCount(resNormalOnTime.length);
        setFilteredSimpleChartDataMissedCount(resNormalNotOnTime.length);


    }

    const buildContent = () => {
        return (
            <Paper className="paper-small">
                <div>
                    <div>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} fontWeight="bold" variant="h4" color={"primary"}>Service Pünktlich/Verpasst pro Einheit Gesamt Bericht</Typography>
                        <Typography className="avg-font" variant="h5">Durchschnitt von {filteredSimpleChartDataCount} Einheiten: <b>{simpleChartDataAvgAll} Pünktlich</b></Typography>
                        <Typography className="avg-font" variant="h5">Durchschnitt von {filteredSimpleChartDataMissedCount} Einheiten: <b>{simpleMissedChartDataAvgAll} Verpasst</b></Typography>
                    </div>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Start Datum"}
                                value={startDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateServicesSimpleChartCount(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Ende Datum"}
                                value={endDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateServicesChartSimpleCount(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Services Pünktlich pro Einheit</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvg} Pünktlich</b></Typography>
                            </Grid>
                            <Grid item>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel=" Pünktlich" />
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Services Verpasst pro Einheit</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServicesBad.length} Einheiten: <b>{simpleChartDataAvgBad} Verpasst</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel=" Verpasst" />
                            </Grid>
                        </Grid>

                    </Grid>

                </div>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Services Pünktlich pro Einheit</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`;
        const subTitleDesc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvg} Pünktlich</b>`

        const titleAsc = `<b>Services Verpasst pro Einheit</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`
        const subTitleAsc = `Durchschnitt von ${simpleChatServicesBad.length} Einheiten: <b>${simpleChartDataAvgBad} Verpasst</b>`

        return (
            <>
                <PrintButton title="Services Pünktlich pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={" Pünktlich"} title={titleDesc} subTitle={subTitleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={" Verpasst"} title={titleAsc} subTitle={subTitleAsc} />
                    </div>
                </div>
            </>

        )
    }

    if (!serviceModesData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});

export const BuildSimpleChartFrequencyCountDetails = React.forwardRef(({ frequencyData, groupsLicenseTableEntries }: { frequencyData: GroupMotion[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateFrequenciesSimpleChartCount(date: Date) {
            updateStartDateFrequenciesSimpleChartCount(date);
        },
        updateEndDateFrequenciesChartSimpleCount(date: Date) {
            updateEndDateFrequenciesChartSimpleCount(date);
        }

    }))

    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [simpleChatServices, setSimpleChartServices] = React.useState<any[]>([]);
    const [simpleChatServicesBad, setSimpleChartServicesBad] = React.useState<any[]>([]);

    const [startDateOnlineStatusSimpleChartData, setStartDateOnlineStatusSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateOnlineStatusSimpleChartData, setEndDateOnlineStatusSimpleChartData] = React.useState(new Date());

    const [simpleChartDataAvgAll, setSimpleChartDataAvgAll] = React.useState(0);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);

    const [filteredSimpleChartDataCount, setFilteredSimpleChartDataCount] = React.useState(0);

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    React.useEffect(() => {
        updateServiceCountSimpleChart(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData, frequencyData)
    }, [maxChartItemCount])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (frequencyData) {
            setStartDateOnlineStatusSimpleChartData(startDate);
            setEndDateOnlineStatusSimpleChartData(endDate);
            updateServiceCountSimpleChart(startDate, endDate, frequencyData);
        }
    }, [frequencyData, groupsLicenseTableEntries])

    const updateStartDateFrequenciesSimpleChartCount = (newVal: Date) => {
        if (!frequencyData) return;
        setStartDateOnlineStatusSimpleChartData(newVal);
        updateServiceCountSimpleChart(newVal, endDateOnlineStatusSimpleChartData, frequencyData);
    }

    const updateEndDateFrequenciesChartSimpleCount = (newVal: Date) => {
        if (!frequencyData) return;
        setEndDateOnlineStatusSimpleChartData(newVal);
        updateServiceCountSimpleChart(startDateOnlineStatusSimpleChartData, newVal, frequencyData);
    }

    const updateServiceCountSimpleChart = (startDate: Date, endDate: Date, serviceMode: GroupMotion[]) => {
        if (startDate && endDate) {
            serviceMode = serviceMode.filter(x => {
                let curDate = new Date(x.createdTimestamp * 1000);
                return isAfter(curDate, startDate) && isBefore(curDate, endDate);
            });
        }

        let res = groupsLicenseTableEntries.reduce((prevVal, curVal: GroupLicenseTable) => {

            let sum = serviceMode.reduce((prevVal2, curVal2) => {
                if (curVal2.groupId == curVal.id) {
                    return prevVal2 + curVal2.blockCount;
                }
                return prevVal2;
            }, 0);
            if (sum < 1) return prevVal;
            return [...prevVal, { argumentFieldService: curVal.notificationName, valueFieldService: sum, countService: sum }];
        }, [] as any[]);

        let resNormal = res.filter(x => x.valueFieldService > 0).sort((a, b) => a.valueFieldService - b.valueFieldService);
        setSimpleChartServices(resNormal.slice(-maxChartItemCount));
        setSimpleChartServicesBad(resNormal.slice(0, maxChartItemCount).reverse());

        setFilteredSimpleChartDataCount(resNormal.length);
        setSimpleChartDataAvgAll(getAvgFromSimpleChart(resNormal))
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormal.slice(-maxChartItemCount)))
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormal.slice(0, maxChartItemCount).reverse()))
    }



    const buildContent = () => {
        return (
            <Paper className="paper-small">
                <div>
                    <Grid item>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} fontWeight="bold" variant="h4" color={"primary"}>Frequenz Anzahl pro Einheit Gesamt Bericht</Typography>
                        <Typography className="avg-font" variant="h5">Durchschnitt von {filteredSimpleChartDataCount} Einheiten: <b>{simpleChartDataAvgAll} Besucher</b></Typography>
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Start Datum"}
                                value={startDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateFrequenciesSimpleChartCount(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Ende Datum"}
                                value={endDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateFrequenciesChartSimpleCount(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}
                                
                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Besucher pro Einheit Absteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvg} Besucher</b></Typography>
                            </Grid>
                            <Grid item>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={" Besucher"} />
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Besucher pro Einheit Aufsteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvgBad} Besucher</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={" Besucher"} />
                            </Grid>
                        </Grid>

                    </Grid>

                </div>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Besucher pro Einheit Absteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`;
        const subTitleDesc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvg} Besucher</b>`

        const titleAsc = `<b>Besucher pro Einheit Aufsteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`
        const subTitleAsc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvgBad} Besucher</b>`

        return (
            <>
                <PrintButton title="Besucher pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={" Besucher"} title={titleDesc} subTitle={subTitleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={" Besucher"} title={titleAsc} subTitle={subTitleAsc} />
                    </div>
                </div>
            </>

        )
    }

    if (!frequencyData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});









export const BuildSimpleChartFeedbacksAvgDetails = React.forwardRef(({ groupFeedbacksData, groupsLicenseTableEntries }: { groupFeedbacksData: GroupFeedback[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateFeedbacksQualitySimpleChartCount(date: Date) {
            updateStartDateFeedbacksQualitySimpleChartCount(date);
        },
        updateEndDateFeedbacksQualityChartSimpleCount(date: Date) {
            updateEndDateFeedbacksQualityChartSimpleCount(date);
        }

    }))

    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [simpleChatServices, setSimpleChartServices] = React.useState<any[]>([]);
    const [simpleChatServicesBad, setSimpleChartServicesBad] = React.useState<any[]>([]);

    const [startDateOnlineStatusSimpleChartData, setStartDateOnlineStatusSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateOnlineStatusSimpleChartData, setEndDateOnlineStatusSimpleChartData] = React.useState(new Date());

    const [simpleChartDataAvgAll, setSimpleChartDataAvgAll] = React.useState(0);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);
    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);


    const [filteredSimpleChartDataCount, setFilteredSimpleChartDataCount] = React.useState(0);

    React.useEffect(() => {
        updateAverageFeedbacksQualitySimpleChart(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData, groupFeedbacksData)
    }, [maxChartItemCount])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (groupFeedbacksData) {
            setStartDateOnlineStatusSimpleChartData(startDate);
            setEndDateOnlineStatusSimpleChartData(endDate);
            updateAverageFeedbacksQualitySimpleChart(startDate, endDate, groupFeedbacksData);
        }
    }, [groupFeedbacksData, groupsLicenseTableEntries])

    const updateStartDateFeedbacksQualitySimpleChartCount = (newVal: Date) => {
        if (!groupFeedbacksData) return;
        setStartDateOnlineStatusSimpleChartData(newVal);
        updateAverageFeedbacksQualitySimpleChart(newVal, endDateOnlineStatusSimpleChartData, groupFeedbacksData);
    }

    const updateEndDateFeedbacksQualityChartSimpleCount = (newVal: Date) => {
        if (!groupFeedbacksData) return;
        setEndDateOnlineStatusSimpleChartData(newVal);
        updateAverageFeedbacksQualitySimpleChart(startDateOnlineStatusSimpleChartData, newVal, groupFeedbacksData);
    }

    const updateAverageFeedbacksQualitySimpleChart = (startDate: Date, endDate: Date, groupFeedbacksData: GroupFeedback[]) => {
        let groupFeedbacks = groupFeedbacksData.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);
        let res = groupsLicenseTableEntries.reduce((sumValue, newValue: GroupLicenseTable) => {
            let sum = 0;
            let count = 0;
            groupFeedbacks.forEach(item => {
                if (item.groupId == newValue.id) {
                    sum += getValueFromGroupFeedback(item);
                    count += 1;
                }
            });
            if (count == 0) return sumValue;
            return [...sumValue, { argumentFieldService: newValue.notificationName, valueFieldService: Math.round(sum / count), countService: count }];
        }, [] as any[]);
        res = res.sort((a, b) => a.valueFieldService - b.valueFieldService);
        setSimpleChartServices(res.slice(-maxChartItemCount));
        setSimpleChartServicesBad(res.slice(0, maxChartItemCount).reverse());

        setFilteredSimpleChartDataCount(res.length);
        setSimpleChartDataAvgAll(getAvgFromSimpleChart(res))
        setSimpleChartDataAvg(getAvgFromSimpleChart(res.slice(-maxChartItemCount)))
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(res.slice(0, maxChartItemCount).reverse()))
    }

    const buildContent = () => {
        return (
            <Paper className="paper-small">
                <Grid item container direction={"row"} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" fontWeight={'bold'} color={"primary"}>Feedbackqualität Gesamt Bericht</Typography>
                        <Typography className="avg-font" variant="h5">Durchschnitt von {filteredSimpleChartDataCount} Einheiten: <b>{simpleChartDataAvgAll}%</b></Typography>
                    </Grid>
                    <Grid item style={{ position: 'absolute', right: 30 }}>
                        {buildSmileDescription(30)}
                    </Grid>
                </Grid>
                <Grid item container direction="row" spacing={3} alignItems="center">

                    <Grid item>
                        < DateTimePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={"Start Datum"}
                            value={startDateOnlineStatusSimpleChartData}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateStartDateFeedbacksQualitySimpleChartCount(newVal);
                            }}
                            format={"dd.MM.yyyy HH:mm"}
                            
                        />
                    </Grid>
                    <Grid item>
                        < DateTimePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={"Ende Datum"}
                            value={endDateOnlineStatusSimpleChartData}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateEndDateFeedbacksQualityChartSimpleCount(newVal);
                            }}
                            format={"dd.MM.yyyy HH:mm"}
                            
                        />
                    </Grid>
                    <Grid item>
                        <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                    </Grid>
                    <MaxChartItemCountInput />
                </Grid>
                <Grid item container direction="row" spacing={3} alignItems="center">
                    <Grid container direction={"column"} spacing={3} item xs={6}>
                        <Grid item>
                            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Feedbackqualität pro Einheit Absteigend</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvg}%</b></Typography>
                        </Grid>
                        <Grid item>
                            <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={"%"} />
                        </Grid>
                    </Grid>
                    <Grid container direction={"column"} spacing={3} item xs={6}>
                        <Grid item>
                            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Feedbackqualität pro Einheit Aufsteigend</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className="avg-font" variant="h5">Durchschnitt von {simpleChatServices.length} Einheiten: <b>{simpleChartDataAvgBad}%</b></Typography>

                        </Grid>
                        <Grid item>
                            <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={"%"} />
                        </Grid>
                    </Grid>

                </Grid>

            </Paper>
        );
    }

    const buildPrintContent = () => {
        const titleDesc = `<b>Feedback Qualität pro Einheit Absteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`;
        const subTitleDesc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvg}%</b>`

        const titleAsc = `<b>Feedback Qualität pro Einheit Absteigend Aufsteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData,endDateOnlineStatusSimpleChartData)}`
        const subTitleAsc = `Durchschnitt von ${simpleChatServices.length} Einheiten: <b>${simpleChartDataAvgBad}%</b>`

        return (
            <>
                <PrintButton title="Feedback Qualität pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={simpleChatServices} name={"Services pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} extraLabel={" Feedbacks"} title={titleDesc} subTitle={subTitleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={simpleChatServicesBad} name={"Services pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} extraLabel={" Feedbacks"} title={titleAsc} subTitle={subTitleAsc} />
                    </div>
                </div>
            </>

        )
    }

    if (!simpleChatServices) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )


});