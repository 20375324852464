import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Check, Close, Delete, FilterAlt, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { PropertyObjectSelectionDataTable } from '../entireEval/PropertyObjectSelectionDataTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';

export const ObjectPropertyDialog = ({
  open,
  setOpen,
  objects,
  properties,
  selectedProperties,
  setSelectedProperties
}: {
  open: boolean,
  setOpen: any,
  objects: ObjectUnit[],
  properties: UserProperty[],
  selectedProperties: UserProperty[],
  setSelectedProperties: any
}) => {

  const handleClose = () => {
    setOpen(false);
  }

  const disabled = selectedProperties.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Zuweisung des Objekts auf Merkmalen</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <PropertyObjectSelectionDataTable rows={properties} objects={objects} selectedProperties={selectedProperties}
          onSelectionChange={(selection: string[]) => {
            setSelectedProperties(properties.filter(x => selection.some(y => x.id == y)));
          }}
          currentGroup={null} />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          size="large"
          endIcon={<Check />}
          onClick={async () => {
            handleClose();
          }}
        >
          Übernehemnen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));