import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DataFilterType, PeriodChartFilterType, generateChartData, generateChartDataWMissingDay, generateDisplayDate, filterFrom, fillGap } from '../../helpers/charts/base';
import { InlineDateTimePicker } from './InlineDateTimePicker';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { defaultEndDate, defaultPastDays, defaultPeriodChartFilterType, defaultStartDate } from '../../helpers/date-management';
import DataFilterTypeChoiceDialog from '../dialogs/DataFilterTypeChoiceDialog';
import { getCountNumber, getHowManyVisitorNeedOneMaterial, getHundredVisitorNeedHowManyMaterials, getProportion, getRatioNumber } from '../../helpers/common';
import { GlobalContext } from '../authorized/PortalPage';
import { addDays } from 'date-fns';
import { Bolt, History } from "@mui/icons-material";

export const TimeChoiceNextGeneration = forwardRef(({ data, setChartData, fields, chartCalcType = ChartCalcType.Sum, dateDisplayType = defaultPeriodChartFilterType }: any, ref: any) => {
  useImperativeHandle(
    ref,
    () => ({
      updateEndDateFromParent(date: any) {
        setSelectedEndDate(date);
      },
      updateStartDateFromParent(date: any) {
        setSelectedStartDate(date);
      },
      updateDataFilterTypeFromParent(dataFilterType: any) {
        setDataFilterType(dataFilterType);
      },
      updateChosenPastDays(days: any) {
        setChosenPastDays(parseInt(days));
      },
      renderData() {
        setTimeBasedChartData(dataFilterType, isInitialMount, periodType, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData, chartCalcType);
      },
      renderDataWithDisplayDateType(value: PeriodChartFilterType) {
        setTimeBasedChartData(dataFilterType, isInitialMount, value, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData, chartCalcType);
        setPeriodType(value);
      }
    }),
  )

  const { periodChartFilter, setPeriodChartFilter }: { periodChartFilter: PeriodChartFilterType, setPeriodChartFilter: any } = React.useContext(GlobalContext);
  const [periodType, setPeriodType] = React.useState(dateDisplayType);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [fromDate, setFromDate] = React.useState(defaultEndDate);


  const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
  const isInitialMount = useRef(true);
  const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);

  React.useEffect(() => {
    setTimeBasedChartData(dataFilterType, isInitialMount, periodType, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData, chartCalcType);
  }, [periodType, selectedEndDate, selectedStartDate, chosenPastDays, dataFilterType]);

  React.useEffect(() => {
    handleChange({ target: { value: periodChartFilter } });
  }, [periodChartFilter])


  const handleChange = (event: any) => {
    setPeriodType(event.target.value);
    if (event.target.value === PeriodChartFilterType.Hour) {
      setChosenPastDays(1);
    }
    else if (event.target.value === PeriodChartFilterType.Day) {
      setChosenPastDays(10)
    }
    else if (event.target.value === PeriodChartFilterType.Week) {
      setChosenPastDays(30);
    }
    else if (event.target.value === PeriodChartFilterType.Month) {
      setChosenPastDays(90);
    }

  };

  const buildFields = () => {
    switch (dataFilterType) {
      case DataFilterType.StartEnd:
        return (
          <Grid container item direction={'row'} spacing={2}>
            <Grid item>
              <InlineDateTimePicker title={"Start Datum"} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
          </Grid>
        );
      case DataFilterType.EndDatePastDays:
        return (
          <Grid container item xs={12} direction={'row'}>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 100, marginLeft: 30 }}
                inputProps={{ min: 1, style: {} }}
                id="filled-number"
                label="Tage zurück"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={chosenPastDays}
                onChange={(event) => { setChosenPastDays(parseInt(event.target.value)) }}
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <Grid container spacing={2} style={{ padding: 10, paddingBottom: 10 }}>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Zeitauswahl</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={periodType} onChange={handleChange} row>
            <FormControlLabel value={PeriodChartFilterType.Hour} control={<Radio />} label="Stündlich" />
            <FormControlLabel value={PeriodChartFilterType.Day} control={<Radio />} label="Täglich" />
            <FormControlLabel value={PeriodChartFilterType.Week} control={<Radio />} label="Wöchentlich" />
            <FormControlLabel value={PeriodChartFilterType.Month} control={<Radio />} label="Monatlich" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType} />
      {buildFields()}
      <Grid item xs={12} container direction="row" spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={'bold'} fontSize={32}>Ansicht Tages/Wochen/Monat</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<Bolt />}
            onClick={() => {
              setFromDate(new Date());
            }}
          >
            Jetzt
          </Button>
        </Grid>
        <Grid item>
          <InlineDateTimePicker title={"Von Datum"} selectedDate={fromDate} setSelectedDate={setFromDate}></InlineDateTimePicker>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<History />}
            onClick={() => {
              let startDate = addDays(fromDate, -1);
              setSelectedEndDate(fromDate);
              setSelectedStartDate(startDate);
              setPeriodType(PeriodChartFilterType.Hour);
            }}
          >
            letzter Tag
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<History />}
            onClick={() => {
              let startDate = addDays(fromDate, -7);
              setSelectedEndDate(fromDate);
              setSelectedStartDate(startDate);
              setPeriodType(PeriodChartFilterType.Day);
            }}
          >
            letzte Woche
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<History />}
            onClick={() => {
              let startDate = addDays(fromDate, -30);
              setSelectedEndDate(fromDate);
              setSelectedStartDate(startDate);
              setPeriodType(PeriodChartFilterType.Week);
            }}
          >
            letztes Monat
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<History />}
            onClick={() => {
              let startDate = addDays(fromDate, -365);
              setSelectedEndDate(fromDate);
              setSelectedStartDate(startDate);
              setPeriodType(PeriodChartFilterType.Month);
            }}
          >
            letztes Jahr
          </Button>
        </Grid>
      </Grid>
    </Grid >
  );
});

export enum ChartCalcType {
  "Sum" = 0,
  "IncreaseSum" = 1,
  "RatioMotionFeedback" = 2,
  "RatioMotionMaterialConsumption" = 3,
  "BySensorId" = 4,
  "CumulativeBySensorId" = 5,
  "CountMotionMaterialConsumption" = 6,
  "AverageQualityCheckServices" = 7,
  "Nothing" = 255,
}

export const setTimeBasedChartData = (dataFilterType: DataFilterType, isInitialMount: any, periodChartFilterType: PeriodChartFilterType, selectedEndDate: Date, selectedStartDate: Date, data: any, fields: any, chosenPastDays: any, setChartData: any, chartCalcType: ChartCalcType = ChartCalcType.Sum) => {
  let newChartFilteredDate = filterFrom(data, periodChartFilterType, dataFilterType, selectedEndDate, selectedStartDate, chosenPastDays);
  newChartFilteredDate = generateDisplayDate(newChartFilteredDate, periodChartFilterType) || [];
  newChartFilteredDate = fillGap(selectedStartDate, selectedEndDate, newChartFilteredDate, periodChartFilterType, ["extraFillGap"])
  setChartData(newChartFilteredDate);

}