
export enum ServiceWorkerType {
    LoginPhoneNumber = 0,
    LoginUsername = 1,
    ObjectLeader = 2,
    ServiceWorkerPlus = 3,
    SuperServiceWorkerPlus = 4,
    IncidentMessenger = 5,
}

export class UserServiceWorker {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    public userId!: string;
    public name!: string;
    public nfcCode!: string;
    public taqtNfcCode: string = "";
    public phoneNumber !: string;
    public isEnabled!: boolean;
    public creationDate !: string;
    public serviceWorkerSignedIntoObject !: ServiceWorkerSignedIntoObject;
    public type !: ServiceWorkerType;
    public loginUsername !: string;
    public activationCode !: string;
    public email !: string;
    public isQmNotificationEnabled !: boolean;
    public autoLogoutObjectMinutes !: number;
    constructor(name: string, nfcCode: string, phoneNumber: string, isEnabled: boolean, loginUsername: string, type: ServiceWorkerType, email: string, taqtNfcCode: string, isQmNotificationEnabled: boolean, autoLogoutObjectMinutes: number) {
        this.name = name;
        this.nfcCode = nfcCode;
        this.phoneNumber = phoneNumber;
        this.isEnabled = isEnabled;
        this.loginUsername = loginUsername;
        this.type = type;
        this.email = email;
        this.taqtNfcCode = taqtNfcCode;
        this.isQmNotificationEnabled = isQmNotificationEnabled;
        this.autoLogoutObjectMinutes = autoLogoutObjectMinutes;

    }
    static CreateUserServiceWorker = () => {
        const object = new UserServiceWorker("", "", "", true, "", ServiceWorkerType.LoginPhoneNumber, "", "", false, 0);
        object.isEnabled = true;
        return object;
    }
    static isLeader(type: ServiceWorkerType | undefined) {
        return type == ServiceWorkerType.ObjectLeader || type == ServiceWorkerType.ServiceWorkerPlus || type == ServiceWorkerType.SuperServiceWorkerPlus;
    }
    static GetLabel = (serviceWorkerType: ServiceWorkerType | undefined) => {
        switch (serviceWorkerType) {
            case ServiceWorkerType.LoginPhoneNumber:
                return "Servicekraft (Tel. Login)"
            case ServiceWorkerType.LoginUsername:
                return "Servicekraft (User Login)"
            case ServiceWorkerType.ObjectLeader:
                return "Objektleiter"
            case ServiceWorkerType.ServiceWorkerPlus:
                return "Servicekraft Plus"
            case ServiceWorkerType.SuperServiceWorkerPlus:
                return "Objektleiter Plus"
            case ServiceWorkerType.IncidentMessenger:
                return "Vorfallmelder (User Login)"
            default:
                return "lokal";
        }
    }
    static Columns = {
        id: 'id',
        name: "name",
        nfcCode: 'nfcCode',
        phoneNumber: 'phoneNumber',
        isEnabled: 'isEnabled',
        creationDate: "creationDate",
        signedInObject: "signedInObject",
        signedInObjectSince: "signedInObjectSince",
        actions: 'actions',
        actions2: 'actions2',
        groups: 'groups',
        devices: "devices",
        assign: "assign",
        loginUsername: "loginUsername",
        type: "type",
        email: 'email',
        assignedObjectLeadersServiceDoneEmail: 'assignedObjectLeadersServiceDoneEmail',
        assignedCleaningTrigger: "assignedCleaningTrigger",
        assignedIncidentTrigger: "assignedIncidentTrigger",
        assignedMissedTrigger: "assignedMissedTrigger",
        taqtNfcCode: 'taqtNfcCode',
        autoLogoutObjectMinutes: "autoLogoutObjectMinutes"


    }

    static SessionColumns = {
        id: 'id',
        object: "object",
        serviceWorker: 'serviceWorker',
        phoneNumber: 'phoneNumber',
        signedInObjectSinceTimestamp: 'signedInObjectSinceTimestamp',
        signedInObjectSinceTime: 'signedInObjectSinceTime',
        signedOutOfObjectSinceTimestamp: "signedOutOfObjectSinceTimestamp",
        signedOutOfObjectSinceTime: "signedOutOfObjectSinceTime",
        duration: 'duration',
        durationMinutes: 'durationMinutes',
        durationHours: 'durationHours'
    }
}

export class ServiceWorkerSignedIntoObject {
    public signedInObjectId !: string;
    public signedInObjectSinceTimestamp !: string;
}