import React, { useCallback, useRef, useState } from 'react';
import { Column, CustomSummary, DataTypeProvider, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupFeedbackQuestion } from '../../../../../models/GroupFeedbackQuestion';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanDateSec, formatEuropeanDateTime, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray, useAccordionForStringArrayStyles } from '../../../../common/AccordionForStringArray';
import { GroupIncidentVisitor } from '../../../../../models/GroupIncidentVisitor';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { eachDayOfInterval, eachHourOfInterval, format, fromUnixTime, getHours, getUnixTime, isAfter, isBefore, isSameDay, isSameHour, parse } from 'date-fns';
import { lightGreen, red } from '@mui/material/colors';
import { LoraGroupSummaryMotionHitlist } from '../../../../../models/LoraGroupMotion';
import { CurrencyFormatterMinutes } from './AnalyticGroupsSummaryLoraWeeklyCalendarDataTable';
import { Sorting } from 'devextreme-react/data-grid';
import { de } from 'date-fns/locale';
import { compareDatesSortingEuropeanDate, compareDatesSortingEuropeanDateTime, compareDatesSortingEuropeanHours } from '../../../../../helpers/devexpressTableExtension';
import { maxContentWrapperPortalStyle, standardHeightVirtualTable } from '../../../PortalPage';


const CurrencyTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatterMinutes}
        {...props}
    />
);

export const AnalyticSummarySingleValuesLoraDailyDataTable = ({ rows, startDate, endDate, onSelectionChange, virtualTableHeight }
    : { rows: LoraGroupSummaryMotionHitlist[], startDate: Date, endDate: Date, onSelectionChange: any, virtualTableHeight: number }) => {

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const classes = useAccordionForStringArrayStyles();
    const [columnExtension, setColumnExtension] = React.useState<any[]>([]);

    const [totalSummaryItems, setTotalSummaryItems] = useState<any[]>([]);
    const [currencyColumns, setCurrencyColumns] = useState<any[]>([]);
    const [sorting, setSorting] = useState<any[]>([{ columnName: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmount, direction: 'desc' }]);
    const [integratedSortingColumnExtensions] = useState([
        { columnName: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDailyDate, compare: compareDatesSortingEuropeanDate },
        { columnName: LoraGroupSummaryMotionHitlist.Columns.maxMotionDate, compare: compareDatesSortingEuropeanDateTime },
        { columnName: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourlyDate, compare: compareDatesSortingEuropeanHours },
    ]);

    React.useEffect(() => {

        let dateColumns = [
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmount,
                title: "Summe",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return row.totalMotionAmount;
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.avgMotionAmount,
                title: "Durchschnitt",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return row.avgMotionAmount;
                }
            }
            ,
            {
                name: LoraGroupSummaryMotionHitlist.Columns.maxMotionAmount,
                title: "Maximaleauslastung",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return row.maxMotionAmount;
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.maxMotionDate,
                title: "Max Datum",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return formatEuropeanDateTime(row.maxMotionDate);
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourly,
                title: "Höchste Stundensumme",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return row.totalHourlyMax.total;
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourlyDate,
                title: "(H) Zeitpunkt",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return format(row.totalHourlyMax.day,'HH') + ":00";
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekday,
                title: "Höchste Wochentagssumme",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return row.totalWeekdayMax.total;
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekdayDate,
                title: "(Tag)",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return format(row.totalWeekdayMax.day, 'EEEE', {locale: de});
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDaily,
                title: "Höchste Tagessume",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return row.totalDailyMax.total;
                }
            },
            {
                name: LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDailyDate,
                title: "(Datum)",
                getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                    return formatEuropeanDate(row.totalDailyMax.day);
                }
            },
        ]
        let onlyDateColumns = [...dateColumns];
        setColumnExtension(onlyDateColumns.map(x => ({ columnName: x.name, align: 'center' })));
        setOnlyDateColumns(onlyDateColumns);


        let sumEntry = {
            name: LoraGroupSummaryMotionHitlist.Columns.groupNotificationName,
            title: "Einheit Name",
            getCellValue: (row: LoraGroupSummaryMotionHitlist) => {
                return row.notificationName;
            },
        };


        dateColumns = [sumEntry as any, ...dateColumns];

        let dateColumnsWidth = dateColumns.map(el => {
            if (el.name == LoraGroupSummaryMotionHitlist.Columns.groupNotificationName || el.name == LoraGroupSummaryMotionHitlist.Columns.sum ||
                 el.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDaily || el.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourly || el.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekday) {
                return { columnName: el.name, width: 250 } as TableColumnWidthInfo;
            }
            return { columnName: el.name, width: 180 } as TableColumnWidthInfo;
        });


        let loraGroupColumnsTotal = dateColumns.filter(x =>
            !(x.name == LoraGroupSummaryMotionHitlist.Columns.groupNotificationName || x.name == LoraGroupSummaryMotionHitlist.Columns.maxMotionDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDailyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourlyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekdayDate))
            .map(el => {
                return { columnName: el.name, type: "sum" };
            });

        let loraGroupColumnsMax = dateColumns.filter(x =>
            !(x.name == LoraGroupSummaryMotionHitlist.Columns.groupNotificationName || x.name == LoraGroupSummaryMotionHitlist.Columns.maxMotionDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDailyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourlyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekdayDate))
            .map(el => {
                return { columnName: el.name, type: "max" };
            });

        let loraGroupColumnsAvg = dateColumns.filter(x =>
            !(x.name == LoraGroupSummaryMotionHitlist.Columns.groupNotificationName || x.name == LoraGroupSummaryMotionHitlist.Columns.maxMotionDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDailyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourlyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekdayDate))
            .map(el => {
                return { columnName: el.name, type: "avg" };
            });


        let totalColumns = [...loraGroupColumnsTotal, ...loraGroupColumnsMax, ...loraGroupColumnsAvg];


        setColumns([...dateColumns]);
        setColumnWidths([...dateColumnsWidth]);
        setTotalSummaryItems(totalColumns);
        setCurrencyColumns(totalColumns.map(x => x.columnName));
    }, [startDate, endDate, rows])


    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: LoraGroupSummaryMotionHitlist, value: any } = props;
        if (onlyDateColumns.some(x => x.name == LoraGroupSummaryMotionHitlist.Columns.maxMotionDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxDailyDate 
            || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxHourlyDate || x.name == LoraGroupSummaryMotionHitlist.Columns.totalMotionAmountMaxWeekdayDate)) {
            <Table.Cell style={{ textAlign: 'center' }} {...props}>
                <Typography>{value}</Typography>
            </Table.Cell>
        }
        else if (onlyDateColumns.some(x => x.name == column.name)) {
            return (
                <Table.Cell style={{ textAlign: 'center', backgroundColor: value == 0 ? red[100] : lightGreen[100] }} {...props}>
                    <Typography>{value} min</Typography>
                </Table.Cell>
            )
        }

        return <Table.Cell {...props} />;
    };


    let [columns, setColumns] = React.useState<Column[]>([]);

    let [onlyDateColumns, setOnlyDateColumns] = React.useState<Column[]>([]);

    let [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>([]);

    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Leistung - LoraMotionDaily Kalender');
        });
    };


    return (
        <div style={maxContentWrapperPortalStyle}>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
                <IntegratedSorting
                    columnExtensions={integratedSortingColumnExtensions}
                />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CurrencyTypeProvider
                    for={currencyColumns}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <IntegratedSummary />

                <VirtualTable
                    columnExtensions={columnExtension}
                    height={"auto"}
                    cellComponent={Cell}
                />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableSummaryRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
                totalSummaryItems={totalSummaryItems}
            />
        </div>
    );
};
