import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { CleaningTriggerExtraServices, GroupCleaningTrigger } from '../../../../../models/GroupCleaningTrigger';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { getDefaultIcon } from '../../../../dialogs/DefaultItemsDialogOld';

export const CleaningTriggerDataTable = ({ rows, groups  }
    : { rows: GroupCleaningTrigger[], groups: GroupLicenseTable[] }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === GroupCleaningTrigger.Columns.extraServices) {
            let names : string[] = row.extraServices.map((x: CleaningTriggerExtraServices)=> `${x.text} | ${x.isDone ? "Erledigt" : "Nicht Erledigt"}`);
            return buildCellStringArray(props, names, `Enthält ${names.length} Extra-Services`, null, names.map(x=> getDefaultIcon(x || '')));
        }
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: GroupCleaningTrigger.Columns.id,
            title: "Id",
            getCellValue: (row: GroupCleaningTrigger) => row.id,
        },
        {
            name: GroupCleaningTrigger.Columns.groupId,
            title: "Aktueller Einheitname",
            getCellValue: (row: GroupCleaningTrigger) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupCleaningTrigger.Columns.cleaningTriggerType,
            title: "Auslösungsart",
            getCellValue: (row: GroupCleaningTrigger) => GroupCleaningTrigger.GetLabelByType(row.cleaningTriggerType),
        },
        {
            name: GroupCleaningTrigger.Columns.createdTimestamp,
            title: "Erstell Datum",
            getCellValue: (row: GroupCleaningTrigger) => formatEuropeanDate(row.createdTimestamp),
        },
        {
            name: GroupCleaningTrigger.Columns.createdTimestampOnlyTime,
            title: "Erstell Zeit",
            getCellValue: (row: GroupCleaningTrigger) => formatEuropeanTime(row.createdTimestamp),
        },
        {
            name: GroupCleaningTrigger.Columns.isServiceStillWithinTime,
            title: "Innerhalb Reaktionszeit",
            getCellValue: (row: GroupCleaningTrigger) => row.isServiceStillWithinTime ? "Innerhalb (gelb)" : "Auslösung abgelaufen",
        },
        {
            name: GroupCleaningTrigger.Columns.isServiceDone,
            title: "Erledigt",
            getCellValue: (row: GroupCleaningTrigger) => row.isServiceDone ? "Erledigt" : "Nicht Erledigt (rot)",
        },
        {
            name: GroupCleaningTrigger.Columns.reactionTime,
            title: "Reaktionszeit",
            getCellValue: (row: GroupCleaningTrigger) => `${row.reactionTime} Minuten`,
        },
        {
            name: GroupCleaningTrigger.Columns.roomName,
            title: "Raumname",
            getCellValue: (row: GroupCleaningTrigger) => row.roomName,
        },
        {
            name: GroupCleaningTrigger.Columns.notificationName,
            title: "Einheitname am Eintragungszeitpunkt",
            getCellValue: (row: GroupCleaningTrigger) => row.notificationName,
        },
        {
            name: GroupCleaningTrigger.Columns.worksOnServiceWorkerId,
            title: "Servicekraft bearbeitet",
            getCellValue: (row: GroupCleaningTrigger) => row.worksOnServiceWorkerId,
        },
        {
            name: GroupCleaningTrigger.Columns.extraServices,
            title: "Extra Services zu erledigen",
            getCellValue: (row: GroupCleaningTrigger) => row.extraServices && row.extraServices.map((x: CleaningTriggerExtraServices)=> `${x.text} | ${x.isDone ? "Erledigt" : "Nicht Erledigt"}`).join(','),
        }

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { 
        if (el.name == GroupCleaningTrigger.Columns.extraServices){
            return { columnName: el.name, width: 350 }
        }
        return { columnName: el.name, width: 180 }
     }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Serviceauslösung');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
          //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[GroupCleaningTrigger.Columns.id]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
