import { Downloading, ExpandMore, Height } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Divider, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { differenceInMinutes, isAfter, isBefore, subDays } from "date-fns";
import React, { Fragment, useContext, useImperativeHandle, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { DataFilterType, PeriodChartFilterType, generateChartData, getDataFromGroupIdsTrigger, APIDataAmountType, getOnlineStatusData } from "../../../../helpers/charts/base";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { getAvgFromSimpleChart, getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../helpers/compontents";
import { formatDateRange, formatEuropeanDateTime, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage, getUnixMillisec } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupOnlineStatus, GroupOnlineStatusCountTable } from "../../../../models/GroupOnlineStatus";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { StandardBarChartSimpleColor } from "../../../charts/StandardBarChartSimpleColor";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { ChartHeightTextBox, GlobalTimeChoice, PrintButton, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { useQuery } from "../checkout/ShopPage";
import { CleaningTriggerDataTable } from "./cleaningTrigger/CleaningTriggerDataTable";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { PrintContext } from "./HitListDashboard";
import { OnlineStatusCountTable } from "./onlineStatus/OnlineStatusCountTable";
import { OnlineStatusDataTable } from "./onlineStatus/OnlineStatusDataTable";
import { EvalApiType } from "./Services";
import { GroupType } from "../../../../models/Group";

export const OnlineStatusDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds, onlineStatuse, groups }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any, onlineStatuse?: any[], groups?: any[] }) => {
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [loadingCompletedGroups, setLoadingCompletedGroups] = React.useState(false);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);
    const [onlineStatusTableData, setOnlineStatusTableData] = React.useState<GroupOnlineStatus[]>([]);
    const [groupsOnlineStatusCountTable, setGroupsOnlineStatusCountTable] = React.useState<GroupOnlineStatusCountTable[]>([]);

    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());
    const refOnlineStatusSimpleChart: any = useRef();

    const [isPrintingPage, setIsPrintingPage] = React.useState({ print: false, resolve: undefined });



    const globalTimeChoiceChildEventsPrintTemplate = { doPrintingProcess: (token: string) => { } };
    const globalTimeChoiceChildEvents = { doPrintingProcess: (token: string) => { } };

    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);

    const queryString = useQuery(history);
    const doPrint = queryString.get("print");
    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();



    const loadDataAsync = async () => {
        setApiDataLoading(true);
        setLoadingCompleted(false);
        const token = getApiToken(history);
        if (!token) return;

        if (serviceDisplayType == EvalApiType.Objects) {
            let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        } else if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        } else {
            let groupsPortal = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        setLoadingCompletedGroups(true);
        let temp = null;
        if (onlineStatuse) {
            temp = onlineStatuse
        } else {
            temp = await getOnlineStatusData(groupId, token, groupIds);

        }

        let tempCountTable = reloadServiceCount(temp, startDate, endDate);
        setGroupsOnlineStatusCountTable(tempCountTable);
        setOnlineStatusTableData(temp);

        setLoadingCompleted(true);
        setApiDataLoading(false);
        if (JSON.parse(doPrint || 'false')) {
            if (globalTimeChoiceChildEvents.doPrintingProcess) {
                await globalTimeChoiceChildEvents.doPrintingProcess(token);

            }
        }
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
    }

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, [])

    useNoInitialEffect(() => {
        loadDataAsync();

    }, [apiDataAmountType, reloadDataFromApi]);

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (onlineStatusTableData) {
            let temp = reloadServiceCount(onlineStatusTableData, startDate, endDate);
            setGroupsOnlineStatusCountTable(temp);
            setStartDate(startDate);
            setEndDate(endDate);

        }
    }, [onlineStatusTableData, groupsLicenseTableEntries])

    const updateChosenPastDaysFromParent = (event: any) => {
    }

    const updateEndDateFromParent = (event: any) => {
        refOnlineStatusSimpleChart.current.updateEndDateOnlineStatusSimpleChartData(event);
        setEndDate(event);
        let temp = reloadServiceCount(onlineStatusTableData, startDate, event);
        setGroupsOnlineStatusCountTable(temp);
    }

    const updateStartDateFromParent = (event: any) => {
        refOnlineStatusSimpleChart.current.updateStartDateOnlineStatusSimpleChartData(event);
        setStartDate(event);
        let temp = reloadServiceCount(onlineStatusTableData, event, endDate);
        setGroupsOnlineStatusCountTable(temp);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
    }

    const reloadServiceCount = (groupOnlineStatus: GroupOnlineStatus[], beginDate: Date | null = null, endDate: Date | null = null, offlineData = false) => {
        let countMax = 0;
        let allGroupOnlineStatus = [...groupOnlineStatus];
        if (beginDate && endDate) {
            groupOnlineStatus = groupOnlineStatus.filter(x => {
                let curDate = new Date(x.createdTimestamp);
                return isAfter(curDate, beginDate) && isBefore(curDate, endDate);
            });
            countMax = differenceInMinutes(endDate, beginDate) / 15;
        }
        let ret = groupOnlineStatus.reduce((prevVal: GroupOnlineStatusCountTable[], curVal: GroupOnlineStatus) => {
            let valExist = prevVal.find(x => x.groupId == curVal.groupId);
            if (valExist) {
                valExist.onlineCount++;
            } else {
                let onlineCount = 1;
                let group = groupsLicenseTableEntries.find(x => x.id == curVal.groupId);
                prevVal.push(new GroupOnlineStatusCountTable(curVal.groupId, curVal.userId, onlineCount, countMax, new Date(curVal.createdTimestamp), curVal.batteryLevel, group?.uniqueDeviceId || "", group?.sensors?.map(x => x.sensorName)?.join(', ') || ""));
            }
            return prevVal
        }, []);

        for (let onlineStatus of allGroupOnlineStatus) {
            let valExist = ret.find(x => x.groupId == onlineStatus.groupId);
            if (valExist) {
                let curDate = new Date(onlineStatus.createdTimestamp);
                if (isBefore(valExist.lastOnlineTimestamp, curDate)) {
                    valExist.lastOnlineTimestamp = curDate;
                }
            }
        }
        if (offlineData) {

            groupsLicenseTableEntries.filter(x=>GroupLicenseTable.isDeivce(x)).forEach(group => {
                if (!ret.some(x => x.groupId == group.id)) {
                    ret.push(new GroupOnlineStatusCountTable(group.id, group.userId, 0, countMax, subDays(new Date(), 30), 0, group?.uniqueDeviceId || "", group?.sensors?.map(x => x.sensorName)?.join(', ') || ""))
                }
            });

        }

        return ret;



    }

    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Online Status Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <BuildOnlineStatusSimpleChartDetails ref={refOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <BuildOnlineStatusSimpleChartDetails ref={refOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} isPercentEnabled={true} />
                        </Paper>
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }


    const buildGridContent = () => {
        return (
            <Grid container spacing={2} direction="row">
                {!loadingCompleted &&
                    <Grid item xs={12}>
                        <CircularProgress size={64} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography variant="h4">Online Status</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Online-Status Log Tabelle {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {onlineStatusTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OnlineStatusDataTable rows={onlineStatusTableData} groups={groupsLicenseTableEntries} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Online-Status Analyse {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {groupsOnlineStatusCountTable.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                <Grid xs={12} item container spacing={3}>
                                    <Grid item>
                                        < DateTimePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={"Start Datum"}
                                            value={startDate}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                let temp = reloadServiceCount(onlineStatusTableData, newVal, endDate);
                                                setGroupsOnlineStatusCountTable(temp);
                                                setStartDate(newVal);
                                            }}
                                            format={"dd.MM.yyyy HH:mm"}

                                        />
                                    </Grid>
                                    <Grid item>
                                        < DateTimePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={"Ende Datum"}
                                            value={endDate}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                let temp = reloadServiceCount(onlineStatusTableData, startDate, newVal);
                                                setGroupsOnlineStatusCountTable(temp);
                                                setEndDate(newVal);
                                            }}
                                            format={"dd.MM.yyyy HH:mm"}

                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            endIcon={<Downloading />}
                                            onClick={async () => {
                                                let temp = reloadServiceCount(onlineStatusTableData, startDate, endDate, true);
                                                setGroupsOnlineStatusCountTable(temp);

                                            }}
                                        >
                                            Offline Geräte anzeigen
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {loadingCompletedGroups ?
                                        <OnlineStatusCountTable setGroupsLicenseTableEntries={setGroupsLicenseTableEntries} groupServicesMode={groupsOnlineStatusCountTable} groupLicenseTable={groupsLicenseTableEntries}></OnlineStatusCountTable>
                                        :
                                        <CircularProgress color="inherit" />
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[]}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateEndDateFromParent={updateEndDateFromParent}
                        updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildOnlineStatusSimpleChartDetails ref={refOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <BuildOnlineStatusSimpleChartDetails ref={refOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} isPercentEnabled={true} />
                    </Paper>
                </Grid>



            </Grid>
        );
    }

    const globalFields = {
        isPrintPage: isPrintPage,
        setIsPrintPage: setIsPrintPage,
        isPrintingPage: isPrintingPage,
        setIsPrintingPage: setIsPrintingPage
    }

    return (
        <PrintContext.Provider value={globalFields}>
            {isPrintPage ? buildPrintContent() : buildGridContent()}
        </PrintContext.Provider>
    )
}

export const BuildOnlineStatusSimpleChartDetails = React.forwardRef(({ onlineStatusTableData, groupsLicenseTableEntries, isPercentEnabled = false }: { onlineStatusTableData: GroupOnlineStatus[], groupsLicenseTableEntries: GroupLicenseTable[], isPercentEnabled?: boolean }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateOnlineStatusSimpleChartData(date: Date) {
            updateStartDateOnlineStatusSimpleChartData(date);
        },
        updateEndDateOnlineStatusSimpleChartData(date: Date) {
            updateEndDateOnlineStatusSimpleChartData(date);
        }

    }))

    const { maxChartItemCount }: { showStatus: boolean, setShowStatus: any, maxChartItemCount: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [onlineStatusSimpleCountMax, setOnlineStatusSimpleCountMax] = React.useState(0);
    const [onlineStatusSimpleChartData, setOnlineStatusSimpleChartData] = React.useState<any[]>([]);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);


    const [onlineStatusSimpleChartDataBad, setOnlineStatusSimpleChartDataBad] = React.useState<any[]>([]);
    const [onlineStatusAverageSimpleChartData, setOnlineStatusAverageSimpleChartData] = React.useState<any[]>([]);
    const [onlineStatusAverageSimpleChartDataBad, setOnlineStatusAverageSimpleChartDataBad] = React.useState<any[]>([]);


    const [startDateOnlineStatusSimpleChartData, setStartDateOnlineStatusSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateOnlineStatusSimpleChartData, setEndDateOnlineStatusSimpleChartData] = React.useState(new Date());

    const [onlineStatusCount, setOnlineStatusCount] = React.useState(0);
    const [onlineStatusAvg, setOnlineStatusAvg] = React.useState(0);

    const [simpleChartDataAvgPercent, setSimpleChartDataAvgPercent] = React.useState(0);
    const [simpleChartDataAvgBadPercent, setSimpleChartDataAvgBadPercent] = React.useState(0);

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    React.useEffect(() => {
        updateOnlineStatusSimpleChart(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData, onlineStatusTableData)
    }, [maxChartItemCount])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (onlineStatusTableData) {
            setStartDateOnlineStatusSimpleChartData(startDate);
            setEndDateOnlineStatusSimpleChartData(endDate);
            updateOnlineStatusSimpleChart(startDate, endDate, onlineStatusTableData);
        }
    }, [onlineStatusTableData, groupsLicenseTableEntries])

    const updateStartDateOnlineStatusSimpleChartData = (newVal: Date) => {
        if (!onlineStatusTableData) return;
        setStartDateOnlineStatusSimpleChartData(newVal);
        updateOnlineStatusSimpleChart(newVal, endDateOnlineStatusSimpleChartData, onlineStatusTableData);
    }

    const updateEndDateOnlineStatusSimpleChartData = (newVal: Date) => {
        if (!onlineStatusTableData) return;
        setEndDateOnlineStatusSimpleChartData(newVal);
        updateOnlineStatusSimpleChart(startDateOnlineStatusSimpleChartData, newVal, onlineStatusTableData);
    }

    const updateOnlineStatusSimpleChart = (startDate: Date, endDate: Date, groupOnlineStatuses: GroupOnlineStatus[]) => {
        let countMax = Math.round(differenceInMinutes(endDate, startDate) / 15);
        setOnlineStatusSimpleCountMax(countMax);


        let filteredGroupOnlineStatuses = groupOnlineStatuses.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let res = filteredGroupOnlineStatuses.reduce((sumValue, newValue: GroupOnlineStatus) => {
            const notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
            let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
            if (entryExists) {
                entryExists.valueFieldService += 1;
                entryExists.countService += 1;
            } else {
                sumValue = [...sumValue, { argumentFieldService: notificationName, valueFieldService: 1, countService: 1 }];
            }
            return [...sumValue];
        }, [] as any[]);
        let resNormal = res.sort((a, b) => a.valueFieldService - b.valueFieldService);
        let resAverage = res.map(x => { { return { ...x, valueFieldService: Number(((100 * x.valueFieldService) / countMax).toFixed(2)) } } }).sort((a, b) => a.valueFieldService - b.valueFieldService);
        setOnlineStatusCount(res.length);
        setOnlineStatusAvg(isPercentEnabled ? getAvgFromSimpleChart(resAverage) : getAvgFromSimpleChart(res));


        setOnlineStatusSimpleChartData(resNormal.slice(-maxChartItemCount));
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormal.slice(-maxChartItemCount)))

        setOnlineStatusSimpleChartDataBad(resNormal.slice(0, maxChartItemCount).reverse());
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormal.slice(0, maxChartItemCount).reverse()))

        //percent

        setSimpleChartDataAvgPercent(getAvgFromSimpleChart(resAverage.slice(-maxChartItemCount)));
        setSimpleChartDataAvgBadPercent(getAvgFromSimpleChart(resAverage.slice(0, maxChartItemCount).reverse()));

        setOnlineStatusAverageSimpleChartData(resAverage.slice(-maxChartItemCount));
        setOnlineStatusAverageSimpleChartDataBad(resAverage.slice(0, maxChartItemCount).reverse());
    }

    const buildContent = () => {
        return (
            <Paper className="paper-small" style={{ paddingTop: 40 }}>
                <Grid container spacing={3} item xs={12} direction="column">
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"} fontWeight="bold">Online Status pro Einheit Gesamt Bericht</Typography>
                    <Typography className="avg-font" variant="h5">Durchschnitt von {onlineStatusCount} Einheiten: <b>{onlineStatusAvg}{isPercentEnabled && "%"}</b></Typography>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Start Datum"}
                                value={startDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateOnlineStatusSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Ende Datum"}
                                value={endDateOnlineStatusSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateOnlineStatusSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        {!isPercentEnabled &&
                            <Grid container direction={"column"} spacing={3} item xs={6}>
                                <Grid item>
                                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Online Status pro Einheit Absteigend</Typography>
                                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h5">Max Anzahl Online Meldungen: <b>{onlineStatusSimpleCountMax}</b></Typography>
                                    <Typography className="avg-font" variant="h5">Durchschnitt von {onlineStatusSimpleChartData.length} Einheiten: <b>{simpleChartDataAvg}</b></Typography>
                                </Grid>
                                <Grid item>
                                    <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={onlineStatusSimpleChartData} name={"Online Status pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} />
                                </Grid>
                            </Grid>
                        }
                        {!isPercentEnabled &&
                            <Grid container direction={"column"} spacing={3} item xs={6}>
                                <Grid item>
                                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Online Status pro Einheit Aufsteigend</Typography>
                                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h5">Max Anzahl Online Meldungen: <b>{onlineStatusSimpleCountMax}</b></Typography>
                                    <Typography className="avg-font" variant="h5">Durchschnitt von {onlineStatusSimpleChartData.length} Einheiten: <b>{simpleChartDataAvgBad}</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={onlineStatusSimpleChartDataBad} name={"Online Status pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} />
                                </Grid>
                            </Grid>
                        }
                        {isPercentEnabled &&
                            <Grid container direction={"column"} spacing={3} item xs={6}>
                                <Grid item>
                                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Online Status pro Einheit in Prozent Bericht</Typography>
                                    <Typography className="avg-font" variant="h5">Durchschnitt von {onlineStatusSimpleChartData.length} Einheiten: <b>{simpleChartDataAvgPercent}%</b></Typography>
                                </Grid>
                                <Grid item>
                                    <StandardBarChartSimpleColor avgVal={simpleChartDataAvgPercent} color={blue[600]} dataSource={onlineStatusAverageSimpleChartData} name={"Online Status pro Einheit in Prozent"} height={chartHeightOnlineStatusSimple} extraLabel={"%"} />
                                </Grid>
                            </Grid>
                        }
                        {isPercentEnabled &&
                            <Grid container direction={"column"} spacing={3} item xs={6}>
                                <Grid item>
                                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Online Status pro Einheit in Prozent Bericht</Typography>
                                    <Typography className="avg-font" variant="h5">Durchschnitt von {onlineStatusSimpleChartData.length} Einheiten: <b>{simpleChartDataAvgBadPercent}%</b></Typography>
                                </Grid>
                                <Grid item>
                                    <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBadPercent} color={red[600]} dataSource={onlineStatusAverageSimpleChartDataBad} name={"Online Status pro Einheit in Prozent"} height={chartHeightOnlineStatusSimple} extraLabel={"%"} />
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                </Grid>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        if (isPercentEnabled) {
            const titleDesc = `<b>Online Status pro Einheit in Prozent Bericht</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData)}`;
            const subTitleDesc = `Durchschnitt von ${onlineStatusSimpleChartData.length} Einheiten: <b>${simpleChartDataAvgPercent}%</b>`

            const titleAsc = `<b>Online Status pro Einheit in Prozent Bericht</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData)}`
            const subTitleAsc = `Durchschnitt von ${onlineStatusSimpleChartData.length} Einheiten: <b>${simpleChartDataAvgBadPercent}%</b>`

            return (
                <>
                    <PrintButton title="Online Status pro Einheit in Prozent Bericht Drucken" pageRef={refPrint} />
                    <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                        <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} display={false} />
                        <div style={{ display: 'inline-block', width: '50%' }}>
                            <StandardBarChartSimpleColor avgVal={simpleChartDataAvgPercent} color={blue[600]} dataSource={onlineStatusAverageSimpleChartData} name={"Online Status pro Einheit in Prozent"} height={chartHeightOnlineStatusSimple} title={titleDesc} subTitle={subTitleDesc} extraLabel={"%"} />
                        </div>
                        <div style={{ display: 'inline-block', width: '50%' }}>
                            <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBadPercent} color={red[600]} dataSource={onlineStatusAverageSimpleChartDataBad} name={"Online Status pro Einheit in Prozent"} height={chartHeightOnlineStatusSimple} title={titleAsc} subTitle={subTitleAsc} extraLabel={"%"} />
                        </div>
                    </div>
                </>

            )
        } else {
            const titleDesc = `<b>Online Status pro Einheit Absteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData)}`;
            const subTitleDesc = `Max Anzahl Online Meldungen: <b>${onlineStatusSimpleCountMax}</b><br>Durchschnitt von ${onlineStatusSimpleChartData.length} Einheiten: <b>${simpleChartDataAvg}</b>`

            const titleAsc = `<b>Online Status pro Einheit Aufsteigend</b><br />${formatDateRange(startDateOnlineStatusSimpleChartData, endDateOnlineStatusSimpleChartData)}`
            const subTitleAsc = `Max Anzahl Online Meldungen: <b>${onlineStatusSimpleCountMax}</b><br>Durchschnitt von ${onlineStatusSimpleChartData.length} Einheiten: <b>${simpleChartDataAvgBad}</b>`

            return (
                <>
                    <PrintButton title="Online Status pro Einheit Drucken" pageRef={refPrint} />
                    <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                        <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} display={false} />
                        <div style={{ display: 'inline-block', width: '50%' }}>
                            <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={onlineStatusSimpleChartData} name={"Online Status pro Einheit Gesamt (Gut)"} height={chartHeightOnlineStatusSimple} title={titleDesc} subTitle={subTitleDesc} />
                        </div>
                        <div style={{ display: 'inline-block', width: '50%' }}>
                            <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={onlineStatusSimpleChartDataBad} name={"Online Status pro Einheit Gesamt (Schlecht)"} height={chartHeightOnlineStatusSimple} title={titleAsc} subTitle={subTitleAsc} />
                        </div>
                    </div>
                </>

            )
        }


    }

    if (!onlineStatusTableData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});