import React from "react"
import { Grid, Paper } from "@mui/material"
import { elevationPaper, paperStyle } from "../../../../../styles/container"
import { LineChart } from "../../../../charts/LineChart"
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart"
import { StackedChart } from "../../../../charts/StackedChart"
import { ChartChoice } from "../../../../core/ChartChoice"
import { TimeChoice } from "../../../../core/TimeChoice"
import { ChartLabelType, chartHeightBig } from "../../../../charts/Base"

export const ServicesDurationChart = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart }
    : { data: any, dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: any, childRefChart?: any }) => {
    const [chartChoice, setChartChoice] = React.useState('1');


    const newData = React.useMemo(() => data.map((x: any) => {
        return {
            dateDisplay: x.dateDisplay,
            timeOfService: Math.round(x.timeOfService / 3600),
            
        }
    }), [data]);

    console.log(newData, "newData");

    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={ChartLabelType.Hours}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={"Tatsächliche Servicedauer (Stunden)"}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={ChartLabelType.Hours}
                    chartHeight={chartHeightBig}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={"Tatsächliche Servicedauer (Stunden)"}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    chartLabelType={ChartLabelType.Hours}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={"Tatsächliche Servicedauer (Stunden)"}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>{buildGridItem()}</Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}