import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Paper, Typography, Divider, Switch, TextField, InputAdornment, FormControlLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import { addMinutes, eachDayOfInterval, endOfDay, fromUnixTime, getDay, isSameDay, isSameHour, isWithinInterval, startOfDay } from "date-fns";
import React from "react";
import { api } from "../../../../../api";
import { getGroupPayPerServiceByGroupIds } from "../../../../../helpers/charts/base";
import { getUserIdFromStorage } from "../../../../../helpers/common";
import { defaultEndDate, defaultStartDate } from "../../../../../helpers/date-management";
import { getApiToken } from "../../../../../helpers/storage-management";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { GroupPayPerService } from "../../../../../models/GroupPayPerService";
import { GroupSettingsIncidenReport } from "../../../../../models/GroupSettings";
import { InlineDateTimePicker } from "../../../../core/InlineDateTimePicker";
import { AnalyticSummaryIncidentVisitorDataTable } from "../feedbacks/AnalyticSummaryIncidentVisitorDataTable";
import { GroupPayPerServiceDataTable } from "../feedbacks/GroupPayPerServiceDataTable";
import { LoraDailyMotion } from "../../../../../models/LoraDailyMotion";
import { LoraGroupMotion, LoraGroupMotionDailyTable, LoraGroupSummaryMotionHitlist, LoraGroupSummaryMotionDailyTable } from "../../../../../models/LoraGroupMotion";
import { AnalyticSummaryLoraDailyDataTable } from "./AnalyticSummaryLoraDailyDataTable";
import { deepCopy } from "../../../../../helpers/general";
import getUnixTime from "date-fns/getUnixTime/index.js";
import { AnalyticGroupsSummaryLoraDailyDataTable } from "./AnalyticGroupsSummaryLoraDailyDataTable";
import { AnalyticGroupsSummaryLoraDailyCalendarDataTable } from "./AnalyticGroupsSummaryLoraDailyCalendarDataTable";
import { AnalyticSummarySingleValuesLoraDailyDataTable } from "./AnalyticSummarySingleValuesLoraDailyDataTable";
import { LoraGroupMotionItemSingleValueDaily } from "../../../../../models/LoraGroupMotionItem";

export const AnalyticSummarySingleValuesLoraData = ({ groupsLicenseTableEntries, loraMotions }: { groupsLicenseTableEntries: GroupLicenseTable[], loraMotions: LoraGroupMotion[] }) => {
    const [selectedStartDate, setSelectedStartDate] = React.useState(startOfDay(defaultStartDate));
    const [selectedEndDate, setSelectedEndDate] = React.useState(endOfDay(defaultEndDate));

    const [loading, setLoading] = React.useState(false);
    const [loraDailyMotionTableItems, setLoraDailyMotionTableItems] = React.useState<LoraGroupSummaryMotionHitlist[]>([]);
    const [showEmptyResults, setShowEmptyResults] = React.useState(false);
    const [virtualTableHeight, setVirtualTableHeight] = React.useState(800);
    const history = useHistory();


    const rows = React.useMemo(() => {
        if (showEmptyResults) {
            return loraDailyMotionTableItems;
        } else {
            return loraDailyMotionTableItems.filter(x => x.totalMotionAmount > 0);
        }
    }, [showEmptyResults, selectedStartDate, selectedEndDate, loraDailyMotionTableItems])

    const buildEndDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker includeHours={true} title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={(event: any) => {
                    let date = new Date(event);
                    date.setHours(23, 59, 59);
                    setSelectedEndDate(date);
                    loadData(selectedStartDate, date);

                }}></InlineDateTimePicker>
            </Grid>
        )
    }
    const buildStartDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker includeHours={true} title={"Start Datum"} selectedDate={selectedStartDate} setSelectedDate={(event: any) => {
                    let startDate = new Date(event);
                    startDate.setHours(0, 0, 0);
                    setSelectedStartDate(startDate);
                    loadData(startDate, selectedEndDate);
                }}></InlineDateTimePicker>
            </Grid>
        )
    }

    const loadData = (selectedStartDate: Date, selectedEndDate: Date) => {
        const token = getApiToken(history);
        setLoading(true);


        let motionsDate = loraMotions.filter(x => isWithinInterval(fromUnixTime(x.createdTimestamp), { start: selectedStartDate, end: selectedEndDate }));

        let days = eachDayOfInterval({ start: selectedStartDate, end: selectedEndDate });

        let table = groupsLicenseTableEntries.map(group => {
            let groupMotions = motionsDate.filter(x => x.groupId == group.id);
            let totalMotionAmount = groupMotions.reduce((preVal, curVal) => {
                return preVal + curVal.motionCount;
            }, 0)
            let maxDate = new Date();
            let maxNumber = groupMotions.reduce((preVal, curVal) => {
                if (curVal.motionCount > preVal) {
                    maxDate = fromUnixTime(curVal.createdTimestamp);
                    return curVal.motionCount;
                }
                return preVal;
            }, 0)


            let dailyMotionSums = groupMotions.reduce((preVal, curVal) => {
                let loraDate = fromUnixTime(curVal.createdTimestamp);
                let exists = preVal.find(x => isSameDay(x.day, loraDate));
                if (exists) {
                    exists.total = exists.total + curVal.motionCount;
                } else {
                    let el = new LoraGroupMotionItemSingleValueDaily();
                    el.day = loraDate;
                    el.total = curVal.motionCount;
                    preVal.push(el)
                }
                return preVal;
            }, [] as LoraGroupMotionItemSingleValueDaily[]);

            let dailyMotionSum = dailyMotionSums.reduce((preVal, curVal) => {
                if (curVal.total > preVal.total) {
                    return curVal;
                }
                return preVal;
            }, { total: 0, day: new Date() });

            let weekdayMotionSums = groupMotions.reduce((preVal, curVal) => {
                let loraDate = fromUnixTime(curVal.createdTimestamp);
                let exists = preVal.find(x => getDay(x.day) == getDay(loraDate));
                if (exists) {
                    exists.total = exists.total + curVal.motionCount;
                } else {
                    let el = new LoraGroupMotionItemSingleValueDaily();
                    el.day = loraDate;
                    el.total = curVal.motionCount;
                    preVal.push(el)
                }
                return preVal;
            }, [] as LoraGroupMotionItemSingleValueDaily[]);

            let weekDayMotionSum = weekdayMotionSums.reduce((preVal, curVal) => {
                if (curVal.total > preVal.total) {
                    return curVal;
                }
                return preVal;
            }, { total: 0, day: new Date() })



            let data: LoraGroupMotion[] = [];


            groupMotions.forEach(lora => {
                let loraCopy = structuredClone(lora);
                while (loraCopy.motionCount > 0) {
                    let newData: LoraGroupMotion;
                    if (loraCopy.motionCount > 60) {
                        newData = structuredClone(loraCopy) as LoraGroupMotion;
                        newData.motionCount = 60;
                        let date = fromUnixTime(loraCopy.createdTimestamp);
                        date = addMinutes(date, 60);
                        loraCopy.createdTimestamp = getUnixTime(date);
                        loraCopy.motionCount = loraCopy.motionCount - 60;

                    } else {
                        newData = structuredClone(loraCopy) as LoraGroupMotion;
                        let date = fromUnixTime(loraCopy.createdTimestamp);
                        date = addMinutes(date, newData.motionCount);
                        loraCopy.createdTimestamp = getUnixTime(date);
                        loraCopy.motionCount = 0;


                    }
                    data.push(newData);


                }

            })


            let hourlyMotionSums = data.reduce((preVal, curVal) => {
                let loraDate = fromUnixTime(curVal.createdTimestamp);
                let exists = preVal.find(x => isSameHour(x.day, loraDate));
                if (exists) {
                    exists.total = exists.total + curVal.motionCount;
                } else {
                    let el = new LoraGroupMotionItemSingleValueDaily();
                    el.day = loraDate;
                    el.total = curVal.motionCount;
                    preVal.push(el)
                }
                return preVal;
            }, [] as LoraGroupMotionItemSingleValueDaily[]);

            let hourlyMotionSum = hourlyMotionSums.reduce((preVal, curVal) => {
                if (curVal.total > preVal.total) {
                    return curVal;
                }
                return preVal;
            }, { total: 0, day: new Date() })





            return { totalWeekdayMax: weekDayMotionSum, totalHourlyMax: hourlyMotionSum, totalDailyMax: dailyMotionSum, maxMotionDate: maxDate, maxMotionAmount: maxNumber, groupId: group.id, totalMotionAmount: totalMotionAmount, avgMotionAmount: Math.round(totalMotionAmount / groupMotions.length), notificationName: group.notificationName };
        });

        setLoraDailyMotionTableItems(table);


        setLoading(false);
    }

    return (
        <Paper>
            <Accordion onChange={async (event, expanded) => {
                if (expanded) {
                    loadData(selectedStartDate, selectedEndDate);
                }

            }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        md={6}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                            >
                                Lora Einheiten Hitliste - Gesamt Analyse
                            </Typography >
                        </Grid>
                        <Grid item>
                            <Typography
                                className="Paper-Result"
                                component="p"
                            >
                                Einheiten für Gesamt: {groupsLicenseTableEntries.length}
                            </Typography >
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">Lora Einheiten Hitliste</Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={3} alignItems={"center"}>
                            {buildStartDateField()}
                            {buildEndDateField()}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showEmptyResults}
                                            onChange={(event) => {
                                                setShowEmptyResults(event.target.checked);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                    label="Einheiten ohne Lora Daten anzeigen"
                                />

                            </Grid>
                        </Grid>

                        {loading && <Grid item><CircularProgress size={64} /></Grid>}
                        <Grid item container spacing={3} direction={"column"}>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>{rows.length} Einheiten in der Tabelle</Typography>
                            </Grid>
                            <Grid item>
                                <AnalyticSummarySingleValuesLoraDailyDataTable rows={rows} onSelectionChange={() => { }} startDate={selectedStartDate} endDate={selectedEndDate} virtualTableHeight={virtualTableHeight} />
                            </Grid>
                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}