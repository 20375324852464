import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AggregateType, APIDataAmountType, getFeedbackData, getFeedbackQuestionsByUserGroupIds, getMotionData, getServiceData, getServiceTriggerByUserIdGroupIds } from "../../../../helpers/charts/base";
import { getCompletedCleaningTaskFields, getIsOnTimeCleaningTaskFields } from "../../../../helpers/charts/dataGenerator";
import { calcKeySum, calcSum, getEvaluationChoice, getGroupIdsForPrint, getProportion } from "../../../../helpers/common";
import { getApiToken } from "../../../../helpers/storage-management";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupServiceMode } from "../../../../models/GroupServiceMode";
import { FeedbackDisplayType, GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { loadMotionData } from "./FrequencyDashboard";
import { ChartSumType, CustomSumOptions } from "./customSum/CustomSumOptions";
import { CustomSumUserOptions } from "./customSum/CustomSumUserOptions";
import { FEEDBACK_QUESTION_NEGATIVE_CHART_TITLE, getFeedbackColorsSum, loadFeedbackData, loadFeedbackMotionCombData, loadFeedbackQuestionData } from "./FeedbackDashboard";
import { EvalApiType, loadTriggeredCleaningDataAsync, manageServicesData } from "./Services";
import { manageServiceUserData } from "./ServiceWorkerServicesDashboard";
import CloseCircleSvg from '../../../../assets/svgs/close-circle.svg'
import FormatListChecksWhiteSvg from '../../../../assets/svgs/format-list-checks_white.svg'
import AlertSvg from '../../../../assets/svgs/alert_white.svg'
import PaperRollWhiteSvg from '../../../../assets/svgs/paper-roll_white.svg'
import EmoticonHappyWhiteSvg from '../../../../assets/svgs/emoticon-happy_white.svg'
import EmoticonSadWhiteSvg from '../../../../assets/svgs/emoticon-sad_white.svg'
import CheckCircleWhiteSvg from '../../../../assets/svgs/check-circle_white.svg'
import { red, yellow } from "../../../../styles/colors";
import { green } from "../../../../styles/colors";
import { mapLabels } from "../../../charts/Base";
import { SimplePanel, SimplePanelRouteType } from "../../../common/SimplePanel";
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { ExpandMore } from "@mui/icons-material";
import { GroupFeedbackEntireChartsSimple } from "./feedbacks/GroupFeedbackEntireChartsSimple";
import { GroupFeedback } from "../../../../models/GroupFeedback";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { GlobalContext } from "../../PortalPage";
import useNoInitialEffect from "../../../../helpers/extensions";
import { CustomSumOptionsFeedbacks } from "./customSum/CustomSumOptionsFeedbacks";
import { GetGroupFeedbacksQualityMotionComb } from "./customerUsage/GetGroupFeedbacksQualityMotionComb";
import { GetGroupFeedbackQuestions } from "./customerUsage/GetGroupFeedbackQuestions";
import { GroupFeedbackQuestion } from "../../../../models/GroupFeedbackQuestion";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { title } from "process";

export const CustomSum = ({ selectedGroupId, groupIds = [], selectedCombinationGroup, serviceDisplayType, drawerDisplayType }
    : { selectedGroupId: string, groupIds?: string[], selectedCombinationGroup: GroupLicenseTable[], serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType }) => {

    const [triggeredCleaningTasks, setTriggeredCleaningTasks] = React.useState<any>([]);
    const [triggeredCleaningAllTasks, setTriggeredCleaningAllTasks] = React.useState<any>([]);
    const [triggerCleaningType, setTriggerCleaningType] = React.useState<any>([]);
    const { groupId } = useParams<{ groupId: string }>();
    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);

    //group
    const [materialConsumptionAllData, setMaterialConsumptionAllData] = React.useState<any>([]);
    const [materialConsumptionData, setMaterialConsumptionData] = React.useState<any>([]);
    const [materialConsumptionDataFields, setMaterialConsumptionDataFields] = React.useState<any>([]);
    const [incidentReportData, setIncidentReportData] = React.useState<any>([]);
    const [incidentReportAllData, setIncidentReportAllData] = React.useState<any>([]);
    const [incidentReportDataFields, setIncidentReportDataFields] = React.useState<any>([]);
    const [cleaningTaskData, setCleaningTaskData] = React.useState<any>([]);
    const [cleaningTaskAllData, setCleaningTaskAllData] = React.useState<any>([]);
    const [cleaningTaskDataFields, setCleaningTaskDataFields] = React.useState<any>([]);
    const [cleaningTaskIsCompletedData, setCleaningTaskIsCompletedData] = React.useState<any>([]);
    const [cleaningTaskIsCompletedAllData, setCleaningTaskIsCompletedAllData] = React.useState<any>([]);
    const [cleaningTaskIsOnTimeData, setCleaningTaskIsOnTimeData] = React.useState<any>([]);
    const [cleaningTaskIsOnTimeAllData, setCleaningTaskIsOnTimeAllData] = React.useState<any>([]);

    //user cleaner
    const [currentCleanerMaterials, setCurrentCleanerMaterials] = React.useState("");
    const [currentCleanerIncidents, setCurrentCleanerIncidents] = React.useState("");
    const [currentCleanerTasks, setCurrentCleanerTasks] = React.useState("");
    const [groupsServiceMode, setGroupsServiceMode] = React.useState<GroupServiceMode[]>([]);
    const [cleaningTaskUserData, setCleaningTaskUserData] = React.useState<any>([]);
    const [cleaningTaskUserAllData, setCleaningTaskUserAllData] = React.useState<any>([]);
    const [cleaningTaskUserDataFields, setCleaningTaskUserDataFields] = React.useState<any>([]);
    const [incidentReportUserData, setIncidentReportUserData] = React.useState<any>([]);
    const [incidentReportUserAllData, setIncidentReportUserAllData] = React.useState<any>([]);
    const [incidentReportUserDataFields, setIncidentReportUserDataFields] = React.useState<any>([]);
    const [materialUserData, setMaterialUserData] = React.useState<any>([]);
    const [materialUserAllData, setMaterialUserAllData] = React.useState<any>([]);
    const [materialUserDataFields, setMaterialUserDataFields] = React.useState<any>([]);
    const history = useHistory();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);

    //usage
    const [feedbackData, setFeedbackData] = React.useState<any>([]);
    const [feedbackAllData, setFeedbackAllData] = React.useState<any>([]);
    const [feedbackDataType, setFeedbackDataType] = React.useState<any>([]);
    const [groupMotions, setGroupMotions] = React.useState<any>([]);
    const [groupMotionsAll, setGroupMotionsAll] = React.useState<any>([]);
    const [groupMotionType, setGroupMotionType] = React.useState<any>([]);
    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);


    const [loadingFeedbackDataCompleted, setLoadingFeedbackDataCompleted] = React.useState(false);
    const [loadingUsageDataCompleted, setLoadingUsageDataCompleted] = React.useState(false);
    const [loadingServiceUserDataCompleted, setLoadingServiceUserDataCompleted] = React.useState(false);
    const [loadingServicesDataCompleted, setLoadingServicesDataCompleted] = React.useState(false);
    const [loadingTriggeredCleaningDataCompleted, setLoadingTriggerCleaningDataCompleted] = React.useState(false);

    const childRefFeedbacks: any = useRef();
    const childRefVisitors: any = useRef();
    const childRefTriggers: any = useRef();
    const childRefTaskIsCompleted: any = useRef();
    const childRefIsTaskOnTime: any = useRef();
    const childRefMaterialConsumption: any = useRef();
    const childRefIncidentReport: any = useRef();
    const childRefCleaningTask: any = useRef();
    const childRefCleanerTask: any = useRef();
    const childRefCleanerIncidents: any = useRef();
    const childRefCleanerMaterials: any = useRef();

    const childRefFeedbacksChart: any = useRef();
    const childRefVisitorsChart: any = useRef();
    const childRefTriggersChart: any = useRef();
    const childRefTaskIsCompletedChart: any = useRef();
    const childRefIsTaskOnTimeChart: any = useRef();
    const childRefMaterialConsumptionChart: any = useRef();
    const childRefIncidentReportChart: any = useRef();
    const childRefCleaningTaskChart: any = useRef();
    const childRefCleanerTaskChart: any = useRef();
    const childRefCleanerIncidentsChart: any = useRef();
    const childRefCleanerMaterialsChart: any = useRef();

    const [feedbackColors, setFeedbackColors] = React.useState<string[]>([]);

    const [tableFeedbackData, setTableFeedbackData] = React.useState<GroupFeedback[]>([]);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const refGroupFeedbackEntireChartsSimple = useRef<{ updateStartDateFeedbackWithGroupsSimpleData(newVal: Date): void, updateEndDateFeedbackWithGroupsSimpleData(newVal: Date): void }>();
    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);



    const [feedbackMotionCombChartData, setFeedbackMotionCombChartData] = React.useState<any>([]);
    const [feedbackCombDataFields, setFeedbackCombDataFields] = React.useState<any>([]);
    const [motionCombDataFields, setMotionCombDataFields] = React.useState<any>([]);
    const [feedbackMotionCombChartDataAll, setFeedbackMotionCombChartDataAll] = React.useState<any>([]);
    const [feedbackQualityMotionCombChartData, setFeedbackQualityMotionCombChartData] = React.useState<any>([]);
    const childRefFeedbackQualityMotionComb: any = useRef();
    const [feedbackDisplayTypeChart, setFeedbackDisplayTypeChart] = React.useState(FeedbackDisplayType.PositiveNegativeNeutral);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);


    const childRefFeedbackQuestion: any = useRef();
    const childRefFeedbackQuestionChart: any = useRef();
    const [feedbackQuestionData, setFeedbackQuestionData] = React.useState<any>([]);
    const [feedbackQuestionAllData, setFeedbackQuestionAllData] = React.useState<any>([]);
    const [feedbackQuestionDataFields, setFeedbackQuestionDataFields] = React.useState<any>([]);


    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();


    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);


    useNoInitialEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            setLoadingFeedbackDataCompleted(false);
            setLoadingUsageDataCompleted(false);
            setLoadingTriggerCleaningDataCompleted(false);
            setLoadingServiceUserDataCompleted(false);
            setLoadingServicesDataCompleted(false);

            setApiDataLoading(true);
            setLoadingProgress(0);
            setValueBuffer(20);
            setFeedbackData([]);
            setGroupMotions([]);
            setTriggeredCleaningTasks([]);
            setCleaningTaskUserData([]);
            setIncidentReportUserData([]);
            setCleaningTaskData([]);
            const token = getApiToken(history);
            if (!token) return;

            if (serviceDisplayType == EvalApiType.Objects) {
                let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupsLicenseTableEntries(groups);
            }
            if (serviceDisplayType == EvalApiType.ServiceWorkers) {
                let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupsLicenseTableEntries(groups);
            }

            setEvaluationChoice(getEvaluationChoice(groupId, groupIds));

            let feedbacks = await getFeedbackData(groupId, token, groupIds);

            loadFeedbackData(
                setFeedbackAllData,
                setFeedbackDataType,
                setFeedbackData,
                setLoadingFeedbackDataCompleted,
                AggregateType.Sum,
                true,
                feedbacks,
                setTableFeedbackData);

            setFeedbackColors(getFeedbackColorsSum());
            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);

            setLoadingProgress(20);
            setValueBuffer(40);


            let motions = await getMotionData(groupId, token, groupIds);

            const r1 = await loadMotionData(
                setGroupMotionsAll,
                setGroupMotionType,
                setGroupMotions,
                setLoadingUsageDataCompleted,
                AggregateType.Sum,
                motions);

            loadFeedbackMotionCombData(setFeedbackMotionCombChartDataAll, setFeedbackCombDataFields, setMotionCombDataFields, setFeedbackQualityMotionCombChartData, setLoadingCompleted, feedbacks, motions);
            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);

            setLoadingProgress(40);
            setValueBuffer(60);

            const tempGroupFeedbacks: GroupFeedbackQuestion[] = await getFeedbackQuestionsByUserGroupIds(groupId, token, groupIds);
            await loadFeedbackQuestionData(tempGroupFeedbacks,
                setFeedbackQuestionAllData,
                setFeedbackQuestionDataFields,
                setFeedbackQuestionData,
                setLoadingCompleted,
                AggregateType.Time);


            let serviceTriggers = await getServiceTriggerByUserIdGroupIds(groupId, token, groupIds);

            await loadTriggeredCleaningDataAsync(
                serviceTriggers,
                setTriggeredCleaningAllTasks,
                setTriggerCleaningType,
                setTriggeredCleaningTasks,
                setLoadingTriggerCleaningDataCompleted);


            setLoadingProgress(60);
            setValueBuffer(80);

            let servicesMode = await getServiceData(groupId, token, groupIds);


            const r2 = await manageServiceUserData(
                servicesMode,
                setGroupsServiceMode,
                setCleaningTaskUserAllData,
                setCleaningTaskUserDataFields,
                setCleaningTaskUserData,
                setIncidentReportUserAllData,
                setIncidentReportUserDataFields,
                setIncidentReportUserData,
                setMaterialUserAllData,
                setMaterialUserDataFields,
                setMaterialUserData,
                setCurrentCleanerIncidents,
                setCurrentCleanerTasks,
                setCurrentCleanerMaterials,
                setLoadingServiceUserDataCompleted,
                AggregateType.Sum);



            const r3 = await manageServicesData(
                servicesMode,
                setMaterialConsumptionAllData,
                setMaterialConsumptionDataFields,
                setMaterialConsumptionData,
                setIncidentReportAllData,
                setIncidentReportDataFields,
                setIncidentReportData,
                setCleaningTaskAllData,
                setCleaningTaskDataFields,
                setCleaningTaskData,
                setCleaningTaskIsCompletedAllData,
                setCleaningTaskIsCompletedData,
                setCleaningTaskIsOnTimeAllData,
                setCleaningTaskIsOnTimeData,
                setLoadingServicesDataCompleted,
                AggregateType.Sum);



            setLoadingProgress(80);
            setValueBuffer(100);

            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);

            if (serviceDisplayType == EvalApiType.Groups) {
                let groupsPortal = await getPortalGroups(groupIds, token, groupId);
                setGroupsLicenseTableEntries(groupsPortal);
            }

            setLoadingProgress(100);
            setValueBuffer(100);
            setApiDataLoading(false);

        })();
    }, [apiDataAmountType, reloadDataFromApi]);


    const updateEndDateFromParent = (event: any) => {
        if (event == null) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateEndDateFromParent(event);
        if (childRefVisitors.current) childRefVisitors.current.updateEndDateFromParent(event);
        if (childRefTriggers.current) childRefTriggers.current.updateEndDateFromParent(event);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateEndDateFromParent(event);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateEndDateFromParent(event);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateEndDateFromParent(event);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateEndDateFromParent(event);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateEndDateFromParent(event);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateEndDateFromParent(event);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateEndDateFromParent(event);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateEndDateFromParent(event);

        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateEndDateFeedbackWithGroupsSimpleData(event);


        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateEndDateFromParent(event);
    }

    const updateStartDateFromParent = (event: any) => {
        if (event == null) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateStartDateFromParent(event);
        if (childRefVisitors.current) childRefVisitors.current.updateStartDateFromParent(event);
        if (childRefTriggers.current) childRefTriggers.current.updateStartDateFromParent(event);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateStartDateFromParent(event);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateStartDateFromParent(event);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateStartDateFromParent(event);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateStartDateFromParent(event);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateStartDateFromParent(event);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateStartDateFromParent(event);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateStartDateFromParent(event);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateStartDateFromParent(event);

        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateStartDateFeedbackWithGroupsSimpleData(event);


        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateStartDateFromParent(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
        if (event == null) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateDataFilterTypeFromParent(event);
        if (childRefVisitors.current) childRefVisitors.current.updateDataFilterTypeFromParent(event);
        if (childRefTriggers.current) childRefTriggers.current.updateDataFilterTypeFromParent(event);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateDataFilterTypeFromParent(event);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateDataFilterTypeFromParent(event);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateDataFilterTypeFromParent(event);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateDataFilterTypeFromParent(event);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateDataFilterTypeFromParent(event);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateDataFilterTypeFromParent(event);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateDataFilterTypeFromParent(event);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateDataFilterTypeFromParent(event);

        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateDataFilterTypeFromParent(event);
    }

    const updateChosenPastDaysFromParent = (event: any) => {
        if (!event) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateChosenPastDays(event.target.value);
        if (childRefVisitors.current) childRefVisitors.current.updateChosenPastDays(event.target.value);
        if (childRefTriggers.current) childRefTriggers.current.updateChosenPastDays(event.target.value);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateChosenPastDays(event.target.value);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateChosenPastDays(event.target.value);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateChosenPastDays(event.target.value);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateChosenPastDays(event.target.value);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateChosenPastDays(event.target.value);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateChosenPastDays(event.target.value);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateChosenPastDays(event.target.value);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateChosenPastDays(event.target.value);
    }




    const buildPrintContent = () => {
        return (
            <div className="page-size" ref={refPrint}>
                <Grid container spacing={1} direction={"row"} >
                    <GroupTypePrintDisplay evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />

                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                            updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                            updateStartDateFromParent={updateStartDateFromParent}
                            updateEndDateFromParent={updateEndDateFromParent} />
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        spacing={3}
                        xs={12}
                    >
                        <Grid item xs={3} >
                            {buildIncidentsPanel()}
                        </Grid>
                        <Grid item xs={3} >
                            {buildMaterialPanel()}
                        </Grid>
                        <Grid item xs={3}>
                            {buildFeedbackNegativVisitorPanel()}
                        </Grid>
                        <Grid item xs={3}>
                            {buildFeedbackPositivPanel()}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={3}
                        item
                        xs={12}
                    >
                        <Grid item xs={3}>
                            {buildPuncutalServicePanel()}
                        </Grid>
                        <Grid item xs={3}>
                            {buildMissedServicePanel()}
                        </Grid>
                        <Grid item xs={3}>
                            {buildCompleteServicePanel()}
                        </Grid>
                        <Grid item xs={3}>
                            {buildInCompleteServicePanel()}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        {buildFeedbackChart()}
                    </Grid>
                    <Grid item xs={6}>
                        {buildVisitorsChart()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupFeedbacksQualityMotionComb()}
                    </Grid>

                </Grid>
                <div className="page-break"></div>

                <Grid direction={"row"} container>
                    <GroupTypePrintDisplay evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        {buildServiceTriggerChart()}
                    </Grid>
                    <Grid item container spacing={2} className="group-print-content-cockpit-services-time">
                        <Grid item xs={6}>
                            {buildServiceCompareChart()}
                        </Grid>
                        <Grid item xs={6}>
                            {buildServiceComparePunctualChart()}

                        </Grid>
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={1} direction={"column"} >
                    <GroupTypePrintDisplay evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item container spacing={1} direction="row" className="group-print-content-cockpit-services">
                        <Grid item xs={6}>
                            {buildMaterialChart()}
                        </Grid>
                        <Grid item xs={6}>
                            {buildIncidentsChart()}
                        </Grid>
                        <Grid item xs={12}>
                            {buildServicesChart()}
                        </Grid>
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={0} direction={"column"} >
                    <GroupTypePrintDisplay evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            {buildServiceForUserChart()}
                        </Grid>
                        <Grid item xs={6}>
                            {buildIncidentsForUserChart()}
                        </Grid>
                        <Grid item xs={6}>
                            {buildMaterialForUserChart()}
                        </Grid>
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container>
                    {fifthPartContent()}
                </Grid>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <div>
                        <GroupTypePrintDisplay evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                        <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                    </div>
                }
            </div>
        );
    }

    const buildIncidentsPanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.ServiceTable}
                evaluationChoice={evaluationChoice}
                color={yellow}
                svgIcon={AlertSvg}
                title={'Anzahl Vorfälle'}
                result={calcSum(incidentReportData)}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildMaterialPanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.ServiceTable}
                evaluationChoice={evaluationChoice}
                svgIcon={PaperRollWhiteSvg}
                title={'Verbrauchtes Material'}
                result={calcSum(materialConsumptionData)}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildFeedbackNegativVisitorPanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.FeedbackChart}
                evaluationChoice={evaluationChoice}
                color={red}
                svgIcon={EmoticonSadWhiteSvg}
                title={'Negatives Feedback / Besucher'}
                result={`${getProportion(calcKeySum(groupMotions, "Besucher"), calcKeySum(feedbackData, mapLabels("negativeAmount")))}%`}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildFeedbackPositivPanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.FeedbackChart}
                evaluationChoice={evaluationChoice}
                color={green}
                svgIcon={EmoticonHappyWhiteSvg}
                title={'Positives Feedback / Besucher'}
                result={`${getProportion(calcKeySum(groupMotions, "Besucher"), calcKeySum(feedbackData, mapLabels("positiveAmount")))}%`}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildPuncutalServicePanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.ServicesChart}
                evaluationChoice={evaluationChoice}
                color={green}
                svgIcon={FormatListChecksWhiteSvg}
                title={'Anzahl Pünktlicher Service'}
                result={calcKeySum(cleaningTaskIsOnTimeData, getIsOnTimeCleaningTaskFields[0])}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildMissedServicePanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.ServicesChart}
                evaluationChoice={evaluationChoice}
                color={red}
                svgIcon={FormatListChecksWhiteSvg}
                title={'Anzahl Verpasster Services'}
                result={calcKeySum(cleaningTaskIsOnTimeData, getIsOnTimeCleaningTaskFields[1])}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildCompleteServicePanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.ServicesChart}
                evaluationChoice={evaluationChoice}
                color={green}
                svgIcon={CheckCircleWhiteSvg}
                title={'Alle Services erledigt'}
                result={calcKeySum(cleaningTaskIsCompletedData, getCompletedCleaningTaskFields[0])}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildInCompleteServicePanel = () => {
        return (
            <SimplePanel
                simplePanelRouteType={SimplePanelRouteType.ServicesChart}
                evaluationChoice={evaluationChoice}
                color={red}
                svgIcon={CloseCircleSvg}
                title={'Services Teilweise erledigt'}
                result={calcKeySum(cleaningTaskIsCompletedData, getCompletedCleaningTaskFields[1])}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup} />
        )
    }

    const buildFeedbackChart = () => {
        return (
            <CustomSumOptionsFeedbacks
                childRef={childRefFeedbacks}
                childRefChart={childRefFeedbacksChart}
                defaultChoice={'1'}
                text={"Feedback-Meldungen"}
                setChartData={setFeedbackData}
                allData={feedbackAllData}
                data={feedbackData}
                dataField={feedbackDataType}
                colors={feedbackColors}
                loadingCompleted={loadingFeedbackDataCompleted} />
        )
    }

    const buildVisitorsChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefVisitorsChart}
                childRef={childRefVisitors}
                defaultChoice={'1'}
                text={"Besucher"}
                setChartData={setGroupMotions}
                allData={groupMotionsAll}
                data={groupMotions}
                dataField={groupMotionType}
                chartSumType={ChartSumType.Motion}
                loadingCompleted={loadingUsageDataCompleted} />
        )
    }

    const buildServiceTriggerChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefTriggersChart}
                childRef={childRefTriggers}
                defaultChoice={'1'}
                text={"Serviceauslösung"}
                setChartData={setTriggeredCleaningTasks}
                allData={triggeredCleaningAllTasks}
                data={triggeredCleaningTasks}
                dataField={triggerCleaningType}
                loadingCompleted={loadingUsageDataCompleted && loadingTriggeredCleaningDataCompleted} />
        )
    }

    const buildServiceCompareChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefTaskIsCompletedChart}
                childRef={childRefTaskIsCompleted}
                defaultChoice={'1'}
                text={"Service erledigt: Alle & Teilweise"}
                setChartData={setCleaningTaskIsCompletedData}
                allData={cleaningTaskIsCompletedAllData}
                data={cleaningTaskIsCompletedData}
                dataField={getCompletedCleaningTaskFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildServiceComparePunctualChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefIsTaskOnTimeChart}
                childRef={childRefIsTaskOnTime}
                defaultChoice={'1'}
                text={"Service: Verpasst & Pünktlich"}
                setChartData={setCleaningTaskIsOnTimeData}
                allData={cleaningTaskIsOnTimeAllData}
                data={cleaningTaskIsOnTimeData}
                dataField={getIsOnTimeCleaningTaskFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildMaterialChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefMaterialConsumptionChart}
                childRef={childRefMaterialConsumption}
                defaultChoice={'1'}
                text={"Materialverbrauch"}
                setChartData={setMaterialConsumptionData}
                allData={materialConsumptionAllData}
                data={materialConsumptionData}
                dataField={materialConsumptionDataFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildIncidentsChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefIncidentReportChart}
                childRef={childRefIncidentReport}
                defaultChoice={'1'}
                text={"Vorfallmeldungen"}
                setChartData={setIncidentReportData}
                allData={incidentReportAllData}
                data={incidentReportData}
                dataField={incidentReportDataFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildServicesChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefCleaningTaskChart}
                childRef={childRefCleaningTask}
                defaultChoice={'0'}
                text={"Serviceleistungen"}
                setChartData={setCleaningTaskData}
                allData={cleaningTaskAllData}
                data={cleaningTaskData}
                dataField={cleaningTaskDataFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildServiceForUserChart = () => {
        return (
            <CustomSumUserOptions
                childRefChart={childRefCleanerTaskChart}
                childRef={childRefCleanerTask}
                currentCleaner={currentCleanerTasks}
                setCurrentCleaner={setCurrentCleanerTasks}
                defaultChoice={'0'}
                text={`Serviceleistungen durchgeführt durch Servicekräfte ${currentCleanerTasks}`}
                setChartData={setCleaningTaskUserData}
                allData={cleaningTaskUserAllData}
                data={cleaningTaskUserData}
                dataField={cleaningTaskUserDataFields}
                loadingCompleted={loadingServiceUserDataCompleted} />
        )
    }

    const buildIncidentsForUserChart = () => {
        return (
            <CustomSumUserOptions
                childRefChart={childRefCleanerIncidentsChart}
                childRef={childRefCleanerIncidents}
                currentCleaner={currentCleanerIncidents}
                setCurrentCleaner={setCurrentCleanerIncidents}
                defaultChoice={'1'}
                text={`Vorfallmeldung durchgeführt durch Servicekräfte ${currentCleanerIncidents}`}
                setChartData={setIncidentReportUserData}
                allData={incidentReportUserAllData}
                data={incidentReportUserData}
                dataField={incidentReportUserDataFields}
                loadingCompleted={loadingServiceUserDataCompleted} />
        )
    }

    const buildMaterialForUserChart = () => {
        return (
            <CustomSumUserOptions
                childRefChart={childRefCleanerMaterialsChart}
                childRef={childRefCleanerMaterials}
                currentCleaner={currentCleanerMaterials}
                setCurrentCleaner={setCurrentCleanerMaterials}
                defaultChoice={'1'}
                text={`Material verwendet durch Servicekräfte ${currentCleanerMaterials}`}
                setChartData={setMaterialUserData}
                allData={materialUserAllData}
                data={materialUserData}
                dataField={materialUserDataFields}
                loadingCompleted={loadingServiceUserDataCompleted} />
        )
    }

    const buildGetGroupFeedbacksQualityMotionComb = () => {
        return (
            <GetGroupFeedbacksQualityMotionComb feedbackDisplayTypeChart={feedbackDisplayTypeChart} data={feedbackQualityMotionCombChartData} allData={feedbackMotionCombChartDataAll} setChartData={setFeedbackQualityMotionCombChartData} childRef={childRefFeedbackQualityMotionComb} loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbackQuestions = () => {
        return (
            <GetGroupFeedbackQuestions
                title={FEEDBACK_QUESTION_NEGATIVE_CHART_TITLE}
                childRefChart={childRefFeedbackQuestionChart}
                childRef={childRefFeedbackQuestion}
                setChartData={setFeedbackQuestionData}
                allData={feedbackQuestionAllData}
                data={feedbackQuestionData}
                dataField={feedbackQuestionDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const firstPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateStartDateFromParent={updateStartDateFromParent}
                        updateEndDateFromParent={updateEndDateFromParent} />
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} md={6} lg={3}>
                        {buildIncidentsPanel()}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        {buildMaterialPanel()}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        {buildFeedbackNegativVisitorPanel()}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        {buildFeedbackPositivPanel()}
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    item
                >
                    <Grid item xs={12} md={6} lg={3}>
                        {buildPuncutalServicePanel()}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        {buildMissedServicePanel()}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        {buildCompleteServicePanel()}
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        {buildInCompleteServicePanel()}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {buildFeedbackChart()}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {buildVisitorsChart()}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {buildServiceTriggerChart()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupFeedbacksQualityMotionComb()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const secondPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    {buildGetGroupFeedbackQuestions()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildServiceCompareChart()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildServiceComparePunctualChart()}
                </Grid>
            </Grid>
        )
    }

    const thirdPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={6}>
                    {buildMaterialChart()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildIncidentsChart()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildServicesChart()}
                </Grid>
            </Grid>
        )
    }

    const fourthPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={12}>
                    {buildServiceForUserChart()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildIncidentsForUserChart()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildMaterialForUserChart()}
                </Grid>
            </Grid>
        )
    }

    const fifthPartContent = () => {
        return (
            <Grid item xs={12} lg={12}>
                <GroupFeedbackEntireChartsSimple ref={refGroupFeedbackEntireChartsSimple} tableFeedbackData={tableFeedbackData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
            </Grid>
        )
    }

    const [page, setPage] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(true);
    const allItems = [firstPartContent, secondPartContent, thirdPartContent, fourthPartContent];
    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        if (page <= allItems.length - 1) {
            setPage((curVal) => curVal + 1);
        } else {
            setHasMore(false);
        }
    };

    const buildGridContent = () => {

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Cockpit</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item style={{ minHeight: 300 }}>
                    <InfiniteScroll
                        dataLength={page} //This is important field to render the next data
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <Paper style={{ margin: 5 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={"large"}
                                    startIcon={<ExpandMore />}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                        fetchMoreData();
                                    }}
                                >
                                    Mehr Laden...
                                </Button>
                            </Paper>
                        }
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Ende des Cockpit erreicht!</b>
                            </p>
                        }
                    // below props only if you need pull down functionality
                    //        refreshFunction={this.refresh}
                    >
                        <Grid container direction={"column"} spacing={2}>
                            {page >= 0 && firstPartContent()}
                            {page >= 1 && secondPartContent()}
                            {page >= 2 && thirdPartContent()}
                            {page >= 3 && fourthPartContent()}
                            {page >= 4 && fifthPartContent()}
                        </Grid>
                    </InfiniteScroll>
                </Grid>

            </Grid>
        );
    }
    return isPrintPage ? buildPrintContent() : buildGridContent();
}

