import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { MarkedMaterial, MarkedService, ObjectLeaderQualityCheckModel } from '../../../../models/ObjectLeaderQualityCheckModel';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { FIVE_SMILE, GREEN_LIGHT_SMILE, GREEN_SMILE, RED_LIGHT_SMILE, RED_SMILE, YELLOW_SMILE } from '../../../../assets';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable, GroupLicenseTableServiceAverage } from '../../../../models/GroupLicenseTable';
import { Group, GroupType } from '../../../../models/Group';
import { mapToiletTypeKey } from '../../../tables/Base';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { buildCellStringArrayControlledExpaned } from '../../../common/AccordionForStringArrayControlledExpanded';
import { green, red, yellow } from '../../../../styles/colors';
import { GaugeFeedback } from './GaugeFeedback';




export const ObjectLeaderQualityCheckAverageTable = ({ rows, userServiceWorker, accordionExpandableServices, setAccordionExpandableServices, accordionExpandableMaterials, setAccordionExpandableMaterials, hiddenColumnNames, setHiddenColumnNames }
    : { rows: GroupLicenseTableServiceAverage[], userServiceWorker?: UserServiceWorker | undefined, accordionExpandableServices: any[] | undefined, setAccordionExpandableServices: any, accordionExpandableMaterials: any[] | undefined, setAccordionExpandableMaterials: any, hiddenColumnNames: string[], setHiddenColumnNames: any }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            if (userServiceWorker) {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-${userServiceWorker.name}-`);
            } else {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-Einheiten`);
            }
        });
    };
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);

    const TableRow = ({ row, ...restProps }: any) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            //     onClick={() => alert(JSON.stringify(row))}
            style={{
                cursor: 'pointer',
                height: 20,
                padding: 0,
                margin: 0
            }}
        />
    );


    const getOverallSumServices = () => {
        let data = rows.filter(x=>Object.keys(x.services).length > 0);
        let allSum = data.reduce((acc, row) => {
            let { sum } = calcSumForRow(row.services);
            return acc + sum;
        }, 0);
        return Math.round(allSum / data.length);
    }

    const getOverallSumMaterials = () => {
        let data = rows.filter(x=>Object.keys(x.materials).length > 0);
        let allSum = data.reduce((acc, row) => {
            let { sum } = calcSumForRow(row.materials);
            return acc + sum;
        }, 0);
        return Math.round(allSum / data.length);
    }

    const calcSumForRow = (items: any) => {
        let ret = Object.keys(items).map(x => `${x} ${items[x]}%`);
        let keys = Object.keys(items);
        let smilesIcon = keys.map((x: string) => {
            return getSmile(items[x]);
        });
        let sum = keys.reduce((acc, curVal) => {
            return acc + items[curVal];
        }, 0);
        sum = Math.round(sum / keys.length);
        return { keys: keys, ret: ret, sum: sum, smilesIcon: smilesIcon }
    }


    const updateAccordionExpandableServices = (row: GroupLicenseTableServiceAverage, setData: any, accordionExpandables: any[] | undefined) => {
        setData(accordionExpandables?.map(x => {
            if (x.id == row.id) {
                return { id: x.id, expanded: !x.expanded }
            } else {
                return x;
            }
        }));
    }

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name == GroupLicenseTableServiceAverage.ColumnsExtended.service && row.services) {
            let sumObject = calcSumForRow(row.services);
            const accObject = accordionExpandableServices?.find(x => x.id == row.id);
            let expand = accObject ? accObject.expanded : true;
            return buildCellStringArrayControlledExpaned(props, sumObject.ret, <Typography style={{ fontWeight: 'bold' }}>Geprüft: {sumObject.keys.length} Services | {isNaN(sumObject.sum) ? "" : <Typography>{sumObject.sum}% {getSmile(sumObject.sum)}</Typography>}</Typography>, null, sumObject.smilesIcon, null, expand, () => updateAccordionExpandableServices(row, setAccordionExpandableServices, accordionExpandableServices));
        }
        else if (column.name == GroupLicenseTableServiceAverage.ColumnsExtended.material && row.materials) {
            let sumObject = calcSumForRow(row.materials);
            const accObject = accordionExpandableMaterials?.find(x => x.id == row.id);
            let expand = accObject ? accObject.expanded : true;
            return buildCellStringArrayControlledExpaned(props, sumObject.ret, <Typography style={{ fontWeight: 'bold' }}>Geprüft: {sumObject.keys.length} Materialien | {isNaN(sumObject.sum) ? "" : <Typography>{sumObject.sum}% {getSmile(sumObject.sum)}</Typography>}</Typography>, null, sumObject.smilesIcon, null, expand, () => updateAccordionExpandableServices(row, setAccordionExpandableMaterials, accordionExpandableMaterials));
        }
        return <Table.Cell {...props} />;
    };

    let columns = [
        {
            name: ObjectLeaderQualityCheckModel.Columns.groupId,
            title: 'Einheit Name',
            getCellValue: (row: GroupLicenseTableServiceAverage) => row.notificationName
        },
        {
            name: GroupLicenseTable.Columns.groupType,
            title: 'Einheit Typ',
            getCellValue: (row: GroupLicenseTableServiceAverage) => Group.GetLabelByType(row.groupType),
        },
        {
            name: GroupLicenseTable.Columns.objectName,
            title: 'Objekt Name',
            getCellValue: (row: GroupLicenseTableServiceAverage) => row.objectName,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: 'Ebene',
            getCellValue: (row: GroupLicenseTableServiceAverage) => row.levelName,
        },
        {
            name: GroupLicenseTable.Columns.roomName,
            title: 'Kunde',
            getCellValue: (row: GroupLicenseTableServiceAverage) => row.roomName,
        },
        {
            name: GroupLicenseTableServiceAverage.ColumnsExtended.service,
            title: 'Services',
            getCellValue: (row: GroupLicenseTableServiceAverage) => {
                let ret = Object.keys(row.services).map(x => `${x} ${row.services[x]}`).join(',');
                return ret;
            },
        },
        {
            name: GroupLicenseTableServiceAverage.ColumnsExtended.material,
            title: 'Materialverbrauch',
            getCellValue: (row: GroupLicenseTableServiceAverage) => {
                let ret = Object.keys(row.materials).map(x => `${x} ${row.materials[x]}`).join(',');
                return ret;
            },
        }
    ];
    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const theme = useTheme();


    const buildCardSum = (getMaterials = false) => {


        if (!getMaterials && hiddenColumnNames.includes(GroupLicenseTableServiceAverage.ColumnsExtended.service)) return;
        if (getMaterials && hiddenColumnNames.includes(GroupLicenseTableServiceAverage.ColumnsExtended.material)) return;

        const sumServices = getMaterials ? getOverallSumMaterials() : getOverallSumServices();
        return (
            <Card sx={{ display: 'flex', marginTop: 5, marginBottom: 5, padding: 1, alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            Gesamt {getMaterials ? "Material" : "Services"}: {sumServices}%
                        </Typography>
                    </CardContent>
                </Box>
                {getSmile(sumServices, 60)}
            </Card>
        )
    }

    const buildGaugeSum = (getMaterials = false) => {


        if (!getMaterials && hiddenColumnNames.includes(GroupLicenseTableServiceAverage.ColumnsExtended.service)) return;
        if (getMaterials && hiddenColumnNames.includes(GroupLicenseTableServiceAverage.ColumnsExtended.material)) return;

        const sumServices = getMaterials ? getOverallSumMaterials() : getOverallSumServices();
        return (
            <Card sx={{ display: 'flex', marginTop: 5, marginBottom: 5, padding: 1, alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <GaugeFeedback gaugeVal={sumServices} title={getMaterials ? "Material" : "Services"}/>
                    </CardContent>
                </Box>
            </Card>
        )
    }

    return (
        <>
            <div style={{ display: 'flex', gap: 30 }}>
                {buildCardSum()}
                {buildCardSum(true)}
                {buildGaugeSum()}
                {buildGaugeSum(true)}
            </div>
            <Paper>

                <Grid
                    rows={rows}
                    columns={columns}
                >
                    <FilteringState />
                    <IntegratedFiltering />

                    <SortingState
                        defaultSorting={[{ columnName: ObjectLeaderQualityCheckModel.Columns.groupId, direction: 'asc' }]}
                    />
                    <IntegratedSorting />

                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                    />
                    <IntegratedPaging />

                    <Table cellComponent={Cell} rowComponent={TableRow} />
                    <TableColumnResizing
                        defaultColumnWidths={[
                            { columnName: ObjectLeaderQualityCheckModel.Columns.groupId, width: 160 },
                            { columnName: GroupLicenseTable.Columns.objectName, width: 130 },
                            { columnName: GroupLicenseTable.Columns.groupType, width: 180 },
                            { columnName: GroupLicenseTable.Columns.levelName, width: 130 },
                            { columnName: GroupLicenseTable.Columns.roomName, width: 130 },
                            { columnName: GroupLicenseTable.Columns.toiletType, width: 130 },
                            { columnName: GroupLicenseTable.Columns.userPropertyName, width: 160 },

                            { columnName: ObjectLeaderQualityCheckModel.Columns.incidents, width: 400 },
                            { columnName: ObjectLeaderQualityCheckModel.Columns.markedMaterials, width: 400 },
                            { columnName: ObjectLeaderQualityCheckModel.Columns.markedServices, width: 400 },
                            { columnName: ObjectLeaderQualityCheckModel.Columns.createdTimestamp, width: 150 },
                            { columnName: GroupLicenseTableServiceAverage.ColumnsExtended.service, width: 400 },
                            { columnName: GroupLicenseTableServiceAverage.ColumnsExtended.material, width: 400 }

                        ]}
                    />
                    <TableHeaderRow showSortingControls />
                    <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <Toolbar />
                    <ExportPanel startExport={startExport} />
                    <ColumnChooser />
                    <TableFilterRow />
                    <PagingPanel pageSizes={[10, 15, 0]} />
                </Grid>
                <GridExporter
                    ref={exporterRef}
                    rows={rows}
                    columns={columns}
                    onSave={onSave}
                />
            </Paper>
        </>
    );
};

export const getSmile = (val: any, height = 20) => {

    if (val >= 85) {
        return (
            <img src={GREEN_SMILE} height={height} />
        );
    }
    else if (val >= 65) {
        return (
            <img src={YELLOW_SMILE} height={height} />
        );
    }
    return (
        <img src={RED_SMILE} height={height} />
    );
}

export const getColorOfSmileQM = (val: any) => {

    if (val >= 85) {
        return green;
    }
    else if (val >= 65) {
        return yellow;
    }
    return red;
}