import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { ChartLabelAlternative, LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../../core/TimeChoice";
import { DurationChoice, DurationChoiceType, getChartLabelType, getLabelOfDurationChoice } from "../../../../core/DurationChoice";
import { ChartLabelType } from "../../../../charts/Base";
import { deepCopy } from "../../../../../helpers/general";


export const ObjectSessionChart = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, chartCalcType = ChartCalcType.Sum, childRefChart, title }
    : { data: any[], dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, chartCalcType?: ChartCalcType, childRefChart?: any, title?: string }) => {
    const [chartChoice, setChartChoice] = React.useState('1');


    const sumBlockCount = React.useMemo(() => data.reduce((prevVal, x: any) => {
        return prevVal + x.signCount;
    }, 0), [data]);


    title = `Objektanmeldungen Gesamt <br>Gesamtsumme: <b>${sumBlockCount} </b>`;


    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                     childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={chartCalcType} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}