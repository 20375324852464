import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { Column, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { MarkedItemThing, MarkedMaterial, MarkedService, ObjectLeaderQualityCheckModel } from '../../../../models/ObjectLeaderQualityCheckModel';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { FIVE_SMILE, GREEN_LIGHT_SMILE, GREEN_SMILE, RED_LIGHT_SMILE, RED_SMILE, YELLOW_SMILE } from '../../../../assets';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Group, GroupType } from '../../../../models/Group';
import { mapToiletTypeKey } from '../../../tables/Base';
import { IncidentImagesDialog } from '../../../authorized/portal/Dashboard/serviceMode/IncidentImagesDialog';
import { GroupServiceModeIncidentPhoto } from '../../../../models/GroupServiceMode';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, ListItem, ListItemButton, TextField, Typography } from '@mui/material';
import { Camera, CameraAlt, Chair, Mail, PhotoAlbum, PhotoAlbumRounded, Smartphone } from '@mui/icons-material';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { StringHelper } from '../../../../helpers/string-helper';
import { useStyles } from '../../../../styles/container';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QualityCheckImagesDialog } from '../../../authorized/portal/Dashboard/serviceMode/QualityCheckImagesDialog';
import { getDefaultIcon } from '../../DefaultItemsDialogOld';
import { QMRatingType, QMSettings } from '../../../../models/ObjectUnit';
import { getSmileImage, getSmileText, getSmileValueFromMarkedService } from './ObjectLeaderQualityCheckTable';
import { GroupIncidentVisitor } from '../../../../models/GroupIncidentVisitor';
import { getSmileValueWeighting } from '../../../../helpers/charts/objectLeader/dataGenerator';



export const ObjectLeaderQualityCheckTableWeighting = ({ qualityChecks, userServiceWorkers, groups }
    : { qualityChecks: ObjectLeaderQualityCheckModel[], userServiceWorkers: UserServiceWorker[], groups: GroupLicenseTable[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            if (userServiceWorkers && userServiceWorkers.length > 0) {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-${userServiceWorkers.map(x => x.name).join()}-`);
            } else {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-Einheiten`);
            }
        });
    };
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);

    const PhotoCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setGroupId(row.groupId);
                        setIncidentPhotos(row.incidentPhotos);
                        setIsIncidentImagesDialogVisible(true);
                    }}
                    endIcon={<PhotoAlbum></PhotoAlbum>}
                >{row.incidentPhotos.length} Vorfallfotos anzeigen</Button>
            </Table.Cell>
        )
    };

    const AccordionForStringArray = ({ props, services, title, listItemIcon = null, row }: { props: any, services: MarkedService[], title: any, listItemIcon?: any, row: ObjectLeaderQualityCheckModel }) => {
        const classes = useStyles();

        const getIcon = (index: number) => {
            if (listItemIcon && listItemIcon[index]) {
                return listItemIcon[index];
            }
            return (
                <Smartphone />
            )
        }

        return (
            <Table.Cell {...props}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <MuiGrid container direction="column">
                            <MuiGrid item>
                                <Typography className={classes.heading}>{title}</Typography>
                            </MuiGrid>

                        </MuiGrid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List style={{ width: '100%' }} component="nav" aria-label="main mailbox folders">
                            {services && services.length > 0 && services.map((el, index) => {
                                return (
                                    <ListItem style={{ width: '100%' }} key={index}>
                                        <Button

                                            onClick={async () => {
                                                setGroupId(row.groupId);
                                                setQualityCheckPhotos(el.qualityCheckPhoto);
                                                setIsQualityCheckImagesDialogVisible(true);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={el.qualityCheckPhoto && el.qualityCheckPhoto.length < 1}
                                            size="small"
                                            endIcon={<CameraAlt />}
                                        >
                                            {el.qualityCheckPhoto ? `${el.qualityCheckPhoto.length} Servicefotos` : "Keine"}
                                        </Button>

                                        <ListItemText style={{ margin: 10 }} primary={el.cleaningOperationText} />
                                        {row.qmRatingType == QMRatingType.QMWeighting && el.weighting > 0 &&
                                            <ListItemText style={{ margin: 10 }} primary={` | ${el.weighting}%`} />
                                        }
                                        <ListItemIcon>
                                            {getIcon(index)}
                                        </ListItemIcon>

                                    </ListItem>
                                )
                            })}

                        </List>
                    </AccordionDetails>
                </Accordion>

            </Table.Cell>
        );
    }

    const Cell = (props: any) => {
        const { column, row }: { column: any, row: ObjectLeaderQualityCheckModel } = props;
        if (column.name == ObjectLeaderQualityCheckModel.Columns.incidents && row.incidents) {
            let incidentsIcon = row.incidents.map((x: string) => getDefaultIcon(x));
            return buildCellStringArray(props, row.incidents, `Enthält ${row.incidents.length} Vorfallmeldungen`, null, incidentsIcon);
        }
        if (column.name == ObjectLeaderQualityCheckModel.Columns.markedServices && row.markedServices) {
            let servicesIcon = row.markedServices.map((x: MarkedService) => getSmileImage(x));
            let countPhotos = row.markedServices.reduce((prevVal, curVal) => {
                return prevVal + curVal.qualityCheckPhoto.length;
            }, 0)
            return <AccordionForStringArray props={props} services={row.markedServices} title={`Enthält ${row.markedServices.length} Services und ${countPhotos} Fotos`} listItemIcon={servicesIcon} row={row}></AccordionForStringArray>

        }
        if (column.name == ObjectLeaderQualityCheckModel.Columns.markedMaterials && row.markedMaterials) {
            let servicesIcon = row.markedMaterials.map((x: MarkedMaterial) => getSmileImage(x));
            let services = row.markedMaterials.map((x: MarkedMaterial) => x.materialText);

            return buildCellStringArray(props, services, `Enthält ${row.markedMaterials.length} Materialien`, null, servicesIcon);
        }
        else if (column.name === "incidentPhotos" && row.incidentPhotos) {
            return <PhotoCell {...props} />
        }
        return <Table.Cell {...props} />;
    };

    const getColumns = (columns: any[]) => {
        return columns.map(el => {
            if (el.name == GroupIncidentVisitor.Columns.items || el.name == GroupIncidentVisitor.Columns.customTexts) {
                return { columnName: el.name, width: 450 } as TableColumnWidthInfo;
            }
            return { columnName: el.name, width: 180 } as TableColumnWidthInfo;
        }) as any[]
    }

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [isIncidentImagesDialogVisible, setIsIncidentImagesDialogVisible] = React.useState(false);
    const [incidentPhotos, setIncidentPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);
    const [groupId, setGroupId] = React.useState("");

    const [isQualityCheckImagesDialogVisible, setIsQualityCheckImagesDialogVisible] = React.useState(false);
    const [qualityCheckPhotos, setQualityCheckPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);
    const [totalSummaryItems, setTotalSummaryItems] = React.useState<any[]>([]);

    const [selection, setSelection] = React.useState<string[]>([]);
    const [mail, setMail] = React.useState<string>("");
    const [loadingMail, setLoadingMail] = React.useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    let [columns, setColumns] = React.useState<Column[]>([]);
    let [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>(getColumns(columns));

    React.useEffect(() => {

        let columns = [
            {
                name: ObjectLeaderQualityCheckModel.Columns.groupId,
                title: 'Einheit Name',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.notificationName,
            },
            {
                name: GroupLicenseTable.Columns.groupType,
                title: 'Einheit Typ',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => Group.GetLabelByType(groups.find(x => x.id == row.groupId)?.groupType || GroupType.DisplayWallApp),
            },
            {
                name: GroupLicenseTable.Columns.objectName,
                title: 'Objekt Name',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.objectName,
            },
            {
                name: GroupLicenseTable.Columns.levelName,
                title: 'Ebene',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.levelName,
            },
            {
                name: GroupLicenseTable.Columns.roomName,
                title: 'Kunde',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.roomName,
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.userServiceWorkerId,
                title: 'Servicekraft',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => userServiceWorkers.find(x => x.id == row.userServiceWorkerId)?.name || row.userServiceWorkerId,
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.markedServices,
                title: 'Services',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return row.markedServices && row.markedServices.map(x => `${x.cleaningOperationText} => ${getSmileText(x)}`).join(' | ')
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.createdTimestamp,
                title: 'Erstellt am',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => formatEuropeanDateTime(row.createdTimestamp),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.weightingSum,
                title: 'Gesamtwertung',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    let res = row.markedServices.reduce((prevVal, curVal) => {
                        let toAdd = (getSmileValueFromMarkedServiceWeighting(curVal) * curVal.weighting) / 100;
                        return prevVal + toAdd;
                    }, 0)
                    return res;
                },
            },


        ];
        let services = qualityChecks.flatMap(x => x.markedServices);
        let servicetexts = Array.from(new Set(services.map(x => x.cleaningOperationText)));
        columns = [...columns, ...servicetexts.map(x => {
            return (
                {
                    name: x,
                    title: x,
                    getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                        let curService = row.markedServices.find(service => x == service.cleaningOperationText);
                        return curService ? (getSmileValueFromMarkedService(curService) * curService.weighting) / 100 : "nicht gefunden";
                    },
                }
            )
        }) as any];

        setTotalSummaryItems([
            { columnName: ObjectLeaderQualityCheckModel.Columns.weightingSum, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.weightingSum, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.weightingSum, type: 'avg' },
            ...servicetexts.map(x => {
                return (
                    {
                        columnName: x,
                        type : 'count'
                    }
                )
            }) as any,
            ...servicetexts.map(x => {
                return (
                    {
                        columnName: x,
                        type : 'max'
                    }
                )
            }) as any,
            ...servicetexts.map(x => {
                return (
                    {
                        columnName: x,
                        type : 'avg'
                    }
                )
            }) as any
        ]);
        /*    columns = [...columns, servicetexts.map(x => {
                return (
                    {
                        name: x + " Gewichtung",
                        title: x + " Gewichtung",
                        getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                            let curService = row.markedServices.find(service => x == service.cleaningOperationText);
                            return curService ? curService.weighting : "nicht gefunden";
                        },
                    }
                )
            }) as any]; */
        setColumns(columns);
        setColumnWidths(getColumns(columns))

    }, [qualityChecks])

    return (
        <Paper>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <TextField
                    id="email"
                    fullWidth
                    label="Email Addresse"
                    margin="dense"
                    name="email"
                    required={true}
                    disabled={loadingMail}
                    size={'medium'}
                    variant="outlined"
                    type="email"
                    value={mail || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMail(event.target.value);
                    }}
                />
                <Button onClick={async () => {
                    setLoadingMail(true);
                    const tokenTemp = getApiToken(history);
                    await api.sendSelectedQualityChecksToMail(tokenTemp, selection, mail, enqueueSnackbar);
                    setLoadingMail(false);
                }}
                    disabled={selection.length < 1 || loadingMail || !StringHelper.IsValidEmail(mail)}
                    style={{ marginLeft: 20 }}
                    startIcon={loadingMail && <CircularProgress size={24} />}
                    color="primary" endIcon={<Mail />} variant={"contained"} size={"small"}>
                    {selection.length} ausgewählte Qualitätsicherungen als Email versenden
                </Button>
            </div>
            <IncidentImagesDialog groupId={groupId} visible={isIncidentImagesDialogVisible} setVisible={setIsIncidentImagesDialogVisible} incidentPhotos={incidentPhotos} />
            <QualityCheckImagesDialog groupId={groupId} visible={isQualityCheckImagesDialogVisible} setVisible={setIsQualityCheckImagesDialogVisible} incidentPhotos={qualityCheckPhotos} />
            <Grid
                rows={qualityChecks}
                columns={columns}
                getRowId={(row: ObjectLeaderQualityCheckModel) => row.id}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection as any}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <IntegratedSummary />


                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow showSortingControls />
                <TableSummaryRow />

                <TableSelection showSelectAll />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={qualityChecks}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};

export const getSmileValueFromMarkedServiceWeighting = (service: MarkedService | MarkedMaterial) => {
    return getSmileValueWeighting(service.veryNegativeAmount, service.negativeAmount, service.neutralAmount, service.positiveAmount, service.veryPositiveAmount);
}