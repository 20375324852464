import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete, Visibility, VisibilityOff, Done, Shop, PhotoAlbum } from '@mui/icons-material';
import { CurrencyHelper } from "../../../helpers/CurrencyHelper";
import { Product } from '../../../models/Product';
import { errorColor } from '../../../styles/colors';
import { UserAccount } from '../../../models/UserAccount';
import { ReactComponent as LicenseSvg } from '../../../assets/svgs/license_grey.svg'
import { AdminSetting } from '../../../models/AdminSetting';
import { CheckboxCell } from '../../tables/ServiceModeTable';

export const AdminSettingsDataTable = ({ rows, users, onEdit, onDelete, onShowImages }
    : { rows: AdminSetting[], users: UserAccount[], onEdit: (row: AdminSetting) => Promise<any>, onDelete: (row: AdminSetting) => any, onShowImages: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row } = props;
        if (column.name === AdminSetting.Columns.active || column.name === AdminSetting.Columns.isDeviceRestartTimeEnabled) {
            return <CheckboxCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };


    const columns: any[] = [
        {
            name: AdminSetting.Columns.actions,
            title: " ",
            getCellValue: (row: AdminSetting) => {
                return <>
                    <Tooltip title="Bearbeiten">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Löschen">
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>;
            },
        },
        {
            name: AdminSetting.Columns.adminSettingType,
            title: "Einstellung Typ",
            getCellValue: (row: AdminSetting) => {
                return AdminSetting.GetLabelByType(row.adminSettingType)
            }
        },
        {
            name: AdminSetting.Columns.adminSettingNewsType,
            title: "Portal News Darstellung",
            getCellValue: (row: AdminSetting) => {
                return AdminSetting.GetLabelNewsByType(row.adminSettingNewsType)
            }
        },
        {
            name: AdminSetting.Columns.id,
            title: "Id",
            getCellValue: (row: AdminSetting) => row.id,
        },
        {
            name: AdminSetting.Columns.creatorUserId,
            title: "Ersteller",
            getCellValue: (row: AdminSetting) => {
                let user = users.find(x => x.id == row.creatorUserId);
                if (!user) return `ID: ${row.creatorUserId} gelöscht`;
                return `${user.firstname} ${user.lastname} | ${user.mail} | ${user.company}`
            }
        },
        {
            name: AdminSetting.Columns.currentDisplayApkVersion,
            title: "Aktive Display Apk Version",
            getCellValue: (row: AdminSetting) => {
                return row.currentDisplayApkVersion
            }
        },
        {
            name: AdminSetting.Columns.deviceRestartTime,
            title: "Geräte Neustart Zeitpunkt",
            getCellValue: (row: AdminSetting) => {
                return row.deviceRestartTime
            }
        },
        {
            name: AdminSetting.Columns.isDeviceRestartTimeEnabled,
            title: "Geräte Neustart Aktiviert",
            getCellValue: (row: AdminSetting) => {
                return row.isDeviceRestartTimeEnabled
            }
        },
        {
            name: AdminSetting.Columns.systemCommand,
            title: "System Command (Android Shell)",
            getCellValue: (row: AdminSetting) => {
                return row.systemCommand
            }
        },
        {
            name: AdminSetting.Columns.active,
            title: "Setting Aktiv",
            getCellValue: (row: AdminSetting) => {
                return row.active
            }
        },
    ];
    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == AdminSetting.Columns.creatorUserId) return { columnName: el.name, width: 350 }
        return { columnName: el.name, width: 220 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: UserAccount) => row.id}
                columns={columns}
            >

                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[AdminSetting.Columns.id]}
                />
                <Toolbar />
                <ColumnChooser />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>

        </div>
    );
};
