import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import { GridStackCustom, GridStackCustomItem } from '../../models/GridStackCustom';
import { CustomDashboardContext } from '../authorized/portal/Dashboard/CustomDashboard';
import { ChartLabelType } from '../charts/Base';

export enum DurationChoiceType {
  hours = "0",
  minutes = "1",
  seconds = "2"
}

export function DurationChoice({ value, setValue }: { value: DurationChoiceType, setValue: any }) {


  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" style={{ paddingLeft: 10 }}>
      <FormLabel component="legend">Zeiteinheit</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} row>
        <FormControlLabel value={DurationChoiceType.seconds} control={<Radio />} label="Sekunden" />
        <FormControlLabel value={DurationChoiceType.minutes} control={<Radio />} label="Minuten" />
        <FormControlLabel value={DurationChoiceType.hours} control={<Radio />} label="Stunden" />
      </RadioGroup>
    </FormControl>
  );
}

export const getLabelOfDurationChoice = (durationChoiceType: any) => {
  switch (durationChoiceType) {
      case DurationChoiceType.hours:
          return "Stunden";
      case DurationChoiceType.minutes:
          return "Minuten";
      default:
          return "Sekunden"
  }
}

export const getChartLabelType = (durationChoiceType: DurationChoiceType) => {
  switch (durationChoiceType) {
      case DurationChoiceType.hours:
          return ChartLabelType.Hours;
      case DurationChoiceType.minutes:
          return ChartLabelType.Minutes;
      default:
          return ChartLabelType.Seconds
  }
}