import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lightgrey } from '../../../styles/colors';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { buildCellObject } from '../../authorized/extendedapp/serviceWorker/UserServiceWorkerManagementPageDataTable';
import { convertMinuteToHours, formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime } from '../../../helpers/date-management';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { Accessibility } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveExcelFile } from '../../../helpers/tables/common';
import { differenceInHours, differenceInMinutes, formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

export const ActiveServiceWorkersInObjectTable = ({  userServiceWorkers, selectedObjectUnit, objects = [] }
    : { userServiceWorkers: UserServiceWorker[], selectedObjectUnit? : ObjectUnit, objects? : ObjectUnit[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjektSession-${selectedObjectUnit?.name || "Alle-Objekte"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const classes = useStyles();

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === UserServiceWorker.SessionColumns.serviceWorker) {
            return <Table.Cell {...props}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center' }}>
                    <Accessibility />
                    <Typography>{value}</Typography>
                </div>
            </Table.Cell>;
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: UserServiceWorker.SessionColumns.serviceWorker,
            title: 'Servicekraft',
            getCellValue: (row: UserServiceWorker) => {
                return (
                    `${row?.name} ${row?.phoneNumber}`
                );
            }
        },
        {
            name: UserServiceWorker.SessionColumns.object,
            title: 'Servicekraft',
            getCellValue: (row: UserServiceWorker) => {
                let curObject = objects.find(x=>x.id == row.serviceWorkerSignedIntoObject.signedInObjectId)
                return (
                    `${curObject?.name} ${curObject?.addressLine}`
                );
            }
        },
        {
            name: UserServiceWorker.SessionColumns.signedInObjectSinceTimestamp,
            title: 'Anmeldung Datum',
            getCellValue: (row: UserServiceWorker) => formatEuropeanDate(row.serviceWorkerSignedIntoObject.signedInObjectSinceTimestamp),
        },
        {
            name: UserServiceWorker.SessionColumns.signedInObjectSinceTime,
            title: 'Anmeldung Zeitpunkt',
            getCellValue: (row: UserServiceWorker) => formatEuropeanTime(row.serviceWorkerSignedIntoObject.signedInObjectSinceTimestamp),
        },
        {
            name: UserServiceWorker.SessionColumns.duration,
            title: 'Anwesenheitsdauer',
            getCellValue: (row: UserServiceWorker) => {
                return formatDistance(new Date(),new Date(row.serviceWorkerSignedIntoObject.signedInObjectSinceTimestamp),{locale: de})
            }
        },
        {
            name: UserServiceWorker.SessionColumns.durationMinutes,
            title: 'Anwesenheitsdauer Minuten',
            getCellValue: (row: UserServiceWorker) => {
                return differenceInMinutes(new Date(),new Date(row.serviceWorkerSignedIntoObject.signedInObjectSinceTimestamp))
            }
        },
        {
            name: UserServiceWorker.SessionColumns.durationHours,
            title: 'Anwesenheitsdauer Stunden',
            getCellValue: (row: UserServiceWorker) => {
                return convertMinuteToHours(differenceInMinutes(new Date(),new Date(row.serviceWorkerSignedIntoObject.signedInObjectSinceTimestamp)))
            }
        }
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: UserServiceWorker.SessionColumns.object, width: 350 },
        { columnName: UserServiceWorker.SessionColumns.serviceWorker, width: 350 },
        { columnName: UserServiceWorker.SessionColumns.signedInObjectSinceTimestamp, width: 200 },
        { columnName: UserServiceWorker.SessionColumns.signedOutOfObjectSinceTimestamp, width: 200 },
        { columnName: UserServiceWorker.SessionColumns.signedOutOfObjectSinceTime, width: 200 },
        { columnName: UserServiceWorker.SessionColumns.signedInObjectSinceTime, width: 200 },
        { columnName: UserServiceWorker.SessionColumns.duration, width: 200 },
        { columnName: UserServiceWorker.SessionColumns.durationMinutes, width: 200 },
        { columnName: UserServiceWorker.SessionColumns.durationHours, width: 200 },
    ]);

    return (
        <Paper>
            <Grid
                rows={userServiceWorkers}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={userServiceWorkers}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};

const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});