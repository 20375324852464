import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AlarmOn, CloudSync, CompareArrows, Dashboard, Delete, DoNotDisturb, EnergySavingsLeaf, ExpandLess, ExpandMore, Folder, FolderShared, GridOn, Help, LocalShipping, Login, NotificationImportant, Refresh, RefreshOutlined, Search, SecurityUpdateGood, Sensors, Settings, SettingsInputAntenna, SupervisorAccount, TableChart, Visibility, Work } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Button, CircularProgress, Collapse, Grid, IconButton, ListItemButton, ListItemSecondaryAction, Tooltip, Typography, useTheme } from '@mui/material';
import { GLOBAL_GRID_STACK_CUSTOMS, GLOBAL_SELECTED_GRID_STACK_CUSTOM, GROUP_COMBINATION, GROUP_ID } from '../../constants';
import ChartBellCurve from '../../assets/svgs/chart-bell-curve.svg';
import EmoticonNeutralOutline from '../../assets/svgs/emoticon-neutral-outline.svg';
import { trimPathname } from '../../helpers/common';
import { GlobalContext } from './PortalPage';
import { UserAccount } from '../../models/UserAccount';
import { UserType } from '../../models/UserType';
import { api } from '../../api';
import { drawerLightBlue, errorColor } from '../../styles/colors';
import { GridStackCustom } from '../../models/GridStackCustom';
import { onlineStatusTitleMenu } from './ChartsDrawerPoints';
import { useTranslation } from 'react-i18next';

export enum DrawerMenuItemDisplayType {
    Single = 0,
    Multiple = 1,
    All = 2
}

export const ObjectMenuPointDrawer = ({ user, title, classes, subHeaderText, path,
    subHeaderText2 = "", openSearchDialog, open, setOpen, displayType = DrawerMenuItemDisplayType.Single, hasAccessTo }:
    {
        user: UserAccount | undefined, title: any; classes: any; subHeaderText: any; path: any;
        subHeaderText2?: string; openSearchDialog?: any; open: boolean, setOpen: any, displayType?: DrawerMenuItemDisplayType, hasAccessTo: any
    }) => {
    const theme = useTheme();
    const handleClick = () => {
        setOpen(!open);
    };
    const { selectedObjectId, selectedObjectName, selectedCombinationObject }: any = useContext(GlobalContext);
    const { showStatus, setShowStatus, gridStackCustoms, setGridStackCustoms, token }: { showStatus: boolean, setShowStatus: any, gridStackCustoms: GridStackCustom[], setGridStackCustoms: any, token: string } = useContext(GlobalContext);


    const [openCustomDashboardCollabsable, setOpenCustomDashboardCollabsable] = React.useState(false);
    const [loadingCustomDashboards, setLoadingCustomDashboards] = React.useState(false);

    const { t } = useTranslation();

    let { pathname } = useLocation();
    pathname = trimPathname(pathname);

    let showContent = true;
    if (DrawerMenuItemDisplayType.Single == displayType) {
        showContent = !!selectedObjectId;
    } else if (DrawerMenuItemDisplayType.Multiple == displayType) {
        showContent = selectedCombinationObject.length > 0;
    } else if (DrawerMenuItemDisplayType.All == displayType) {
        showContent = true;
    }


    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <ListItem role={undefined} button onClick={showContent ? handleClick : openSearchDialog} className="font-subheader-drawer" style={{ backgroundColor: drawerLightBlue }}>
                {showContent ?
                    <ListItemIcon className="font-subheader-drawer">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    :
                    <ListItemIcon className="font-subheader-drawer"><Help /></ListItemIcon>}
                <Grid container direction={"row"} justifyContent={"space-between"} alignContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"}>{subHeaderText}</Typography>
                        {title && <Typography style={{ fontSize: 16 }}>{title}</Typography>}
                        {subHeaderText2 && <Typography style={{ fontSize: 16 }}>{subHeaderText2}</Typography>

                        }
                    </Grid>
                </Grid>
                {displayType != DrawerMenuItemDisplayType.All &&
                    <ListItemSecondaryAction>
                        <IconButton className="font-subheader-drawer" onClick={openSearchDialog} size="large">
                            <Search />
                        </IconButton>
                    </ListItemSecondaryAction>
                }
            </ListItem>
            {showContent &&
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {hasAccessTo(user?.userMenuVisibility.objectSessionAnalytic) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/service-worker/sessions`}
                                selected={pathname === `${path}/service-worker/sessions`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Login />
                                </ListItemIcon>
                                <ListItemText primary="Anmeldungen im Objekt" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.objectCleaningTriggerDashboard) &&

                            <ListItemButton
                                component={Link}
                                to={`${path}/objects/interval-trigger`}
                                selected={pathname === `${path}/objects/interval-trigger`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <AlarmOn />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.objectCleaningTriggerDashboard")} />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.objectStockDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/objects/stocks`}
                                selected={pathname === `${path}/objects/stocks`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <LocalShipping />
                                </ListItemIcon>
                                <ListItemText primary="Lager - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.objectCarbonDioxideDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/carbon-dioxide-consumption`}
                                selected={pathname === `${path}/carbon-dioxide-consumption`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <EnergySavingsLeaf />
                                </ListItemIcon>
                                <ListItemText primary="CO2 Verbrauch - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.customSum) &&
                            <ListItemButton

                                component={Link}
                                to={`${path}/sum`}
                                selected={pathname === `${path}/sum`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText primary="Standard Cockpit" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.servicesDashboard) &&
                            <ListItemButton

                                component={Link}
                                to={`${path}/services`}
                                selected={pathname === `${path}/services`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText primary="Leistungsdoku - Chart" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.serviceModeDashboardTable) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/table`}
                                selected={pathname === `${path}/table`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <TableChart />
                                </ListItemIcon>
                                <ListItemText primary="Leistungsdoku - Tabelle" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.serviceWorkerServicesDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/user`}
                                selected={pathname === `${path}/user`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <SupervisorAccount />
                                </ListItemIcon>
                                <ListItemText primary="Leistungsdoku - Servicekräfte/in" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.cleaningTriggerDashboard) &&
                            <ListItemButton

                                component={Link}
                                to={`${path}/reinigungstrigger`}
                                selected={pathname === `${path}/reinigungstrigger`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <AlarmOn />
                                </ListItemIcon>
                                <ListItemText primary="Serviceauslösungen" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.qmTriggerDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/qm-trigger`}
                                selected={pathname === `${path}/qm-trigger`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <AlarmOn />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.qmtriggers")} />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.onlineStatusDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/online-status`}
                                selected={pathname === `${path}/online-status`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <SettingsInputAntenna />
                                </ListItemIcon>
                                <ListItemText primary={t(onlineStatusTitleMenu)} />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.feedbackDashboard) &&

                            <ListItemButton
                                component={Link}
                                to={`${path}/feedback`}
                                selected={pathname === `${path}/feedback`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <img src={EmoticonNeutralOutline} />
                                </ListItemIcon>
                                <ListItemText primary="Feedback - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.frequencyDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/frequency`}
                                selected={pathname === `${path}/frequency`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <img src={ChartBellCurve} />
                                </ListItemIcon>
                                <ListItemText primary="Frequenz - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.frequencyLoraDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/frequency-lora`}
                                selected={pathname === `${path}/frequency-lora`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Sensors />
                                </ListItemIcon>
                                <ListItemText primary="Frequenz Lora - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.qualityChecksDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/qualitätssicherung`}
                                selected={pathname === `${path}/qualitätssicherung`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <SecurityUpdateGood />
                                </ListItemIcon>
                                <ListItemText primary="Qualitätssicherung - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.hitListDashboard) &&
                            <ListItemButton
                                component={Link}
                                to={`${path}/hitlist`}
                                selected={pathname === `${path}/hitlist`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <CompareArrows />
                                </ListItemIcon>
                                <ListItemText primary="Hitliste - Analyse" />
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.objectSharedFolderDashboard) &&

                            <ListItemButton
                                component={Link}
                                to={`${path}/shared-folders`}
                                selected={pathname === `${path}/shared-folders`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Folder />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.objectSharedFolderDashboard")} />
                                <ListItemIcon>
                                    <CloudSync />
                                </ListItemIcon>
                            </ListItemButton>
                        }
                        {hasAccessTo(user?.userMenuVisibility.customDashboard) &&
                            <ListItemButton onClick={() => { setOpenCustomDashboardCollabsable(prev => !prev) }}>
                                <ListItemIcon>
                                    <GridOn />
                                </ListItemIcon>
                                <ListItemText primary="Meine Berichte" />
                                {openCustomDashboardCollabsable ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={openCustomDashboardCollabsable} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {gridStackCustoms.length < 1 ?
                                    <ListItemButton
                                        style={{ textAlign: 'center' }}
                                        component={Link}
                                        to={`/portal/dashboardverwaltung`}
                                        selected={pathname === `/portal/dashboardverwaltung`}
                                        className={classes.nested}
                                    >
                                        <ListItemIcon style={{ color: errorColor }}>
                                            <DoNotDisturb />
                                        </ListItemIcon>
                                        <ListItemText style={{ color: errorColor }} primary={"Keine Berichte geladen"} secondary={"Zur Berichte - Verwaltung"} />
                                    </ListItemButton>
                                    :
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={loadingCustomDashboards}
                                            endIcon={loadingCustomDashboards && <CircularProgress size={32} />}
                                            startIcon={<RefreshOutlined />}
                                            onClick={async () => {
                                                setLoadingCustomDashboards(true);
                                                const tempgridStackCustoms = await api.getUsergridStackCustoms(token);
                                                setGridStackCustoms(tempgridStackCustoms);
                                                localStorage.setItem(GLOBAL_GRID_STACK_CUSTOMS, JSON.stringify(tempgridStackCustoms));
                                                setLoadingCustomDashboards(false);
                                            }}
                                        >
                                            Refresh Berichte von Server
                                        </Button>
                                        {gridStackCustoms.map(item => {
                                            return (
                                                <ListItemButton
                                                    onClick={() => {
                                                        localStorage.setItem(GLOBAL_SELECTED_GRID_STACK_CUSTOM, JSON.stringify(item));
                                                    }}
                                                    component={Link}
                                                    to={`${path}/custom-sum/${item.name}`}
                                                    selected={pathname === `${path}/${item.name}/custom-sum`}
                                                    className={classes.nested}
                                                >
                                                    <ListItemIcon>
                                                        <GridOn />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />
                                                </ListItemButton>
                                            )
                                        })}
                                    </>
                                }

                            </List>
                        </Collapse>




                    </List>
                </Collapse>}
        </List>
    );
};