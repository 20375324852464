import { AdminSetting } from "./models/AdminSetting";

export const USER_ID = "user_id";
export const SELECTED_USER_ID_FROM_ADMIN = "selected_user_id_from_admin";
export const GROUP_ID = "group_id";
export const GROUP_NAME = "group_name";
export const GROUP_TYPE = "group_type";

export const OBJECT_ID = "object_id";
export const OBJECT_NAME = "object_name";
export const OBJECT_COMBINATION = "object_combination";

export const SERVICE_WORKER_ID = "service_worker_id";
export const SERVICE_WORKER_NAME = "service_worker_name";
export const SERVICE_WORKER_COMBINATION = "service_worker";

export const USER_TOKEN = "user_token";
export const GROUP_COMBINATION = "group_combination";
export const GLOBAL_DATE_CHARTS = "global_date_charts";
export const GLOBAL_START_DATE_CHARTS = "global_start_date_charts";
export const GLOBAL_START_TIME_BACKEND_FILTER = "global_start_time_backend_filter";
export const GLOBAL_END_TIME_BACKEND_FILTER = "global_end_time_backend_filter";
export const GLOBAL_CHOSEN_PAST_DAYS_CHARTS = "global_chosen_past_days_charts";
export const GLOBAL_DATA_FILTER_TYPE = "global_data_filter_type";
export const GLOBAL_PERIOD_CHART_FILTER = "global_period_chart_filter";
export const GLOBAL_API_DATA_AMOUNT_TYPE = "global_api_data_amount_type"
export const GLOBAL_GRID_STACK_CUSTOMS = "global_grid_stack_customs"
export const GLOBAL_SELECTED_GRID_STACK_CUSTOM = "global_selected_grid_stack_custom"

export const GLOBAL_IS_EVAL_GROUPS_VISIBLE = "global_isEvalGroupsVisible";
export const GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE = "global_isEvalServiceWorkersVisible";
export const GLOBAL_IS_EVAL_OBJECTS_VISIBLE = "global_isEvalObjectsVisible";
export const GLOBAL_IS_EVAL_DASHBOARD_VISIBLE = "global_isEvalDashboardVisible";

export const GLOBAL_IS_EVAL_SETTINGS_VISIBLE = "global_isEvalSettingsVisible";
export const GLOBAL_IS_EVAL_ACCOUNT_VISIBLE = "global_isEvalAccountVisible";
export const GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE = "global_isEvalNewsInfoVisible";
export const GLOBAL_IS_EVAL_SHOP_VISIBLE = "global_isEvalShopVisible";

export const GLOBAL_VISIBILITY_MATERIAL_ANALYSIS = "global_visibility_material_analysis";


export const GROUP_LICENSE_COST_SETTINGS = "GROUP_LICENSE_COST_SETTINGS";
export const PUBLIC_SHOP_CHOSEN_ITEMS = "public_shop_chosen_items";

export const FEEDBACK_DISPLAY_TYPE_CHART = "feedback_display_type_chart"

export const INCLUDE_HOUR_FILTER = "include_hour_filter";
export const FILTER_BETWEEN_SPECIFIED_TIME = "filter_between_specified_time";
export const FILTER_BETWEEN_SPECIFIED_TIME_BACKEND = "filter_between_specified_time_backend";
export const ADMIN_DATA_BACKEND = "adi_backend";


export const ADMIN_SETTING_PORTAL = "admin_setting_portal";
export const GLOBAL_CHART_DISPLAY_SIZE_TYPE = "Chart_Display_Size_Type";


export const CHART_BAR_FONT_SIZE = "chart_bar_font_size";
export const CHART_BAR_SIZE = "chart_bar_size";
export const MAX_CHART_ITEM_COUNT = "max_chart_item_count";
export const AUTO_CHART_HEIGHT = "auto_chart_height";

export const LAST_PUBLIC_FEEDBACK = "last_public_feedback";

export const getFromStorageAdminSettingPortal = () => {
    let setting;
    setting = JSON.parse(localStorage.getItem(ADMIN_SETTING_PORTAL) || "{}");
    return setting as AdminSetting;
}

export const setFromStorageAdminSettingPortal = (adminSetting: AdminSetting) => {
    localStorage.setItem(ADMIN_SETTING_PORTAL, JSON.stringify(adminSetting))
}

export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;



export const CITIES = ["Wien", "Bregenz", "Eisenstadt", "Graz", "Innsbruck", "Klagenfurt", "Villach", "Linz", "Salzburg", "Sankt Pölten", 
"Berlin", "Bremen", "Düsseldorf" , "Hamburg", "Hannover", "Kiel", "Magdeburg", "Mainz", "München", "Potsdam", "Saarbrücken", "Schwerin", "Stuttgart", "Wiesbaden", 
"Halle", "Zwickau", "Halberstadt","Erfurt", "Dresden", "Chemnitz", "Leipzig", "Bonn", "Köln", "Frankfurt", 
"London","Zürich", "Basel", "Bern"]
