import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FileCopy, Groups, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { errorColor, white } from '../../../../styles/colors';
import { PropertySelectionDataTable } from './PropertySelectionDataTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from './ObjectSelectionDataTable';
import { TemplateSettingsSelectionDataTable } from './TemplateSettingsSelectionDataTable';
import { UserGroupSettingsTemplate } from '../../../../models/UserGroupSettingsTemplate';
import { GroupSettings, GroupSettingsType } from '../../../../models/GroupSettings';
import { TemplateSettingsMultipleSelectionDataTable } from './TemplateSettingsMultipleSelectionDataTable';
import { UserServiceWorkerMultipleSelectionDataTable } from './UserServiceWorkerMultipleSelectionDataTable';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignMultupleGroupToServiceWorkerDialog = ({
  open,
  setOpen,
  currentGroups,
  allGroups,
  userServiceWorkers,
  objects,
  onRefresh,
  onDelete = null,
  onAdd = null,
  preSelected,
  type = GroupSettingsType.Default
}: {
  open: boolean,
  setOpen: any,
  currentGroups: GroupLicenseTable[],
  allGroups: GroupLicenseTable[],
  userServiceWorkers: UserServiceWorker[],
  objects: ObjectUnit[],
  onRefresh: any,
  onDelete?: any,
  onAdd?: any,
  preSelected?: string[],
  type?: GroupSettingsType
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();
  const [token, setToken] = React.useState("");
  const [assignObjectOfGroup, setAssignObjectOfGroup] = React.useState(true);
  const [selectedUserServiceWorkers, setSelectedUserServiceworkers] = React.useState<UserServiceWorker[]>([]);

  const handleClose = () => {
    setSelectedUserServiceworkers([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      if (preSelected) {
        setSelectedUserServiceworkers(userServiceWorkers.filter(x => preSelected.some(y => x.id == y)));
      }

      
      const tempToken = getApiToken(history);
      if (!tempToken) return;

      setToken(tempToken);


    })();

  }, [open, userServiceWorkers]);

  const title = type == GroupSettingsType.Default ? `${currentGroups.length} Einheiten` : 'Vorlagen'

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Wähle Servicekräfte für die ausgewählten ${title}`}
        handleClose={handleClose}
      />

      <DialogContent>
        <UserServiceWorkerMultipleSelectionDataTable rows={userServiceWorkers}
          groups={allGroups} objects={objects}
          selectedUserServiceWorkers={selectedUserServiceWorkers}
          onSelectionChange={(selection: string[]) => {
            setSelectedUserServiceworkers(userServiceWorkers.filter(x => selection.includes(x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <FormControlLabel style={{ marginLeft: 'auto' }}
          control={
            <Checkbox
              checked={assignObjectOfGroup}
              onChange={(event: any) => {
                setAssignObjectOfGroup(event.target.checked);
              }}
              name="isEnabled"
              color="primary"
            />
          }
          label={`Objekt der ${type == GroupSettingsType.Default ? "Einheit" : "Vorlage"} zur Servicekraft zuweisen/wegweisen`}
        />
      </DialogActions>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={selectedUserServiceWorkers.length < 1 || loading ? {} : { backgroundColor: errorColor, color: white }}
          disabled={selectedUserServiceWorkers.length < 1 || loading}
          endIcon={<Delete />}
          startIcon={loading ? <CircularProgress /> : <Groups />}
          onClick={async () => {
            setLoading(true);

            if (onDelete) {
              await onDelete(selectedUserServiceWorkers, assignObjectOfGroup);
            } else {
              for (let currentGroup of currentGroups) {
                await api.UnAssignUserServiceWorkersFromGroup(currentGroup.id, selectedUserServiceWorkers.map(x => x.id), token, enqueueSnackbar);
                if (currentGroup.objectUnitId && assignObjectOfGroup) {
                  await api.unassignServiceWorkersFromObject(currentGroup.objectUnitId, selectedUserServiceWorkers.map(x => x.id), token, enqueueSnackbar);
                }
              }
            }

            setLoading(false);
            handleClose();
            onRefresh();

          }}
        >
          Ausgewählte Servicekräfte von {title} wegweisen
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading ? <CircularProgress /> : <Groups />}
          disabled={selectedUserServiceWorkers.length < 1 || loading}
          endIcon={<Add />}
          onClick={async () => {
            setLoading(true);
            if (!!onAdd) {
              await onAdd(selectedUserServiceWorkers, assignObjectOfGroup);
            } else {
              for (let currentGroup of currentGroups) {
                await api.assignUserServiceWorkersToGroup(currentGroup.id, selectedUserServiceWorkers.map(x => x.id), token, enqueueSnackbar);
                if (currentGroup.objectUnitId && assignObjectOfGroup) {
                  await api.assignServiceWorkersToObject(currentGroup.objectUnitId, selectedUserServiceWorkers.map(x => x.id), token, enqueueSnackbar);
                }
              }
            }


            setLoading(false);
            handleClose();
            onRefresh();

          }}
        >
          Zuweisen
        </Button>

      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));