import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { PropertyType, UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { PropertySelectionDataTable } from './PropertySelectionDataTable';
import { PropertyMultipleSelectionDataTable } from './PropertyMultipleSelectionDataTable';
import { GroupSettingsType } from '../../../../models/GroupSettings';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignMultipleGroupToPropertyDialog = ({
  open,
  setOpen,
  groups,
  currentGroups,
  onRefresh,
  preSelectedProperties = [],
  onAdd,
  type = GroupSettingsType.Default,
  propertyType
}: {
  open: boolean,
  setOpen: any,
  currentGroups: GroupLicenseTable[],
  groups: GroupLicenseTable[],
  onRefresh: any,
  preSelectedProperties?: UserProperty[],
  onAdd?: any,
  type?: GroupSettingsType,
  propertyType?: PropertyType
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [properties, setProperties] = React.useState<UserProperty[]>([]);

  const [token, setToken] = React.useState("");
  const [selectedProperties, setSelectedProperties] = React.useState<UserProperty[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterPropertyType, setFilterPropertyType] = React.useState("");
  const {t} = useTranslation();

  const handleClose = () => {
    setSelectedProperties([]);
    setOpen(false);
  }

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);
      let tempProps = properties;
      if (tempProps.length < 1) {
        tempProps = await api.getUserProperties(tempToken);
        setProperties(tempProps);
      }
      if (propertyType == PropertyType.Standard || propertyType == PropertyType.System){
        setFilterPropertyType(UserProperty.GetLabelByType(propertyType));
      }else{
        setFilterPropertyType("");
      }
      setSelectedProperties(preSelectedProperties);

    })();

  }, [open]);
  const disabled = selectedProperties.length < 1;
  const title = type == GroupSettingsType.Default ? `${currentGroups.length} Einheiten` : 'Vorlagen'

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={false}
    >
      <DefaultDialogTitle
        title={`Wähle ein Merkmal für die ausgewählten ${title}`}
        handleClose={handleClose}
      />

      <DialogContent>
        <PropertyMultipleSelectionDataTable 
        filterPropertyType={filterPropertyType}
        rows={properties} groups={groups}
          selectedProperties={selectedProperties}
          onSelectionChange={(selection: string[]) => {
            setSelectedProperties(properties.filter(x => selection.some(s => s === x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        {!onAdd &&
          <Button
            variant="contained"
            color="error"
            size="large"
            startIcon={loading && <CircularProgress size={32} />}
            endIcon={<Delete />}
            disabled={disabled || loading}
            onClick={async () => {
              setLoading(true);
              for (let currentGroup of currentGroups) {
                await api.unAssignPropertiesFromGroup(currentGroup.id, selectedProperties.map(x => x.id), token, enqueueSnackbar);
              }

              onRefresh();
              handleClose();
              setLoading(false);
            }}
          >
            
            {t("myUnitsSettings.unassignSelectedFeaturesToUnits",{title: title})}
          </Button>
        }
        <Button
          variant="contained"
          color="primary"
          disabled={disabled || loading}
          startIcon={loading && <CircularProgress size={32} />}
          size="large"
          endIcon={<Add />}
          onClick={async () => {
            setLoading(true);
            if (onAdd) {
              await onAdd(selectedProperties);
            } else {
              for (let currentGroup of currentGroups) {
                await api.assignPropertiesToGroup(currentGroup.id, selectedProperties, token, enqueueSnackbar)
              }
            }
            onRefresh();
            handleClose();
            setLoading(false);
          }}
        >
          {t("myUnitsSettings.assignSelectedFeaturesToUnits",{title: title})}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));