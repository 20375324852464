import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Accessibility, Download } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { differenceInHours, differenceInMinutes, formatDistance, fromUnixTime } from 'date-fns';
import { de } from 'date-fns/locale';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime } from '../../../../../helpers/date-management';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { StandardWorkItem } from '../../../../../models/StandardItem';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { GroupSettings } from '../../../../../models/GroupSettings';
import { UserStockExpanded } from '../../../../../models/UserStockExpanded';
import { lightBlue, lightGreen, lightRed } from '../../../../../styles/colors';
import { UserStock } from '../../../../../models/UserStock';
import { UserAccountBasic } from '../../../../../models/UserAccountLight';
import { DownloadFileImageDialog } from '../../../admin/DownloadFileImageDialog';
import { FileBlobType } from '../../../../../models/FileBlobType';

export const ObjectStockTableExpanded = ({ userStocks, groups, userServiceWorkers, selectedObjectUnit, objects = [], usersInformation }
    : { userStocks: UserStockExpanded[], groups: GroupSettings[], userServiceWorkers: UserServiceWorker[], selectedObjectUnit?: ObjectUnit, objects?: ObjectUnit[], usersInformation: UserAccountBasic[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjektSession-${selectedObjectUnit?.name || "Alle-Objekte"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(15);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: UserStockExpanded, value: any } = props;
        const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
        const [showGroupDownloadDialog, setShowGroupDownloadDialog] = React.useState(false);

        if (row.objectId && [UserStockExpanded.Columns.currentObjectStock, UserStockExpanded.Columns.lastObjectStock, UserStockExpanded.Columns.diffObjectStock, UserStockExpanded.Columns.objectStockNumber].includes(column.name)) {
            return (
                <Table.Cell {...props} style={{ backgroundColor: row.materialStock.currentObjectStockAmount == row.materialStock.lastObjectStockAmount ? lightBlue : row.materialStock.currentObjectStockAmount < row.materialStock.lastObjectStockAmount ? lightRed : lightGreen }}>
                    <Typography >{value}</Typography>
                </Table.Cell>
            )
        }
        if (row.groupId && [UserStockExpanded.Columns.currentGroupStock, UserStockExpanded.Columns.lastGroupStock, UserStockExpanded.Columns.diffGroupStock, UserStockExpanded.Columns.groupStockNumber].includes(column.name)) {
            return (
                <Table.Cell {...props} style={{ backgroundColor: row.materialStock.currentGroupStockAmount == row.materialStock.lastGroupStockAmount ? lightBlue : row.materialStock.currentGroupStockAmount < row.materialStock.lastGroupStockAmount ? lightRed : lightGreen }}>
                    <Typography >{value}</Typography>
                </Table.Cell>
            )
        }
        if (UserStockExpanded.Columns.objectImages == column.name && row.objectId && row?.materialStock?.objectPhotos?.length > 0) {
            return (
                <Table.Cell {...props} style={{}}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Download />}
                        onClick={() => {
                            setShowDownloadDialog(true);
                        }}
                    >
                        Zeige {row.materialStock.objectPhotos.length} Bilder
                    </Button>
                    <DownloadFileImageDialog id={row.objectId} visible={showDownloadDialog} setVisible={setShowDownloadDialog} incidentPhotos={row.materialStock.objectPhotos} fileBlobType={FileBlobType.ObjectMaterialStockPhoto}
                        title={row.materialText} fotoSubDescription='Materialfoto'/>
                </Table.Cell>

            )
        }
        if (UserStockExpanded.Columns.groupImages == column.name && row.groupId && row?.materialStock?.groupPhotos?.length > 0) {
            return (
                <Table.Cell {...props} style={{ }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Download />}
                        onClick={() => {
                            setShowGroupDownloadDialog(true);
                        }}
                    >
                        Zeige {row.materialStock.groupPhotos.length} Bilder
                    </Button>
                    <DownloadFileImageDialog id={row.groupId} visible={showGroupDownloadDialog} setVisible={setShowGroupDownloadDialog} incidentPhotos={row.materialStock.groupPhotos} fileBlobType={FileBlobType.GroupMaterialStockPhoto}
                        title={row.materialText} fotoSubDescription='Materialfoto'/>
                </Table.Cell>

            )
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [

        {
            name: UserStockExpanded.Columns.objectId,
            title: 'Objekt',
            getCellValue: (row: UserStockExpanded) => {
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? "" : `${object?.name} ${object?.addressLine}`
                );
            }
        },
        {
            name: UserStockExpanded.Columns.groupId,
            title: 'Einheit',
            getCellValue: (row: UserStockExpanded) => {
                let group = groups.find(x => x.id == row.groupId);
                if (group) {
                    return `${group?.locationInformation?.notificationName} ${group?.locationInformation?.levelName}`
                }
                return "";
            }
        },
        {
            name: UserStockExpanded.Columns.materials,
            title: 'Material',
            getCellValue: (row: UserStockExpanded) => row.materialText,
        },
        {
            name: UserStock.Columns.userServiceWorkerId,
            title: 'Durchgeführt von',
            getCellValue: (row: UserStock) => {
                let userServiceWorker = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
                if (userServiceWorker) {
                    return `${userServiceWorker?.name}`
                }
                let user = usersInformation?.find(x => x.id == row.userId);
                if (user) {
                    return `${user.firstname} ${user.lastname} ${user.mail}`
                }
                return "Servicekraft" || "Manager"
            },
        },
        {
            name: UserStockExpanded.Columns.reason,
            title: 'Grund Lagerung',
            getCellValue: (row: UserStockExpanded) => row.reason,
        },

        {
            name: UserStockExpanded.Columns.objectImages,
            title: 'Objektbilder',
            getCellValue: (row: UserStockExpanded) => row?.materialStock?.objectPhotos?.length ? `Zeige ${row.materialStock.objectPhotos.length} Bilder`  : "",
        },
        {
            name: UserStockExpanded.Columns.groupImages,
            title: 'Einheitbilder',
            getCellValue: (row: UserStockExpanded) => row?.materialStock?.groupPhotos?.length ? `Zeige ${row.materialStock.groupPhotos.length} Bilder` : "",
        },
        {
            name: UserStockExpanded.Columns.createdTimestamp,
            title: 'Erstellungsdatum',
            getCellValue: (row: UserStockExpanded) => formatEuropeanDateTime(row.createdTimestamp),
        },
        {
            name: UserStockExpanded.Columns.currentObjectStock,
            title: 'Aktuell Objekt',
            getCellValue: (row: UserStockExpanded) => row.objectId && row.materialStock.currentObjectStockAmount,
        },
        {
            name: UserStockExpanded.Columns.lastObjectStock,
            title: 'Letzte Menge',
            getCellValue: (row: UserStockExpanded) => row.objectId && row.materialStock.lastObjectStockAmount,
        },
        {
            name: UserStockExpanded.Columns.diffObjectStock,
            title: 'Differenz',
            getCellValue: (row: UserStockExpanded) => row.objectId && row.materialStock.currentObjectStockAmount - row.materialStock.lastObjectStockAmount,
        },
        {
            name: UserStockExpanded.Columns.objectStockNumber,
            title: 'Lagerung Nr.',
            getCellValue: (row: UserStockExpanded) => row.objectId && row.materialStock.resetCountObject,
        },
        {
            name: UserStockExpanded.Columns.currentGroupStock,
            title: 'Aktuell Einheit',
            getCellValue: (row: UserStockExpanded) => row.groupId && row.materialStock.currentGroupStockAmount,
        },
        {
            name: UserStockExpanded.Columns.lastGroupStock,
            title: 'Letzte Menge',
            getCellValue: (row: UserStockExpanded) => row.groupId && row.materialStock.lastGroupStockAmount,
        },
        {
            name: UserStockExpanded.Columns.diffGroupStock,
            title: 'Differenz',
            getCellValue: (row: UserStockExpanded) => row.groupId && row.materialStock.currentGroupStockAmount - row.materialStock.lastGroupStockAmount,
        },
        {
            name: UserStockExpanded.Columns.groupStockNumber,
            title: 'Lagerung Nr.',
            getCellValue: (row: UserStockExpanded) => row.groupId && row.materialStock.resetCountGroup,
        },
        {
            name: UserStockExpanded.Columns.restockObjectCount,
            title: 'Fortlaufende Nr. Objekt',
            getCellValue: (row: UserStockExpanded) => row.restockObjectCount || "",
        },
        {
            name: UserStockExpanded.Columns.restockGroupCount,
            title: 'Fortlaufende Nr. Einheit',
            getCellValue: (row: UserStockExpanded) => row.groupId ? row.restockGroupCount : "keine Einheit",
        },
        {
            name: UserStockExpanded.Columns.stockActionDoneFromType,
            title: 'Aktion ausgeführt in',
            getCellValue: (row: UserStockExpanded) => UserStockExpanded.GetLabelByStockActionDoneFromType(row.stockActionDoneFromType),
        },

    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: UserStockExpanded.Columns.objectId, width: 350 },
        { columnName: UserStockExpanded.Columns.groupId, width: 200 },
        { columnName: UserStockExpanded.Columns.userServiceWorkerId, width: 200 },
        { columnName: UserStockExpanded.Columns.reason, width: 200 },
        { columnName: UserStockExpanded.Columns.restockObjectCount, width: 100 },
        { columnName: UserStockExpanded.Columns.restockGroupCount, width: 100 },
        { columnName: UserStockExpanded.Columns.stockActionDoneFromType, width: 320 },
        { columnName: UserStockExpanded.Columns.createdTimestamp, width: 150 },

        { columnName: UserStockExpanded.Columns.currentObjectStock, width: 150 },
        { columnName: UserStockExpanded.Columns.lastObjectStock, width: 150 },
        { columnName: UserStockExpanded.Columns.diffObjectStock, width: 150 },
        { columnName: UserStockExpanded.Columns.objectStockNumber, width: 150 },

        { columnName: UserStockExpanded.Columns.currentGroupStock, width: 150 },
        { columnName: UserStockExpanded.Columns.lastGroupStock, width: 150 },
        { columnName: UserStockExpanded.Columns.diffGroupStock, width: 150 },
        { columnName: UserStockExpanded.Columns.groupStockNumber, width: 150 },
        { columnName: UserStockExpanded.Columns.materials, width: 300 },
        { columnName: UserStockExpanded.Columns.objectImages, width: 150 },
        { columnName: UserStockExpanded.Columns.groupImages, width: 150 },




    ]);

    return (
        <Paper>
            <Grid
                rows={userStocks}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={userStocks}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};