import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, Room, Battery6Bar, Battery90, Hub, Router, SupervisedUserCircle, FeaturedVideo, TabletAndroid, HowToReg, SettingsInputHdmi, CleanHands, AddTask, Map, LibraryAdd, QrCodeScanner, Visibility, QrCode, Sensors, Cancel, Publish, Today } from "@mui/icons-material";
import React, { useContext } from "react";
import { api } from "../../../api";
import { getApiToken, setStorageForDrawer } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../models/GroupLicense";
import { useHistory } from "react-router-dom";
import LicenseSvg from '../../../assets/svgs/license.svg'
import RunFastSvg from '../../../assets/svgs/run-fast.svg'
import KeyChainVariant from '../../../assets/svgs/key-chain-variant.svg'
import { ReactComponent as MapMarkerMultiple } from '../../../assets/svgs/map-marker-multiple.svg'
import { ReactComponent as MapMarkerPlus } from '../../../assets/svgs/map-marker-plus.svg'

import KeyPlusIcon from '../../../assets/svgs/key-plus.svg'
import LockWhite from '../../../assets/svgs/lock_white.svg'

import { useSnackbar } from "notistack";
import { UserKeysDialog } from "../../dialogs/UserKeys";
import { UserKey } from "../../../models/UserKey";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { AddUserKeyDialog } from "../../dialogs/AddUserKey";
import { handleSelectedCombinationGroup } from "../../tables/Base";
import { green, red, white, yellow } from "../../../styles/colors";
import { SimplePanel } from "../../common/SimplePanel";
import { UserAccount } from "../../../models/UserAccount";
import { UserType } from "../../../models/UserType";
import { UserInvoice } from '../../../models/UserInvoice';
import { UserInvoicesTable } from '../../dialogs/UserInvoicesTable';
import { LicenseType } from '../../../models/LicenseType';
import { GroupSelectionTable } from '../../tables/GroupSelectionTable';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../dialogs/AssignLicenseChoosingTableDialog';
import { LicensesAdminTable } from '../../tables/LicensesAdminTable';
import { GROUP_ID, GROUP_NAME } from '../../../constants';
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import { AssignSensorLicenseChoosingTableDialog } from "../../dialogs/AssignSensorLicenseChoosingTableDialog";
import { LoadingIcon } from "../../common/LoadingIcon";
import { useQuery } from "./checkout/ShopPage";
import { getUserIdFromStorage } from "../../../helpers/common";
import { GroupType } from "../../../models/Group";
import { CheckpointGroupEditingDialog } from "../../dialogs/CheckpointGroupEditingDialog";
import { GroupSettings } from "../../../models/GroupSettings";
import { CheckpointSelectionDialog } from "../extendedapp/checkpoint/CheckpointSelectionDialog";
import { GlobalContext } from "../PortalPage";
import { AddHubDialog } from "../../dialogs/licenseManagement/AddHubDialog";
import { BatteryDisplayDialogChoice, BatteryDisplaySelectionDialog } from "../extendedapp/batteryDisplay/BatteryDisplaySelectionDialog";
import { BssdSelectionDialog } from "../extendedapp/bssdHub/BssdSelectionDialog";
import { HubModel } from "../../../models/HubModel";
import { SsdUnregisteredDevicesDialog } from "../extendedapp/bssdHub/SsdUnregisteredDevicesDialog";
import { GREEN_SMILE, LicenseWhite } from "../../../assets";
import { AssignMultipleLicenseChoosingTableDialog } from "../../dialogs/AssignMultipleLicenseChoosingTableDialog";
import { SsdDeviceModel } from "../../../models/bssd/SsdDeviceModel";
import { RegisteredDevicesSelectionDialog } from "../extendedapp/batteryDisplay/RegisteredDevicesSelectionDialog";
import { BssdRegisteredDeviceModel } from "../../../models/bssd/BssdRegisteredDeviceModel";
import { LicenseManagementTouchDisplayDialog, TouchDisplayDialogChoice, TouchDisplayLicenseActionChoice } from "./dialog/LicenseManagementTouchDisplayDialog";
import { TorkDevicesDialog } from "../extendedapp/torkDevices/TorkDevicesDialog";
import { TorkDevicesLocations } from "../extendedapp/torkDevices/TorkDevicesLocations";
import { AddLoraDeviceDialog } from "../../dialogs/licenseManagement/AddLoraDeviceDialog";
import { LoraDeviceModel } from "../../../models/lora/LoraDeviceModel";
import { LoraDevicesSelectionDialog } from "../../dialogs/licenseManagement/LoraDevicesSelectionDialog";
import { DefaultDialogTitle } from "../../dialogs/DefaultDialogTitle";
import { stringify } from "querystring";
import { ImportDataButtonCount } from "../extendedapp/ObjectManagementPageDataTable";
import { schemaImportCheckpoint } from "../../../helpers/excel/schemas";
import { ReactComponent as MicrosofExcel } from '../../../assets/svgs/microsoft-excel.svg'
import PayLicense from '../../../assets/image/license/pay_license.png'
import { useTranslation } from "react-i18next";

export const LicenseManagement = ({
    selectedCombinationGroup,
    setSelectedCombinationGroup,
    userAccount,
    selectedGroupId,
    setSelectedGroupName,
    setSelectedGroupId,
    setSelectedGroupType }
    : {
        selectedCombinationGroup: GroupLicenseTable[],
        setSelectedCombinationGroup: any,
        userAccount: UserAccount | undefined,
        selectedGroupId: string,
        setSelectedGroupName: any,
        setSelectedGroupId: any,
        setSelectedGroupType: any
    }) => {

    const history = useHistory();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const globals: any = useContext(GlobalContext);

    const [token, setToken] = React.useState("");
    const [backdropLoading, setBackdropLoading] = React.useState(false);
    const [checkpointCountAdd, setCheckpointCountAdd] = React.useState(1);
    const [freeLicenses, setFreeLicenses] = React.useState<GroupLicense[]>([]);
    const [checkpointLicenses, setCheckpointLicenses] = React.useState<GroupLicense[]>([]);
    const [qualityCheckLicenses, setQualityLicenses] = React.useState<GroupLicense[]>([]);
    const [taqtLicenses, setTaqtLicenses] = React.useState<GroupLicense[]>([]);

    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [userKeys, setUserKeys] = React.useState<UserKey[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);
    const [invoices, setInvoices] = React.useState<UserInvoice[]>([]);

    const [isTouchDisplayManagementVisible, setIsTouchDisplayManagementVisible] = React.useState(false);

    const [openUserKeysDialog, setOpenUserKeysDialog] = React.useState(false);
    const [openAddUserKeysDialog, setOpenAddUserKeysDialog] = React.useState(false);

    const [openAddHubDialog, setOpenAddHubDialog] = React.useState(false);

    const [openBssdRegisteredHubsDialog, setOpenBssdRegisteredHubsDialog] = React.useState(false);
    const [openSsdUnregisteredDevicesDialog, setOpenSsdUnregisteredDevicesDialog] = React.useState(false);


    const [openSsdRegisteredDevicesDialog, setOpenSsdRegisteredDevicesDialog] = React.useState(false);

    const [bssdGroupEditDialogVisible, setBssdGroupEditDialogVisible] = React.useState(false);
    const [currentSelectedBssdGroup, setCurrentSelectedBssdGroup] = React.useState<GroupSettings>();

    const [assignCheckpointLicenseDialogVisible, setAssignCheckpointLicenseDialogVisible] = React.useState(false);
    const [assignCheckpointLicenseExtentedDialogVisible, setAssignCheckpointLicenseExtentedDialogVisible] = React.useState(false);


    const [assignBssdLicenseDialogVisible, setAssignBssdLicenseDialogVisible] = React.useState(false);
    const [assignBssdSensorLicenseDialogVisible, setAssignBssdSensorLicenseDialogVisible] = React.useState(false);


    const [openLoraDeviceDialog, setOpenLoraDevicesDialog] = React.useState(false);
    const [openAddLoraDeviceDialog, setOpenAddLoraDeviceDialog] = React.useState(false);
    const [loraDevices, setLoraDevices] = React.useState<LoraDeviceModel[]>([]);
    const [isLoraDevicesLoading, setIsLoraLoading] = React.useState<boolean | undefined>(undefined);

    //multiple dialog

    const {t} = useTranslation();

    const [checkpointEditDialogVisible, setCheckpointEditDialogVisible] = React.useState(false);
    const [currentSelectedCheckpoint, setCurrentSelectedCheckpoint] = React.useState<GroupSettings>();
    const [currentSelectedCheckpointId, setCurrentSelectedCheckpointId] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [isInvoicesLoading, setIsInvoicesLoading] = React.useState<boolean | undefined>(undefined);
    const [isUserKeysLoading, setIsUserKeysLoading] = React.useState<boolean | undefined>(undefined);
    const [isBatteryDisplayLoading, setIsBatteryDisplayLoading] = React.useState<boolean | undefined>(undefined);

    const handleUserKeysClose = () => setOpenUserKeysDialog(false);
    const chosenUnit = useQuery(history).get("chosenUnit");
    const navigationForAddingCheckpoint = useQuery(history).get("navigationForAddingCheckpoint");
    const [isAccordionExpandedLicenseTable, setIsAccordionExpandedLicenseTable] = React.useState(false);
    const [isAllGroupsAccordionExpandedLicenseTable, setIsAllGroupsAccordionExpandedLicenseTable] = React.useState(false);

    const [checkpointSelectionDialogVisible, setCheckpointSelectionDialogVisible] = React.useState(false);


    const [batteryDisplaySelectionDialogVisible, setBatteryDisplaySelectionDialogVisible] = React.useState(false);
    const [hubs, setHubs] = React.useState<HubModel[]>([]);
    const [devices, setDevices] = React.useState<SsdDeviceModel[]>([]);
    const [registeredDevices, setRegisteredDevices] = React.useState<BssdRegisteredDeviceModel[]>([]);
    const [currentSelectedBssdGroupId, setCurrentSelectedBssdGroupId] = React.useState<string>("");
    const [currentSelectedBssdGroupLicenseTable, setCurrentSelectedBssdGroupLicenseTable] = React.useState<GroupLicenseTable>();


    const [openTorkDevicesDialog, setOpenTorkDevicesDialog] = React.useState(false);
    const [openTorkLocationsDialog, setOpenTorkLocationsDialog] = React.useState(false);

    const [assignMultipleCheckpoints, setAssignMultipleCheckpoints] = React.useState(false);
    const [openAllGroupsLicenseDialog, setOpenAllGroupsLicenseDialog] = React.useState(false);


    const assignBssdSensorLicense = (groupId: string) => {
        setCurrentSelectedBssdGroupId(groupId);
        setAssignBssdSensorLicenseDialogVisible(true);
    }


    const assignBssdDisplayLicense = (groupId: string) => {
        setCurrentSelectedBssdGroupId(groupId);
        setAssignBssdLicenseDialogVisible(true);
    }

    const loadLoraData = async () => {
        const tempUserKeys = await api.getLoraDevices(token);
        setLoraDevices(tempUserKeys);
    }

    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(null);
        setSelectedGroups([]);
        setLoading(true);

        const licenses = await api.getLicensesAsync(tempToken);
        setFreeLicenses(licenses.filter(el => !el.isUsed && el.type != LicenseType.ObjectNotificationLog));
        setCheckpointLicenses(licenses.filter(el => (el.checkpointCount > 0)));
        setTaqtLicenses(licenses.filter(el => (el.taqtFeedbackCount > 0)));
        setQualityLicenses(licenses.filter(el => el.qualityCheckCount > 0));

        const tempGroups = await api.getAllGroups(tempToken);
        setGroups(tempGroups);
        let isGroupPreChosen = tempGroups.find(el => el.id == chosenUnit);
        if (isGroupPreChosen) {
            setSelectedGroup(isGroupPreChosen);
            setSelectedGroups([isGroupPreChosen]);
        }




        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();

            if (navigationForAddingCheckpoint) {
                setAssignMultipleCheckpoints(false);
                addCheckpoint();
            }
        })();
    }, []);

    React.useEffect(() => {
        setSelectedGroups(selectedCombinationGroup.map((el: any) => el.id));
    }, [selectedCombinationGroup]);




    const navigateToGroup = (currentGroup: GroupLicenseTable) => {

        globals.onUpdateDisplayGroup(currentGroup);

    }

    const navigateByLicenseTable = (selectedGroup: GroupLicenseTable) => {
        if (!selectedGroup) return;
        navigateToGroup(selectedGroup);
    }



    const addCheckpoint = () => {
        if (userAccount?.userType == UserType.Customer || userAccount?.userType == UserType.ObjectCustomer) {
            enqueueSnackbar("Kunde kann nicht Checkpoint hinzufügen. Wenden Sie sich an den Besitzer!", { variant: 'error' });
            return;
        }

        setAssignCheckpointLicenseDialogVisible(true);
    }

    const createCheckpoint = async (userId: string, selectedLicense: GroupLicense, notificationName: string, levelName: string, objectName: string, customerName: string) => {
        let res = await api.createGroupCheckpoint(userId, selectedLicense.id, token, enqueueSnackbar);
        return await api.createGroupSettings(GroupSettings.CreateCheckpointSetting(notificationName, objectName, levelName, customerName, res.id), token, enqueueSnackbar);
    }

    const loadBssdDevices = async () => {
        setIsBatteryDisplayLoading(true);
        const tempRegisteredDevices = await api.getBssdRegisteredDevices(token);
        setRegisteredDevices(tempRegisteredDevices);

        const tempHubs = await api.getBssdRegisteredHubs(token);
        setHubs(tempHubs);

        const tempDevices = await api.getBssdUnregisteredDevices(token);
        setDevices(tempDevices);
        setIsBatteryDisplayLoading(false);
    }


    const buildCheckpointPanel = () => {
        return (
            <Grid item xs={12} lg={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}                    >
                        <Typography component="h4" variant="h4">Lizenzierte Checkpoints</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item md={4}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ minHeight: 60 }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Checkpoints</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.Checkpoint).length}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => {
                                                        setCheckpointSelectionDialogVisible(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<QrCode />}
                                                >
                                                    Checkpoints Anzeigen
                                                </Button>
                                                <UserKeysDialog userKeys={userKeys} open={openUserKeysDialog} onClose={handleUserKeysClose} />
                                            </Grid>
                                            <Grid item>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <Visibility fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ minHeight: 60 }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Verfügbare Lizenzen</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.Checkpoint).reduce((prevVal, curVal) => curVal.checkpointCount + prevVal, 0)}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={async () => {
                                                        setAssignMultipleCheckpoints(false);
                                                        addCheckpoint();
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<AddBoxIcon />}
                                                >
                                                    Checkpoint Hinzufügen
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <MapMarkerPlus fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ minHeight: 60 }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        label="Anzahl Checkpoints"
                                                        type="number"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end"><LibraryAdd /></InputAdornment>
                                                        }}
                                                        value={checkpointCountAdd}
                                                        onChange={(event: any) => {
                                                            setCheckpointCountAdd(event.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={async () => {
                                                        setAssignMultipleCheckpoints(true);
                                                        addCheckpoint();
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    size="large"
                                                    endIcon={<AddBoxIcon />}
                                                >
                                                    Mehrere Checkpoints
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <QrCodeScanner fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={6}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ minHeight: 60 }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                md={2}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Verfügbare Lizenzen</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.Checkpoint).reduce((prevVal, curVal) => curVal.checkpointCount + prevVal, 0)}</Typography >
                                                </Grid>
                                            </Grid>

                                            <Grid md={8} item container direction="column" alignItems={"center"} justifyContent="center">
                                                <Grid item>
                                                    <Button href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Checkpoints.xlsx">Vorlage Import Checkpoints Download</Button>
                                                </Grid>
                                                <Grid>
                                                    <ImportDataButtonCount title="Import Checkpoints von Excel-Spreadsheet" schema={schemaImportCheckpoint} onFinish={async (row: any) => {
                                                        const userId = getUserIdFromStorage();
                                                        for (let license of checkpointLicenses) {
                                                            if (license.checkpointCount > 0) {
                                                                await createCheckpoint(userId || "", license, row["Einheit Name"], row["Ebene"], "", "");
                                                                license.checkpointCount = license.checkpointCount - 1;
                                                                break;
                                                            }
                                                        }

                                                    }} />

                                                </Grid>
                                            </Grid>
                                            <Grid md={2} item>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <MicrosofExcel fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }






    const buildLoraDevicePanel = () => {
        const height = 130;
        return (
            <Grid item xs={12} lg={12}>
                <Accordion onChange={async (event, expanded) => {
                    if (expanded && userKeys.length < 1) {
                        setIsLoraLoading(true);
                        await loadLoraData();
                        setIsLoraLoading(false);
                    }

                }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Sensors style={{ fontSize: 40, marginRight: 10 }} /><Typography component="h4" variant="h4">   Lizenzierte Lorasensoren </Typography>
                        <br />
                        {isLoraDevicesLoading &&
                            <CircularProgress size={64} />
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} alignItems="center" justifyContent={"center"} alignContent="center">
                            <Grid item md={4}>
                                <Paper style={{ height: height }} className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Lorageräte</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{loraDevices.length}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() => {
                                                        setOpenLoraDevicesDialog(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<Sensors />}
                                                >
                                                    Lorasensor Anzeigen
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <Sensors fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper style={{ height: height }} className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Verfügbare Lizenzen</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.LoraSensorExtension).reduce((prevVal, curVal) => curVal.loraSensorCount + prevVal, 0)}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={async () => {
                                                        setOpenAllGroupsLicenseDialog(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<LicenseWhite />}
                                                >
                                                    Lorasensorlizenzen zu Einheiten zuweisen
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <LicenseWhite />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={4}>
                                <Paper style={{ height: height }} className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Registriere für deinen User einen Lora Sensor</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={async () => {
                                                        setOpenAddLoraDeviceDialog(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<AddBoxIcon />}
                                                >
                                                    Lorasensor Hinzufügen
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <Sensors fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildUserKeysPanel = () => {
        return (
            <Grid item xs={12} lg={12}>
                <Accordion onChange={async (event, expanded) => {
                    if (expanded && userKeys.length < 1) {
                        setIsUserKeysLoading(true);
                        const tempUserKeys = await api.getUserKeys(token);
                        setUserKeys(tempUserKeys);
                        setIsUserKeysLoading(false);
                    }

                }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography component="h4" variant="h4">Touch Display Keys (Aktivierung)</Typography>

                                {isUserKeysLoading &&
                                    <CircularProgress size={64} />
                                }
                            </Grid>
                            <Grid item>

                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {isUserKeysLoading === undefined ? "Ausklappen, um Keys zu laden" : ""}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Touch Display Keys</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{userKeys.length}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() => {
                                                        setOpenUserKeysDialog(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<VpnKey />}
                                                >
                                                    Touch Display Keys Anzeigen
                                                </Button>
                                                <UserKeysDialog userKeys={userKeys} open={openUserKeysDialog} onClose={handleUserKeysClose} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <img src={KeyChainVariant} />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={6}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Max. User Keys</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{3}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={async () => {

                                                        if (userAccount?.userType == UserType.Manager) {
                                                            enqueueSnackbar("Manager kann nicht Lizenz-Keys hinzufügen. Wenden Sie sich an den Besitzer!", { variant: 'error' });
                                                            return;
                                                        }

                                                        setOpenAddUserKeysDialog(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<AddBoxIcon />}
                                                >
                                                    Lizenz-Key Generieren
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <img src={KeyPlusIcon} />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildAllGroupsPanel = () => {
        return (
            <Grid item xs={12}>
                <Accordion expanded={!!chosenUnit || isAllGroupsAccordionExpandedLicenseTable} >
                    <AccordionSummary onClick={() => { setIsAllGroupsAccordionExpandedLicenseTable(!isAllGroupsAccordionExpandedLicenseTable) }} expandIcon={<ExpandMore />}                    >
                        <Typography component="h4" variant="h4">Alle Lizenzierte Einheiten</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AllLicensedGroups allLicensedGroupsDialogType={AllLicensedGroupsDialogType.None} loading={loading} groups={groups} selectedGroup={selectedGroup} navigateByLicenseTable={navigateByLicenseTable} selectedGroups={selectedGroups} setSelectedCombinationGroup={setSelectedCombinationGroup} freeLicenses={freeLicenses} setSelectedGroups={setSelectedGroups} setSelectedGroup={setSelectedGroup} loadDataAsync={loadDataAsync} setGroups={setGroups} qualityCheckLicenses={qualityCheckLicenses} licenses={[]} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildAllGroupsLicenseDialog = ({ open, setOpen, onRefresh }
        : { open: boolean, setOpen: any, onRefresh: any }) => {

        const handleClose = () => setOpen(false);

        return (
            <Dialog
                fullScreen
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle
                    title="Alle Lizenzierte Einheiten"
                    handleClose={handleClose}
                />
                <DialogContent dividers>
                    <AllLicensedGroups allLicensedGroupsDialogType={AllLicensedGroupsDialogType.None} loading={loading} groups={groups} selectedGroup={selectedGroup} navigateByLicenseTable={navigateByLicenseTable} selectedGroups={selectedGroups} setSelectedCombinationGroup={setSelectedCombinationGroup} freeLicenses={freeLicenses} setSelectedGroups={setSelectedGroups} setSelectedGroup={setSelectedGroup} loadDataAsync={loadDataAsync} setGroups={setGroups} qualityCheckLicenses={qualityCheckLicenses} licenses={[]} />
                </DialogContent>
                <DialogActions>
                    <Button
                        size="large"
                        startIcon={<Cancel />}
                        variant={'outlined'}
                        onClick={handleClose}
                        disabled={loading}
                        color="primary"
                    >
                        {t("myUnitsSettings.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >
            <Grid item xs={12} md={6} lg={3}>
                <SimplePanel
                    color={green}
                    svgIcon={LicenseSvg}
                    title={'Aktivierte Einheiten'}
                    result={groups.length}
                    selectedGroupId={selectedGroupId}
                    selectedCombinationGroup={selectedCombinationGroup}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <SimplePanel
                    color={yellow}
                    svgIcon={LicenseSvg}
                    title={'Freie Lizenzen'}
                    result={freeLicenses.filter(el => el.purchaseTimestamp).length}
                    selectedGroupId={selectedGroupId}
                    selectedCombinationGroup={selectedCombinationGroup}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <SimplePanel
                    color={red}
                    svgIcon={LockWhite}
                    title={'Abgelaufene Lizenzen'}
                    result={groups.filter(el => el.deviceLicenseIsExpired || el.adLicenseIsExpired || el.qualityCheckLicenseIsExpired).length}
                    selectedGroupId={selectedGroupId}
                    selectedCombinationGroup={selectedCombinationGroup}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <SimplePanel
                    color={green}
                    svgIcon={RunFastSvg}
                    title={'Laufende Lizenzen'}
                    result={groups.filter(el => !el.deviceLicenseIsExpired).length}
                    selectedGroupId={selectedGroupId}
                    selectedCombinationGroup={selectedCombinationGroup}
                />
            </Grid>

            {
                invoices &&
                token &&
                <Grid item xs={12}>
                    <Accordion onChange={async (event, expanded) => {
                        if (expanded && invoices.length < 1) {
                            setIsInvoicesLoading(true);
                            const tempInvoices = await api.getUserInvoicesAsync(token);
                            setInvoices(tempInvoices);
                            setIsInvoicesLoading(false);
                        }

                    }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography component="h4" variant="h4">
                                    {t("orders.myOrders")} {isInvoicesLoading ? "(wird geladen)" : ""}
                                    </Typography >
                                    {isInvoicesLoading &&
                                        <CircularProgress size={64} />
                                    }
                                </Grid>
                                <Grid item>

                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {isInvoicesLoading === undefined ? "Ausklappen, um Bestellungen zu laden" : invoices.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserInvoicesTable
                                rows={invoices}
                                token={token}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }

            {buildUserKeysPanel()}

            {buildLoraDevicePanel()}

            {buildAllGroupsPanel()}

            {buildCheckpointPanel()}






            <Grid item xs={12} lg={12}>
                <Accordion onChange={async (event, expanded) => {
                    if (expanded) {
                        await loadBssdDevices();
                    }
                }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>

                                <Typography component="h4" variant="h4">
                                    Lizenzierte Batterie Smart-Service-Displays & Hubs {isBatteryDisplayLoading ? "(wird geladen)" : ""}
                                </Typography>

                                {isBatteryDisplayLoading &&
                                    <CircularProgress size={64} />
                                }
                            </Grid>
                            <Grid item>

                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {isBatteryDisplayLoading === undefined ? "Ausklappen, um Geräte zu laden" : ""}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction="column" spacing={3}>
                            <Grid container item direction="row" alignItems={"center"} spacing={3} justifyContent={"center"}>
                                <Grid item md={6}>
                                    <Paper className="Paper" >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    xs={4}
                                                    item
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid item>
                                                        <Typography variant="body1" component="p" color="textSecondary">Hubs</Typography >
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="Paper-Result" component="p">{hubs.length}</Typography >
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenBssdRegisteredHubsDialog(true);
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        endIcon={<Hub />}
                                                    >
                                                        Registrierte Hubs Anzeigen
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                        <Hub fill="white" />
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item md={6}>
                                    <Paper className="Paper" >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    xs={4}
                                                    item
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid item>
                                                        <Typography variant="body1" component="p" color="textSecondary">Verfügbare Hubs</Typography >
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="Paper-Result" component="p">0</Typography >
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={async () => {
                                                            setOpenAddHubDialog(true);
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        endIcon={<Router />}
                                                    >
                                                        Hub Hinzufügen
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                        <Router fill="white" />
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"} spacing={3} justifyContent={"center"}>
                                <Grid item md={6}>
                                    <Paper className="Paper" >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    xs={4}
                                                    item
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid item>
                                                        <Typography variant="body1" component="p" color="textSecondary">Registrierte Devices</Typography >
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="Paper-Result" component="p">{registeredDevices.length}</Typography >
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenSsdRegisteredDevicesDialog(true);
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        endIcon={<HowToReg />}
                                                    >
                                                        Registrierte Devices Anzeigen
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                        <HowToReg fill="white" />
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item md={6}>
                                    <Paper className="Paper" >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    xs={4}
                                                    item
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid item>
                                                        <Typography variant="body1" component="p" color="textSecondary">Freie Devices</Typography >
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="Paper-Result" component="p">{devices.length}</Typography >
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={async () => {
                                                            setOpenSsdUnregisteredDevicesDialog(true);
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        endIcon={<AddBoxIcon />}
                                                    >
                                                        Unregistriertes Gerät Aktivieren
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                        <SettingsInputHdmi fill="white" />
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"} spacing={3} justifyContent={"center"}>
                                <Grid item md={12}>
                                    <Paper className="Paper" >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    xs={4}
                                                    item
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid item>
                                                        <Typography variant="body1" component="p" color="textSecondary">Batterie Displays</Typography >
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.DisplayBatteryWallApp).length}</Typography >
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() => {
                                                            setBatteryDisplaySelectionDialogVisible(true);
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        endIcon={<Battery90 />}
                                                    >
                                                        Batterie Displays Anzeigen
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                        <Battery90 fill="white" />
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>


            <Grid item xs={12}>
                <Accordion expanded={!!chosenUnit || isAccordionExpandedLicenseTable} >
                    <AccordionSummary onClick={() => { setIsAccordionExpandedLicenseTable(!isAccordionExpandedLicenseTable) }} expandIcon={<ExpandMore />}                    >
                        <Typography component="h4" variant="h4">Lizenzierte Touch Smart-Service-Displays</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading && <LoadingIcon loading={loading} icon={null} />}
                        <Grid container item direction="row" alignItems={"center"} spacing={3} justifyContent={"center"}>
                            <Grid item md={12}>
                                <Paper className="Paper" >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                                container
                                                direction="column"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Touch Displays</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.DisplayWallApp).length}</Typography >
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() => {
                                                        setIsTouchDisplayManagementVisible(true);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    endIcon={<TabletAndroid />}
                                                >
                                                    Touch Displays Verwalten
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Avatar style={{ backgroundColor: theme.palette.primary.main, marginLeft: 'auto' }}>
                                                    <TabletAndroid fill="white" />
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            {

                freeLicenses &&
                <Grid item xs={12} lg={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}                    >
                            <Typography component="h4" variant="h4">Freie Lizenzen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                freeLicenses &&
                                freeLicenses.length > 0 &&
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <LicensesAdminTable
                                            licenses={freeLicenses}
                                            setLicenses={setFreeLicenses}
                                            user={userAccount}
                                            onComplete={loadDataAsync}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {
                                freeLicenses &&
                                freeLicenses.length === 0 &&
                                <Paper className="Paper">
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <Typography
                                                component="h5"
                                                variant="h5"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Sie haben keine Lizenzen zum zuweisen! Bitte kaufen Sie einen neuen Lizenz im unserem Shop!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                endIcon={<ShoppingCart />}
                                                onClick={() => {
                                                    history.push(`/portal/shop`);
                                                }}
                                            >
                                                Zum Shop
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }

            <AddUserKeyDialog
                open={openAddUserKeysDialog}
                setOpen={setOpenAddUserKeysDialog}
                setUserKeys={setUserKeys}
            />

            <AddHubDialog onRefresh={async () => { await loadDataAsync() }} open={openAddHubDialog} setOpen={setOpenAddHubDialog} />
            <AddLoraDeviceDialog onRefresh={async () => { await loadDataAsync() }} open={openAddLoraDeviceDialog} setOpen={setOpenAddLoraDeviceDialog} />
            <LoraDevicesSelectionDialog
                groups={groups}
                assignSensorLicense={() => {
                    setOpenLoraDevicesDialog(false);
                    setOpenAllGroupsLicenseDialog(true);
                }}
                open={openLoraDeviceDialog}
                setOpen={setOpenLoraDevicesDialog}
                onRefresh={async () => {
                    setIsLoraLoading(true);
                    await loadLoraData();
                    setIsLoraLoading(false);
                }}
                devices={loraDevices} />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.Checkpoint}
                visible={assignCheckpointLicenseDialogVisible}
                licenses={checkpointLicenses}
                onClose={() => {
                    setAssignCheckpointLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    const userId = getUserIdFromStorage();
                    if (!userId) return;
                    if (!assignMultipleCheckpoints) {
                        let res = await api.createGroupCheckpoint(userId, selectedLicense.id, token, enqueueSnackbar);
                        setAssignCheckpointLicenseDialogVisible(false);
                        setBackdropLoading(true);
                        let groupSettings = await api.createGroupSettings(GroupSettings.CreateCheckpointSetting("", "", "", "", res.id), token, enqueueSnackbar);
                        setBackdropLoading(false);
                        setCurrentSelectedCheckpoint(groupSettings);
                        setCheckpointEditDialogVisible(true);
                    } else {

                        let i = checkpointCountAdd;
                        while (i > 0) {
                            try {
                                await createCheckpoint(userId, selectedLicense, "", "", "", "");
                            } catch { }
                            i--;
                        }
                        enqueueSnackbar(`${checkpointCountAdd} Checkpoints wurden hinzugefügt`, { variant: 'success' });
                        setAssignCheckpointLicenseDialogVisible(false);
                    }

                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.Checkpoint}
                visible={assignCheckpointLicenseExtentedDialogVisible}
                licenses={checkpointLicenses}
                onClose={() => {
                    setAssignCheckpointLicenseExtentedDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    await api.assignLicenseToGroup(currentSelectedCheckpointId, selectedLicense, token, enqueueSnackbar);
                    setAssignCheckpointLicenseExtentedDialogVisible(false);
                }}
            />




            {currentSelectedCheckpoint &&
                <CheckpointGroupEditingDialog
                    onRefresh={loadDataAsync}
                    visible={checkpointEditDialogVisible}
                    setVisible={setCheckpointEditDialogVisible}
                    group={currentSelectedCheckpoint}
                    onSave={async (newSettings: GroupSettings) => {

                        await api.updateGroupSettings(newSettings, token, enqueueSnackbar);
                    }}
                />
            }


            <CheckpointSelectionDialog
                open={checkpointSelectionDialogVisible}
                setOpen={setCheckpointSelectionDialogVisible}
                groups={groups.filter(x => x.groupType == GroupType.Checkpoint)}
                onEdit={async (group: GroupLicenseTable) => {
                    let settings = await api.getGroupSettings(group.id, token);
                    setCurrentSelectedCheckpoint(settings);
                    setCheckpointEditDialogVisible(true);

                }}
                groupType={GroupType.Checkpoint}
                onExtendLicense={async (group: GroupLicenseTable) => {
                    setCurrentSelectedCheckpointId(group.id);
                    setAssignCheckpointLicenseExtentedDialogVisible(true);
                }}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}
            />
            <TorkDevicesDialog open={openTorkDevicesDialog} setOpen={setOpenTorkDevicesDialog} />
            <TorkDevicesLocations open={openTorkLocationsDialog} setOpen={setOpenTorkLocationsDialog} />
            {hubs.length > 0 &&
                <BssdSelectionDialog open={openBssdRegisteredHubsDialog} setOpen={setOpenBssdRegisteredHubsDialog} hubs={hubs} />
            }
            <SsdUnregisteredDevicesDialog open={openSsdUnregisteredDevicesDialog} setOpen={setOpenSsdUnregisteredDevicesDialog} devices={devices}
                onFinish={async () => {
                    await loadDataAsync();
                    await loadBssdDevices();
                }} />
            <RegisteredDevicesSelectionDialog
                groups={groups}
                assignBssdSensorLicense={() => {
                    setOpenSsdRegisteredDevicesDialog(false);
                    setBatteryDisplaySelectionDialogVisible(true);
                }}
                assignBssdDisplayLicense={assignBssdDisplayLicense}
                open={openSsdRegisteredDevicesDialog}
                setOpen={setOpenSsdRegisteredDevicesDialog}
                onRefresh={async () => {
                    await loadBssdDevices();
                    await loadDataAsync();
                }}
                devices={registeredDevices}
                onFinish={() => {
                    setAssignBssdLicenseDialogVisible(true);
                }} />
            <BatteryDisplaySelectionDialog
                open={batteryDisplaySelectionDialogVisible}
                setOpen={setBatteryDisplaySelectionDialogVisible}
                groups={groups.filter(x => x.groupType == GroupType.DisplayBatteryWallApp)}
                freeLicenses={freeLicenses}
                loadData={async () => {
                    await loadBssdDevices();
                    await loadDataAsync();
                }}
                dialogChoice={BatteryDisplayDialogChoice.None}
                onEdit={async (group: GroupLicenseTable) => {
                    let settings = await api.getGroupSettings(group.id, token);
                    setCurrentSelectedBssdGroup(settings);
                    setBssdGroupEditDialogVisible(true);
                }}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {buildAllGroupsLicenseDialog({ open: openAllGroupsLicenseDialog, setOpen: setOpenAllGroupsLicenseDialog, onRefresh: loadDataAsync })}
            <LicenseManagementTouchDisplayDialog licenseActionChoice={TouchDisplayLicenseActionChoice.None} dialogChoice={TouchDisplayDialogChoice.None} visible={isTouchDisplayManagementVisible} setVisible={setIsTouchDisplayManagementVisible} groups={groups} setGroups={setGroups} freeLicenses={freeLicenses} handleSelectedCombinationGroup={handleSelectedCombinationGroup} navigateByLicenseTable={navigateByLicenseTable} loadDataAsync={loadDataAsync} />
        </Grid >
    );
}


export enum AllLicensedGroupsDialogType {
    QualityCheck = 0,
    Lora = 1,
    PublicFeedback = 2,
    PayPerService = 3,
    CalendarPro = 4,
    None = 255
}

export const AllLicensedGroups = ({ allLicensedGroupsDialogType, loading, groups, selectedGroup, navigateByLicenseTable, selectedGroups, setSelectedCombinationGroup, freeLicenses, setSelectedGroups, setSelectedGroup, loadDataAsync, setGroups, qualityCheckLicenses, licenses }:
    { allLicensedGroupsDialogType: AllLicensedGroupsDialogType, loading: boolean, groups: GroupLicenseTable[], selectedGroup: any, navigateByLicenseTable: any, selectedGroups: any[], setSelectedCombinationGroup: any, freeLicenses: GroupLicense[], setSelectedGroups: any, setSelectedGroup: any, loadDataAsync: any, setGroups: any, qualityCheckLicenses: GroupLicense[], licenses: GroupLicense[] }) => {
    const [assignLoraSensorDialogVisible, setAssignLoraSensorDialogVisible] = React.useState(false);
    const [assignLoraSensorLicenseChooserDialogVisible, setAssignLoraSensorLicenseChooserDialogVisible] = React.useState(false);
    const classes = useStyles();
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { userAccount }: any = useContext(GlobalContext);
    const [assignQualityCheckLicenseDialogVisible, setAssignQualityCheckLicenseDialogVisible] = React.useState(false);
    const [assignMultipleQualityCheckLicenseDialogVisible, setAssignMultipleQualityCheckLicenseDialogVisible] = React.useState(false);
    const [assignMultiplePublicFeedbackLicenseDialogVisible, setAssignMultiplePublicFeedbackLicenseDialogVisible] = React.useState(false);
    const [assignMultiplePayPerServiceLicenseDialogVisible, setAssignMultiplePayPerServiceLicenseDialogVisible] = React.useState(false);
    const [assignMultipleCalendarProLicenseDialogVisible, setAssignMultipleCalendarProLicenseDialogVisible] = React.useState(false);

    const {t} = useTranslation();

    const [selectedLicense, setSelectedLicense] = React.useState<GroupLicense>();
    const [token, setToken] = React.useState("");
    const history = useHistory();

    const assignLicense = async (selectedLicense: GroupLicense, setVisibleDialog: any = null) => {
        if (!selectedLicense || !selectedGroup) return;

        if (setVisibleDialog) setVisibleDialog(false);

        await api.assignLicenseToGroup(selectedGroup.id, selectedLicense, token, enqueueSnackbar);
        await loadDataAsync();
    }

    const assignLicenseToSpecificGroup = async (selectedLicense: GroupLicense, currentGroup: GroupLicenseTable) => {
        await api.assignLicenseToGroup(currentGroup.id, selectedLicense, token, enqueueSnackbar);
    }

    React.useEffect(() => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);
    }, []);


    const buildButtonQualityCheckLicense = () => {
        return (
            <>
                {selectedGroups.length < 2 &&
                    <Grid item lg={3}>
                        <Button
                            disabled={!selectedGroup}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="primary"
                            startIcon={LicenseWhite()}
                            endIcon={<SupervisedUserCircle />}
                            onClick={() => {
                                setAssignQualityCheckLicenseDialogVisible(true);
                            }}
                        >
                            {selectedGroup && selectedGroup.qualityCheckLicenseIsValid ? "Qualitätscheck erweitern" : "Qualitätscheck zuweisen"}
                        </Button>
                    </Grid>
                }
                {selectedGroups.length > 1 &&
                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            color="primary"
                            startIcon={LicenseWhite()}
                            endIcon={<SupervisedUserCircle />}
                            onClick={() => {
                                setAssignMultipleQualityCheckLicenseDialogVisible(true);
                            }}
                        >
                            {`Qualitätscheck für ${selectedGroups.length} Einheiten zuweisen`}
                        </Button>
                    </Grid>
                }
            </>
        )
    }

    const buildButtonCalendarProLicense = () => {
        return (
            <>
                <Grid item lg={3}>
                    <Button
                        disabled={selectedGroups.length < 1}
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        startIcon={LicenseWhite()}
                        endIcon={<Today />}
                        onClick={() => {
                            setAssignMultipleCalendarProLicenseDialogVisible(true);
                        }}
                    >
                        {`Kalender Pro für ${selectedGroups.length} Einheiten zuweisen`}
                    </Button>
                </Grid>

            </>
        )
    }

    const buildButtonPublicFeedbackLicense = () => {
        return (
            <>
                <Grid item lg={3}>
                    <Button
                        disabled={selectedGroups.length < 1}
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        startIcon={LicenseWhite()}
                        endIcon={<img style={{ height: 50, width: 50 }} src={GREEN_SMILE} />}
                        onClick={() => {
                            setAssignMultiplePublicFeedbackLicenseDialogVisible(true);
                        }}
                    >
                        {`Öffentliches Feedback für ${selectedGroups.length} Einheiten zuweisen`}
                    </Button>
                </Grid>

            </>
        )
    }

    const buildButtonPayPerServiceLicense = () => {
        return (
            <>
                <Grid item lg={3}>
                    <Button
                        disabled={selectedGroups.length < 1}
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        startIcon={LicenseWhite()}
                        endIcon={<img style={{ height: 50, width: 50 }} src={PayLicense} />}
                        onClick={() => {
                            setAssignMultiplePayPerServiceLicenseDialogVisible(true);
                        }}
                    >
                        {`Pay Per Service für ${selectedGroups.length} Einheiten zuweisen`}
                    </Button>
                </Grid>

            </>
        )
    }


    const buildButtonLoraLicense = () => {
        return <>
            <Grid item lg={3}>
                <Button
                    disabled={selectedGroups.length != 1 && !selectedGroup}
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    startIcon={LicenseWhite()}
                    endIcon={<Sensors />}
                    onClick={() => {
                        setAssignLoraSensorDialogVisible(true);
                    }}
                >
                    Lora Sensor Lizenz zuweisen
                </Button>
            </Grid>

        </>
    }

    const buildLicenseButtons = () => {
        switch (allLicensedGroupsDialogType) {
            case AllLicensedGroupsDialogType.Lora:
                return buildButtonLoraLicense();
            case AllLicensedGroupsDialogType.QualityCheck:
                return buildButtonQualityCheckLicense();
            case AllLicensedGroupsDialogType.PublicFeedback:
                return buildButtonPublicFeedbackLicense();
            case AllLicensedGroupsDialogType.PayPerService:
                return buildButtonPayPerServiceLicense();
            case AllLicensedGroupsDialogType.CalendarPro:
                return buildButtonCalendarProLicense();
            default:
                return (
                    <>
                        {buildButtonLoraLicense()}
                        {buildButtonQualityCheckLicense()}
                        {buildButtonPublicFeedbackLicense()}
                        {buildButtonPayPerServiceLicense()}
                        {buildButtonCalendarProLicense()}
                    </>
                )
        }

    }

    return (
        <>
            {loading && <LoadingIcon loading={loading} icon={null} />}
            {
                groups.length > 0 && !loading &&
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Button
                            disabled={!selectedGroup}
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<Navigation />}
                            onClick={() => {
                                if (!selectedGroup) return;
                                navigateByLicenseTable(selectedGroup);
                            }}
                        >
                            Zur Einheit navigieren
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={selectedGroups.length < 2}
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<MergeType />}
                            onClick={() => {
                                handleSelectedCombinationGroup(selectedGroups, groups, setSelectedCombinationGroup, history);
                            }}
                        >
                            Einheiten kombinieren
                        </Button>
                    </Grid>
                    {
                        userAccount?.userType == UserType.Admin &&
                        selectedGroups.length > 0 &&
                        <Grid item>
                            <Button
                                className={classes.deleteButton}
                                variant="contained"
                                size="large"
                                startIcon={<Close />}
                                disabled={selectedGroups.length < 1}
                                onClick={() => {

                                    if (selectedGroups.length < 1) return;
                                    setDeleteConfirmationDialogVisible(true);
                                }}
                            >
                                Einheit löschen
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item style={{ maxWidth: '100%' }}>
                        <GroupSelectionTable
                            groups={groups}
                            selectedGroups={selectedGroups}
                            onSelectionChange={(selection: string[]) => {
                                setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
                                setSelectedGroup(selection.length === 1 ? groups.filter(x => selection.some(s => s === x.id))[0] : null);
                            }}
                        />
                    </Grid>
                </Grid>
            }
            {
                (!groups || groups.length === 0) &&
                <Paper className="Paper">
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography component="h5" variant="h5"
                            >
                                {t("license-general.youHaveNotYetAssignedAnyLicencesInstallADeviceNow")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            }
            <Grid container justifyContent={"flex-end"}>
                {buildLicenseButtons()}
            </Grid>
            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.LoraSensor}
                visible={assignLoraSensorDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.LoraSensorExtension)}
                onClose={() => {
                    setAssignLoraSensorDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    if (!selectedLicense || !selectedGroup) return;
                    console.log(selectedGroup, "mygroup");
                    setAssignLoraSensorDialogVisible(false);
                    if (selectedLicense.loraSensorCount > 0) {
                        setSelectedLicense(selectedLicense);
                        setAssignLoraSensorLicenseChooserDialogVisible(true);
                    } else {
                        await assignLicense(selectedLicense, setAssignLoraSensorDialogVisible);
                    }
                }}
            />
            {selectedGroup && selectedLicense && selectedGroup.loraSensorLicenses &&
                <AssignSensorLicenseChoosingTableDialog
                    visible={assignLoraSensorLicenseChooserDialogVisible}
                    licenses={selectedGroup.loraSensorLicenses}
                    currentSelectedLicense={selectedLicense}
                    sensorCount={selectedLicense.loraSensorCount}
                    onClose={() => {
                        setAssignLoraSensorLicenseChooserDialogVisible(false);
                    }}
                    onLicenseSelected={async (selectedSensorLicenses: SensorLicense[]) => {
                        selectedLicense.loraSensorLicenses = selectedSensorLicenses;
                        if (selectedLicense) await assignLicense(selectedLicense, setAssignLoraSensorLicenseChooserDialogVisible);
                    }}
                />
            }
            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title={`Sind Sie sicher, die ausgewählten Einheiten zu löschen?`}
                onClose={() => setDeleteConfirmationDialogVisible(false)}
                onConfirmed={async () => {

                    if (selectedGroups.length < 1) return;

                    const token = getApiToken(history);
                    for (let currentGroup of selectedGroups) {
                        await api.deleteGroupAsync(currentGroup.id, token, enqueueSnackbar);
                    }
                    setGroups(groups.filter(x => !selectedGroups.some(y => y.id == x.id)));

                    setDeleteConfirmationDialogVisible(false);
                }}
            />
            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    assignLicense(selectedLicense, setAssignQualityCheckLicenseDialogVisible);
                }}
            />
            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignMultipleQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.qualityCheckCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.PublicFeedback}
                visible={assignMultiplePublicFeedbackLicenseDialogVisible}
                licenses={licenses.filter(x => x.publicFeedbackCount > 0)}
                onClose={() => {
                    setAssignMultiplePublicFeedbackLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.publicFeedbackCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultiplePublicFeedbackLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.PayPerService}
                visible={assignMultiplePayPerServiceLicenseDialogVisible}
                licenses={licenses.filter(x => x.payPerServiceCount > 0)}
                onClose={() => {
                    setAssignMultiplePayPerServiceLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.payPerServiceCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultiplePayPerServiceLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.CalendarPro}
                visible={assignMultipleCalendarProLicenseDialogVisible}
                licenses={licenses.filter(x => x.calendarProCount > 0)}
                onClose={() => {
                    setAssignMultipleCalendarProLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.calendarProCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultipleCalendarProLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />
        </>

    )
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));