import React, { ChangeEvent, useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore } from "@mui/icons-material";
import { convertMinutesToTimespan, convertTimespanToMinutes } from "../../../helpers/date-management";
import { CleaningOperation, GroupSettings, GroupSettingsIntervalType, GroupSettingsIntervalControl } from "../../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../../models/UserGroupSettingsTemplate";
import { errorColor, white } from "../../../styles/colors";
import { GroupSettingFieldsUseStyles } from "../../../styles/GroupSettingFieldsStyles";
import { ReactComponent as ChartBellCurve } from '../../../assets/svgs/chart-bell-curve.svg';
import { useContainerStyles } from "../../../styles/container";
import { DayOfWeek } from "../../../models/datetime/DayOfWeek";
import { IntervalControlScheduleCalendar } from "../IntervalControlScheduleCalendar";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { TimePicker } from "@mui/x-date-pickers";
import { InlineTimePicker } from "../InlineDateTimePicker";
import { StringHelper } from "../../../helpers/string-helper";
import { ReactionTimeComponent } from "./ReactionTimeComponent";
import { MinimumTimePresenceComponent } from "./MinimunTimeComponent";
import { IntervalContext, SettingsContext } from "../../authorized/PortalPage";
import { useTranslation } from "react-i18next";

export enum IntervalType {
    Group = 0,
    Object = 1,
    QMObject = 2
}

export const GroupSettingFieldsIntervalControl = ({ row, intervalControl, services, onChange, myAppointments, setMyAppointments, labelTime = "Reaktionszeit", title = "Intervall-Steuerung (Standard)" }: { row?: GroupSettings | UserGroupSettingsTemplate | undefined, intervalControl: GroupSettingsIntervalControl; services: CleaningOperation[]; onChange: any; myAppointments: AppointmentModel[]; setMyAppointments: any, labelTime?: string, title?: string }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();
    const {t} = useTranslation();


    if (intervalControl && !intervalControl.startTime) {
        intervalControl.startTime = "00:00:00"
    }
    if (intervalControl && !intervalControl.endTime) {
        intervalControl.endTime = "00:00:00"
    }
    if (intervalControl && !intervalControl.repeatDuration) {
        intervalControl.repeatDuration = "00:00:00"
    }





    return (
        <Accordion className={classes.hover}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <ChartBellCurve width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage."+ title)}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    {row &&
                        <Grid item xs={3}>
                            <FormControlLabel
                                label={`${t("settingsPage.intervalMessagesActivated")}`}
                                control={
                                    <Checkbox
                                        name="isIntervalNotificationEnabled"
                                        color="primary"
                                        checked={row.notification?.isIntervalNotificationEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            event.target.value = checked;
                                            onChange(event);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={intervalControl?.isIntervalControlEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name="isIntervalControlEnabled"
                                color="primary" />}
                            label={`${t("settingsPage."+title)} ${t("settingsPage.activated")}`} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={2}>
                    <BuildTypeChooserInterval intervalControl={intervalControl} onChange={onChange} onChange2={onChange} />
                    <BuildIntervalItem intervalControl={intervalControl} onChange={onChange} labelTime={labelTime} />
                    <BuildSpecificIntervalItem intervalControl={intervalControl} onChange={onChange} labelTime={labelTime} />
                    <BuildCalendarIntervalItem intervalControl={intervalControl} onChange={onChange} services={services} myAppointments={myAppointments} setMyAppointments={setMyAppointments} />
                </Grid>
            </AccordionDetails>
        </Accordion >
    );
};

export const BuildTypeChooserInterval = ({ intervalControl, onChange, onChange2 }: { intervalControl: GroupSettingsIntervalControl, onChange: any, onChange2: any }) => {
    const {t} = useTranslation();
    const changeWeekDay = (dayOfWeek: DayOfWeek) => {
        let value: DayOfWeek[] = [];
        if (intervalControl?.daysOfWeekEnabled.some(x => x == dayOfWeek)) {
            value = intervalControl?.daysOfWeekEnabled.filter(x => x != dayOfWeek);
        } else {
            if (intervalControl?.daysOfWeekEnabled)
                value = [...intervalControl?.daysOfWeekEnabled, dayOfWeek];
            else
                value = [dayOfWeek]
        }
        let res = { target: { name: 'daysOfWeekEnabled', value: JSON.stringify(value) } };
        onChange(res)
    }

    const checkedWeekDay = (dayOfWeek: DayOfWeek) => {
        return intervalControl?.daysOfWeekEnabled && intervalControl?.daysOfWeekEnabled.some(x => x == dayOfWeek);
    }


    return (
        <Grid item container direction={"column"} spacing={3}>
            <Grid item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("myUnitsSettings.triggerSelection")}</FormLabel>
                    <RadioGroup
                        row
                        aria-label="gender"
                        name="groupSettingsIntervalType"
                        value={intervalControl?.type || GroupSettingsIntervalType.Interval}
                        onChange={onChange2}
                    >
                        <FormControlLabel
                            value={GroupSettingsIntervalType.Interval}
                            control={<Radio />}
                            label={t("myUnitsSettings.recurring")}
                        />
                        <FormControlLabel
                            value={GroupSettingsIntervalType.Specific}
                            control={<Radio />}
                            label={t("myUnitsSettings.specific")}
                        />
                        <FormControlLabel
                            value={GroupSettingsIntervalType.Calendar}
                            control={<Radio />}
                            label={t("myUnitsSettings.calendar")}
                        />
                    </RadioGroup>

                </FormControl>
            </Grid>
            {intervalControl?.type != GroupSettingsIntervalType.Calendar &&
                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t("myUnitsSettings.selectSpecificWeekdays")}</FormLabel>
                        <FormGroup aria-label="position" row>
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Monday} label={t("myUnitsSettings.monday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Tuesday} label={t("myUnitsSettings.tuesday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Wednesday} label={t("myUnitsSettings.wednesday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Thursday} label={t("myUnitsSettings.thursday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Friday} label={t("myUnitsSettings.friday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Saturday} label={t("myUnitsSettings.saturday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                            <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Sunday} label={t("myUnitsSettings.sunday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                        </FormGroup>
                    </FormControl>
                </Grid>
            }
        </Grid>
    )
}

export const WeekDayCheckboxItem = (
    { dayOfWeek, label, checkedWeekDay, onChange }:
        { dayOfWeek: DayOfWeek, label: string, checkedWeekDay: (dayOfWeek: DayOfWeek) => boolean, onChange: any }) => {
    return (
        <FormControlLabel
            name="daysOfWeekEnabled"
            value="top"
            control={<Checkbox
                color="primary" checked={checkedWeekDay(dayOfWeek)}
                onChange={(event) => onChange(dayOfWeek)}
            />}
            label={label}
            labelPlacement="top"

        />
    )
}


export const BuildIntervalItem = ({ intervalControl, onChange, labelTime }: { intervalControl: GroupSettingsIntervalControl, onChange: any, labelTime: string }) => {

    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(30);
    const [seconds, setSeconds] = React.useState(0);
    const {t} = useTranslation();

    const { intervalType } = React.useContext(IntervalContext);

    React.useEffect(() => {
        let [hours, minutes, seconds] = intervalControl.repeatDuration?.split(":") as any;
        setHours(hours || 0);
        setMinutes(minutes || 30);
        setSeconds(seconds || 0);


    }, [])

    if (intervalControl?.type != GroupSettingsIntervalType.Interval) return <></>;
    return (
        <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={12}>
                <Typography variant={"h5"} fontWeight={'bold'}>{t("myUnitsSettings.triggerTimeframe")}</Typography>
            </Grid>
            <Grid item md={6} lg={3}>
                {intervalControl.startTime &&
                    <InlineTimePicker label="Wiederholung Start" value={new Date(`${"2017-03-13"} ${intervalControl.startTime}`)} change={(value: any) => {
                        if (value != "Invalid Date" && value) {
                            let res = { target: { name: 'intervalControlStartTime', value: value } };
                            onChange(res);
                        }
                    }} />
                }
            </Grid>
            <Grid item md={6} lg={3}>
                {intervalControl.endTime &&
                    <InlineTimePicker label="Wiederholung Ende" value={new Date(`${"2017-03-13"} ${intervalControl.endTime}`)} change={(value: any) => {
                        if (value != "Invalid Date" && value) {
                            let res = { target: { name: 'intervalControlEndTime', value: value } };
                            onChange(res);
                        }
                    }} />
                }
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid container direction={"column"} item lg={3} spacing={3}>
                <Grid item>
                    <Typography variant={"h5"} fontWeight={'bold'}>{t("myUnitsSettings.triggerRepetition")}</Typography>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            variant="filled"
                            label="Stunden"
                            type="number"
                            InputProps={{
                                inputProps: { min: 0, max: 23 },
                                endAdornment: <InputAdornment position="end">{t("myUnitsSettings.hours")}</InputAdornment>
                            }}
                            value={hours}
                            onChange={(event: any) => {
                                if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                                setHours(event.target.value);
                                let res = { target: { name: 'intervalControlRepeatDuration', value: `${event.target.value}:${minutes}:${seconds}` } };
                                onChange(res);
                            }}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField

                            required
                            variant="outlined"
                            label="Minuten"
                            type="number"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: <InputAdornment position="end">{t("myUnitsSettings.minutes")}</InputAdornment>
                            }}
                            value={minutes}
                            onChange={(event: any) => {
                                if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                                setMinutes(event.target.value);
                                let res = { target: { name: 'intervalControlRepeatDuration', value: `${hours}:${event.target.value}:${seconds}` } };
                                onChange(res);
                            }}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField

                            required
                            variant="outlined"
                            label="Sekunden"
                            type="number"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: <InputAdornment position="end">{t("myUnitsSettings.seconds")}</InputAdornment>
                            }}
                            value={seconds}
                            onChange={(event: any) => {
                                if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                                setSeconds(event.target.value);
                                let res = { target: { name: 'intervalControlRepeatDuration', value: `${hours}:${minutes}:${event.target.value}` } };
                                onChange(res);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    {intervalControl.repeatDuration &&
                        <InlineTimePicker disabled={true} label={t("myUnitsSettings.repeat")} value={new Date(`${"2017-03-13"} ${intervalControl.repeatDuration}`)} change={(value: any) => {
                            if (value != "Invalid Date" && value) {

                            }
                        }} />
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <ReactionTimeComponent label={labelTime} deviationTime={intervalControl.deviationTime || ""} onChange={onChange} target={"intervalControlDeviationTime"} />
            {intervalType == IntervalType.Object &&
                <MinimumTimePresenceComponent minimumTime={intervalControl.minimumTime || ""} onChange={onChange} target={"intervalControlMinimumTime"} />
            }
            {intervalType == IntervalType.Object &&
                <MinimumServiceWorkerComponent target="intervalControlMinimumServiceWorker" minimumServiceWorkerControl={intervalControl.minimumServiceWorker} onChange={onChange} />
            }

        </Grid>
    )
}

export const MinimumServiceWorkerComponent = ({ onChange, minimumServiceWorkerControl, target, index }: { onChange: any, minimumServiceWorkerControl: number, target: string, index?: number }) => {
    const [minimumServiceWorker, setMinimumServiceWorker] = React.useState(1);
    const {t} = useTranslation();

    React.useEffect(() => {
        setMinimumServiceWorker(minimumServiceWorkerControl);
    }, [])

    return (
        <Grid container direction={"column"} item xs={12} spacing={3}>
            <Grid item>
                <Typography fontWeight={'bold'}>Minimum Servicekräfte</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField

                    required
                    variant="outlined"
                    label="Minimum Servicekräfte"
                    type="number"
                    InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                    }}
                    value={minimumServiceWorker}
                    onChange={(event: any) => {
                        if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                        setMinimumServiceWorker(event.target.value);
                        if (target == "intervalControlSpecificTimes") {
                            let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: event.target.value, index: index, property: 'minimumServiceWorker' }) } };
                            onChange(res);
                        } else {
                            let res = { target: { name: target, value: event.target.value } };
                            onChange(res);
                        }

                    }}
                />
            </Grid>
        </Grid>
    )
}

export const BuildSpecificIntervalItem = ({ intervalControl, onChange, labelTime }: { intervalControl: GroupSettingsIntervalControl, onChange: any, labelTime: string }) => {
    const { intervalType, refreshSettings } = React.useContext(IntervalContext);
    const {t} = useTranslation();

    if (intervalControl?.type != GroupSettingsIntervalType.Specific) return <></>;



    return (
        <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
            <Grid container item xs={12} spacing={3}>
                <Grid item>
                    <Button size="large"
                        startIcon={<Add />} variant={'contained'} onClick={async () => {
                            onChange({ target: { name: 'intervalControlSpecificTimesAdd', value: "" } });
                        }} color="primary">
                        {t("myUnitsSettings.addSpecificTime")}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {intervalControl?.specificTimes.map((el, index) => {
                if (!el.specificTime) {
                    el.specificTime = "00:00:00";
                }


                const changeWeekDay = (dayOfWeek: DayOfWeek) => {
                    let value: DayOfWeek[] = [];
                    if (el?.daysOfWeekEnabled?.some(x => x == dayOfWeek)) {
                        value = el.daysOfWeekEnabled.filter(x => x != dayOfWeek);
                    } else {
                        if (el.daysOfWeekEnabled)
                            value = [...el.daysOfWeekEnabled, dayOfWeek];
                        else
                            value = [dayOfWeek]
                    }
                    el.daysOfWeekEnabled = value;
                    refreshSettings();
                }

                const checkedWeekDay = (dayOfWeek: DayOfWeek) => {
                    return el.daysOfWeekEnabled && el.daysOfWeekEnabled.some(x => x == dayOfWeek);
                }

                return (
                    <Grid item container direction="row" alignItems="center" spacing={3}>
                        <Grid item lg={12}>
                            <FormControlLabel
                                label={t("myUnitsSettings.specificWeekdaysEnabled")}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={el.isSpecificDaysOfWeekEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            el.isSpecificDaysOfWeekEnabled = checked;
                                            refreshSettings();
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        {el.isSpecificDaysOfWeekEnabled &&
                            <Grid item lg={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t("myUnitsSettings.selectSpecificWeekdays")}</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Monday} label={t("myUnitsSettings.monday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Tuesday} label={t("myUnitsSettings.tuesday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Wednesday} label={t("myUnitsSettings.wednesday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Thursday} label={t("myUnitsSettings.thursday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Friday} label={t("myUnitsSettings.friday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Saturday} label={t("myUnitsSettings.saturday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                        <WeekDayCheckboxItem dayOfWeek={DayOfWeek.Sunday} label={t("myUnitsSettings.sunday")} checkedWeekDay={checkedWeekDay} onChange={changeWeekDay} />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        }
                        <Grid lg={3} container direction={"column"} item spacing={3}>
                            <Grid item>
                                <Typography fontWeight={'bold'}>{t("myUnitsSettings.planAttendanceStart")}</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={3} alignItems="center">
                                <Grid item>
                                    <Fab size="small" style={{ backgroundColor: errorColor, color: white }} aria-label="add" onClick={() => {
                                        onChange({ target: { name: 'intervalControlSpecificTimesRemove', value: JSON.stringify({ index: index }) } });
                                    }}>
                                        <Remove />
                                    </Fab>
                                </Grid>
                                <Grid item>
                                    {el.specificTime &&
                                        <InlineTimePicker label={t("myUnitsSettings.planAttendanceStart")} value={new Date(`${"2017-03-13"} ${el.specificTime}`)} change={(value: any) => {
                                            if (value != "Invalid Date" && value) {
                                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: value, index: index, property: 'specificTime' }) } };
                                                onChange(res);
                                            }
                                        }} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid lg={3} item>
                            <ReactionTimeComponent deviationTime={el.deviationTime} onChange={onChange} target={"intervalControlSpecificTimes"} index={index} label={labelTime}/>
                        </Grid>

                        {intervalType == IntervalType.Object &&
                            <Grid lg={3} item>
                                <MinimumTimePresenceComponent minimumTime={el.minimumTime || ""} onChange={onChange} target={"intervalControlSpecificTimes"} index={index} />
                            </Grid>
                        }
                        {intervalType == IntervalType.Object &&
                            <Grid lg={3} item>
                                <MinimumServiceWorkerComponent target="intervalControlSpecificTimes" minimumServiceWorkerControl={el.minimumServiceWorker} onChange={onChange} index={index} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>

    )
}
export const BuildCalendarIntervalItem = ({ intervalControl, onChange, services, myAppointments, setMyAppointments }: { intervalControl: GroupSettingsIntervalControl, onChange: any, services: CleaningOperation[], myAppointments: AppointmentModel[], setMyAppointments: any }) => {
    if (intervalControl?.type != GroupSettingsIntervalType.Calendar) return <></>;
    return (
        <Grid item>
            <IntervalControlScheduleCalendar services={services} row={intervalControl} onChange={onChange} myAppointments={myAppointments} setMyAppointments={setMyAppointments} />
        </Grid>
    )
}

