import { Dialog, DialogTitle, DialogContent, DialogActions, Button, AppBar, Toolbar, Typography, IconButton, Theme } from "@mui/material";
import React from "react";
import { Plugin, Template, TemplateConnector, TemplatePlaceholder, } from '@devexpress/dx-react-core';
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFields } from "../core/GroupSettingFields";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { GroupSettingsType } from "../../models/GroupSettings";
import { ServiceWorkerServicesDashboard } from '../authorized/portal/Dashboard/ServiceWorkerServicesDashboard';
import { Group, GroupType } from "../../models/Group";
import makeStyles from '@mui/styles/makeStyles';
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";


const createNewGroupForTemplate = () => {
  let group = new Group();
  group.groupType = GroupType.DisplayWallApp;
  group.sensorLicenses = [];
  return group;
}

export const PopupGroupSettingsTemplate = ({ row, onChange, onApplyChanges, onCancelChanges, open }
  : { row: UserGroupSettingsTemplate, onChange: any, onApplyChanges: any, onCancelChanges: any, open: any }) => {




  const group = React.useRef(createNewGroupForTemplate());
  const classes = useStyles();
  const {t} = useTranslation();


  return (
    <Dialog fullScreen open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.dialogTitle}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography className={classes.dialogTitleAppBarTitle} variant="h6">
              {row.name ? `Vorlage für ${row.name}` : 'Neue Template erstellen'}
            </Typography>
            <IconButton color="inherit" onClick={onCancelChanges} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        <GroupSettingFields loadData={() => { }} row={row} onChange={onChange} type={GroupSettingsType.Template} group={group.current} />
      </DialogContent>
      <DialogActions>
        <Button size="large"
          startIcon={<CancelIcon />} variant={'outlined'} onClick={onCancelChanges} color="primary">
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button size="large"
          startIcon={<SaveIcon />} variant={'contained'} onClick={onApplyChanges} color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export const PopupEditingGroupSettingsTemplate = React.memo(({ popupComponent: Popup }: { popupComponent: any }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          {
            rows,
            getRowId,
            addedRows,
            editingRowIds,
            createRowChange,
            rowChanges,
          },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows,
          },
        ) => {

          const isNew = addedRows.length > 0;
          let editedRow: any;
          let rowId: any;

          if (isNew) {
            rowId = 0;
            editedRow = { ...UserGroupSettingsTemplate.Default, ...addedRows[rowId] };
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row: any) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const rowIds = isNew ? [0] : editingRowIds;
          const open = editingRowIds.length > 0 || isNew;

          const processValueChange = ({ target: { name, value } }: { target: any }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name),
            };

            if (isNew)
              changeAddedRow(changeArgs);
            else
              changeRow(changeArgs);
          };


          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };

          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + parseInt(theme.spacing(2)) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
  },
  dialogTitle: {
    padding: 0,
    flexGrow: 1,
  },
  dialogTitleAppBar: {
    flexGrow: 1,
  },
  dialogTitleAppBarTitle: {
    flexGrow: 1,
  },
  empty: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));