import React, { ChangeEvent } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore } from "@mui/icons-material";
import { convertMinutesToTimespan, convertTimespanToMinutes } from "../../../helpers/date-management";
import { CleaningOperation, GroupSettings, GroupSettingsIntervalType, GroupSettingsIntervalControl } from "../../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../../models/UserGroupSettingsTemplate";
import { errorColor, white } from "../../../styles/colors";
import { GroupSettingFieldsUseStyles } from "../../../styles/GroupSettingFieldsStyles";
import { ReactComponent as ChartBellCurve } from '../../assets/svgs/chart-bell-curve.svg';
import { useContainerStyles } from "../../../styles/container";
import { DayOfWeek } from "../../../models/datetime/DayOfWeek";
import { IntervalControlScheduleCalendar } from "../IntervalControlScheduleCalendar";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { TimePicker } from "@mui/x-date-pickers";
import { InlineTimePicker } from "../InlineDateTimePicker";
import { StringHelper } from "../../../helpers/string-helper";


export const ReactionTimeComponent = ({ deviationTime, onChange, target, index, label = "Reaktionszeit" }: { deviationTime: string, onChange: any, target: string, index?: any, label? : string }) => {


    const [daysReaction, setDaysReaction] = React.useState(0);
    const [hoursReaction, setHoursReaction] = React.useState(0);
    const [minutesReaction, setMinutesReaction] = React.useState(30);
    const [secondsReaction, setSecondsReaction] = React.useState(0);

    React.useEffect(() => {

        var timesToSplit = deviationTime;
        if (deviationTime.includes(".")) {
            let daysReaction = 0;
            [daysReaction, timesToSplit] = deviationTime?.split(".") as any;
            setDaysReaction(daysReaction);
        }
        if (timesToSplit) {
            let [hoursReaction, minutesReaction, secondsReaction] = timesToSplit.split(":") as any;
            setHoursReaction(hoursReaction || 0);
            setMinutesReaction(minutesReaction || 30);
            setSecondsReaction(secondsReaction || 0);
        }

    }, [])

    return (
        <Grid container direction={"column"} item spacing={3}>
            <Grid item>
                <Typography fontWeight={'bold'}>{label}</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item>
                    <TextField
                        variant="outlined"
                        label="Tage"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 30 },
                        }}
                        value={daysReaction}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setDaysReaction(event.target.value);
                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${event.target.value}.${hoursReaction}:${minutesReaction}:${secondsReaction}`, index: index, property: 'deviationTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${event.target.value}.${hoursReaction}:${minutesReaction}:${secondsReaction}` } };
                                onChange(res);
                            }

                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        label="Stunden"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 23 },
                        }}
                        value={hoursReaction}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setHoursReaction(event.target.value);

                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${daysReaction}.${event.target.value}:${minutesReaction}:${secondsReaction}`, index: index, property: 'deviationTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${daysReaction}.${event.target.value}:${minutesReaction}:${secondsReaction}` } };
                                onChange(res);
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        label="Minuten"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 59 },
                        }}
                        value={minutesReaction}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setMinutesReaction(event.target.value);

                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${daysReaction}.${hoursReaction}:${event.target.value}:${secondsReaction}`, index: index, property: 'deviationTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${daysReaction}.${hoursReaction}:${event.target.value}:${secondsReaction}` } };
                                onChange(res);
                            }
                        }}
                    />
                </Grid>
                {/*
                <Grid item>
                    <TextField
                        variant="outlined"
                        label="Sekunden"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 59 },
                            endAdornment: <InputAdornment position="end">Sekunden</InputAdornment>
                        }}
                        value={secondsReaction}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setSecondsReaction(event.target.value);

                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${daysReaction}.${hoursReaction}:${minutesReaction}:${event.target.value}`, index: index, property: 'deviationTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${daysReaction}.${hoursReaction}:${minutesReaction}:${event.target.value}` } };
                                onChange(res);
                            }

                        }}
                    />
                </Grid>
                    */}
            </Grid>
        </Grid>
    )
}