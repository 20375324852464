import React from 'react';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UserAccount } from '../../models/UserAccount';
import { UserSingleSelection } from '../tables/UserSingleSelection';
import { Close, TouchApp, Undo } from '@mui/icons-material';
import { SELECTED_USER_ID_FROM_ADMIN } from '../../constants';
import { ConfirmationDialog } from './ConfirmationDialog';
import { useSnackbar } from 'notistack';

export const UserSingleSelectionEvaluationDialog = ({
  open,
  setOpen,
  setSelectedUserFromAdmin,
  setSelectedGroupId,
  setSelectedGroupName,
  setSelectedCombinationGroup, user }
  : {
    open: boolean,
    setOpen: any,
    setSelectedUserFromAdmin: any,
    setSelectedGroupId: any,
    setSelectedGroupName: any,
    setSelectedCombinationGroup: any,
    user: UserAccount | undefined
  }) => {

  const [users, setUsers] = React.useState<UserAccount[]>([]);

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUser, setSelectedUser] = React.useState<UserAccount | undefined>(undefined);
  const [deleteConfirmDialogVisible, setDeleteConfirmDialogVisible] = React.useState(false);

  const handleClose = () => setOpen(false);

  const changeUser = (changedUser: UserAccount | undefined) => {

    if (!changedUser) return;

    localStorage.setItem(SELECTED_USER_ID_FROM_ADMIN, changedUser.id);
    setSelectedGroupName(null);
    setSelectedGroupId(null);
    setSelectedCombinationGroup([]);
    setSelectedUserFromAdmin(changedUser);
    history.push(`/portal/account/${changedUser.id}`);
    handleClose();
  }

  React.useEffect(() => {
    if (open) {
      (async () => {

        const token = getApiToken(history);
        if (!token) return;

        const tempUsers = await api.getAllUsersByAdmin(token);
        setUsers(tempUsers);

      })();
    }
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitle}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography className={classes.dialogTitleText} variant="h6">Wähle User zum verwalten</Typography>
            <IconButton color="inherit" onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <UserSingleSelection
          users={users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Undo />}
          disabled={selectedUser?.id === user?.id}
          onClick={() => {

            if (!selectedUser) return;

            setSelectedUser(user);
            changeUser(user);
          }}
        >
          Zu meinem Benutzer
        </Button>

        <Button
          className={classes.deleteButton}
          variant="outlined"
          startIcon={<Close />}
          disabled={!selectedUser || selectedUser.id === user?.id}
          onClick={async () => {

            if (!selectedUser || selectedUser.id === user?.id) return;

            setDeleteConfirmDialogVisible(true);
          }}
        >
          Benutzer entfernen
        </Button>

        <Button
          variant="contained"
          color="primary"
          endIcon={<TouchApp />}
          disabled={!selectedUser}
          onClick={() => {

            if (!selectedUser) return;

            changeUser(selectedUser);
          }}
        >
          {selectedUser ? selectedUser.mail : 'Benutzer'} verwalten
        </Button>

      </DialogActions>

      {
        selectedUser &&
        <ConfirmationDialog
          visible={deleteConfirmDialogVisible}
          title={`Sind Sie sicher, den Benutzer '${selectedUser.mail}' zu entfernen ?`}
          onClose={() => setDeleteConfirmDialogVisible(false)}
          onConfirmed={async () => {
            setDeleteConfirmDialogVisible(false);

            if (!selectedUser) return;

            const token = getApiToken(history);
            await api.deleteUserAsync(selectedUser.id, token, enqueueSnackbar);

            setUsers(users.filter(x => x.id !== selectedUser.id));

            const currentSelectedUserId = localStorage.getItem(SELECTED_USER_ID_FROM_ADMIN);
            if (currentSelectedUserId == selectedUser.id)
              changeUser(user);

            setSelectedUser(undefined);
          }}
        />
      }

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    padding: 0,
    flexGrow: 1,
  },
  dialogTitleText: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  }
}));