import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DefaultDialogTitle } from '../dialogs/DefaultDialogTitle';
import { ItemProductType, ProductType } from './ProductType';
import { ItemOption, ItemProduct, Product } from '../../models/Product';
import { Add, RemoveCircle, Save } from '@mui/icons-material';
import { StringHelper } from '../../helpers/string-helper';
import { CurrencyHelper } from "../../helpers/CurrencyHelper";
import { red } from '@mui/material/colors';
import { white } from '../../styles/colors';
import { useTranslation } from 'react-i18next';

export const ProductManagementPageEditingDialog = ({ visible, setVisible, products, product, onSave }
    : { visible: boolean, setVisible: any, products: Product[], product?: Product, onSave: any }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [row, setRow] = React.useState<Product>(new Product());

    const [articleNumber, setArticleNumber] = React.useState('');
    const [days, setDays] = React.useState(0);
    const [lengthDescription, setLengthDescription] = React.useState<string>();
    const [checkpoints, setCheckpoints] = React.useState(0);
    const [qmCount, setQmCount] = React.useState(0);
    const [publicFeedbackCount, setPublicFeedbackCount] = React.useState(0);
    const [payPerServiceCount, setPayPerServiceCount] = React.useState(0);
    const [calendarProCount, setCalendarProCount] = React.useState(0);
    const [taqtFeedbackCount, setTaqtFeedbackCount] = React.useState(0);
    const [taqtTimeCount, setTaqtTimeCount] = React.useState(0);


    const [sensorCount, setSensorCount] = React.useState(0);

    const [name, setName] = React.useState('');
    const [type, setType] = React.useState<ProductType>();
    const [description, setDescription] = React.useState('');
    const [unitPrice, setUnitPrice] = React.useState('');
    const [isVisibleInShop, setIsVisibleInShop] = React.useState(true);

    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [changed, setChanged] = React.useState(false);

    const [itemProducts, setItemProducts] = React.useState<ItemProduct[]>([]);

    const [itemOptions, setItemOptions] = React.useState<ItemOption[]>([]);

    const [priority, setPriority] = React.useState(0);
    const {t} = useTranslation();


    const onClose = () => setVisible(false);

    const isValid = () => {
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(name)) {
            setNameError("Produktname ist erforderlich");
            valid = false;
        }

        if (!type) {
            setTypeError("Typ ist erforderlich");
            valid = false;
        }

        const unitPriceValue = CurrencyHelper.convertStringToCurrencyValue(unitPrice);
        if (isNaN(unitPriceValue) || unitPriceValue < 0) {
            setUnitPriceError("Preis Angabe ist ungültigt");
            valid = false;
        }

        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }

    React.useEffect(() => {

        (async () => {

            setRow(product || new Product());
            setName(product?.name || '');
            setArticleNumber(product?.articleNumber || '');
            setDays(product?.days || 0);
            setLengthDescription(product?.lengthDescription);
            setCheckpoints(product?.checkpointCount || 0);
            setSensorCount(product?.sensorCount || 0);
            setQmCount(product?.qmCount || 0);
            setPublicFeedbackCount(product?.publicFeedbackCount || 0);
            setPayPerServiceCount(product?.payPerServiceCount || 0);
            setCalendarProCount(product?.calendarProCount || 0);
            setTaqtFeedbackCount(product?.taqtFeedbackCount || 0);
            setTaqtTimeCount(product?.taqtTimeCount || 0);

            setPriority(product?.priority || 0);

            setType(product?.type);
            setDescription(product?.description || '');
            setUnitPrice(CurrencyHelper.convertNumberToCurrencyNumberString(product?.unitPrice));
            setIsVisibleInShop(product?.isVisibleInShop || true);

            setItemProducts(product?.itemProduct || []);

            if (product?.itemOption && product.itemOption.length > 0) {
                setItemOptions(product.itemOption);
            }

            resetErrorText();
            setChanged(false);

        })();

    }, [visible, product])

    const buildProductItem = (productItem: ItemProduct, setProductItem: any, index: number) => {
        let label = productItem.itemProductType == ItemProductType.CheckmenowLicense ? "Produkt Name" : "Produkt Name";
        return (
            <Grid item container xs={12} alignItems='center' spacing={1}>
                <Grid item xs={7} lg={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={label}
                        disabled={loading}
                        value={productItem?.name}
                        onFocus={resetErrorText}
                        onChange={(event: any) => {
                            if (productItem) {
                                productItem.name = event.target.value
                                setProductItem([...itemProducts]);
                                setChanged(true);
                            }

                        }}
                    />
                </Grid>
                <Grid item xs={2} lg={1}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label="Preis"
                        type="number"
                        disabled={loading}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                        value={productItem?.price}
                        onChange={(event: any) => {
                            if (productItem) {
                                productItem.price = event.target.value
                                setProductItem([...itemProducts]);
                                setChanged(true);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={9} lg={5}>
                    <FormControl
                        fullWidth
                        required
                        variant="outlined"
                    >
                        <InputLabel id="item-price-settings-page-add-settings-dialog-price-type">Produkt Item Typ</InputLabel>
                        <Select
                            labelId="item-price-settings-page-add-settings-dialog-price-type"
                            value={productItem.itemProductType}
                            label="Produkt Item Typ"
                            disabled={loading}
                            error={!StringHelper.IsNullOrWhiteSpace(typeError)}
                            onFocus={resetErrorText}
                            onChange={(event: any) => {
                                productItem.itemProductType = event.target.value;
                                setProductItem([...itemProducts]);
                                setChanged(true);
                            }}
                        >
                            {
                                ItemProduct.Labels
                                    .sort((a, b) => a.value - b.value)
                                    .map((selection: { value: ItemProductType, label: string }) => <MenuItem value={selection.value}>{selection.value} - {selection.label}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} lg={2} style={{ alignSelf: 'center' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={productItem.isVisible}
                                onChange={(event: any) => {
                                    if (productItem) {
                                        productItem.isVisible = event.target.checked;
                                        setProductItem([...itemProducts]);
                                        setChanged(true);
                                    }
                                }}
                            />
                        }
                        labelPlacement="start"
                        label={productItem.isVisible ? "Sichtbar" : "Unsichtbar"}
                    />

                </Grid>
                <Grid item xs={3} lg={1}>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: red[900], color: white }}
                        onClick={() => {
                            itemProducts.splice(index, 1);
                            setItemProducts([...itemProducts]);
                        }}
                        endIcon={<RemoveCircle />}
                    >
                        Löschen
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const buildItemOptionSelection = () => {
        return (
            <>
                <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>Wähle die Vorschläge, wenn das Produkt im Warenkorb ist.</Typography>
                {products.map((el) => {
                    return (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={itemOptions.some(x => x.id == el.id)}
                                        onChange={(event: any) => {
                                            if (itemOptions.some(x => x.id == el.id)) {
                                                setItemOptions(itemOptions.filter(x => x.id != el.id));
                                            } else {
                                                setItemOptions([...itemOptions, new ItemOption(el.id)])
                                            }
                                            setChanged(true);
                                        }}
                                        name={el.id}
                                        color="primary"
                                    />
                                }
                                label={el.name}
                            />
                            <Divider />
                        </>
                    )
                })}
            </>
        )
    }

    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="lg">
            <DefaultDialogTitle
                title="Produkt hinzufügen"
                handleClose={onClose}
            />

            <DialogContent>
                <form noValidate>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                            <Typography variant='h5'>
                                Produkt Details:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                multiline
                                rows={3}
                                label="Name"
                                disabled={loading}
                                value={name}
                                error={!StringHelper.IsNullOrWhiteSpace(nameError)}
                                helperText={nameError}
                                onFocus={resetErrorText}
                                onChange={(event: any) => {
                                    setName(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                variant="outlined"
                                label="Beschreibung"
                                disabled={loading}
                                value={description}
                                onFocus={resetErrorText}
                                onChange={(event: any) => {
                                    setDescription(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Artikel Nummer"
                                disabled={loading}
                                value={articleNumber}
                                onChange={(event: any) => {
                                    setArticleNumber(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Dauer Gültigkeit (Tage)"
                                type="number"
                                disabled={loading}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Tage</InputAdornment>
                                }}
                                value={days}
                                onChange={(event: any) => {
                                    setDays(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Reihenfolge"
                                type="number"
                                disabled={loading}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Prio</InputAdornment>
                                }}
                                value={priority}
                                onChange={(event: any) => {
                                    setPriority(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Dauer Beschreibung"
                                disabled={loading}
                                value={lengthDescription}
                                onChange={(event: any) => {
                                    setLengthDescription(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>

                        {(type == ProductType.LicenseLoraSensor12Months ||
                            type == ProductType.LicenseLoraSensor24Months || type == ProductType.LicenseLoraSensor36Months) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Sensor Anzahl"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={sensorCount}
                                    onChange={(event: any) => {
                                        setSensorCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        {(type == ProductType.LicenseCheckpointPackageTest || type == ProductType.LicenseCheckpointPackage12Months ||
                            type == ProductType.LicenseCheckpointPackage24Months || type == ProductType.LicenseCheckpointPackage36Months) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Checkpoints"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={checkpoints}
                                    onChange={(event: any) => {
                                        setCheckpoints(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        {(type == ProductType.LicenseGroupUnitQualityCheckPackage12Months || type == ProductType.LicenseGroupUnitQualityCheckPackage24Months ||
                            type == ProductType.LicenseGroupUnitQualityCheckPackage36Months || type == ProductType.LicenseGroupUnitQualityCheckPackageTest) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Stückzahl Qualitätskontrolle"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={qmCount}
                                    onChange={(event: any) => {
                                        setQmCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        {(type == ProductType.LicenseGroupPublicFeedbackPackage12Months || type == ProductType.LicenseGroupPublicFeedbackPackage24Months ||
                            type == ProductType.LicenseGroupPublicFeedbackPackage36Months || type == ProductType.LicenseGroupPublicFeedbackPackageTest) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Stückzahl Feedback Public"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={publicFeedbackCount}
                                    onChange={(event: any) => {
                                        setPublicFeedbackCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }

                        {(type == ProductType.LicenseGroupPayPerServicePackage12Months || type == ProductType.LicenseGroupPayPerServicePackage24Months ||
                            type == ProductType.LicenseGroupPayPerServicePackage36Months || type == ProductType.LicenseGroupPayPerServicePackageTest) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Stückzahl PayPerService"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={payPerServiceCount}
                                    onChange={(event: any) => {
                                        setPayPerServiceCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        {(type == ProductType.LicenseCalendarProPackage12Months || type == ProductType.LicenseCalendarProPackage24Months ||
                            type == ProductType.LicenseCalendarProPackage36Months || type == ProductType.LicenseCalendarProPackageTest) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Stückzahl KalenderPro"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={calendarProCount}
                                    onChange={(event: any) => {
                                        setCalendarProCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        {(type == ProductType.LicenseGroupTaqtFeedbackPackage12Months || type == ProductType.LicenseGroupTaqtFeedbackPackage24Months ||
                            type == ProductType.LicenseGroupTaqtFeedbackPackage36Months || type == ProductType.LicenseGroupTaqtFeedbackPackageTest) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Stückzahl Touchless-Feedback-Einheiten"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={taqtFeedbackCount}
                                    onChange={(event: any) => {
                                        setTaqtFeedbackCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        {(type == ProductType.LicenseGroupTaqtTimePackage12Months || type == ProductType.LicenseGroupTaqtTimePackage24Months ||
                            type == ProductType.LicenseGroupTaqtTimePackage36Months || type == ProductType.LicenseGroupTaqtTimePackageTest) &&
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Stückzahl Touchless-Time-Einheiten"
                                    type="number"
                                    disabled={loading}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                    }}
                                    value={taqtTimeCount}
                                    onChange={(event: any) => {
                                        setTaqtTimeCount(event.target.value);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} lg={4}>
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                                required
                                variant="outlined"
                            >
                                <InputLabel id="price-settings-page-add-settings-dialog-price-type">Typ</InputLabel>
                                <Select
                                    labelId="price-settings-page-add-settings-dialog-price-type"
                                    value={type}
                                    label="Typ"
                                    disabled={loading}
                                    error={!StringHelper.IsNullOrWhiteSpace(typeError)}
                                    onFocus={resetErrorText}
                                    onChange={(event: any) => {
                                        setType(event.target.value);

                                        if (StringHelper.IsNullOrWhiteSpace(name))
                                            setName(Product.GetLabelByType(event.target.value));

                                        setChanged(true);
                                    }}
                                >
                                    {
                                        Product.AvailableTypes
                                            .filter(x => !products.some(p => p.type === x.value && type != p.type))
                                            .sort((a, b) => a.value - b.value)
                                            .map((selection: { value: ProductType, label: string }) => <MenuItem value={selection.value}>{selection.value} - {selection.label}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Einheit Preis"
                                type="number"
                                disabled={loading}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                                error={!StringHelper.IsNullOrWhiteSpace(unitPriceError)}
                                value={unitPrice}
                                onFocus={resetErrorText}
                                onChange={(event: any) => {
                                    setUnitPrice(event.target.value);
                                    setChanged(true);
                                }}
                            />
                        </Grid>


                        <Grid container item xs={12} lg={3} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography>{isVisibleInShop ? "Produkt im Shop sichtbar" : "Produkt nicht im Shop sichtbar"}</Typography>
                            </Grid>
                            <Grid item>
                                <Switch
                                    color="primary"
                                    checked={isVisibleInShop}
                                    onChange={() => {
                                        setIsVisibleInShop(x => !x);
                                        setChanged(true);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>
                                Enthält folgende Produkt Items:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setItemProducts([...itemProducts, ItemProduct.CreateInstance(ItemProductType.None)]);
                                }}
                                endIcon={<Add />}
                            >
                                Produkt Item Hinzufügen
                            </Button>
                        </Grid>
                        {itemProducts.map((el, index) => {
                            return buildProductItem(el, setItemProducts, index);
                        })}
                        <Grid item xs={12}>
                            {buildItemOptionSelection()}
                        </Grid>

                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {

                        if (!changed) {
                            onClose();
                            return;
                        };

                        if (!isValid()) return;

                        row.name = name;
                        row.days = days;
                        row.priority = priority;
                        row.checkpointCount = checkpoints;
                        row.qmCount = qmCount;
                        row.publicFeedbackCount = publicFeedbackCount;
                        row.payPerServiceCount = payPerServiceCount;
                        row.calendarProCount = calendarProCount;
                        row.taqtFeedbackCount = taqtFeedbackCount;
                        row.taqtTimeCount = taqtTimeCount;
                        row.sensorCount = sensorCount;
                        row.articleNumber = articleNumber;
                        row.description = description;
                        row.itemProduct = [...itemProducts];
                        row.isVisibleInShop = isVisibleInShop;
                        row.itemOption = itemOptions;
                        row.lengthDescription = lengthDescription;

                        if (type)
                            row.type = type;

                        row.unitPrice = CurrencyHelper.convertStringToCurrencyValue(unitPrice);

                        setLoading(true);
                        await onSave(row);
                        setLoading(false);
                        setChanged(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
