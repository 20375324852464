import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import RRule from "rrule";
import { DateDataTable } from "./DateDataTable";
import useNoInitialEffect from "./extensions";
import { adjustForUTCOffset } from "./date-management";
import { useTranslation } from "react-i18next";

export const RruleGeneratorComponent = ({ rRule, setRrule }: { rRule: RRule, setRrule: any }) => {

    const {t} = useTranslation();
    const [dates, setDates] = React.useState<Date[]>([]);
    const [bymonthday, setBymonthday] = React.useState("");
    const [byyearday, setByyearday] = React.useState("");
    const [byWeekdays, setByWeekDays] = React.useState<number[]>([]);
    const [byMonth, setByMonth] = React.useState<number[]>([]);
    const [byhour, setByHour] = React.useState("");
    React.useEffect(() => {
        console.log(rRule.toString());
        if (rRule.options.bymonth) {
            setByMonth(rRule.options.bymonth);
        }
        if (rRule.options.byweekday) {
            setByWeekDays(rRule.options.byweekday);
        }
        if (rRule.options.bymonthday) {
            setBymonthday(rRule.options.bymonthday.toString());
        }
        if (rRule.options.byyearday) {
            setByyearday(rRule.options.byyearday.toString());
        }
        if (rRule.options.byhour) {
            setByHour(rRule.options.byhour.toString());
        }

        let dates = rRule.all();
        dates = dates.map(date=>{
            return adjustForUTCOffset(date);
        });
        console.log(dates);
        setDates(dates);
    }, [rRule])

    return (
        <Grid item container direction="row">
            <Grid item xs={6} container direction="column" spacing={3}>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Typography fontWeight={'bold'} style={{ fontSize: 34 }}>Anzahl der Services in einer Periode</Typography>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Frequenz</FormLabel>
                        <RadioGroup
                            row
                            value={rRule.options.freq}
                            onChange={(event) => {
                                rRule.options.freq = parseInt(event.target.value) as any;
                                setRrule(new RRule({ ...rRule.options }));
                            }}
                        >
                            <FormControlLabel
                                value={RRule.YEARLY}
                                control={<Radio />}
                                label={"Jährlich"}
                            />
                            <FormControlLabel
                                value={RRule.MONTHLY}
                                control={<Radio />}
                                label={"Monatlich"}
                            />
                            <FormControlLabel
                                value={RRule.WEEKLY}
                                control={<Radio />}
                                label={"Wöchentlich"}
                            />
                            <FormControlLabel
                                value={RRule.DAILY}
                                control={<Radio />}
                                label={"Täglich"}
                            />
                            <FormControlLabel
                                value={RRule.HOURLY}
                                control={<Radio />}
                                label={"Stündlich"}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}>Das Intervall zwischen jeder Frequenziteration. Wenn Sie beispielsweise Jährlich verwenden, bedeutet ein Intervall von 2 einmal alle zwei Jahre, bei Täglich bedeutet es jedoch einmal alle zwei Tage.</Typography>}>
                        <TextField
                            label={"Intervall"}
                            type="number"
                            value={rRule.options.interval}
                            onChange={(event) => {
                                if (parseInt(event.target.value) > 0) {
                                    rRule.options.interval = parseInt(event.target.value) as any;
                                    setRrule(new RRule({ ...rRule.options }));
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}>Der Wochenstarttag. Gibt den ersten Tag der Woche an. Dies wirkt sich auf Wiederholungen basierend auf wöchentlichen Perioden aus.</Typography>}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Wochenstart</FormLabel>
                            <RadioGroup
                                row
                                value={rRule.options.wkst}
                                onChange={(event) => {
                                    rRule.options.wkst = parseInt(event.target.value) as any;
                                    setRrule(new RRule({ ...rRule.options }));
                                }}
                            >
                                <FormControlLabel
                                    value={RRule.MO.weekday}
                                    control={<Radio />}
                                    label={"Montag"}
                                />
                                <FormControlLabel
                                    value={RRule.TU.weekday}
                                    control={<Radio />}
                                    label={"Dienstag"}
                                />
                                <FormControlLabel
                                    value={RRule.WE.weekday}
                                    control={<Radio />}
                                    label={"Mittwoch"}
                                />
                                <FormControlLabel
                                    value={RRule.TH.weekday}
                                    control={<Radio />}
                                    label={"Donnerstag"}
                                />
                                <FormControlLabel
                                    value={RRule.FR.weekday}
                                    control={<Radio />}
                                    label={"Freitag"}
                                />
                                <FormControlLabel
                                    value={RRule.SA.weekday}
                                    control={<Radio />}
                                    label={"Samstag"}
                                />
                                <FormControlLabel
                                    value={RRule.SU.weekday}
                                    control={<Radio />}
                                    label={"Sonntag"}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}> Wenn diese Werte angegeben werden, definieren sie die Wochentage, an denen die Wiederholung angewendet wird.</Typography>}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Wochentage</FormLabel>
                            <FormGroup
                                row
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.MO.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.MO.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.MO.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.MO.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.MO.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Montag"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.TU.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.TU.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.TU.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.TU.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.TU.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Dienstag"}
                                />
                                <FormControlLabel
                                    value={RRule.WE}
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.WE.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.WE.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.WE.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.WE.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.WE.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Mittwoch"}
                                />
                                <FormControlLabel
                                    value={RRule.TH}
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.TH.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.TH.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.TH.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.TH.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.TH.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Donnerstag"}
                                />
                                <FormControlLabel
                                    value={RRule.FR}
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.FR.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.FR.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.FR.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.FR.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.FR.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Freitag"}
                                />
                                <FormControlLabel
                                    value={RRule.SA}
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.SA.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.SA.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.SA.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.SA.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.SA.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Samstag"}
                                />
                                <FormControlLabel
                                    value={RRule.SU}
                                    control={<Checkbox
                                        checked={byWeekdays.some(x => x == RRule.SU.weekday)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByWeekDays(prev => [...prev, RRule.SU.weekday]);
                                                rRule.options.byweekday = [...byWeekdays, RRule.SU.weekday];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByWeekDays(prev => prev.filter((x: any) => x != RRule.SU.weekday));
                                                rRule.options.byweekday = byWeekdays.filter((x: any) => x != RRule.SU.weekday);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Sonntag"}
                                />
                            </FormGroup>
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}> Wenn diese Werte angegeben werden, definieren sie die Monate, an denen die Wiederholung angewendet wird.</Typography>}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Monate</FormLabel>
                            <FormGroup
                                row
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 1)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 1]);
                                                rRule.options.bymonth = [...byMonth, 1];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 1));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 1);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Januar"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 2)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 2]);
                                                rRule.options.bymonth = [...byMonth, 2];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 2));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 2);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Februar"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 3)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 3]);
                                                rRule.options.bymonth = [...byMonth, 3];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 3));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 3);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"März"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 4)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 4]);
                                                rRule.options.bymonth = [...byMonth, 4];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 4));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 4);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"April"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 5)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 5]);
                                                rRule.options.bymonth = [...byMonth, 5];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 5));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 5);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Mai"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 6)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 6]);
                                                rRule.options.bymonth = [...byMonth, 6];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 6));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 6);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Juni"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 7)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 7]);
                                                rRule.options.bymonth = [...byMonth, 7];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 7));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 7);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Juli"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 8)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 8]);
                                                rRule.options.bymonth = [...byMonth, 8];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 8));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 8);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"August"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 9)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 9]);
                                                rRule.options.bymonth = [...byMonth, 9];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 9));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 9);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"September"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 10)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 10]);
                                                rRule.options.bymonth = [...byMonth, 10];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 10));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 10);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Oktober"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 11)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 11]);
                                                rRule.options.bymonth = [...byMonth, 11];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 11));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 11);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"November"}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={byMonth.some(x => x == 12)}
                                        onChange={(event: any) => {
                                            if (event.target.checked) {
                                                setByMonth(prev => [...prev, 12]);
                                                rRule.options.bymonth = [...byMonth, 12];
                                                setRrule(new RRule({ ...rRule.options }));
                                            } else {
                                                setByMonth(prev => prev.filter((x: any) => x != 12));
                                                rRule.options.bymonth = byMonth.filter((x: any) => x != 12);
                                                setRrule(new RRule({ ...rRule.options }));
                                            }
                                        }}
                                        color="primary"
                                    />}
                                    label={"Dezember"}
                                />
                            </FormGroup>
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}>Wenn angegeben, muss es entweder eine Ganzzahl oder eine Folge von Ganzzahlen sein, d. h. die Monatstage, auf die die Wiederholung angewendet werden soll.</Typography>}>
                        <TextField
                            label={"Monatstag"}
                            value={bymonthday}
                            onChange={(event) => {
                                try {
                                    if (event.target.value) {
                                        rRule.options.bymonthday = JSON.parse(`[${event.target.value}]`);
                                    } else {
                                        rRule.options.bymonthday = [];
                                    }
                                    setRrule(new RRule({ ...rRule.options }));
                                } catch {

                                }
                                setBymonthday(event.target.value);

                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}>Wenn angegeben, muss es entweder eine Ganzzahl oder eine Folge von Ganzzahlen sein, d. h. die Tage im Jahr, auf die die Wiederholung angewendet werden soll.</Typography>}>
                        <TextField
                            label={"Jahrestag"}
                            value={byyearday}
                            onChange={(event) => {
                                try {
                                    if (event.target.value) {
                                        rRule.options.byyearday = JSON.parse(`[${event.target.value}]`);
                                    } else {
                                        rRule.options.byyearday = [];
                                    }
                                    setRrule(new RRule({ ...rRule.options }));
                                } catch {

                                }
                                setByyearday(event.target.value);
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}>Wenn angegeben, muss es entweder eine Ganzzahl oder eine Folge von Ganzzahlen sein, also die Stunden, auf die die Wiederholung angewendet werden soll.</Typography>}>
                        <TextField
                            label={"Stunden"}
                            value={byhour}
                            onChange={(event) => {
                                try {
                                    if (event.target.value) {
                                        rRule.options.byhour = JSON.parse(`[${event.target.value}]`);
                                        setRrule(new RRule({ ...rRule.options }));
                                    } else {
                                     //   rRule.options.byhour = [];
                                    }
                                } catch {

                                }
                                setByHour(event.target.value);
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>


            </Grid >
            <Grid xs={6} container item direction={"column"} spacing={3}>
                <Grid item>
                    <Typography style={{ fontWeight: 'bold', fontSize: 32 }}>{t("myUnitsSettings.preview")}</Typography>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography style={{ fontSize: 24, textAlign: 'center' }}>Wie viele Vorkommen werden generiert.</Typography>}>
                        <TextField
                            label={"Anzahl Einträge"}
                            type="number"
                            value={rRule.options.count}
                            onChange={(event) => {
                                rRule.options.count = parseInt(event.target.value) as any;
                                setRrule(new RRule({ ...rRule.options }));
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        < DateTimePicker
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={"Start Datum"}
                            value={rRule.options.dtstart}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                rRule.options.dtstart = newVal;
                                setRrule(new RRule({ ...rRule.options }));
                            }}
                            format={"dd.MM.yyyy HH:mm"}
                        />
                    </Grid>
                    <Grid item>
                        < DateTimePicker
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={"Ende Datum"}
                            value={rRule.options.until}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                rRule.options.until = newVal;
                                setRrule(new RRule({ ...rRule.options }));
                            }}
                            format={"dd.MM.yyyy HH:mm"}
                        />
                    </Grid>
                </Grid>
                {dates.length > 0 &&
                    <Grid item>
                        <DateDataTable rows={dates} />
                    </Grid>
                }
            </Grid>


        </Grid>
    );
}