import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, Switch, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, Save, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { ApplyGroupSettingsUpdateByObjectModel, UpdateGroupSettingsByObjectIdModel } from '../../../models/UpdateGroupSettingsByObjectIdModel';
import { useTranslation } from 'react-i18next';

export const ObjectGroupSettingsDialog = ({
  open,
  setOpen,
  currentObject,
  objects,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  currentObject: ObjectUnit,
  objects: ObjectUnit[]
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();

  const [token, setToken] = React.useState("");
  const [updateGroupSettingsByObjectModel, setUpdateGroupSettingsByObjectModel] = React.useState<UpdateGroupSettingsByObjectIdModel>();
  const handleClose = () => {
    setOpen(false);
  }

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);
      if (currentObject.updateGroupSettings) {
        if (!currentObject.updateGroupSettings.applyGroupSettingsUpdateByObjectModel){
          currentObject.updateGroupSettings.applyGroupSettingsUpdateByObjectModel = new ApplyGroupSettingsUpdateByObjectModel();
        }
        setUpdateGroupSettingsByObjectModel(currentObject.updateGroupSettings);
      } else {
        setUpdateGroupSettingsByObjectModel(new UpdateGroupSettingsByObjectIdModel());
      }

    })();

  }, [open]);


  console.log(updateGroupSettingsByObjectModel, "updateGroupSettingsByObjectModel");
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Gruppen Settings für Einheiten des Objekts</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <Grid container direction="row" spacing={3} >
          <Grid item xs={6} container direction="column" spacing={3}>
            <Grid item></Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updateGroupSettingsByObjectModel?.isObjectSignInRfidEnabled}
                    onChange={(event: any) => { setUpdateGroupSettingsByObjectModel({ ...updateGroupSettingsByObjectModel, isObjectSignInRfidEnabled: event.target.checked } as any); }}
                    name="isEnabled"
                    color="primary"
                  />
                }
                label="RFID Objekt Login für Einheit"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updateGroupSettingsByObjectModel?.isContentEnabled}
                    onChange={(event: any) => { setUpdateGroupSettingsByObjectModel({ ...updateGroupSettingsByObjectModel, isContentEnabled: event.target.checked } as any); }}
                    name="isEnabled"
                    color="primary"
                  />
                }
                label="Content Aktivieren"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="column" spacing={3}>
            <Grid item></Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={updateGroupSettingsByObjectModel?.applyGroupSettingsUpdateByObjectModel?.isObjectSignInRfidEnabled}
                    onChange={(event: any) => { 
                      if (!updateGroupSettingsByObjectModel) return;
                      updateGroupSettingsByObjectModel.applyGroupSettingsUpdateByObjectModel.isObjectSignInRfidEnabled = event.target.checked;
                      setUpdateGroupSettingsByObjectModel({ ...updateGroupSettingsByObjectModel}); 
                    }}                  />
                }
                labelPlacement="start"
                label={"Einstellung RFID Objekt Login Übernehmen"}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={updateGroupSettingsByObjectModel?.applyGroupSettingsUpdateByObjectModel?.isContentEnabled}
                    onChange={(event: any) => { 
                      if (!updateGroupSettingsByObjectModel) return;
                      updateGroupSettingsByObjectModel.applyGroupSettingsUpdateByObjectModel.isContentEnabled = event.target.checked;
                      setUpdateGroupSettingsByObjectModel({ ...updateGroupSettingsByObjectModel}); 
                    }}

                  />
                }
                labelPlacement="start"
                label={"Einstellung Content Aktivieren Übernehmen"}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>

      </DialogActions>
      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Save />}
          onClick={async () => {
            if (!currentObject || !updateGroupSettingsByObjectModel) return;

            await api.updateGroupSettingsFromObjectUnitAsync(currentObject.id, token, enqueueSnackbar, updateGroupSettingsByObjectModel);

            onRefresh();
            handleClose();
          }}
        >
          Einheiten Speichern des Objekts
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));