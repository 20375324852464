import React, { useContext, useRef } from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FileDownload, FindReplace, Print, Refresh, Save, SdStorage, Shop } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../../api';
import { getApiToken } from '../../../../../helpers/storage-management';
import { StringHelper } from '../../../../../helpers/string-helper';
import { GroupSettings, QrCodeInformation } from '../../../../../models/GroupSettings';
import { NavigatedFromType } from '../../../../../models/NavigatedFrom';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { usePortalAccountStyles } from '../../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../../dialogs/DefaultDialogTitle';
import { DigitalCheckpointDialog } from '../../../../dialogs/groupSettings/DigitalCheckpointDialog';
import { GroupLogosDialog } from '../../../../dialogs/GroupLogosDialog';
import { UserLogo } from '../../../../../models/UserLogo';
import { buildPrintItem, handleDownloadImage, noNullInInputNumber } from '../../../../common/qrcode/qrcodeManagement';
import { useReactToPrint } from 'react-to-print';
import IconCMN from '../../../../../assets/svgs/check-me-now_icon.png'
import { primaryColor } from '../../../../../App';
import { ChromePicker } from 'react-color';
import saveAs from 'file-saver';
import { GlobalContext } from '../../../PortalPage';
import { buildPrintItemGroup } from '../../../../core/GroupSettingFieldsLocation';
import { useTranslation } from 'react-i18next';

export enum QrCodeObjectType {
    Address = 0,
    Description = 1,
}

export class QrCodeObjectUnit {
    public qrCode !: any;
    public objectField1 !: string;
    public objectField2 !: string;
    public objectUnit !: ObjectUnit;
    public getRefPrint !: any;
}

export const ObjectQrCodePrintManyDialog = ({ visible, setVisible, objects }
    : { visible: boolean, setVisible: any, objects: ObjectUnit[] }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [currentObject, setCurrentObject] = React.useState<ObjectUnit>(new ObjectUnit());

    const [qrCodes, setQrCodes] = React.useState<QrCodeObjectUnit[]>([]);

    const [groupSettings, setGroupSettings] = React.useState<GroupSettings>();
    const history = useHistory();
    const [qrCodeObjectType, setQrCodeObjectType] = React.useState(QrCodeObjectType.Address);

    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const [isSettingsFromObjectEnabled, setIsSettingsFromObjectEnabled] = React.useState(false);

    const refPrint: any = useRef();

    const [sizeQrCode, setSizeQrCode] = React.useState(250);
    const [fontSizeSpecQrCode, setFontSizeSpecQrCode] = React.useState(30);
    const [fontSizeTypo, setFontSizeTypo] = React.useState(20);
    const [backgroundColorQrCode, setBackgroundColorQrCode] = React.useState("#eeeeee");
    const [borderColorQrCode, setBorderColorQrCode] = React.useState(primaryColor);
    const [urlQrCode, setUrlQrCode] = React.useState("www.check-me-now.com");
    const [token, setToken] = React.useState("");
    const [logo, setLogo] = React.useState<any>(IconCMN);
    const [logos, setLogos] = React.useState<UserLogo[]>([]);
    const [groupLogosDialogIsOpen, setOpenGroupLogosDialog] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { sourceLogoUser
    }: any = useContext(GlobalContext);
    const onClose = () => {
        setCurrentObject(ObjectUnit.CreateObject());
        setVisible(false);
    }

    React.useEffect(() => {
        if (visible) {
            (async () => {
                setLoading(true);
                if (sourceLogoUser) setLogo(sourceLogoUser);

                setCount(objects.length);
                let qrCodes: QrCodeObjectUnit[] = [];
                const tokenTemp = getApiToken(history);
                setToken(tokenTemp);
                for (let object of objects) {
                    let qrCode = await api.downloadQrCodeObjectImageAsync(tokenTemp, object.id);
                    let newEntry = new QrCodeObjectUnit();
                    newEntry.qrCode = qrCode;
                    mapQrCodeTypeToText(QrCodeObjectType.Address, newEntry, object);
                    newEntry.objectUnit = object;
                    qrCodes.push(newEntry);
                    setCount(prev => prev - 1);

                }
                setLoading(false);
                setQrCodes(qrCodes);
            })();
        }


    }, [visible]);




    const mapQrCodeTypeToText = (qrCodeObjectType: QrCodeObjectType, qrCodeObject: QrCodeObjectUnit, object: ObjectUnit) => {
        switch (qrCodeObjectType) {
            case QrCodeObjectType.Address:
                qrCodeObject.objectField1 = object?.name || "";
                qrCodeObject.objectField2 = `${object?.addressLine} ${object?.city}`;
                break;
            default:
                qrCodeObject.objectField1 = object?.description || "";
                qrCodeObject.objectField2 = `${object?.costLocation} ${object?.objectNumber}`;
                break
        }
    }




    const onLogoFileChosen = async (event: any) => {

        const file = event.target.files[0];
        const blob = new Blob([file]);
        const url = URL.createObjectURL(blob);

        setLogo(url);
    }

    const handlePrint = useReactToPrint({

        content: () => refPrint.current,
        onAfterPrint: () => {

        }

    });

    const refreshLogo = async () => {
        let logo = "";// = await api.downloadLogoImageAsync(row as GroupSettings, token);
        if (!logo) logo = IconCMN;
        setLogo(logo);
    }


    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title="Objekt Qr-Code"
                handleClose={onClose}
            />

            <DialogContent>

                <Grid container direction="row" className={classes.container} spacing={3}>
                    {loading &&
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Lade Qr Codes: Noch {count} Downloads</Typography>
                            <CircularProgress size={128} />
                        </Grid>}
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSettingsFromObjectEnabled}
                                    onChange={(event: any) => { setIsSettingsFromObjectEnabled(event.target.checked) }}
                                    name="isEnabled"
                                    color="primary"
                                />
                            }
                            label="Einstellungen aus dem Objekt übernehmen"
                        />
                    </Grid>
                    <Grid item>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="qrCodeObjectType"
                            value={qrCodeObjectType}
                            onChange={(event) => {
                                for (let qrCode of qrCodes) {
                                    mapQrCodeTypeToText(parseInt(event.target.value), qrCode, qrCode.objectUnit);
                                }
                                setQrCodeObjectType(parseInt(event.target.value));
                            }}
                        >
                            <FormControlLabel
                                value={QrCodeObjectType.Address}
                                control={<Radio />}
                                label={t("myUnitsSettings.address")}
                            />
                            <FormControlLabel
                                value={QrCodeObjectType.Description}
                                control={<Radio />}
                                label="Beschreibung"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={6} container direction="row" spacing={2}>
                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<FileDownload />} onClick={() => {
                                    for (let qrCode of qrCodes) {
                                        saveAs(qrCode.qrCode, `${qrCode.objectField1}.png`);
                                    }
                                }} color="primary">
                                    {t("myUnitsSettings.downloadQRCode")}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<Print />} color="primary" onClick={handlePrint}>
                                    {t("myUnitsSettings.printQRCode")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary" onClick={() => handleDownloadImage(refPrint)}>
                                    {t("myUnitsSettings.downloadLabel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary"
                                    onClick={() => {
                                        for (let qrCode of qrCodes) {
                                            handleDownloadImage(qrCode.getRefPrint(), qrCode.objectField1)
                                        }
                                    }}>
                                    Download Alle Etikette Einzeln
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SdStorage />}
                                    component="label">
                                    Logo von Festplatte
                                    <input
                                        type="file"
                                        name="updateLogo"
                                        onChange={onLogoFileChosen}
                                        hidden
                                        accept="image/png, image/jpeg, image/jpg" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size="small"
                                    startIcon={loading ? <CircularProgress color="inherit" /> : <FindReplace />}
                                    variant={'contained'}
                                    disabled={loading}
                                    onClick={async () => {
                                        setLoading(true);
                                        let logos = await api.getLogosOfGroup(token);
                                        let ret = [];
                                        for (let logo of logos) {
                                            let res = await api.downloadAnyLogoImageAsync(logo.filename, logo.groupId, token);
                                            logo.objectUrl = res;
                                            ret.push(logo);
                                        }
                                        setLogos(ret);
                                        setOpenGroupLogosDialog(true);
                                        setLoading(false);
                                    }}
                                    color="primary">
                                    Gespeicherte Logos ansehen
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={t("myUnitsSettings.printSize")}
                                value={sizeQrCode}
                                type="number"
                                onChange={(event) => {
                                    setSizeQrCode(noNullInInputNumber(event));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={t("myUnitsSettings.fontSizeButton")}
                                value={fontSizeSpecQrCode}
                                type="number"
                                onChange={(event) => {
                                    setFontSizeSpecQrCode(noNullInInputNumber(event));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={t("myUnitsSettings.fontSizeBottomRight")}
                                value={fontSizeTypo}
                                type="number"
                                onChange={(event) => {
                                    setFontSizeTypo(noNullInInputNumber(event));
                                }}
                                variant="filled"
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label="Url"
                                value={urlQrCode}
                                onChange={(event) => {
                                    setUrlQrCode(event.target.value);
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid container item justifyContent="center" direction="row">
                            <Grid item>
                                <Grid item style={{ textAlign: 'center' }}>
                                    <Typography variant="h3">{t("myUnitsSettings.preview")}</Typography>
                                </Grid>
                                <Button variant="contained" startIcon={<Refresh />} color="primary" onClick={refreshLogo}>
                                    {t("myUnitsSettings.refreshPreview")}
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={3} spacing={3} container direction="column" style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item>
                                <Typography variant="h5">{t("myUnitsSettings.labelBackgroundColor")}</Typography>
                            </Grid>
                            <Grid item>
                                <ChromePicker color={backgroundColorQrCode} onChange={(event) => { setBackgroundColorQrCode(event.hex) }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={3} spacing={3} container direction="column" style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item>
                                <Typography variant="h5">{t("myUnitsSettings.borderColor")}</Typography>
                            </Grid>
                            <Grid item>
                                <ChromePicker color={borderColorQrCode} onChange={(event) => { setBorderColorQrCode(event.hex) }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div ref={refPrint} className="page-size">
                        {qrCodes?.filter(x => !!x?.objectUnit?.qrCodeInformation).map(qrCode => {
                            return (
                                <>

                                    <BuildQrCodeLogo urlQrCode={urlQrCode} isSettingsFromObjectEnabled={isSettingsFromObjectEnabled} qrCode={qrCode.qrCode} firstTitle={qrCode.objectField1} secondTitle={qrCode.objectField2} sizeQrCode={sizeQrCode} fontSizeSpecQrCode={fontSizeSpecQrCode}
                                        fontSizeTypo={fontSizeTypo} borderColorQrCode={borderColorQrCode} backgroundColorQrCode={backgroundColorQrCode} logo={logo} qrCodeObject={qrCode} />
                                    <div className="page-break"></div>
                                </>
                            )
                        })}
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
            </DialogActions>
            <GroupLogosDialog logos={logos} visible={groupLogosDialogIsOpen}
                onConfirmed={async (item: UserLogo) => {
                    setOpenGroupLogosDialog(false);
                    let blob = await fetch(item.objectUrl).then(r => r.blob());
                    const file = new File([blob], "random", { type: blob.type })
                    setLogo(item.objectUrl);
                }}
                onClose={() => {
                    setOpenGroupLogosDialog(false);
                }
                }
                onDelete={async (item: UserLogo) => {
                    let settings = await api.getGroupSettings(item.groupId, token);
                    if (settings.logo?.fileName == item.filename) {
                        enqueueSnackbar("Logo wird gerade von der Einheit verwendet! Kann nicht gelöscht werden", { variant: 'error' });
                        return;
                    }
                    await api.deleteGroupLogoAsync(item.groupId, item.filename, token, enqueueSnackbar)
                }}
            />
        </Dialog >
    );
};

export const BuildQrCodeLogo = (
    { isSettingsFromObjectEnabled, urlQrCode, qrCode, firstTitle, secondTitle, sizeQrCode, fontSizeSpecQrCode, fontSizeTypo, borderColorQrCode, backgroundColorQrCode, logo, qrCodeObject }:
        { isSettingsFromObjectEnabled: boolean, urlQrCode: string, qrCode: string, firstTitle: any, secondTitle: any, sizeQrCode: number, fontSizeSpecQrCode: number, fontSizeTypo: number, borderColorQrCode: string, backgroundColorQrCode: string, logo: any, qrCodeObject: QrCodeObjectUnit }) => {

    const refPrint = useRef();


    const getRefPrint = () => {
        return refPrint;
    };

    React.useEffect(() => {
        qrCodeObject.getRefPrint = getRefPrint;
    }, []);


    return (
        <React.Fragment>
            {isSettingsFromObjectEnabled ? buildPrintItemGroup(qrCodeObject.objectUnit.qrCodeInformation.printSize, qrCodeObject.objectUnit.qrCodeInformation.fontSizeButton, qrCodeObject.objectUnit.qrCodeInformation.fontSizeBottomRight, refPrint, qrCodeObject.qrCode, qrCodeObject.objectUnit.qrCodeInformation, logo, qrCodeObject.objectField1, qrCodeObject.objectField2, logo, "Objekt")
                :
                buildPrintItemGroup(sizeQrCode, fontSizeSpecQrCode, fontSizeTypo, refPrint, qrCode, new QrCodeInformation(backgroundColorQrCode, borderColorQrCode, urlQrCode), logo, firstTitle, secondTitle, logo, "Objekt")}

        </React.Fragment>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
