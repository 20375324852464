import { Chart, Series, CommonSeriesSettings, Legend, Export, Tooltip, ValueAxis, Label, Format, Connector, ZoomAndPan, Size, Font } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight as chartHeightGlobal, ChartLabelType, customizeLabel, customizeLegendTextBarLineChart, customizePercentageText, customizeTooltipWithType, mapLabels, NoDataChartLoader } from './Base';
import React from 'react';
import { Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Fullscreen, Height, ZoomOut } from '@mui/icons-material';
import { FullScreenChartDialog } from './dialog/FullScreenChartDialog';
import { getWindowDimensions } from '../../helpers/tables/common';
import { ChartLabelAlternative } from './LineChart';
import { GridStackCustom, GridStackCustomItem } from '../../models/GridStackCustom';
import { CustomDashboardContext } from '../authorized/portal/Dashboard/CustomDashboard';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});


export const StackDraw = ({ classes, data, dataField, text, loadingCompleted, chartLabelType, childRefChart, colors, chartHeight = chartHeightGlobal, chartLabelAlternative = ChartLabelAlternative.Standard }: { classes?: any, data?: any, dataField?: any, text?: any, loadingCompleted?: any, chartLabelType?: any, childRefChart?: any, colors?: any, chartHeight?: any, chartLabelAlternative?: ChartLabelAlternative }) => {
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);
    const [chartHeightNow, setChartHeightNow] = React.useState(chartHeight);
    /*    const {item, selectedGridStackCustom} : {item: GridStackCustomItem, selectedGridStackCustom: GridStackCustom} = React.useContext(CustomDashboardContext);
        React.useEffect(()=>{
            if (item){
                if (item.isStandardChartHeightEnabled){
                    setChartHeightNow(chartHeight);
                }
                else if (item.isAutoChartHeightEnabled){
                    setChartHeightNow(item.height * selectedGridStackCustom.cellHeight - 530)
                }else{
                    setChartHeightNow(item.chartHeight)
                }
            }
        },[selectedGridStackCustom]) */

    if (!data || data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;

    const buildLabel = () => {
        switch (chartLabelType) {
            case ChartLabelType.Percent:
                return (
                    <ValueAxis>
                        <Label customizeText={customizePercentageText}>
                            <Font weight={'bold'} size={30} />
                        </Label>
                    </ValueAxis>
                )
            case ChartLabelType.Comma:
            case ChartLabelType.Co2:
                return (
                    <ValueAxis>
                        <Label customizeText={({ valueText }: any) => { return `${valueText}` }} >
                            <Font weight={'bold'} size={30} />


                        </Label>
                    </ValueAxis>
                )
            default:
                return <ValueAxis allowDecimals={false}>
                    <Label>
                        <Font weight={'bold'} size={30} />
                    </Label>
                </ValueAxis>
        }
    }


    const customizeLabelStackedChart = (point: any) => {
        switch (chartLabelType) {
            case ChartLabelType.SquareMeter:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText} m²`;
            case ChartLabelType.Co2:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText} kg Co2`;
            case ChartLabelType.Hours:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText} h`;
            case ChartLabelType.Minutes:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText} Min`;
            case ChartLabelType.Seconds:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText} Sek`;
            case ChartLabelType.Percent:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText}%`;
            default:
                if (point.valueText == 0) {
                    return "";
                }
                return `${mapLabels(point.seriesName, chartLabelAlternative)}: ${point.valueText}${point.percentText == '100%' ? "" : ` / ${point.percentText}`}`;
        }

    }
    const buildChart = () => {
        return (
            <>
                <Chart
                    ref={childRefChart}
                    palette={colors}
                    title={text}
                    dataSource={data}
                    id="chart"
                >
                    <Size
                        height={chartHeightNow}
                        width={'100%'}
                    />
                    <CommonSeriesSettings
                        argumentField="dateDisplay"
                        type="stackedBar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                    >
                        <Label visible={true}
                            customizeText={customizeLabelStackedChart}>
                            <Font weight={'bold'} size={18} />
                            <Connector visible={true} />
                        </Label>
                    </CommonSeriesSettings>
                    <ZoomAndPan
                        valueAxis="both"
                        argumentAxis="both"
                        dragToZoom={true}
                        allowMouseWheel={false}
                        panKey="shift" />
                    {dataField.map((el: any, id: any) => {
                        return (
                            <Series
                                valueField={el}
                                argumentField="dateDisplay"
                                name={el}
                                key={id}
                            />
                        );
                    })}

                    {buildLabel()}
                    <Legend verticalAlignment="right" horizontalAlignment="right" customizeText={customizeLegendTextBarLineChart}></Legend>
                    <Export enabled={true} />
                    <Tooltip
                        enabled={true}
                        location="edge"
                        customizeTooltip={(arg: any) => customizeTooltipWithType(arg, chartLabelType, chartLabelAlternative)}
                    />
                </Chart>
                <Grid container direction="row" spacing={3} alignItems="center">
                    <Grid item>
                        <ChartHeightField chartHeight={chartHeightNow} setChartHeight={setChartHeightNow} />
                    </Grid>
                    <Grid item>
                        <ResetButtonZoom chartRef={childRefChart} />
                    </Grid>
                    <Grid item>
                        <FullscreenButton isFullScreenVisible={isFullScreenChartVisible} setIsFullscreenChartVisible={setIsFullscreenChartVisible} setChartHeight={setChartHeightNow} />
                    </Grid>

                </Grid>
            </>
        )
    }

    return isFullScreenChartVisible ? <FullScreenChartDialog setChartHeight={setChartHeightNow} visible={isFullScreenChartVisible} title={text} onClose={() => { setIsFullscreenChartVisible(false) }} buildChart={buildChart} /> : buildChart();
}

export const ResetButtonZoom = ({ chartRef }: { chartRef: any }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<ZoomOut />}
            onClick={async () => {
                chartRef.current.instance.resetVisualRange();
            }}
        >
            Reset Zoom
        </Button>
    )
}

export const FullscreenButton = ({ isFullScreenVisible, setIsFullscreenChartVisible, setChartHeight }: { isFullScreenVisible: boolean, setIsFullscreenChartVisible: any, setChartHeight: any }) => {
    React.useEffect(() => {
        if (isFullScreenVisible) {
            if (setChartHeight) {
                setChartHeight(getWindowDimensions().height);
            }
        }
    }, [isFullScreenVisible])

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<Fullscreen />}
            disabled={isFullScreenVisible}
            onClick={async () => {
                setIsFullscreenChartVisible(true);
            }}
        >
            Fullscreen
        </Button>
    )
}

export const ChartHeightField = ({ chartHeight, setChartHeight }: { chartHeight: number, setChartHeight: any }) => {
    const { item, updateGridStack, selectedGridStackCustom }: { item: GridStackCustomItem, updateGridStack: (currentGridStackCustom: GridStackCustomItem) => void, selectedGridStackCustom: GridStackCustom } = React.useContext(CustomDashboardContext);
    React.useEffect(() => {
        if (item) {
            if (item.isStandardChartHeightEnabled) {
                setChartHeight(chartHeight);
            }
            else if (item.isAutoChartHeightEnabled) {
                setChartHeight(item.height * selectedGridStackCustom.cellHeight - 330)
            } else {
                setChartHeight(item.chartHeight)
            }
        }
    }, [selectedGridStackCustom]);
    return (
        <TextField
            fullWidth
            required
            variant="outlined"
            label="Höhe"
            type="number"
            InputProps={{
                endAdornment: <InputAdornment position="end"><Height /></InputAdornment>
            }}
            value={chartHeight}
            onChange={(event: any) => {
                let val = parseInt(event.target.value);
                if (item) {
                    item.chartHeight = val;
                    updateGridStack(item);
                }

                setChartHeight(val);
            }}
        />
    )
}

export const StackedChart = withStyles(demoStyles, { name: 'Demo' })(StackDraw);