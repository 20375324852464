import { Grid, Paper } from "@mui/material"
import { GroupServiceCompletedCleaningTasks } from "../../../../../models/GroupServiceMode"
import { elevationPaper, paperStyle } from "../../../../../styles/container"
import { RotatedBarChart } from "../../../../charts/RotatedBarChart"
import { TimeChoice } from "../../../../core/TimeChoice"

export const CleaningTasksOnTimeDashboard = ({ data, dataName1, dataName2, allData, setChartData, childRef, loadingCompleted, childRefChart }
    : { data: GroupServiceCompletedCleaningTasks[], dataName1: any, dataName2: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart? :any }) => {
    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        <RotatedBarChart
                            childRefChart={childRefChart}
                            data={data}
                            dataField1={'isOnTime'}
                            dataField2={'isNotOnTime'}
                            text={"Service Mode: Verpasst <> Pünktlich"}
                            loadingCompleted={loadingCompleted} />
                    </Grid>
                    <Grid item>
                        <TimeChoice
                            ref={childRef}
                            fields={[dataName1, dataName2]}
                            data={allData}
                            setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}