import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { Button, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, Delete, Publish, RemoveCircle } from '@mui/icons-material';
import { DeviceType, SsdDeviceModel } from '../../../../models/bssd/SsdDeviceModel';
import { BssdRegisteredDeviceModel } from '../../../../models/bssd/BssdRegisteredDeviceModel';
import { errorColor, green, white } from '../../../../styles/colors';
import { lightGreen, red } from '@mui/material/colors';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';

export const SsdRegisteredDevicesTable = ({ rows, selection, setSelection, chosenDeviceType, assignBssdSensorLicense, groups, onDeleted }
    : { rows: BssdRegisteredDeviceModel[], selection: any[], setSelection: any, chosenDeviceType: DeviceType, assignBssdSensorLicense: any, groups: GroupLicenseTable[], onDeleted: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    let columns = [
        {
            name: BssdRegisteredDeviceModel.Columns.groupName,
            title: "Einheit Name",
            getCellValue: (row: BssdRegisteredDeviceModel) => row.groupId ? groups.find(x => x.id == row.groupId)?.notificationName : "Keines zugewiesen",
        },
        {
            name: BssdRegisteredDeviceModel.Columns.id,
            title: "Device Id",
            getCellValue: (row: BssdRegisteredDeviceModel) => row.id,
        },
        {
            name: BssdRegisteredDeviceModel.Columns.deviceType,
            title: "Device Typ",
            getCellValue: (row: BssdRegisteredDeviceModel) => SsdDeviceModel.GetLabel(row.deviceType),
        },
        {
            name: BssdRegisteredDeviceModel.Columns.hubId,
            title: "Hub Id",
            getCellValue: (row: BssdRegisteredDeviceModel) => row.hubId,
        },
        {
            name: BssdRegisteredDeviceModel.Columns.creationTimestamp,
            title: "Reservierungszeitpunkt",
            getCellValue: (row: BssdRegisteredDeviceModel) => formatEuropeanDateTime(row.creationTimestamp),
        }
    ];
    if (chosenDeviceType == DeviceType.Display) {
        columns = [{
            name: BssdRegisteredDeviceModel.Columns.assignSensorLicense,
            title: "Battery Display Aktiviert",
            getCellValue: (row: BssdRegisteredDeviceModel) => row.groupId ? "Aktiviert" : "Battery Display nicht aktiviert",
        }, ...columns]
    }
    else if (chosenDeviceType == DeviceType.Sensor) {
        columns = [{
            name: BssdRegisteredDeviceModel.Columns.deleteButton,
            title: "Bearbeiten",
            getCellValue: (row: BssdRegisteredDeviceModel) => row.groupId ?
                <Button
                    variant="contained"
                    style={{ backgroundColor: red[900], color: white }}
                    onClick={async () => {
                        const tempToken = getApiToken(history);
                        await api.removeBssdSensorFromGroup(row.groupId, row.id, tempToken, enqueueSnackbar);
                        onDeleted();
                    }}
                    endIcon={<RemoveCircle />}
                >
                    Einheit Zuweisung Löschen
                </Button> : "Sensor keiner Einheit zugewiesen"
        } as any, ...columns]
    }

    const Cell = (props: any) => {
        const { column, row, value } = props;
        /*   if (column.name === BssdRegisteredDeviceModel.Columns.assignSensorLicense && row.groupId) {
               return (
                   <Table.Cell {...props}>
                       <Button
                           variant="contained"
                           color="primary"
                           startIcon={<Publish />}
                           onClick={() => {
                               assignBssdSensorLicense(row.groupId);
                           }}
                       >
                          Battery Display Lizenz zuweisen
                       </Button>
                   </Table.Cell>
               )
           } */
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Hubs');
        });
    };

    let defaultColumnWidths = React.useRef([{ columnName: BssdRegisteredDeviceModel.Columns.deleteButton, width: 300 }, ...columns.map(el => { return { columnName: el.name, width: 220 } })]);
    const [defaultHiddenColumnNames] = React.useState([]);

    const SelectionCell = (props: any) => {
        const { column, row, value } = props;
        if (row.groupId) return (
            <td style={{ paddingLeft: 4, textAlign: 'right', overflow: 'visible', paddingRight: 0 }}>
                <span style={{ padding: 8, marginTop: -1, marginBottom: -1 }}>
                    <Checkbox checked={true} disabled />
                </span>
            </td>
        );
        return <TableSelection.Cell {...props} />;
    };

    const TableRow = ({ row, ...restProps }: any) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            style={{
                cursor: 'pointer',
                backgroundColor: row.groupId && lightGreen[50]
            }}
        />
    );

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                startIcon={<Publish />}
                onClick={() => {
                    assignBssdSensorLicense();
                }}
            >
                Battery Display Lizenz zuweisen
            </Button>
            <Grid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //            sorting={[{ columnName: MotionTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection}
                    onSelectionChange={(id: any) => {
                        setSelection([id[id.length - 1]])
                    }}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table rowComponent={TableRow} cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths.current} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection rowComponent={TableRow} cellComponent={SelectionCell} />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
                <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
