import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { GridStackCustomDataTable } from '../../authorized/portal/Dashboard/customDashboard/GridStackCustomDataTable';
import { GridStackCustom } from '../../../models/GridStackCustom';
import { useTranslation } from 'react-i18next';

export const GridStackSelectionDialog = ({ open, setOpen, onRefresh, gridStackCustoms }
    : { open: boolean, setOpen: any, onRefresh: any, gridStackCustoms: GridStackCustom[] }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [selection, setSelection] = useState<string[]>([]);

    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle handleClose={handleClose} title={"Wählen Sie Ihr Dashboard aus"} />
                <DialogContent className={classes.dialogContent} dividers>
                    <GridStackCustomDataTable
                        rows={gridStackCustoms}
                        onEdit={null}
                        onDelete={null}
                        selection={selection}
                        setSelection={(ids: string[])=>{
                            if (ids.length < 1){
                                setSelection([]);
                            }else{
                                setSelection([ids[ids.length - 1]]);

                            }
                        }}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        disabled={loading}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        endIcon={loading && <CircularProgress size={32} />}
                        variant={'contained'}
                        color="primary"
                        disabled={loading || selection.length != 1}
                        onClick={async () => {
                            setLoading(true);
                            await onRefresh(gridStackCustoms.find(x=>selection.includes(x.id)));
                            handleClose();
                            setLoading(false);
                        }}
                    >
                        Zuweisen!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));