import { Alert, AlertTitle, Avatar, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material";
import React, { Ref, useContext } from "react";
import { AUTO_CHART_HEIGHT, CHART_BAR_FONT_SIZE, CHART_BAR_SIZE, GLOBAL_API_DATA_AMOUNT_TYPE, GLOBAL_CHOSEN_PAST_DAYS_CHARTS, GLOBAL_DATA_FILTER_TYPE, GLOBAL_DATE_CHARTS, GLOBAL_PERIOD_CHART_FILTER, GLOBAL_START_DATE_CHARTS, MAX_CHART_ITEM_COUNT } from "../../constants";
import { defaultAPIDataAmountType, defaultDataFilterType, defaultEndDate, defaultPastDays, defaultPeriodChartFilterType, defaultStartDate, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage, setDefaultDataFilterType, setDefaultEndDate, setDefaultPastDays, setDefaultStartDate } from "../../helpers/date-management";
import { InlineDateTimePicker } from "./InlineDateTimePicker";
import ClockWhiteSvg from '../../assets/svgs/clock_white.svg'
import { green, grey, lightgrey, white } from "../../styles/colors";
import { APIDataAmountType, DataFilterType, PeriodChartFilterType } from "../../helpers/charts/base";
import DataFilterTypeChoiceDialog from "../dialogs/DataFilterTypeChoiceDialog";
import { useReactToPrint } from "react-to-print";
import { EvaluationChoice } from "../../models/EvaluationChoice";
import { api } from "../../api";
import { getApiToken } from "../../helpers/storage-management";
import { useHistory } from "react-router-dom";
import { GroupSettings, LocationInformation } from "../../models/GroupSettings";
import { useStyles } from "../../styles/container";
import { getChosenGroups } from "../../helpers/tables/common";
import { getQuery, useQuery } from "../authorized/portal/checkout/ShopPage";
import { red } from "@mui/material/colors";
import { ArrowRight, BrowserUpdated, ControlPointDuplicate, FlashOn, Height, History, PictureAsPdf, Print, PrintDisabled, PrintOutlined, RemoveCircle, StopCircle, Watch } from "@mui/icons-material";
import { addDays, addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { GlobalContext } from "../authorized/PortalPage";
import { GridStackCustom, GridStackCustomItem } from "../../models/GridStackCustom";
import { CustomDashboardContext } from "../authorized/portal/Dashboard/CustomDashboard";
import { useTranslation } from "react-i18next";

export const GlobalTimeChoice = ({ updateEndDateFromParent, updateStartDateFromParent, updateDataFilterTypeFromParent, updateChosenPastDaysFromParent, chartRefs, pageRef, isPrintPage, setIsPrintPage, isPrintingPage, setIsPrintingPage }
    : { updateEndDateFromParent: any, updateStartDateFromParent: any, updateDataFilterTypeFromParent: any, updateChosenPastDaysFromParent: any, chartRefs?: any, pageRef?: any, isPrintPage?: any, setIsPrintPage?: any, isPrintingPage?: any, setIsPrintingPage?: any }) => {


    const { doPrintOnceRef,maxChartItemCount, setMaxChartItemCount, autoHeightChart, setAutoHeightChart, barSize, setBarSize, chartBarFontSize, setChartBarFontSize }:
        { doPrintOnceRef: React.MutableRefObject<any>,maxChartItemCount: number, setMaxChartItemCount: any, autoHeightChart: boolean, setAutoHeightChart: any, periodChartFilter: PeriodChartFilterType, setPeriodChartFilter: any, apiDataAmountType: APIDataAmountType, setApiDataAmountType: any, reloadDataFromApi: number, setReloadDataFromApi: any, apiDataLoading: boolean, setApiDataLoading: any, barSize: number, setBarSize: any, chartBarFontSize: number, setChartBarFontSize: any } = React.useContext(GlobalContext);


    const [secondsUntilPrint, setSecondsUntilPrint] = React.useState(60);



    const [isAutoPrintEnabled, setIsAutoPrintEnabled] = React.useState(true);
    const isAutoPrintEnabledRef = React.useRef(isAutoPrintEnabled);
    const {t} = useTranslation();

    const [loadingPrint, setLoadingPrint] = React.useState(false);

    //http://localhost:3000/portal/users/services/qualit%C3%A4tssicherung?startDate=2022-05-15T00:05:32.000Z&endDate=2022-10-15T00:05:32.000Z&print=true

    const autoPrint = React.useCallback(() => {
        if (!isAutoPrintEnabledRef.current) return;
        setTimeout(() => {
            setSecondsUntilPrint((curVal) => {
                if (curVal < 1) {
                    doPrintingProcess();
                    return curVal;
                } else {
                    autoPrint();
                    return curVal - 1;
                }
            });

        }, 1000)
    }, [isAutoPrintEnabled]);

    React.useEffect(() => {
        if (isPrintingPage && isPrintingPage.print) {
            if (isPrintingPage.resolve) {
                isPrintingPage.resolve();
                setIsPrintingPage({ print: true, resolve: undefined });
            }
        }
    }, [isPrintingPage]);

    React.useEffect(() => {
        if (doPrintOnceRef.current) {
            doPrintOnceRef.current = false;
            doPrintingProcess();
        }
    }, [])

    const classes = useStyles();

    const handlePrint = useReactToPrint({

        content: () => pageRef.current,

        pageStyle: `@page {
            size: A4;
            margin: 10mm;
          }`,

        onAfterPrint: () => {
            if (setIsPrintingPage) {
                setIsPrintingPage({ print: false, resolve: undefined });
            }
            //  setIsPrintPage(false);
        }

    });

    const doPrintingProcess = async () => {
        setLoadingPrint(true);
        if (isPrintPage) {

            if (handlePrint) {
                handlePrint();
            }
        } else {
            setIsPrintPage(true);
        }
        setLoadingPrint(false);
    }

    return (
        <Paper className="Paper" style={{ backgroundColor: grey }}>
            <Grid container item xs={12} direction={'row'} justifyContent="center" alignItems="center" spacing={3}>
                <Grid container item xs={12} direction={'row'} justifyContent="center" alignItems="center" spacing={3}>
                    {isPrintPage != undefined &&
                        <Grid item>
                            <Button disabled={loadingPrint} variant={"contained"} color={"primary"} size={"large"} endIcon={<PictureAsPdf />} onClick={async () => {
                                await doPrintingProcess();
                            }
                            }>
                                {isPrintPage ? "Zu PDF Druckansicht" : "Daten Exportieren und Vorschau anzeigen"}
                            </Button>
                        </Grid>
                    }
                    {isPrintPage &&
                        <Grid item>
                            <Button disabled={loadingPrint} variant={"contained"} color={"error"} size={"large"} endIcon={<PrintDisabled />} onClick={async () => {
                                setIsPrintPage(false);
                            }
                            }>
                                Drucken {t("myUnitsSettings.cancel")}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    <Avatar className={classes.large} style={{ backgroundColor: green }}>
                        <img src={ClockWhiteSvg} width={40} height={40} />
                    </Avatar>
                </Grid>
                <Grid item>
                    <Typography variant="h4" component="h4">Standard Berichtszeitraum</Typography>
                </Grid>
                <BuildDateFields updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} />
                <Grid item container direction={"row"} spacing={3} justifyContent="center" alignItems={"center"}>
                    <Grid item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Balken Anzahl (Quer)"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                            }}
                            value={maxChartItemCount}
                            onChange={(event: any) => {
                                setMaxChartItemCount(event.target.value);
                                localStorage.setItem(MAX_CHART_ITEM_COUNT, JSON.stringify(event.target.value))
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={autoHeightChart}
                                    onChange={(event: any) => {
                                        localStorage.setItem(AUTO_CHART_HEIGHT, JSON.stringify(event.target.checked))
                                        setAutoHeightChart(event.target.checked)
                                    }}
                                    name="isEnabled"
                                    color="primary"
                                />
                            }
                            label="Balken Diagramm Auto Höhe aktiviert"
                        />
                    </Grid>
                    {autoHeightChart &&
                        <Grid item>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Balken Größe"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Größe</InputAdornment>
                                }}
                                value={barSize}
                                onChange={(event: any) => {
                                    setBarSize(event.target.value);
                                    localStorage.setItem(CHART_BAR_SIZE, JSON.stringify(event.target.value))
                                }}
                            />
                        </Grid>
                    }
                    <Grid item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Balken Schriftgröße"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Größe</InputAdornment>
                            }}
                            value={chartBarFontSize}
                            onChange={(event: any) => {
                                setChartBarFontSize(event.target.value);
                                localStorage.setItem(CHART_BAR_FONT_SIZE, JSON.stringify(event.target.value))
                            }}
                        />
                    </Grid>
                </Grid>
                {/*doPrint &&
                    <Grid item xs={12} container direction={"row"} justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography style={{ textAlign: 'center' }} variant="h5">{secondsUntilPrint} Sekunden bis zur Druckvorschau</Typography>
                        </Grid>
                        <Grid item>
                            {isAutoPrintEnabled ? <Button
                                variant="contained"
                                style={{ backgroundColor: red[900], color: white }}
                                onClick={() => {
                                    setIsAutoPrintEnabled(false);
                                    isAutoPrintEnabledRef.current = false;
                                }}
                                endIcon={<StopCircle />}
                            >
                                Countdown {t("myUnitsSettings.cancel")}
                            </Button> :
                                <Button
                                    variant="contained"

                                    onClick={() => {
                                        setIsAutoPrintEnabled(true);
                                        isAutoPrintEnabledRef.current = true;
                                        autoPrint();
                                    }}
                                    endIcon={<ArrowRight />}
                                >
                                    Countdown Fortsetzen
                                </Button>}

                        </Grid>
                    </Grid>
                                */}

            </Grid>

        </Paper>
    );
}

export const getChartInstance = (chart: any) => {
    if (chart.current && chart.current.instance) {
        return chart.current.instance;
    }
    return null;
}


export const splitArray = (charts: any) => {
    let localCharts = [...charts];
    let ret = [];
    while (localCharts.length > 0) {
        let elements = localCharts.splice(0, 1);
        elements = elements.filter((el) => el.current && el.current.instance);
        ret.push(elements.map((el: any) => el.current.instance));
    }

    return ret;
}

export const showGlobalDateData = (updateEndDateFromParent: any, updateStartDateFromParent: any, updateChosenPastDaysFromParent: any) => {
    let dataFilterType = localStorage.getItem(GLOBAL_DATA_FILTER_TYPE) || "" + defaultDataFilterType;
    if (DataFilterType.StartEnd == parseInt(dataFilterType)) {
        updateEndDateFromParent(getGlobalEndDate());
        updateStartDateFromParent(getGlobalStartDate());
    } else {
        let pastDays = localStorage.getItem(GLOBAL_CHOSEN_PAST_DAYS_CHARTS) || "10";
        updateChosenPastDaysFromParent({ target: { value: pastDays } });
    }


}

export const getGlobalStartDate = () => {
    const queryString = getQuery();
    let startDate = getStartDateFromUrlOrStorage(queryString);
    startDate = startDate || defaultStartDate;
    return new Date(startDate);
}

export const getGlobalEndDate = () => {
    const queryString = getQuery();
    let endDate = getEndDateFromUrlOrStorage(queryString);
    endDate = endDate || defaultEndDate;
    return new Date(endDate);
}


export const BuildDateFields = ({ updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent, updateDataFilterTypeFromParent, showLoadingButton = true }
    : { updateEndDateFromParent: any, updateStartDateFromParent: any, updateChosenPastDaysFromParent: any, updateDataFilterTypeFromParent: any, showLoadingButton?: boolean }) => {
    const history = useHistory();
    const queryString = useQuery(history);
    const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);


    const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
    const { isPreFilterDialogVisible, selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate, maxChartItemCount, setMaxChartItemCount, autoHeightChart, setAutoHeightChart, periodChartFilter, setPeriodChartFilter, apiDataAmountType, setApiDataAmountType, reloadDataFromApi, setReloadDataFromApi, apiDataLoading, setApiDataLoading, barSize, setBarSize, chartBarFontSize, setChartBarFontSize }:
        { isPreFilterDialogVisible: boolean, selectedStartDate: any, selectedEndDate: any, setSelectedStartDate: any, setSelectedEndDate: any, maxChartItemCount: number, setMaxChartItemCount: any, autoHeightChart: boolean, setAutoHeightChart: any, periodChartFilter: PeriodChartFilterType, setPeriodChartFilter: any, apiDataAmountType: APIDataAmountType, setApiDataAmountType: any, reloadDataFromApi: number, setReloadDataFromApi: any, apiDataLoading: boolean, setApiDataLoading: any, barSize: number, setBarSize: any, chartBarFontSize: number, setChartBarFontSize: any } = React.useContext(GlobalContext);



    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        setSelectedEndDate(endDate);
        setSelectedStartDate(startDate);
        let pastDays = localStorage.getItem(GLOBAL_CHOSEN_PAST_DAYS_CHARTS) || "" + defaultPastDays;
        setChosenPastDays(parseInt(pastDays));

        let dataFilterType = localStorage.getItem(GLOBAL_DATA_FILTER_TYPE) || "" + defaultDataFilterType;
        setDataFilterType(parseInt(dataFilterType));

        let periodChartFilter = localStorage.getItem(GLOBAL_PERIOD_CHART_FILTER) || "" + defaultPeriodChartFilterType;
        setPeriodChartFilter(periodChartFilter);



        let apiDataAmountType = localStorage.getItem(GLOBAL_API_DATA_AMOUNT_TYPE) || "" + defaultAPIDataAmountType;
        setApiDataAmountType(apiDataAmountType);
    }, [isPreFilterDialogVisible])

    React.useEffect(() => {
        localStorage.setItem(GLOBAL_DATA_FILTER_TYPE, dataFilterType.toString());
        setDefaultDataFilterType(dataFilterType);
        if (updateDataFilterTypeFromParent) updateDataFilterTypeFromParent(dataFilterType);
    }, [dataFilterType])



    const buildEndDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={(event: any) => {
                    changeEndDate(event);
                }}></InlineDateTimePicker>
            </Grid>
        )
    }
    const buildStartDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker title={"Start Datum"} selectedDate={selectedStartDate} setSelectedDate={(event: any) => {
                    changeStartDate(event);
                }}></InlineDateTimePicker>
            </Grid>
        )
    }

    const changeEndDate = (event: Date) => {
        setSelectedEndDate(event);
        localStorage.setItem(GLOBAL_DATE_CHARTS, event.toISOString());
        setDefaultEndDate(event);
        if (updateEndDateFromParent) updateEndDateFromParent(event);
    }

    const changeStartDate = (event: Date) => {
        setSelectedStartDate(event);
        localStorage.setItem(GLOBAL_START_DATE_CHARTS, event.toISOString());
        setDefaultStartDate(event);
        if (updateStartDateFromParent) updateStartDateFromParent(event);
    }
    let fieldSize = 2;

    switch (dataFilterType) {
        case DataFilterType.StartEnd:
            return (
                <Grid container item xs={12} spacing={2} justifyContent={"center"} alignItems={"center"} alignContent="center">
                    <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType} />
                    {apiDataAmountType == APIDataAmountType.All && buildStartDateField()}
                    {apiDataAmountType == APIDataAmountType.All && buildEndDateField()}
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Globale Zeitauswahl</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={periodChartFilter}
                                onChange={(event) => {
                                    setPeriodChartFilter(event.target.value);
                                    localStorage.setItem(GLOBAL_PERIOD_CHART_FILTER, event.target.value)
                                }} row>
                                <FormControlLabel value={PeriodChartFilterType.Hour} control={<Radio />} label="Stündlich" />
                                <FormControlLabel value={PeriodChartFilterType.Day} control={<Radio />} label="Täglich" />
                                <FormControlLabel value={PeriodChartFilterType.Week} control={<Radio />} label="Wöchentlich" />
                                <FormControlLabel value={PeriodChartFilterType.Month} control={<Radio />} label="Monatlich" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} justifyContent="center" container direction={"row"} spacing={3}>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<History />}
                                onClick={() => {
                                    let endDate = new Date();
                                    let startDate = addDays(endDate, -1);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                letzte 24 Stunden
                            </Button>
                        </Grid>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<History />}
                                onClick={() => {
                                    let endDate = new Date();
                                    let startDate = addDays(endDate, -7);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                letzte 7 Tage
                            </Button>
                        </Grid>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<History />}
                                onClick={() => {
                                    let endDate = new Date();
                                    let startDate = addDays(endDate, -30);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                letzte 30 Tage
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} justifyContent="center" container direction={"row"} spacing={3}>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<FlashOn />}
                                onClick={() => {
                                    let baseDate = new Date();
                                    let endDate = endOfDay(baseDate);
                                    let startDate = startOfDay(baseDate);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                Aktueller Tag
                            </Button>
                        </Grid>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<FlashOn />}
                                onClick={() => {
                                    let baseDate = new Date();
                                    let endDate = endOfWeek(baseDate, { weekStartsOn: 1 });
                                    let startDate = startOfWeek(baseDate, { weekStartsOn: 1 });
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                Aktuelle Woche
                            </Button>
                        </Grid>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<FlashOn />}
                                onClick={() => {
                                    let baseDate = new Date();
                                    let endDate = endOfMonth(baseDate);
                                    let startDate = startOfMonth(baseDate);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                Aktuelles Monat
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} justifyContent="center" container direction={"row"} spacing={3}>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<History />}
                                onClick={() => {
                                    let baseDate = addDays(new Date(), -1);
                                    let endDate = endOfDay(baseDate);
                                    let startDate = startOfDay(baseDate);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                Letzter Tag
                            </Button>
                        </Grid>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<History />}
                                onClick={() => {
                                    let baseDate = addWeeks(new Date(), -1);
                                    let endDate = endOfWeek(baseDate, { weekStartsOn: 1 });
                                    let startDate = startOfWeek(baseDate, { weekStartsOn: 1 });
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                Letzte Woche
                            </Button>
                        </Grid>
                        <Grid item lg={fieldSize}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<History />}
                                onClick={() => {
                                    let baseDate = addMonths(new Date(), -1);
                                    let endDate = endOfMonth(baseDate);
                                    let startDate = startOfMonth(baseDate);
                                    changeEndDate(endDate);
                                    changeStartDate(startDate);
                                }}
                            >
                                Letztes Monat
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Datenmenge</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={apiDataAmountType}
                                onChange={(event) => {
                                    setApiDataAmountType(event.target.value);
                                    localStorage.setItem(GLOBAL_API_DATA_AMOUNT_TYPE, event.target.value)
                                }} row>
                                <FormControlLabel value={APIDataAmountType.All} control={<Radio />} label="Alle Daten laden" />
                                <FormControlLabel value={APIDataAmountType.DateRange} control={<Radio />} label="Daten aus Zeitraum laden" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    {apiDataAmountType == APIDataAmountType.DateRange && buildStartDateField()}
                    {apiDataAmountType == APIDataAmountType.DateRange && buildEndDateField()}
                    {showLoadingButton &&
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={apiDataLoading && <CircularProgress size={32} />}
                                disabled={apiDataLoading}
                                size="large"
                                endIcon={<BrowserUpdated />}
                                onClick={() => {
                                    setReloadDataFromApi((prev: number) => prev + 1);
                                }}
                            >
                                {apiDataAmountType == APIDataAmountType.All ? "Daten Neu laden" : "Lade Daten"}
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}></Grid>
                    {apiDataAmountType == APIDataAmountType.DateRange &&
                        <Grid item>
                            <Alert severity="info">
                                <AlertTitle>Hinweis Datenmenge</AlertTitle>
                                Es werden nur Daten geladen, die im Zeitraum angegeben. Um die Daten zu laden klicken Sie auf "Lade Daten"
                            </Alert>
                        </Grid>
                    }

                </Grid>
            );
        default:
            return (
                <Grid container item xs={12} spacing={2} justifyContent={"center"} alignItems={"center"}>
                    <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType} />
                    {buildEndDateField()}
                    <Grid item>
                        <TextField
                            style={{ width: 100 }}
                            inputProps={{ min: 1, style: {} }}
                            id="filled-number"
                            label="Tage zurück"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            value={chosenPastDays}
                            onChange={(event: any) => {
                                setChosenPastDays(parseInt(event.target.value));
                                localStorage.setItem(GLOBAL_CHOSEN_PAST_DAYS_CHARTS, event.target.value);
                                setDefaultPastDays(event.target.value);
                                updateChosenPastDaysFromParent(event);
                            }}
                        />
                    </Grid>
                </Grid>
            );
    }
}


export const ChartHeightTextBox = ({ chartHeight, setChartHeight, display = true, actualCount }: { chartHeight: number, setChartHeight: any, display?: boolean, actualCount?: number }) => {
    const { autoHeightChart, maxChartItemCount, barSize }: { autoHeightChart: boolean, maxChartItemCount: number, barSize: number } = useContext(GlobalContext);

    React.useEffect(() => {
        if (autoHeightChart) {
            setChartHeight((actualCount && actualCount < maxChartItemCount) ? actualCount * barSize : maxChartItemCount * barSize);
        }
    }, [autoHeightChart, maxChartItemCount, actualCount, barSize]);

    const { item, updateGridStack, selectedGridStackCustom }: { item: GridStackCustomItem, updateGridStack: (currentGridStackCustom: GridStackCustomItem) => void, selectedGridStackCustom: GridStackCustom } = React.useContext(CustomDashboardContext);
    React.useEffect(() => {
        if (item) {
            if (item.isStandardChartHeightEnabled) {
                setChartHeight(chartHeight);
            }
            else if (item.isAutoChartHeightEnabled) {
                setChartHeight(item.height * selectedGridStackCustom.cellHeight - 330)
            } else {
                setChartHeight(item.chartHeight)
            }
        }
    }, [selectedGridStackCustom]);

    return (
        <>
            <TextField
                style={{ display: display ? 'block' : 'none' }}
                fullWidth
                required
                variant="outlined"
                label="Diagramm Balken Höhe"
                type="number"
                InputProps={{
                    endAdornment: <InputAdornment position="end"><Height /></InputAdornment>
                }}
                value={chartHeight}
                onChange={(event: any) => {
                    let val = parseInt(event.target.value);
                    if (item) {
                        item.chartHeight = val;
                        updateGridStack(item);
                    }
                    setChartHeight(val);
                }}
            />
        </>
    )
}



export const PrintButton = ({ pageRef, title = "Drucken" }: { pageRef: any, title?: string }) => {

    const handlePrint = useReactToPrint({

        content: () => pageRef.current,

        pageStyle: `
        @page {
            size: A4;
            margin: 10mm;
          }`,
        onAfterPrint: () => {

        }

    });

    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<Print />}
            onClick={() => {
                handlePrint();
            }}
        >
            {title}
        </Button>
    )
}