import React, { useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { DrawerMenuItemDisplayType } from '../../ObjectMenuPointDrawer';
import { GlobalContext } from '../../PortalPage';
import { OBJECT_COMBINATION } from '../../../../constants';
import { ObjectCleaningTrigger } from '../../../../models/ObjectCleaningTrigger';
import { ObjectPresenceTriggerTable } from './table/ObjectPresenceTriggerTable';
import { ObjectCleaningTriggerForExcel } from '../../../../models/ObjectCleaningTriggerForExcel';
import { ObjectPresenceTriggerTableForExcel } from './table/ObjectPresenceTriggerTableForExcel';
import { ExpandMore } from '@mui/icons-material';
import { APIDataAmountType } from '../../../../helpers/date-management';
import useNoInitialEffect from '../../../../helpers/extensions';

export const ObjectCleaningTriggerDashboard = ({ displayType = DrawerMenuItemDisplayType.Single }: { displayType?: DrawerMenuItemDisplayType }) => {


    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [objectCleaningTriggers, setObjectCleaningTriggers] = React.useState<ObjectCleaningTrigger[]>([]);
    const [objectCleaningTriggersForExcel, setObjectCleaningTriggersForExcel] = React.useState<ObjectCleaningTriggerForExcel[]>([]);
    const { selectedCombinationObject }: any = useContext(GlobalContext);


    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const [selectedObjectUnit, setSelectedObjectUnit] = React.useState<ObjectUnit>();
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);
    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);


    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);

    }, [history.location.pathname]);

    useNoInitialEffect(() => {
        setApiDataLoading(true);
        setLoading(true);
        (async () => {

            await loadDataAsync();
            setLoading(false);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);


    const loadDataAsync = React.useCallback(async () => {
        setLoading(true);
        const token = getApiToken(history);
        if (!token) return;
        let objectPresenceTriggers;
        if (displayType == DrawerMenuItemDisplayType.All) {
            objectPresenceTriggers = await api.getObjectCleaningTriggerByObjectIds(token, []);
            setObjectCleaningTriggers(objectPresenceTriggers);
        } else if (displayType == DrawerMenuItemDisplayType.Multiple) {
            const tempCombObject = JSON.parse(localStorage.getItem(OBJECT_COMBINATION) || "[]").map((x:any)=>x.id);
            objectPresenceTriggers = await api.getObjectCleaningTriggerByObjectIds(token, tempCombObject);
            setObjectCleaningTriggers(objectPresenceTriggers.filter(x => tempCombObject.some((y: string) => y == x.objectId)));
        }
        else {
            objectPresenceTriggers = await api.getObjectCleaningTriggerByObjectIds(token, [selectedObjectId]);
            setObjectCleaningTriggers(objectPresenceTriggers);
        }
        let excelData = objectPresenceTriggers.reduce((prevVal, curVal) => {
            if (curVal.minimums.durationOfObjectSessions.length > 0) {
                curVal.minimums.durationOfObjectSessions.forEach(session => {
                    prevVal.push(ObjectCleaningTriggerForExcel.CreateInstance(curVal, session))
                });
            } else {
                prevVal.push(ObjectCleaningTriggerForExcel.CreateInstance(curVal, undefined))
            }

            return prevVal;
        }, [] as ObjectCleaningTriggerForExcel[]);
        setObjectCleaningTriggersForExcel(excelData);

        const tempServiceWorkers = await api.getServiceWorkers(token);
        setUserServiceWorkers(tempServiceWorkers);

        const tempObject = await api.getUserObjectUnit(selectedObjectId, token);
        setSelectedObjectUnit(tempObject);

        if (displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) {
            var tempObjects = await api.getUserObjectUnits(token);
            setObjects(tempObjects);
        }else{
            setObjects([tempObject]);
        }

        setLoading(false);
    }, [])


    return (
        <Paper>
            {loading && <CircularProgress size={128} />}
            {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item>
                                <Typography variant="h4">Anwesenheitsauslösung Zusammengefasst: {objectCleaningTriggers.length}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ObjectPresenceTriggerTable
                            selectedObjectUnit={selectedObjectUnit}
                            objectCleaningTriggers={objectCleaningTriggers}
                            userServiceWorkers={userServiceWorkers}
                            objects={objects}
                        />
                    </AccordionDetails>
                </Accordion>


            }
            {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item>
                                <Typography variant="h4">Anwesenheitsauslösung Aufgebrochen: {objectCleaningTriggersForExcel.length}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ObjectPresenceTriggerTableForExcel
                            selectedObjectUnit={selectedObjectUnit}
                            objectCleaningTriggers={objectCleaningTriggersForExcel}
                            userServiceWorkers={userServiceWorkers}
                            objects={objects}
                        />
                    </AccordionDetails>
                </Accordion>
            }
        </Paper>
    );
}