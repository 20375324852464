import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { FormattedNumber } from 'react-intl';
import { Typography } from '@mui/material';
import { UserProperty } from '../../models/UserProperty';
import { CurrencyTypeProvider } from '../core/devExpressGrid/CurrencyTypeProvider';
import { AdminSetting } from '../../models/AdminSetting';
import { GlobalContext } from '../authorized/PortalPage';

export const PropertiesPerformanceTable = ({ rows, properties }: { rows: any[], properties: UserProperty[] }) => {
  const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);

  const [columns] = useState([
    { name: 'propertyId', title: 'Merkmal Details' },
    { name: 'notificationName', title: 'Einheit Name' },
    { name: 'areaSize', title: 'Einheit Fläche' },
    {
      name: 'areaPerformancePerSquareMeter',
      title: 'Geplante Servicezeit',
      getCellValue: (row: any) => row.areaSize / row.areaPerformancePerSquareMeter,
    },
    {
      name: 'co2Consumption',
      title: 'kg Co2 pro Service',
      getCellValue: (row: any) => row.areaSize * (row.co2Value / 100),
    },
  ]);

  const [totalSummaryItems] = useState([
    { columnName: 'propertyId', type: 'count' },
    { columnName: 'areaSize', type: 'sum' },
    { columnName: 'areaPerformancePerSquareMeter', type: 'sum' },
    { columnName: 'co2Consumption', type: 'sum' },

  ]);

  const [groupSummaryItems] = useState([
    { columnName: 'propertyId', type: 'count' },
    { columnName: 'areaSize', type: 'sum' },
    { columnName: 'areaPerformancePerSquareMeter', type: 'sum' },
    {
      columnName: 'areaPerformancePerSquareMeter', type: 'max', showInGroupFooter: false, alignByColumn: true,
    },
    { columnName: 'co2Consumption', type: 'sum' },
    {
      columnName: 'co2Consumption', type: 'max', showInGroupFooter: false, alignByColumn: true,
    },
    {
      columnName: 'areaSize', type: 'max', showInGroupFooter: false, alignByColumn: true,
    },
  ]);
  const [currencyColumns] = useState(['co2Consumption','areaPerformancePerSquareMeter', 'areaSize']);

  const Cell = (props: any) => {
    const { column, row, value } = props;
    if (column.name === "propertyId") {
      return (
        <Table.Cell {...props}>
          <Typography>{row.propertyName}</Typography>
        </Table.Cell>
      )
    }
    if (column.name == "areaSize") {
      return (
        <Table.Cell {...props}>
          <Typography><FormattedNumber value={value} />m²</Typography>
        </Table.Cell>
      )
    }
    if (column.name == "areaPerformancePerSquareMeter") {
      return (
        <Table.Cell {...props}>
          <FormattedNumber value={value} />h
        </Table.Cell>
      )
    }
    if (column.name == "co2Consumption") {
      return (
        <Table.Cell {...props}>
          <FormattedNumber value={value} />kg/m²
        </Table.Cell>
      )
    }
    return <Table.Cell {...props} />;
  };

  const CellGroupRow = (props: any) => {
    const { column, row, value } = props;
    const property = properties.find(prop => prop.id == row.value) || new UserProperty();
    let currentUsageCategory = adminSetting.adminUsageCategories.find(x=>x.id == property.usageCategoryId);

    return (
      <TableGroupRow.Content {...props} >
        Nutzungart: {currentUsageCategory?.name} | Flächenleistung: <FormattedNumber value={property?.areaPerformancePerSquareMeter} />m²/h | CO2 Verbrauch: <FormattedNumber value={currentUsageCategory?.carbonDioxideConsumption || 0} />kg/m²
      </TableGroupRow.Content>
    );
  };

  const CurrencyFormatter = (props: any) => {
    if (props.column.name == "areaPerformancePerSquareMeter"){
      return props.value.toLocaleString('de') + "h"
    }else if (props.column.name == "co2Consumption"){
      return props.value.toLocaleString('de') + "kg/m²"
    }
    return props.value.toLocaleString('de') + "m²"
  }

  const CurrencyTypeProvider = (props: any) => {
    return (
      <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
      />
    )
  };


  return (
    <Paper>
      <Grid
        rows={rows}
        columns={columns}
      >
        <CurrencyTypeProvider
          for={currencyColumns}
        />
        <GroupingState
          grouping={[{ columnName: 'propertyId' }]}
        />
        <SummaryState
          totalItems={totalSummaryItems}
          groupItems={groupSummaryItems}
        />
        <IntegratedGrouping />
        <IntegratedSummary />
        <Table cellComponent={Cell} />
        <TableHeaderRow />
        <TableGroupRow showColumnsWhenGrouped contentComponent={CellGroupRow} />
        <TableSummaryRow />
      </Grid>
    </Paper>
  );
};
