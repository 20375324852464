import { Typography, FormControl, FormLabel, FormControlLabel, Radio, Grid, TextField, Paper, RadioGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import React, { useImperativeHandle, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MaxChartItemCountInput } from "../../../../../helpers/compontents";
import { formatDateRange, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../../helpers/date-management";
import { GroupFeedbackQuestion } from "../../../../../models/GroupFeedbackQuestion";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { StandardBarChartSimpleColor } from "../../../../charts/StandardBarChartSimpleColor";
import { ChartHeightTextBox, PrintButton } from "../../../../core/GlobalTimeChoice";
import { GlobalContext } from "../../../PortalPage";
import { useQuery } from "../../checkout/ShopPage";
import { FeedbackQuestionTextType } from "../FeedbackDashboard";
import { PrintContext } from "../HitListDashboard";
import { GroupFeedback } from "../../../../../models/GroupFeedback";
import { GroupMotion } from "../../../../../models/GroupMotion";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";
import { DetailType } from "../customerUsage/GetGroupFeedbacksQualityMotionComb";
import { FEEDBACK_DISPLAY_TYPE_CHART } from "../../../../../constants";

export const FeedbackQualityVerticalChart = React.forwardRef(({ feedbackTableData, motionTableData, groupsLicenseTableEntries }: { feedbackTableData: GroupFeedback[], motionTableData: GroupMotion[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateFeedbackQualityVerticalChartData(date: Date) {
            updateStartDateFeedbackQualityVerticalChartData(date);
        },
        updateEndDateFeedbackQualityVerticalChartData(date: Date) {
            updateEndDateFeedbackQualityVerticalChartData(date);
        }

    }));

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);
    const history = useHistory();
    const queryString = useQuery(history);


    const [startDateFeedbackQuestionWithGroupsSimple, setStartDateFeedbackQuestionWithGroupsSimple] = React.useState(subDays(new Date(), 10));
    const [endDateFeedbackQuestionWithGroupsSimple, setEndDateFeedbackQuestionWithGroupsSimple] = React.useState(new Date());
    const title = isPrintPage ? `<b>Gesamt Feedbackqualität der Einheiten Bericht</b><br />${formatDateRange(startDateFeedbackQuestionWithGroupsSimple, endDateFeedbackQuestionWithGroupsSimple)}` : "";

    const [chartHeightFeedbackQuestionWithGroupsSimple, setChartHeightFeedbackQuestionWithGroupsSimple] = React.useState(300);
    const [feedbackQuestionWithGroupsSimpleChartData, setFeedbackQuestionWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackDetailsType, setFeedbackDetailsType] = React.useState(DetailType.Standard);

    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);


    React.useEffect(() => {
        updateEndDateFeedbackQualityVerticalChartData(endDateFeedbackQuestionWithGroupsSimple);
    }, [maxChartItemCount]);

    React.useEffect(() => {
        let feedbackDisplayTypeChart = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (feedbackTableData) {
            setStartDateFeedbackQuestionWithGroupsSimple(startDate);
            setEndDateFeedbackQuestionWithGroupsSimple(endDate);
            updateFeedbackQualityVerticalChartData(startDate, endDate, feedbackTableData, motionTableData, feedbackDisplayTypeChart,feedbackDetailsType);
        }
    }, [feedbackTableData, groupsLicenseTableEntries])


    const updateStartDateFeedbackQualityVerticalChartData = (newVal: Date) => {
        if (!feedbackTableData) return;
        setStartDateFeedbackQuestionWithGroupsSimple(newVal);
        let feedbackDisplayTypeChart = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());
        updateFeedbackQualityVerticalChartData(newVal, endDateFeedbackQuestionWithGroupsSimple, feedbackTableData, motionTableData, feedbackDisplayTypeChart,feedbackDetailsType);
    }

    const updateEndDateFeedbackQualityVerticalChartData = (newVal: Date) => {
        if (!feedbackTableData) return;
        setEndDateFeedbackQuestionWithGroupsSimple(newVal);
        let feedbackDisplayTypeChart = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());
        updateFeedbackQualityVerticalChartData(startDateFeedbackQuestionWithGroupsSimple, newVal, feedbackTableData, motionTableData, feedbackDisplayTypeChart,feedbackDetailsType);
    }





    const updateFeedbackQualityVerticalChartData = (startDate: Date, endDate: Date, tableFeedbackData: GroupFeedback[], tableMotionData : GroupMotion[], feedbackDisplayTypeChart = FeedbackDisplayType.PositiveNegativeNeutral, feedbackDetailsType: DetailType) => {
        let feedbacks = tableFeedbackData.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);
        let res = feedbacks.reduce((sumValue, newValue: GroupFeedback) => {
            let newItems = newValue.groupId
            if (newItems) {
                let notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
                let entryExists = sumValue.find((x: any) => x.argumentField == notificationName);
                if (entryExists) {
                    entryExists.veryNegativeAmount += newValue.veryNegativeAmount;
                    entryExists.negativeAmount += newValue.negativeAmount;
                    entryExists.neutralAmount += newValue.neutralAmount;
                    entryExists.positiveAmount += newValue.positiveAmount;
                    entryExists.veryPositiveAmount += newValue.veryPositiveAmount;

                } else {
                    sumValue.push({ groupId: newValue.id,argumentField: notificationName, veryNegativeAmount: newValue.veryNegativeAmount, negativeAmount: newValue.negativeAmount, neutralAmount: newValue.neutralAmount, positiveAmount: newValue.positiveAmount, veryPositiveAmount: newValue.veryPositiveAmount });
                }
            }


            return sumValue;
        }, [] as any[]);


        let motionSums = tableMotionData.reduce((sumValue, newValue: GroupMotion) => {
            let newItems = newValue.groupId
            if (newItems) {
                let notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
                let entryExists = sumValue.find((x: any) => x.argumentField == notificationName);
                if (entryExists) {
                    entryExists.blockCount += newValue.blockCount;
                } else {
                    sumValue.push({ groupId: newValue.id,argumentField: notificationName, blockCount : newValue.blockCount });
                }
            }


            return sumValue;
        }, [] as any[]);

        res = res.map(x => {
            let allFeedbackCount = 0;
            let overallFeedbackWithVisitor = 0;
            let allFeedbackSum = 0;
            let allFeedbackWithoutVisitorSum = 80;
            if (feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative) {
                let veryNegativeAmount = x.veryNegativeAmount;
                while (veryNegativeAmount > 0) {
                    allFeedbackSum = allFeedbackSum + 25;
                    veryNegativeAmount = veryNegativeAmount - 1;
                    allFeedbackCount++;
                }
                let veryPositiveAmount = x.veryPositiveAmount;
                while (veryPositiveAmount > 0) {
                    allFeedbackSum = allFeedbackSum + 100;
                    veryPositiveAmount = veryPositiveAmount - 1;
                    allFeedbackCount++;
                }
            }
            let negativeAmount = x.negativeAmount;
            while (negativeAmount > 0) {
                allFeedbackSum = allFeedbackSum + 25;
                negativeAmount = negativeAmount - 1;
                allFeedbackCount++;
            }
            let neutralAmount = x.negativeAmount;
            while (neutralAmount > 0) {
                allFeedbackSum = allFeedbackSum + 75;
                neutralAmount = neutralAmount - 1;
                allFeedbackCount++;
            }
            let positiveAmount = x.positiveAmount;
            while (positiveAmount > 0) {
                allFeedbackSum = allFeedbackSum + 100;
                positiveAmount = positiveAmount - 1;
                allFeedbackCount++;
            }

            let motion = motionSums.find(motion=>motion.argumentField == x.argumentField);


            let noFeedbackCount = (motion?.blockCount || 0) - allFeedbackCount;
            overallFeedbackWithVisitor = allFeedbackCount;

            if (allFeedbackSum > 0) {
                allFeedbackWithoutVisitorSum = allFeedbackSum;
            }
            while (noFeedbackCount > 0) {
                allFeedbackSum = allFeedbackSum + 80;
                noFeedbackCount--;
                overallFeedbackWithVisitor++;
            }
            if (overallFeedbackWithVisitor > 0) {
                allFeedbackSum = Math.round(allFeedbackSum / overallFeedbackWithVisitor)
            }
            if (allFeedbackCount > 0) {
                allFeedbackWithoutVisitorSum = Math.round(allFeedbackWithoutVisitorSum / allFeedbackCount)
            }


            return {
                argumentField: x.argumentField,
                valueField : feedbackDetailsType == DetailType.Standard ? allFeedbackSum : allFeedbackWithoutVisitorSum,
                blockCount: x.blockCount,
                allFeedbackSum: allFeedbackSum,
                allFeedbackWithoutVisitor: allFeedbackWithoutVisitorSum,
                allFeedbackCount: allFeedbackCount,
            }
        });


        res = res.sort((a, b) => a.valueField - b.valueField).slice(-maxChartItemCount);
        setFeedbackQuestionWithGroupsSimpleChartData(res);
    }

    const buildContent = () => {
        return <>
            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Gesamt Feedbackqualität der Einheiten Bericht</Typography>
            <FormControl component="fieldset" >
                <FormLabel component="legend">Feedbackqualität Art</FormLabel>
                <RadioGroup
                    row
                    name="feedbackQuestionWithGroupsType"
                    value={feedbackDetailsType}
                    onChange={(event) => {
                        setFeedbackDetailsType(event.target.value as any);
                        let feedbackDisplayTypeChart = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());
                        updateFeedbackQualityVerticalChartData(startDateFeedbackQuestionWithGroupsSimple, endDateFeedbackQuestionWithGroupsSimple, feedbackTableData, motionTableData, feedbackDisplayTypeChart,event.target.value as any);
                    }}
                >
                    <FormControlLabel value={DetailType.Standard} control={<Radio />} label="Standard" />
                    <FormControlLabel value={DetailType.Details} control={<Radio />} label="Tatsächliche Feedbackqualität" />
                </RadioGroup>
            </FormControl>
            <Grid container direction="row" spacing={3}>
                <Grid item>
                    < DatePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={"Start Datum"}
                        value={startDateFeedbackQuestionWithGroupsSimple}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            updateStartDateFeedbackQualityVerticalChartData(newVal);
                        }}
                        format={"dd.MM.yyyy"}
                        
                    />
                </Grid>
                <Grid item>
                    < DatePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={"Ende Datum"}
                        value={endDateFeedbackQuestionWithGroupsSimple}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            updateEndDateFeedbackQualityVerticalChartData(newVal);

                        }}
                        format={"dd.MM.yyyy"}
                        
                    />
                </Grid>
                <Grid item>
                    <ChartHeightTextBox chartHeight={chartHeightFeedbackQuestionWithGroupsSimple} setChartHeight={setChartHeightFeedbackQuestionWithGroupsSimple} actualCount={feedbackQuestionWithGroupsSimpleChartData.length} />
                </Grid>
                <MaxChartItemCountInput />
            </Grid>
        </>;
    }

    const buildPrintContent = () => {
        return (
            <>
                <PrintButton title="Feedbackqualität Drucken" pageRef={refPrint} />
                <ChartHeightTextBox chartHeight={chartHeightFeedbackQuestionWithGroupsSimple} setChartHeight={setChartHeightFeedbackQuestionWithGroupsSimple} display={false} actualCount={feedbackQuestionWithGroupsSimpleChartData.length} />
            </>
        )
    }

    if (!feedbackTableData) return <></>

    return (
        <Paper elevation={elevationPaper} style={paperStyle}>
            {isPrintPage ? buildPrintContent() : buildContent()}
            <Paper ref={refPrint}>
                <StandardBarChartSimpleColor valueFieldService={"valueField"} argumentFieldService={"argumentField"} dataSource={feedbackQuestionWithGroupsSimpleChartData} name={"Feedbackqualität Bewertung durch Besucher"} height={chartHeightFeedbackQuestionWithGroupsSimple} title={title} />
            </Paper>
        </Paper>
    )
});