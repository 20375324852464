import { Typography, FormControl, FormLabel, FormControlLabel, Radio, Grid, TextField, Paper } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import { RadioGroup } from "devextreme-react";
import { type } from "os";
import React, { useContext, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { primaryColor } from "../../../../../App";
import { MaxChartItemCountInput } from "../../../../../helpers/compontents";
import { formatDateRange, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../../helpers/date-management";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { LoraGroupMotion } from "../../../../../models/LoraGroupMotion";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { StandardBarChartSimpleColor } from "../../../../charts/StandardBarChartSimpleColor";
import { ChartHeightTextBox, PrintButton } from "../../../../core/GlobalTimeChoice";
import { GlobalContext } from "../../../PortalPage";
import { useQuery } from "../../checkout/ShopPage";
import { FeedbackQuestionTextType } from "../FeedbackDashboard";
import { PrintContext } from "../HitListDashboard";
import { DurationChoice, DurationChoiceType, getLabelOfDurationChoice } from "../../../../core/DurationChoice";
import { WithZeroValueChoice, WithZeroValuesChoiceType } from "../../../../core/WithZeroValuesChoice";

export const LoraMotionWithGroupsChartSimple = React.forwardRef(({ loraMotionsTableData, groupsLicenseTableEntries }: { loraMotionsTableData: LoraGroupMotion[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateLoraMotionWithGroupsSimpleData(date: Date) {
            updateStartDateLoraMotionsWithGroupsSimpleData(date);
        },
        updateEndDateLoraMotionWithGroupsSimpleData(date: Date) {
            updateEndDateLoraMotionsWithGroupsSimpleData(date);
        }

    }));

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);
    const history = useHistory();
    const queryString = useQuery(history);
    const [durationChoiceType, setDurationChoiceType] = React.useState(DurationChoiceType.minutes);
    const [withZeroValueChoice, setWithZeroValueChoice] = React.useState(WithZeroValuesChoiceType.WithoutZero);


    const [startDateLoraMotionsWithGroupsSimple, setStartDateLoraMotionsWithGroupsSimple] = React.useState(subDays(new Date(), 10));
    const [endDateLoraMotionsWithGroupsSimple, setEndDateLoraMotionsWithGroupsSimple] = React.useState(new Date());
    const title = isPrintPage ? `<b>Belegungsrate der Einheiten Bericht</b><br />${formatDateRange(startDateLoraMotionsWithGroupsSimple, endDateLoraMotionsWithGroupsSimple)}` : "";

    const [chartHeightLoraMotionsWithGroupsSimple, setChartHeightLoraMotionsWithGroupsSimple] = React.useState(300);
    const [loraMotionsWithGroupsSimpleChartData, setLoraMotionsWithGroupsSimpleChartData] = React.useState<any[]>([]);

    const { maxChartItemCount }: { maxChartItemCount: number } = useContext(GlobalContext);


    React.useEffect(() => {
        updateEndDateLoraMotionsWithGroupsSimpleData(endDateLoraMotionsWithGroupsSimple);
    }, [maxChartItemCount]);

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (loraMotionsTableData) {
            setStartDateLoraMotionsWithGroupsSimple(startDate);
            setEndDateLoraMotionsWithGroupsSimple(endDate);
            updateLoraMotionsWithGroupsSimpleChart(startDate, endDate, loraMotionsTableData);
        }
    }, [loraMotionsTableData, groupsLicenseTableEntries, durationChoiceType, withZeroValueChoice])


    const updateStartDateLoraMotionsWithGroupsSimpleData = (newVal: Date) => {
        if (!loraMotionsTableData) return;
        setStartDateLoraMotionsWithGroupsSimple(newVal);
        updateLoraMotionsWithGroupsSimpleChart(newVal, endDateLoraMotionsWithGroupsSimple, loraMotionsTableData);
    }

    const updateEndDateLoraMotionsWithGroupsSimpleData = (newVal: Date) => {
        if (!loraMotionsTableData) return;
        setEndDateLoraMotionsWithGroupsSimple(newVal);
        updateLoraMotionsWithGroupsSimpleChart(startDateLoraMotionsWithGroupsSimple, newVal, loraMotionsTableData);
    }

    const updateLoraMotionsWithGroupsSimpleChart = (startDate: Date, endDate: Date, loraMotionsAllData: LoraGroupMotion[]) => {
        let loraMotions = loraMotionsAllData.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);
        let res = loraMotions.reduce((sumValue, newValue: LoraGroupMotion) => {

            let notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
            let entryExists = sumValue.find((x: any) => x.argumentField == notificationName);
            if (entryExists) {
                entryExists.valueField += newValue.motionCount;
            } else {
                sumValue.push({ argumentField: notificationName, valueField: newValue.motionCount });
            }



            return sumValue;
        }, [] as any[]);
        res = res.map(x => {
            switch (durationChoiceType) {
                case DurationChoiceType.hours:
                    x.valueField = Math.round(x.valueField / 60);
                    break;
                case DurationChoiceType.minutes:
                    x.valueField = x.valueField;
                    break;
                default:
                    x.valueField = x.valueField * 60;
                    break;
            }

            return x;
        });
        if (withZeroValueChoice == WithZeroValuesChoiceType.All) {
            groupsLicenseTableEntries.forEach(x => {
                if (!res.some(r => r.argumentField == x.notificationName)) {
                    res.push({ argumentField: x.notificationName, valueField: 0 });
                }
            });
        }

        res = res.sort((a, b) => a.valueField - b.valueField).slice(-maxChartItemCount);
        setLoraMotionsWithGroupsSimpleChartData(res);
    }

    const buildContent = () => {
        return (
            <Grid container direction={"column"} spacing={3}>
                <Grid item>
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>{`Belegungsrate in ${getLabelOfDurationChoice(durationChoiceType)} der Einheiten Bericht`}</Typography>
                </Grid>
                <Grid item>
                    <DurationChoice value={durationChoiceType} setValue={setDurationChoiceType} />
                </Grid>
                <Grid item>
                    <WithZeroValueChoice value={withZeroValueChoice} setValue={setWithZeroValueChoice} />
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        < DatePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={"Start Datum"}
                            value={startDateLoraMotionsWithGroupsSimple}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateStartDateLoraMotionsWithGroupsSimpleData(newVal);
                            }}
                            format={"dd.MM.yyyy"}
                            
                        />
                    </Grid>
                    <Grid item>
                        < DatePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={"Ende Datum"}
                            value={endDateLoraMotionsWithGroupsSimple}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateEndDateLoraMotionsWithGroupsSimpleData(newVal);

                            }}
                            format={"dd.MM.yyyy"}
                            
                        />
                    </Grid>
                    <Grid item>
                        <ChartHeightTextBox chartHeight={chartHeightLoraMotionsWithGroupsSimple} setChartHeight={setChartHeightLoraMotionsWithGroupsSimple} actualCount={loraMotionsWithGroupsSimpleChartData.length} />
                    </Grid>
                    <MaxChartItemCountInput />
                </Grid>
            </Grid>
        );
    }

    const buildPrintContent = () => {
        return (
            <>
                <PrintButton title="Belegungsrate der Einheiten Bericht Drucken" pageRef={refPrint} />
                <ChartHeightTextBox chartHeight={chartHeightLoraMotionsWithGroupsSimple} setChartHeight={setChartHeightLoraMotionsWithGroupsSimple} display={false} actualCount={loraMotionsWithGroupsSimpleChartData.length} />
            </>
        )
    }

    if (!loraMotionsTableData) return <></>

    return (
        <Paper elevation={elevationPaper} style={paperStyle}>
            {isPrintPage ? buildPrintContent() : buildContent()}
            <Paper ref={refPrint}>
                <StandardBarChartSimpleColor extraLabel={` ${getLabelOfDurationChoice(durationChoiceType)} belegt`} color={primaryColor} valueFieldService={"valueField"} argumentFieldService={"argumentField"} dataSource={loraMotionsWithGroupsSimpleChartData} name={"Belegungsrate Gruppiert Nach Einheiten Gesamt"} height={chartHeightLoraMotionsWithGroupsSimple} title={title} />
            </Paper>
        </Paper>
    )
});