import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Group, TouchApp } from '@mui/icons-material';
import { formatEuropeanDate } from '../../helpers/date-management';
import { GroupLicense } from '../../models/GroupLicense';
import { GroupLicenseTable, SensorLicense } from '../../models/GroupLicenseTable';
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { GroupSettingsSensorLicense, LoraSensor, Sensor } from '../../models/GroupSettings';
import { useTranslation } from 'react-i18next';

export const AssignLicenseToRegisteredSensorChoosingTableDialog = ({ visible, onClose, licenses, onLicenseSelected, sensor, sensors }
    : { visible: boolean, onClose: any, licenses: SensorLicense[], onLicenseSelected: any, sensor: Sensor | undefined, sensors: Sensor[] | LoraSensor[] }) => {

    const classes = useStyles();

    const [selection, setSelection] = React.useState<GroupSettingsSensorLicense | null>(null);
    const [loading, setLoading] = React.useState(false);

    const {t} = useTranslation();

    React.useEffect(() => {

        setSelection(null);
        setLoading(false);
        if (sensor && sensor.sensorLicense) {
            let sl = new GroupSettingsSensorLicense();
            sl.id = sensor.sensorLicense.id;
            setSelection(sl);
        }

    }, [visible])

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={visible}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleText}>{t("myUnitsSettings.selectLicense")}</Typography>
                        <IconButton color="inherit" onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <List>
                    {
                        licenses &&
                        licenses.length > 0 &&
                        licenses.map((item: SensorLicense, index: number) => {
                            const disabled = sensors.some(x => x?.sensorLicense?.id == item.id) && sensor?.sensorLicense?.id != item.id;
                            const currentSensor = sensors.find(x=>x.sensorLicense?.id == item.id);
                            return (
                                <ListItem
                                    key={index}
                                    button
                                    disabled={disabled}
                                    onClick={() => {
                                        if (selection?.id == item.id) {
                                            selection.id = "";
                                            setSelection({...selection});
                                        } else {
                                            let sl = new GroupSettingsSensorLicense();
                                            sl.id = item.id;
                                            setSelection(sl);
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selection?.id === item.id}
                                            tabIndex={-1}
                                            inputProps={{}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={`Gültig bis: ${formatEuropeanDate(item.sensorLicenseValidUntilDate)}`} />
                                    <ListItemText primary={`Abgelaufen: ${item.isSensorLicenseExpired ? "Ja" : "Nein"}`} />
                                    <ListItemIcon>
                                        <LicenseSvg fill={item.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />
                                    </ListItemIcon>
                                    {disabled &&
                                        <ListItemText primary={`${t("myUnitsSettings.licenseAlreadyInUse")} ${currentSensor?.sensorRoomName}`} />
                                    }
                                </ListItem>
                            )
                        })
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<TouchApp />}
                    onClick={async () => {
                        await onLicenseSelected(selection);
                    }}
                >
                    {t("myUnitsSettings.selectSensorLicense")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 0,
        flexGrow: 1,
    },
    dialogTitleText: {
        flexGrow: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
        height: 320,
    },
    dialogContentRadio: {
        padding: theme.spacing(1),
    },
}));