import React, { useContext } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { IconButton, Tooltip, Button } from '@mui/material';
import { Edit, Delete, PhonelinkSetup } from '@mui/icons-material';
import { ReactComponent as LicenseSvg } from '../../../assets/svgs/license_grey.svg'
import { GroupSelectionAssignPropertyDialog } from './GroupSelectionAssignPropertyDialog';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { errorColor } from '../../../../styles/colors';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { FormattedNumber } from 'react-intl';
import { GlobalContext } from '../../PortalPage';
import { AdminSetting } from '../../../../models/AdminSetting';
import { useTranslation } from 'react-i18next';

export const PropertyManagementPageDataTable = ({ rows, groups, onEdit, onDelete, onRefresh }
    : { rows: UserProperty[], groups: GroupLicenseTable[], onEdit: (row: UserProperty) => Promise<any>, onDelete: (row: UserProperty) => Promise<any>, onRefresh: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);
    const [currentProperty, setCurrentProperty] = React.useState<UserProperty>();
    const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);
    const {t} = useTranslation();

    const columns = [
        {
            name: UserProperty.Columns.actions,
            title: " ",
            getCellValue: (row: UserProperty) => {
                return <>


                    <Tooltip title="Bearbeiten">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Löschen">
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Button onClick={async () => {
                        setGroupSelectionAssignObjectDialogVisible(true);
                        setCurrentProperty(row);
                    }}
                        color="primary" endIcon={<PhonelinkSetup />} variant={"contained"}>
                        Auf Einheiten zuweisen
                    </Button>
                </>;
            },
        },
        {
            name: UserProperty.Columns.name,
            title: "Name",
            getCellValue: (row: UserProperty) => row.name,
        },
        {
            name: UserProperty.Columns.propertyType,
            title: t("myUnitsSettings.featureType"),
            getCellValue: (row: UserProperty) => UserProperty.GetLabelByType(row.propertyType),
        },
        {
            name: UserProperty.Columns.co2Value,
            title: t("myUnitsSettings.co2ConsumptionKgM2"),
            getCellValue: (row: UserProperty) => {
                let currentUsageCategory = adminSetting?.adminUsageCategories.find(x => x.id == row.usageCategoryId);
                return currentUsageCategory ? currentUsageCategory.carbonDioxideConsumption : "Keine Nutzungsart zugewiesen"
            },
        },
        {
            name: UserProperty.Columns.usageType,
            title: t("myUnitsSettings.usageType"),
            getCellValue: (row: UserProperty) => {
                let currentUsageCategory = adminSetting?.adminUsageCategories.find(x => x.id == row.usageCategoryId);
                return currentUsageCategory ? currentUsageCategory.name : "Keine Nutzungsart zugewiesen"
            },
        },
        {
            name: UserProperty.Columns.areaPerformancePerSquareMeter,
            title: t("myUnitsSettings.areaPerformance"),
            getCellValue: (row: UserProperty) => row.areaPerformancePerSquareMeter,
        },
        {
            name: UserProperty.Columns.assign,
            title: t("myUnitsSettings.assignedUnits"),
            getCellValue: (row: UserProperty) => {
                return groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: UserProperty.Columns.deviceIds,
            title: "DeviceIds",
            getCellValue: (row: UserProperty) => {
                return groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 400 } }));
    let columnWidth = Object.keys(UserProperty.Columns).map(el => {
        if (el == UserProperty.Columns.assign || el == UserProperty.Columns.deviceIds) {
            return { columnName: el, width: 400 }
        }
        if (el == UserProperty.Columns.actions) {
            return { columnName: el, width: 400 }
        }
        return { columnName: el, width: 200 }
    });

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === UserProperty.Columns.assign) {
            let names = groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).map(x => x.notificationName);
            return buildCellStringArray(props, names, `Enthält ${names.length} Einheiten`);
        }
        if (column.name === UserProperty.Columns.deviceIds) {
            let names = groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).map(x => x.uniqueDeviceId);
            return buildCellStringArray(props, names, `Enthält ${names.length} DeviceIds`);
        }
        if (column.name == UserProperty.Columns.areaPerformancePerSquareMeter || column.name == UserProperty.Columns.co2Value) {
            return (
                <Table.Cell {...props}>
                    <FormattedNumber value={value} />
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: UserProperty) => row.id}
                columns={columns}
            >

                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ColumnChooser />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            {currentProperty &&
                <GroupSelectionAssignPropertyDialog userProperties={rows} onRefresh={onRefresh} open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} userProperty={currentProperty} />
            }
        </div>
    );
};
