import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../../core/TimeChoice";

export const GroupMotionChart = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, chartCalcType = ChartCalcType.Sum, childRefChart, title }
    : { data: any[], dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, chartCalcType?: ChartCalcType, childRefChart?: any, title?: string }) => {
    const [chartChoice, setChartChoice] = React.useState('1');

    const sumBlockCount = React.useMemo(() => data.reduce((prevVal, x: any) => {
        const sum = x.blockCount;
        return prevVal + sum
    }, 0), [data]);
    if (!title) {
        if (chartCalcType == ChartCalcType.IncreaseSum) {
            title = `Besucher Verlauf Gesamt<br>Gesamtsumme: <b>${sumBlockCount} Besucher</b>`;
        } else {
            title = `Besucher Gesamt<br>Gesamtsumme: <b>${sumBlockCount} Besucher</b>`;
        }
    }



    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={chartCalcType} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}