import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime, formatUnixSecToDate, formatUnixSecToTime } from '../../../../../helpers/date-management';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { getDefaultIcon } from '../../../../dialogs/DefaultItemsDialogOld';
import { GroupQmTrigger } from '../../../../../models/GroupQmTrigger';
import { GroupTriggerBase } from '../../../../../models/GroupTriggerBase';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { ObjectLeaderQualityCheckModel } from '../../../../../models/ObjectLeaderQualityCheckModel';

export const QmTriggerDataTable = ({ rows, groups,userServiceWorkers, qualityChecks  }
    : { rows: GroupQmTrigger[], groups: GroupLicenseTable[], userServiceWorkers : UserServiceWorker[], qualityChecks : ObjectLeaderQualityCheckModel[] }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: GroupTriggerBase.Columns.id,
            title: "Id",
            getCellValue: (row: GroupTriggerBase) => row.id,
        },
        {
            name: GroupTriggerBase.Columns.groupId,
            title: "Aktueller Einheitname",
            getCellValue: (row: GroupTriggerBase) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupQmTrigger.Columns.qmTriggerType,
            title: "Auslösungsart",
            getCellValue: (row: GroupQmTrigger) => GroupQmTrigger.GetLabelByType(row.qmTriggerType),
        },
        {
            name: GroupTriggerBase.Columns.createdTimestamp,
            title: "Erstell Datum",
            getCellValue: (row: GroupTriggerBase) => formatEuropeanDate(row.createdTimestamp),
        },
        {
            name: GroupTriggerBase.Columns.createdTimestampOnlyTime,
            title: "Erstell Zeit",
            getCellValue: (row: GroupTriggerBase) => formatEuropeanTime(row.createdTimestamp),
        },
        {
            name: GroupTriggerBase.Columns.finishedAtTimestamp,
            title: "Fertigdatum",
            getCellValue: (row: GroupTriggerBase) => formatEuropeanDate(row.finishedAtTimestamp),
        },
        {
            name: GroupTriggerBase.Columns.finishedAtTimestampOnlyTime,
            title: "Fertigzeit",
            getCellValue: (row: GroupTriggerBase) => formatEuropeanTime(row.finishedAtTimestamp),
        },
        {
            name: GroupQmTrigger.Columns.isQmStillWithinTime,
            title: "Innerhalb Reaktionszeit",
            getCellValue: (row: GroupQmTrigger) => row.isServiceStillWithinTime ? "Innerhalb (gelb)" : "Auslösung abgelaufen",
        },
        {
            name: GroupQmTrigger.Columns.isQmDone,
            title: "Erledigt",
            getCellValue: (row: GroupQmTrigger) => row.isQmDone ? "Erledigt" : "Nicht Erledigt (rot)",
        },
        {
            name: GroupTriggerBase.Columns.reactionTime,
            title: "Reaktionszeit",
            getCellValue: (row: GroupTriggerBase) => `${row.reactionTime} Minuten`,
        },
        {
            name: GroupTriggerBase.Columns.worksOnServiceWorkerId,
            title: "Objektleiter erledigt",
            getCellValue: (row: GroupTriggerBase) => userServiceWorkers.find(x=>x.id == row.doneByServiceWorkerId)?.name || "",
        },
        {
            name: GroupTriggerBase.Columns.services,
            title: "Services Checkt im QM",
            getCellValue: (row: GroupTriggerBase) => row.doneByQualityCheckId,
        },

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { 
        return { columnName: el.name, width: 180 }
     }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Serviceauslösung');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
          //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[GroupQmTrigger.Columns.id]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
