import * as React from 'react';
import Paper from '@mui/material/Paper';
import { AppointmentModel, ChangeSet, SchedulerDateTime, ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
  WeekView,
  ViewSwitcher,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  styled, darken, alpha, lighten,
} from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { EditingState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import {
  MonthView,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  Resources,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import WbSunny from '@mui/icons-material/WbSunny';
import FilterDrama from '@mui/icons-material/FilterDrama';
import Opacity from '@mui/icons-material/Opacity';
import ColorLens from '@mui/icons-material/ColorLens';
import { Add, AddBox, CalendarMonth, CleaningServices } from '@mui/icons-material';
import { AppointmentCosmosDbModel, CleaningOperation, GroupSettings, GroupSettingsIntervalControl } from '../../models/GroupSettings';
import { DateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { Button, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, TextField } from '@mui/material';
import { CustomDateTimePicker } from './InlineDateTimePicker';
import { addMinutes, addMonths, differenceInDays, differenceInMinutes, getUnixTime, intervalToDuration } from 'date-fns';
import { addDays, addHours } from 'date-fns/esm';
import { StringHelper } from '../../helpers/string-helper';
import { MinimumTimePresenceComponent } from './intervalControl/MinimunTimeComponent';
import { MinimumServiceWorkerComponent } from './intervalControl/GroupSettingFieldsIntervalControl';
import { formatEuropeanDateTime } from '../../helpers/date-management';
import { useTranslation } from 'react-i18next';

const DateEditor = (props: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  return <CustomDateTimePicker value={props.value} change={(event: any) => { props.onValueChange(event) }} label="" />

};

const RecurrenceMenuCustom = (props: any) => {
  return (
    <>
      <EditRecurrenceMenu.Layout {...props} />
    </>
  )

}



const SelectEditor = (props: any) => {
  return (
    <AppointmentForm.Select {...props} />
  )

}

const BooleanEditor = (props: any) => {
  console.log(props, "booleanEditor");
  if (props.label == "Ganztägig") {
    return (
      <div></div>
    );
  }

  return (
    <AppointmentForm.BooleanEditor {...props} />
  )

}


const RadioGroupEditor = (props: any) => {
  return (
    <AppointmentForm.RadioGroup {...props} />
  )

}

const LabelEditor = (props: any) => {
  return (
    <AppointmentForm.Label {...props} />
  )
}

const allDayLocalizationMessages: any = {
  'fr-FR': {
    allDay: 'Temps plein',
  },
  'de-AT': {
    allDayLabel: 'Ganztägig',
    titleLabel: 'Titel',
    commitCommand: 'Übernehmen',
    moreInformationLabel: 'Mehr Information',
    repeatLabel: 'Wiederholung',
    notesLabel: 'Notizen',
    never: 'Niemals',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    yearly: 'Jährlich',
    repeatEveryLabel: 'Alle',
    daysLabel: 'Tag(e)',
    endRepeatLabel: 'Ende Wiederholung',
    occurrencesLabel: 'Ereigniss(e)',
    weeksOnLabel: 'Woche(n)',
    monthsLabel: 'Monat(e)',
    ofEveryMonthLabel: 'jedes Monat(s)',
    theLabel: 'Der',
    firstLabel: 'Erste',
    secondLabel: 'Zweite',
    thirdLabel: 'Dritte',
    fourthLabel: 'Vierte',
    lastLabel: 'Letzte',
    yearsLabel: 'Jahr(e)',
    ofLabel: 'von',
    everyLabel: 'Jedes',
    onLabel: "Am / Bei",
    every: "Jede",
    afterLabel: "Nach"
  },
  'en-US': {
    allDay: 'All Day',
  },
};
const getAllDayMessages = (locale: any) => { return allDayLocalizationMessages[locale] };


const defaultAppointmentRecurrenceExtraService = {
  "startDate": new Date(),
  "endDate": new Date(),
  "allDay": false,
  "rRule": "RRULE:INTERVAL=1;FREQ=DAILY;COUNT=30"
};

export const IntervalControlScheduleCalendar = ({ row, services, onChange, myAppointments, setMyAppointments }: { row: GroupSettingsIntervalControl, services: CleaningOperation[], onChange: any, myAppointments: AppointmentModel[], setMyAppointments: any }) => {

  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [isServicesCustomRruleEnabled, setIsServicesCustomRruleEnabled] = React.useState(false);
  const commitChanges = (changes: ChangeSet) => {
    const { added, changed, deleted } = changes;
    setMyAppointments((prevState: AppointmentModel[]) => {
      let data = prevState;
      if (added) {
        let startingAddedId = 0;
        if (data.length > 0) {
          let val: any = data[data.length - 1].id;
          startingAddedId = data.length > 0 ? val + 1 : 0;
        } else {
          startingAddedId = 0;
        }

        data = [...data, { id: startingAddedId, ...added as AppointmentModel }];

      }
      if (changed) {
        data = data.map((appointment: any) => (
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment));
      }
      if (deleted !== undefined) {
        data = data.filter((appointment: any) => appointment.id !== deleted);
      }
      if (row) row.calendarEntries = data.map(x => AppointmentCosmosDbModel.ConvertFromAppointmentModel(x));



      return data.map(x => { x.allDay = false; return x });
    });
  }

  const RecurrenceAppointmentFormCustom = ({ onFieldChange, appointmentData, ...restProps }: any) => {
    console.log(appointmentData.extraServicesRrule);
    if (!appointmentData.extraServicesRrule) {
      appointmentData.extraServicesRrule = { ...defaultAppointmentRecurrenceExtraService };
    }
    if (!(appointmentData.extraServicesRrule.endDate instanceof Date)) {
      appointmentData.extraServicesRrule.endDate = new Date(appointmentData.extraServicesRrule.endDate);
    }
    if (!(appointmentData.extraServicesRrule.startDate instanceof Date)) {
      appointmentData.extraServicesRrule.startDate = new Date(appointmentData.extraServicesRrule.startDate);
    }

    return (
      <>
        <div>
          <AppointmentForm.RecurrenceLayout onFieldChange={onFieldChange} {...restProps} appointmentData={appointmentData} />
          {appointmentData.isServicesCustomRruleEnabled && appointmentData && appointmentData.services && appointmentData.services.length > 0 &&
            <div>
              <Typography variant="h5" fontWeight={'bold'}>Angeabe Service Spezifische Auslösung</Typography>
              <AppointmentForm.RecurrenceLayout  {...restProps} appointmentData={appointmentData.extraServicesRrule} onFieldChange={(props: any) => {
                onFieldChange({
                  extraServicesRrule: {
                    ...appointmentData.extraServicesRrule,
                    "rRule": props.rRule
                  }
                })
              }} />
            </div>
          }
        </div>
      </>
    )

  }

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }: any) => {
    const onCustomFieldChange = (nextValue: any) => {
      onFieldChange({ customField: nextValue });
    };

    const {t} = useTranslation();

    const [minutes, setMinutes] = React.useState(10);
    const [hours, setHours] = React.useState(0);

    const [days, setDays] = React.useState(0);
    const [months, setMonths] = React.useState(0);



    React.useEffect(() => {
      if (appointmentData.endDate && appointmentData.startDate) {
        let diff = intervalToDuration({
          start: new Date(appointmentData.startDate),
          end: new Date(appointmentData.endDate)
        });
        setMonths(diff.months || 0);
        setDays(diff.days || 0);
        setHours(diff.hours || 0);
        setMinutes(diff.minutes || 0);
        calcEndDate(diff.months || 0, diff.days || 0, diff.hours || 0, diff.minutes || 0, appointmentData.startDate);
      } else {
        setMinutes(30);
        onFieldChange({ ...appointmentData, endDate: addMinutes(appointmentData.startDate, 30) });
      }
    }, []);

    const calcEndDate = (months: number, days: number, hours: number, minutes: number, startDate: Date) => {
      let endDate = addMonths(startDate, months);
      endDate = addDays(endDate, days);
      endDate = addHours(endDate, hours);
      endDate = addMinutes(endDate, minutes);
      onFieldChange({ ...appointmentData, endDate: endDate, startDate: startDate });
    }


    return (
      <AppointmentForm.BasicLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
        dateEditorComponent={() => null}
        textEditorComponent={() => null}
        labelComponent={() => null}
        booleanEditorComponent={() => null}
      >
        <Grid container direction="column" spacing={3} style={{ marginTop: 20 }}>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Kalender Titel"}
              value={appointmentData.title}
              onChange={(event: any) => {
                onFieldChange({ ...appointmentData, title: event.target.value });
              }}
            />
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography variant={"h5"} fontWeight={'bold'}>Servicestart</Typography>
          </Grid>
          <Grid item container spacing={3} alignItems={"center"}>
            <Grid item>
              < DateTimePicker

                slotProps={{ textField: { variant: 'outlined' } }}
                label={"Start Datum"}
                value={appointmentData.startDate}
                onChange={(newVal) => {
                  if (!newVal) return;
                  calcEndDate(months, days, hours, minutes, newVal);

                }}
                format={"dd.MM.yyyy HH:mm"}

              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!appointmentData.rRule}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        onFieldChange({ ...appointmentData, rRule: "RRULE:INTERVAL=1;FREQ=DAILY" })
                      } else {
                        onFieldChange({ ...appointmentData, rRule: undefined })
                      }
                    }}
                    name="isEnabled"
                    color="primary"
                  />
                }
                label="Service Wiederholung"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant={"h5"} fontWeight={'bold'}>{t("myUnitsSettings.reactionTime")}</Typography>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item lg={3}>
              <TextField

                required
                variant="outlined"
                label="Monate"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Monate</InputAdornment>
                }}
                value={months}
                onChange={(event: any) => {
                  if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                  calcEndDate(event.target.value, days, hours, minutes, appointmentData.startDate);
                  setMonths(event.target.value);

                }}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField

                required
                variant="outlined"
                label="Tage"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Tage</InputAdornment>
                }}
                value={days}
                onChange={(event: any) => {
                  if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                  calcEndDate(months, event.target.value, hours, minutes, appointmentData.startDate);
                  setDays(event.target.value);

                }}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField

                required
                variant="outlined"
                label="Stunden"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Stunden</InputAdornment>
                }}
                value={hours}
                onChange={(event: any) => {
                  if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                  calcEndDate(months, days, event.target.value, minutes, appointmentData.startDate);
                  setHours(event.target.value);
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField

                required
                variant="outlined"
                label="Minuten"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Minuten</InputAdornment>
                }}
                value={minutes}
                onChange={(event: any) => {
                  if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                  calcEndDate(months, days, hours, event.target.value, appointmentData.startDate);
                  setMinutes(event.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <MinimumTimePresenceComponent minimumTime={appointmentData?.minimumTime || "00:00:00"} onChange={(event: any) => {
              appointmentData.minimumTime = event.target.value;
              onFieldChange({ ...appointmentData, minimumTime: event.target.value });
            }} target={'test'} />
          </Grid>
          <MinimumServiceWorkerComponent target="intervalControlMinimumServiceWorker" minimumServiceWorkerControl={appointmentData?.minimumServiceWorker || 0} onChange={(event: any) => {
            appointmentData.minimumServiceWorker = event.target.value;
            onFieldChange({ ...appointmentData, minimumServiceWorker: event.target.value });
          }} />
          <Grid item>
            < DateTimePicker
              slotProps={{ textField: { variant: 'outlined' } }}
              label={"Service abgelaufen"}
              disabled={true}
              value={appointmentData.endDate}
              onChange={(newVal) => {
                if (!newVal) return;
                onFieldChange({ ...appointmentData, endDate: newVal });
              }}
              format={"dd.MM.yyyy HH:mm"}

            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={3}>
            <Grid item>
              <Divider style={{ width: '100%' }} />
            </Grid>

            {services.map((el: CleaningOperation, index: any) => {
              return (
                <Grid item container alignItems={"center"} spacing={2}>
                  <Grid item>
                    <TextField disabled label={`Standard Service ${index + 1}`} value={el.text} onChange={(event) => {
                      el.text = event.target.value;
                      onFieldChange({ services: [...appointmentData.services] });
                    }}>

                    </TextField>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={el.isEnabled}
                          onChange={(event) => {
                            el.isEnabled = event.target.checked;
                            onFieldChange({ services: [...appointmentData.services] });

                          }}
                          name="isEnabled"
                          color="primary"
                        />
                      }
                      label="Aktiviert"
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </AppointmentForm.BasicLayout>
    );
  };


  console.log(myAppointments, "myAppointments");

  return (
    <Paper>
      <Scheduler
        data={myAppointments}
        locale={"de-AT"}
      >

        <EditingState
          onCommitChanges={commitChanges}
        />
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
          defaultCurrentViewName="Month"
        />
        <DayView
        />
        <WeekView
        />
        <WeekView
          name="work-week"
          displayName="Arbeits Woche"
          excludedDays={[0, 6]}

        />
        <MonthView

        />
        <AllDayPanel />

        <Appointments
          appointmentComponent={Appointment}
          appointmentContentComponent={AppointmentContent}

        />
        <Toolbar
          flexibleSpaceComponent={FlexibleSpace}
        />
        <ViewSwitcher />
        <DateNavigator />
        <EditRecurrenceMenu layoutComponent={RecurrenceMenuCustom} />
        <AppointmentTooltip
          showCloseButton
          showDeleteButton
          showOpenButton
        />
        <AppointmentForm

          messages={getAllDayMessages("de-AT")}
          dateEditorComponent={DateEditor}
          basicLayoutComponent={BasicLayout}
          recurrenceLayoutComponent={RecurrenceAppointmentFormCustom}
          labelComponent={LabelEditor}
          selectComponent={SelectEditor}
          booleanEditorComponent={BooleanEditor}
          radioGroupComponent={RadioGroupEditor}
          onVisibilityChange={(visible) => {
            if (!visible) {
            }
          }}
          onAppointmentDataChange={(appointmentData) => {
          }}
        />
        <DragDropProvider />
      </Scheduler>
    </Paper>
  );
};






const PREFIX = 'Demo';

const classes = {
  cell: `${PREFIX}-cell`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  sun: `${PREFIX}-sun`,
  cloud: `${PREFIX}-cloud`,
  rain: `${PREFIX}-rain`,
  sunBack: `${PREFIX}-sunBack`,
  cloudBack: `${PREFIX}-cloudBack`,
  rainBack: `${PREFIX}-rainBack`,
  opacity: `${PREFIX}-opacity`,
  appointment: `${PREFIX}-appointment`,
  apptContent: `${PREFIX}-apptContent`,
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  flexContainer: `${PREFIX}-flexContainer`,
  tooltipContent: `${PREFIX}-tooltipContent`,
  tooltipText: `${PREFIX}-tooltipText`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  circle: `${PREFIX}-circle`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`,
};

const getBorder = (theme: any) => (`1px solid ${theme.palette.mode === 'light'
  ? lighten(alpha(theme.palette.divider, 1), 0.88)
  : darken(alpha(theme.palette.divider, 1), 0.68)
  }`);

const DayScaleCell = (props: any) => (
  <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
);

const StyledOpacity = styled(Opacity)(() => ({
  [`&.${classes.rain}`]: {
    color: '#4FC3F7',
  },
}));
const StyledWbSunny = styled(WbSunny)(() => ({
  [`&.${classes.sun}`]: {
    color: '#FFEE58',
  },
}));
const StyledFilterDrama = styled(FilterDrama)(() => ({
  [`&.${classes.cloud}`]: {
    color: '#90A4AE',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    color: '#78909C!important',
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 100,
    borderLeft: getBorder(theme),
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  [`&.${classes.sunBack}`]: {
    backgroundColor: '#FFFDE7',
  },
  [`&.${classes.cloudBack}`]: {
    backgroundColor: '#ECEFF1',
  },
  [`&.${classes.rainBack}`]: {
    backgroundColor: '#E1F5FE',
  },
  [`&.${classes.opacity}`]: {
    opacity: '0.5',
  },
}));
const StyledDivText = styled('div')(() => ({
  [`&.${classes.text}`]: {
    padding: '0.5em',
    textAlign: 'center',
  },
}));
const StyledDivContent = styled('div')(() => ({
  [`&.${classes.content}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
}));

const StyledAppointmentsAppointment: any = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.${classes.flexibleSpace}`]: {
    flex: 'none',
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const StyledAppointmentsAppointmentContent: any = styled(Appointments.AppointmentContent)(() => ({
  [`&.${classes.apptContent}`]: {
    '&>div>div': {
      whiteSpace: 'normal !important',
      lineHeight: 1.2,
    },
  },
}));

const appointments: AppointmentModel[] = [
];

const resources = [{
  fieldName: 'ownerId',
  title: 'Owners',
  instances: [],
}];

const WeatherIcon = ({ id }: any) => {
  switch (id) {
    case 0:
      return <StyledOpacity className={classes.rain} fontSize="large" />;
    case 1:
      return <StyledWbSunny className={classes.sun} fontSize="large" />;
    case 2:
      return <StyledFilterDrama className={classes.cloud} fontSize="large" />;
    default:
      return null;
  }
};

const CellBase = React.memo(({
  startDate,
  formatDate,
  otherMonth,
}: any) => {
  const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
  const isFirstMonthDay = startDate.getDate() === 1;
  const formatOptions = isFirstMonthDay
    ? { day: 'numeric', month: 'long' }
    : { day: 'numeric' };
  return (
    <StyledTableCell
      tabIndex={0}
      className={classNames({
        [classes.cell]: true,
        [classes.rainBack]: iconId === 0,
        [classes.sunBack]: iconId === 1,
        [classes.cloudBack]: iconId === 2,
        [classes.opacity]: otherMonth,
      })}
    >
      <StyledDivContent className={classes.content}>
        <WeatherIcon classes={classes} id={iconId} />
      </StyledDivContent>
      <StyledDivText className={classes.text}>
        {formatDate(startDate, formatOptions)}
      </StyledDivText>
    </StyledTableCell>
  );
});

const TimeTableCell = (CellBase);

const Appointment = (({ children, style, ...restProps }: any) => {
  if (restProps.data.services && restProps.data.services.length > 0) {
    return <div>
      <div style={{ position: 'absolute', right: 5, bottom: 0, zIndex: 1, display: 'flex', alignItems: 'center' }}>
        <CleaningServices style={{ fontSize: 18 }} />
        <AddBox style={{ fontSize: 18 }} />
        <Typography color={"white"}>{restProps.data.services.length}</Typography>
      </div>
      <Appointments.Appointment
        {...restProps}
      ></Appointments.Appointment>
    </div>
  } else {
    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
        }}
      >
        {children}
      </Appointments.Appointment>
    )
  }

}

);

const AppointmentContent = (({ ...restProps }: any) => {
  if (restProps.data.services && restProps.data.services.length > 0) {
    return (
      <Appointments.AppointmentContent  {...restProps}>
        <Typography>test</Typography>
      </Appointments.AppointmentContent>
    )
  } else {
    return <StyledAppointmentsAppointmentContent  {...restProps} className={classes.apptContent} >
      <Typography>{restProps.data.title}<br />{formatEuropeanDateTime(restProps.data.startDate)} - {formatEuropeanDateTime(restProps.data.endDate)}</Typography>
    </StyledAppointmentsAppointmentContent>

  }
});

const FlexibleSpace = (({ ...restProps }) => { 
  const {t} = useTranslation();
  return (
  <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>
    <div className={classes.flexContainer}>
      <Typography variant="h5" style={{ marginLeft: '10px' }}>{t("myUnitsSettings.calendarPlanning")}</Typography>
      <CalendarMonth fontSize="large" color='primary' />
    </div>
  </StyledToolbarFlexibleSpace>
)});
