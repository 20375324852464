import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { CleaningTriggerExtraServices, GroupCleaningTrigger } from '../../../../../models/GroupCleaningTrigger';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanTime, formatUnixSecToDate, formatUnixSecToTime } from '../../../../../helpers/date-management';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { GroupOnlineStatus } from '../../../../../models/GroupOnlineStatus';
import { Group, GroupType } from '../../../../../models/Group';
import { getDefaultIcon } from '../../../../dialogs/DefaultItemsDialogOld';
import { GridStackCustom, GridStackCustomItem } from '../../../../../models/GridStackCustom';
import { IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { errorColor } from '../../../../../styles/colors';

export const GridStackCustomItemsDataTable = ({ rows, onEdit, onDelete }
    : { rows: GridStackCustomItem[], onEdit: any, onDelete: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        // if (column.name === GroupCleaningTrigger.Columns.extraServices) {
        //     let names: string[] = row.extraServices.map((x: CleaningTriggerExtraServices) => `${x.text} | ${x.isDone ? "Erledigt" : "Nicht Erledigt"}`);
        //     return buildCellStringArray(props, names, `Enthält ${names.length} Extra-Services`, null, names.map(x => getDefaultIcon(x || '')));
        // }
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: GridStackCustom.Columns.actions,
            title: "Bearbeiten",
            getCellValue: (row: GridStackCustom) => {
                return <>

                    <Tooltip title="Bearbeiten">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Löschen">
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>;
            },
        },
        {
            name: GridStackCustomItem.Columns.id,
            title: "Name",
            getCellValue: (row: GridStackCustomItem) => row.id,
        },
        {
            name: GridStackCustomItem.Columns.createdTimestamp,
            title: "Erstell Datum",
            getCellValue: (row: GridStackCustomItem) => formatEuropeanDate(row.createdTimestamp),
        },
        {
            name: GridStackCustomItem.Columns.createdTime,
            title: "Erstell Zeit",
            getCellValue: (row: GridStackCustomItem) => formatEuropeanTime(row.createdTimestamp),
        },
        {
            name: GridStackCustomItem.Columns.origin,
            title: "Diagramm Ursprung",
            getCellValue: (row: GridStackCustomItem) => GridStackCustomItem.GetOrigin(row.gridStackItemType).join(','),
        }
    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == GroupOnlineStatus.Columns.groupId) {
            return { columnName: el.name, width: 350 }
        }
        return { columnName: el.name, width: 180 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Custom-Dashboard');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
                getRowId={(y=>y.id)}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
