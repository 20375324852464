import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { MarkedItemThing, MarkedMaterial, MarkedService, ObjectLeaderQualityCheckModel } from '../../../../models/ObjectLeaderQualityCheckModel';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { FIVE_SMILE, GREEN_LIGHT_SMILE, GREEN_SMILE, RED_LIGHT_SMILE, RED_SMILE, YELLOW_SMILE } from '../../../../assets';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Group, GroupType } from '../../../../models/Group';
import { mapToiletTypeKey } from '../../../tables/Base';
import { IncidentImagesDialog } from '../../../authorized/portal/Dashboard/serviceMode/IncidentImagesDialog';
import { GroupServiceModeIncidentPhoto } from '../../../../models/GroupServiceMode';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, ListItem, ListItemButton, TextField, Typography } from '@mui/material';
import { Camera, CameraAlt, Chair, Mail, PhotoAlbum, PhotoAlbumRounded, Smartphone } from '@mui/icons-material';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { StringHelper } from '../../../../helpers/string-helper';
import { useStyles } from '../../../../styles/container';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QualityCheckImagesDialog } from '../../../authorized/portal/Dashboard/serviceMode/QualityCheckImagesDialog';
import { getDefaultIcon } from '../../DefaultItemsDialogOld';
import { QMRatingType, QMSettings } from '../../../../models/ObjectUnit';
import { ItemThing } from '../../../../models/GroupSettings';
import { getSmileValue } from '../../../../helpers/charts/objectLeader/dataGenerator';
import { useTranslation } from 'react-i18next';

export const getSmileText = (service: MarkedService | MarkedMaterial) => {
    let src = ''
    if (service.veryNegativeAmount == 1) {
        src = "Sehr Negativ";
    }
    else if (service.negativeAmount == 1) {
        src = "Negativ"
    }
    else if (service.neutralAmount == 1) {
        src = "Neutral"
    }
    else if (service.positiveAmount == 1) {
        src = "Positive"
    }
    else if (service.veryPositiveAmount == 1) {
        src = "Sehr Positv"
    }
    else {
        src = "";
    }

    return src;
}
export const getSmileImage = (service: MarkedService | MarkedMaterial) => {
    let src = ''
    if (service.veryNegativeAmount == 1) {
        src = RED_SMILE;
    }
    else if (service.negativeAmount == 1) {
        src = RED_LIGHT_SMILE;
    }
    else if (service.neutralAmount == 1) {
        src = YELLOW_SMILE;
    }
    else if (service.positiveAmount == 1) {
        src = GREEN_LIGHT_SMILE
    }
    else if (service.veryPositiveAmount == 1) {
        src = GREEN_SMILE;
    }
    else {
        src = "";
    }

    return <img src={src} style={{ height: 60 }} />
}
export const getSmileValueFromMarkedService = (service: MarkedService | MarkedMaterial) => {
    return getSmileValue(service.veryNegativeAmount, service.negativeAmount, service.neutralAmount, service.positiveAmount, service.veryPositiveAmount);
}

export const ObjectLeaderQualityCheckTable = ({ qualityChecks, userServiceWorkers, groups }
    : { qualityChecks: ObjectLeaderQualityCheckModel[], userServiceWorkers: UserServiceWorker[], groups: GroupLicenseTable[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            if (userServiceWorkers && userServiceWorkers.length > 0) {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-${userServiceWorkers.map(x => x.name).join()}-`);
            } else {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-Einheiten`);
            }
        });
    };
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    const PhotoCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setGroupId(row.groupId);
                        setIncidentPhotos(row.incidentPhotos);
                        setIsIncidentImagesDialogVisible(true);
                    }}
                    endIcon={<PhotoAlbum></PhotoAlbum>}
                >{row.incidentPhotos.length} Vorfallfotos anzeigen</Button>
            </Table.Cell>
        )
    };

    const AccordionForStringArray = ({ props, services, title, listItemIcon = null, row }: { props: any, services: MarkedService[], title: any, listItemIcon?: any, row: ObjectLeaderQualityCheckModel }) => {
        const classes = useStyles();

        const getIcon = (index: number) => {
            if (listItemIcon && listItemIcon[index]) {
                return listItemIcon[index];
            }
            return (
                <Smartphone />
            )
        }

        return (
            <Table.Cell {...props}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <MuiGrid container direction="column">
                            <MuiGrid item>
                                <Typography className={classes.heading}>{title}</Typography>
                            </MuiGrid>

                        </MuiGrid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List style={{ width: '100%' }} component="nav" aria-label="main mailbox folders">
                            {services && services.length > 0 && services.map((el, index) => {
                                return (
                                    <ListItem style={{ width: '100%' }} key={index}>
                                        <Button

                                            onClick={async () => {
                                                setGroupId(row.groupId);
                                                setQualityCheckPhotos(el.qualityCheckPhoto);
                                                setIsQualityCheckImagesDialogVisible(true);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={el.qualityCheckPhoto && el.qualityCheckPhoto.length < 1}
                                            size="small"
                                            endIcon={<CameraAlt />}
                                        >
                                            {el.qualityCheckPhoto ? `${el.qualityCheckPhoto.length} Servicefotos` : "Keine"}
                                        </Button>

                                        <ListItemText style={{ margin: 10 }} primary={el.cleaningOperationText} />
                                        {row.qmRatingType == QMRatingType.QMWeighting && el.weighting > 0 &&
                                            <ListItemText style={{ margin: 10 }} primary={` | ${el.weighting}%`} />
                                        }
                                        <ListItemIcon>
                                            {getIcon(index)}
                                        </ListItemIcon>

                                    </ListItem>
                                )
                            })}

                        </List>
                    </AccordionDetails>
                </Accordion>

            </Table.Cell>
        );
    }

    const AccordionForStringArrayMarkedItemThings = ({ props, itemThings, title, listItemIcon = null, row }: { props: any, itemThings: MarkedItemThing[], title: any, listItemIcon?: any, row: ObjectLeaderQualityCheckModel }) => {
        const classes = useStyles();

        const getIcon = (index: number) => {
            if (listItemIcon && listItemIcon[index]) {
                return listItemIcon[index];
            }
            return (
                <Smartphone />
            )
        }

        return (
            <Table.Cell {...props}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <MuiGrid container direction="column">
                            <MuiGrid item>
                                <Typography className={classes.heading}>{title}</Typography>
                            </MuiGrid>

                        </MuiGrid>
                    </AccordionSummary>
                    <AccordionDetails style={{margin: 0, padding: 0}}>
                        <List style={{ width: '100%' }} component="nav" aria-label="main mailbox folders">
                            {itemThings && itemThings.length > 0 && itemThings.map((el, index) => {
                                return (
                                    <ListItem style={{ width: '100%' }} key={index}>
                                        <Button

                                            onClick={async () => {
                                                setGroupId(row.groupId);
                                                setQualityCheckPhotos(el.qualityCheckPhoto);
                                                setIsQualityCheckImagesDialogVisible(true);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={el.qualityCheckPhoto && el.qualityCheckPhoto.length < 1}
                                            size="small"
                                            endIcon={<CameraAlt />}
                                        >
                                            {el.qualityCheckPhoto ? `${el.qualityCheckPhoto.length} Foto` : "Keine"}
                                        </Button>

                                        <ListItemText style={{ marginLeft: 10 }} secondary={`${ItemThing.getItemThingTypeText(el.itemThingType,t)}`} primary={`${el.text} | Fehler: ${el.errorCount}`} />
                                        <ListItemIcon>
                                            {getIcon(index)}
                                        </ListItemIcon>

                                    </ListItem>
                                )
                            })}

                        </List>
                    </AccordionDetails>
                </Accordion>

            </Table.Cell>
        );
    }

    const Cell = (props: any) => {
        const { column, row } : {column: any, row : ObjectLeaderQualityCheckModel} = props;
        if (column.name == ObjectLeaderQualityCheckModel.Columns.incidents && row.incidents) {
            let incidentsIcon = row.incidents.map((x: string) => getDefaultIcon(x));
            return buildCellStringArray(props, row.incidents, `Enthält ${row.incidents.length} Vorfallmeldungen`, null, incidentsIcon);
        }
        if (column.name == ObjectLeaderQualityCheckModel.Columns.markedItemThings && row.markedItemThings) {
            let servicesIcon = row.markedItemThings.map((x: MarkedItemThing) => <Chair />);
            let countPhotos = row.markedItemThings.reduce((prevVal, curVal)=>{
                return prevVal + curVal.qualityCheckPhoto.length;
            },0)
            return <AccordionForStringArrayMarkedItemThings props={props} itemThings={row.markedItemThings} title={`Enthält ${row.markedItemThings.length} Gegenstände und ${countPhotos} Fotos`} listItemIcon={servicesIcon} row={row}></AccordionForStringArrayMarkedItemThings>

        }
        if (column.name == ObjectLeaderQualityCheckModel.Columns.markedServices && row.markedServices) {
            let servicesIcon = row.markedServices.map((x: MarkedService) => getSmileImage(x));
            let countPhotos = row.markedServices.reduce((prevVal, curVal)=>{
                return prevVal + curVal.qualityCheckPhoto.length;
            },0)
            return <AccordionForStringArray props={props} services={row.markedServices} title={`Enthält ${row.markedServices.length} Services und ${countPhotos} Fotos`} listItemIcon={servicesIcon} row={row}></AccordionForStringArray>

        }
        if (column.name == ObjectLeaderQualityCheckModel.Columns.markedMaterials && row.markedMaterials) {
            let servicesIcon = row.markedMaterials.map((x: MarkedMaterial) => getSmileImage(x));
            let services = row.markedMaterials.map((x: MarkedMaterial) => x.materialText);

            return buildCellStringArray(props, services, `Enthält ${row.markedMaterials.length} Materialien`, null, servicesIcon);
        }
        else if (column.name === "incidentPhotos" && row.incidentPhotos) {
            return <PhotoCell {...props} />
        }
        return <Table.Cell {...props} />;
    };


    let columns = [
        {
            name: ObjectLeaderQualityCheckModel.Columns.groupId,
            title: 'Einheit Name',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupLicenseTable.Columns.groupType,
            title: 'Einheit Typ',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => Group.GetLabelByType(groups.find(x => x.id == row.groupId)?.groupType || GroupType.DisplayWallApp),
        },
        {
            name: GroupLicenseTable.Columns.objectName,
            title: 'Objekt Name',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: 'Ebene',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: GroupLicenseTable.Columns.roomName,
            title: 'Kunde',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.roomName,
        },
        {
            name: ObjectLeaderQualityCheckModel.Columns.userServiceWorkerId,
            title: 'Servicekraft',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => userServiceWorkers.find(x => x.id == row.userServiceWorkerId)?.name || row.userServiceWorkerId,
        },
        {
            name: ObjectLeaderQualityCheckModel.Columns.qmRatingType,
            title: 'Bewertungs Typ',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => QMSettings.GetLabelByType(row.qmRatingType)
        },
        {
            name: ObjectLeaderQualityCheckModel.Columns.markedServices,
            title: 'Services',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                return row.markedServices && row.markedServices.map(x => `${x.cleaningOperationText} => ${getSmileText(x)}`).join(' | ')
            },
        },
        {
            name: ObjectLeaderQualityCheckModel.Columns.markedItemThings,
            title: 'Gegenstände',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                return row?.markedItemThings && row?.markedItemThings?.map(x => `${x.text}`).join(' | ')
            },
        },
        {
            name: ObjectLeaderQualityCheckModel.Columns.markedMaterials,
            title: 'Materialen',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedMaterials && row.markedMaterials.map(x => `${x.materialText} => ${getSmileText(x)}`).join(' | '),
        },
        { name: ObjectLeaderQualityCheckModel.Columns.incidentPhotos, title: 'Vorfallfotos', getCellValue: (row: ObjectLeaderQualityCheckModel) => row.incidentPhotos && `${row.incidentPhotos.length} Vorfallfotos anzeigen` },
        {
            name: ObjectLeaderQualityCheckModel.Columns.incidents,
            title: 'Vorfallmeldungen',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => row.incidents && row.incidents.join(', '),
        },
        {
            name: ObjectLeaderQualityCheckModel.Columns.createdTimestamp,
            title: 'Erstellt am',
            getCellValue: (row: ObjectLeaderQualityCheckModel) => formatEuropeanDateTime(row.createdTimestamp),
        },
        {
            name: GroupLicenseTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.userPropertyName,
        },


    ];
    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [isIncidentImagesDialogVisible, setIsIncidentImagesDialogVisible] = React.useState(false);
    const [incidentPhotos, setIncidentPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);
    const [groupId, setGroupId] = React.useState("");

    const [isQualityCheckImagesDialogVisible, setIsQualityCheckImagesDialogVisible] = React.useState(false);
    const [qualityCheckPhotos, setQualityCheckPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);

    const [selection, setSelection] = React.useState<string[]>([]);
    const [mail, setMail] = React.useState<string>("");
    const [loadingMail, setLoadingMail] = React.useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    return (
        <Paper>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <TextField
                    id="email"
                    fullWidth
                    label="Email Addresse"
                    margin="dense"
                    name="email"
                    required={true}
                    disabled={loadingMail}
                    size={'medium'}
                    variant="outlined"
                    type="email"
                    value={mail || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMail(event.target.value);
                    }}
                />
                <Button onClick={async () => {
                    setLoadingMail(true);
                    const tokenTemp = getApiToken(history);
                    await api.sendSelectedQualityChecksToMail(tokenTemp, selection, mail, enqueueSnackbar);
                    setLoadingMail(false);
                }}
                    disabled={selection.length < 1 || loadingMail || !StringHelper.IsValidEmail(mail)}
                    style={{ marginLeft: 20 }}
                    startIcon={loadingMail && <CircularProgress size={24} />}
                    color="primary" endIcon={<Mail />} variant={"contained"} size={"small"}>
                    {selection.length} ausgewählte Qualitätsicherungen als Email versenden
                </Button>
            </div>
            <IncidentImagesDialog groupId={groupId} visible={isIncidentImagesDialogVisible} setVisible={setIsIncidentImagesDialogVisible} incidentPhotos={incidentPhotos} />
            <QualityCheckImagesDialog groupId={groupId} visible={isQualityCheckImagesDialogVisible} setVisible={setIsQualityCheckImagesDialogVisible} incidentPhotos={qualityCheckPhotos} />
            <Grid
                rows={qualityChecks}
                columns={columns}
                getRowId={(row: ObjectLeaderQualityCheckModel) => row.id}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection as any}
                />
                <IntegratedPaging />
                <IntegratedSelection />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    defaultColumnWidths={[
                        { columnName: ObjectLeaderQualityCheckModel.Columns.groupId, width: 160 },
                        { columnName: GroupLicenseTable.Columns.objectName, width: 130 },
                        { columnName: GroupLicenseTable.Columns.groupType, width: 180 },
                        { columnName: GroupLicenseTable.Columns.levelName, width: 130 },
                        { columnName: GroupLicenseTable.Columns.roomName, width: 130 },
                        { columnName: GroupLicenseTable.Columns.toiletType, width: 130 },
                        { columnName: GroupLicenseTable.Columns.userPropertyName, width: 160 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.incidents, width: 400 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.incidentPhotos, width: 300 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.markedMaterials, width: 400 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.markedServices, width: 450 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.markedItemThings, width: 450 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.createdTimestamp, width: 150 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.userServiceWorkerId, width: 160 },
                        { columnName: ObjectLeaderQualityCheckModel.Columns.qmRatingType, width: 160 },
                    ]}
                />
                <TableHeaderRow showSortingControls />
                <TableSelection showSelectAll />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={qualityChecks}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};