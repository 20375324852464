import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    Grid,
    Input,
    InputLabel,
    Paper,
    Select,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, FeaturedVideo, MergeType, Navigation, TabletAndroid } from '@mui/icons-material';
import React from 'react';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupLicenseTable, SensorLicense } from '../../../../models/GroupLicenseTable';
import { white } from '../../../../styles/colors';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { GroupType } from '../../../../models/Group';
import { LicenseWhite, MotionSensorSvgWhite } from '../../../../assets';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../../dialogs/AssignLicenseChoosingTableDialog';
import { LicenseType } from '../../../../models/LicenseType';
import { GroupLicense } from '../../../../models/GroupLicense';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { AssignSensorLicenseChoosingTableDialog } from '../../../dialogs/AssignSensorLicenseChoosingTableDialog';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

export enum TouchDisplayDialogChoice {
    ExtendTouchDevice = 0,
    ExtendSensor = 1,
    ExtendAd = 2,
    None = 255
}

export enum TouchDisplayLicenseActionChoice {
    ExtendTouchDevice = 0,
    ExtendSensor = 1,
    ExtendAd = 2,
    None = 255
}

export const LicenseManagementTouchDisplayDialog = ({ dialogChoice = TouchDisplayDialogChoice.None, visible, setVisible, groups, setGroups, freeLicenses, handleSelectedCombinationGroup, navigateByLicenseTable, loadDataAsync, preSelectedGroup = null, licenseActionChoice }
    : { dialogChoice: TouchDisplayDialogChoice, visible: boolean, setVisible: any, groups: GroupLicenseTable[], setGroups: any, freeLicenses: GroupLicense[], handleSelectedCombinationGroup: any, navigateByLicenseTable: any, loadDataAsync: any, preSelectedGroup?: GroupLicenseTable | null, licenseActionChoice: TouchDisplayLicenseActionChoice }) => {

    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);

    const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);
    const [selectedCombinationGroup, setSelectedCombinationGroup] = React.useState<GroupLicenseTable[]>([]);
    const [assignAdLicenseDialogVisible, setAssignAdLicenseDialogVisible] = React.useState(false);

    const [assignDeviceLicenseDialogVisible, setAssignDeviceLicenseDialogVisible] = React.useState(false);
    const [assignExtendedSensorDialogVisible, setAssignExtendedSensorDialogVisible] = React.useState(false);
    const [selectedLicense, setSelectedLicense] = React.useState<GroupLicense>();
    const [assignExtendedSensorLicenseChooserDialogVisible, setAssignExtendedSensorLicenseChooserDialogVisible] = React.useState(false);

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [token, setToken] = React.useState("");
    const {t} = useTranslation();

    const assignLicenseWithSensor = async (selectedLicense: GroupLicense, setVisibleDialog: any) => {
        if (!selectedLicense || !selectedGroup) return;

        setVisibleDialog(false);
        if (selectedLicense.sensorCount > 0) {
            setSelectedLicense(selectedLicense);
            setAssignExtendedSensorLicenseChooserDialogVisible(true);
        } else {
            await assignLicense(selectedLicense);
        }
    }

    const assignLicense = async (selectedLicense: GroupLicense, setVisibleDialog: any = null) => {
        if (!selectedLicense || !selectedGroup) return;

        if (setVisibleDialog) setVisibleDialog(false);



        await api.assignLicenseToGroup(selectedGroup.id, selectedLicense, token, enqueueSnackbar);
        await loadDataAsync();
    }


    const classes = useStyles();

    const onClose = () => setVisible(false);

    React.useEffect(() => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(preSelectedGroup);
        setSelectedGroups(preSelectedGroup ? [preSelectedGroup] : []);

        if (preSelectedGroup) {
            switch (dialogChoice) {
                case TouchDisplayDialogChoice.ExtendTouchDevice:
                    setAssignDeviceLicenseDialogVisible(true);
                    break;
                case TouchDisplayDialogChoice.ExtendAd:
                    setAssignAdLicenseDialogVisible(true);
                    break;
                case TouchDisplayDialogChoice.ExtendSensor:
                    setAssignExtendedSensorDialogVisible(true);
                    break;
            }
        }

    }, [visible]);

    const buildButtonExtendTouchDevice = () => {
        return (
            <Button
                disabled={!selectedGroup}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                startIcon={LicenseWhite()}
                endIcon={<TabletAndroid />}
                onClick={() => {
                    setAssignDeviceLicenseDialogVisible(true);
                }}
            >
                {selectedGroup && selectedGroup.deviceLicenseIsValid ? t("myUnitsSettings.extendTouchDisplayLicense") : `Touch Display ${t("myUnitsSettings.assignLicense")}`}
            </Button>
        )
    }

    const buildButtonExtendAdLicense = () => {
        return (
            <Button
                disabled={!selectedGroup}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                startIcon={LicenseWhite()}
                endIcon={<FeaturedVideo />}
                onClick={() => {
                    setAssignAdLicenseDialogVisible(true);
                }}
            >
                {selectedGroup && selectedGroup.adLicenseIsValid ? t("myUnitsSettings.extendAdvertisingLicense") : `${t("myUnitsSettings.advertisements")} ${"myUnitsSettings.assignLicense"}`}
            </Button>
        )
    }

    const buildButtonExtendBluetoothDevice = () => {
        return (
            <Button
                disabled={!selectedGroup}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                startIcon={LicenseWhite()}
                endIcon={<MotionSensorSvgWhite height={30}/>}
                onClick={() => {
                    setAssignExtendedSensorDialogVisible(true);
                }}
            >
                {selectedGroup && selectedGroup.deviceLicenseIsValid ? t("myUnitsSettings.extendExtendedSensor") : `Extended Sensor ${"myUnitsSettings.assignLicense"}`}
            </Button>
        )
    }

    const buildActionButton = () => {
        switch (licenseActionChoice) {
            case TouchDisplayLicenseActionChoice.ExtendTouchDevice:
                return (
                    buildButtonExtendTouchDevice()
                )
            case TouchDisplayLicenseActionChoice.ExtendAd:
                return (
                    buildButtonExtendAdLicense()
                )
            case TouchDisplayLicenseActionChoice.ExtendSensor:
                return (
                    buildButtonExtendBluetoothDevice()
                )
            case TouchDisplayLicenseActionChoice.None:
                return (
                    <>
                        {buildButtonExtendTouchDevice()}
                        {buildButtonExtendAdLicense()}
                        {buildButtonExtendBluetoothDevice()}
                    </>
                )
        }
    }

    return (
        <Dialog
            open={visible}
            onClose={onClose}
            fullWidth
            maxWidth="xl"
        >
            <DefaultDialogTitle
                title="Lizenzierte Touch Smart-Service-Displays"
                handleClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                {
                    groups.length > 0 &&
                    <Grid container direction="row" spacing={2}>
                        <Grid item>
                            <Button
                                disabled={!selectedGroup}
                                variant="contained"
                                size="large"
                                color="primary"
                                startIcon={<Navigation />}
                                onClick={() => {
                                    if (!selectedGroup) return;
                                    navigateByLicenseTable(selectedGroup);
                                }}
                            >
                                {t("myUnitsSettings.navigateToUnit")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={selectedGroups.length < 2}
                                variant="contained"
                                size="large"
                                color="primary"
                                startIcon={<MergeType />}
                                onClick={() => {
                                    handleSelectedCombinationGroup(selectedGroups, groups, setSelectedCombinationGroup, history);
                                }}
                            >
                                Einheiten kombinieren
                            </Button>
                        </Grid>

                        {
                            selectedGroups.length > 0 &&
                            <Grid item>
                                <Button
                                    className={classes.deleteButton}
                                    variant="contained"
                                    size="large"
                                    startIcon={<Close />}
                                    disabled={selectedGroups.length < 1}
                                    onClick={() => {

                                        if (selectedGroups.length < 1) return;
                                        setDeleteConfirmationDialogVisible(true);
                                    }}
                                >
                                    Einheit löschen
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Lizenz zuweisen: {!!selectedGroup ? selectedGroup.notificationName : "Keine Einheit ausgewählt"}</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={3}>
                            <Grid item lg={3}>

                            </Grid>

                            <Grid item lg={3}>

                            </Grid>

                            <Grid item lg={3}>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item style={{ maxWidth: '100%' }}>
                            <GroupSelectionTable
                                groups={groups.filter(x => x.groupType == GroupType.DisplayWallApp)}
                                selectedGroups={selectedGroups}
                                onSelectionChange={(selection: string[]) => {
                                    setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
                                    setSelectedGroup(selection.length === 1 ? groups.filter(x => selection.some(s => s === x.id))[0] : null);
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                {
                    (!groups || groups.length === 0) &&
                    <Paper className="Paper">
                        <Grid container direction="row" alignItems="center" justifyContent="center">
                            <Grid item>
                                <Typography component="h5" variant="h5"
                                >
                                    {t("license-general.youHaveNotYetAssignedAnyLicencesInstallADeviceNow")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                }

            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<Close />}
                    onClick={async () => {
                        onClose();
                    }}
                >
                    {t("myUnitsSettings.close")}
                </Button>
                {buildActionButton()}
            </DialogActions>
            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title={`Sind Sie sicher, die ausgewählten Einheiten zu löschen?`}
                onClose={() => setDeleteConfirmationDialogVisible(false)}
                onConfirmed={async () => {

                    if (selectedGroups.length < 1) return;

                    const token = getApiToken(history);
                    for (let currentGroup of selectedGroups) {
                        await api.deleteGroupAsync(currentGroup.id, token, enqueueSnackbar);
                    }
                    setGroups(groups.filter(x => !selectedGroups.some(y => y.id == x.id)));

                    setDeleteConfirmationDialogVisible(false);
                }}
            />
            <AssignLicenseChoosingTableDialog
                visible={assignExtendedSensorDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.Extension)}
                onClose={() => {
                    setAssignExtendedSensorDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    assignLicenseWithSensor(selectedLicense, setAssignExtendedSensorDialogVisible);
                }}
            />
            <AssignLicenseChoosingTableDialog
                visible={assignAdLicenseDialogVisible}
                showCountType={ShowCountType.Ad}
                licenses={freeLicenses.filter(x => x.type === LicenseType.Advertisement)}
                onClose={() => {
                    setAssignAdLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    assignLicenseWithSensor(selectedLicense, setAssignAdLicenseDialogVisible);
                }}
            />
            <AssignLicenseChoosingTableDialog
                visible={assignDeviceLicenseDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.Device)}
                onClose={() => {
                    setAssignDeviceLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    assignLicenseWithSensor(selectedLicense, setAssignDeviceLicenseDialogVisible);
                }}
            />
            {selectedGroup && selectedLicense && selectedGroup.sensorLicenses &&
                <AssignSensorLicenseChoosingTableDialog
                    visible={assignExtendedSensorLicenseChooserDialogVisible}
                    licenses={selectedGroup.sensorLicenses}
                    currentSelectedLicense={selectedLicense}
                    sensorCount={selectedLicense.sensorCount}
                    onClose={() => {
                        setAssignExtendedSensorLicenseChooserDialogVisible(false);
                    }}
                    onLicenseSelected={async (selectedSensorLicenses: SensorLicense[]) => {
                        selectedLicense.sensorLicenses = selectedSensorLicenses;
                        if (selectedLicense) await assignLicense(selectedLicense, setAssignExtendedSensorLicenseChooserDialogVisible);
                    }}
                />
            }
        </Dialog>

    );
};

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
    dialogContent: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    }
}));
