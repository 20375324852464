import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { useTranslation } from 'react-i18next';

export const ObjectMultipleSelectionDataTable = ({ rows, groups, selectedObjects, onSelectionChange }
    : { rows: ObjectUnit[], groups: GroupLicenseTable[], selectedObjects: ObjectUnit[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();
    
    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === ObjectUnit.Columns.groups) {
            let names = groups.filter(x => x.objectUnitId && x.objectUnitId == row.id).map(x => x.notificationName);
            return buildCellStringArray(props, names, `Enthält ${names.length} Einheiten`);
        }
        if (column.name === ObjectUnit.Columns.devices) {
            let names = groups.filter(x => x.objectUnitId && x.objectUnitId == row.id).map(x => x.uniqueDeviceId);
            return buildCellStringArray(props, names, `Enthält ${names.length} DeviceIds`);
        }
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: ObjectUnit.Columns.name,
            title: "Name",
            getCellValue: (row: ObjectUnit) => row.name,
        },
        {
            name: ObjectUnit.Columns.customerName,
            title: t("myUnitsSettings.customerName"),
            getCellValue: (row: ObjectUnit) => row.customerName,
        },
        {
            name: ObjectUnit.Columns.groups,
            title: t("myUnitsSettings.assignedUnits"),
            getCellValue: (row: ObjectUnit) => {
                return groups.filter(x => x.objectUnitId == row.id).reduce((prevVal, curVal) => {
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: ObjectUnit.Columns.devices,
            title: "DeviceIds",
            getCellValue: (row: ObjectUnit) => {
                return groups.filter(x => x.objectUnitId == row.id).reduce((prevVal, curVal) => {
                    console.log(prevVal, curVal);
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: ObjectUnit.Columns.addressLine,
            title: t("myUnitsSettings.address"),
            getCellValue: (row: ObjectUnit) => row.addressLine,
        },
        {
            name: ObjectUnit.Columns.city,
            title: t("myUnitsSettings.city"),
            getCellValue: (row: ObjectUnit) => row.city,
        },
        {
            name: ObjectUnit.Columns.state,
            title: t("myUnitsSettings.state"),
            getCellValue: (row: ObjectUnit) => row.state,
        },
        {
            name: ObjectUnit.Columns.country,
            title: t("myUnitsSettings.country"),
            getCellValue: (row: ObjectUnit) => row.country,
        },
        {
            name: ObjectUnit.Columns.zipCode,
            title: t("myUnitsSettings.postalCode"),
            getCellValue: (row: ObjectUnit) => row.zipCode,
        },
        {
            name: ObjectUnit.Columns.isActivatedEnabled,
            title: "Aktiviert",
            getCellValue: (row: ObjectUnit) => row.isActivatedEnabled ? "Aktiviert" : "Deaktivert",
        }

    ];


    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 180 } }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: ObjectUnit) => row.id}
                columns={columns}
            >

                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedObjects.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
        </div>
    );
};
