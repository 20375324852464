import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Checkbox, FormControlLabel, TableCell, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { GroupOnlineStatusCountTable } from '../../../../../models/GroupOnlineStatus';
import { GroupServiceModeCountTable } from '../../../../../models/GroupServiceModeCountTable';
import { Group } from '../../../../../models/Group';
import { mapToiletTypeKey } from '../../../../tables/Base';
import React from 'react';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { ObjectSessionCountTableDataType, UserServiceWorkerObjectSession } from '../../../../../models/UserServiceWorkerObjectSession';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { Engineering } from '@mui/icons-material';


const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

export const ObjectSessionCountTable = ({ objectSessionTableData,objectSessionCountTableData, objects, userServiceWorkers }: { objectSessionTableData: UserServiceWorkerObjectSession[],objectSessionCountTableData: ObjectSessionCountTableDataType[], objects?: ObjectUnit[], userServiceWorkers: UserServiceWorker[] }) => {
    const exporterRef: any = useRef(null);
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [columns] = useState([
        {
            name: ObjectSessionCountTableDataType.Columns.objectId,
            title: 'Object',
            getCellValue: (row: ObjectSessionCountTableDataType) => {
                if (!objects) return "";
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? "Nicht Aktiv" : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        },
        { name: ObjectSessionCountTableDataType.Columns.sessionCount, title: 'Online Gemeldet', getCellValue: (row: ObjectSessionCountTableDataType) => row.sessionCount },
        { name: ObjectSessionCountTableDataType.Columns.countMax, title: 'Anz. Servicekräfte Anwesend', getCellValue: (row: ObjectSessionCountTableDataType) => row.userServiceWorkerIds.length },
        {
            name: ObjectSessionCountTableDataType.Columns.allWorkers,
            title: 'Zugewiesene Servicekräfte',
            getCellValue: (row: ObjectSessionCountTableDataType) => {
                if (!objects) return "";
                let object = objects.find(x => x.id == row.objectId);
                return (
                    object?.userServiceWorkerIds.length
                );
            }
        },
        {
            name: ObjectSessionCountTableDataType.Columns.countPercent, title: 'Prozentanteil Anwesend',
            getCellValue: (row: ObjectSessionCountTableDataType) => {
                if (!objects) return "";
                let object = objects.find(x => x.id == row.objectId);
                if (!object || row.userServiceWorkerIds.length < 1) return 0;
                return (
                    Math.round((row.userServiceWorkerIds.length / object.userServiceWorkerIds.length) * 100) + "%"
                );
            }
        },
        {
            name: ObjectSessionCountTableDataType.Columns.missingWorkers, title: 'Fehlende Servicekräfte',
            getCellValue: (row: ObjectSessionCountTableDataType) => {
                if (!objects) return "";
                let object = objects.find(x => x.id == row.objectId);
                let workers = object?.userServiceWorkerIds.filter(x => !row.userServiceWorkerIds.includes(x));
                if (!object || row.userServiceWorkerIds.length < 1) return 0;
                return (
                    workers?.map(x => userServiceWorkers.find(y => y.id == x)?.name || "Servicekraft gelöscht").join(", ")
                );
            }
        }
    ]);



    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == ObjectSessionCountTableDataType.Columns.objectId) {
            return { columnName: el.name, width: 300 }
        }
        if (el.name == ObjectSessionCountTableDataType.Columns.countMax) {
            return { columnName: el.name, width: 350 }
        }
        return { columnName: el.name, width: 250 }
    }));

    const Cell = (props: any) => {
        const { column, row } : {column: any, row: ObjectSessionCountTableDataType} = props;
        if (column.name === ObjectSessionCountTableDataType.Columns.countMax) {
            let names = row.userServiceWorkerIds.map(x=> `${userServiceWorkers.find(y=>y.id == x)?.name} | Anmeldungen: ${objectSessionTableData.filter(y=>y.objectId == row.objectId).filter(y=>y.userServiceWorkerId == x).length}` || "Gelöscht");
            return buildCellStringArray(props, names, `${names.length} Servicekräfte`, null,names?.map(x=><Engineering />));
        }
        if (column.name === ObjectSessionCountTableDataType.Columns.allWorkers) {
            if (!objects) return 0;
            let object = objects.find(x => x.id == row.objectId);
            let names = object?.userServiceWorkerIds.map(x=> userServiceWorkers.find(y=>y.id == x)?.name || "Gelöscht");
            return buildCellStringArray(props, names || [], `${object?.userServiceWorkerIds.length} Servicekräfte`, null,names?.map(x=><Engineering />));
        }
        if (column.name === ObjectSessionCountTableDataType.Columns.missingWorkers) {
            if (!objects) return "";
            let object = objects.find(x => x.id == row.objectId);
            let workerIds = object?.userServiceWorkerIds.filter(x => !row.userServiceWorkerIds.includes(x));
            let names = workerIds?.map(x=> userServiceWorkers.find(y=>y.id == x)?.name || "Gelöscht");
            if (!object || row.userServiceWorkerIds.length < 1) return 0;
            return buildCellStringArray(props, names || [], `${names?.length} Servicekräfte`,null, names?.map(x=><Engineering />));
        }

        return <Table.Cell {...props} />;
    };


    const [filters, setFilters] = useState([]);
    return (
        <>
            <Grid
                rows={objectSessionCountTableData}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering />
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                />
                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting />

                <IntegratedPaging />
                <Table
                    cellComponent={Cell as any}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >

                    <TemplatePlaceholder />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow
                />
                <PagingPanel />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={objectSessionCountTableData}
                columns={columns}
                filters={filters}
                onSave={onSave}
            />
        </>
    );
};