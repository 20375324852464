import React, { useCallback, useRef, useState } from 'react';
import { Column, CustomSummary, DataTypeProvider, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupFeedbackQuestion } from '../../../../../models/GroupFeedbackQuestion';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { dayNameOfJsWeekDay, formatEuropeanDate, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray, useAccordionForStringArrayStyles } from '../../../../common/AccordionForStringArray';
import { GroupIncidentVisitor } from '../../../../../models/GroupIncidentVisitor';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandMore, Warning } from '@mui/icons-material';
import { Grid as MuiGrid } from '@mui/material';
import { eachDayOfInterval, eachHourOfInterval, format, fromUnixTime, getHours, isAfter, isBefore, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday } from 'date-fns';
import { LoraGroupMotionWeeklyTable } from '../../../../../models/LoraGroupMotion';
import { lightBlue } from '../../../../../styles/colors';
import { lightGreen, red } from '@mui/material/colors';
import { Days } from 'rrule/dist/esm/src/rrule';
import { de } from 'date-fns/locale';
import { CurrencyFormatterMinutes } from './AnalyticGroupsSummaryLoraWeeklyCalendarDataTable';
import { maxContentWrapperPortalStyle } from '../../../PortalPage';


const CurrencyTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatterMinutes}
        {...props}
    />
);


export const AnalyticSummaryLoraDailyByMonthDataTable = ({ rows, startDate, endDate, onSelectionChange, virtualTableHeight }
    : { rows: LoraGroupMotionWeeklyTable[], startDate: Date, endDate: Date, onSelectionChange: any, virtualTableHeight: number }) => {

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const classes = useAccordionForStringArrayStyles();
    const [columnExtension, setColumnExtension] = React.useState<any[]>([]);
    const [sorting, setSorting] = useState<any[]>([]);


    const [totalSummaryItems, setTotalSummaryItems] = useState<any[]>([]);
    const [currencyColumns, setCurrencyColumns] = useState<any[]>([]);

    React.useEffect(() => {

        let days = [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR, Days.SA, Days.SU]

        let dateColumns = days.map(day => {
            let dayJs = day.getJsWeekday();

            return {
                name: dayNameOfJsWeekDay[dayJs],
                title: dayNameOfJsWeekDay[dayJs],
                getCellValue: (row: LoraGroupMotionWeeklyTable) => {
                    let funcToCall: (date: Date) => boolean;
                    switch (dayJs) {
                        case 0:
                            funcToCall = isSunday;
                            break;
                        case 1:
                            funcToCall = isMonday;
                            break;
                        case 2:
                            funcToCall = isTuesday;
                            break;
                        case 3:
                            funcToCall = isWednesday;
                            break;
                        case 4:
                            funcToCall = isThursday;
                            break;
                        case 5:
                            funcToCall = isFriday;
                            break;
                        default:
                            funcToCall = isSaturday;
                    }

                    let entry = row.data.filter(x => {
                        return funcToCall(fromUnixTime(x.createdTimestamp));
                    }).reduce((prevVal, curVal) => prevVal = prevVal + curVal.motionCount, 0);

                    return entry;
                }
            };
        });
        let onlyDateColumns = [...dateColumns];
        setColumnExtension(onlyDateColumns.map(x => ({ columnName: x.name, align: 'center' })));
        setOnlyDateColumns(onlyDateColumns);
        let sumEntry = {
            name: LoraGroupMotionWeeklyTable.Columns.week,
            title: "Einheit Name",
            getCellValue: (row: LoraGroupMotionWeeklyTable) => {
                return format(row.week, "MMMM", { locale: de });
            },
        };

        let sumEntryRight = {
            name: LoraGroupMotionWeeklyTable.Columns.sum,
            title: "Summe",
            getCellValue: (row: LoraGroupMotionWeeklyTable) => {
                let entries = row.data.reduce((prevVal, curVal) => {
                    return prevVal + curVal.motionCount;
                }, 0);

                return entries;
            }
        };
        dateColumns = [sumEntry as any, ...dateColumns, sumEntryRight];

        let dateColumnsWidth = dateColumns.map(el => {
            if (el.name == LoraGroupMotionWeeklyTable.Columns.week || el.name == LoraGroupMotionWeeklyTable.Columns.sum) {
                return { columnName: el.name, width: 250 } as TableColumnWidthInfo;
            }
            return { columnName: el.name, width: 120 } as TableColumnWidthInfo;
        });
        setColumns([...dateColumns]);
        setColumnWidths([...dateColumnsWidth]);



        let loraGroupColumnsTotal = dateColumns.filter(x =>
            !(x.name == LoraGroupMotionWeeklyTable.Columns.week))
            .map(el => {
                return { columnName: el.name, type: "sum" };
            });

        let loraGroupColumnsMax = dateColumns.filter(x =>
            !(x.name == LoraGroupMotionWeeklyTable.Columns.week))
            .map(el => {
                return { columnName: el.name, type: "max" };
            });

        let loraGroupColumnsAvg = dateColumns.filter(x =>
            !(x.name == LoraGroupMotionWeeklyTable.Columns.week))
            .map(el => {
                return { columnName: el.name, type: "avg" };
            });



        let totalColumns = [...loraGroupColumnsTotal, ...loraGroupColumnsMax, ...loraGroupColumnsAvg];
        setTotalSummaryItems(totalColumns);
        setCurrencyColumns(totalColumns.map(x => x.columnName));

    }, [startDate, endDate, rows])


    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: LoraGroupMotionWeeklyTable, value: any } = props;
        if (onlyDateColumns.some(x => x.name == column.name)) {
            return (
                <Table.Cell style={{ textAlign: 'center', backgroundColor: value == 0 ? red[100] : lightGreen[100] }} {...props}>
                    <Typography>{value}</Typography>
                </Table.Cell>
            )
        }

        return <Table.Cell {...props} />;
    };


    let [columns, setColumns] = React.useState<Column[]>([]);

    let [onlyDateColumns, setOnlyDateColumns] = React.useState<Column[]>([]);

    let [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>([]);

    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Leistung - LoraMotionDaily Kalender');
        });
    };


    return (
        <div style={maxContentWrapperPortalStyle}>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CurrencyTypeProvider
                    for={currencyColumns}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <IntegratedSummary />

                <VirtualTable
                    columnExtensions={columnExtension}
                    height={"auto"}
                    cellComponent={Cell}
                />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                <TableHeaderRow showSortingControls/>
                <TableSummaryRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
                totalSummaryItems={totalSummaryItems}
            />
        </div>
    );
};
